<template>
  <div>
    <div class="grid grid-cols-1 gap-2 md:grid-cols-3 md:gap-4">
      <div class="flex flex-col">
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Nombre</label
        >
        <v-select
          class="style-chooser custom-border"
          :options="employees"
          :reduce="(employee) => employee.id"
          :disabled="this.action == 'update'"
          @input="submited"
          v-model="buyObj.employee_id"
          :getOptionLabel="
            (employee) =>
              employee.name +
              ' ' +
              employee.last_name +
              ' ' +
              employee.mothers_last_name
          "
        />
      </div>
      <div class="flex flex-col">
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Rut</label
        >
        <input
          class="input-text cursor-not-allowed"
          disabled
          type="text"
          placeholder="Rut"
          v-model="employee_rut"
        />
      </div>

      <div class="flex flex-col">
        <label
        class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1">
        Fecha</label
        >
        <date-picker
          type="date"
          format="DD-MM-YYYY"
          class="border dark:bg-black-dark dark:border-background-dark"
          :style="{
            width: '100%',
            'border-radius': '4px',
            'padding-left': '0.66rem',
            'padding-right': '0.66rem',
            'padding-top': '0.66rem',
            'padding-bottom': '0.66rem',
          }"
          input-class="xmx-input"
          v-model="buyObj.period"
          placeholder="Selecciona una Fecha"
        ></date-picker>
      </div>

      <div class="flex flex-col">
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Tipo Remuneración</label
        >
        <v-select
          class="style-chooser custom-border"
          :options="settlement_type_options"
          :reduce="(type) => type.value"
          label="name"
          :disabled="this.action == 'update'"
          @input="submited"
          v-model="buyObj.settlement_type"
          append-to-body
        />
      </div>
<!--       <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Total</label
        >
        <input
          class="dark:bg-black-dark dark:border-background-dark appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4"
          type="text"
          placeholder="Total"
          v-model="buyObj.total"
        />
      </div> -->
      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Total Haber</label
        >
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Total Haber"
          v-model="buyObj.total_assets"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Descuentos Trabajador
          <span class="text-xs text-gray-500 italic normal-case">
            (AFP, Salud y AFC)
          </span>
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Descuentos Trabajador"
          v-model="buyObj.total_legal_discounts"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Liquido a Pago
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Liquido a Pago"
          v-model="buyObj.liquid_to_payment"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Impuesto Trabajador
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Impuesto Trabajador"
          v-model="buyObj.taxes_salary"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1 || buyObj.settlement_type == 0"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Anticipos
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Anticipos"
          v-model="buyObj.total"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1 || buyObj.settlement_type == 3"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Indemnizaciones
        </label>
        <currency-input
          class="input-text caret-cian-dark "
          currency="CLP"
          locale="es-CL"
          placeholder="Indemnizaciones"
          v-model="buyObj.total"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Aporte Empleador
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Aporte Empleador"
          v-model="buyObj.employer_contribution"
        />
      </div>

      <div
        class="flex flex-col"
        v-if="buyObj.settlement_type == 1"
      >
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
          >Otras Retenciones
        </label>
        <currency-input
          class="input-text"
          currency="CLP"
          locale="es-CL"
          placeholder="Otras Retenciones"
          v-model="buyObj.other_retentions"
        />
      </div>
    </div>

    <div class="modal-footer flex justify-end py-3">
      <slot name="footer">
        <div class="">
          <button
            :disabled="submitValidation"
            class="bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white font-bold p-3 dark:bg-cian-dark dark:border-cian-dark rounded-full"
            @click.prevent="submit"
          >
            Guardar Información
          </button>
        </div>
      </slot>
    </div>
  </div>
</template>
<script>
// import { required } from "vuelidate/lib/validators";
import DatePicker from "vue2-datepicker";

export default {
  name: "newRemuneration",
  props: {
    buy_id: null,
    action: null,
  },
  data() {
    return {
      ids: [],
      name: "",
      date: "",
      rut: "",
      employees: [],
      employee_rut: null,
      employee_id: null,
      submitValidation: false,
      settlement_type_options: [
        { value: null, name: 'Seleccionar tipo de remuneración' },
        { value: 0, name: 'Anticipo' },
        { value: 1, name: 'Sueldo' },
        // { value: 2, name: 'Sueldo Reprocesada' },
        { value: 3, name: 'Finiquito' }
      ],
      defaultSelected: {
        id: null,
        name: null,
      },
      dataAcc: [
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          neto: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "buy",
          state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
        },
      ],
      buyObj: {
        type_movement: "type_buy",
        settlement_type: null,
        category_movement: 5,
        movement_status: "not_paid",
        company_id: this.$store.state.company_id,
        total: 0,
        total_assets: 0,
        total_legal_discounts: 0,
        liquid_to_payment: 0,
        taxes_salary: 0,
        advance: 0,
        compensation: 0,
        period: null,
        employer_contribution: 0,
        other_retentions: 0
      },
    };
  },
  components: {
    DatePicker,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
     buyObj: {
       total_assets: {},
       total_legal_discounts:{},
       liquid_to_payment:{},
       taxes_salary:{},
       advance:{},
       compensation:{},
       employer_contribution:{},
     }
  },
  watch: {
    employee_id() {
      this.getEmployee();
    },
  },
  mounted() {
    this.getAllEmployees();
    this.getBuy()
  },
  methods: {
    addAttribute(e) {
      this.buyObj.account_movements_attributes = e;
      this.dataAcc = e;
    },
    submit() {
      this.action == "create"
        ? this.create(this.buyObj)
        : this.update(this.buyObj, this.buy_id);
    },
    update(data, id) {
      this.submitValidation = true;
      this.axios
        .patch("movements/" + id, data)
        .then((obj) => {
          this.$swal.fire(
            "Actualizado",
            "Tu egreso ha sido actualizado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file");
          this.$emit("getBuys");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    create(data) {
      this.submitValidation = true;
      this.axios
        .post("/movements", data)
        .then((obj) => {
          this.$swal.fire(
            "Creado",
            "Tu egreso ha sido creado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file");
          this.$emit("getBuys");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    getEmployee() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/employees/" + app.buyObj.employee_id, {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          this.employee_rut = response.data.rut;
        });
    },
    getBuy() {
      if (this.buy_id) {
        this.axios.get(`movements/${this.buy_id}`).then((res) => {
          switch (res.data.category_movement) {
            case "buy_sii":
              this.buyObj.category_movement = 2;
              break;
            case "honorary_sii":
              this.buyObj.category_movement = 3;
              break;
            case "monthly_expenses":
              this.buyObj.category_movement = 4;
              break;
            case "remuneration":
              this.buyObj.category_movement = 5;
              break;
            case "withdraw":
              this.buyObj.category_movement = 6;
          }

          switch (res.data.settlement_type) {
            case "advance":
              this.buyObj.settlement_type = 0;
              break;
            case "salary":
              this.buyObj.settlement_type = 1;
              break;
            case "reprocessed_salary":
              this.buyObj.settlement_type = 2;
              break;
            case "settlement":
              this.buyObj.settlement_type = 3;
          }

          this.buyObj.coin = res.data.coin === "pen" ? 0 : 1;
          this.buyObj.total = Number(res.data.total);
          this.buyObj.neto = Number(res.data.neto);
          this.buyObj.period = new Date(res.data.period);
          this.buyObj.responsable = res.data.responsable;
          this.buyObj.folio = res.data.folio;
          this.buyObj.company_id = res.data.company_id;
          this.buyObj.vendor_id = res.data.vendor_id;
          this.buyObj.service_provider_id = res.data.service_provider_id;
          this.buyObj.user_id = res.data.user_id;
          this.buyObj.vendor = res.data.vendor;
          this.buyObj.account_neto_id = res.data.account_neto_id;
          this.buyObj.movement_status = res.data.movement_status;
          this.buyObj.movement_checked = res.data.movement_checked;
          this.buyObj.doc_name = res.data.doc_name;
          this.buyObj.doc_value = res.data.doc_value;
          this.buyObj.total_assets = res.data.total_assets;
          this.buyObj.total_legal_discounts = res.data.total_legal_discounts;
          this.buyObj.liquid_to_payment = res.data.liquid_to_payment;
          this.buyObj.taxes_salary = res.data.taxes_salary;
          this.buyObj.advance = res.data.advance;
          this.buyObj.compensation = res.data.compensation;
          this.buyObj.employee_id = res.data.employee_id;
          this.employee_id = res.data.employee_id;
          this.buyObj.employer_contribution = res.data.employer_contribution;
          this.buyObj.period = new Date(res.data.period);
          this.buyObj.other_retentions = res.data.other_retentions;
          this.dataAcc = res.data.account_movements;
          this.setMaxMin(res.data.account_movements_only_neto);
          //

        });
      }
    },
    setMaxMin(account_movements) {
      let a = account_movements.map((element, index) => {
        if (
          this.action === "create" ||
          element.state === "neto" ||
          element.state === "exempt_amount" ||
          element.state === "gross" ||
          this.action === "update"
        ) {
          let porcentaje = (element.total_amount / this.buyObj.neto) * 100;
          element.percentage = Math.round(porcentaje, 2);
          element.min = 0;
          element.max = Math.floor(this.buyObj.neto * element.percentage) / 100;
          this.ids.push(index);
          element.disable = true;
          if(index == account_movements.length - 1){
            element.disable = false
          }
          return element;
        }
        return element;
      });
      this.dataAcc = a;
    },
    submited(event) {
      this.employee_id = event;
    },
    getAllEmployees() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/employees", {
          params: {
            company_id: app.company_id
          },
        })
        .then((response) => {
          app.employees = response.data.data;
        });
    },
  },
};
</script>
<style scoped>
textarea:focus,
input:focus {
  outline: none;
}
</style>
