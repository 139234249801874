<template>
  <div>
    <h2
      class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200"
    >
      Cambiar Propiedad Compañia 
    </h2>
    <div class="grid grid-cols-3">
      <div>
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Propietario
        </label>
        <v-select
          class="style-chooser custom-border"
          v-model="defaultSelected"
          :clearable="false"
          :options="company.permissions_attributes"
          :reduce="(permission) => permission.user_id"
          label="email"
        ></v-select>
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading" />
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        :disabled="company.sii_config_attributes.user_id !== user.id"
        :class="{ 'opacity-50 cursor-not-allowed': company.sii_config_attributes.user_id !== user.id}"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner2.vue";
import "vue-select/dist/vue-select.css";
export default {
  name: "CompanyProperty",
  props: {
    company: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      defaultSelected: "",
      user: this.$auth.user(),
      loading: false,
    };
  },
  components: {
    Spinner,
  },
  created() {
    this.$emit("updateTab", "CompanyProperty");
    this.defaultSelected = this.company.sii_config_attributes.user_id;
  },
  methods: {
    update() {
      let config_id = this.company.sii_config_attributes.id;
      (this.loading = true),
        this.$swal
          .fire({
            title: "¿Estas Seguro?",
            text: "Vas a cambiar la propiedad de " + this.company.business_name,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Cambiar la Propiedad",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value) {
              this.axios
                .patch("sii_configs_property/" + config_id, {
                  user_id: this.defaultSelected,
                })
                .then(() => {
                  this.$swal.fire(
                    "Actualizado",
                    "El cambio de Compañia ha sido registrado exitosamente.",
                    "success"
                  );
                  this.$router.go()
                  this.loading = false;
                })
                .catch(() => this.loading = false);
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "No se cambio la propiedad de la compañia.",
                "error"
              );
              this.loading = false;
            }
          });
    },
  },
};
</script>
