<template>
  <div class="w-full">
    <h2 class="text-xl dark:text-white font-semibold my-4">
      Movimientos
    </h2>
    <div>
      <BeeGridTable
        :serverSide="true"
        border
        :showFilter="false"
        :showSummary="false"
        :filterTotal="filterTotal"
        :total="total"
        :page="page"
        :pageSize="pageSize"
        :pageCount="pageCount"
        :fetchMethod="fetchMethod"
        :columns="columns"
        :data="movements"
        :loading="loading"
      >
        <BeeColumn
          field="folio"
          title="Folio"
          type="date"
          sortable="true"
          :width="180"
        >
          <template slot-scope="{ row }">
            {{ row.folio }}
            <div class="text-sm tracking-normal text-gray-500">
              {{ formatDocType(row.doc_type) }}
            </div>
          </template>
        </BeeColumn>
        <BeeColumn field="period" title="Fecha" type="date" :width="180">
          <template slot-scope="{ row }">
            {{ formatSimpleDate(row.period) }}
            <div
              class="text-sm tracking-normal text-gray-500"
              v-if="row.movement_status === 'not_bill'"
            >
              {{ differenceInDays(row.period) }} días vencidos
            </div>
          </template>
        </BeeColumn>
        <BeeColumn
          field="category_movement"
          title="Fecha"
          type="date"
          :width="180"
        >
          <template slot-scope="{ row }">
            <div v-if="row.category_movement === 'remuneration'">
              <div class="flex gap-2 text-gray-400">
                <ToolTip>
                  <template #icon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                      />
                    </svg>
                  </template>
                  Costo Empresa
                </ToolTip>
                <i>{{ formatNumber(row.company_cost) }}</i>
              </div>
            </div>
          </template>
        </BeeColumn>
        <BeeColumn field="dateSII" title="Periodo SII" type="date" :width="180">
          <template slot-scope="{ row }">
            <div class="text-sm tracking-normal flex" v-if="row.claim_date">
              {{ formatDateSII(row.date_sii) }}
              <span class="red pl-3">
                <Times />
              </span>
            </div>
            <div class="text-sm tracking-normal flex" v-else>
              {{ formatDateSII(row.date_sii) }}
              <span class="green pl-3">
                <Check class="w-5 h-5" />
              </span>
            </div>
          </template>
        </BeeColumn>

        <BeeColumn field="id" title="Clasificación" type="text" :width="300">
          <template slot-scope="{ row }">
            {{ row.account_neto_name }}
          </template>
        </BeeColumn>

        <BeeColumn
          field="total"
          title="Total"
          type="number"
          sortable="true"
          :width="180"
        >
          <template slot-scope="{ row }">
            $ {{ formatNumber(row.total) }}
            <div class="text-sm tracking-normal text-gray-500">
              $ {{ formatNumber(row.balance) }}
            </div>
          </template>
        </BeeColumn>

        <BeeColumn field="cobrado" title="Cobrado" type="text" :width="180">
          <template slot-scope="{ row }">
            <div v-if="row.classified">
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :class="{
                    notBill: row.movement_status === 'bill_subscriber',
                  }"
                  :checked="checkedFunction(row.movement_status)"
                  @input.prevent="updatePay($event.target.checked, row.id)"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div v-else>S/C</div>
          </template>
        </BeeColumn>

        <BeeColumn field="editar" title="Opciones" type="text" :width="180">
          <template slot-scope="{ row }">
            <div class="flex gap-4">
              <a v-on:click="editFunction(row.id)" >
                <Edit />
              </a>

              <a v-on:click="deleteFunction(row.id)" >
                <Times />
              </a>
            </div>
          </template>
        </BeeColumn>
      </BeeGridTable>
    </div>
  </div>
</template>

<script>
import {
  formatDocType,
  formatSimpleDate,
  formatDateSII,
  differenceInDays
} from "@/utils/formatters.js"
import ToolTip from "@/components/ToolTip.vue"
import Times from "@/components/Icons/Times.vue"
import Edit from "@/components/Icons/Edit.vue"
import Check from "@/components/Icons/CheckBadge.vue"

export default {
  props: {
    employee_id: {
      type: Number,
      required: true,
    },
  },
  components: {
    ToolTip,
    Times,
    Edit,
    Check
},
  data() {
    return {
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      columns: [],
      movements: [],
      loading: true,
      per_page: 8,
      total_pages: 0,
      total_items: 0,
    }
  },
  mounted() {
    this.getMovement()
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
    dateRange: {
      get() {
        return this.$store.getters.getDateRange
      },
      set(newValue) {
        this.$store.commit("addDateRange", [newValue[0], newValue[1]])
      },
    },
  },
  methods: {
    formatDocType,
    formatSimpleDate,
    formatDateSII,
    differenceInDays,
    formatNumber(value) {
      if (value) {
        let val = (value / 1).toFixed(0).replace(".", ",")
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      } else {
        return 0
      }
    },
    checkedFunction(status) {
      if (status == "not_paid") return false
      return status === "paid_subscriber" || status === "paid"
    },
    getMovement() {
      this.loading = true
      let app = this
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            employee_id: app.employee_id,
            category_movement: app.category_movement || [5],
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            pending_sii: app.pending_sii,
            sorting: app.sorting,
            query: app.query,
            from: app.dateRange[0],
            to: app.dateRange[1],
          },
        })
        .then((response) => {
          app.movements = response.data.data
          app.filterTotal = response.data.total_items //total filtered data count
          app.total = response.data.per_page // total data count
          app.page = response.data.current_page // current page offset from server
          app.current_page = response.data.current_page
          app.pageSize = response.data.per_page
          app.pageCount = response.data.total_pages
          app.without_account = response.data.without_account
          app.sale_total = response.data.sale_total
          app.sale_total_bill = response.data.sale_total_bill
          app.sale_total_not_bill = response.data.sale_total_not_bill
          app.loading = false
        })
    },
    rowClassName() {
      // update array with ids movements
      let selecteds_checks = this.$refs.selection.getSelection()
      this.checkeds_id = selecteds_checks.map((e) => {
        return e.id
      })
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size)
      let sorting = ""
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType
        if (i !== sortInfos.length - 1) {
          sorting += ","
        }
      }
      console.log(sorting)
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function (value) {
          return value.field
        })

        _sorting = sortInfos.map(function (value) {
          return value.sortType
        })
        this.sorting = _sorting.toString()
        this.nameSorting = _nameSorting.toString()
      }
      this.current_page = offset
      this.per_page = size
      this.getMovement()
    },
  },
}
</script>
