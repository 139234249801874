<template>
  <div>
    <transition name="modal">
      <div class="modal-mask h-screen flex justify-center items-center">
        <div class="">
          <div class="modal-container rounded-sm w-50 dark:bg-background-dark">
            <div class="modal-header flex flex-row justify-center">
              <slot name="header">
                <div class="w-full flex items-center mt-4">
                  <h1 class="text-grey-darker w-full text-2xl dark:text-white">
                    {{ id ? "Actualizar" : "Crear" }}
                  </h1>
                  <a
                    class="text-4xl dark:text-white dark:hover:text-cian-dark"
                    @click.prevent="$emit('close')"> &times; </a>
                </div>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <form class="h-full" enctype="multipart/form-data">
                 <div
                    class="w-full flex flex-col justify-between"
                  >
                    <div class="w-full py-4">
                      <label
                        class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 dark:text-white"
                      >
                        Nombre
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.name"
                      />
                    </div>

                    <!-- <div class="w-full py-2">
                      <label
                        class="block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1 dark:text-white"
                        >Ticket</label
                      >
                       <v-select
                          class="style-chooser custom-border"
                          :options="iconNames"
                          v-model="obj.icon"
                        >
                        <template v-slot:option="option">
                          <font-awesome-icon :icon="option.label" size="lg"/>
                          {{ option.label }}
                        </template>
                      </v-select>
                    </div> -->

                    <div class="w-full py-2">
                      <label
                        class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
                        >Color</label
                      >
                      <input
                        type="color"
                        class="input-text"
                        v-model="obj.color"
                      />
                    </div>
                  </div>

                </form>
              </slot>
            </div>
            <div class="modal-footer flex justify-center">
              <slot name="footer">
                <div class="w-3/3 mb-2">
                  <button
                    class="bg-black dark:bg-cian-dark dark:border-cian-dark rounded-full w-full hover:bg-white hover:text-black border hover:border-black text-white p-2 px-4"
                    @click.prevent="submit()"
                  >
                    Guardar Información
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>
<script>
// import { library } from "@fortawesome/fontawesome-svg-core";
// import { fas } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "new",
  props: {
    id: Number,
    action: String,
    route: String
  },
    data() {
    return {
      obj: {
        name: null,
        color: '#000000',
        icon: null,
        company_id: null,
      },
      iconNames: [],
      optionsTree: [],
    };
  },
  components: {
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  mounted() {
    this.getElement();
  },
  methods: {
    getElement() {
      if(this.id) {
        this.axios
        .get(this.route + "/" + this.id)
        .then((res) => {
          this.obj = res.data;
        });
      }
    },
    submit: function() {
      this.obj.company_id = this.company_id
        if (this.action == "create") {
          this.axios
            .post(this.route, this.obj)
            .then(() => {
              this.$swal.fire(
                "Creado",
                "Ha sido creado con exito",
                "success"
              );
              this.$emit("getData");
              this.$emit("close");
            })
            .catch(() => {
              this.$swal.fire("Oops...", "Algo ha salido mal", "error");
            });
        } else if (this.action == "update") {
          this.axios
            .patch(this.route + "/" + this.id, this.obj)
            .then(() => {
              this.$swal.fire(
                "Actualizado",
                "Ha sido actualizado con exito",
                "success"
              );
              this.$emit("getData");
              this.$emit("close");
            })
            .catch(() => {
              this.$swal.fire("Oops...", "Algo ha salido mal", "error");
            });
        }
      },
  },

}
</script>