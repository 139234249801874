<template>
  <div class="flex items-center justify-center h-screen">
    <div class="rounded-lg shadow-2xl bg-white">
      <div class="flex-col grid gap-4 p-12">
        <div class="text-center text-3xl text-gray-700 font-semibold">
          Lo sentimos, pero no tienes permisos suficientes
        </div>
        <div class="text-center text-4xl text-gray-600 font-bold">
          Error 403
        </div>
        <div class="text-center text-xl text-gray-700">
          Estás intentando acceder a una dirección para la cual no tienes las credenciales necesarias.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "403"
  };
</script>