<template>
  <div class="py-2 mx-6" :class="{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }">
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto text-xl font-semibold">
        Rli
      </h1>
      <div>
        <date-picker
          input-class="xmx-input"
          v-model="dateRange"
          type="date"
          range
          format='DD-MM-YYYY'
          placeholder="Selecciona un rango de Fecha"
          :style="{
            border: '1px solid #e2e8f0',
            width: '100%',
            'background-color': 'white',
            'border-radius': '2px',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.80rem',
            'padding-bottom': '0.80rem',
            'border-radius': '0px'
          }"
        ></date-picker>
      </div>
      <a v-bind:href=" 'https://api.cymasuite.com/rli?company_id=' + company_id + '&from=' + dateRange[0] + '&to=' + dateRange[1] " target="_blank" class="px-8">Descargar Rli</a>
    </div>
  </div>
</template>

<script>
  import DatePicker from 'vue2-datepicker';

  export default {
    name: "Rli",
    props: {},
    data() {
      return {
        
      };
    },
    computed: {
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      }
    },
    watch: {
      company_id(){
        this.reload();
      },
    },
    components: {
      DatePicker
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->