export default {
  auth: require("@websanova/vue-auth/drivers/auth/devise.js"),

  http: require("@websanova/vue-auth/drivers/http/axios.1.x.js"),

  router: require("@websanova/vue-auth/drivers/router/vue-router.2.x.js"),

  loginData: {
    url: "auth/sign_in",
    method: "POST",
    redirect: "/",
    fetchUser: true,
  },

  registerData: { url: "auth", method: "POST", redirect: "/login" },

  logoutData: {
    url: "auth/sign_out",
    method: "DELETE",
    redirect: "/login",
    makeRequest: false,
  },

  fetchData: {
    url: "auth/validate_token",
    method: "GET",
    enabled: true,
    interval: 0,
  },
  refreshData: {
    url: "user_token/",
    method: "GET",
    enabled: false,
    interval: 0,
  },
};
