<template>
  <transition name="modal">
    <div
      class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      v-if="loading"
    >
      <Loader />
    </div>
    <div class="modal-mask flex md:justify-center md:items-center">
      <div class="h-screen w-screen"></div>
      <div class="absolute w-10/12">
        <div
          class="dark:bg-background-dark bg-white w-screen overflow-auto h-screen md:h-auto md:max-h-screen py-5 px-4 md:w-full md:py-4 md:px-10"
        >
          <div class="flex items-center">
            <div class="flex-auto">
              <h1
                class="font-semibold text-grey-darker dark:text-white py-8 text-3xl"
              >
                <slot name="title"> </slot>
              </h1>
            </div>
            <div class="flex justify-end dark:text-white">
              <a
                class="text-6xl dark:hover:text-cian-dark"
                @click.prevent="$emit('close')"
              >
                &times;
              </a>
            </div>
          </div>

          <div class="flex gap-10 flex-col">
            <slot name="body"> </slot>
          </div>
          <div class="modal-footer flex justify-center pr-5 pb-3">
            <slot name="footer"> </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import Loader from "@/components/loader.vue"
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  name: "CustomModal",
  components: { 
    Loader
    }
};
</script>
