<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div
    class="mx-4 md:mx-6 lg:mx-2"
    v-if="company_id"
  >

    <div class="flex-1 mt-4 main-content lg:mt-2 lg:pb-4 md:pb-5">
      <div class="flex flex-col justify-start lg:flex-row">
        <div class="w-full lg:w-8/12">
          <div class="flex flex-wrap px-0 xl:px-4 dark:border-gray-muted rounded-lg">
            <div class="flex-auto mb-4 text-2xl dark:text-white">
              <Transition
                mode="out-in"
                name="custom classes"
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="transform opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform opacity-0"
              >
                <div v-if="loadingMovementsUtility" class="animate-pulse" >
                  <div class="w-12 h-4 my-1 bg-gray-100 dark:bg-black-dark"></div>
                  <div class="w-40 h-6 mt-2 bg-gray-100 dark:bg-black-dark"></div>
                </div>

                <div v-else>
                  <div v-if ="utility == 0 && losses == 0">
                    <p class="text-sm">Pérdida</p>
                    <p class="text-2xl font-bold">0</p>
                  </div>
                  <div v-if="losses < 0">
                    <p class="text-sm text-gray-400">
                      Pérdida
                    </p>
                    <h3
                      class="flex items-center gap-2 font-bold dark:text-white"
                    >
                      {{ formatPrice(losses || 0) }}
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#fab6b8">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </h3>
                  </div>
                  <div v-if="utility > 0">
                    <p class="text-sm text-gray-400">
                      Utilidad
                    </p>
                    <h3
                      class="flex items-center gap-2 text-2xl font-bold dark:text-white "
                    >
                      {{ formatPrice(utility || 0) }}
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#a8d3bd">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                      </svg>
                    </h3>
                  </div>
                </div>
              </Transition>
            </div>

            <div class="w-full mb-5 font-semibold text-gray-500 rounded text-md sm:w-auto sm:mb-0">
              <div>
                <date-picker
                  input-class="xmx-input"
                  v-model="dateRange"
                  :style="{
                    width: '100%',
                    'border-radius': '4px',
                    'padding-left': '0.55rem',
                    'padding-right': '0.55rem',
                    'padding-top': '0.65rem',
                    'padding-bottom': '0.65rem',
                  }"
                  format='DD-MM-YYYY'
                  type="date"
                  range
                  placeholder="Selecciona un rango de Fecha"
                  v-on:input="getMovements"
                >
                  <template v-slot:header="{ emit }">
                    <div style="text-align: left" class="sm:inline-flex justify-center">
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 3)">
                        Últimos 3 meses
                      </button>
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 6)">
                        Últimos 6 meses
                      </button>
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 12)">
                        Último año
                      </button>
                    </div>
                  </template>
                </date-picker>
              </div>
            </div>

            <div class="w-full gap-6 space-y-4 lg:flex lg:space-y-0">
              <div class="lg:w-1/3">
                <div class="flex items-center h-full p-4 bg-cyma-red dark:bg-red-dark rounded-lg">
                  <div v-if="!loadingSummary" class="flex items-center justify-center w-full">
                    <Spinner color="#fdaba8" />
                  </div>
                  <div v-else class="flex flex-col ">
                    <div class="flex flex-row items-center">
                      <div class="flex-shrink pr-4">
                        <div class="p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-red-title">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                          </svg>
                        </div>
                      </div>
                      <div class="flex-1">
                        <h5 class="text-xs font-bold uppercase text-cyma-red-title">Por pagar</h5>

                        <h3 class="font-bold xl:text-xl lg:text-base">
                          {{ formatPrice(buy_total_not_paid || 0) }}
                        </h3>
                        <p v-if="!loadingAcumulated && !loadingSummary"  class="text-xs font-medium text-gray-600">
                          Acumulado $ {{ formatPrice(accumulated_buy || 0) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/3">
                <div class="flex items-center h-full p-4 bg-cyma-green dark:bg-green-dark rounded-lg">
                  <div v-if="!loadingSummary" class="flex items-center justify-center w-full">
                    <Spinner color="#a9d2be" />
                  </div>
                  <div v-else class="flex flex-col">
                    <div class="flex flex-row items-center gap-2">
                      <div class="flex-shrink">
                        <div class="p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-green-title">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                          </svg>
                        </div>
                      </div>
                      <div class="flex-1">
                        <h5 class="text-xs font-bold uppercase text-cyma-green-title">Venta</h5>
                        <h3 class="font-bold xl:text-xl lg:text-base">
                          {{ formatPrice(sales || 0) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/3">
                <div class="flex items-center p-4 bg-white border dark:bg-black-dark border-cyma-gray dark:border-black-dark rounded-lg">
                  <div v-if="!loadingSummary" class="flex items-center justify-center w-full">
                    <Spinner color="#d1d5db" />
                  </div>
                  <div v-else class="flex flex-row items-center">
                    <div class="pr-2">
                      <div class="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                        </svg>
                      </div>
                    </div>
                    <div class="flex-1">
                      <h5 class="text-xs font-bold text-gray-500 uppercase">
                        Total por cobrar
                      </h5>
                      <h3 class="font-bold xl:text-xl lg:text-base dark:text-white">
                        {{ formatPrice(sale_total_not_bill || 0) }}
                      </h3>
                      <p class="text-xs font-medium text-gray-500 dark:text-white">
                        Acumulado $ {{ formatPrice(accumulated_sale || 0) }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div v-if="!loading" class="flex items-center justify-center mt-6 h-96">
            <Spinner color="#d1d5db"/>
          </div>
          <div v-else-if="loading" class="px-0 py-2 mt-4 rounded-lg xl:px-4 dark:border-black-dark">
            <div class="flex flex-wrap items-center justify-between mb-4 bg-cyma-light-gray rounded-lg px-4 py-2 dark:bg-black-dark">
              <div @click.prevent="changeGraph('')" class="dark:text-white">
                <span>
                  <div v-if="loadingAverage" class="animate-pulse" >
                    <div class="w-20 h-3 mt-1.5 bg-gray-100 focus-within: dark:bg-gray-600"></div>
                    <div class="w-16 pr-1 h-3 mt-2 bg-gray-100 focus-within: dark:bg-gray-600"></div>
                  </div>
                  <template v-else>
                    <p class="text-sm text-gray-400">Crecimiento</p>
                    <div class="flex items-center gap-2" v-if="average < 0">
                      {{ average.toString().replace('.', ',') }} %
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fab3b6" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 4.5l15 15m0 0V8.25m0 11.25H8.25" />
                      </svg>
                    </div>

                    <div class="flex items-center gap-2" v-if="average > 0">
                      {{ average.toString().replace('.', ',') }} %
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#a9fdd2" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                      </svg>
                    </div>
                  </template>
                </span>
              </div>
              <ul class="flex gap-px">
                <!-- Resumen -->
                <li>
                  <button @click.prevent="changeGraph('')" class="flex p-2 text-sm focus:outline-none rounded-lg" :class="activeGraph === '' ? ' font-semibold bg-gray-100 dark:bg-background-dark dark:border-cyma-red-icon' : 'text-cyma-icon-gray dark:text-white hover:shadow-sm dark:hover:shadow-sm-dark'" data-cy="resume">
                    <span class="dark:text-white">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                      </svg>
                    </span>
                  </button>
                </li>
                <!-- Compras -->
                <li>
                  <button @click.prevent="changeGraph('buy')" class="flex p-2 text-sm focus:outline-none rounded-lg" :class="activeGraph === 'buy' ? ' font-semibold bg-gray-100 dark:bg-background-dark dark:border-cyma-red-icon' : 'text-cyma-icon-gray dark:text-white hover:shadow-sm dark:hover:shadow-sm-dark'" data-cy="buy">
                    <span class="text-cyma-red-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                      </svg>
                    </span>
                  </button>
                </li>
                <!-- Ventas -->
                <li>
                  <button @click.prevent="changeGraph('sale')" class="flex p-2 text-sm focus:outline-none rounded-lg" :class="activeGraph === 'sale' ? ' font-semibold bg-gray-100 dark:bg-background-dark dark:border-cyma-red-icon' : 'text-cyma-icon-gray dark:text-white hover:shadow-sm dark:hover:shadow-sm-dark'" data-cy="sale">
                    <span class="text-cyma-green-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                      </svg>
                    </span>
                  </button>
                </li>
                <!-- <li>
                  <button @click.prevent="changeGraph('tax')" class="flex p-2 text-sm focus:outline-none" :class="activeGraph === 'tax' ? ' font-semibold bg-gray-100 dark:bg-background-dark rounded-lg dark:border-cyma-red-icon' : 'text-cyma-icon-gray dark:text-white'">
                    <span class="text-yellow-300 dark:text-yellow-100">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                    </span>
                  </button>
                </li> -->
              </ul>
            </div>
            <div v-if="loadingAverage" class="h-96 grid place-items-center">
              <Spinner color="#d1d5db" />
            </div>
            <template v-else>
              <div v-if="activeGraph==''" id="grapBar" class="pr-0 lg:pr-2">
                <h2 class="text-lg text-gray-400">Resumen por mes</h2>
                <bar-chart :chart-data="datacollection"></bar-chart>
              </div>
              <div id="grapBarBuy">
                <div v-if="activeGraph == 'buy'">
                  <h2 class="text-lg text-gray-400">Compras</h2>
                  <bar-chart :chart-data="dataCollectionBuys"></bar-chart>
                </div>
              </div>
              <div id="grapBarSale">
                <div v-if="activeGraph == 'sale'" >
                  <h2 class="text-lg text-gray-400">Ventas</h2>
                  <bar-chart :chart-data="dataCollectionSales"></bar-chart>
                </div>
              </div>
              <div id="grapBarTax">
                <div v-if="activeGraph == 'tax'" >
                  <p class="text-lg text-gray-400">Impuestos</p>
                  <div  class="grid sm:grid-cols-2 xl:grid-cols-4 gap-4 md:gap-6 flex-wrap mb-4">
                    <!-- IVA sale -->
                    <div>
                      <div class="mt-4 flex items-end justify-between">
                        <div>
                          <h4 class="text-xl font-bold text-black dark:text-white">
                            ${{ formatPrice(saleIVA) }}
                          </h4>
                          <span class="text-xs text-gray-400 dark:text-gray-300">IVA de Ventas</span>
                        </div>
                      </div>
                    </div>
                    <!-- IVA buy -->
                    <div>
                      <div class="mt-4 flex items-end justify-between">
                        <div>
                          <h4 class="text-xl font-bold text-black dark:text-white">
                            ${{ formatPrice(buyIVA) }}
                          </h4>
                          <span class="text-xs text-gray-400 dark:text-gray-300">IVA de Compras</span>
                        </div>
                      </div>
                    </div>
                    <!-- IVA residual -->
                    <div>
                      <div class="mt-4 flex items-end justify-between gap-2">
                        <div>
                          <h4 class="text-xl font-bold text-black dark:text-white">
                            ${{ formatPrice(residualIVA) }}
                          </h4>
                          <span class="text-xs text-gray-400 dark:text-gray-300">IVA remanente</span>
                        </div>
                      </div>
                    </div>
                    <!-- IVA to pay -->
                    <div>
                      <div class="mt-4 flex items-end justify-between gap-2">
                        <div>
                          <h4 class="text-xl font-bold text-black dark:text-white">
                            ${{ formatPrice(IVAToPay) }}
                          </h4>
                          <span class="text-xs text-gray-400 dark:text-gray-300">Total IVA a Pagar</span>
                        </div>

                      </div>
                    </div>
                  </div>
                  <bar-chart :chart-data="dataCollectionTax"></bar-chart>
                </div>
              </div>
            </template>
          </div>
        </div>
        <modalAccount
          v-if="showModal"
          :movementId="movementId"
          :accounts="optionsAccount"
          @close="closeFunction"
          @getMovements="getMovements"
        ></modalAccount>
        <div class="flex flex-col pt-10 lg:pt-0 lg:w-4/12">
          <div class="rounded-2xl">
            <ul class="relative flex list-none flex-wrap rounded-lg bg-cyma-light-gray dark:bg-black-dark p-1">
              <div v-for="(tab, tabIndex) in tabs" :key="'tabs' + tabIndex  " class="flex-1">
                <li
                  class="text-center cursor-pointer xl:px-4 py-1 xl:py-2 whitespace-nowrap flex w-full items-center justify-center dark:text-white rounded-lg"
                  :class="activeTab===tabIndex ? 'dark:text-white dark:bg-background-dark-disabled font-semibold bg-white border-0 px-0 py-1 transition-all ease-in-out dark:border-white' : 'text-cyma-icon-gray dark:text-white hover:shadow-sm dark:hover:shadow-sm-dark'" @click.prevent="tabRedirect(tabIndex)"
                  v-text="tab"
                >
                </li>
              </div>
              <span class="absolute w-full h-1 -bottom-px"></span>
            </ul>
            <div class="w-full bg-cyma-light-gray dark:bg-black-dark h-fit mt-6 rounded-lg">
              <div v-if="activeTab===0">
                <div v-if="!loading" class="flex justify-center mt-10" >
                  <Spinner color="#d1d5db" />
                </div>
                <div v-else>
                  <div v-if="total_items > 0" >
                    <div class="col-span-6 lg:col-span-3">
                      <div class="h-full py-6 xl:px-4 lg:px-4">
                        <div class="flex flex-row items-center">
                          <div class="flex-shrink">
                            <div></div>
                          </div>
                          <div class="flex-1">
                            <h2 class="text-lg px-4 dark:text-white">
                              {{ total_items }} Movimientos sin categorizar
                            </h2>
                            <h3 class="text-xs px-4 text-gray-400 dark:text-gray-400 font-normal py-2">
                              Automatiza la clasificación para que esta se haga por vez única, así podrás tener el balance actualizado.
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="xl:px-4 lg:px-4 movements-box grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-3">
                      <div v-for="(buy, i) in movements" :key="'buy' + i">
                        <div class="h-full grid grid-cols-10 gap-y-2 justify-around px-2 xl:px-4 py-4 mb-3 bg-white bg-opacity-90 border border-opacity-5 border-stroke-dark dark:bg-background-dark-disabled rounded-2xl">
                          <div class="col-span-7 group">
                            <p class="font-bold uppercase text-md dark:text-white">
                              {{ buy.business_name }}
                            </p>
                            <div v-if="buy.recommended">
                              <span class="text-gray-400 text-xs font-medium italic rounded-full">
                                {{ buy.recommended_name }}
                              </span>
                            </div>
                          </div>
                          <div class="col-span-3 font-semibold text-md text-right dark:text-white">
                            $ {{ formatPrice(buy.total || 0) }}
                          </div>

                          <div class="col-span-7 flex text-sm text-gray-500 w-full">
                            <div>
                              <div class="flex items-center gap-2 text-sm text-gray-400">
                                {{ buy.folio }} / {{ formatDocType(buy.doc_type) }}
                                <toolTips v-if="buy.vendor_id" :id="buy.vendor_id" :buy_id="buy.id"></toolTips>
                              </div>
                              <div class="text-sm text-gray-400">
                                {{ formatSimpleDate(buy.period) }}
                              </div>
                            </div>
                          </div>
                          <div class="col-span-3 flex justify-center lg:justify-end items-center">
                            <button class="p-2 px-4 font-semibold transition duration-300 ease-in-out rounded-full bg-gray-50 hover:bg-gray-100 text-cyma-purple active:bg-cyma-dark-gray focus:outline-none dark:bg-cian-500 dark:border-cian-dark dark:hover:bg-cian-600 dark:text-white border border-gray-100" @click="selectAccount(buy.id)">
                              Clasificar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-6" v-else>
                    <div class="h-full py-4">
                      <p class="px-8 text-lg font-semibold text-gray-500 dark:text-white">¡Felicitaciones! <br>Tienes todos tus movimientos clasificados.</p>
                      <div class="flex px-8 pt-4 items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#77dfaa" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        <p class="text-gray-500">Olvídate de transcribir millones de movimientos mes a mes, reduciendo el margen de error.</p>
                      </div>

                      <div class="flex px-8 pt-4 items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#77dfaa" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        <p class="text-gray-500">Automatiza la clasificación para que esta se haga por vez única, así podrás tener el control total de tus movimientos.</p>
                      </div>

                      <div class="flex px-8 pt-4 items-center gap-4">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#77dfaa" class="w-6 h-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                        </svg>
                        <p class="text-gray-500">Puedes tomar mejores decisiones con tus datos que se encontrarán siempre actualizados.</p>
                      </div>
                      <div class="my-4">
                        <img src="@/assets/images/success.png" class="w-2/3 m-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="activeTab===1" class="pt-6">
                <h2 class="text-lg px-8 mb-5 dark:text-white">Resumen de activos</h2>
                <div class="flex justify-center mt-10" v-if="!loadingGraph">
                  <Spinner color="#d1d5db" />
                </div>
                <div v-else>
                  <div v-if="activeTab===1" class="px-16" id="grapPie">
                    <pie-chart :chart-data="charDataAsset"></pie-chart>
                  </div>
                  <div class="mt-4 accounts-box">
                    <details v-for="(value, i) in widgetAssets" :key="'Activos' + i" class="details" v-on:click="getDataAccountMovement(value.name, widgetAssets)">
                      <summary class="details__summary flex items-center gap-4 hover:text-gray-800">
                        <div class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-lg">
                          <Icons :value="value.name" :bg="i" type="activos"/>
                        </div>
                        <div class="flex flex-col items-start">
                          <span class="font-bold uppercase dark:text-white">{{ value.name }}</span>
                          <span class="text-gray-400">{{ formatPrice(countAsset[value.name] || 0) }} movimientos</span>
                        </div>
                        <div class="self-start ml-auto font-bold dark:text-white">$ {{ formatPrice(value.mount || 0) }}</div>
                      </summary>
                      <div class="flex justify-center" v-if="value.loading">
                        <Spinner color="#d1d5db" />
                      </div>
                      <table v-else class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th class="p-2">Cuenta</th>
                            <th class="p-2">Debe</th>
                            <th class="p-2">Haber</th>
                            <!-- <th>Glosa</th> -->
                          </tr>
                        </thead>
                        <tr v-for="(row, i) in value.balance" :key="i" class="text-xs">
                          <td class="uppercase p-2 w-3/5">
                            {{ row.movement.business_name }} <span class="text-gray-400">{{ row.movement.folio }}</span>
                            <p>
                              <span class="tracking-normal text-gray-500">
                                {{ formatDocType(row.movement.doc_type) }}
                              </span>
                              {{ formatSimpleDate(row.period) }}
                              <span class="font-medium text-gray-400">{{ formatTypeMovement(row.type_movement) }}</span>
                            </p>
                          </td>
                          <td class="w-1/5 p-2">$ {{ formatPrice(row.debit) }}</td>
                          <td class="w-1/5 p-2">$ {{ formatPrice(row.credit) }}</td>
                        </tr>
                      </table>
                    </details>
                  </div>
                </div>
              </div>
              <div v-if="activeTab===2" class="pt-6">
                <h2 class="text-lg px-8 mb-5 dark:text-white">Resumen de Gastos</h2>
                <div class="flex justify-center mt-10" v-if="!loadingGraph">
                  <Spinner color="#d1d5db" />
                </div>
                <div v-else>
                  <div v-if="activeTab===2" class="px-16" id="grapPieTwo">
                    <pie-chart :chart-data="charData"></pie-chart>
                  </div>
                  <div class="mt-4 accounts-box">
                    <details v-for="(value, i) in widgetLostResult" :key="'Gastos' + i" class="details p-4" v-on:click="getDataAccountMovement(value.name, widgetLostResult)">
                      <summary class="details__summary flex items-center gap-4 hover:text-gray-800">
                        <div class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-lg">
                          <Icons :value="value.name" :bg="i" type="gastos"/>
                        </div>
                        <div class="flex flex-col items-start">
                          <span class="font-bold uppercase dark:text-white">{{ value.name }}</span>
                          <span class="text-gray-500">{{ formatPrice(countLostResult[value.name] || 0) }} movimientos</span>
                        </div>
                        <div class="self-start ml-auto font-bold dark:text-white">$ {{ formatPrice(value.mount || 0) }}</div>
                      </summary>
                      <div class="flex justify-center" v-if="value.loading">
                        <Spinner color="#d1d5db" />
                      </div>
                      <table v-else class="w-full text-sm text-left text-gray-500 dark:text-gray-400 mt-4">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                          <tr>
                            <th class="p-2">Cuenta</th>
                            <th class="p-2">Debe</th>
                            <th class="p-2">Haber</th>
                            <!-- <th>Glosa</th> -->
                          </tr>
                        </thead>
                        <tr v-for="(row, i) in value.balance" :key="i" class="text-xs">
                          <td class="uppercase p-2 w-3/5">
                            {{ row.movement.business_name }} <span class="text-gray-400">{{ row.movement.folio }}</span>
                            <p>
                              <span class="tracking-normal text-gray-500">
                                {{ formatDocType(row.movement.doc_type) }}
                              </span>
                              {{ formatSimpleDate(row.period) }}
                              <span class="font-medium text-gray-400">{{ formatTypeMovement(row.type_movement) }}</span>
                            </p>
                          </td>
                          <td class="w-1/5 p-2">$ {{ formatPrice(row.debit) }}</td>
                          <td class="w-1/5 p-2">$ {{ formatPrice(row.credit) }}</td>
                        </tr>
                      </table>
                    </details>
                  </div>
                </div>
              </div>
              <div v-if="activeTab===3" class="py-6">
                <h2 class="text-lg px-8 mb-5 dark:text-white">Conciliación Bancaria</h2>
                <div class="w-full" v-if="!banks.length > 0">
                  <div
                    class="flex flex-col lg:flex-col-reverse w-full px-6 py-6 gap-2 mb-4 max-w-sm mx-auto bg-cyma-purple rounded-lg"
                  >
                    <div class="flex justify-end w-1/2 lg:w-2/5 ml-auto">
                      <img src="@/assets/images/sync.png" alt="sync" class="pointer-events-none select-none" />
                    </div>
                    <div class="flex flex-col justify-between w-2/3">
                      <p class="mb-4 text-xl text-white font-bold">
                        Sincroniza tu banco de forma segura
                      </p>
                      <button
                        @click.prevent="openFintoc()"
                        class="flex self-start px-5 py-2 font-semibold transition duration-150 ease-in-out bg-gray-200 rounded-full text-cyma-purple active:bg-gray-300 focus:outline-none"
                      >
                        Conectar Banco
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <Cards class="pb-1" :banks="banks" @afterChange="indexBank = $event"/>
                  <div class="px-4 pt-4 accounts-box">
                    <!-- <div v-for="bank in banks" :key="bank.id"> -->
                    <div>
                      <span
                        class="px-6 dark:text-white"
                        v-if="banks[indexBank].movements_count > 0"
                      >
                        {{ banks[indexBank].movements_count }} movimientos bancarios sin conciliar
                      </span>
                      <div v-for="movement in banks[indexBank].movements" :key="movement.id">
                        <div class="flex justify-around px-2 py-4 mb-3 mt-2 bg-cyma-gray-card-light  dark:bg-black-dark dark:hover:bg-background-dark-disabled rounded-lg">
                          <div class="w-8/12 group">
                            <span class="font-bold uppercase text-md dark:text-white">
                              <div>
                                <div v-if="movement && movement.transfer_account_holder_name">
                                  <p>{{ movement.transfer_account_holder_name }}</p>
                                  <p class="flex items-center gap-2 text-sm tracking-normal text-gray-400" v-if="movement && movement.transfer_account_holder_id">{{ formatRut(movement.transfer_account_holder_id) }}</p>
                                </div>
                                <div v-else-if="movement && movement.recipient_account.hasOwnProperty('holder_name')">
                                  <p>{{ movement.recipient_account.holder_name }}</p>
                                  <p
                                    class="flex items-center gap-2 text-sm tracking-normal text-gray-400"
                                    v-if="movement.recipient_account.holder_id"
                                  >
                                    {{ formatRut(movement.recipient_account.holder_id) }}
                                  </p>
                                </div>
                                <div v-else>
                                  {{ movement.description }}
                                </div>
                              </div>
                            </span>
                            <div class="flex text-sm text-gray-500">
                              <div class="w-2/3">
                                <div class="text-sm tracking-normal text-gray-400">
                                  {{ formatSimpleDate(movement.date) }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="flex flex-col items-center content-between gap-6">
                            <div class="font-semibold text-right text-md dark:text-white">
                              <p v-if="movement.amount > 0" class="flex justify-between items-center gap-2">
                                $ {{ formatPrice(Math.abs(movement.amount)) }}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-cyma-green-title">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                                </svg>
                              </p>
                              <p v-if="movement.amount < 0" class="flex justify-between items-center gap-2">
                                $ {{ formatPrice(Math.abs(movement.amount)) }}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-cyma-red-title">
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                                </svg>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  lg:border-l lg:border-gray-200 lg:pl-6 -->
          <!-- <div v-if="!loading" class="flex items-center justify-center h-96">
            <Spinner color="#d1d5db" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h1 class="p-20 mx-20 text-center text-gray-500">
      En estos momentos nuestro sistema de contabilidad esta obteniendo la
      información del<b> Servicio de Impuesto Interno.</b>
    </h1>
  </div>
</template>
<script>
import { formatDocType, formatRut, formatSimpleDate } from "@/utils/formatters.js";
import toolTips from './toolTips.vue'
import DatePicker from "vue2-datepicker";
import BarChart from "./BarChart";
import modalAccount from "@/components/forms/modalAccount";
import Icons from "./Icons";
import PieChart from "./PieChart.vue";
import Spinner from '@/components/Spinner.vue';

import Cards from '@/components/etc/creditCards.vue';

import ModalRequest from '@/components/forms/modalRequest.vue';

export default {
  name: "dashboard",
  components: {
    DatePicker,
    BarChart,
    modalAccount,
    PieChart,
    toolTips,
    Icons,
    Spinner,
    Cards,
  },
  props: {},
  data() {
    return {
      indexBank: 0,
      banks: [],
      activeTab: 0,
      tabs: [
        "Movimientos",
        "Activos",
        "Gastos",
        "Bancos"
      ],
      countAsset: [],
      countLostResult: [],
      widgetAssets: [],
      widgetLostResult: [],
      balances: [],
      user: null,
      movementId: null,
      showModal: false,
      showCategoriesSection: false,
      optionsAccount: [],
      company: null,
      buy: null,
      sales: null,
      movements: [],
      total_items: 0,
      labels: [],
      data_graph_sales: [],
      data_graph_buys: [],
      data_graph_sales_tax: [],
      data_graph_buys_tax: [],
      datacollection: null,
      dataCollectionBuys: null,
      dataCollectionSales: null,
      dataCollectionTax: null,
      sale_total_bill: null,
      sale_total_not_bill: null,
      buy_total_paid: null,
      accumulated_buy: null,
      accumulated_sale: null,
      buy_total_not_paid: null,
      percentage: 0,
      average: 0,
      utility: 0,
      losses: 0,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      charData: {},
      charDataAsset: {},
      loading: false,
      loadingSummary: false,
      loadingGraph: false,
      loadingAverage: true,
      loadingAcumulated: false,
      loadingMovementsUtility: false,
      activeGraph: '',
      showModalRequest: false,
      movementsBank: [],
    };
  },
  created() {
    this.user = this.$auth.user();
    this.getCompany();
    this.getAccounts();
    this.getMovements();
    this.fillData();
    this.getBankBills();
  },
  mounted() {
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    date_type: {
      get(){
        return this.$store.state.buy_date_type;
      },
      set(newValue){
        this.$store.commit('buyDateType', newValue);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    company_id() {
      this.loading = false,
      this.loadingSummary = false,
      this.loadingGraph = false,
      this.loadingAcumulated = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    },
    refresh() {
      this.loading = false,
      this.loadingSummary = false,
      this.loadingGraph = false,
      this.loadingAcumulated = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    },
    date_type() {
      this.loading = false,
      this.loadingSummary = false,
      this.loadingGraph = false,
      this.loadingAcumulated = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    }
  },
  methods: {
    formatDocType,
    formatRut,
    formatSimpleDate,
    cssAccount(value){
      if(value === true){
        return ''
      } else {
        return 'text-gray-300'
      }

    },
    formatDateS(date) {
      const months = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
      ];
      const dateFormatted = new Date(date);
      const month = months[dateFormatted.getMonth()];
      const year = dateFormatted.getFullYear();
      return `${month} ${year}`;
    },
    calculateF29Length(form29){
      return "w-1/" + form29.length
    },
    getDataAccountMovement(value, widget){
      let app = this;
      this.axios.get("/daily-book", {
        params: {
          company_id: app.company_id,
          account_name: value,
          from: app.dateRange[0],
          to: app.dateRange[1],
        }
      },
      ).then((response) => {
        let account_movement = widget.find((asset) => asset.name == value)
        account_movement.balance = response.data
        account_movement.loading = false
      });
    },
    changeGraph(graph) {
      this.activeGraph = graph;
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    selectLastsMonths(emit, month) {
      const dateDay = new Date()
      const getFirstDay = this.getFirstDayOfMonth(dateDay.getFullYear(), dateDay.getMonth())
      const end =  this.getFirstDayOfMonth(dateDay.getFullYear(), dateDay.getMonth())
      const start = getFirstDay
      start.setMonth(start.getMonth() - month)

      const date = [start, end]
      emit(date)
    },
    openFintoc() {
      let cur = this;
      let user_id = this.$auth.user().id;
      this.widget = Fintoc.create({
        holderType: "business",
        product: "movements",
        publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
        webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${cur.company_id}_${user_id}`,
      });
      this.widget.open();
    },
    getBankBills() {
      let app = this;
      this.axios
        .get("/bank_bills", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page
          }
        }).then((response) => {
          app.banks = response.data.data;
          app.loading = true;
        });
    },
    validationDataGraph(){
      if(this.data_graph_sales && this.data_graph_buys){
        return this.data_graph_buys.reduce((a,b) => a + b, 0) !== 0 && this.data_graph_sales.reduce((a,b) => a + b, 0) !== 0
      }
      return false
    },
    closeFunction() {
      this.showModal = false;
    },
    closeCategoriesSection() {
      this.showCategoriesSection = false;
    },
    selectAccount(id) {
      this.showModal = true;
      this.movementId = id;
    },
    updateValue(e, id, i) {
      if (e == null) {
        this.$refs.select[i].$refs.search.style.display = "block";
      } else {
        this.$refs.select[i].$refs.search.style.display = "none";
        this.axios
          .patch(`/movements/${id}`, {
            account_neto_id: e,
          })
          .then(() => {
            console.log("success");
            this.movements = [];
            this.getMovements();
          })
          .catch(() => {
            console.log("wrong!");
          });
      }
    },
    formatTypeMovement(name){
      switch (name) {
        case 'accounting':
        return 'Comprobantes'
        case 'sale':
        return  'Ventas'
        case 'buy':
        return 'Compras'
      }
    },
    fillData() {
      const app = this;
      this.datacollection = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Ventas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#2554FF",
            data: app.data_graph_sales,
          },
          {
            label: "Compras",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buys,
          }
        ],
      };
      this.dataCollectionSales = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Pagadas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#2554FF",
            data: app.data_graph_sale_total_bill,
          },
          {
            label: "No Pagadas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#e5e7e9",
            borderColor: "#e5e7e9",
            pointBorderColor: "#e5e7e9",
            data: app.data_graph_sale_total_not_bill,
          }
        ],
      };

      this.dataCollectionBuys = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Pagadas",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buy_total_paid,
          },
          {
            label: "No Pagadas",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#e5e7e9",
            borderColor: "#e5e7e9",
            pointBorderColor: "#e5e7e9",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buy_total_not_paid,
          }
        ],
      };

      this.dataCollectionTax = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "IVA Debito",
            borderWidth: 1,
            stack: "venta",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#aafdd2",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_sales_tax,
          },
          {
            label: "IVA Credito",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buys_tax,
          }
        ],
      };
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getCompany() {
      this.axios
        .get("companies/" + this.company_id)
        .then((response) => {
          this.company = response.data;
        })
        .catch((e) => console.log(e));
    },
    getAccounts() {
      this.axios.get("/select_dashboard", {
        params: {
          company_id: this.company_id
        },
      }).then((response) => {
        this.optionsAccount = response.data;
      });
    },
    tabRedirect(index) {
      this.activeTab = index;
      if(index === 1 || index === 2) {
        this.getMovementsGraph();
      }
    },
    getMovements() {
      this.getMovementsData();
      this.getMovementsAccumulated();
      this.getMovementsSymmary();
      this.getMovementsUtility();
      // Summary of assets and expenses
      if(this.activeTab === 1 || this.activeTab === 2) {
        this.getMovementsGraph();
      }
    },
    getMovementsUtility(){
      this.loadingMovementsUtility = true;
      this.axios
        .get("/stats-utility", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
            date_type: this.date_type
          },
        })
        .then((response) => {
          this.utility = response.data.utility;
          this.losses = response.data.losses;
          this.loadingMovementsUtility = false;
        });
    },
    getMovementsData() {
      this.axios
        .get("/stats", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
            date_type: this.date_type
          },
        })
        .then((response) => {
          this.movements = response.data.without_account;
          this.total_items = response.data.total_items;
          this.labels = response.data.labels;
          this.data_graph_sales = response.data.data_graph_sales;
          this.data_graph_buys = response.data.data_graph_buys;
          this.data_graph_sale_total_bill = response.data.data_graph_sale_total_bill;
          this.data_graph_sale_total_not_bill = response.data.data_graph_sale_total_not_bill;
          this.data_graph_buy_total_paid = response.data.data_graph_buy_total_paid;
          this.data_graph_buy_total_not_paid = response.data.data_graph_buy_total_not_paid;
          this.percentage = response.data.percentage;
          this.average = response.data.average;
          this.charData = response.data.char_data;
          this.fillData();
          this.loading = true;
        })
        .catch((e) => console.log(e))
        .finally(() => {
          this.loadingAverage = false
        })
    },
    getMovementsGraph() {
      this.axios
        .get("/stats-graph", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
            date_type: this.date_type
          },
        })
        .then((response) => {
          this.countLostResult = response.data.count_lost_result;
          this.countAsset = response.data.count_asset;

          if(response.data.data_lost_result !== undefined){
            this.widgetLostResult = Object.entries(response.data.data_lost_result);
            this.widgetLostResult = this.widgetLostResult.map((e)=>{
              return { mount: e[1], name: e[0], balance: [], loading: true}
            })
          }
          if(response.data.data_asset !== undefined){
            this.widgetAssets = Object.entries(response.data.data_asset);
            this.widgetAssets = this.widgetAssets.map((e)=>{
              return { mount: e[1], name: e[0], balance: [], loading: true}
            })
          }
          this.charData = {
            labels: response.data.label_char_data,
            datasets: [
            {
              label: "Gastos",
              data: response.data.values_char_data,
              backgroundColor: ['#E16C74', '#e78990', '#eda7ac', '#f3c4c7', '#f9e2e3']
            },
            ],
          }
          this.charDataAsset = {
            labels: response.data.label_asset_data,
            datasets: [
            {
              label: "Activos",
              data: response.data.values_asset_data,
              backgroundColor: ["#42f09f", "#68f3b2", "#8ef6c5", "#b3f9d9", "#d9fcec"],
            },
            ],
          }
          this.loadingGraph = true;
        });
    },
    getMovementsSymmary() {
      // reset zero when this changed
      let app = this;
      app.sale_total_bill = 0;
      app.sale_total_not_bill = 0;
      app.buy_total_paid = 0;
      app.buy_total_not_paid = 0;

      this.axios
        .get("/stats-summary", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            date_type: app.date_type
          },
        })
        .then((response) => {
          app.buy = response.data.buy_total;
          app.sales = response.data.sale_total;
          app.sale_total_bill = response.data.sale_total_bill;
          app.sale_total_not_bill = response.data.sale_total_not_bill;
          app.buy_total_paid = response.data.buy_total_paid;
          app.buy_total_not_paid = response.data.buy_total_not_paid;
          app.loadingSummary = true;
        });
    },
    getMovementsAccumulated() {
      // reset zero when this changed
      let app = this;
      app.accumulated_buy = 0;
      app.accumulated_sale = 0;

      this.axios
        .get("/stats-accumulated-total", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1]
          },
        })
        .then((response) => {
          app.accumulated_buy = response.data.accumulated_buy;
          app.accumulated_sale = response.data.accumulated_sale;
          app.loadingAcumulated = true
        });
    },
    randomColors(length) {
      const colorsArr = []
      for (let i = 0; i < length; i++) {
        colorsArr.push("#" + Math.floor(Math.random() * 16777215).toString(16))
      }
      return colorsArr
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: green;
}
.red {
  color: red;
}
.box .selectDashboard {
  width: 250%;
}
.box .vs__dropdown-toggle {
  border: none;
  border-bottom: 1px solid #f7f9ff;
}
.movements-box {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.accounts-accounting {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.accounts-box {
  max-height: 40vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.custom-columns{
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}
#grapPie canvas{
  height: 200px !important;
}
#grapPieTwo canvas{
  height: 200px !important;
}
.movements-box::-webkit-scrollbar, .accounts-box::-webkit-scrollbar, .accounts-accounting::-webkit-scrollbar  {
  width: 5px;
}

    /* Track */
.movements-box::-webkit-scrollbar-track, .accounts-box::-webkit-scrollbar-track, .accounts-accounting::-webkit-scrollbar-track  {
  /* box-shadow: inset 0 0 2px grey; */
  border-radius: 10px;
}

    /* Handle */
.movements-box::-webkit-scrollbar-thumb, .accounts-box::-webkit-scrollbar-thumb, .accounts-accounting::-webkit-scrollbar-thumb {
  background: transparent;/*rgb(7, 7, 7);*/
  border-radius: 10px;
}

    /* Handle on hover */
.movements-box:hover::-webkit-scrollbar-thumb, .accounts-box:hover::-webkit-scrollbar-thumb, .accounts-accounting:hover::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-700;
}
</style>
