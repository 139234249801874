<template>
  <div
      class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      v-if="loading"
    >
      <Loader />
    </div>
  <div v-else class="">
    <transition name="modal">
      <div class="flex modal-mask md:justify-center md:items-center">
        <div class="w-screen h-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="w-screen h-screen px-4 py-5 overflow-auto bg-white dark:bg-background-dark md:h-auto md:max-h-screen md:w-full md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="py-8 text-4xl font-semibold text-grey-darker dark:text-white"
                  v-if="action === 'create'"
                >
                  Agregar Crédito
                </h1>
                <h1
                  class="py-8 text-4xl font-semibold text-grey-darker dark:text-white"
                  v-else
                >
                  Actualizar Crédito
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a
                  class="text-6xl dark:hover:text-cian-dark"
                  @click.prevent="$emit('close')"
                >
                  &times;
                </a>
              </div>
            </div>
            <div class="">
              <slot name="body">
                <form
                  class="grid grid-cols-1 gap-2 md:grid-cols-1 lg:grid-cols-1"
                >
                  <div>
                    <h3
                      class="my-2 text-sm font-semibold uppercase dark:text-gray-500"
                    >
                      Crédito
                    </h3>
                    <div class="flex flex-col gap-5">
                      <div class="flex flex-col">
                        <label class="label-cymasuite"
                          >Numero de Operación</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="obj.operation_number"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-3"
                  >
                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Descripción </label>
                      <input
                        class="input-text"
                        v-model="obj.name"
                        type="text"
                      />
                    </div>
                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Divisa </label>
                      <v-select
                        class="style-chooser custom-border"
                        :options="typeCurrencies"
                        :clearable="false"
                        :reduce="(currency) => currency.value"
                        v-model="obj.type_currency"
                        label="name"
                      ></v-select>
                    </div>
                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Número de Cuotas </label>
                      <v-number-input
                        size="large"
                        v-model="obj.number_of_payments"
                        @input="updateDate"
                      ></v-number-input>
                    </div>
                    <div class="flex flex-col">
                      <label class="label-cymasuite">Inicio </label>
                      <!-- :class="[{'error': $v.from.$error,  }, 'border dark:bg-black-dark dark:border-background-dark']" -->
                      <date-picker
                        type="date"
                        format='DD-MM-YYYY'
                        input-class="xmx-input"
                        :style="{
                          width: '100%',
                          'padding-left': '0.55rem',
                          'padding-right': '0.55rem',
                          'padding-top': '0.80rem',
                          'padding-bottom': '0.80rem',
                          'border-radius': '0.5rem',
                        }"
                        class="border dark:bg-black-dark dark:border-background-dark"
                        v-model="obj.issue_date"
                        placeholder="Selecciona una Fecha"
                      ></date-picker>
                    </div>
                    <div class="flex flex-col">
                      <label class="label-cymasuite">Término </label>
                      <date-picker
                        type="date"
                        format='DD-MM-YYYY'
                        input-class="xmx-input"
                        disabled
                        :style="{
                          width: '100%',
                          'border-radius': '0.5rem',
                          'padding-left': '0.55rem',
                          'padding-right': '0.55rem',
                          'padding-top': '0.55rem',
                          'padding-bottom': '0.55rem',
                        }"
                        v-model="obj.end_date"
                        placeholder="Selecciona una Fecha"
                      ></date-picker>
                    </div>
                  </div>

                  <h3
                    class="my-2 text-sm font-semibold uppercase dark:text-gray-500"
                  >
                    Montos
                  </h3>
                  <div
                    class="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-3"
                  >
                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Monto Base </label>
                      <currency-input
                        class="input-text"
                        currency="CLP"
                        locale="es-CL"
                        v-model="obj.base_amount"
                      />
                    </div>

                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Interes Anual ( Porcentaje % ) </label>
                      <div class="flex items-center gap-2">
                        <input
                          class="input-text"
                          type="text"
                          v-model="obj.annual_interest_rate"
                        />
                      </div>
                    </div>

                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Monto Total </label>
                      <currency-input
                        class="input-text"
                        currency="CLP"
                        locale="es-CL"
                        disabled
                        v-model="obj.total_amount"
                      />
                    </div>

                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Monto del Interes </label>
                      <currency-input
                        class="input-text"
                        currency="CLP"
                        locale="es-CL"
                        v-model="obj.interest_amount"
                        disabled
                      />
                    </div>

                    <div class="flex flex-col">
                      <label class="label-cymasuite"> Monto de cuota </label>
                      <currency-input
                        class="input-text"
                        currency="CLP"
                        locale="es-CL"
                        v-model="obj.payment_value"
                        disabled
                      />
                    </div>
                  </div>
                  <div>
                    <!-- <button
                      v-if="!loading"
                      class="flex justify-center gap-2 dark:bg-cian-dark dark:hover:bg-cian-600 dark:text-white items-center bg-gray-200 hover:bg-gray-300 rounded-full p-2 px-4"
                      @click.prevent="generatePayments()"
                    >
                      Generar Cuotas
                    </button>
                    <div v-else class="w-full flex justify-center">
                      <Spinner2 customClass="h-6 w-6" />
                    </div> -->
                  </div>
                  <div v-if="paymentsData.length > 0">
                    <h3
                      class="my-2 text-sm font-semibold uppercase dark:text-gray-500"
                    >
                      Cuotas
                    </h3>
                    <div class="grid grid-cols-5 gap-2">
                      <div class="">
                        <label class="label-cymasuite">
                          Fecha de Registro
                        </label>
                      </div>
                      <div class="">
                        <label class="label-cymasuite">
                          Fecha de Vencimiento
                        </label>
                      </div>
                      <div class="">
                        <label class="label-cymasuite"> Capital </label>
                      </div>
                      <div class="">
                        <label class="label-cymasuite"> Ínteres </label>
                      </div>
                      <div class="">
                        <label class="label-cymasuite"> Valor Cuota </label>
                      </div>
                    </div>
                    <div
                    v-for="(payment, i) in $v.paymentsData.$each.$iter"
                    :key="i"
                    class="grid grid-cols-5 gap-2 mt-2"
                    >
                      <div class="">
                        <date-picker
                          type="date"
                          format='DD-MM-YYYY'
                          input-class="xmx-input"
                          :style="{
                            width: '100%',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.80rem',
                            'padding-bottom': '0.80rem',
                            'border-radius': '0.5rem',
                          }"
                          v-model="payment.credit_init_date.$model"
                          placeholder="Selecciona una Fecha"
                        ></date-picker>
                      </div>
                      <div class="">
                        <date-picker
                          type="date"
                          format='DD-MM-YYYY'
                          input-class="xmx-input"
                          :style="{
                            width: '100%',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.80rem',
                            'padding-bottom': '0.80rem',
                            'border-radius': '0.5rem',
                          }"
                          v-model="payment.expiration_date.$model"
                          placeholder="Selecciona una Fecha"
                        ></date-picker>
                      </div>
                      <div class="">
                        <currency-input
                          class="input-text"
                          v-model="payment.capital.$model"
                          currency="CLP"
                          locale="es-CL"
                        />
                      </div>
                      <div class="">
                        <currency-input
                          class="input-text"
                          v-model="payment.interest.$model"
                          currency="CLP"
                          locale="es-CL"
                        />
                      </div>
                      <div class="">
                        <currency-input
                          class="input-text"
                          v-model="payment.total.$model"
                          currency="CLP"
                          locale="es-CL"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </slot>
            </div>
            <div class="flex justify-center pb-3 pr-5 modal-footer">
              <div class="w-5/5 mt-8" v-if="paymentsData.length > 0">
                <button
                  v-if="!loading"
                  class="p-2 px-4 font-bold text-white bg-black border rounded-full hover:bg-white hover:text-black hover:border-black dark:bg-cian-dark dark:border-cian-dark"
                  @click.prevent="submit"
                >
                  Guardar Información
                </button>
                <div v-else class="w-full flex justify-center">
                  <Spinner2 customClass="h-6 w-6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import Spinner2 from "@/components/Spinner2.vue";
import { required } from "vuelidate/lib/validators";
import Loader from "@/components/loader.vue"
import DatePicker from "vue2-datepicker";
import { CurrencyInput } from "vue-currency-input";


export default {
  name: "Add",
  props: {
    id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      error: "",
      loading: true,
      rut_error: null,
      branch_offices: [],
      cost_centers: [],
      accounts: [],
      rutW: null,
      rut_loading: false,
      typeCurrencies: [{ name: "Peso Chileno", value: "cl" }],
      paymentsData: [],
      obj: {
        name: null,
        operation_number: null,
        type_currency: null,
        number_of_payments: 1,
        issue_date: "",
        end_date: "",
        total_amount: null,
        annual_interest_rate: null,
        company_id: null,
        user_id: null,
        base_amount: null,
        interest_amount: null,
        payment_value: null,
        type_movement: 'type_buy',
        movements_attributes: [],
        category_movement: 18,
        business_name: null
      },
    };
  },
  components: {
    Loader,
    Spinner2,
    DatePicker,
    CurrencyInput
},
  watch: {
    "paymentsData":{
      handler: function (val) {
        val.forEach(payment => {
          return payment.total = payment.capital + payment.interest
        });
      },
      deep: true,
    },
    "obj.issue_date": function (newDate) {
      if(this.action != 'update'){
        const date = new Date(newDate);
        this.setEndDate(date);
        this.generatePayments()
      }
    },
    "obj.base_amount": function () {
      if(this.action != 'update'){
        this.calculateTotalAmount();
      }
    },
    "obj.annual_interest_rate": function () {
      if(this.action != 'update'){
        this.calculateTotalAmount();
      }
    }
  },
  created() {
    if(this.action == 'update'){
      this.getCredit()
    } else {
      this.loading = false
    }
  },
  mounted() {},
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
    required,
    paymentsData: {
      required,
      $each: {
        total: {
          required,
        },
        expiration_date: {
        },
        credit_init_date: {
        },
        credit_state: {},
        company_id: {},
        user_id: {},
        doc_type: {},
        folio: {},
        capital: {},
        interest: {},
      },
    },
  },
  methods: {
    updateDate(number){
      const parsedValue = parseInt(number.target.value);
      if (!isNaN(parsedValue)) {
        this.obj.number_of_payments = parsedValue;
        this.obj.end_date = new Date(this.obj.issue_date);
        this.obj.end_date.setMonth(this.obj.end_date.getMonth() + this.obj.number_of_payments);
      } else {
        this.obj.number_of_payments = 0;
        this.obj.end_date = new Date(this.obj.issue_date);
        this.obj.end_date.setMonth(this.obj.end_date.getMonth());
      }
      this.generatePayments()
    },
    calculateTotalAmount() {
      if(this.obj.annual_interest_rate) {
        this.obj.interest_amount = parseInt(this.obj.base_amount) * (parseInt(this.obj.annual_interest_rate) / 100);
        this.obj.total_amount = parseInt(this.obj.base_amount) + parseInt(this.obj.interest_amount);
        this.obj.payment_value = this.obj.total_amount / this.obj.number_of_payments;
      } else {
        this.obj.interest_amount = 0;
        this.obj.total_amount = parseInt(this.obj.base_amount) + parseInt(this.obj.interest_amount);
        this.obj.payment_value = this.obj.total_amount / this.obj.number_of_payments;
      }
      this.generatePayments()
    },
    setEndDate(date) {
      this.obj.end_date = new Date(
        date.setMonth(date.getMonth() + this.obj.number_of_payments)
      );
    },
    getCredit() {
      this.axios
        .get("credits/" + this.id)
        .then((response) => {
          this.obj = response.data;
          this.obj.issue_date = new Date(response.data.issue_date)
          this.obj.end_date = new Date(response.data.end_date)
          this.paymentsData = response.data.movements.map((element) => {
            element.credit_init_date = new Date(element.credit_init_date)
            element.expiration_date = new Date(element.expiration_date)
            return element
          })
          this.loading = false
        })
        .catch((e) => console.log(e));
    },
    update() {
      this.axios
        .patch(`credits/${this.id}`, this.obj)
        .then(() => {
          this.$swal.fire(
            "Actualizado",
            "Ha sido actualizado con exito",
            "success"
          );
          this.$emit("getCredits");
          this.$emit("close");
          this.loading = false;
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
          this.loading = false;
        });
    },
    create() {
      this.axios
        .post("credits", this.obj)
        .then(() => {
          console.log(this.obj, "asdasds");
          this.$swal.fire("Creado", "Ha sido creado con exito", "success");
          this.$emit("getCredits");
          this.$emit("close");
          this.loading = false;
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
          this.loading = false;
        });
    },
    submit() {
      this.loading = true;
      this.obj.company_id = this.company_id;
      this.obj.user_id = this.$auth.user().id;
      this.obj.movements_attributes = this.paymentsData
      this.action === "create" ? this.create() : this.update();
    },
    generatePayments() {
      let app = this;
      let user = this.$auth.user().id;
      app.paymentsData = [];
      let final_date;
      let initial_date
      for (var i = 0; i < this.obj.number_of_payments; i++) {
        initial_date = new Date(this.obj.issue_date.getFullYear(), this.obj.issue_date.getMonth() + i, 0);
        final_date = new Date(this.obj.issue_date.getFullYear(), this.obj.issue_date.getMonth() + i, this.obj.issue_date.getDate());
        let mov = {
          total: 0,
          credit_state: "pending_credit",
          credit_init_date: initial_date,
          expiration_date: final_date,
          period: final_date,
          capital: 0,
          interest: 0,
          company_id: this.company_id,
          type_movement: 'type_buy',
          category_movement: 18,
          user_id: user,
          doc_type: "200000000",
          folio: 'N° ' + (i + 1),
          business_name: this.obj.name + " - " + this.obj.operation_number,
          name: this.obj.name + " - " + this.obj.operation_number
        };
        app.paymentsData.push(mov);
      }
    },
  },
};
</script>
