<template>
  <div
    v-if="!loading"
    class="w-full min-h-screen flex flex-col"
  >
    <!-- <Breadcrumb
      v-if="
        (step > 2 && step < 5) ||
        step == 'configuration_step'
      "
      :crumbs="crumbs"
      :step="step"
      class="mt-16"
    /> -->
    <SearchCompany
      v-if="step == 1 || step == 'sii_step'"
      @next-step="step = $event"
      @fullrut="rutctnr = $event"
      @rut="rut = $event"
      @dv="dv = $event"
      @updateName="name = $event"
    />

    <ConnectionBanks
      v-if="step == 'bank_step'"
      @next-step="step = $event"
    />

    <ConfigStep
      v-if="step == 'configuration_step'"
      @next-step="step = $event"
      @updateNavbar="updateNavbar"
    />

    <ContractStep
      v-if="step == 'contract_step'"
      @next-step="step = $event"
      @update-navbar="updateNavbar"
    />

    <GetData v-if="step == 'summary_step'" />

    <button
      @click="goToDashboard"
      class="fixed top-5 right-5 hover:border-gray-300 border border-transparent rounded-full p-2 transition-all duration-200 focus:outline-none"
    >
      <Icon icon="heroicons:x-mark" class="h-6 w-6 dark:text-white" />
    </button>

  </div>
</template>
<script>
import { mapActions } from "vuex";
import SearchCompany from "@/views/private/components/onboarding/SearchCompany";
import ConnectionBanks from "@/views/private/components/onboarding/ConnectionBanks";
import ConfigStep from "@/views/private/components/onboarding/ConfigStep";
import ContractStep from "@/views/private/components/onboarding/ContractStep";
import GetData from "@/views/private/components/onboarding/GetData.vue";
// import Breadcrumb from "@/components/Breadcrumb.vue";
import { Icon } from "@iconify/vue2";


export default {
  name: "OnboardingIndex",
  props: {
    user_id: String,
  },
  components: {
    SearchCompany,
    ConnectionBanks,
    ConfigStep,
    ContractStep,
    GetData,
    // Breadcrumb,
    Icon,
},
  data() {
    return {
      step: 1,
      crumbs: [
        { name: "Datos Empresa", icon: "company" },
        { name: "Conciliación Bancaria", icon: "bank" },
        { name: "Datos Generales", icon: "setting" },
      ],
      rutctnr: "",
      rut: "",
      dv: "",
      name: "",
      password: null,
      from: null,
      configSii: [],
      hasConfigSii: false,
      hasCompany: false,
      disabled: false,
      siiConfig: {},
      sii_config_id: this.$cookie.get("sii_config_id"),
      stateConfig: false,
      loading: true,
    };
  },
  created() {
    if (this.$cookie.get("sii_config_id")) {
      let id = this.$cookie.get("sii_config_id");
      this.getConfigSii(id);
    } else{
      this.loading = false
    }
  },
  methods: {
    ...mapActions(['toggleOnboarding']),
    goToDashboard() {
      this.toggleOnboarding(false);
      this.$router.push({ name: 'dashboard' });
    },
    nextStep() {
      this.step++;
    },
    updateNavbar() {
      this.$emit("getCompanies");
    },
    cleanRut(value) {
      if (value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
      }
    },
    getConfigSii(id) {
      this.axios.get("sii_configs/" + id).then((response) => {
        this.siiConfig = response.data;
        this.step = response.data.step;
        this.loading = false
      }).catch(() => {
        this.$cookie.delete("sii_config_id");
        this.$router.go();
      });
    },
    getCompanies() {
      this.axios.get("companies").then((response) => {
        this.companies = response.data.data;
        this.hasCompany = response.data.data.length > 0;
      });
    },
  },
};
</script>
