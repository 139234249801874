<template>
  <div>
    <div class="flex flex-col h-modalwelcome items-center gap-2 w-full max-w-screen-md mx-auto">
      <div
        ref="chatContent"
        class="chat_content w-full py-10 overflow-y-scroll bg-white rounded-lg p-4"
      >
        <div class="flex flex-col gap-10">
          <div v-for="(message, i) in messages" :key="'message' + i" class="flex gap-2">
            <div class="relative flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg v-if="message.type != 'ia'" class="absolute w-10 h-10 text-gray-400 -left-1" fill="currentColor"
                viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd">
                </path>
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-7 h-7" viewBox="0 0 37 55" fill="none">
                <path d="M18.4557 6.05949L29.2779 34.7001L33.9679 29.0709L22.6948 0.00405884H14.5026L2.96486 29.0487L2.88342 29.2503L7.47163 34.6235L18.4557 6.05949Z" fill="currentColor"/>
                <path d="M33.9658 29.0669L18.5463 47.5949L2.88241 29.2543L-0.000976562 36.5099L15.2222 53.9839L21.2516 54.0031L36.6711 36.2328L33.9658 29.0669Z" fill="currentColor"/>
              </svg>
            </div>
            <div class="flex flex-col ml-2 flex-1">
              <p class="text-xl font-bold">{{ message.sender }}</p>
              <div v-if="message.type === 'ia'">
                <div v-html="i === animatedMessageIndex ? animatedText : message.text"></div>
              </div>
              <div v-else v-html="message.text"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full">
        <div class="grid grid-cols-3 gap-3">
          <div
            class="bg-white flex gap-2 items-center w-full hover:bg-gray-100 p-2.5 rounded-md focus:outline-none transition-all cursor-pointer shadow-xs hover:shadow-sm border border-gray-200"
            @click="updateBalanceDoc">
            <Icon :icon="loadingBalance ? 'svg-spinners:90-ring-with-bg' : 'mdi:file-refresh-outline'"
                class="w-6 h-6 text-gray-300" />
            <div>Enviar balance</div>
          </div>
          <div
            class="bg-white flex gap-2 items-center w-full hover:bg-gray-100 p-2.5 rounded-md focus:outline-none transition-all cursor-pointer shadow-xs hover:shadow-sm border border-gray-200"
            @click="updateDailyBookDoc">
            <Icon :icon="loadingDailyBook ? 'svg-spinners:90-ring-with-bg' : 'mdi:file-refresh-outline'"
                class="w-6 h-6 text-gray-300" />
            <div>Enviar Libro Diario</div>
          </div>
          <div
            class="bg-white flex gap-2 items-center w-full hover:bg-gray-100 p-2.5 rounded-md focus:outline-none transition-all cursor-pointer shadow-xs hover:shadow-sm border border-gray-200"
            @click="updateHigherBookDoc">
            <Icon :icon="loadingHigherBook ? 'svg-spinners:90-ring-with-bg' : 'mdi:file-refresh-outline'"
                class="w-6 h-6 text-gray-300" />
            <div>Enviar Libro Mayor</div>
          </div>
          <div
            class="bg-white flex gap-2 items-center w-full hover:bg-gray-100 p-2.5 rounded-md focus:outline-none transition-all cursor-pointer shadow-xs hover:shadow-sm border border-gray-200"
            @click="updateRemunerationBookDoc">
            <Icon :icon="loadingRemunerationBook ? 'svg-spinners:90-ring-with-bg' : 'mdi:file-refresh-outline'"
                class="w-6 h-6 text-gray-300" />
            <div>Enviar Libro Remuneraciones</div>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center flex-1 w-full">
        <form @submit.prevent="sendMessage"
          class="overflow-hidden flex flex-col w-full flex-grow relative border border-gray-400 rounded-2xl">
          <textarea :disabled="loading" dir="auto" rows="1" v-model="message" @keydown.enter.prevent="sendMessage"
            :class="[loading ? 'bg-gray-100' : 'bg-white']" placeholder="Escribe un mensaje..."
            class="m-0 w-full resize-none border-0 bg-transparent focus:ring-0 focus-visible:ring-0 py-2 pr-10 md:py-3.5 md:pr-12 max-h-[25dvh] max-h-52 pl-3 md:pl-3"
            style="height: 52px; overflow-y: hidden;"></textarea>
          <button type="submit"
            class="absolute bottom-1.5 right-2 rounded-lg text-white transition-colors enabled:bg-black disabled:text-gray-400 disabled:opacity-10 md:bottom-3 md:right-3">
            <span class="" data-state="closed">
              <Icon :icon="loading ? 'svg-spinners:90-ring-with-bg' : 'mdi:arrow-up-circle'"
                class="w-6 h-6 text-cyma-purple" />
            </span>
          </button>
        </form>

      </div>
    </div>
  </div>
</template>
<script>
import { marked } from "marked";
import { Icon } from "@iconify/vue2"


export default {
  name: 'Gpt',
  data() {
    return {
      // Prueba
      loading: false,
      loadingBalance: false,
      loadingDailyBook: false,
      loadingHigherBook: false,
      loadingRemunerationBook: false,
      message: '',
      messages: [],
      animatedText: '', // Nueva propiedad para el texto animado
		animatedMessageIndex: null, // Índice del mensaje que se está animando
    }
  },
  components: {
    Icon,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
    dateRange: {
      get() {
        return this.$store.getters.getDateRange
      },
      set(newValue) {
        this.$store.commit("addDateRange", [newValue[0], newValue[1]])
      },
    },
  },

  methods: {
    toMarkDown(data) {
      let dataMarkdown = marked(data).replace('<table>', '<table class="table-ia">');
      return dataMarkdown
    },
    animateText(text) {
      this.animatedText = '';
      let index = 0;

      const typeLetter = () => {
        if (index < text.length) {
          this.animatedText += text[index];
          index++;
          setTimeout(typeLetter, 1); // Ajusta el tiempo para la velocidad de la animación
        }
      };

      typeLetter();
    },
    sendMessage() {
      this.loading = true
      this.messages.push({
        sender: 'Tu',
        type: 'user',
        text: this.message
      })
      this.axios
        .get('gpt', {
          params: {
            text: this.message,
            company_id: this.company_id,
          },
        }).then(res => {
          let gptData = res.data.result

          this.messages.push({
            sender: 'CymAI',
            type: 'ia',
            text: this.toMarkDown(gptData)
          })
          this.animatedMessageIndex = this.messages.length - 1; // Índice del último mensaje agregado
          this.animateText(this.toMarkDown(gptData))
          this.message = null
          this.loading = false
          this.$nextTick(() => {
            this.scrollToBottom()
          })
        })
    },
    scrollToBottom() {
      const chatContent = this.$refs.chatContent;
      if (chatContent) {
        chatContent.scrollTop = chatContent.scrollHeight
      }
    },
    updateBalanceDoc() {
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar el balance",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.loadingBalance = true;
            this.$swal.fire(
              "Actualizando",
              "El balance esta siendo sincronizado.",
              "success"
            );
            const app = this
            this.axios
              .get("/gpt-balance", {
                params: {
                  company_id: app.company_id,
                  from: app.dateRange[0],
                  to: app.dateRange[1],
                },
              })
              .then(() => {
                this.loadingBalance = false;
              })
              .catch(() => {
                this.$swal.fire(
                  "Error",
                  "El balance no ha sido sincronizado.",
                  "error"
                )
                this.loadingBalance = false;
              })
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El balance no ha sido sincronizado.",
              "error"
            )
          }
        })
    },
    updateDailyBookDoc() {
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar el libro diario",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.loadingDailyBook = true;
            this.$swal.fire(
              "Actualizando",
              "El libro diario esta siendo sincronizado.",
              "success"
            );
            const app = this
            this.axios
              .get("/gpt-daily-book", {
                params: {
                  company_id: app.company_id,
                  from: app.dateRange[0],
                  to: app.dateRange[1],
                  only_classified: true,
                },
              })
              .then(() => {
                this.loadingDailyBook = false;
              })
              .catch(() => {
                this.loadingDailyBook = false;
                this.$swal.fire(
                "Error",
                "El Libro Diario no ha sido sincronizado.",
                "error"
              )
              })
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Error",
              "El Libro Diario no ha sido sincronizado.",
              "error"
            )
          }
        })
    },
    updateHigherBookDoc() {
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar el libro mayor",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.loadingHigherBook = true;
            this.$swal.fire(
              "Actualizando",
              "El libro mayor esta siendo sincronizado.",
              "success"
            );
            const app = this
            this.axios
              .get("/gpt-higher-book", {
                params: {
                  company_id: app.company_id,
                  from: app.dateRange[0],
                  to: app.dateRange[1],
                },
              })
              .then(() => {
                this.loadingHigherBook = false;
              })
              .catch(() => {
                this.loadingHigherBook = false;
                this.$swal.fire(
                "Error",
                "El Libro Mayor no ha sido sincronizado.",
                "error"
              )
              })
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Error",
              "El Libro Mayor no ha sido sincronizado.",
              "error"
            )
          }
        })
    },
    updateRemunerationBookDoc() {
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar el libro de remuneraciones",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.loadingRemunerationBook = true;
            this.$swal.fire(
              "Actualizando",
              "El libro de remuneraciones esta siendo sincronizado.",
              "success"
            );
            const app = this
            this.axios
              .get("/gpt-remuneration-book", {
                params: {
                  type_movement: "type_buy",
                  category_movement: [5],
                  company_id: app.company_id,
                  from: app.dateRange[0],
                  to: app.dateRange[1],
                  get_salary: true
                },
              })
              .then(() => {
                this.loadingRemunerationBook = false;
              })
              .catch(() => {
                this.loadingRemunerationBook = false;
                this.$swal.fire(
                "Error",
                "El Libro de Remuneraciones no ha sido sincronizado.",
                "error"
              )
              })
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Error",
              "El Libro de Remuneraciones no ha sido sincronizado.",
              "error"
            )
          }
        })
    },
  }
}
</script>
<style>
.chat_content {
  height: 90%;
}

/* Estilos generales de la tabla */
.table-ia {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

/* Estilo de las celdas de encabezado */
.table-ia th {
  background-color: #f2f2f2;
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Estilo de las celdas de datos */
.table-ia td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Resaltado de filas alternas */
tr:nth-child(even) {
  background-color: #f9f9f9;
}


/* Resaltado de filas pares */
.table-ia tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Color de fondo de las filas pares */
}
</style>
