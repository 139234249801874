<template>
  <div class="py-2 mx-6">
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto text-lg sm:text-xl font-semibold dark:text-white">
        Control de Créditos
      </h1>

      <div class="flex items-center gap-2">
        <a
          @click.prevent="createButton"
          class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        >
          <span class="hidden sm:block"> Agregar Credito </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </a>
      </div>
    </div>
    <div>
      <div class="filter sm:w-2/12 w-full pb-4 mt-2">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar Credito"
          @input="getCredits"
          v-model="query"
        />
      </div>
    </div>
    <Add
      v-if="showModal"
      @close="showModal = false"
      @getCredits="getCredits"
      :id="id"
      :action="action"
    ></Add>
    <BeeGridTable
      :serverSide="true"
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showSummary="false"
      :showFilter="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="credits"
      :loading="loading"
    >
      <BeeColumn
        field="name"
        title="Credito"
        type="text"
        :sortable="true"
        :width="300"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <a
            @click.prevent="
              goTo('CreditShow', {
                id: row.id,
              })
            "
            class="dark:text-cian-dark dark:hover:text-white uppercase"
          >
            {{ row.name }}
          </a>
        </template>
      </BeeColumn>

      <BeeColumn
        field="operation_number"
        title="N° de Operación"
        type="text"
        :sortable="true"
        :width="200"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.operation_number }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="type_currency"
        title="Tipo de Moneda"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.type_currency }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="number_of_payments"
        title="N° de Cuotas"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.number_of_payments }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="issue_date"
        title="Fecha de Inicio"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatSimpleDate(row.issue_date) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="end_date"
        title="Fecha de Término"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatSimpleDate(row.end_date) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="annual_interest_rate"
        title="Interés Anual"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.annual_interest_rate }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="total_amount"
        title="Monto Total"
        type="text"
        :sortable="true"
        :width="150"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.total_amount }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="editar" title="Opciones" type="text" :width="120">
        <template slot-scope="{ row }">
          <div class="flex items-center">
            <a
              v-on:click="editFunction(row.id)"
              class="pl-1 dark:text-cian-dark dark:hover:text-white"
            >
              <Edit />
            </a>
            <a
              v-on:click="deleteFunction(row.id)"
              class="pl-1 dark:text-cian-dark dark:hover:text-white"
            >
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatSimpleDate } from "@/utils/formatters";
import routerMixin from "@/mixins/routerMixin";
import Edit from "@/components/Icons/Edit.vue";
import Times from "@/components/Icons/Times.vue";
import Add from "./Add.vue";
export default {
  name: "CreditIndex",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      excelName: "",
      showFileInput: false,
      uploadFile: null,
      id: null,
      action: null,
      showModal: false,
      sale_total: 0,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      columns: [],
      credits: [],
      attribute_sorting: null,
      sorting: null,
      loading: true,
      query: null,
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id() {
      this.getCredits();
    },
  },
  created() {
    this.getCredits();
  },
  components: {
    Edit,
    Add,
    Times
  },
  methods: {
    formatSimpleDate,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      console.log(sorting);
      this.current_page = offset;
      this.per_page = size;
      this.getCredits();
      // request api example
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    getCredits() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/credits", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query,
          },
        })
        .then((response) => {
          app.credits = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Se eliminara el Credito registrado",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "El Credito registrado ha sido eliminado",
              "success"
            );
            this.axios
              .delete(`credits/${id}`)
              .then(() => this.getCredits());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El Credito registrado no ha sido eliminado",
              "error"
            );
          }
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
