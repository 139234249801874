<template>
  <div class="py-2 mx-4 lg:mx-6">
    <div class="w-full flex gap-4">
      <div class="lg:text-4xl text-3xl font-semibold items-center dark:text-white flex-auto flex gap-4">
        Comprobantes
      </div>
      <div class="flex gap-2 items-center">
        <a
          @click.prevent="createButton"
          class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 dark:hover:bg-white  dark:hover:text-cian-dark"
        > <span class="hidden sm:block">
            Agregar Comprobante
          </span>
          <Plus />
        </a>
      </div>
    </div>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 my-3">
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar Comprobante"
          @input="getBuys"
          v-model="query"
          data-cy="search"
        />
      </div>
      <div class="text-md font-semibold text-gray-500">
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            :style="{
              width: '100%',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.65rem',
              'padding-bottom': '0.65rem',
              'border-radius': '0.5rem'
            }"
            v-model="dateRange"
            type="date"
            format='DD-MM-YYYY'
            range
            placeholder="Fecha"
            v-on:input="getBuys"
          ></date-picker>
        </div>
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="movement_state"
          :options="status"
          :reduce="(state) => state.value"
          label="name"
          placeholder="Estado"
        ></v-select>
      </div>
    </div>
    <newBuys
      v-if="showModal"
      @close="showModal = false"
      :buy_id="buy_id"
      :action="action"
      @getBuys="getBuys"
    ></newBuys>
    <newMovement
      v-if="showMovement"
      @getBuys="getBuys"
      @close="closeFunction"
      :buy_id="buy_id"
    ></newMovement>
    <br />
    <BeeGridTable
      :serverSide="true"
      border
      stripe
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="buys"
      :loading="loading"
    >
      <BeeColumn
        field="business_name"
        title="Comprobantes"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="350"
      >
        <template slot-scope="{ row }">
          <a :title="row.name" class="might-overflow dark:text-cian-dark uppercase">
            {{ row.name }}
          </a>
        </template>
      </BeeColumn>

      <BeeColumn
        field="period"
        title="Fecha"
        type="date"
        :width="180"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.period) }}
        </template>
      </BeeColumn>

      <BeeColumn
        field="date_sii"
        title="Periodo SII"
        type="date"
        :width="120"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatDateSII(row.date_sii) }}
        </template>
      </BeeColumn>

      <BeeColumn field="Account" title="Clasificación" type="text" :width="600">
        <template slot-scope="{ row }">
          <span class="uppercase text-xs">{{ row.account_neto_name || "No clasificado" }}</span>
        </template>
      </BeeColumn>

      <BeeColumn field="total" title="Total" type="number" :sortable="true" :width="150">
        <template slot-scope="{ row }">
          <div class="flex gap-2 items-center">
            $ {{ formatNumber(row.total) }}

            <div v-if="!row.doble_match">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
              </svg>
            </div>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="pagado" title="Pagado" type="text" :width="100" >
        <template slot-scope="{ row }">
          <div v-if="row.classified && !(row.credit_note_id) && !(row.accounting_not_include)">
            <label class="switch label_switch">
              <input
                class="check"
                type="checkbox"
                :class="{ notPaid: row.movement_status === 'paid_subscriber' }"
                :checked="checkedFunction(row.movement_status)"
                @click.prevent="updatePay($event.target.checked, row.id)"
              />
              <span><slot></slot></span>
            </label>
          </div>
          <div v-else-if="!(row.credit_note_id)"></div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="eliminar"
        title="Opciones"
        type="text"
        class="text-center"
        :width="120"
      >
        <template slot-scope="{ row, index }">
          <div class="flex gap-4 items-center">
            <ToolTip>
              <template #icon>
                <a v-on:click="editFunction(row.id)">
                  <Edit />
                </a>
              </template>
              Editar
            </ToolTip>
            <ToolTip>
              <template #icon>
                <svg @click.prevent="openModalVersions(row.id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 dark:text-cian-dark dark:hover:text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                </svg>
              </template>
              Versiones
            </ToolTip>
            <ToolTip>
              <template #icon>
                <button :data-cy="'delete' + index" @click="deleteFunction(row.id)">
                  <Times />
                </button>
              </template>
              Eliminar
            </ToolTip>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
    <modalVersions
      v-if="showModalVersions"
      @close="showModalVersions = false"
      :buy_id="buy_id"
      :action="action"
    ></modalVersions>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import { formatNumber, formatSimpleDate, formatDateSII } from "@/utils/formatters"
import newBuys from "./newAccouting";
import newMovement from "./newMovement.vue";
import "@/assets/stylesheets/switch.scss";
import Plus from '@/components/Icons/Plus.vue';
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';
import modalVersions from "../buys/modalVersions.vue";
import ToolTip from '@/components/ToolTip.vue';

export default {
  name: "buys",
  props: {},
  data() {
    return {
      account_name: "No clasificado",
      buy_id: null,
      action: "create",
      showMovement: false,
      showModal: false,
      buys: [],
      filterTotal: 0,
      page: 1,
      showModalVersions: false,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      nameSorting: null,
      sorting: null,
      current_page: 1,
      query: null,
      per_page: 10,
      total_pages: 0,
      total_items: 0,
      movement_state: null,
      category_movement: null,
      status: [],
      types: [
        { value: null, name: "Seleccionar tipo de egreso" },
        { value: 9, name: "Comprobantes contables" },
      ],
      columns: [
        {
          title: "Razón social",
          key: "business_name",
          width: 350,
          sortable: true,
        },
        { title: "Periodo", key: "period" },
        { title: "Periodo SII", key: "date_sii" },
        { title: "Plan de cuenta", key: "Account" },
        {
          title: "Total",
          key: "total",
          width: 150,
          sortable: true,
        },
        {
          title: "Pagado",
          key: "pagado",
          width: 150,
        },
        {
          title: "Eliminar",
          key: "eliminar",
          width: 150,
        },
      ],
      buy_total: 0,
      loading: true,
    };
  },
  components: {
    DatePicker,
    newBuys,
    newMovement,
    Plus,
    Edit,
    Times,
    modalVersions,
    ToolTip
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
  },
  watch: {
    company_id() {
      this.getBuys();
    },
    movement_state(){
      this.getBuys();
    },
  },
  created() {
    this.action = "create";
  },
  mounted() {
    this.getBuys();
    this.status = [
      { value: null, name: "Todos los estado" },
      { value: 1, name: "No pagado" },
      { value: 0, name: "Pagado" },
    ]
  },
  methods: {
    formatNumber,
    formatSimpleDate,
    formatDateSII,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getBuys();
    },
    openModalVersions(id){
      this.showModalVersions = true,
      this.buy_id = id;
    },
    deleteFunction(id) {
      // /movements/:id
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu egreso ha sido eliminado",
              "success"
            );
            this.axios.delete(`movements/${id}`).then(() => this.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    reload() {
      this.$mount();
    },
    updatePay(event, id) {
      console.log(event);
      this.showMovement = true;
      this.buy_id = id;
    },
    closeFunction() {
      this.showMovement = false;
    },
    checkedFunction(status) {
      if (status == "not_paid") return false;
      return status === "paid_subscriber" || status === "paid";
    },
    createButton() {
      this.buy_id = null;
      this.showModal = true;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.buy_id = id;
      this.action = "update";
    },
    showAccount(id) {
      this.axios.get("/account/" + id).then((response) => {
        if (response.length >= 0) {
          this.account_name = response.name;
        }
      });
    },
    getBuys() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_accounting",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: 9,
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            pending_sii: app.pending_sii,
            sorting: app.sorting,
            query: app.query,
            order_date: 'asc'
          },
        })
        .then((response) => {
          app.buys = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // curr
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.without_account = response.data.without_account;
          app.movement_status = response.data.movement_status;
          app.buy_total = response.data.buy_total;
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped lang='scss'>
.notPaid {
  &:checked {
    + span {
      &:before {
        background-color: rgba(#f9fd00, 0.5);
      }
      &:after {
        background-color: #f9fd00;
        transform: translate(80%, -50%);
      }
    }
  }
}
.filter .style-chooser {
  min-width: 250px;
}
</style>
