<template>
  <div>
    <transition name="fade" mode="out-in">
      <div
        class="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black bg-opacity-50"
        v-if="loading"
      >
        <Loader />
      </div>
      <div class="flex modal-mask md:justify-center md:items-center" v-else>
        <div class="w-screen h-screen"></div>
        <div class="absolute md:w-full lg:w-auto">
          <div
            class="w-screen h-screen px-4 py-5 bg-white dark:bg-background-dark md:w-full md:h-full md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="py-8 text-2xl font-bold text-grey-darker lg:text-4xl dark:text-white"
                >
                  Pagar masivamente
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a
                  class="text-6xl dark:hover:text-cian-dark"
                  @click.prevent="closeFunction"
                >
                  &times;
                </a>
              </div>
            </div>
            <template v-if="!status">
              <div class="flex flex-col justify-between modal-body lg:flex-row">
                <div class="w-full py-6 lg:w-6/12">
                  <div class="lg:p-4">
                    <p
                      class="text-sm font-bold tracking-wide text-gray-500 uppercase dark:text-white"
                    >
                      Importante
                    </p>
                    <p class="pt-2 text-2xl font-bold text-gray-500">
                      Se pagara el 100% de todos los egresos seleccionados.
                    </p>
                  </div>
                </div>
                <form class="flex flex-col w-full h-full lg:w-6/12">
                  <div class="flex flex-col">
                    <label
                      class="block mb-2 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
                    >
                      ¿En qué fecha se pago?
                    </label>
                    <date-picker
                      :disabled="dateDocument"
                      type="date"
                      :style="{
                        width: '100%',
                        height: '47px',
                        'border-radius': '2px',
                        'padding-left': '0.55rem',
                        'padding-right': '0.55rem',
                        'padding-top': '0.55rem',
                        'padding-bottom': '0.55rem',
                      }"
                      format="dd-MM-yyyy"
                      v-model="$v.payObjs.period.$model"
                      :class="[
                        { error: $v.payObjs.period.$error, 'bg-gray-100' : dateDocument },
                        'border dark:bg-black-dark dark:border-background-dark',
                      ]"
                      placeholder="Seleccionar Fecha"
                    ></date-picker>
                    <div class="text_error" v-if="$v.payObjs.period.$error">
                      Este campo es requerido
                    </div>
                    <div class="flex items-center gap-2 mt-1">
                      <input type="checkbox" name="dateMovement" id="dateMovement" v-model="dateDocument">
                      <label class="" for="dateMovement">Usar fecha de Emisión del Documento</label>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="block mt-4 mb-2 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
                    >
                      Cuenta corriente involucrada
                    </label>
                    <div class="box-select">
                      <v-select
                        :class="{ error: $v.payObjs.account_id.$error }"
                        class="style-chooser custom-border"
                        :options="accounts"
                        :reduce="(account) => account.id"
                        label="fantasy_name"
                        v-model="$v.payObjs.account_id.$model"
                      ></v-select>
                      <div
                        class="text_error"
                        v-if="$v.payObjs.account_id.$error"
                      >
                        Este campo es requerido
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="mt-4 mb-2 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
                      >
                      Comentarios (recomendado)
                    </label>
                    <input
                      type="text"
                      class="w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter dark:bg-black-dark dark:border-black-dark"
                      v-model="payObjs.comentary"
                    />
                  </div>
                </form>
              </div>
              <div class="flex justify-end pt-8 pb-3 pr-5 modal-footer">
                <div class="">
                  <button
                    class="w-full p-3 font-bold text-white bg-black border rounded-full hover:bg-white hover:text-black hover:border-black dark:bg-cian-dark dark:border-cian-dark"
                    :disabled="disabled"
                    v-if="!disabled"
                    @click.prevent="massBuy"
                  >
                    Guardar
                  </button>
                  <div
                    class="flex items-center justify-center px-4 py-2 mt-4"
                    v-else
                  >
                    <Spinner2 customClass="h-5 w-5 dark:text-white" />
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="w-full px-3 py-12">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    color="#facea8"
                    class="w-40 h-40 mx-auto"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
              </div>
              <div class="flex justify-center pb-3 px-5 modal-footer">
                <div class="w-full">
                  <p class="text-2xl text-center">
                    Uno o mas Egresos actualmente se encuentran pagados o no se
                    encuentran clasificados.
                  </p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "@/assets/stylesheets/modal.css";
import { required } from "vuelidate/lib/validators";
import Loader from "@/components/loader.vue";
import Spinner2 from "@/components/Spinner2.vue";

export default {
  name: "massBuy",
  props: {
    selected_checks: { default: null },
    status: { default: true },
  },
  data() {
    return {
      dateDocument: false,
      user: {},
      clients: [],
      providers: [],
      accounts: [],
      saldo: 0,
      min: 0,
      max: 0,
      disabled: false,
      status_movements: this.status,
      payObjs: {
        arrMovements: this.selected_checks,
        account_id: null,
        total_amount: 0,
        period: null,
        comentary: null,
        type_account_movement: "paid",
        type_movement: "buy",
        company_id: this.company_id,
        dateDocument: false,
      },
      loading: true,
    };
  },
  components: {
    Loader,
    Spinner2,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    dateDocument(val){
      if(val){
        this.payObjs.period = null;
        this.payObjs.dateDocument = true;
      } else {
        this.payObjs.dateDocument = false;
      }
    }
  },
  validations() {
    return {
      payObjs: {
        account_id: {
          required,
        },
        period: {
          required: this.dateDocument ? () => true : required,
        },
      },
    }
  },
  mounted() {
    this.getAccounts();
  },
  methods: {
    massBuy() {
      this.disabled = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        setTimeout(() => {
          this.submitStatus = "ERROR";
          this.disabled = false;
        }, 500);
      } else {
        this.axios
          .post("update_mass_buy", this.payObjs)
          .then(() => {
            this.$emit("close");
            this.$emit("getBuys");
          })
          .then(() => {
            setTimeout(() => {
              this.submitStatus = "OK";
              this.disabled = false;
            }, 500);
          });
      }
    },
    getAccounts() {
      let app = this;
      this.axios
        .get("select_paid_or_bill", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          app.accounts = response.data;
          app.loading = false;
        });
    },
    closeFunction() {
      this.$emit("close");
    },
    getFile() {
      this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
        this.url = e.data.url;
        this.filename = e.data.name;
        console.log(e.data.url);
      });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getClients() {
      this.axios.get("clients").then((response) => {
        this.clients = response.data;
      });
    },
    formatMoney(e) {
      this.payObj.total_amount = e;
    },
    replaceName(name) {
      switch (name) {
        case "paid":
          return "Pagado";
      }
    },
  },
};
</script>
<style lang="scss">
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
</style>
