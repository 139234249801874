<template>
  <div>
         <BeeGridTable
        border
        height="560"
        :showSummary="false"
        :columns="columns"
        :data="representatives"
        :loading="loading"
      >
        <BeeColumn field="first_names" title="Nombres" type="date" sortable="true">
          <template slot-scope="{ row }">
            {{ row.first_names }}
          </template>
        </BeeColumn>


        <BeeColumn field="total" title="Total" type="Fecha de inicio" sortable="true">
          <template slot-scope="{ row }">
            {{formatCompleteDate(row.start_date)}}
          </template>
        </BeeColumn>
        <BeeColumn field="maternal_surname" title="Apellido materno" type="date" sortable="true">
          <template slot-scope="{ row }">
            {{ row.maternal_surname }}
          </template>
        </BeeColumn>

        <BeeColumn field="paternal_surname" title="Apellido paterno" type="text" sortable="true">
          <template slot-scope="{ row }">
            {{row.paternal_surname}}
          </template>
        </BeeColumn>
       <BeeColumn field="rut" title="RUT" type="text" sortable="true">
          <template slot-scope="{ row }">
            {{formatRut(row.rut)}}
          </template>
        </BeeColumn>

        <BeeColumn field="id" title="Cuenta" type="text">
          <template slot-scope="{ row }" slot="state">
            {{ row.id }}
          </template>
        </BeeColumn>
      </BeeGridTable>
  </div>
</template>
<script>
import { formatRut, formatCompleteDate } from '@/utils/formatters'
// import DatePicker from 'vue2-datepicker';

export default {

  name: 'representative',
  data(){
    return{
      representatives: [],
      columns: [
        { title: "Nombres", key: "first_names", resizable: true, filterHeight: 50 },
        { title: "Apellido paterno", key: "paternal_surname", filterHeight: 50, resizable: true },
        { title: "Apellido materno", key: "maternal_surname", filterHeight: 50, resizable: true },
        { title: "RUT", key: "rut", filterHeight: 50, resizable: true },
        { title: "Total", key: "total", width: 150, resizable: true, sortable: true }
      ],
      loading: true,
    }
  },
  components: {
    // DatePicker
  },
  mounted(){
    this.getRepresentatives()
  },
  methods:{
    formatRut,
    formatCompleteDate,
    getRepresentatives(){
      this.loading = true;
      this.axios.get("/representatives").then((response)=>{
        this.representatives = response.data
        this.loading = false;
      })
    }
  }
}
</script>
