<template>
  <div>
    <transition name="modal">
      <div
        class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        v-if="loading"
      >
        <Loader />
      </div>
      <div class="modal-mask flex md:justify-center md:items-center" v-else>
        <div class="h-screen w-screen"></div>
        <div class="absolute md:w-full lg:w-10/12">
          <div
            class="dark:bg-background-dark bg-white w-screen overflow-auto h-screen md:h-auto md:max-h-screen py-5 px-4 md:w-full md:py-4 md:px-10"
          >
            <div class="modal-header flex lg:mb-4 items-center">
              <h1 class="flex-auto font-bold text-grey-darker py-4 lg:text-4xl text-2xl dark:text-white">Marcar como pagado</h1>
              <div class="flex justify-end">
                <a class="text-6xl dark:hover:text-cian-dark dark:text-white" @click.prevent="closeFunction">
                  &times;
                </a>
              </div>
            </div>
            <div class="modal-body flex flex-col lg:flex-row justify-between">
              <slot name="body">
                <div class="lg:w-7/12 px-2">
                  <div class="">
                    <div class="">
                      <p
                        class="uppercase tracking-wide sm:text-base font-bold text-gray-500 dark:text-white"
                      >
                        {{saleObj.business_name}}
                      </p>
                      <p
                        class="text-gray-500"
                      >
                        {{formatDocType(saleObj.doc_type)}} {{saleObj.folio}}
                      </p>
                      <p class="lg:text-4xl text-3xl text-gray-500 font-bold dark:text-white">
                        $ {{ formatNumber(saleObj.total) }}
                      </p>
                      <p class="text-gray-400">
                        {{ formatCompleteDate(saleObj.period) }}
                      </p>
                    </div>
                  </div>
                  <div class="overflow-auto my-8">
                    <table class="table-auto border min-w-full dark:border-black-dark">
                      <thead class="sticky top-0 dark:text-white">
                        <tr class="border dark:border-black-dark w-full">
                          <th class="py-2 whitespace-nowrap text-left px-2 md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Fecha</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold px-2">Plan de cuentas</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold px-2">Debe</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold px-2">Haber</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold px-1">Eliminar</th>
                        </tr>
                      </thead>
                      <tbody class="dark:text-white">
                        <tr
                          v-for="(
                            account_movement, index_record
                          ) in account_movements_record"
                          :key="index_record"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap px-2">{{ formatSimpleDate(account_movement.period) }}</td>
                          <td class="py-2 whitespace-nowrap uppercase px-2">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                          </td>
                          <td class="py-2 whitespace-nowrap px-2">{{ formatNumber(account_movement.debit) }}</td>
                          <td class="py-2 whitespace-nowrap px-2">{{ formatNumber(account_movement.credit) }}</td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>

                        <tr
                          v-for="(
                            account_movement, index_bill
                          ) in account_movements_bill"
                          :key="index_bill + 10"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap px-2">{{ formatSimpleDate(account_movement.period) }}</td>
                          <td class="py-2 whitespace-nowrap uppercase px-2">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                            <span
                              v-if="account_movement.bank_transaction"
                              class="cursor-pointer text-gray-400 hover:text-cian-dark dark:text-cian-dark dark:hover:text-white"
                              v-on:click="goTo('transaction', account_movement.bank_transaction.transaction_id)"
                            >
                              {{ account_movement.bank_transaction.transaction_id }}
                            </span>
                          </td>
                          <td class="py-2 whitespace-nowrap px-2">{{ formatNumber(account_movement.debit) }}</td>
                          <td class="py-2 whitespace-nowrap px-2">{{ formatNumber(account_movement.credit) }}</td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                          <td v-if="index_bill === account_movements_bill.length - 1" class="py-2">
                            <button @click="deleteFunction(account_movement.id)" class="flex items-center mx-auto" >
                              <Times class="w-4 h-4" />
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-if="!saleObj.doble_match" class="float-right mt-4">
                      <a class="gap-4 flex items-center" @click.prevent="movementSii()">
                        Solucionar Problema
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                        </svg>
                      </a>
                    </span>
                  </div>
                  <div v-if="saleObj.credit_notes.length > 0">
                    <div
                      v-for="(credit_note , k) in saleObj.credit_notes" :key="'creditNote' + k" class="bg-gray-100 border-cyma-grey dark:text-white border rounded-lg p-4 my-4 dark:bg-black-dark dark:border-black-dark"
                    >
                      <p class="text-2xl font-bold">{{ credit_note?.business_name }}</p>
                      <p class="text-sm text-gray-500">
                        {{ credit_note.folio }} {{ formatDocType(credit_note.doc_type) }}
                      </p>
                      <p>$ {{ formatNumber(credit_note.total) }}</p>
                    </div>
                  </div>
                </div>
                <div v-if="saleObj.movement_status == 'bill'" class="w-full lg:w-5/12 py-12 px-3">
                  <div class="flex justify-center items-center ">
                    <Check />
                  </div>
                </div>
                <div v-else class="w-full lg:w-5/12">
                  <form v-if="saleObj.movement_status != 'bill' && !saleObj.is_credit_note && saleObj.credit_note_statement !== 'voided'" class="w-full h-full flex flex-col">
                      <div class="mx-2 lg:mx-8">
                        <label
                          class="label-cymasuite"
                        >
                          ¿En qué fecha se pago?</label
                        >
                        <date-picker
                          type="date"
                          :style="{
                            width: '100%',
                            'border-radius': '0.5rem',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.50rem',
                            'padding-bottom': '0.50rem',
                          }"
                          v-model="$v.payObj.period.$model"
                          format="dd-MM-yyyy"
                          :class="[{'error': $v.payObj.period.$error }, 'border dark:bg-black-dark dark:border-background-dark' ]"
                          placeholder="Fecha"
                        ></date-picker>
                        <div class="text_error" v-if="$v.payObj.period.$error"> Este campo es requerido</div>
                      </div>
                      <div class="mx-2 lg:mx-8 pt-4">
                        <label
                          class="label-cymasuite"
                          >Plan de cuenta</label
                        >
                        <div class="box-select">
                          <v-select
                            class="style-chooser custom-border"
                            :class="{'error': $v.payObj.account_id.$error }"
                            :options="accounts"
                            :reduce="(account) => account.id"
                            label="fantasy_name"
                            v-model="payObj.account_id"
                          ></v-select>
                        </div>
                        <div class="text_error" v-if="$v.payObj.account_id.$error">Este campo es requerido</div>
                      </div>
                      <div class="mx-2 lg:mx-8 pt-4">
                        <label
                          class="label-cymasuite"
                          >Monto</label
                        >
                        <div class="flex flex-row">
                          <currency-input
                            currency="CLP"
                            :class="{'error': $v.payObj.total_amount.$error }"
                            locale="es-CL"
                            class="input-text mr-2"
                            v-model="$v.payObj.total_amount.$model"
                          />
                          <div class="text_error" v-if="$v.payObj.total_amount.$error">Este campo es requerido</div>
                          <range-slider
                            class="slider"
                            :min="min"
                            :max="max"
                            @change="formatMoney"
                            v-model="payObj.total_amount"
                          >
                          </range-slider>
                        </div>
                      </div>
                      <div class="mx-2 lg:mx-8 pt-4">
                        <label
                          class="label-cymasuite"
                          >Comentarios</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="payObj.comentary"
                        />
                      </div>
                  </form>
                  <div v-if="saleObj.credit_note_statement == 'voided'" class="w-full h-full flex flex-col px-8">
                      <div class="text-sm pb-4 text-gray-500 uppercase">
                        Movimiento vinculado
                      </div>
                      <p
                      class="uppercase tracking-wide text-sm font-bold text-gray-500 dark:text-white"
                      >
                        {{ saleObj?.credit_note?.business_name }}
                      </p>
                      <p class="text-gray-500">
                        {{ formatDocType(saleObj.credit_note.doc_type) }}
                      </p>
                      <p class="text-gray-500">
                        {{ saleObj.credit_note.folio }}
                      </p>
                      <p class="text-4xl text-gray-500 font-bold dark:text-white">
                        ${{ formatNumber(saleObj.credit_note.total) }}
                      </p>
                      <p class="text-gray-500">
                        {{ formatCompleteDate(saleObj.credit_note.period) }}
                      </p>
                  </div>
                </div>

              </slot>
            </div>
            <div v-if="saleObj.movement_status != 'bill'" class="modal-footer flex lg:justify-end justify-center pr-5 pt-8 pb-3">
              <slot name="footer">
                <div class="">
                  <div v-if="disabled">
                    <Spinner>
                    </Spinner>
                  </div>
                  <button
                    v-else
                    :disabled="disabled"
                    class="bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white font-bold dark:bg-cian-dark dark:border-cian-dark rounded-full p-2 px-4"
                    @click.prevent="submit"
                  >
                    Guardar Pago
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "@/assets/stylesheets/modal.css";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from 'vuelidate/lib/validators';
import Loader from "@/components/loader.vue"
import Check from "@/components/Icons/Check.vue";
import Times from '@/components/Icons/Times.vue';
import Spinner from '@/components/Spinner2.vue';
import { formatDocType, formatNumber, formatSimpleDate, formatCompleteDate } from "@/utils/formatters.js";

export default {
  name: "newSale",
  props: {
    buy_id: { default: null },
  },
  data() {
    return {
      tab: 'pay',
      user: {},
      clients: [],
      disabled: false,
      providers: [],
      saldo: 0,
      min: 0,
      max: 0,
      accounts: [],
      saleObj: {
        type_movement: 1,
        business_name: null,
        coin: null,
        total: null,
        period: null,
        responsable: null,
        folio: null,
        company_id: null,
        client_id: null,
        file_movement: null,
        movement_status: null,
        account_movements: [],
        credit_note: [],
        credit_notes: [],
        credit_note_id: null ,
        credit_note_statement: null,
      },
      payObj: {
        account_id: null,
        movement_id: null,
        debit: null,
        total_amount: 0,
        credit: 0,
        period: null,
        comentary: null,
        type_account_movement: 'bill',
        type_movement: 'sale',
        company_id: this.$store.state.company_id,

      },
      loading: true
    };
  },
  components: {
    RangeSlider,
    Loader,
    Check,
    Times,
    Spinner
},
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    movement_status() {
      return this.saleObj.movement_status;
    },
    account_movements_record() {
      return this.saleObj.account_movements.filter(account_movement => account_movement.type_account_movement == 'record');
    },
    account_movements_bill() {
      return this.saleObj.account_movements.filter(account_movement => account_movement.type_account_movement == 'bill');
    }
  },
  watch: {
    movement_status() {
      this.saleObj.movement_status;
    }
  },
  validations: {
    payObj:{
      total_amount: {
        required,
        minValue(value) {
          return value > 0;
        },
      },
      account_id: {
        required
      },
      period: {
        required
      }
    }
  },
  mounted() {
    this.getBuy();
    this.getAccounts();
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    formatCompleteDate,
    getAccounts() {
      this.axios.get("select_paid_or_bill", {
          params: {
            company_id: this.company_id,
          },
        }).then((response) => {
        this.accounts = response.data;
      });
    },
    closeFunction(){
      this.$emit('getSales')
      this.$emit('close')
    },
    totalAmount(amount){
      let sum = amount.reduce((acc,e)=> acc + e.debit, 0);
      let creditNoteSum = this.saleObj.credit_notes.reduce((acc,e)=> acc + parseInt(e.total), 0);
      this.min = 0;
      this.max = parseInt(this.saleObj.total) - parseInt(sum) - parseInt(creditNoteSum);
    },
    getFile() {
      this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
        this.url = e.data.url;
        this.filename = e.data.name;
      });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getClients() {
      this.axios.get("clients", {
          params: {
            company_id: this.company_id,
          },
        }).then((response) => {
        this.clients = response.data;
      });
    },
    movementSii() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/movement-sii", {
          params: {
            company_id: app.company_id,
            id: app.saleObj.movement_id,
          },
        })
        .then((response) => {
          console.log("response", response);
          app.getBuy();
          setTimeout(() => {
            app.loading = false;
          }, 5000);
        });
    },
    getBuy() {
      this.axios.get(`movements/${this.buy_id}`).then((res) => {
        this.payObj.movement_id = res.data.id;
        this.saleObj.business_name = res.data.business_name;
        this.saleObj.movement_id = res.data.id;
        this.saleObj.total = res.data.total;
        this.saleObj.period = new Date(res.data.period);
        this.saleObj.company_id = res.data.company_id;
        this.saleObj.user_id = res.data.user_id;
        this.saleObj.folio = res.data.folio;
        this.saleObj.doc_type = res.data.doc_type;
        this.saleObj.account_movements = res.data.account_movements_order;
        this.saleObj.movement_status = res.data.movement_status;
        this.saleObj.credit_note_id = res.data.credit_note_id;
        this.saleObj.credit_note = res.data.credit_note;
        this.saleObj.credit_note_statement = res.data.credit_note_statement;
        this.saleObj.doble_match = res.data.doble_match;
        this.saleObj.credit_notes = res.data.credit_notes;
        this.getUser(res.data.user_id);
        this.totalAmount(res.data.account_movements.filter(account_movement => account_movement.type_account_movement == 'bill'));
        this.loading = false;
      });
    },
    replaceName(name){
       switch (name) {
        case 'bill':
        return 'Cobrado'
      }
    },
    goTo(url, data = null) {
      const request = this.$router.resolve({ name: url }).href;
      window.open(request + '?query=' + data, '_blank');
    },
    formatMoney(e){
      this.payObj.total_amount = e
    },
    deleteFunction(account_movement_id) {
      this.axios.delete("account_movements/" + account_movement_id).then(() => {
        this.getBuy();
      });
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.disabled = true;
        this.payObj.company_id = this.$store.state.company_id;
        this.axios
        .post("/account_movements", this.payObj)
        .then(() => {
          this.getBuy();
          this.payObj = {
            account_total_id: null,
            account_neto_id: null,
            movement_id: null,
            debit: null,
            total_amount: 0,
            credit: 0,
            period: null,
            comentary: null,
            type_account_movement: 'bill',
            type_movement: 'sale',
            company_id: null
          };
          this.disabled = false;
        })
        .catch((error) => alert(error));
      }
    },
  },
};
</script>
<style lang="scss">
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
</style>
