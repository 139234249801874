<template  >
  <div class="py-2 mx-6">
    <div class="flex flex-wrap">
      <div class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex lg:gap-4 gap-2">
        $ {{ formatNumber(total_paid || 0) }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#a8d3bd">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </div>
      <div class="flex items-center gap-4">
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            :style="{
              width: '100%',
              'border-radius': '1px',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.50rem',
              'padding-bottom': '0.50rem',
            }"
            v-model="dateRange"
            type="date"
            format='DD-MM-YYYY'
            range
            placeholder="Seleccionar un rango de Fecha"
            v-on:input="getPayments"
          ></date-picker>
        </div>
      </div>
    </div>
    <br>
    <BeeGridTable
      :serverSide="true"
      border
      ref="selection"
      :filterTotal="filterTotal"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :data="payments"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :fetchMethod="fetchMethod"
      :loading="loading"
    >
      <template slot-scope="{ row }" slot="expand">
        <div>
          <p class="text-lg">Detalle de la Compra</p>
          <table class="w-full py-3">
            <tr>
              <th class="w-1/12 px-2" >Cantidad</th>
              <th class="px-2">Descripción</th>
              <th class="px-2">Precio</th>
            </tr>
            <tr v-for="(item) in row.additional_info.items" :key="item.quantity">
              <td class="px-2"> {{ item.quantity }} </td>
              <td class="px-2"> {{ item.title == 'Discount' ? 'Descuento': item.title || '' }} </td>
              <td class="px-2"> {{ formatNumber(item.unit_price) }} </td>
            </tr>
          </table>
        </div>
      </template>

      <BeeColumn field="money_release_date" title="fecha de lanzamiento de dinero" type="text" :width="150">
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.money_release_date) }}
        </template>
      </BeeColumn>

      <BeeColumn field="name" title="Monto Total" type="text" :width="150">
        <template slot-scope="{ row }">
          {{ formatNumber(row.transaction_details.total_paid_amount) }}
        </template>
      </BeeColumn>

      <BeeColumn field="net_received_amount" title="Cantidad Neta" type="text" :width="150">
        <template slot-scope="{ row }">
          {{ formatNumber(row.transaction_details.net_received_amount) }}
        </template>
      </BeeColumn>

      <!-- <BeeColumn field="additional_info" title="Información Adicional" type="text" :width="500">
        <template slot-scope="{ row }">
          <div v-for="(item) in row.additional_info.items" :key="item" class="flex gap-2 justify-between">
            <span>{{ item.quantity }} {{ item.title }}</span>
            <span>{{ formatNumber(item.unit_price) }}</span>
          </div>
        </template>
      </BeeColumn> -->

      <BeeColumn field="fee_details" title="Tarifa Detalles" type="text" :width="300">
        <template slot-scope="{ row }">
          <div v-for="(fee_detail) in row.fee_details" :key="fee_detail.amount" class="flex gap-2 justify-between">
            <span>{{ fee_detail.type }}</span>
            <span>{{ formatNumber(fee_detail.amount) }}</span>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="payer" title="Pagador" type="text" :width="500">
        <template slot-scope="{ row }">
          <div class="">
            <p>{{ row.additional_info.payer.first_name }} {{ row.additional_info.payer.last_name }}</p>
            <p>{{ row.additional_info.payer.phone.number }}</p>
            <p>{{ row.additional_info.payer.address.street_name }} {{ row.additional_info.payer.address.street_number }}</p>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatNumber, formatSimpleDate } from "@/utils/formatters";
import DatePicker from "vue2-datepicker";
export default {
  name: "MercadoPago",
  props: {},
  data() {
    return {
      page: 1,
      total: 0,
      filterTotal: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      total_paid: 0,
      payments: [],
      columns: [
        {
          type: "expand",
          hideFilter: true,
          slot: "expand",
          width: 50,
        },
      ],
      loading: true,
    };
  },
  components: {
    DatePicker
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    exchange_rate: {
      get(){
        return this.$store.state.exchange_rate;
      },
      set(newValue){
        this.$store.commit('addExchangeRate', newValue);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
  },
  mounted() {
    this.getPayments();
  },
  watch: {
    exchange_rate() {
      this.getPayments()
    },
    company_id() {
      this.getPayments();
    }
  },
  methods: {
    formatNumber,
    formatSimpleDate,
    getPayments() {
      let app = this;
      app.loading = true;
      this.axios.get("/mercado_pago_payments", {
        params: {
          from: app.dateRange[0],
          to: app.dateRange[1],
          page: app.current_page,
          per_page: app.per_page,
          company_id: app.company_id
        },
      }).then((response) => {
        app.payments = response.data.data;
        app.total_paid = response.data.total_paid;
        app.filterTotal = response.data.total_items;
        app.total = response.data.per_page; // total data count
        app.page = response.data.current_page; // current page offset from server
        app.current_page = response.data.current_page;
        app.pageSize = response.data.per_page;
        app.pageCount = response.data.total_pages;
        app.loading = false;
      });
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getPayments();
    },
  },
};
</script>
