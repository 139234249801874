<template>
  <index title="Centro de Costos" route="/cost_centers"></index>
</template>
<script>
import index from '../../../../components/etc/crud/index'
export default {
  name:"CostCenter",
  components: {
    index
  }
}
</script>