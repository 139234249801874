<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div class="relative flex-1 bg-white dark:bg-background-dark h-auto">
    <section class="relative mx-auto pb-3 flex flex-col md:flex-row gap-5">
      <div
        class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-5/12 flex flex-col bg-cyma-purple-200 order-2 lg:order-none dark:bg-background-dark min-h-screen"
      >
        <div class="max-w-sm mx-auto">
          <header class="flex justify-center items-center mb-12">
            <button
              href="#"
              @click.prevent="$router.go()"
              class="block p-4 lg:p-6 outline-none border-transparent appearance-none focus:outline-none"
            >
              <img
                src="https://ptishwor.sirv.com/cyma-imgs/logo.png?format=webp"
                width="220"
                height="48"
                style="aspect-ratio: 151/29;"
                alt="cymasuite logo"
              />
            </button>
          </header>
          <div class="text-base text-center dark:text-gray-200">
            <p>
              Automatiza tu conciliación bancaria conectando con los principales bancos y realizando el proceso de manera eficiente.
            </p>
          </div>
          <img
            src="@/assets/images/onboarding-step2.svg"
            alt=""
            class="w-10/12 mx-auto my-5 select-none pointer-events-none"
            style="aspect-ratio: 336/262;"
          >
          <div>
            <p class="text-base dark:text-gray-200 text-center mb-5">
              Puedes disfrutar aún más de nuestros servicios descargando nuestra aplicación móvil
            </p>
            <div class="flex justify-center mb-5">
              <Icon icon="heroicons:chevron-double-down" class="w-8 h-8 text-cyma-purple" />
            </div>
            <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="https://apps.apple.com/us/app/cymasuite/id1582243635" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:apple" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Download on the</div>
                  <div class="font-sans text-sm font-semibold">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gonozer.cymasuitemobile&hl=es_CL&gl=US" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:google-play" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Get in on</div>
                  <div class="font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div>

      </div>

      <div class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-7/12 order-1 lg:order-none overflow-x-auto">
        <div class="max-w-xl mx-auto">
          <!-- Título -->
          <h2
            class="pt-20 uppercase text-2xl font-yesevaOne font-bold text-cyma-purple dark:text-white mb-4"
          >
            Conciliación Bancaria
          </h2>
          <p class="text-base dark:text-gray-200 mb-12">
            Simplifica el proceso vinculando tu cuenta bancaria. Por favor, ingresa tus credenciales bancarias.
          </p>
          <!-- Card -->
          <div class="text-center">
            <article
              class="inline-block rounded-lg mx-auto card transition-all duration-300"
              :class="{
                'bg-cyma-purple': bank_bill,
                'bg-cyma-gray-card bg-opacity-80 dark:bg-gray-800': !bank_bill,
              }"
            >
              <figure class="relative w-96 sm:h-60 p-4 md:p-5">
                <div class="flex items-center justify-between text-white">
                  <span v-if="bank_bill" class="text-lg sm:text-2xl font-bold">
                    {{formatNumber(bank_bill.balance_available)}}
                    <small class="text-sm font-light">CLP</small>
                  </span>
                  <span v-else class="text-lg sm:text-2xl font-bold"
                    >$ 83.861.379
                    <small class="text-sm font-light">CLP</small>
                  </span>
                </div>
                <!-- <p
                  class="flex text-xs sm:text-sm items-center gap-2 text-white"
                >
                  -------------
                </p> -->
                <div class="flex items-center gap-4 mt-5 sm:mt-8">
                  <div class="flex flex-row">
                    <img
                      v-for="i in 4"
                      :key="i"
                      class="w-3 h-3"
                      src="https://i.imgur.com/FNef1H8.png"
                    />
                  </div>
                  <div class="flex flex-row">
                    <img
                      v-for="i in 4"
                      :key="i"
                      class="w-3 h-3"
                      src="https://i.imgur.com/FNef1H8.png"
                    />
                  </div>
                  <div class="flex flex-row">
                    <img
                      v-for="i in 4"
                      :key="i"
                      class="w-3 h-3"
                      src="https://i.imgur.com/FNef1H8.png"
                    />
                  </div>
                  <div class="flex flex-row">
                    <span
                      v-if="bank_bill"
                      class="mr-1 xl:text-lg lg:text-base font-bold tracking-widest text-white"
                      >{{ bank_bill.number.slice(-4) }}</span
                    >
                    <span
                      v-else
                      class="mr-1 xl:text-lg lg:text-base font-bold tracking-widest text-white"
                      >XXXX</span
                    >
                  </div>
                </div>
                <div
                  class="flex items-center justify-between mt-5 sm:mt-8 text-white"
                >
                  <div class="flex flex-col">
                    <span v-if="bank_bill" class="text-xs sm:text-sm"> {{ bank_bill.name }} </span>
                    <span v-else class="text-xs sm:text-sm"> Cuenta corriente </span>
                    <span v-if="bank_bill" class="text-xs sm:text-sm">
                      {{ bank_bill.holder_name }}
                    </span>
                    <span v-else class="text-xs sm:text-sm">
                      El nombre de tu empresa
                    </span>
                  </div>
                  <div v-if="bank_bill">
                    <!-- Banco Santander -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco Santander'">
                      <IconBancoSantander class="h-10 w-10" />
                    </div>
                    <!-- Banco Chile -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco de Chile'">
                      <IconBancoDeChile class="h-10 w-10" />
                    </div>
                    <!-- Banco Estado -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco Estado'">
                      <IconBancoEstado class="h-10 w-10" />
                    </div>
                    <!-- Banco BCI -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco BCI'">
                      <IconBancoBCI class="h-10 w-10" />
                    </div>
                    <!-- Banco Itaú -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco Itaú'">
                      <IconBancoItau class="h-10 w-10" />
                    </div>
                    <!-- Banco Security -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco Security'">
                      <IconBancoSecurity class="h-10 w-10" />
                    </div>
                    <!-- Banco BICE -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco BICE'">
                      <IconBancoBICE class="h-10 w-10" />
                    </div>
                    <!-- Banco Scotiabank -->
                    <div v-if="bank_bill.bank.institution_country === 'Banco Scotiabank'">
                      <IconBancoScotiabank class="h-10 w-10" />
                    </div>
                  </div>
                </div>
              </figure>
            </article>
          </div>
          <div class="flex justify-center items-center mt-8 gap-4">
            <button
              v-if="!bank_bill"
              @click="nextStep"
              class="text-cyma-purple dark:text-white dark:hover:text-cian-dark font-semibold hover:underline focus:outline-none mx-10 p-2"
            >
              Omitir
            </button>
            <CymaButton
              v-if="!bank_bill"
              @click="openFintoc()"
              class="bg-cyma-purple text-white px-12 font-semibold"
            >
              Conectar
            </CymaButton>
            <CymaButton
              v-else
              @click="nextStep"
              class="bg-cyma-purple text-white px-12"
            >
              Siguiente
            </CymaButton>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatNumber } from "@/utils/formatters"
import { Icon } from "@iconify/vue2"
import CymaButton from "@/components/CymaButton.vue";
import IconBancoSantander from '@/components/Icons/BancoSantander.vue';
import IconBancoDeChile from '@/components/Icons/BancoDeChile.vue';
import IconBancoEstado from '@/components/Icons/BancoEstado.vue';
import IconBancoBCI from '@/components/Icons/BancoBCI.vue';
import IconBancoItau from '@/components/Icons/BancoItau.vue';
import IconBancoSecurity from '@/components/Icons/BancoSecurity.vue';
import IconBancoBICE from '@/components/Icons/BancoBICE.vue';
import IconBancoScotiabank from '@/components/Icons/BancoScotiabank.vue';

export default {
  components: {
    Icon,
    CymaButton,
    IconBancoSantander,
    IconBancoDeChile,
    IconBancoEstado,
    IconBancoBCI,
    IconBancoItau,
    IconBancoSecurity,
    IconBancoBICE,
    IconBancoScotiabank,
  },
  data() {
    return {
      company_id: null,
      bank_bill: null
    };
  },
  created() {
    this.getSiiConfig(this.$cookie.get("sii_config_id"));
  },
  methods: {
    formatNumber,
    getSiiConfig(id) {
      console.log(id);
      this.axios
        .get("sii_configs/" + id)
        .then((response) => {
          this.company_id = response.data.company.id;
          this.getBankBill(response.data.company.id);
        })
        .catch((e) => console.log(e));
    },

    updateSiiStep(id) {
      if (!id) {
        id = this.$cookie.get("sii_config_id");
      }
      this.axios.patch("/sii_configs/" + id, {
        step: 2,
      });
    },
    openFintoc() {
      let cur = this;
      let user_id = this.$auth.user().id;
      this.widget = Fintoc.create({
        holderType: "business",
        product: "movements",
        publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
        // publicKey: "pk_test_ymMNeuUD7dBDxKT5Ko_zww_isXsXaumn",
        webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${cur.company_id}_${user_id}`,
        // webhookUrl: `https://great-rocks-live-179-6-108-119.loca.lt/fintoc?credentials=${cur.company_id}_${user_id}`,
        onSuccess: () => {
          this.getBankBill(this.company_id);
        },
      });
      this.widget.open();
    },
    nextStep() {
      this.updateSiiStep();
      this.$emit("next-step", "configuration_step");
    },

    getBankBill(id) {
      this.axios
        .get("/bank_bills", {
          params: {
              company_id: id,
              page: this.current_page, // will_paginate
              per_page: this.per_page
          }
        })
        .then((response) => {
          console.log(response.data.data.length)
          this.bank_bill = response.data.data[response.data.data.length - 1]
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>

<style scoped lang="scss">
.card {
  aspect-ratio: 15/8;
  // height: 0;
  // padding-bottom: 53%;
  // @media (max-width: 380px) {
  //   padding-bottom: 60%;
  // }
}
</style>
