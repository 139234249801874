<template>
  <div class="relative flex h-screen overflow-hidden bg-white">
    <div class="items-center self-end justify-end hidden h-full lg:flex lg:w-1/2">
      <div class="max-w-sm mx-auto">
        <header class="flex items-center justify-center gap-3 py-2 mb-12">
          <Prisma class="flex-shrink-0 w-auto h-9 text-cyma-purple"/>
          <Cymasuite class="w-auto h-6 text-cyma-purple"/>
        </header>
        <img
          src="@/assets/images/success.png"
          alt="Digitalización de documentos"
          class="w-10/12 mx-auto mt-16 mb-5 pointer-events-none select-none sm:mb-12"
          style=""
        >
        <div>
          <h3 class="mb-5 text-xl font-semibold text-center text-cyma-purple">
            Somos tu partner contable
          </h3>
          <p class="text-base text-center text-cyma-purple">
            Equipo de contadores dedicados a tu empresa, ofreciendo atención personalizada y datos siempre actualizados.
          </p>

        </div>
      </div>
    </div>

    <div
      v-if="contentState == 'login'"
      class="flex items-center w-full p-4 pb-4 space-y-8 bg-white lg:w-1/2"
    >
      <div class="w-full border px-6 py-10 mx-auto bg-white shadow-xl fade-in md:max-w-xl sm:px-10 sm:py-24 rounded-2xl">
        <h2 class="text-2xl text-center select-none font-yesevaOne sm:text-3xl text-cyma-purple">
          Bienvenido a Cymasuite
        </h2>
        <p class="mt-5 mb-8 text-sm text-center lg:text-base text-cyma-purple">
          Conecta con tus datos y descubre cómo administrar tu negocio. Comienza ahora y simplifica la contabilidad
        </p>
        <div class="p-4 text-red-700 bg-red-100 border-l-4 border-red-500" role="alert" v-if="error">
          <p class="font-bold">Ha ocurrido un problema</p>
          <p>Credenciales de acceso inválidos. Inténtalo de nuevo</p>
        </div>
        <form class="mt-8" @submit.prevent="login">
          <div>
            <div class="relative mb-6 -mx-3 focus-within:text-gray-900 dark:focus-within:text-gray-800">
              <div class="px-3 md:w-full">
                <div
                  aria-hidden="true"
                  class="absolute inset-y-0 flex items-center px-4 pointer-events-none"
                >
                  <Icon icon="heroicons:user" class="w-5 h-5 text-gray-400" />
                </div>
                <input
                  class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                  id="email-address"
                  name="email"
                  type="email"
                  v-model="email"
                  required
                  placeholder="Usuario"
                />
              </div>
            </div>
            <div class="relative mb-6 focus-within:text-gray-900 dark:focus-within:text-gray-800">
              <div aria-hidden="true" class="absolute inset-y-0 flex items-center px-4 pointer-events-none">
                <Icon
                  icon="heroicons:lock-closed"
                  class="w-5 h-5 text-gray-400"
                />
              </div>
              <input
                class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                id="password"
                name="password"
                v-model="password"
                :type="!isShow ? 'text' : 'password'"
                required
                placeholder="Contraseña"
              />
              <div class="absolute right-0 z-30 flex items-center px-4 inset-y-1">
                <button
                  type="button"
                  @click="isShow = !isShow"
                  class="z-30 focus:outline-none"
                >
                  <Icon
                    :icon="isShow ? 'heroicons:eye' : 'heroicons:eye-slash'"
                    class="w-5 h-5 text-gray-400"
                  />
                </button>
              </div>
            </div>
          </div>
          <div class="flex justify-between items-center text-sm flex-wrap gap-y-4 gap-x-2">
            <a class="cursor-pointer text-cyma-purple hover:text-indigo-600 hover:underline" @click.prevent="goTo('register')">
              Registro
            </a>
            <a class="text-right cursor-pointer text-cyma-purple hover:text-indigo-500"
              @click.prevent="goTo('resendEmail')">
              ¿No recibió correo de confirmación?
            </a>
          </div>
          <div class="mt-4">
            <a
              @click.prevent="contentState = 'forgetPassword'"
              class="cursor-pointer text-cyma-dark-gray-200 hover:text-indigo-500 hover:underline"
            >
              ¿Olvidaste tu contraseña?
            </a>
          </div>
          <div class="flex justify-end items-center mt-6">
            <button
              v-if="state"
              type="submit"
              class="relative flex justify-center w-full px-4 py-2 overflow-hidden text-sm font-medium text-white transition-all duration-300 ease-out rounded-md sm:w-auto md:px-8 group bg-cyma-purple hover:bg-gradient-to-r hover:from-cyma-purple hover:to-cyma-light-purple hover:ring-2 ring-cyma-light-purple"
            >
              <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:translate-x-100 ease"></span>
              <span class="relative">Iniciar sesión</span>
            </button>

            <div v-else class="flex items-center justify-center px-4 py-3 mt-4" >
              <Spinner2 class="text-cyma-purple" />
            </div>

          </div>
        </form>
      </div>
    </div>

    <div
      v-else-if="contentState == 'forgetPassword'"
      class="flex items-center w-full p-4 pb-4 space-y-8 bg-white lg:w-1/2"
    >
      <div class="w-full border px-6 py-10 mx-auto bg-white shadow-xl fade-in md:max-w-xl sm:px-10 sm:py-24 rounded-2xl">
        <div>
          <h2 class="text-2xl font-yesevaOne sm:text-3xl text-cyma-purple text-center">
            Olvidé mi contraseña
          </h2>
        </div>
        <p class="mt-6 text-cyma-purple text-center">
          Introduzca su correo electrónico a continuación y le enviaremos una nueva contraseña.
        </p>
        <form class="mt-8 space-y-10" @submit.prevent="sendEmail()">
          <div class="-space-y-px rounded-md shadow-sm">
            <div class="relative focus-within:text-gray-900 dark:focus-within:text-gray-800">
              <div aria-hidden="true" class="absolute inset-y-0 flex items-center px-4 pointer-events-none">
                <Icon icon="lucide:at-sign" class="w-5 h-5 text-gray-400" />
              </div>
              <input
                class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                id="email-address-forget"
                name="emailForget"
                type="email"
                v-model="emailForget"
                autocomplete="off"
                placeholder="Correo electrónico"
                required
              />
            </div>
          </div>
          <div class="flex justify-end flex-wrap gap-4">
            <button
              type="button"
              @click.prevent="contentState = 'login'"
              class="relative flex justify-center w-full px-4 py-2 text-sm font-semibold duration-300 transform border rounded-md sm:w-auto group border-gray text-cyma-light-purple hover:bg-white hover:text-cyma-purple active:scale-105 focus:outline-none hover:border-cyma-purple">
                Volver
            </button>
            <button
              type="submit"
              class="relative flex justify-center w-full px-4 py-2 overflow-hidden text-sm font-medium text-white transition-all duration-300 ease-out rounded-md sm:w-auto md:px-8 group bg-cyma-purple hover:bg-gradient-to-r hover:from-cyma-purple hover:to-cyma-light-purple hover:ring-2 ring-cyma-light-purple"
            >
              <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:translate-x-100 ease"></span>
              <span class="relative">Enviar</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner2 from '@/components/Spinner2.vue';
import { Icon } from '@iconify/vue2';
import Prisma from "@/components/Icons/Prisma.vue"
import Cymasuite from "@/components/Icons/Cymasuite.vue"

export default {
  components: {
    Spinner2,
    Icon,
    Prisma,
    Cymasuite,
  },
  name: "Login",
  data() {
    return {
      contentState: "login",
      email: "",
      password: "",
      emailForget: "",
      error: null,
      state: true,
      isShow: true,
    };
  },
  methods: {
    goTo(url) {
      this.$router.push(url).catch(() => {});
    },
    login() {
      this.state = false
      this.$auth.login({
        data: {
          email: this.email,
          password: this.password,
        },
        staySignedIn: true,
      }).then(response =>{
        this.$auth.user(response.data);
        this.goTo( "dashboard")
        this.state = true
      }).catch(e => {
        this.error = e.response.data.errors[0];
        this.state = true
      }
      );
    },
    sendEmail(){
      let cur = this
      cur.axios
      .post("auth/password", {
        email: cur.emailForget,
        // redirect_url: "http://localhost:8080/changePassword"
        redirect_url: "https://app.cymasuite.com/changePassword"
      })
      .then(() => {
        this.$swal.fire({
          title: '¡Correo enviado!',
          text: 'Por favor, revise su bandeja de entrada.',
          icon: 'success',
        })
        .then((result) => {
          if (result.isConfirmed) {
            cur.emailForget = ''
            cur.contentState = 'login'
          }
        })
      })
    },
  },
};
</script>

<style scoped>
/* form {
  border: 1px solid #d1cdcd;
} */
.auth::placeholder {
  /* font-family: Aileron; */
  text-transform: unset;
  font-variant: normal;
}
.auth:focus {
  border: 1px solid #f4f4f4;
}
</style>
