var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-wrap",class:{
    widthVal: this.booleanSlider,
    widthValidation: !this.booleanSlider,
  }},[_c('div',{staticClass:"w-full pr-12 pl-6"},[_c('ul',{staticClass:"flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row"},[_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
            'text-black bg-white': _vm.openTab !== 1,
            'text-white bg-black': _vm.openTab === 1,
          },on:{"click":function($event){return _vm.toggleTabs(1)}}},[_vm._v(" Proveedores de servicios ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
            'text-black bg-white': _vm.openTab !== 2,
            'text-white bg-black': _vm.openTab === 2,
          },on:{"click":function($event){return _vm.toggleTabs(2)}}},[_vm._v(" Clientes ")])]),_c('li',{staticClass:"-mb-px mr-2 last:mr-0 flex-auto text-center"},[_c('a',{staticClass:"text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal",class:{
            'text-black bg-white': _vm.openTab !== 3,
            'text-white bg-black': _vm.openTab === 3,
          },on:{"click":function($event){return _vm.toggleTabs(3)}}},[_vm._v(" Proveedores ")])])]),_c('div',{staticClass:"relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"},[_c('div',{staticClass:"px-4 py-5 flex-auto"},[_c('div',{staticClass:"tab-content tab-space"},[_c('ServiceProvider',{class:{
              hidden: _vm.openTab !== 1,
              block: _vm.openTab === 1,
              widthBig: !this.booleanSlider,
              widthSmall: this.booleanSlider,
            }}),_c('AllClients',{staticClass:"w-full",class:{
              hidden: _vm.openTab !== 2,
              block: _vm.openTab === 2,
              widthBig: !this.booleanSlider,
              widthSmall: this.booleanSlider,
            }}),_c('AllVendors',{staticClass:"w-full",class:{
              hidden: _vm.openTab !== 3,
              block: _vm.openTab === 3,
              widthBig: !this.booleanSlider,
              widthSmall: this.booleanSlider,
            }})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }