<template>
  <div class="h-full py-5 mx-6">
    <permission-modal
      v-if="showModal"
      @close="showModal = false"
      :company="company"
      @getData="getData">
    </permission-modal>
    <form class="w-full h-full">
      <div class="">
        <div
          id="logo"
          class="w-48 h-48 m-auto my-4 bg-center bg-cover rounded-full drop-shadow-lg"
          :style="{ 'background-image': `url('${company.logo_url || this.defaultImage}')` }">
        </div>
      </div>
      <!-- Tabs -->
      <div class="relative mb-6">
        <!-- <div class="absolute bottom-0 w-full h-px bg-gray-300" aria-hidden="true"></div> -->
        <ul
          class="relative flex -mx-4 overflow-x-auto text-sm font-medium flex-nowrap sm:-mx-6 lg:-mx-8 scroll overscroll-y-contain"
        >
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editCompanyData' }"
              >
              <p
                class="flex flex-col items-center justify-center w-20 py-3 text-xs text-gray-500 border border-transparent dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark':activeItem === 'CompanyData'}"
              >
                <span>
                  <svg class="w-6 h-6 mx-auto" fill="none" stroke-width="1.8" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Datos de empresa
                </span>
              </p>
            </router-link>
          </li>
          <li
            v-if="company.representatives_attributes"
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
                :to="{ name: 'editLegalRepresentatives' }"
                >
              <p
                class="flex flex-col items-center justify-center w-24 py-3 text-xs text-gray-500 border border-transparent dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'LegalRepresentatives'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path d="M10 8a3 3 0 100-6 3 3 0 000 6zM3.465 14.493a1.23 1.23 0 00.41 1.412A9.957 9.957 0 0010 18c2.31 0 4.438-.784 6.131-2.1.43-.333.604-.903.408-1.41a7.002 7.002 0 00-13.074.003z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Representante Legal
                </span>
              </p>
            </router-link>
          </li>
          <li
            v-if="company.partners_attributes"
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editPartners' }"
            >
              <p
                class="flex flex-col items-center justify-center w-20 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Partners'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path d="M7 8a3 3 0 100-6 3 3 0 000 6zM14.5 9a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM1.615 16.428a1.224 1.224 0 01-.569-1.175 6.002 6.002 0 0111.908 0c.058.467-.172.92-.57 1.174A9.953 9.953 0 017 18a9.953 9.953 0 01-5.385-1.572zM14.5 16h-.106c.07-.297.088-.611.048-.933a7.47 7.47 0 00-1.588-3.755 4.502 4.502 0 015.874 2.636.818.818 0 01-.36.98A7.465 7.465 0 0114.5 16z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Socios
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editBillingData' }"
            >
              <p
                class="flex flex-col items-center justify-center w-24 py-3 text-xs text-gray-500 border border-transparent dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'BillingData'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z"></path>
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Datos de Facturación
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editNotificationData' }">
              <p
                class="flex flex-col items-center justify-center w-24 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'NotificationData'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M10 2a6 6 0 00-6 6c0 1.887-.454 3.665-1.257 5.234a.75.75 0 00.515 1.076 32.91 32.91 0 003.256.508 3.5 3.5 0 006.972 0 32.903 32.903 0 003.256-.508.75.75 0 00.515-1.076A11.448 11.448 0 0116 8a6 6 0 00-6-6zM8.05 14.943a33.54 33.54 0 003.9 0 2 2 0 01-3.9 0z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Notificaciones
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
            :to="{ name: 'editIntegrations' }">
              <p
              class="flex flex-col items-center justify-center w-24 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
              :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Integrations'}"
            >
              <span>
                <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z"></path>
                </svg>
              </span>
              <span class="text-center pt-2">
                Integraciones
              </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editSii' }"
            >
              <p
                class="flex flex-col items-center justify-center w-24 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 whitespace-nowrap xl:whitespace-normal dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'SII'}"
              >
                <span>
                  <svg class="w-6 h-6" height="8.289" stroke="currentColor" viewBox="0 0 9.672 8.289" width="9.672" xmlns="http://www.w3.org/2000/svg">
                    <g data-name="Grupo 28408" transform="translate(8688.173 9962.173)">
                      <path d="M108.349,1.5c0,.019,0,.039,0,.058q0,.89,0,1.781c0,.055,0,.055-.056.055h-1.777c-.051,0-.051,0-.051-.051q0-.894,0-1.788c0-.018,0-.036,0-.054Z" data-name="Trazado 52338" fill="none" stroke-miterlimit="10" stroke-width="0.6" transform="translate(-8792.53 -9963.473)" />
                      <path d="M3.38,106.674a1.336,1.336,0,0,0-.041.137.753.753,0,0,0,.071.465.947.947,0,0,0,.189.272c.36.363.715.731,1.082,1.086a2.522,2.522,0,0,1,.677,1.183,2.9,2.9,0,0,1,.1.6,1.991,1.991,0,0,1-.1.78,2.038,2.038,0,0,1-1.246,1.286,2.444,2.444,0,0,1-.626.148,2.921,2.921,0,0,1-1.724-.359c-.08-.044-.157-.093-.234-.143a.069.069,0,0,1-.027-.05q0-.641,0-1.281c0-.1,0-.2.006-.306,0-.012,0-.025,0-.044.092.038.179.072.265.11.179.077.355.16.535.232a2.323,2.323,0,0,0,.6.151,1.01,1.01,0,0,0,.41-.028.479.479,0,0,0,.36-.419,1.106,1.106,0,0,0-.163-.736,2.561,2.561,0,0,0-.707-.76,3.685,3.685,0,0,1-.678-.626,1.735,1.735,0,0,1-.377-.795,1.491,1.491,0,0,1-.012-.492c.02-.124.047-.247.069-.371.005-.03.019-.04.05-.04H3.346Z" data-name="Trazado 52341" fill="none" id="Trazado_52341" stroke-miterlimit="10" stroke-width="0.6" transform="translate(-8689.473 -10066.737)" />
                      <path d="M260.121,133.452v2.713c0,.057,0,.057-.057.057h-1.769c-.057,0-.057,0-.057-.058V130.73c0-.054,0-.054.056-.054h1.773c.054,0,.054,0,.054.055Z" data-name="Trazado 52342" fill="none" id="Trazado_52342" stroke-miterlimit="10" stroke-width="0.6" transform="translate(-8941.557 -10090.308)" />
                      <path d="M409.075,133.449v-2.717c0-.054,0-.054.055-.054H410.9c.055,0,.055,0,.055.055q0,2.717,0,5.434c0,.057,0,.057-.058.057h-1.769c-.057,0-.057,0-.057-.058Z" data-name="Trazado 52343" fill="none" id="Trazado_52343" stroke-miterlimit="10" stroke-width="0.6" transform="translate(-9089.659 -10090.308)" />
                    </g>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Datos del SII
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editF29' }"
            >
              <p
                class="flex flex-col items-center justify-center w-24 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'F-29'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path d="M5.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H6a.75.75 0 01-.75-.75V12zM6 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H6zM7.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H8a.75.75 0 01-.75-.75V12zM8 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H8zM9.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V10zM10 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H10zM9.25 14a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H10a.75.75 0 01-.75-.75V14zM12 9.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V10a.75.75 0 00-.75-.75H12zM11.25 12a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H12a.75.75 0 01-.75-.75V12zM12 13.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V14a.75.75 0 00-.75-.75H12zM13.25 10a.75.75 0 01.75-.75h.01a.75.75 0 01.75.75v.01a.75.75 0 01-.75.75H14a.75.75 0 01-.75-.75V10zM14 11.25a.75.75 0 00-.75.75v.01c0 .414.336.75.75.75h.01a.75.75 0 00.75-.75V12a.75.75 0 00-.75-.75H14z"></path>
                    <path clip-rule="evenodd" fill-rule="evenodd" d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Formularios
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editUsersAllowed' }"
            >
              <p
                class="flex flex-col items-center justify-center w-24 pt-3 text-xs text-gray-500 border border-transparent pb-7 dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'UsersAllowed'}"
              >
                <span>
                  <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="w-6 h-6">
                    <path d="M10 9a3 3 0 100-6 3 3 0 000 6zM6 8a2 2 0 11-4 0 2 2 0 014 0zM1.49 15.326a.78.78 0 01-.358-.442 3 3 0 014.308-3.516 6.484 6.484 0 00-1.905 3.959c-.023.222-.014.442.025.654a4.97 4.97 0 01-2.07-.655zM16.44 15.98a4.97 4.97 0 002.07-.654.78.78 0 00.357-.442 3 3 0 00-4.308-3.517 6.484 6.484 0 011.907 3.96 2.32 2.32 0 01-.026.654zM18 8a2 2 0 11-4 0 2 2 0 014 0zM5.304 16.19a.844.844 0 01-.277-.71 5 5 0 019.947 0 .843.843 0 01-.277.71A6.975 6.975 0 0110 18a6.974 6.974 0 01-4.696-1.81z"></path>
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Permisos
                </span>
              </p>
            </router-link>
          </li>
          <li
            class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
          >
            <router-link
              :to="{ name: 'editCompanyProperty' }"
            >
              <p
                class="flex flex-col items-center justify-center w-24 py-3 text-xs text-gray-500 border border-transparent dark:text-gray-200 hover:text-gray-600 hover:bg-gray-100 dark:hover:bg-black-dark"
                :class="{'text-indigo-500 dark:text-cian-dark border-t-0 border-l-0 border-r-0 border-b-2 hover:border-indigo-600 dark:hover:border-cian-500 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'CompanyProperty'}"
              >
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
                  </svg>
                </span>
                <span class="text-center pt-2">
                  Cambiar Propiedad
                </span>
              </p>
            </router-link>
          </li>
        </ul>
      </div>
      <router-view
        v-if="!loading"
        :company="company"
        @updateTab="activeItem = $event"
        @showModal="showModal = $event"
      >
      </router-view>

    </form>
  </div>
</template>
<script>
// import VueRut from "@/components/etc/VueRut"
import permissionModal from "./permissionModal"
import routerMixin from "@/mixins/routerMixin";
// import LegalRepresentativeSII from "./tabs/LegalRepresentativeSII.vue";

export default {
  name: "newCompany",
  props: {
    action: { default: null },
    sii_config_id: { default: null },
  },
  mixins: [routerMixin],
  components: {
    permissionModal,
    // VueRut,
    // LegalRepresentativeSII,
},
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  data() {
    return {
      activeItem : "CompanyData",
      showSecret: false,
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      user: this.$auth.user(),
      rutW: null,
      showModal: false,
      loading: true,
      company: {
        billing_data_business_name: null,
        billing_data_rut: null,
        billing_data_giro: null,
        username_talana: null,
        password_talana: null,
        business_name: null,
        rut: null,
        dv: null,
        activity_gloss: null,
        open_factura_state: null,
        directorate_operational_unit: null,
        operational_unit_description: null,
        start_date_activities: null,
        subtype_taxpayer_descrip: null,
        rutcntr_partner: null,
        password_partner: null,
        addresses: [],
        id: null,
        current_user_permission: null,
        f29_date: null,
        f50_date: null,
        sii_config_id: null,
        from_talana_date: null,
        mercado_pago_token: null,
        mercado_pago_from: null,
        bsale_token: null,
        bsale_date: null,
        ventipay_date: null,
        flow_date: null,
        sii_config_attributes: {
          rutctnr: null,
          rut: null,
          dv: null,
          password: null,
          from: null,
        },
        logo_url: null,
        representatives_attributes: [],
        partners_attributes: [],
        permissions_attributes: [],
        email_remuneration: [],
        integrations_connections: {},
      },
    };
  },
  mounted() {
    this.getData(this.sii_config_id);
  },
  watch: {
    company_id(){
      this.getData()
    }
  },
  methods: {
    cleanRut(value) {
      if(value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
      }
    },
    errorInRut() {
        this.error = 'RUT Inválido';
        this.$refs.rut.focus();
    },
    updatePermission(id){
      this.axios.patch('permissions/' + id)
    },

    updateImage(id, img) {
      let data = new FormData();
      data.append("logo", img);
      data.append("id", id);
      this.axios
        .patch("company/update_logo", data)
        .then(()=> console.log("sucess"))
        .catch((e) => console.log(e))
    },
    newCompany() {
      const obj = {
        rut: this.rut,
        email: this.email,
      };
      this.action === "update" ? this.update(obj) : this.save(obj);
    },
    newSii() {
      this.axios
        .post("sii_configs", {
          rutcntr: this.rutctnr,
          rut: this.rut,
          dv: this.dv,
          user_id: this.user_id,
          password: this.password,
          from: this.from,
        })
        .then((result) => {
          console.log(result.data);
          this.goTo("Company");
        })
        .catch((e) => alert(e));
    },
    getData() {
        const app = this;
        app.loading = true;
        this.axios.get("companies/" + this.company_id).then((response) => {
          app.company.business_name= response.data.business_name,
          app.company.addresses = response.data.addresses,
          app.company.rut= response.data.rut
          app.company.dv= response.data.dv
          app.rutW = response.data.rut + response.data.dv
          app.company.activity_gloss= response.data.activity_gloss,
          app.company.directorate_operational_unit= response.data.directorate_operational_unit,
          app.company.operational_unit_description= response.data.operational_unit_description;
          if(response.data.start_date_activities){
            app.company.start_date_activities= new Date(response.data.start_date_activities);
          }
          app.company.subtype_taxpayer_descrip= response.data.subtype_taxpayer_descrip,
          app.company.sii_config_attributes = response.data.sii_config;
          // When date is null new Date() generate 31-12-1969
          if(response.data.sii_config.from){
            app.company.sii_config_attributes.from = new Date(response.data.sii_config.from);
          }
          app.company.sii_config_id = response.data.sii_config_id;
          app.company.id = response.data.id;
          app.company.representatives_attributes = response.data.representative_actives;
          app.company.partners_attributes = response.data.partners;
          app.company.permissions_attributes = response.data.permissions;
          app.company.openfactura_key = response.data.openfactura_key;
          app.company.logo_url = response.data.logo_url;
          app.company.username_talana = response.data.username_talana;
          app.company.password_talana = response.data.password_talana;
          // When date is null new Date() generate 31-12-1969
          if(response.data.from_talana_date){
            app.company.from_talana_date = new Date(response.data.from_talana_date);
          }
          // When date is null new Date() generate 31-12-1969
          if(response.data.f29_date){
            app.company.f29_date = new Date(response.data.f29_date);
          }
          if(response.data.f50_date){
            app.company.f50_date = new Date(response.data.f50_date);
          }
          app.company.mercado_pago_token = response.data.mercado_pago_token;
          if(response.data.mercado_pago_from){
            app.company.mercado_pago_from = new Date(response.data.mercado_pago_from);
          }
          app.company.rutcntr_partner = response.data.rutcntr_partner;
          app.company.password_partner = response.data.password_partner;
          app.company.open_factura_state = response.data.open_factura_state;
          app.company.current_user_permission = response.data.current_user_permission;
          app.company.billing_data_business_name = response.data.billing_data_business_name
          app.company.billing_data_rut = response.data.billing_data_rut
          app.company.billing_data_giro = response.data.billing_data_giro
          app.company.billing_data_email = response.data.billing_data_email
          app.company.email_remuneration = response.data.email_remuneration
          app.company.bsale_token = response.data.bsale_token
          if(response.data.bsale_date){
            app.company.bsale_date = new Date(response.data.bsale_date)
          }
          if(response.data.flow_date){
            app.company.flow_date = new Date(response.data.flow_date)
          }
          if(response.data.ventipay_date){
            app.company.ventipay_date = new Date(response.data.ventipay_date)
          }
          app.company.integrations_connections = response.data.integrations_connections
          app.loading = false;
        });
    },
    update() {
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          if(this.company.logo_url) {
            this.updateImage(this.company_id, this.company.logo_url)
          }
          this.$router.push({ name: "Company"})
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
<style>
.eyeButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  top: 13px;
}

.scroll::-webkit-scrollbar {
  width: 0.2em;
  height: 0.5em;
  background-color: #F5F5F5;
}
.scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #e4eaff52;
}

.dark .scroll::-webkit-scrollbar {
  background-color: #10172a;
}
.dark .scroll::-webkit-scrollbar-thumb:hover {
  width: 0.2em;
  background-color: #404550;

}
.dark .scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #404555;
}
</style>
