<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div
    class="mx-4 md:mx-6 lg:mx-2"
    v-if="company_id"
  >
    <div class="flex-1 mt-4 main-content lg:mt-2 lg:pb-4 md:pb-5">
      <div class="flex flex-col justify-start lg:flex-row">
        <div class="w-full lg:w-8/12">
          <div class="flex flex-wrap px-0 xl:px-4 dark:border-gray-muted rounded-lg">
            <div class="flex-auto mb-4 text-2xl dark:text-white">
              <Transition
                mode="out-in"
                name="custom classes"
                enter-active-class="transition duration-300 ease-out"
                enter-from-class="transform opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-200 ease-in"
                leave-from-class="opacity-100"
                leave-to-class="transform opacity-0"
              >
                <div v-if="loadingTax" class="animate-pulse" >
                  <div class="w-12 h-4 my-1 bg-gray-100 dark:bg-black-dark"></div>
                  <div class="w-40 h-6 mt-2 bg-gray-100 dark:bg-black-dark"></div>
                </div>

                <div v-else>
                  <div>
                    <p class="text-sm text-gray-400" v-if="IVAToPay > 0">Total IVA a Pagar</p>
                    <p class="text-sm text-gray-400" v-if="IVAToPay < 0">Iva Remanente Mes Siguiente</p>
                    <h3
                      class="flex items-center gap-2 text-2xl font-bold dark:text-white "
                    >
                      {{ formatPrice(IVAToPay || 0) }}
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="#a8d3bd">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                      </svg>
                    </h3>
                  </div>
                </div>
              </Transition>
            </div>

            <div class="w-full mb-5 font-semibold text-gray-500 rounded text-md sm:w-auto sm:mb-0">
              <div>
                <date-picker
                  input-class="xmx-input"
                  v-model="dateRange"
                  :style="{
                    width: '100%',
                    'border-radius': '0.375rem',
                    'padding-left': '0.55rem',
                    'padding-right': '0.55rem',
                    'padding-top': '0.65rem',
                    'padding-bottom': '0.65rem',
                  }"
                  format='DD-MM-YYYY'
                  type="date"
                  range
                  placeholder="Selecciona un rango de Fecha"
                  v-on:input="getMovements"
                >
                  <template v-slot:header="{ emit }">
                    <div style="text-align: left" class="sm:inline-flex justify-center">
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 3)">
                        Últimos 3 meses
                      </button>
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 6)">
                        Últimos 6 meses
                      </button>
                      <button class="block mx-btn mx-btn-text" @click="selectLastsMonths(emit, 12)">
                        Último año
                      </button>
                    </div>
                  </template>
                </date-picker>
              </div>
            </div>

            <div class="w-full gap-6 space-y-4 lg:flex lg:space-y-0">
              <div class="lg:w-1/3">
                <div class="flex items-center p-4 bg-white border dark:bg-black-dark border-cyma-gray dark:border-black-dark rounded-lg">
                  <div v-if="loadingTax" class="flex items-center justify-center w-full">
                    <Spinner color="#d1d5db" />
                  </div>
                  <div v-else class="flex flex-col ">
                    <div class="flex flex-row items-center">
                      <div class="flex-shrink pr-4">
                        <div class="p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-green-title">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                          </svg>
                        </div>
                      </div>
                      <div class="flex-1">
                        <h5 class="text-xs font-bold text-gray-500 uppercase">IVA de Ventas</h5>
                        <h3 class="font-bold xl:text-xl lg:text-base dark:text-white">
                          {{ formatPrice(saleIVA || 0) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/3">
                <div class="flex items-center p-4 bg-white border dark:bg-black-dark border-cyma-gray dark:border-black-dark rounded-lg">
                  <div v-if="loadingTax" class="flex items-center justify-center w-full">
                    <Spinner color="#d1d5db" />
                  </div>
                  <div v-else class="flex flex-col">
                    <div class="flex flex-row items-center gap-2">
                      <div class="flex-shrink">
                        <div class="p-2">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-red-title">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                          </svg>
                        </div>
                      </div>
                      <div class="flex-1">
                        <h5 class="text-xs font-bold text-gray-500 uppercase">IVA de Compras</h5>
                        <h3 class="font-bold xl:text-xl lg:text-base dark:text-white">
                          {{ formatPrice(buyIVA || 0) }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="lg:w-1/3">
                <div class="flex items-center p-4 bg-white border dark:bg-black-dark border-cyma-gray dark:border-black-dark rounded-lg">
                  <div v-if="loadingTax" class="flex items-center justify-center w-full">
                    <Spinner color="#d1d5db" />
                  </div>
                  <div v-else class="flex flex-row items-center">
                    <div class="pr-2">
                      <div class="p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                        </svg>
                      </div>
                    </div>
                    <div class="flex-1">
                      <h5 class="text-xs font-bold text-gray-500 uppercase">
                        IVA remanente
                      </h5>
                      <h3 class="font-bold xl:text-xl lg:text-base dark:text-white">
                        {{ formatPrice(residualIVA || 0) }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="flex items-center justify-center h-96">
            <Spinner color="#d1d5db"/>
          </div>
          <div v-else-if="loading" class="px-0 py-2 rounded-lg xl:px-4 dark:border-black-dark">
            <template>
              <div class="pr-0 lg:pr-2">
                <div v-if="IVAPendingSii > 0" class="text-sm p-4 bg-white my-2 rounded-lg dark:bg-black-dark dark:text-white">
                  Tienes <span class="font-bold">$ {{ formatPrice(IVAPendingSii) }}</span> pesos de IVA que podrías rebajar en el pago de impuestos de tu próxima declaración F29, dando acuse de recibo a <span class="font-bold">{{ MovementsPendingSii }} factura(s)</span> pendientes de proveedor(es) antes de finalizar este mes.
                </div>
                <div>
                  <p class="text-lg text-gray-400">Impuestos</p>
                  <bar-chart :chart-data="dataCollectionTax"></bar-chart>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="flex flex-col pt-10 lg:pt-0 lg:w-4/12">
          <div class="rounded-2xl">
            <div class="w-full dark:bg-black-dark h-fit rounded-lg">
              <div>
                <h2 class="text-lg px-8 dark:text-white pt-4">Obligación Tributaria (OT)</h2>
                <h3 class="text-xs px-8 text-gray-400 dark:text-gray-400 font-normal py-2 mb-5">
                  Todo contribuyente debe cumplir con el Estado, se pueden clasificar en dos: la obligación principal y las obligaciones accesorias.
                </h3>
                <div class="accounts-accounting px-4 xl:px-6 mt-4 mx-auto scroll-mod">
                  <ol class="relative border-l border-gray-200 dark:border-gray-700 last:mb-0">
                    <li v-for="(item,i) in form29" :key="item + i" class="mb-8 ml-4">
                      <div class="absolute w-3 h-3 bg-green-dark rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900"></div>
                        <time class="text-xs font-normal leading-none text-gray-400 dark:text-gray-500">
                          {{ i }}
                        </time>
                        <div v-for="(movement, i) in item" :key="i" class="mx-2 p-2 rounded-lg hover:bg-white dark:bg-black-dark dark:hover:bg-background-dark-disabled transition-colors duration-200 ease-in">
                          <summary
                            class="details__summary flex items-center gap-4 hover:text-gray-800"
                            :class="cssAccount(movement['exists'])"
                            >
                            <div class="flex items-center justify-center w-10 h-10 bg-gray-100 rounded-lg">
                              <div>
                                <Icons :value="movement['category_movement']" type="activos"/>
                              </div>
                            </div>
                            <div :class="cssAccount(movement['exists'])" class="flex flex-col items-start">

                              <span class="font-bold uppercase dark:text-white" v-if="movement['category_movement'] === 'form_22'">Operación Renta</span>
                              <span class="font-bold uppercase dark:text-white" v-if="movement['category_movement'] === 'previred'">Pago Cotizaciones</span>
                              <span class="font-bold uppercase dark:text-white" v-if="movement['category_movement'] === 'form_f29'">Declaraciones de impuestos</span>
                              <span :class="movement['exists'] === true ? 'text-gray-400' : 'text-gray-300'"  class="uppercase text-xs">{{ movement['business_name'] }}</span>
                            </div>
                            <div :class="cssAccount(movement['exists'])" class="self-start ml-auto font-bold dark:text-white whitespace-nowrap">
                              $ {{ formatPrice(movement['total']) }}
                            </div>
                          </summary>
                        </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!loading" class="flex items-center justify-center h-96">
            <Spinner color="#d1d5db" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <h1 class="p-20 mx-20 text-center text-gray-500">
      En estos momentos nuestro sistema de contabilidad esta obteniendo la
      información del<b> Servicio de Impuesto Interno.</b>
    </h1>
  </div>
</template>
<script>
import toolTips from './../toolTips.vue'
import DatePicker from "vue2-datepicker";
import BarChart from "./../BarChart";
import Icons from "./../Icons";
import PieChart from "./../PieChart.vue";
import Spinner from '@/components/Spinner.vue';
import Cards from '@/components/etc/creditCards.vue';

export default {
  name: "dashboard",
  components: {
    DatePicker,
    BarChart,
    PieChart,
    toolTips,
    Icons,
    Spinner,
    Cards,
  },
  props: {},
  data() {
    return {
      user: null,
      optionsAccount: [],
      company: null,
      total_items: 0,
      labels: [],
      data_graph_sales_tax: [],
      data_graph_buys_tax: [],
      dataCollectionTax: null,
      IVAPendingSii: 0,
      MovementsPendingSii: 0,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      loading: false,
      loadingGraph: false,
      loadingAverage: true,
      loadingTax: false,
      activeGraph: '',
      showModalRequest: false,
      form29: [],
      movementsBank: [],
      saleIVA: null,
      buyIVA: null,
      residualIVA: null,
      IVAToPay: null,
    };
  },
  created() {
    this.user = this.$auth.user();
    this.getCompany();
    this.getAccounts();
    this.getMovements();
    this.fillData();
    this.getBankBills();
  },
  mounted() {
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    date_type: {
      get(){
        return this.$store.state.buy_date_type;
      },
      set(newValue){
        this.$store.commit('buyDateType', newValue);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    company_id() {
      this.loading = false,
      this.loadingGraph = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    },
    refresh() {
      this.loading = false,
      this.loadingGraph = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    },
    date_type() {
      this.loading = false,
      this.loadingGraph = false,
      this.getCompany();
      this.getMovements();
      this.fillData();
      this.getAccounts();
      this.getBankBills(); /* !WARN */
    }
  },
  methods: {
    cssAccount(value){
      if(value === true){
        return ''
      } else {
        return 'text-gray-300'
      }

    },
    formatDateS(date) {
      const months = [
        "Ene", "Feb", "Mar", "Abr", "May", "Jun",
        "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"
      ];
      const dateFormatted = new Date(date);
      const month = months[dateFormatted.getMonth()];
      const year = dateFormatted.getFullYear();
      return `${month} ${year}`;
    },
    getFirstDayOfMonth(year, month) {
      return new Date(year, month, 1);
    },
    selectLastsMonths(emit, month) {
      const dateDay = new Date()
      const getFirstDay = this.getFirstDayOfMonth(dateDay.getFullYear(), dateDay.getMonth())
      const end =  this.getFirstDayOfMonth(dateDay.getFullYear(), dateDay.getMonth())
      const start = getFirstDay
      start.setMonth(start.getMonth() - month)

      const date = [start, end]
      emit(date)
    },
    getBankBills() {
      let app = this;
      this.axios
        .get("/bank_bills", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page
          }
        }).then((response) => {
          app.banks = response.data.data;
          app.loading = true;
        });
    },
    fillData() {
      const app = this;
      this.dataCollectionTax = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "IVA Debito",
            borderWidth: 1,
            stack: "venta",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#aafdd2",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_sales_tax,
          },
          {
            label: "IVA Credito",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buys_tax,
          }
        ],
      };
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getCompany() {
      this.axios
        .get("companies/" + this.company_id)
        .then((response) => {
          this.company = response.data;
        })
        .catch((e) => console.log(e));
    },
    getAccounts() {
      this.axios.get("/select_dashboard", {
        params: {
          company_id: this.company_id
        },
      }).then((response) => {
        this.optionsAccount = response.data;
      });
    },
    getMovements() {
      this.getMovementsDataTax();
      this.getMovementsForm29();
      this.getMovementsIVAStats();
    },
    getMovementsForm29() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/movements-form-22", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [13, 14, 15],
            page: app.current_page,
            per_page: app.per_page,
            attribute_sorting: 'date_sii',
            sorting: 'desc',
            order_date: 'asc',
            date_type: app.date_type,
          },
        })
        .then((response) => {
          app.form29 = response.data;
        });
    },
    getMovementsIVAStats() {
      this.loadingTax = true;
      this.axios
        .get("/movements/iva-stats", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
            date_type: this.date_type
          },
        }).then((response) => {
          this.saleIVA = response.data.sale_iva
          this.buyIVA = response.data.buy_iva
          this.residualIVA = response.data.residual_iva
          this.IVAToPay = response.data.iva_to_pay
          this.IVAPendingSii = response.data.iva_pending_sii
          this.MovementsPendingSii = response.data.movements_pending_sii
          this.loadingTax = false;
        });
    },
    getMovementsDataTax() {
      this.axios
        .get("/stats-tax", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
            date_type: this.date_type
          },
        })
        .then((response) => {
          this.movements = response.data.without_account;
          this.total_items = response.data.total_items;
          this.labels = response.data.labels;
          this.data_graph_sales_tax = response.data.data_graph_sales_tax;
          this.data_graph_buys_tax = response.data.data_graph_buys_tax;
          this.fillData();
          this.loading = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.accounts-accounting {
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.custom-columns {
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}
</style>
