<template>
  <div v-if="!loading" class="flex justify-center min-h-screen">
    <router-view v-if="(hasShow || !validateOnboarding) && !hasPermission"/>
    <div class="w-full" v-else-if="hasCompany">
      <div>
        <div
          v-if="!onboarding"
          class="w-full md:w-auto fixed top-0 md:h-screen z-10 hidden lg:block"
        >
          <Sidebar :dataCompanies="companies" />
        </div>
        <div
          :class="onboarding ? '': 'lg:pl-20 lg:pr-3'"
          class="w-full h-screen"
        >
          <Navbar :dataCompanies="companies" v-if="!onboarding"  />
          <div v-if="permitted">
            <router-view @getCompanies="getCompanies" />
          </div>
          <div v-else>
            <Permission />
          </div>
        </div>
      </div>
      <!-- <div class="flex flex-col md:flex-row my-2"> -->
    </div>
    <!-- <Onboarding v-if="hasShow" :user_id="String(user.id)" /> -->
  </div>
</template>

<script>
import Navbar from "@/components/etc/navbar";
import Permission from "@/components/etc/Permission";
import Sidebar from "@/components/etc/Sidebar";
// import Onboarding from "@/views/private/components/onboarding/Index.vue"
import feather from "feather-icons";

export default {
  name: "Private",
  data() {
    return {
      hasConfigSii: false,
      hasCompany: false,
      hasShow: false,
      user: { id: null },
      companies: [],
      loading: true,
      validateOnboarding: false,
      hasPermission: false
    };
  },
  created() {
    this.getSii();
    this.getCompanies();
    this.getPermission();
    this.user = this.$auth.user();
  },
  computed: {
    permitted() {
      return this.$store.state.permitted;
    },

    onboarding() {
      if (this.$route.name == "Onboarding") {
        return true;
      }
      return this.$store.state.onboarding;
    }
  },
  mounted() {
    feather.replace();
  },
  updated() {
    feather.replace();
  },
  components: {
    Navbar,
    Sidebar,
    Permission,
    // Onboarding,
  },
  methods: {
    getSii() {
      const app = this;
      this.axios
        .get("sii_configs", {
          params: {
            status: [0, 1, 2, 4],
          },
        })
        .then((response) => {
          if(response.data.length == 1){
            app.validateOnboarding = response.data[0].onboarding
          } else{
            app.validateOnboarding = true
          }
          app.hasConfigSii = response.data.length > 0;
        });
    },
    getPermission() {
      this.axios
        .get("permissions", {
          params: {
            state: [0,2],
            user_id: this.$auth.user().id
          },
        })
        .then((response) => {
          // Validate Company
          const company_id = this.$store.state.company_id
          let permission = response.data.find(v => v.company_id === company_id);

          if(permission === undefined && response.data.length > 0){
            this.$store.commit("addCompanyId", response.data[0].company_id);
            this.$router.push({ name: "dashboard" }).catch(() => {});
          }

          this.hasPermission = response.data.length > 0
          if(!this.validateOnboarding && response.data.length == 0) {
            this.$router.push({ name: "Onboarding" }).catch(() => {});
          }
        });
    },
    getCompanies() {
      this.loading = true
      this.axios.get("navbar").then((response) => {
        this.hasCompany = response.data.length > 0;
        this.hasShow = response.data.length == 0;
        if (!response.data.length > 0) {
          this.$router.push({ name: "Onboarding" }).catch(() => {});
          this.loading = false
        }
        if (response.data.length > 0) {
          this.companies = response.data;
          if (!this.$store.state.company_id) {
            this.$store.commit("addCompanyId", response.data[0].id);
          }
        }
        this.loading = false
      });
    },
  }
};
</script>
<style></style>
