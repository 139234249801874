`<template>
  <div class="">
    <transition name="modal">
      <div class="modal-mask h-full flex"  >
        <div class="md:w-4/5 lg:w-3/5 flex justify-center items-center m-auto">
          <div
            class="
              modal-container
              z-10
              md:h-full
              flex flex-col
              justify-center
              w-screen
            "
          >
            <div class="modal-header flex ml-5">
              <div class="flex-auto">
                <div class="w-full flex justify-between mt-4">
                  <h1
                    class="font-semibold ext-grey-darker py-4"
                    v-if="action === 'create'"
                  >
                    Agregar Socio 
                  </h1>
                  <h1 class="font-semibold ext-grey-darker py-4" v-else>
                    Actualizar Socio
                  </h1>
                  <a
                    class="text-4xl leading-none"
                    @click.prevent="$emit('close')"
                  >
                    &times;
                  </a>
                </div>
                <h2 class="text-xl font-semibold">{{ obj.names }} {{obj.last_name}} {{obj.mothers_last_name}}</h2>
                <h2 class="text-xl font-semibold text-gray-500">
                  {{ obj.rut }}
                </h2>
              </div>
            </div>
            <div class="">
              <slot name="body">
                <form class="h-full">
                  <div v-if="action === 'create'">
                    <h3 class="mx-6 uppercase py-4 pt-2 font-semibold text-sm">
                      Socio
                    </h3>
                    <div class="grid grid-cols-3 grid-rows-2 h-24">
                      <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
                          >RUT</label
                        >
                        <input
                          type="text"
                          class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                          v-model="obj.rut"
                        />
                      </div>

                      <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
                          >Nombres</label
                        >
                        <input
                          type="text"
                          class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                          v-model="obj.names"
                        />
                      </div>
                      <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
                          >Apellido</label
                        >
                        <input
                          type="text"
                          class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                          v-model="obj.last_name"
                        />
                        <label
                          class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
                          >Apellido Materno</label
                        >
                        <input
                          type="text"
                          class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                          v-model="obj.mothers_last_name"
                        />
                      </div>
                      
                    </div>
                  </div>
                  <h3 class="mx-6 uppercase py-4 pt-2 font-semibold text-sm">
                    Otra informacion
                  </h3>
                  <div class="grid grid-cols-3 grid-rows-2 h-24">
                    <div class="flex flex-col w-full h-full px-4 mx-2 mb-2">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                        "
                        >Contribucion Informada</label
                      >
                      <input
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                        "
                        v-model="obj.informed_contribution"
                      />
                    </div>
                    <div class="flex flex-col w-full h-full px-4 mx-2 mb-2">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                        "
                        >Descripción de Error</label
                      >
                      <input
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                        "
                        v-model="obj.error_description"
                      />
                    </div>
                     <div class="flex flex-col w-full h-full px-4 mx-2 mb-2">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                        "
                        >Dia de Incorporación</label
                      >
                      <date-picker
                      format="dd-MM-yyyy"
                      type="date"
                      :style="{
                        border: '1px solid #e2e8f0',
                        width: '100%',
                        'border-radius': '2px',
                        'padding-left': '0.55rem',
                        'padding-right': '0.55rem',
                        'padding-top': '0.55rem',
                        'padding-bottom': '0.55rem',
                      }"
                      v-model="obj.date_of_incorporation"
                      placeholder="Selecciona una Fecha"
                    ></date-picker>
                    </div>
                  </div>
                </form>
              </slot>
            </div>
            <div class="modal-footer flex justify-center pr-5 pb-3">
              <slot name="footer">
                <div class="mt-8 w-2/5">
                  <button
                    class="
                      bg-black
                      w-full
                      hover:bg-white
                      hover:text-black
                      border
                      hover:border-black
                      text-white
                      font-bold
                      p-3
                      rounded
                    "
                    @click.prevent="submit"
                  >
                    Guardar
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "Add",
  props: {
    id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      branch_offices: [],
      cost_centers: [],
      accounts: [],
      obj: {
        rut: null,
        names: null,
        last_name: null,
        mothers_last_name: null,
        error_description: null,
        informed_contribution: null,
        date_of_incorporation: null,
      },
    };
  },
  components: {},
  created() {
  },
  mounted() {
    this.getService();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    getService() {
      this.axios
        .get("partners/" + this.id)
        .then((response) => {
          this.obj = response.data;
        })
        .catch((e) => console.log(e));
    },
    getOption(route, element) {
      this.axios
        .get(route, {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          element.push(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update() {
      this.axios
        .patch(`partners/${this.id}`, this.obj)
        .then(() => {
          this.$swal.fire(
            "Actualizado",
            "Ha sido actualizado con exito",
            "success"
          );
          this.$emit("getAllPartners");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    create() {
      this.axios
        .post("partners", this.obj)
        .then(() => {
          this.$swal.fire("Creado", "Ha sido creado con exito", "success");
          this.$emit("getAllPartners");
          this.$emit("close|");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit: function () {
      this.obj.company_id = this.company_id;
      this.action === "create" ? this.create() : this.update();
    },
  },
};
</script>
<style scoped>
</style>`