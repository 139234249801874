<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Formularios
    </h2>
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha Formulario 29</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.35rem',
            'padding-right': '0.35rem',
            'padding-top': '0.35rem',
            'padding-bottom': '0.35rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.f29_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          type="date"
          input-class="xmx-input"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>

    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha Formulario 50</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.35rem',
            'padding-right': '0.35rem',
            'padding-top': '0.35rem',
            'padding-bottom': '0.35rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.f50_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          type="date"
          input-class="xmx-input"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue';
import DatePicker from "vue2-datepicker";
export default {
  name: "f29Data",
  data() {
    return {
      loading: false,
    }
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$emit('updateTab', "F-29")
  },
  components: {Spinner, DatePicker},
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>
