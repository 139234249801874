<template>
  <div class="flex flex-col justify-center items-center gap-8 ">
    <img src="@/assets/images/permission_denied.png" class="w-1/3" />
    <p class="text-2xl text-gray-500">Upss... Parece que no tienes permiso para ver esta pagina.</p>
  </div>
</template>

<script>
export default {
  name: "Permission"
}
</script>

<style>

</style>