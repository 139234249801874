<template>
  <div class="py-2 mx-6" :class="{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }">
    <h2 class="flex-auto text-xl font-semibold dark:text-white mb-6">
      Estado de Resultado
    </h2>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div >
        <v-select
          class="style-chooser custom-border"
          :options="cost_centers"
          :reduce="(cost_center) => cost_center.value"
          label="name"
          v-model="cost_center"
          placeholder="Centro de Costos"
        ></v-select>
      </div>
      <div>
        <v-select
          class="style-chooser custom-border"
          :options="branch_offices"
          :reduce="(branch_office) => branch_office.value"
          label="name"
          v-model="branch_office"
          placeholder="Sucursal"
        ></v-select>
      </div>
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          v-model="dateRange"
          type="date"
          range
          format='DD-MM-YYYY'
          placeholder="Fecha"
          v-on:input="reload"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem'
          }"
        ></date-picker>
      </div>
    </div>
    <div v-if="loadingFile" class="w-14">
      <Spinner2 />
    </div>
    <div v-else class="flex items-center gap-2 pb-4">
      <button class="flex items-center gap-2 pr-4 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" @click="exportInExcel()">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
      <button class="flex items-center gap-2 pr-4 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" @click="exportInPdf()">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="15px" height="15px" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
          <path d="M70 250 l0 -230 180 0 180 0 0 163 0 163 -68 67 -68 67 -112 0 -112 0 0 -230z m210 145 l0 -65 65 0 65 0 0 -145 0 -145 -160 0 -160 0 0 210 0 210 95 0 95 0 0 -65z m110 -42 c0 -2 -20 -3 -45 -3 l-45 0 0 47 0 47 45 -44 c25 -24 45 -45 45 -47z"/>
          <path d="M222 263 c7 -52 -9 -91 -43 -109 -16 -8 -29 -21 -29 -29 0 -22 37 -18 53 5 19 27 58 39 106 33 33 -5 41 -3 41 10 0 18 -30 30 -52 22 -22 -9 -42 22 -43 63 0 28 -5 38 -19 40 -16 3 -18 -1 -14 -35z m38 -74 c0 -5 -7 -9 -15 -9 -9 0 -12 6 -9 15 6 15 24 11 24 -6z"/>
          </g>
          </svg>
        Exportar
      </button>
    </div>
    <BeeGridTable
      border
      stripe
      :showFilter="false"
      :columns="columns"
      :data="balances"
      :showSummary="true"
      :summary-method="customSummary"
      :loading="loading"
    >

      <BeeColumn field="account_name" title="Cuenta" type="date" :width="450" sortable="true">
        <template slot-scope="{ row }">
          <div class="uppercase">
            {{ row.account_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="debit" title="Debito" type="date" :width="180" sortable="true">
        <template slot-scope="{ row }">
          {{ formatNumber(row.debit) }}
        </template>
      </BeeColumn>

      <BeeColumn field="credit" title="Credito" type="date" :width="180" sortable="true">
        <template slot-scope="{ row }">
          {{ formatNumber(row.credit) }}
        </template>
      </BeeColumn>

      <BeeColumn field="balance" title="Saldo Mes" type="date" :width="180" sortable="true">
        <template slot-scope="{ row }">
          {{ formatNumber(row.balance) }}
        </template>
      </BeeColumn>

      <BeeColumn field="accumulated" title="Saldo Acumulado" type="date" :width="180" sortable="true">
        <template slot-scope="{ row }">
          {{ formatNumber(row.accumulated) }}
        </template>
      </BeeColumn>


    </BeeGridTable>
  </div>
</template>

<script>
  import { formatNumber, formatSimpleDate } from '@/utils/formatters'
  import DatePicker from 'vue2-datepicker';
  import Spinner2 from '@/components/Spinner2.vue';

  export default {
    name: "Balance",
    props: {},
    data() {
      return {
        loadingFile: false,
        balances: [],
        current_page: 0,
        per_page: 0,
        total_pages: 0,
        total_items: 0,
        without_account: 0,
        total: [],
        costCenter: [],
        cost_centers: [],
        branch_offices: [],
        columns: [
          {
            title: "Cuenta",
            key: "account_name",
            align: "left",
            width: 350,
          },
        ],
        loading: true,
      };
    },
    computed: {
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
      branch_office: {
        get(){
          return this.$store.state.buy_branch_office_state;
        },
        set(newValue){
          this.$store.commit('buyBranchOfficeState', newValue);
        }
      },
      cost_center: {
        get(){
          return this.$store.state.buy_cost_center_state;
        },
        set(newValue){
          this.$store.commit('buyCostCenterState', newValue);
        }
      },
      date_type: {
        get(){
          return this.$store.state.buy_date_type;
        },
        set(newValue){
          this.$store.commit('buyDateType', newValue);
        }
      },
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      }
    },
    watch: {
      company_id(){
        this.reload();
      },
      cost_center(){
        this.reload();
      },
      branch_office(){
        this.reload();
      },
      date_type(){
        this.reload();
      },
    },
    components: {
      DatePicker,
      Spinner2
    },
    mounted() {
      this.getBalances();
      this.getCostCenter();
    },
    methods: {
      formatNumber,
      formatSimpleDate,
      exportInPdf(){
        this.loadingFile = true,
        this.axios.post('/statement-of-income-pdf',{
          company_id: this.company_id,
          from: this.dateRange[0],
          to: this.dateRange[1],
          cost_center_id: this.cost_center,
          branch_office_id: this.branch_office,
          date_type: this.date_type
        },{
          responseType: 'blob'
        })
          .then(response => {
            const url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/pdf'
            }))
            var link = document.createElement('a')
            link.href = url
            link.setAttribute('download', ('Estado-de-Resultado-PDF-' + this.formatSimpleDate(this.dateRange[0]) + 'al' + this.formatSimpleDate(this.dateRange[1])))
            document.body.appendChild(link)
            link.click()
            this.loadingFile = false
          })
          .catch((error) => {
            console.log(error)
          })
      },
      exportInExcel() {
        this.loadingFile = true
        this.axios.post('statement-of-income-excel', {
          company_id: this.company_id,
          from: this.dateRange[0],
          to: this.dateRange[1],
          cost_center_id: this.cost_center,
          branch_office_id: this.branch_office,
          date_type: this.date_type
        }, {
            responseType: 'blob'
        }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', ('Estado-Resultado-' + this.formatSimpleDate(this.dateRange[0]) + 'al' + this.formatSimpleDate(this.dateRange[1])))
            document.body.appendChild(link)
            link.click()
            this.loadingFile = false
        });
      },
      reload() {
        this.getBalances();
        this.getCostCenter();
      },
      customSummary({ columns, data }) {
        const sums = {};
        data
        columns.forEach((column, index) => {
          index
          const key = column.key;
          if (column.key === "account_name") {
            sums[column.key] = {
              key: column.key,
              value: "TOTALES",
            };
            return;
          }
          if (column.key === "debit") {
            sums[key] = {
              key,
              value: "$" + this.formatNumber(this.total[2]),
            };
          }
          if (column.key === "credit") {
            sums[key] = {
              key,
              value: "$" + this.formatNumber(this.total[1]),
            };
          }
          if (column.key === "balance") {
            sums[key] = {
              key,
              value: "$" + this.formatNumber(this.total[3]),
            };
          }
          if (column.key === "accumulated") {
            sums[key] = {
              key,
              value: "$" + this.formatNumber(this.total[4]),
            };
          }
        });
        return sums;
      },
      formatToBalance(total){
        const app = this;
        let tempData = [];
        for (let balance in app.balances) {
          const obj = app.balances[balance];
          tempData.push({
            account_id: obj.account_id,
            account_name: obj.account_name,
            company_id: obj.company_id,
            period: obj.period,
            debit: obj.debit,
            credit: obj.credit,
            balance: obj.balance,
            accumulated: obj.accumulated,
          });
        }
        app.total = total
        app.balances = tempData;
      },
      getCostCenter() {
        let app = this;
        this.axios.get("cost_centers", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.cost_centers = response.data;
          app.cost_centers = response.data.map(cost_center => {
            return { value: cost_center.id, name: cost_center.name }
          })
          app.cost_centers.unshift({ value: null, name: "Seleccionar Centro de costo" })
        });
      },
      getBranchOffice() {
        let app = this;
        this.axios.get("branch_offices", {
          params: {
            company_id: this.company_id,
          },
        }).then((response) => {
          this.cost_centers = response.data;
          app.branch_offices = response.data.map(branch_office => {
            return { value: branch_office.id, name: branch_office.name }
          })
          app.branch_offices.unshift({ value: null, name: "Seleccionar Sucursal" })
        });
      },
      getBalances() {
        this.loading = true;
        let app = this;
        this.axios.get("/statement-of-income", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            cost_center_id: app.cost_center,
            branch_office_id: app.branch_office,
            date_type: app.date_type
          }
        },
        ).then((response) => {
          app.balances = response.data.data;
          app.total = response.data.total;
          app.formatToBalance(app.total);
          app.loading = false;
        });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

