<template>
  <div class="modal-mask flex md:justify-center md:items-center">
    <div class="h-screen w-screen"></div>
    <div class="absolute w-full md:w-4/12 right-0">
      <aside
        class="dark:bg-background-dark bg-white w-screen pb-10 overflow-auto right-0 h-screen md:max-h-screen  md:w-full overscroll-contain">
        <div class="flex justify-end pr-5">
          <a class="text-6xl right-0 dark:hover:text-cian-dark" @click.prevent="$emit('close')">
            &times;
          </a>
        </div>
        <div v-if="!loading">
        <div class="flex justify-between items-center px-6">
          <div>
            <slot name="title"></slot>
          </div>
          </div>
          <div class="px-6">
            <slot name="body"></slot>
          </div>
        </div>
        <div v-else class="flex h-full w-full justify-center items-center">
          <Spinner2 customClass="h-16 w-16"/>
        </div>
      </aside>
    </div>
  </div>
</template>
<script>
import Spinner2 from '@/components/Spinner2.vue'
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Spinner2
  }
}
</script>