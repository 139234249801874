<template>
  <div>
    <transition name="modal">
      <div
        class="h-screen
          w-screen
          fixed
          top-0
          left-0
          bg-black bg-opacity-50
          flex
          justify-center
          items-center
          z-50"
        v-if="loading"
      >
        <Loader />
      </div>
      <div class="modal-mask flex md:justify-center md:items-center" v-else>
        <div class="h-screen w-screen"></div>
        <div class="absolute md:w-full lg:w-8/12">
          <div
            class="
              dark:bg-background-dark
              bg-white
              w-screen
              h-screen md:h-auto
              overflow-auto
              py-5
              px-4
              md:w-full md:max-h-screen md:py-4 md:px-10"
          >
            <div class="modal-header flex">
              <div class="flex-auto">
                <h1 class="font-bold text-grey-darker py-8 text-4xl dark:text-white">
                  Resumen de movimientos
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="closeFunction"> &times; </a>
              </div>
            </div>
            <div class="w-1/2">
              <div class="filter">
                <input
                  type="text"
                  class="input-text"
                  v-model="query"
                  v-on:keyup="getMovementsReconsile"
                  placeholder="Buscar Movimientos"
                />
              </div>
            </div>
            <div
              class="p-0  overflow-x-auto"
            >
              <slot name="body">
                <div class="w-full py-6 h-full">
                  <BeeGridTable
                    :serverSide="true"
                    border
                    :showFilter="false"
                    :showSummary="false"
                    :filterTotal="filterTotal"
                    :total="total"
                    :page="page"
                    :fetchMethod="fetchMethod"
                    :pageSize="pageSize"
                    :pageCount="pageCount"
                    :showPager="showPager"
                    :data="data"
                    :loading="loading"
                  >
                    <BeeColumn
                      field="business_name"
                      title="Razon Social"
                      type="text"
                      :width="300"
                      :sortable="true"
                    >
                      <template slot-scope="{ row }">
                        <p>{{ row.business_name }}</p>
                      </template>
                    </BeeColumn>
                    <BeeColumn
                      field="folio "
                      title="Folio"
                      type="date"
                      :width="180"
                      :sortable="true"
                    >
                      <template slot-scope="{ row }">
                        {{ row.folio }}
                        <div class="text-sm tracking-normal text-gray-400">
                          {{ formatDocType(row.doc_type) }}
                        </div>
                        <div
                          v-if="row.ticket_honorary"
                          class="text-sm tracking-normal text-gray-500"
                        >
                          {{ formatDocType(row.ticket_honorary.type_ticket) }}
                        </div>
                      </template>
                    </BeeColumn>
                    <BeeColumn
                      field="total"
                      title="Total"
                      :width="180"
                      :sortable="true"
                    >
                      <template slot-scope="{ row }">
                        $ {{ formatNumber(row.total) }}
                      </template>
                    </BeeColumn>
                    <BeeColumn
                      field="period"
                      title="Fecha"
                      type="date"
                      :width="150"

                    >
                      <template slot-scope="{ row }">
                        {{ formatSimpleDate(row.period) }}
                      </template>
                    </BeeColumn>
                    <BeeColumn
                      field="date_sii"
                      title="Periodo SII"
                      type="date"
                      :width="150"
                      :sortable="true"
                    >
                      <template slot-scope="{ row }">
                        {{ formatDateSII(row.date_sii) }}
                      </template>
                    </BeeColumn>

                    <showMovement
                      v-if="showModal"
                      @close="showModal = false"
                      :buy_id="buy_id"
                    ></showMovement>

                    <BeeColumn
                      field="eliminar"
                      title="Opciones"
                      type="text"
                      class="text-center"
                      :width="100"
                    >
                      <template slot-scope="{ row }">
                        <div class="flex">
                          <a v-on:click="showModalMovement(row.id)" class="pl-1">
                            <Eye/>
                          </a>
                        </div>
                      </template>
                    </BeeColumn>
                  </BeeGridTable>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { formatDocType, formatNumber, formatDateSII, formatSimpleDate } from "@/utils/formatters.js";
import "@/assets/stylesheets/modal.css";
import "vue-range-slider/dist/vue-range-slider.css";
import showMovement from "./showMovement.vue"
import Eye from "@/components/Icons/Eye.vue"
import Loader from "@/components/loader.vue";

export default {
  name: "movementsReconsile",
  props: {
    transaction_id: Number,
  },
  data() {
    return {
      loading: true,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      showPager: true,
      data: [],
      query: null,
      attribute_sorting: null,
      sorting: null,
      showModal: false
    };
  },
  components: {
    Loader,
    showMovement,
    Eye
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    movement_status() {
      return this.buyObj.movement_status;
    },
    account_movements_record() {
      return this.buyObj.account_movements.filter(
        (account_movement) => account_movement.type_account_movement == "record"
      );
    },
    account_movements_paid() {
      return this.buyObj.account_movements.filter(
        (account_movement) => account_movement.type_account_movement == "paid"
      );
    },
  },
  watch: {},
  validations: {},
  mounted() {
    this.getMovementsReconsile();
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    formatDateSII,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getMovementsReconsile();
    },
    showModalMovement(movement_id){
      this.showModal = true;
      this.buy_id = movement_id;
    },
    closeFunction() {
      this.$emit("getTransactions");
      this.$emit("close");
    },
    getMovementsReconsile() {
      let app = this;
      this.axios
        .get("transaction-movements-reconsile/" + this.transaction_id, {
          params: {
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            query: app.query,
            sorting: app.sorting,
            attribute_sorting: app.nameSorting,
          },
        })
        .then((response) => {
          app.data = response.data.data;
          app.current_page = response.data.current_page;
          app.filterTotal = response.data.total_items;
          app.total = response.data.per_page;
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    formatMoney(e) {
      // this.payObj.total_amount = "$" + this.formatNumber(e)
      this.payObj.total_amount = e;
    },
    replaceName(name) {
      switch (name) {
        case "paid":
          return "Pagado";
      }
    },
    deleteFunction(account_movement_id) {
      this.axios.delete("account_movements/" + account_movement_id).then(() => {
        // this.getBuy();
      });
    },
  },
};
</script>
<style lang="scss">
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
</style>
