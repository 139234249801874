<template>
    <div
      class="w-full bg-transparent text-black h-8 cursor-pointer rounded overflow-hidden whitespace-nowrap "
      id="select" :class="{ 'open': show }">
      <div class="group" @click.prevent="toggleShow()" id="selected">
        <span class="text-xs font-bold block truncate text-gray-600 group-hover:text-blue-300">({{ options.length }}) {{ selected.business_name }}</span>
        <span class="text-xs block text-gray-400 group-hover:text-blue-300">$ {{ formatNumber(selected.total) }} - {{ formatSimpleDate(selected.period) }} - {{ selected.folio }}</span>
      </div>
      <div class="w-full custom-select overflow-auto max-h-32 flex flex-col gap-2 bg-white z-100 shadow-xl" :class="{ 'absolute': show, 'hidden' : !show }">
        <div
          v-for="(option, i) in options"
          :key="i + 'Options'"
          @click.prevent="selectOption(option)"
          class="text-black w-48 hover:bg-gray-100 w-full"
        >
          <div class="p-2">
            <span class="text-xs font-bold block truncate">{{ option.business_name }}</span>
            <span class="text-xs block text-gray-400">$ {{ formatNumber(option.total) }} - {{ formatSimpleDate(option.period) }} - {{ option.folio }}</span>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { formatNumber, formatSimpleDate } from '@/utils/formatters'

export default {
  name: "SelectCustom",
  props: {
    options: {
      default: [
      ]
    },
    selected: {
      require: true
    }
  },
  data() {
    return {
      show: false,
      openUp: false
    }
  },
  mounted() {
    this.checkDropdownPosition();
    window.addEventListener('resize', this.checkDropdownPosition);
    window.addEventListener('click', this.handleGlobalClick);
  },
  methods: {
    formatNumber,
    formatSimpleDate,
    selectOption(option) {
      this.$emit('updateRecommended', option )
      this.show = false
    },
    toggleShow() {
      this.show = !this.show
    },
    handleGlobalClick(event) {
      if (this.show && !this.$el.contains(event.target)) {
        this.show = false;
      }
    },
    checkDropdownPosition() {
      const selectRect = this.$el.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      this.openUp = selectRect.top > windowHeight - selectRect.bottom;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDropdownPosition);
    window.removeEventListener('click', this.handleGlobalClick);
  }
}
</script>

<style>
.custom-select.open-up {
  transform-origin: bottom;
  transform: translateY(-122%);
}
</style>
