<template>
  <div
    class="flex flex-wrap"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider,
    }"
  >
    <div class="w-full pr-12 pl-6">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row">
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            v-on:click="toggleTabs(1)"
            v-bind:class="{
              'text-black bg-white': openTab !== 1,
              'text-white bg-black': openTab === 1,
            }"
          >
            Proveedores de servicios
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            v-on:click="toggleTabs(2)"
            v-bind:class="{
              'text-black bg-white': openTab !== 2,
              'text-white bg-black': openTab === 2,
            }"
          >
            Clientes
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a
            class="text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal"
            v-on:click="toggleTabs(3)"
            v-bind:class="{
              'text-black bg-white': openTab !== 3,
              'text-white bg-black': openTab === 3,
            }"
          >
            Proveedores
          </a>
        </li>
      </ul>
      <div
        class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded"
      >
        <div class="px-4 py-5 flex-auto">
          <div class="tab-content tab-space">
            <ServiceProvider
              v-bind:class="{
                hidden: openTab !== 1,
                block: openTab === 1,
                widthBig: !this.booleanSlider,
                widthSmall: this.booleanSlider,
              }"
            ></ServiceProvider>
            <AllClients
              v-bind:class="{
                hidden: openTab !== 2,
                block: openTab === 2,
                widthBig: !this.booleanSlider,
                widthSmall: this.booleanSlider,
              }"
              class="w-full"
            ></AllClients>
            <AllVendors
              v-bind:class="{
                hidden: openTab !== 3,
                block: openTab === 3,
                widthBig: !this.booleanSlider,
                widthSmall: this.booleanSlider,
              }"
              class="w-full"
            ></AllVendors>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceProvider from "@/views/private/components/serviceProviders/index";
import AllVendors from "@/views/private/components/vendors/All";

import AllClients from "@/views/private/components/clients/All";
export default {
  name: "tabContact",
  data() {
    return {
      openTab: 1,
    };
  },
  components: {
    ServiceProvider,
    AllVendors,
    AllClients,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  methods: {
    toggleTabs: function (tabNumber) {
      this.openTab = tabNumber;
    },
  },
};
</script>
<style scoped>
.widthBig {
  width: 80vw;
}
.widthSmall {
  width: 65vw;
}
</style>