<template>
  <div class="py-2 mx-4 lg:mx-0 lg:px-6" v-if="!loading">
    <h2 class="mb-4 text-lg font-bold md:text-3xl dark:text-white">
      {{ request.name }}
    </h2>
    <div class="flex flex-col">
      <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block w-full py-2 lg:max-w-3xl sm:px-6 lg:px-8">
          <div class="overflow-auto">
            <table class="w-full border-indigo-700 lg:max-w-3xl">
              <tbody class="rounded-xl">
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Estado
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ formatStatus(request.status) }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Tipo de solicitud
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ formatTypeRequest(request.type_request) }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Solicitante
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ nameComplete }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Asignado a
                  </td>
                  <td
                    v-if="request.assigned_to_id"
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ assigned_to_name_complete }}
                  </td>
                  <td
                    v-else
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    No asignado
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Fecha de creación
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ formatDate(request.created_at) }}
                  </td>
                </tr>

                <tr class="bg-white dark:bg-background-dark">
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    Descripción
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    {{ request.description }}
                  </td>
                </tr>
              </tbody>
              <tbody
                v-if="
                  request.status === 'completed' ||
                  request.status === 'rejected'
                "
              >
                <tr class="bg-gray-100 border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Fecha de asignación
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ formatDate(request.time_assignment) }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Tiempo desde la asignación
                  </td>
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ request.duration_in_progress }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Tiempo desde la creación
                  </td>
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ request.duration_created }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td
                    class="px-6 py-4 text-sm font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap"
                  >
                    Fecha de cierre
                  </td>
                  <td
                    colspan="2"
                    class="px-6 py-4 text-sm font-semibold text-gray-900 dark:text-white whitespace-nowrap"
                  >
                    {{ formatDate(request.deadline) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- view file-->
        <div
          v-if="file"
          class="flex w-full gap-3 py-2 mt-3 border-indigo-700 justify-evenly lg:max-w-3xl sm:px-6 lg:px-8"
        >
          <a
            :href="file.url"
            target="_blank"
            download
            class="relative inline-block text-sm font-medium text-white group focus:outline-none focus:ring hover:text-white"
          >
            <span
              class="absolute inset-0 border border-cyma-purple group-active:border-red-500"
            ></span>
            <span
              class="block px-12 py-3 transition-all transform border border-cyma-purple bg-cyma-purple active:border-red-500 active:bg-cyma-purple/90 group-hover:-translate-x-1 group-hover:-translate-y-1"
            >
              Descargar
            </span>
          </a>
        </div>
        <div
          v-if="validateAccountant"
          class="flex w-full gap-3 py-2 mt-3  border-indigo-700 justify-evenly lg:max-w-3xl sm:px-6 lg:px-8"
        >
          <template v-if="request.status == 'pending'">
            <button
                v-if="!loading_assign"
                @click.prevent="statusRequest('in_progress')"
                class="flex items-center justify-center w-1/3 gap-2 px-4 py-2 font-semibold bg-gray-100 rounded-md focus:outline-none"
                :class="{
                  'hover:bg-gray-200': request.status == 'pending',
                  'bg-gray-100 cursor-not-allowed': request.status != 'pending',
                }"
                :disabled="request.status !== 'pending'"
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19 7.5v3m0 0v3m0-3h3m-3 0h-3m-2.25-4.125a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zM4 19.235v-.11a6.375 6.375 0 0112.75 0v.109A12.318 12.318 0 0110.374 21c-2.331 0-4.512-.645-6.374-1.766z"
                    />
                  </svg>
                </div>
                <div>Asignación</div>
            </button>
            <Spinner2 v-else/>
          </template>
          <template v-else>
            <template v-if="!loading_assign && request.status == 'in_progress' && request.assigned_to_id == user.id">
              <button
                @click.prevent="statusRequest('completed')"
                class="flex items-center justify-center w-1/3 gap-2 px-4 py-2 font-semibold rounded-md bg-cyma-green focus:outline-none"
                :class="{
                  'hover:bg-cyma-green hover:bg-opacity-90':
                    request.status == 'in_progress',
                  'cursor-not-allowed': notAllowableAssignment(),
                  'cursor-not-allowed':
                    request.status == 'rejected' ||
                    request.status == 'completed' ||
                    request.status == 'pending',
                }"
                :disabled="
                  notAllowableAssignment() &&
                  (request.status == 'completed' || request.status == 'rejected')
                "
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-cyma-green-title"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>Acabado</div>
              </button>
              <button
                @click.prevent="statusRequest('rejected')"
                class="flex items-center justify-center w-1/3 gap-2 px-4 py-2 font-semibold rounded-md bg-cyma-red focus:outline-none"
                :class="{
                  'bg-cyma-green hover:bg-cyma-red hover:bg-opacity-90':
                    request.status == 'in_progress',
                  'cursor-not-allowed': notAllowableAssignment(),
                  'cursor-not-allowed':
                    request.status == 'rejected' ||
                    request.status == 'completed' ||
                    request.status == 'pending',
                }"
                :disabled="
                  notAllowableAssignment() &&
                  (request.status == 'completed' || request.status == 'rejected')
                "
              >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6 text-cyma-red-title"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </div>
                <div>Rechazado</div>
              </button>
            </template>
            <Spinner2 v-else-if="loading_assign"/>
            <div v-else-if=" request.status == 'rejected' ||  request.status == 'completed'">
              Esta solicitud a sido {{ formatStatus(request.status) }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div class="flex items-center justify-center h-48 px-4 py-2 mt-4 w-96" v-else>
    <Spinner2 />
  </div>
</template>

<script>
import Spinner2 from "@/components/Spinner2.vue"

export default {
  name: "RequestDetails",
  props: {},
  components: { Spinner2 },
  data() {
    return {
      request: {
        user: {
          name: "",
        },
        assigned_to: {
          name: "",
        },
        status: "",
      },
      loading: true,
      loading_assign: false,
      file: null,
    }
  },
  created() {
    this.getRequest()
    this.user = this.$auth.user()
    this.notAllowableAssignment()
    this.getFileRequest()
  },
  computed: {
    validateAccountant(){
      return this.$store.state.role == 'accountant';
    },
    request_id() {
      return parseInt(this.$route.params.request_id)
    },
    nameComplete() {
      return this.request.user.name + " " + this.request.user.lastname
    },
    assigned_to_name_complete() {
      return (
        this.request.assigned_to.name + " " + this.request.assigned_to.lastname
      )
    },
  },
  methods: {
    // Comprueba si el usuario logueado no es el mismo que esta asignado a la solicitud
    notAllowableAssignment() {
      return this.request.assigned_to_id !== this.user.id
    },
    getRequest() {
      // this.loading = true
      let app = this
      app.axios.get("/requests/" + app.request_id).then((response) => {
        app.request = response.data
        app.loading = false
      })
    },
    statusRequest(status) {
      let app = this
      app.loading_assign = true
      let assigned = this.assigned_to_id ? this.assigned_to_id : this.user.id
      this.axios
      .patch("/requests/" + app.request_id, {
        status,
        assigned_to_id: assigned,
      })
      .then(() => {
          app.loading_assign = false
          this.getRequest()
        })
    },
    getFileRequest() {
      let app = this
      this.axios.get("/file_request/" + app.request_id).then((response) => {
        app.file = response.data
        console.log(app.file, "file")
      })
    },
    formatStatus(state) {
      let status = {
        pending: "Pendiente",
        in_progress: "En progreso",
        completed: "Completado",
        rejected: "Rechazado",
      }
      return status[state]
    },
    formatTypeRequest(req) {
      let request = {
        accounting: "Contabilidad",
        rrhh: "Recursos humanos",
      }
      return request[req]
    },
    formatDate(date) {
      if (date === null) {
        return "Sin fecha"
      }
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      }
      return new Date(date).toLocaleString("es-CL", options)
    },
  },
}
</script>
