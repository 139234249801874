<template>
  <div
    class="py-2 mx-4 sm:mx-6 flex flex-col lg:flex-row justify-between gap-4"
  >
    <div
      id="profile"
      class="rounded-lg shadow-lg bg-white lg:mx-0 w-full lg:w-2/6 dark:bg-black-dark"
    >
      <div class="p-4 md:p-12 lg:px-4 xl:px-12">
        <img :src='company.logo_url' class="block rounded-full shadow-xl h-48 w-48 m-auto bg-cover bg-center">

        <h1 class="mt-6 text-2xl font-bold pt-8 lg:pt-0 text-center lg:text-center dark:text-white">{{company.business_name}}</h1>
        <div class="flex max-w-xs mx-auto text-grey-dark gap-2 overflow-x-auto scroll-mod">
          <div class="text-center p-2">
            <h2 class="text-base font-semibold dark:text-white">{{representatives.length}}</h2>
            <span class="text-gray-500 text-xs dark:text-gray-300">Representantes</span>
          </div>
          <div class="text-center p-2">
            <h2 class="text-base font-semibold dark:text-white">{{partners.length}}</h2>
            <span class="text-gray-500 text-xs dark:text-gray-300">Socios</span>
          </div>
          <div class="text-center p-2">
            <h2 class="text-base font-semibold dark:text-white">0</h2>
            <span class="text-gray-500 text-xs dark:text-gray-300">Clasificados</span>
          </div>
          <div class="text-center p-2">
            <h2 class="text-base font-semibold dark:text-white">0</h2>
            <span class="text-gray-500 text-xs dark:text-gray-300">Inicios</span>
          </div>
        </div>
        <table class="text-xs my-3">
          <tbody>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">RUT</td>
              <td class="px-2 py-2 dark:text-white" v-if="company.rut && company.dv">{{formatRut(company.rut + company.dv)}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Actividad económica</td>
              <td class="px-2 py-2 dark:text-white">
                {{company.activity_gloss}}
              </td>
             <!--  <td class="px-2 py-2">
                <div
                  class=""
                  v-for="(economic_activity, index) in economic_activities"
                  :key="index"
                >
                  <div>
                    {{ economic_activity.description }}
                  </div>
                </div>
              </td> -->
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Domicilio casa matriz</td>
              <td class="px-2 py-2 dark:text-white">
                <div v-for="(address, index) in company.addresses" :key="index">
                  {{ address.street }} {{ address.number }} {{address.city }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Correo electrónico</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.email }}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Sistema tributario</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.subtype_taxpayer_descrip }}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Sucursales</td>
              <td class="px-2 py-2 dark:text-white">
                {{company.operational_unit_description}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Inicio de actividades</td>
              <td class="px-2 py-2 dark:text-white">
                {{formatSimpleDate(company.start_date_activities)}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="rounded-lg shadow-lg bg-white dark:bg-black-dark lg:mx-0 p-4 md:p-12 flex-grow">
      <div class="flex gap-3 mb-8 py-1 overflow-x-auto scroll-mod">
        <div
          class="cursor-pointer p-2 text-sm font-bold text-gray-500 dark:text-white whitespace-nowrap hover:shadow-sm dark:hover:shadow-sm-dark rounded-lg"
          @click="profileMenu = 'company'"
        >
          Mis Empresas
        </div>
        <template v-if="representatives[0] && representatives[0].error_code === 0">
          <div
            class="cursor-pointer p-2 text-sm font-bold text-gray-500 dark:text-white whitespace-nowrap hover:shadow-sm dark:hover:shadow-sm-dark rounded"
            @click="profileMenu = 'transaction'"
          >
            Representante legal
          </div>
        </template>
        <template v-if="validatePartner()">
          <div
            class="cursor-pointer p-2 text-sm font-bold text-gray-500 dark:text-white whitespace-nowrap hover:shadow-sm dark:hover:shadow-sm-dark rounded"
            @click="profileMenu = 'socio'"
          >
            Socios
          </div>
        </template>
        <template>
          <div
            class="cursor-pointer p-2 text-sm font-bold text-gray-500 dark:text-white whitespace-nowrap hover:shadow-sm dark:hover:shadow-sm-dark rounded"
            @click="profileMenu = 'carpetas'"
          >
            Mis carpetas
          </div>
        </template>
      </div>
      <transition name="modal-fade" mode="out-in">
        <div
          v-if="profileMenu === 'company'"
          class="grid sm:grid-cols-2 gap-x-3 gap-y-4 fade-in"
        >
          <div v-for="movement in movements" v-bind:key="movement.id">
            <div class="border p-3 rounded-md dark:bg-background-dark dark:border-background-dark">
              <p>
                <span class="font-bold dark:text-white">{{movement.business_name}}</span>
                <span class="float-right dark:text-white font-bold">$ {{formatNumber(movement.total)}}</span>
              </p>
              <p class="text-gray-500">{{movement.date_sii}}</p>
              <p class="text-gray-500" v-if="movement.account_neto_name">{{movement.account_neto_name}}</p>
              <p class="text-gray-500" v-else>Sin clasificar</p>
            </div>
          </div>
        </div>
        <div v-if="profileMenu === 'transaction' && representatives[0].error_code === 0">
          <div class="h-full">
            <div
              class="my-2 px-2"
              v-for="(representative, index) in representatives"
              :key="index"
            >
              <h2 class="text-xl font-semibold dark:text-white">
                Representante legal n° {{ index + 1 }}
              </h2>
              <div class="my-2">
                <h2 class="font-bold text-sm dark:text-white">Nombre</h2>
                <div class="dark:text-white font-bold">
                  {{ representative.first_names }}
                  {{ representative.paternal_surname }}
                  {{ representative.maternal_surname }}
                </div>
              </div>
              <div class="my-2">
                <h2 class="font-bold text-sm dark:text-white">RUT</h2>
                <div class="dark:text-white font-bold">
                  {{ formatRut(representative.rut + "-" + representative.dv) }}
                </div>
              </div>
              <div class="my-2">
                <h2 class="font-bold text-sm dark:text-white">Fecha de inicio</h2>
                <div class="dark:text-white font-bold">
                  {{ formatSimpleDate(representative.start_date) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="profileMenu === 'socio' && validatePartner()">
          <div class="h-full">
            <div
              class="px-2 my-2"
              v-for="(partner, index) in partners"
              :key="index"
            >
              <h2 class="text-xl font-semibold mb-2 dark:text-white">Socio n° {{ index + 1 }}</h2>
              <div>
                <div class="my-2">
                  <h2 class="font-bold text-sm dark:text-white">Nombre completo</h2>
                  <div class="dark:text-white font-bold">
                    {{ partner.names }} {{ partner.last_name }}
                    {{ partner.mothers_last_name }}
                  </div>
                </div>
                <div class="my-2">
                  <h2 class="font-bold text-sm dark:text-white">Rut</h2>
                  <div class="dark:text-white font-bold">
                    {{ formatRut(partner.rut + "-" + partner.dv) }}
                  </div>
                </div>
                <div class="my-2">
                  <h2 class="font-bold text-sm dark:text-white">Capital</h2>
                  <div class="dark:text-white font-bold">
                    {{partner.profit_sharing}} %
                  </div>
                </div>
                <div class="my-2">
                  <h2 class="font-bold text-sm dark:text-white ">Utilidades</h2>
                  <div class="dark:text-white font-bold">
                    {{partner.equity_participation}} %
                  </div>
                </div>
                <div class="my-2">
                  <h2 class="font-bold text-sm dark:text-white ">Fecha de incorporacion</h2>
                  <div class="dark:text-white font-bold">
                    {{ formatSimpleDate(partner.date_of_incorporation) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { formatRut, formatNumber, formatSimpleDate } from '@/utils/formatters'

export default {
  name: "CompanyIndex",
  props: {
    props_company_id: null,
  },
  data() {
    return {
      company: [],
      representatives: [],
      addresses: [],
      partners: [],
      movements: [],
      economic_activities: [],
      defaultImage: "url('https://source.unsplash.com/MP0IUfwrn0A')",
      profileMenu: "company",
    };
  },
  mounted() {
    this.getCompany();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    props_company_id() {
      this.getCompany();
    },
  },
  methods: {
    formatRut,
    formatNumber,
    formatSimpleDate,
    validatePartner(){
      if(this.partners[0] != undefined){
        this.partners[0].error_code === 0
      } else {
        return false
      }
    },
    getCompany() {
      const app = this;
      this.axios.get("companies/" + app.props_company_id).then((response) => {
        app.company = response.data;
        app.representatives = response.data.representative_actives;
        app.addresses = response.data.addresses;
        app.partners = response.data.partners;
        app.economic_activities = response.data.economic_activities;
        app.movements = response.data.last_movements;
      });
    },
  },
};
</script>
