<template>
  <transition name="modal-fade" appear>
    <div class="modal-mask flex md:justify-center items-center">
      <div class="h-screen w-screen"></div>
      <div class="absolute w-3/5">
        <div
          class="dark:bg-background-dark bg-white rounded-xl py-5 px-4 md:w-full md:h-full md:py-4 md:px-10"
        >
          <div class="modal-header">
            <slot name="header">
              <div class="w-full flex items-center py-4">
                <h1
                  class="font-semibold text-grey-darker w-full text-2xl dark:text-white"
                >
                  Formulario Solicitudes
                </h1>
                <a
                  class="text-6xl dark:hover:text-cian-dark"
                  @click.prevent="$emit('close')"
                >
                  &times;
                </a>
              </div>
            </slot>
          </div>
          <div class="">
            <div class="flex py-1 relative mb-4 ">
              <span class="w-full h-1 bg-cyma-purple dark:bg-cian-dark absolute bottom-0"></span>
              <button
                class="block w-full py-2 text-white font-semibold rounded-t-lg focus:outline-none"
                :class="{
                  'bg-cyma-purple dark:bg-cian-dark text-white': type_request === 'accounting',
                  'text-gray-600': type_request !== 'accounting',
                }"
                @click.prevent="typeChange('accounting')"
              >
                BackOffice Contable
              </button>
              <button
                class="block w-full  py-2 text-white font-semibold rounded-t-lg focus:outline-none"
                :class="{
                  'bg-cyma-purple dark:bg-cian-dark text-white': type_request === 'rrhh',
                  'text-gray-600': type_request !== 'rrhh',
                }"
                @click.prevent="typeChange('rrhh')"
              >
                BackOffice Gestión de Personas
              </button>
            </div>

            <div v-if="type_request==='accounting'" class="fadeIn">
              <v-select
                :options="accountingList"
                class="style-chooser custom-border"
                placeholder="Elige un tipo de solicitud"
                v-model.trim="$v.name.$model"
                :class="{ 'border border-b-0 border-red-400 dark:border-cyma-red-title': $v.$error }"
              />
            </div>
            <div v-if="type_request==='rrhh'" class="fadeIn">
              <v-select
                :options="personList"
                class="style-chooser custom-border"
                placeholder="Elige un tipo de solicitud"
                v-model.trim="$v.name.$model"
                :class="{ 'border border-b-0 border-red-400 dark:border-cyma-red-title': $v.$error }"
              />
            </div>

            <div
              class="bg-red-100 dark:bg-red-dark border border-t-0 border-red-400 dark:border-cyma-red-title text-red-700 dark:text-cyma-red-title px-4 py-2 rounded-bl rounded-br"
              v-if="$v.name.$error"
            >
              Este campo es requerido
            </div>
            <div class="">
              <div class="w-full my-3">
                <textarea
                  class="w-full h-40 py-2 border border-gray-300 px-4 dark:bg-black-dark focus:outline-none focus:ring-1 focus:ring-cyma-purple dark:focus:ring-cian-500 dark:text-gray-100 dark:border-gray-800"
                  name="requestText"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="inserte texto aquí"
                  v-model="description"
                ></textarea>
              </div>
              <label class="w-64 mb-3 flex justify-center items-center px-4 py-2 border border-cyma-purple rounded-lg uppercase cursor-pointer text-sm font-medium text-cyma-purple hover:bg-cyma-purple hover:text-white tracking-wide dark:border-cian-500 dark:text-cian-500 dark:hover:bg-cian-500 dark:hover:text-white" for="file_input">
                <svg class="w-8 h-8" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
                </svg>
                <span class="ml-2 text-base leading-normal">Subir archivo</span>
                <input
                  type="file"
                  id="file_input"
                  class="hidden"
                  ref="fileInput"
                  @change.prevent="uploadFile()"
                >
              </label>
              <template v-if="fileRequest">
                <p>{{ fileRequest.name }}</p>
              </template>
              <div>
                <button
                  v-if="!loading"
                  @click.prevent="sendRequest()"
                  class="bg-cyma-purple text-white font-semibold py-2 px-4 rounded-lg dark:bg-cian-500"
                >
                  Enviar
                </button>
                <div class="flex justify-center items-center mt-4 py-2 px-4" v-else >
                  <Spinner  customClass="h-6 w-6"/>
                </div>
                <p class="text-gray-700 dark:text-gray-200" v-if="submitStatus === 'PENDING'">Enviando...</p>

                <p class="text-gray-700 dark:text-gray-200" v-if="submitStatus === 'OK'">Gracias por su envío!</p>
                <p class="text-gray-700 dark:text-gray-200" v-if="submitStatus === 'ERROR'">Por favor complete el formulario correctamente.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import "@/assets/stylesheets/modal.css"
import { required } from 'vuelidate/lib/validators'
import Spinner from "../Spinner2.vue"


export default {
  name: "modalRequest",
  components: {
    Spinner
  },
  data() {
    return {
      type_request: "accounting",
      fileRequest: null,
      name: "",
      description: "",
      user: null,
      submitStatus: null,
      accountingList: [
        "Iniciación de actividades",
        "Cambio o modificación de dirección",
        "Representantes legales y/o socios",
        "Rectificatoria de F29",
        "Facturación Clientes",
        "Certificado deuda tesorería",
        "Certificado estatuto",
        "Certificado vigencia",
        "Certificado anotaciones",
        "Revisión de situación tributaria",
        "Término de giro"
      ],
      personList: [
        "Tramite?? licencia médica",
        "Carta aviso despido",
        "Generar finiquito",
        "Certificado de cotizaciones",
        "Subir constancia o aviso de término de contrato a Dirección del Trabajo",
        "Certificado F30 y/o F30-1 ",
        "Subir libro de remuneración a la DT mayor a 5 colaboradores",
        "Subir contratos laborales a DT",
        "Subir Finiquito laboral electrónico a DT listo para pago",
        "Respaldo Formato DDJJ 1887",
        "Verificar valores de plan de isapres cuando llega notificación",
        "Contrato de trabajo",
        "Liquidación de sueldo",
        "vacaciones",
        "Pago Leyes Sociales",
        "Subir archivos a PreviRed"
      ],
      loading: false,
    }
  },
  validations: {
    name: {
      required,
    },

  },
  created() {
    this.user = this.$auth.user();
  },
  mounted() {},
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    typeChange(type) {
      this.type_request = type;
      this.name = ""
      this.description = ""
    },
    uploadFile() {
      this.fileRequest = this.$refs.fileInput.files[0];
    },
    updateFile(id) {
      let data = new FormData();
      data.append("id", id)
      data.append("file", this.fileRequest);
      this.axios
        .patch("/update_file_request", data)
        .then(()=> console.log("success"))
        .catch((e) => console.error(e))
    },
    sendRequest() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        return
      }
      this.loading = true
      this.submitStatus = 'PENDING'
      this.axios
        .post("/requests", {
          name: this.name,
          type_request: this.type_request,
          description: this.description,
          user_id: this.user.id,
          company_id: this.company_id,
        })
        .then((response) => {
          this.loading = false;
          if(response.status === 201){
            if (this.fileRequest) {
              this.updateFile(response.data.id)
            }
            this.submitStatus = 'SUCCESS'
            this.$emit("getAllRequests")
            this.$emit("close")
          }
        })
        .catch((e) => {
          console.error('error', e)
          this.loading = false;
          this.submitStatus = 'ERROR'
        });
    },
  },
}
</script>

<style scoped lang="scss">
.fadeIn {
  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.style-chooser ::placeholder {
  font-size: 0.875rem;
}
// .modal-mask-f29 {
//   bottom:0;
//   position:fixed;
//   overflow-y:scroll;
//   overflow-x:hidden;
//   z-index: 100;
//   top: 0;
//   left: 0;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   transition: opacity 0.3s ease;
// }
</style>
