`<template>
  <div class="">
    <transition name="modal">
      <div class="flex modal-mask md:justify-center md:items-center">
        <div class="w-screen h-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="w-screen h-screen px-4 py-5 overflow-auto bg-white dark:bg-background-dark md:h-auto md:max-h-screen md:w-full md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="py-8 text-4xl font-semibold text-grey-darker dark:text-white"
                  v-if="action === 'create'"
                >
                  Agregar Cliente
                </h1>
                <h1 class="py-8 text-4xl font-semibold text-grey-darker dark:text-white" v-else>
                  Actualizar Cliente
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a
                  class="text-6xl dark:hover:text-cian-dark"
                  @click.prevent="$emit('close')"
                >
                  &times;
                </a>
              </div>
            </div>
            <div class="">
              <slot name="body">
                <form class="grid grid-cols-1 gap-2 md:grid-cols-1 lg:grid-cols-1">
                  <div>
                    <div class="flex flex-col gap-5">
                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >RUT</label
                        >
                        <input
                          v-model="rutW" name="rut" ref="rut" id="rut" type="text"
                          placeholder="12.345.678-0" required autofocus maxlength="12"
                          :disabled="action == 'update'"
                          class="input-text"
                        >
                        <div v-if="rut_loading">
                          <Spinner2 customClass="w-5 h-5"/>
                        </div>
                        <div v-else>
                          <p v-if="rut_error" class="text-red-400">No es un rut valido</p>
                          <p v-else-if="!rut_error && rut_error != null" class="text-green-500">Rut valido y verificado</p>
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Nombre</label
                        >
                        <input
                          type="text"
                          disabled
                          class="input-text"
                          v-model="obj.name"
                        />
                      </div>
                    </div>
                  </div>

                  <h3
                    class="label-cymasuite pt-2"
                  >
                    Contacto
                  </h3>
                  <div class="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-3">
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                      >
                        Nombre
                      </label>
                      <input
                        class="input-text"
                        v-model="obj.name"
                        disabled
                        type="text"
                      />
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                      >
                        Cargo
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.position"
                      />
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                      >
                        Teléfono
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.phone"
                      />
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                      >
                        Correo Electrónico
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.contact"
                      />
                    </div>
                  </div>
                  <h3 class="pt-2 my-4 label-cymasuite"
                  >
                    Acuerdo comercial
                  </h3>
                  <div class="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-1">
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                        >Días de caducidad</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        v-model="obj.expiration_date_days"
                        :options="caducityOptions"
                        taggable
                      />
                    </div>
                  </div>
                  <h3 class="pt-2 my-4 text-sm font-semibold uppercase dark:text-gray-500">
                    Clasificación Predeterminada
                  </h3>
                  <div class="grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-3">
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                        >Plan de cuenta</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="accounts[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.account_id"
                        placeholder="Plan de cuentas"
                      ></v-select>
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                        >Sucursal</label
                      >
                      <v-select
                        class="w-full style-chooser custom-border"
                        :options="branch_offices[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.branch_office_id"
                        placeholder="Sucursal"
                      ></v-select>
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="label-cymasuite"
                        >Centro de Costos</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="cost_centers[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.cost_center_id"
                        placeholder="Centro de Costos"
                      ></v-select>
                    </div>
                  </div>
                </form>
              </slot>
            </div>
            <div class="flex justify-center pb-3 pr-5 modal-footer">
              <slot name="footer">
                <div class="w-5/5 mt-8">
                  <button
                    v-if="!loading"
                    class="p-2 px-4 font-bold text-white bg-black border rounded-full hover:bg-white hover:text-black hover:border-black dark:bg-cian-dark dark:border-cian-dark"
                    @click.prevent="submit"
                  >
                    Guardar Información
                  </button>
                  <div v-else class="w-full flex justify-center">
                    <Spinner2 customClass="h-6 w-6" />
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import Spinner2 from "@/components/Spinner2.vue";
export default {
  name: "Add",
  props: {
    id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      caducityOptions: [ '30', '60', '90', '120'],
      error: '',
      loading: false,
      rut_error: null,
      branch_offices: [],
      cost_centers: [],
      accounts: [],
      rutW: null,
      rut_loading: false,
      obj: {
        rut: null,
        name: null,
        business_name: null,
        company_id: null,
        contact: null,
        branch_office_id: null,
        cost_center_id: null,
        date_of_birth: null,
        expiration_date_days: null,
        total_charged: null,
        total_pending_collection: null,
        account_id: null,
        position: null,
        phone: null,
        money_order: null,
      },
    };
  },
  components: {
    Spinner2
},
  watch: {
    rutW(){
      this.rutW = this.cleanRut(this.rutW);
      let len = this.rutW.length - 1;
      if (len > 3) {
        let dv = this.rutW[len];
        let beforeDv = this.rutW.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        this.rutW = beforeDv + '-' + dv;
      }
      this.obj.rut = this.rutW.split('-')[0].split('.').join('') + '-' + this.rutW.split('-')[1]
      if (len > 6 && this.action == 'create'){
        this.getRutData()
      }
    }
  },
  created() {
    this.getOption("/branch_offices", this.branch_offices);
    this.getOption("/cost_centers", this.cost_centers);
    this.getOption("/select_movement_sale", this.accounts);
  },
  mounted() {
    if (this.action == 'update') {
      this.getService();
    }
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    cleanRut(value) {
      if(value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
      }
    },
    errorInRut() {
        this.error = 'RUT Inválido';
        this.$refs.rut.focus();
    },
    formatter(target) {
      let value = target.value.replace(/\./g, "").replace("-", "");
      if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
      } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
        value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
      } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
        value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
      } else if (value.match(/^(\d)(\d{0,2})$/)) {
        value = value.replace(/^(\d)(\d{0,2})$/, "$1.$2");
      }
      console.log(target.value)
      target.value = value;
      let formatedRut = value.split('-')
      let b =  formatedRut[0].split('.')
      let dv = formatedRut[1] || ''
      let rutPoint = b.join('')
      if(formatedRut[1]){
        this.obj.rut = rutPoint + '-' + dv
        this.rutW = rutPoint + '-' + dv
      }
      // this.rutctnr = value;
      // this.dv = arrayRut[1];
      // this.$v.rut.$touch()
    },
    getRutData(){
      this.rut_loading = true
      this.axios
        .get('api/v1/rut_data',{
          params:{
            rut: this.obj.rut
          }
        })
        .then((response) => {
          console.log(response.data)
          this.rut_loading = false
          if(response.data.error){
            this.rut_error = true
          } else {
            this.rut_error = false
            this.obj.name = response.data.razon_social
          }
        })
    },
    getService() {
      this.axios
        .get("clients/" + this.id)
        .then((response) => {
          this.obj = response.data;
          this.obj.expiration_date_days = parseInt(response.data.expiration_date_days) || null
          this.rutW = response.data.rut
        })
        .catch((e) => console.log(e));
    },
    getOption(route, element) {
      this.axios
        .get(route, {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          element.push(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update() {
      this.axios
        .patch(`clients/${this.id}`, this.obj)
        .then(() => {
          this.$swal.fire(
            "Actualizado",
            "Ha sido actualizado con exito",
            "success"
          );
          this.$emit("getAllClients");
          this.$emit("close");
          this.loading = false;
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
          this.loading = false;
        });
    },
    create() {
      this.axios
        .post("clients", this.obj)
        .then(() => {
          this.$swal.fire("Creado", "Ha sido creado con exito", "success");
          this.$emit("getAllClients");
          this.$emit("close");
          this.loading = false;
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
          this.loading = false;
        });
    },
    submit() {
      this.loading = true
      this.obj.company_id = this.company_id;
      this.action === "create" ? this.create() : this.update();
    },
  },
};
</script>
<style scoped></style>`
