<template>
  <div>
    <div name="modal">
      <div class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="flex">
          <div class="w-screen h-screen lg:py-5 lg:px-4 max-h-screen lg:flex lg:items-center">
            <button
              class="absolute top-2 right-2 md:top-4 md:right-6 text-6xl text-white dark:hover:text-cian-dark"
              @click="closeFunction">
              &times;
            </button>
            <div class="w-auto lg:w-full mt-16 md:mt-6 lg:mt-0">
              <vue-calendly url="https://calendly.com/cymasuite/30min" :height="690"></vue-calendly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "modalCalendly",
  data() {
    return {
    };
  },
  methods: {
    closeFunction() {
      this.$emit("close");
    },
  },
};
</script>
