<template>
  <div
    class="
      h-screen
      w-screen
      fixed
      top-0
      left-0
      bg-black bg-opacity-50
      flex
      justify-center
      items-center
      z-50
    "
    v-if="loading"
  >
    <Loader />
  </div>
  <Dialog
    v-else
    @hide="($emit('close'))"
    :visible.sync="showDialog"
    :containerStyle="{ width: '90vw' }"
    :maximizable="true" :modal="true"
  >
    <template #header>
      <div class="text-gray-500 w-full text-lg">
        <h1 class="text-md">
          {{ transaction?.transfer_account_holder_name }}
        </h1>
        <p class="font-semibold text-2xl dark:text-white">
          {{ transaction?.description }}
        </p>
        <p class="text-sm">
          <span v-if="transaction?.recipient_account['holder_name']">
            {{ transaction.recipient_account["holder_name"] }}
            {{ formatRut(transaction.recipient_account["holder_id"]) }} -
          </span>
          <span v-if="transaction?.sender_account['holder_name']">
            {{ transaction.sender_account["holder_name"] }}
            <span v-if="transaction.bank_bill['name'] !== 'Mercadopago'">
              {{ formatRut(transaction.sender_account["holder_id"]) }} -
            </span>
          </span>
          {{ formatSimpleDate(transaction.post_date) }}
        </p>
      </div>
    </template>
    <div>
      <div class="w-full flex">
        <div class="text-gray-500 w-full font-roboto text-lg">
          <p class="text-xl dark:text-white pt-3">
            <span class="font-bold mr-2 pt-1">
              $ {{ formatNumber(transaction.amount) }}
            </span>
          </p>
          <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
            <div>
              <p class="text-gray-500 text-sm dark:text-gray-500">
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Total:</span
                >
                $ {{ formatNumber(total) }}
              </p>
              <p
                class="text-gray-500 text-sm dark:text-gray-500"
                v-if="balance > 0"
              >
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Balance:</span
                >
                $ {{ formatNumber(balance) }}
              </p>
              <p class="text-gray-500 text-sm dark:text-gray-500">
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Monto Conciliado:</span
                >
                $ {{ formatNumber(sum) }}
              </p>
            </div>
            <div>
              <p class="text-gray-500 text-sm dark:text-gray-500">
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Monto Conciliado anteriormente:</span
                >
                $ {{ formatNumber(transaction.balance) }}
              </p>
              <p class="text-gray-500 text-sm dark:text-gray-500">
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Suma General de los montos:</span
                >
                $ {{ formatNumber(sum + transaction.balance) }}
              </p>
              <p class="text-gray-500 text-sm dark:text-gray-500">
                <span class="text-gray-600 dark:text-gray-300 font-semibold"
                  >Saldo:</span
                >
                $
                {{
                  formatNumber(
                    Math.abs(transaction.amount) - transaction.balance - sum
                  )
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
    <div class="h-full grid grid-cols-3 gap-4 pt-2">
          <div class="col-span-3">
            <div class="flex gap-3 items-center">
              <input
                type="text"
                class="input-text mb-2"
                v-model="query"
                v-on:keyup="searchTransaction"
                placeholder="Buscar movimientos"
              />
              <Dropdown class="right-0">
                <template #out-drop>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-9 h-9"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                    />
                  </svg>
                </template>

                <template #in-drop>
                  <div class="flex flex-col">
                    <p
                      class="dark:hover:bg-cian-dark hover:bg-gray-100 cursor-pointer border-b-2 border-gray-100 dark:border-gray-700 p-3"
                      @click.prevent="
                        orderByFilter('date', 'desc', 'Más reciente')
                      "
                    >
                      Más reciente
                    </p>
                    <p
                      class="dark:hover:bg-cian-dark hover:bg-gray-100 cursor-pointer border-b-2 border-gray-100 dark:border-gray-700 p-3"
                      @click.prevent="
                        orderByFilter('date', 'asc', 'Más antiguo')
                      "
                    >
                      Más antiguo
                    </p>
                    <p
                      class="dark:hover:bg-cian-dark hover:bg-gray-100 cursor-pointer border-b-2 border-gray-100 dark:border-gray-700 p-3"
                      @click.prevent="
                        orderByFilter('mount', 'desc', 'Mayor monto')
                      "
                    >
                      Mayor monto
                    </p>
                    <p
                      class="dark:hover:bg-cian-dark hover:bg-gray-100 cursor-pointer border-b-2 border-gray-100 dark:border-gray-700 p-3"
                      @click.prevent="
                        orderByFilter('mount', 'asc', 'Menor monto')
                      "
                    >
                      Menor monto
                    </p>
                  </div>
                </template>
              </Dropdown>
            </div>
            <Accordion
              v-if="movementSelectedCompleted.length > 0"
              class="fade-in mb-5"
            >
              <template #offside>
                {{ movementSelectedCompleted.length }}
                {{
                  movementSelectedCompleted.length == 1
                    ? "Movimiento Seleccionado"
                    : "Movimientos Seleccionados"
                }}
              </template>
              <template #inside>
                <div
                  v-for="(movement, i) in movementSelectedCompleted"
                  :key="i + 'Completed'"
                >
                  <div
                    class="bg-cyma-green px-5 py-4 mt-2 rounded-lg dark:bg-green-700 dark:border-cyma-green-title"
                  >
                    <div class="flex flex-col md:flex-row justify-between">
                      <div class="leading-5 text-gray-500 text-sm md:w-2/3">
                        <div
                          class="font-bold text-lg dark:text-white items-center flex gap-2"
                        >
                          <input
                            checked
                            type="checkbox"
                            :disabled="
                              !movement.classified ||
                              ((sum >= total - transaction.balance ||
                                balance == 0 ||
                                movementsSelected.length === 0) &&
                                balance < sum &&
                                movementsSelected.length >= 1 &&
                                !movementsSelected.includes(
                                  movement.id.toString()
                                ))
                            "
                            @change="checked($event, movement, i)"
                            v-bind:id="movement.id"
                            class="form-tick h-5 w-5 border-white rounded-lg checked:bg-blue-600 checked:border-transparent focus:outline-none"
                          />
                          {{ movement.business_name }}
                        </div>
                        <div
                          class="text-sm tracking-normal text-gray-500 dark:text-gray-200 flex gap-2"
                        >
                          <div
                            class="text-sm font-semibold tracking-normal text-gray-500 dark:text-gray-200"
                          >
                            {{ formatSimpleDate(movement.period) }}
                          </div>
                          {{ movement.folio }} /
                          {{ formatDocType(movement.doc_type) }}
                        </div>
                      </div>
                      <div class="lg:mr-24">
                        <div>
                          <div
                            class="leading-5 text-gray-500 dark:text-gray-200"
                          >
                            Total
                          </div>
                          <div class="flex items-center gap-2">
                            <div class="flex flex-col dark:text-white">
                              <span
                                class="text-lg font-semibold whitespace-nowrap"
                                >$ {{ formatNumber(movement.total) }}</span
                              >
                              <span
                                class="text-xs text-gray-500 dark:text-gray-200"
                                v-if="editBalanceArray.includes(movement.id)"
                              >
                                <span class="text-gray-500 dark:text-gray-200">
                                  Máximo abonar: $
                                  {{
                                    formatNumber(setMax(movement, transaction))
                                  }}
                                </span>
                              </span>
                            </div>
                            <div
                              v-if="
                                movementsSelected.length >= 1 &&
                                movementsSelected.includes(
                                  movement.id.toString()
                                )
                              "
                            >
                              <a
                                title="Modificar monto"
                                @click.prevent="updateEditBalance(movement.id)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5 dark:text-gray-100"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div v-if="movement.balance > 0">
                            Abono $ {{ formatNumber(movement.balance) }}
                          </div>
                          <currency-input
                            v-if="
                              movementsSelected.length >= 1 &&
                              movementsSelected.includes(
                                movement.id.toString()
                              ) &&
                              editBalanceArray.includes(movement.id)
                            "
                            :disabled="movement.check_confirm"
                            currency="CLP"
                            locale="es-CL"
                            :valueRange="{ max: setMax(movement, transaction) }"
                            placeholder="Monto abonar"
                            class="text-grey-darker border border-grey-lighter py-2 px-3 rounded-lg dark:bg-white dark:text-gray-800 dark:border-white mr-2 focus:outline focus:ring-2 focus:ring-cian-100 focus:border-cian-dark  dark:focus:ring-cian-400 dark:focus:border-cian-dark"
                            v-model="movement.balance_manual"
                          />
                          <button
                            class="dark:text-white dark:hover:text-gray-200 text-sm"
                            v-if="
                              !movement.check_confirm &&
                              movementsSelected.length >= 1 &&
                              movementsSelected.includes(
                                movement.id.toString()
                              ) &&
                              editBalanceArray.includes(movement.id)
                            "
                            @click.prevent="
                              movement.check_confirm = true;
                              generateArray(
                                movement.id,
                                movement.total,
                                movement.balance_manual
                              );
                            "
                          >
                            Confirmar
                          </button>
                          <button
                            v-else-if="movement.check_confirm"
                            class="might-overflow dark:text-cian-dark dark:hover:text-white"
                            @click.prevent="movement.check_confirm = false"
                          >
                            Editar
                          </button>
                        </div>
                        <div
                          class="flex items-end"
                          v-if="selectedAmount(movement.id) != false"
                        >
                          <p class="text-xs text-gray-500 dark:text-white">
                            $ {{ selectedAmount(movement.id) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </Accordion>

            <p v-if="orderText" class="ml-2 text-xs dark:text-gray-400">
              Ordenado por: {{ orderText }}
            </p>
            <div class="newbox">
              <Spinner class="mx-auto my-6" v-if="loadingMovements" />
              <div
                v-else
                v-for="(movement, i) in movements"
                :key="i + 'Selectables'"
              >
                <div
                  class="bg-cyma-grey px-5 py-4 mt-2 rounded-lg dark:bg-black-dark dark:border-black-dark"
                >
                  <div
                    v-if="movement.pending_sii"
                    class="text-gray-500 text-sm uppercase px-2 gap-2 flex items-center mb-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                    <span>Pendiente de Acuse de recibo</span>
                  </div>
                  <div
                    v-if="!movement.classified"
                    class="text-gray-500 text-sm uppercase px-2 gap-2 flex items-center mb-1"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                      />
                    </svg>
                    <span>Primero debes clasificar tu movimiento</span>
                  </div>

                  <div class="flex flex-col md:flex-row justify-between">
                    <div class="leading-5 text-gray-500 text-sm md:w-2/3">
                      <div
                        class="font-bold text-lg dark:text-white items-center flex gap-2 uppercase"
                      >
                        <input
                          type="checkbox"
                          :disabled="
                            movement.pending_sii ||
                            !movement.classified ||
                            ((sum >= total - transaction.balance ||
                              balance == 0 ||
                              movementsSelected.length === 0) &&
                              balance < sum &&
                              movementsSelected.length >= 1 &&
                              !movementsSelected.includes(
                                movement.id.toString()
                              ))
                          "
                          @change="checked($event, movement, i)"
                          v-bind:id="movement.id"
                          class="form-tick h-5 w-5 border-white rounded-lg checked:bg-blue-600 checked:border-transparent focus:outline-none"
                        />
                        {{ movement.business_name }}
                      </div>
                      <div
                        class="text-sm tracking-normal text-gray-500 dark:text-gray-200 flex gap-2"
                      >
                        <div
                          class="text-sm font-bold tracking-normal text-gray-500 dark:text-gray-200"
                        >
                          {{ formatSimpleDate(movement.period) }}
                        </div>
                        {{ movement.folio }} /
                        {{ formatDocType(movement.doc_type) }}
                      </div>
                    </div>
                    <div class="lg:mr-24">
                      <div>
                        <div class="leading-5 text-gray-500 dark:text-gray-200">
                          Total
                        </div>
                        <div class="flex items-center gap-2">
                          <div class="flex flex-col dark:text-white">
                            <span
                              class="text-lg font-semibold whitespace-nowrap"
                              >$ {{ formatNumber(movement.total) }}</span
                            >
                            <span
                              class="text-xs text-gray-500 dark:text-gray-200"
                              v-if="editBalanceArray.includes(movement.id)"
                            >
                              <span class="text-gray-500 dark:text-gray-200">
                                Máximo abonar: $
                                {{ setMax(movement, transaction) }}
                              </span>
                            </span>
                          </div>

                          <div
                            v-if="
                              movementsSelected.length >= 1 &&
                              movementsSelected.includes(movement.id.toString())
                            "
                          >
                            <a @click.prevent="updateEditBalance(movement.id)">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="h-5 w-5 dark:text-gray-100"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div v-if="movement.balance > 0">
                          Abono $ {{ formatNumber(movement.balance) }}
                        </div>

                        <currency-input
                          v-if="
                            movementsSelected.length >= 1 &&
                            movementsSelected.includes(
                              movement.id.toString()
                            ) &&
                            editBalanceArray.includes(movement.id)
                          "
                          :disabled="movement.check_confirm"
                          currency="CLP"
                          locale="es-CL"
                          :valueRange="{ max: setMax(movement, transaction) }"
                          placeholder="Monto abonar"
                          class="text-grey-darker border border-grey-lighter py-2 px-3 rounded-lg dark:bg-white dark:text-gray-800 dark:border-white mr-2 focus:outline focus:ring-2 focus:ring-cian-100 focus:border-cian-dark  dark:focus:ring-cian-400 dark:focus:border-cian-dark"
                          v-model="movement.balance_manual"
                        />
                        <button
                          v-if="
                            !movement.check_confirm &&
                            movementsSelected.length >= 1 &&
                            movementsSelected.includes(
                              movement.id.toString()
                            ) &&
                            editBalanceArray.includes(movement.id)
                          "
                          @click.prevent="
                            movement.check_confirm = true;
                            generateArray(
                              movement.id,
                              movement.total,
                              movement.balance_manual
                            );
                          "
                        >
                          Confirmar
                        </button>
                        <button
                          v-else-if="movement.check_confirm"
                          class="might-overflow dark:text-cian-dark dark:hover:text-white"
                          @click.prevent="movement.check_confirm = false"
                        >
                          Editar
                        </button>
                      </div>
                      <div
                        class="flex items-end"
                        v-if="selectedAmount(movement.id) != false"
                      >
                        <p class="text-xs text-gray-500">
                          $ {{ selectedAmount(movement.id) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="movements.length == 0 && !loadingMovements"
                class="py-6 text-gray-400"
              >
                No se han encontrado resultados.
              </div>
            </div>
          </div>
    </div>
    </div>
    <template #footer>
      <div class="modal-footer flex justify-center">
        <slot name="footer">
          <div>
            <button
              class="bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white py-2 px-4 text-center mb-4 rounded-full dark:border-cian-dark dark:bg-cian-dark"
              @click.prevent="submit"
            >
              Conciliar Movimientos
            </button>
          </div>
        </slot>
      </div>
    </template>
  </Dialog>
</template>
<script>
// import the component
// import the styles
import 'primeicons/primeicons.css'
import { formatDocType, formatRut, formatNumber, formatSimpleDate } from "@/utils/formatters.js";
import Dropdown from "@/components/dropdowncustom.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Loader from "@/components/loader.vue";
import Spinner from "@/components/Spinner2.vue";
import Accordion from "@/components/Accordion.vue";
import "@/assets/stylesheets/modal.css";

export default {
  name: "newAccount",
  props: {
    action: String,
    account: Object,
    showModal: Boolean,
    parents: Array,
    id: Number,
  },
  emits: ["close"],
  data() {
    return {
      showDialog: false,
      user: null,
      query: null,
      movements: [],
      transaction: [],
      movementSelectedCompleted: [],
      movementsSelected: [],
      editBalanceArray: [],
      balance: 0,
      sum: 0,
      total: 0,
      loading: false,
      movementAmount: [],
      balanceTotal: 0,
      orderMount: null,
      orderDate: null,
      orderText: null,
      debounceTimer: null,
      loadingMovements: false,
    };
  },
  created() {
    this.user = this.$auth.user;
    this.setTransaction();
    this.recommended();
    this.searchTransaction();
  },
  components: {
    Loader,
    Dropdown,
    Spinner,
    Accordion,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    formatDocType,
    formatRut,
    formatNumber,
    formatSimpleDate,
    orderByFilter(filter, value, text) {
      if (filter == "mount") {
        this.orderMount = value;
        this.orderDate = null;
      }
      if (filter == "date") {
        this.orderMount = null;
        this.orderDate = value;
      }
      this.orderText = text;
      this.searchTransaction();
    },
    selectedAmount(id) {
      let array = this.movementAmount.find((e) => {
        return e[0] == id;
      });
      if (array == undefined) {
        return false;
      }
      return this.formatNumber(array[1]);
    },
    setMax(movement, transaction) {
      // Si existe balance manual
      let movementTotal = Math.abs(movement.total);
      let movementTransaction = Math.abs(transaction.amount);
      let totalExceptMov = this.movementAmount.filter((e) =>  e[0] != movement.id).reduce(
          (acc, prod) => acc + prod[1],
          0
        )
      let amount_movement = 0;
      if (movement.balance) {
        if (movement.balance > movementTransaction - totalExceptMov) {
          amount_movement = movementTransaction - totalExceptMov;
        } else {
          amount_movement = movement.balance;
        }
      } else if (movementTransaction <= movementTotal) {
        amount_movement = movementTransaction;
      } else {
        amount_movement = movement.total;
      }
      return amount_movement;
    },
    updateEditBalance(id) {
      let app = this;
      const index = app.editBalanceArray.indexOf(id);
      if (index > -1) {
        app.editBalanceArray.splice(index, 1);
      } else {
        app.editBalanceArray.push(id);
      }
    },
    generateArray(movement_id, total, manual) {
      // delete last
      let index = this.movementAmount.findIndex((e) => e[0] == movement_id);
      this.movementAmount.splice(index, 1);
      // Add new
      let array = [movement_id, manual];
      this.movementAmount.push(array);
      let all = this.movementAmount.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );
      all.length > 0 ? (this.sum = parseInt(all[1])) : (this.sum = 0);
      this.balance = this.total - this.transaction.balance - this.sum;
    },
    checked(event, movement, i) {
      this.loadingMovements = true;
      if (event.target.checked) {
        this.movementSelectedCompleted.push(movement);
        this.movementsSelected.push(event.target.id);
        let movementTotal = Math.abs(movement.balance);
        this.movements.splice(i, 1);
        let card = event.target;
        card.checked = false;
        // let movementTransaction = Math.abs(this.transaction.amount);
        // console.log(movementTransaction, "MTransacttion")
        // dos abonos sobre el mismo movimiento.
        if (this.balance > movementTotal) {
          // Si el total de la transaccion es menor al total.
          let array = [parseInt(event.target.id), movementTotal];
          this.movementAmount.push(array);
        } else {
          let array = [parseInt(event.target.id), this.balance];
          this.movementAmount.push(array);
        }
      } else {
        let card = event.target;
        card.checked = true;
        this.movements.unshift(movement);
        const index = this.movementsSelected.indexOf(event.target.id);
        this.movementsSelected.splice(index, 1);
        this.movementSelectedCompleted.splice(i, 1);
        let array_index = this.movementAmount.indexOf([
          event.target.id,
          movement.total,
        ]);
        this.movementAmount.splice(array_index, 1);
        movement.balance_manual = 0;
      }
      let all = this.movementAmount.reduce(
        (r, a) => a.map((b, i) => (r[i] || 0) + b),
        []
      );
      all.length > 0 ? (this.sum = parseInt(all[1])) : (this.sum = 0);
      this.balance = this.total - this.transaction.balance - this.sum;
      this.loadingMovements = false;
    },
    submit() {
      this.action === "update" ? this.update() : this.create();
    },
    setTransaction() {
      this.loading = true
      const app = this;
      this.axios.get("/transactions/" + app.id).then((response) => {
        app.transaction = response.data;
        app.total = Math.abs(response.data.amount);
        this.showDialog = true
        this.loading = false
        // value abs
        app.balance = response.data.total - response.data.balance;
      });
    },
    searchTransaction() {
      const app = this;
      app.loadingMovements = true;
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.axios
          .get("/transaction-search/" + this.id, {
            params: {
              order_mount: app.orderMount,
              order_date: app.orderDate,
              query: app.query,
              not_ids: this.movementsSelected,
            },
          })
          .then((response) => {
            app.movements = response.data;
          })
          .finally(() => {
            app.loadingMovements = false;
          });
      }, 500); // 500 ms (medio segundo) de debounce
    },
    recommended() {
      const app = this;
      this.axios.get("/transaction-recommended/" + app.id).then((response) => {
        app.movements = response.data;
      });
    },
    update() {
      // update transactions
      let app = this;
      app.loading = true;
      app.movements = [];
      this.axios
        .patch("transactions/" + app.transaction.id, {
          movementsAmountArray: app.movementAmount,
        })
        .then(() => {
          this.$emit("getTransactions");
          app.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>
<style>
.newbox {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.p-dialog {
  @apply bg-white dark:bg-background-dark;
}
.p-dialog .p-dialog-header{
  padding: 1.5rem 1.5rem 0 1.5rem !important;
  font-family: 'Lato', sans-serif;
}
.p-dialog .p-dialog-header, .p-dialog .p-dialog-content, .p-dialog .p-dialog-footer, .p-dialog .p-dialog-header .p-dialog-header-icon {
  background: transparent !important;
  @apply dark:text-white;
}
</style>
