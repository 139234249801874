<template>
  <div v-if="!validatedOrder" class="flex gap-8 py-10 px-20">
    <div class="flex min-h-full border-solid border-2 rounded-md py-8 px-8 w-3/6">
      <div class="flex flex-col grid content-between w-full">
        <div class="flex flex-col items-center">
          <div class="text-4xl text-gray-600 text-center font-bold border-b w-full">{{ subscription.name }}</div>
        </div>
        <div class="flex flex-col text-center w-full py-8">
          <div class="text-gray-800 font-bold text-3xl text-low">UF {{ formatNumber(subscription.total_amount) }} + IVA</div>
          <div class="text-gray-800 font-bold text-lg mt-2">Per Month</div>
        </div>
        <div class="flex grid grid-cols-2 justify-between gap-2">
          <div v-for="(product, index) in subscription.products" :key="index">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-1/12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <div class="text-base text-gray-700 mb-1 w-11/12">Modulo de {{ product.name }}</div>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-full w-1/12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="text-base text-gray-700 mb-1 w-11/12">Usuarios conectados: {{ subscription.maximum_admin_users }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex min-h-full border-solid border-2 rounded-md py-8 px-8 w-3/6">
      <div class="flex flex-col grid content-between w-full">
        <div class="flex flex-col items-start">
          <div class="text-4xl text-gray-600 text-end font-bold border-b w-full">Resumen de pago</div>
        </div>
        <div class="flex flex-col gap-4 py-8">
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="flex justify-between text-base text-gray-700 mb-1 w-11/12">
              <div>
                Subtotal:
              </div>
              <div>
                $ {{ formatNumber(clpValue) }}
              </div>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="flex justify-between text-base text-gray-700 mb-1 w-11/12">
              <div>
                IVA:
              </div>
              <div>
                $ {{ formatNumber(ivaValue) }}
              </div>
            </div>
          </div>
          <div class="flex items-center gap-2 w-full">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <div class="flex justify-between text-base text-gray-700 mb-1 w-11/12">
              <div>
                Monto total:
              </div>
              <div>
                $ {{ formatNumber(totalAmount) }}
              </div>
            </div>
          </div>
          <div class="flex flex-col gap-2 w-full">
            <div class="font-bold text-xl text-gray-600 w-full">
              Opciones de pago:
            </div>
            <div class="flex w-full items-center border border-opacity-0 rounded p-4">
              <div class="grid justify-items-center w-1/6">
                <input type="radio" id="methodPay" value="webpay" v-model="methodPay">
              </div>
              <div class="flex flex-col gap-2 w-5/6">
                <div class="text-right font-bold text-gray-800">
                  RedCompra - Tarjeta de débito o crédito
                </div>
                <div class="text-right text-gray-600">
                  Será re-direccionado a Webpay Plus (Rest) para realizar su pago seguro
                </div>
                <div class="grid justify-items-end pl-8">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full px-20">
          <div
            @click.prevent="methodPay ? goToPay() : alertMessage()"
            :class="methodPay ? 'border-gray-400 text-black cursor-pointer' : 'border-gray-300 text-gray-400'"
            class="w-full border-2 px-4 py-3 rounded-lg font-bold uppercase text-center text-base"
          >
            Ir a pagar
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <page403 />
  </div>
</template>

<script>
  import { formatNumber, formatToDashedDate } from "@/utils/formatters";
  import routerMixin from "@/mixins/routerMixin";
  import page403 from "@/components/pages/403";
  export default {
    name: "SelectPayment",
    props: {
      subscriptionId: Number,
    },
    mixins: [routerMixin],
    data() {
      return {
        validatedOrder: this.$store.state.validatedOrder,
        subscription: {},
        userId: null,
        methodPay: null,
        todayUf: 0,
        clpValue: 0,
        ivaValue: 0,
        totalAmount: 0,
        marketplace: this.$store.state.marketplace
      }
    },
    async created () {
      await this.getTodayUf()
      await this.getSubscription()
    },
    components: {
      page403,
    },
    mounted() {
      this.userId = this.$auth.user().id;
    },
    methods: {
      formatNumber,
      formatToDashedDate,
      alertMessage() {
        alert("Seleccione un medio de pago")
      },
      formatClp(value, todayUf) {
        let response  = (value * todayUf)
        return response
      },
      getIva(value) {
        let response = (value * 0.19)
        return response
      },
      getTotalAmount(clpValue, ivaValue) {
        let response = (clpValue + ivaValue)
        return response
      },
      goToPay() {
        const cur = this
        const accessToken = cur.$cookie.get("access-token")
        this.axios
        .post(cur.marketplace + "/api/v1/orders", {
          user_id: cur.userId,
          subscription_id: cur.subscriptionId,
          method_pay: cur.methodPay,
          headers: {
            authorization: "Bearer " + accessToken
          }
        })
        .then(() => {
          cur.goTo("PaymentPlan")
        })
      },
      getTodayUf() {
        const cur        = this
        const date       = new Date()
        const today_date = cur.formatToDashedDate(date)
        return this.axios
        .get(`https://mindicador.cl/api/uf/${ today_date }`, {
          transformRequest: (data, headers) => {
            delete headers.common
            return data
          }
        })
        .then((response) => {
          cur.todayUf = response.data.serie[0]["valor"]
        })
      },
      getSubscription() {
        const cur = this
        const accessToken  = cur.$cookie.get("access-token")
        return this.axios
        .get(cur.marketplace + '/api/v1/subscriptions/' + cur.subscriptionId, {
          headers: {
            authorization: "Bearer " + accessToken
          }
        })
        .then((response) => {
          cur.subscription = response.data
          cur.clpValue     = cur.formatClp(cur.subscription.total_amount, cur.todayUf)
          cur.ivaValue     = cur.getIva(cur.clpValue)
          cur.totalAmount  = cur.getTotalAmount(cur.clpValue, cur.ivaValue)
        })
      }
    }
  };
</script>
