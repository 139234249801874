<template>
  <div>
    <transition name="modal">
      <div class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-50" v-if="loading">
        <Loader/>
      </div>
      <div class="modal-mask flex md:justify-center md:items-center" v-else>
        <div class="h-screen w-screen"></div>
        <div class="absolute md:w-full lg:w-8/12">
          <div class="
              dark:bg-background-dark
              bg-white
              w-screen
              h-screen md:h-auto
              overflow-auto
              py-5
              px-4
              md:w-full md:max-h-screen md:py-4 md:px-10">
            <div class="modal-header flex ml-5 mb-4">
              <h1 class="flex-auto font-semibold ext-grey-darker py-8 text-4xl dark:text-white">Resumen de movimientos</h1>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl" @click.prevent="closeFunction">
                  &times;
                </a>
              </div>
            </div>
            <div class="modal-body flex flex-col lg:flex-row justify-between">
              <slot name="body">
                <div class="w-12/12 w-full py-8 px-4">
                  <div class="bg-white shadow-xl rounded-lg dark:bg-background-dark-disabled">
                    <div class="p-4">
                      <p
                        class="uppercase tracking-wide text-sm font-bold text-gray-700 dark:text-white"
                      >
                        {{buyObj.business_name}}
                      </p>
                      <p
                        class="text-gray-600 dark:text-white"
                      >
                        {{formatDocType(buyObj.doc_type)}}
                      </p>
                      <p class="text-gray-500 dark:text-white">
                        {{buyObj.folio}}
                      </p>
                      <p class="text-4xl text-gray-900 font-bold dark:text-white">
                        ${{ formatNumber(buyObj.total) }}
                      </p>
                      <p class="text-gray-700 dark:text-white">
                        {{ formatCompleteDate(buyObj.period) }}
                      </p>
                    </div>
                  </div>
                  <div>
                    <table class="table-auto border dark:border-black-dark w-full min-w-full mt-8 dark:text-white">
                      <thead>
                        <tr class="dark:border-black-dark w-full">
                          <th class="py-2 whitespace-nowrap text-left pl-2 md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Fecha</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Plan de cuentas</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Debe</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Haber</th>
                          <th class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold">Eliminar</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            account_movement, index
                          ) in account_movements_record"
                          :key="index"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2">{{ formatSimpleDate(account_movement.period) }}</td>
                          <td class="py-2 whitespace-nowrap uppercase">{{account_movement.account_name}}</td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.debit) }}</td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.credit) }}</td>
                          <td></td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>
                      </tbody>

                      <tbody>
                        <tr
                          v-for="(
                            account_movement, index
                          ) in account_movements_paid"
                          :key="index"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2">{{ formatSimpleDate(account_movement.period) }}</td>
                          <td class="py-2 whitespace-nowrap uppercase">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                            <span
                              v-if="account_movement.bank_transaction"
                              class="cursor-pointer text-gray-400 hover:text-cian-dark dark:text-cian-dark dark:hover:text-white"
                              v-on:click="goTo('transaction', account_movement.bank_transaction.transaction_id)"
                            >
                              {{ account_movement.bank_transaction.transaction_id }}
                            </span>
                          </td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.debit) }}</td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.credit) }}</td>
                          <td v-if="index === account_movements_paid.length - 1" class="text-center">
                            <a v-on:click="deleteFunction(account_movement.id)">
                              <Times/>
                            </a>
                          </td>
                          <td v-else></td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>

                        <tr
                          v-for="(
                            account_movement, index_bill
                          ) in account_movements_bill"
                          :key="index_bill"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2">{{ formatSimpleDate(account_movement.period) }}</td>
                          <td class="py-2 whitespace-nowrap uppercase">
                            {{account_movement.account_name}}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                            <span
                              v-if="account_movement.bank_transaction"
                              class="cursor-pointer text-gray-400 hover:text-cian-dark dark:text-cian-dark dark:hover:text-white"
                              v-on:click="goTo('transaction', account_movement.bank_transaction.transaction_id)"
                            >
                              {{ account_movement.bank_transaction.transaction_id }}
                            </span>
                          </td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.debit) }}</td>
                          <td class="py-2 whitespace-nowrap">{{ formatNumber(account_movement.credit) }}</td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                          <td v-if="index_bill === account_movements_bill.length - 1" class="text-center">
                            <a v-on:click="deleteFunction(account_movement.id)">
                              <Times/>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <span v-if="!buyObj.doble_match" class="float-right mt-4">
                      <a
                        class="gap-4 flex items-center"
                        @click.prevent="movementSii()"
                        >
                        Solucionar Problema
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                        </svg>
                      </a>
                    </span>
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { formatDocType, formatNumber, formatSimpleDate, formatCompleteDate } from "@/utils/formatters.js";
import "@/assets/stylesheets/modal.css";
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from 'vuelidate/lib/validators';
import Loader from "@/components/loader.vue"
import Times from "@/components/Icons/Times.vue"

export default {
  name: "newBuy",
  props: {
    buy_id: { default: null },
  },
  data() {
    return {
      user: {},
      clients: [],
      providers: [],
      accounts: [],
      saldo: 0,
      min: 0,
      max: 0,
      disabled: false,
      buyObj: {
        type_movement: 1,
        coin: null,
        total: null,
        period: null,
        responsable: null,
        folio: null,
        company_id: null,
        client_id: null,
        file_movement: null,
        movement_status: null,
        doble_match: null,
        classified: null,
        checksii: null,
        account_movements: [],
      },
      payObj: {
        account_id: null,
        movement_id: null,
        total_amount: 0,
        period: null,
        comentary: null,
        type_account_movement: 'paid',
        type_movement: 'buy',
        company_id: null,
      },
      loading: true,
    };
  },
  components: {
    Loader,
    Times
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    movement_status() {
      return this.buyObj.movement_status;
    },
    account_movements_record() {
      return this.buyObj.account_movements.filter(account_movement => account_movement.type_account_movement == 'record');
    },
    account_movements_paid() {
      return this.buyObj.account_movements.filter(account_movement => account_movement.type_account_movement == 'paid');
    },
    account_movements_bill() {
      return this.buyObj.account_movements.filter(account_movement => account_movement.type_account_movement == 'bill');
    }
  },
  watch: {
    movement_status() {
      this.buyObj.movement_status;
    }
  },
  validations: {
    payObj:{
      account_id: {
        required
      },
      total_amount: {
        required,
        minValue(value) {
          return value > 0;
        },
      },
      period: {
        required
      }
    }
  },
  mounted() {
    this.getBuy();
    this.getAccounts();
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    formatCompleteDate,
    getAccounts() {
      let app = this;
      this.axios.get("select_paid_or_bill", {
        params: {
          company_id: app.company_id
        }
      }).then((response) => {
        this.accounts = response.data;
      });
    },
    closeFunction(){
      this.$emit('getBuys')
      this.$emit('close')
    },
    totalAmount(amount){
      let sum = amount.reduce((acc,e)=> acc + e.credit, 0);
      this.min = 0;
      this.max = this.buyObj.total - sum
    },
    getFile() {
      this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
        this.url = e.data.url;
        this.filename = e.data.name;
        console.log(e.data.url);
      });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    goTo(url, data = null) {
      const request = this.$router.resolve({ name: url }).href;
      window.open(request + '?query=' + data, '_blank');
    },
    getClients() {
      this.axios.get("clients").then((response) => {
        this.clients = response.data;
      });
    },
    formatMoney(e){
      // this.payObj.total_amount = "$" + this.formatNumber(e)
      this.payObj.total_amount = e
    },
    getBuy() {
      this.axios.get('movements/' + this.buy_id).then((res) => {
        this.payObj.movement_id = res.data.id;
        this.buyObj.movement_id = res.data.id;
        this.buyObj.total = Number(res.data.total);
        this.buyObj.business_name = res.data.business_name;
        this.buyObj.doc_type = res.data.doc_type;
        this.buyObj.period = res.data.period;
        this.buyObj.company_id = res.data.company_id;
        this.buyObj.user_id = res.data.user_id;
        this.buyObj.folio = res.data.folio;
        this.buyObj.account_movements = res.data.account_movements_order;
        this.buyObj.movement_status = res.data.movement_status;
        this.buyObj.doble_match = res.data.doble_match;
        this.buyObj.classified = res.data.classified;
        this.buyObj.checksii = res.data.checksii;
        this.getUser(res.data.user_id);
        // tengo la duda if paid or bill
        this.totalAmount(res.data.account_movements.filter(account_movement => account_movement.type_account_movement == 'paid'));
        this.loading = false;
      });
    },
    movementSii() {
      let app = this;
      app.loading = true;
      this.axios.get("/movement-sii", {
        params: {
          company_id: app.company_id,
          id: app.buyObj.movement_id
        }
      }).then((response) => {
        console.log('response', response)
        app.getBuy();
        setTimeout(() => {
          app.loading = false;
        }, 5000)
      });
    },
    replaceName(name){
       switch (name) {
        case 'paid':
        return 'Pagado'
      }
    },
    deleteFunction(account_movement_id) {
      this.axios.delete("account_movements/" + account_movement_id).then(() => {
        this.getBuy();
      });
    },
    submit() {
      console.log('submit!')
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        this.disabled = true;
        this.payObj.company_id = this.$store.state.company_id;
        this.axios
        .post("/account_movements", this.payObj)
        .then(() => {
          this.getBuy();
          this.payObj = {
            account_total_id: null,
            account_neto_id: null,
            movement_id: null,
            debit: null,
            total_amount: 0,
            credit: 0,
            period: null,
            comentary: null,
            type_account_movement: 'paid',
            type_movement: 'buy',
            company_id: null
          };
        })
        .catch((error) => alert(error));
        setTimeout(() => {
          this.submitStatus = 'OK';
          this.disabled = false;
        }, 500)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
@media (min-width: 1280px) {
  .modal-container {
    min-height: 86vh;
  }
}
</style>
