<template>
  <div>
    <section class="py-20 bg-gray-100 text-gray-800 px-4 xl:px-6">
      <div class="px-4 mx-auto xl:max-w-7xl">
        <div class="max-w-2xl mx-auto mb-16 text-center">
          <span class="font-bold tracking-wide uppercase text-cyma-purple"
            >Precios</span
          >
          <h2 class="text-4xl font-bold lg:text-5xl">Elija su mejor plan</h2>
          <!-- Error -->
          <div
            v-if="error"
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong class="font-bold">{{ error }} </strong>
            <span class="block sm:inline">Por favor revisar sus compras.</span>
            <span @click.prevent="goTo('MyPlan')" class="underline">
              Revisar Aqui!</span
            >
            <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                @click.prevent="error = ''"
                class="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <title>Close</title>
                <path
                  d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="flex flex-wrap items-stretch -mx-4">
          <div class="sm:flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/4 lg:mb-0">
            <div
              class="w-full flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-gray-50"
            >
              <div class="space-y-2">
                <h4 class="text-2xl font-bold">Microempresa</h4>
                <span class="text-5xl xl:text-6xl font-bold"
                  >2,5
                  <span class="text-sm tracking-wide">UF + IVA</span>
                </span>
              </div>
              <p class="leading-relaxed text-gray-600">
                Para emprendedores y pequeños negocios.
              </p>
              <ul class="flex-1 space-y-2 text-gray-600">
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Registro de Gastos</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Clasificación automática</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Control de cobros y pagos</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Formulario 29</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Registro y Control de clientes/proveedores</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Conciliación automática de Bancos</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Conexión Multi-bancos</span>
                </li>
              </ul>
              <ButtonPricing
                @click="goToPay(1)"
                variant="primary"
              >
                Comenzar
              </ButtonPricing>
            </div>
          </div>
          <div class="sm:flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/4 lg:mb-0">
            <div
              class="w-full flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-cyma-purple text-gray-50"
            >
              <div class="space-y-2">
                <h4 class="text-2xl font-bold">Pequeña</h4>
                <span class="text-5xl xl:text-6xl font-bold"
                  >5
                  <span class="text-sm tracking-wide">UF + IVA</span>
                </span>
              </div>
              <p class="leading-relaxed">
                Solución avanzada para empresas en crecimiento.
              </p>
              <ul class="flex-1 space-y-2">
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Todo en microempresa</span>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Sincronización SII</span>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Registro Compras y Ventas</span>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Registro de Honorarios y BTE</span>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Categorización de compras</span>
                </li>
                <li class="flex items-center space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Gestión de hasta 5 trabajadores</span>
                </li>
              </ul>
              <ButtonPricing
                @click="goToPay(2)"
                variant="secondary"
              >
                Comenzar
              </ButtonPricing>
              <!-- <button
                v-else
                @click.prevent="goToPay(2)"
                class="inline-block w-full px-5 py-3 font-bold tracking-wide text-center rounded bg-gray-100 text-cyma-purple"
              >
                Comenzar
              </button> -->
            </div>
          </div>
          <div class="sm:flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/4 lg:mb-0">
            <div
              class="w-full flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-gray-50"
            >
              <div class="space-y-2">
                <h4 class="text-2xl font-bold">Mediana</h4>
                <span class="text-5xl xl:text-6xl font-bold"
                  >10
                  <span class="text-sm tracking-wide">UF + IVA</span>
                </span>
              </div>
              <p class="leading-relaxed text-gray-600">
                Plan premium para empresas medianas y escalables.
              </p>
              <ul class="flex-1 space-y-2 text-gray-600">
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Todo en plan pequeña</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Recomendación de conciliación bancaria</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Métricas y datos bancarios</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Gestión de hasta 10 trabajadores</span>
                </li>
              </ul>
              <ButtonPricing
                @click="goToPay(3)"
                variant="primary"
              >
                Comenzar
              </ButtonPricing>
            </div>
          </div>
          <div class="sm:flex w-full mb-8 sm:px-4 md:w-1/2 lg:w-1/4 lg:mb-0">
            <div
              class="w-full flex flex-col p-6 space-y-6 rounded shadow sm:p-8 bg-gray-50"
            >
              <div class="space-y-2">
                <h4 class="text-2xl font-bold">Gran empresa</h4>
                <span class="text-5xl xl:text-6xl font-bold"
                  >20
                  <span class="text-sm tracking-wide">UF + IVA</span>
                </span>
              </div>
              <p class="leading-relaxed text-gray-600">
                Para corporaciones y empresas de amplio alcance.
              </p>
              <ul class="flex-1 space-y-2 text-gray-600">
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Todo en planes anteriores</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Configuración avanzada</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Integraciones</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Acceso API</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Gestión de hasta 25 trabajadores</span>
                </li>
                <li class="flex items-start space-x-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    class="flex-shrink-0 w-6 h-6 text-cyma-purple"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span>Acceso a equipo especializado</span>
                </li>
              </ul>
              <ButtonPricing
                @click="goToPay(4)"
                variant="primary"
              >
                Comenzar
              </ButtonPricing>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="px-4 py-20 mx-auto sm:px-6 lg:px-8 xl:max-w-7xl">
        <PricingComparisonMobile :plans="plans" />
        <div class="hidden lg:block">
          <div class="max-w-2xl mx-auto mb-20 text-center font-ubuntu">
            <h2 class="text-3xl font-bold">
              Compara cada plan
            </h2>
          </div>
          <table class="w-full h-px table-auto font-ubuntu">
            <thead class="sticky top-0 bg-white">
              <tr>
                <th></th>
                <th
                  class="px-6 pb-4 pt-2 text-lg leading-6 font-semibold text-left text-gray-900"
                  scope="col"
                >
                  Planes mensuales
                </th>
                <th
                  v-for="(plan,i) in plans"
                  :key="i"
                  class="w-1/6 px-6 pb-4 text-lg leading-6 font-medium text-center text-gray-900"
                  scope="col"
                >
                  {{  plan.title }}
                </th>
              </tr>
            </thead>
            <tbody class="border-t border-gray-200 divide-y divide-gray-300">
              <tr>
                <td rowspan="2"></td>
                <th
                  class="px-6 py-8 text-sm font-semibold text-left text-gray-900 align-top"
                  scope="row"
                ></th>
                <td
                  v-for="(plan, i) in plans"
                  :key="'price' + i"
                  class="h-full px-6 py-8 align-top"
                >
                  <div class="relative table w-full h-full">
                    <div class="mt-2" style="text-decoration: none">
                      <div class="text-2xl font-extrabold text-center">
                        {{ plan.price }} UF
                        <small class="text-xs font-medium text-gray-400"
                          >+ IVA</small
                        >
                      </div>
                      <ButtonPricing
                        @click="goToPay(plan.id)"
                        variant="primary"
                        class="mt-2"
                      >
                        Lo quiero
                      </ButtonPricing>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td
                  class="px-6 py-5 text-sm font-semibold text-left text-hex-2B4452"
                  scope="row"
                >
                  Si vendo entre
                </td>
                <td
                  v-for="({ifSellBetween}, i) in plans"
                  :key="'range' + i"
                  class="px-4 py-5 text-sm text-center"
                >
                  {{ ifSellBetween }}
                </td>

              </tr>
              <tr class="bg-gray-50">
                <td class="py-2 px-4"></td>
                <th
                  class="py-2 pl-6 text-sm font-semibold text-left text-gray-900"
                  colspan="5"
                  scope="colgroup"
                >
                  Incluye
                </th>
              </tr>
              <tr>
                <td rowspan="18" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Gestión Contable
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Empresas conectadas
                </th>
                <td v-for="({accountingManagement}, i) in plans"
                  class="px-4 py-5 text-sm text-center"
                  :key="'numberCompaniesConnected' + i"
                >
                  {{ accountingManagement.numberCompaniesConnected }}
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Sincronización Automática SII
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'automaticSynchronizationSII' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasAutomaticSIISynchronization">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Dashboard
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'dashboard' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasDashboard">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Clasificación Automática
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'automaticClassification' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasAutomaticClassification">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Monitoreo Obligaciones Tributarias
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'monitoringTaxObligations' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasMonitoringTaxObligations">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de Ventas
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'salesRegister' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasSalesRegister">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de Compras
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'purchasesRegister' + i"
                  class="px-6 py-5 text-center text-sm"
                >
                  <template v-if="accountingManagement.hasPurchasesRegister">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de Honorarios
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'feeRegister' + i"
                  class="px-6 py-5 text-center text-sm"
                >
                    <template v-if="accountingManagement.hasFeeRegister">
                      <IconCheck class="mx-auto" />
                      <span class="sr-only">Sí</span>
                    </template>
                    <template v-else>
                      <XMarkIcon class="mx-auto" />
                      <span class="sr-only">No</span>
                    </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de Honorarios de terceros
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'thirdPartyFeeRegister' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasThirdPartyFeeRegister">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de Gastos
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'expenseRegister' + i"
                  class="px-4 py-5 text-sm"
                >
                  <template v-if="accountingManagement.hasExpenseRegister">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Formulario 29
                </th>

                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'form29' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasF29">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro y Control de clientes
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'customerRegistrationManagement' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasCustomerRegistrationManagement">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Control de Cobros
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'collectionControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasCollectionControl">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro y Control de proveedores
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'supplierRegistrationControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasSupplierRegistrationControl">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Control de pagos
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'paymentControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasPaymentControl">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                  </template>
                </td>
              </tr>

              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Formulario 22 empresa
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'form22Company' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasF22Company">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Categorización de Compras
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'purchaseCategorization' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasPurchaseCategorization">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Recomendación de clasificación
                </th>
                <td
                  v-for="({accountingManagement}, i) in plans"
                  :key="'classificationRecommendation' + i"
                  class="px-6 py-5"
                >
                  <template v-if="accountingManagement.hasClassificationRecommendation">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="7" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Mi Banco
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">
                    Cantidad de Bancos sincronizados hasta
                  </div>
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'numberSynchronizedBanks' + i"
                  class="px-4 py-5 text-sm text-center"
                >
                  <div class="mt-10">{{ myBank.numberSynchronizedBanks }}</div>
                </td>
              </tr>

              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Sincronización automática
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'automaticSynchronization' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myBank.hasAutomaticSynchronization">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Métricas y saldos bancarios
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'bankingMetricsAndBalances' + i"
                  class="px-6 py-5"
                  >
                    <template v-if="myBank.hasBankingMetricsAndBalances">
                      <IconCheck class="mx-auto" />
                      <span class="sr-only">Sí</span>
                    </template>
                    <template v-else>
                      <XMarkIcon class="mx-auto" />
                      <span class="sr-only">No</span>
                    </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Conexión Multi-bancos
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'multiBankConnection' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myBank.hasMultiBankConnection">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Recomendación de conciliación
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'reconciliationRecommendation' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myBank.hasReconciliationRecommendation">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Conciliación automática
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'automaticReconciliation' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myBank.hasAutomaticReconciliation">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Control de créditos financieros
                </th>
                <td
                  v-for="({myBank}, i) in plans"
                  :key="'financialCreditControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myBank.hasFinancialCreditControl">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="8" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Gestión de Personas
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Gestión de trabajadores hasta</div>
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'numberEmployeeManagement' + i"
                  class="px-4 py-5 text-sm text-center"
                >
                  <div class="mt-10">{{ peopleManagement.numberEmployeeManagement  }}</div>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Contratos automáticos
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'automaticContracts' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasAutomaticContracts">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Liquidaciones
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'settlements' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasSettlements">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Finiquitos
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'severancePay' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasSeverancePay">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  App Movil trabajador
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'workerMobileApp' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasWorkerMobileApp">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Perfil trabajador
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'workerProfile' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasWorkerProfile">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Control de vacaciones y solicitudes
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'vacationRequestsControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasVacationRequestsControl">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Previred
                </th>
                <td
                  v-for="({peopleManagement}, i) in plans"
                  :key="'previred' + i"
                  class="px-6 py-5"
                >
                  <template v-if="peopleManagement.hasPreviredIntegration">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="8" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Mi empresa
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">
                    Configuración de Sincronización, impuestos y otros
                  </div>
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'synchronizationConfiguration' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasSynchronizationSettings">
                    <IconCheck class="mx-auto mt-10" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10" />
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Plan de cuentas personalizado
                </th>

                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'customChartOfAccounts' + i"
                class="px-6 py-5"
                >
                  <template v-if="myCompany.hasCustomChartOfAccounts">
                    <IconCheck class="mx-auto" />
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Configuración Sucursales
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'branchConfiguration' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasBranchesConfiguration">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Configuración de centro de costos
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'costCenterConfiguration' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasCostCenterConfiguration">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Centro de Notificaciones
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'notificationCenter' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasNotificationsCenter">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Multi-empresa
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'multiCompany' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasMultiCompany">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Integraciones
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'integrations' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasIntegrations">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Conexión API
                </th>
                <td
                  v-for="({myCompany}, i) in plans"
                  :key="'apiConnection' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myCompany.hasAPIConnection">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="6" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Mi equipo
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Control de permisos</div>
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'permissionControl' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myTeam.hasPermissionControl">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Gestión de tareas
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'taskManagement' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myTeam.hasTaskManagement">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Asignación de empresas a usuarios
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'companyAssignmentToUsers' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myTeam.hasCompanyAssignmentToUsers">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Dashboard monitoreo continuo
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'ongoingMonitoringDashboard' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myTeam.hasOngoingMonitoringDashboardForAccountants">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Monitoreo de actividades usuarios
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'userActivityMonitoring' + i"
                  class="px-6 py-5"
                >
                  <template v-if="myTeam.hasUserActivityMonitoring">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Usuarios
                </th>
                <td
                  v-for="({myTeam}, i) in plans"
                  :key="'users' + i"
                  class="px-6 py-5 text-center"
                >
                  {{ myTeam.numberUsers }}
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="3" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    App Mobile
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Métricas</div>
                </th>

                <td
                  v-for="({mobileApp}, i) in plans"
                  :key="'metrics' + i"
                  class="px-6 py-5"
                >
                  <template v-if="mobileApp.hasMetrics">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Notificaciones
                </th>
                <td
                  v-for="({mobileApp}, i) in plans"
                  :key="'notifications' + i"
                  class="px-6 py-5"
                >
                  <template v-if="mobileApp.hasNotifications">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Registro de gastos
                </th>
                <td
                  v-for="({mobileApp}, i) in plans"
                  :key="'expenseRegister' + i"
                  class="px-6 py-5"
                >
                  <template v-if="mobileApp.hasExpenseRegister">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>

            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="8" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold"
                  >
                    Reportería e informes
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Informe semanal</div>
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'weeklyReport' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasWeeklyReport">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Carpeta tributaria
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'taxFolder' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasTaxFolder">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Libro Diario
                </th>

                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'generalJournal' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasGeneralJournal">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Libro Mayor
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'generalLedger' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasGeneralLedger">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Balance
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'balance' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasBalanceSheet">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Estado de resultado
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'incomeStatement' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasIncomeStatement">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Libro de remuneraciones
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'payrollRegister' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasPayrollRegister">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Flujo de Caja
                </th>
                <td
                  v-for="({reportingAndReports}, i) in plans"
                  :key="'cashFlow' + i"
                  class="px-6 py-5"
                >
                  <template v-if="reportingAndReports.hasCashFlow">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- AntiFraudes -->
            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="5" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold mt-10"
                  >
                    Antifraudes
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Huella digital</div>
                </th>
                <td
                  v-for="({antiFraud}, i) in plans"
                  :key="'digitalFingerprint' + i"
                  class="px-6 py-5"
                >
                  <template v-if="antiFraud.hasDigitalFingerprint">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Acuse de recibo
                </th>
                <td
                  v-for="({antiFraud}, i) in plans"
                  :key="'receipt' + i"
                  class="px-6 py-5"
                >
                  <template v-if="antiFraud.hasReceipt">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Monitoreo Contable
                </th>
                <td
                  v-for="({antiFraud}, i) in plans"
                  :key="'accountingMonitoring' + i"
                  class="px-6 py-5"
                >
                  <template v-if="antiFraud.hasAccountingMonitoring">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Detección Anomalías
                </th>
                <td
                  v-for="({antiFraud}, i) in plans"
                  :key="'anomalyDetection' + i"
                  class="px-6 py-5"
                >
                  <template v-if="antiFraud.hasAnomalyDetection">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Notificaciones
                </th>
                <td
                  v-for="({antiFraud}, i) in plans"
                  :key="'notifications' + i"
                  class="px-6 py-5"
                >
                  <template v-if="antiFraud.hasNotifications">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- Soporte -->
            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="3" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold mt-10"
                  >
                    Soporte
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Chat</div>
                </th>

                <td
                  v-for="({support}, i) in plans"
                  :key="'chat' + i"
                  class="px-6 py-5"
                >
                  <template v-if="support.hasChat">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>

              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Correo
                </th>
                <td
                  v-for="({support}, i) in plans"
                  :key="'email' + i"
                  class="px-6 py-5"
                >
                  <template v-if="support.hasEmail">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Telefónico
                </th>
                <td
                  v-for="({support}, i) in plans"
                  :key="'telephonic' + i"
                  class="px-6 py-5"
                >
                  <template v-if="support.hasTelephonic">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- BackOffice contable tributario-->
            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="6" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold mt-10"
                  >
                    BackOffice Contable - Tributario
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Gestión de impuestos mensuales F29</div>
                </th>
                <td
                  v-for="({backOfficeCT}, i) in plans"
                  :key="'monthlyTaxManagementF29' + i"
                  class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.hasMonthlyTaxManagementF29">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>

              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Gestión de impuestos anuales F22 empresa
                </th>
                <td
                  v-for="({backOfficeCT}, i) in plans"
                  :key="'annualTaxManagementF22Company' + i"
                  class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.hasAnualTaxManagementF22">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Apoyo en uso de la plataforma
                </th>

                <td
                  v-for="({backOfficeCT}, i) in plans"
                  :key="'platformSupport' + i"
                  class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.platformUsageSupport">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Monitoreo continuo de tu información
                </th>
                <td
                  v-for="({backOfficeCT}, i) in plans"
                  :key="'continuousMonitoringInformation' + i"
                  class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.hasContinuousMonitoringInformation">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Reuniones Mensuales
                </th>
                <td
                v-for="({backOfficeCT}, i) in plans"
                :key="'monthlyMeetings' + i"
                class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.hasMeet">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Acceso a equipo contable tributario especializado
                </th>
                <td
                  v-for="({backOfficeCT}, i) in plans"
                  :key="'accessAccountingTeam' + i"
                  class="px-6 py-5"
                >
                  <template v-if="backOfficeCT.AccessAccountingTeam">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- BackOffice laboral -->
            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="5" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold mt-10"
                  >
                    BackOffice Laboral
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Gestión de contrato Estándar</div>
                </th>
                <td
                  class="px-6 py-5"
                  v-for="({laborBackOffice}, i) in plans"
                  :key="'standardContractManagement' + i"
                >
                  <template v-if="laborBackOffice.hasStandardContractManagement">
                    <IconCheck class="mx-auto mt-10"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto mt-10"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Gestión de Liquidación
                </th>
                <td
                  v-for="({laborBackOffice}, i) in plans"
                  :key="'settlementManagement' + i"
                  class="px-6 py-5"
                >
                  <template v-if="laborBackOffice.hasSettlementManagement">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Gestión de finiquito
                </th>
                <td
                  v-for="({laborBackOffice}, i) in plans"
                  :key="'settlementManagement' + i"
                  class="px-6 py-5"
                >
                  <template v-if="laborBackOffice.hasSeverancePayManagement">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Reuniones Mensuales
                </th>
                <td
                  v-for="({laborBackOffice}, i) in plans"
                  :key="'hasMeetMonthly' + i"
                  class="px-6 py-5"
                >
                  <template v-if="laborBackOffice.hasMeet">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Acceso a equipo laboral especializado
                </th>
                <td
                  v-for="({laborBackOffice}, i) in plans"
                  :key="'accessLaborTeam' + i"
                  class="px-6 py-5"
                >
                  <template v-if="laborBackOffice.AccessLaborTeam">
                    <IconCheck class="mx-auto"/>
                    <span class="sr-only">Sí</span>
                  </template>
                  <template v-else>
                    <XMarkIcon class="mx-auto"/>
                    <span class="sr-only">No</span>
                  </template>
                </td>
              </tr>
            </tbody>
            <!-- Costos adicionales -->
            <tbody class="divide-y-2 divide-gray-200">
              <tr>
                <td rowspan="3" class="px-4 py-5">
                  <p
                    class="vertical-lr rotate-180 text-center font-semibold mt-10"
                  >
                    Costos adicionales
                  </p>
                </td>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  <div class="mt-10">Conexión de un banco adicional</div>
                </th>
                <!-- <td class="px-6 py-5 text-sm text-center">
                  <div class="mt-10">No aplica</div>
                </td> -->
                <td class="px-6 py-5 text-sm text-center" colspan="4">
                  <div class="mt-10">0,5 UF + iva</div>
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  Gestión de un trabajador adicional
                </th>
                <!-- <td class="px-6 py-5 text-sm text-center">No aplica</td> -->
                <td class="px-6 py-5 text-sm text-center" colspan="4">
                  0,5 UF + iva
                </td>
              </tr>
              <tr>
                <th
                  class="px-6 py-5 text-sm font-normal text-left text-hex-2B4452"
                  scope="row"
                >
                  1 Usuario adicional
                </th>
                <td class="px-6 py-5 text-sm text-center">No aplica</td>
                <td class="px-6 py-5 text-sm text-center" colspan="3">
                  0,1 UF + iva
                </td>
              </tr>
            </tbody>

            <tfoot>
              <tr class="border-t border-gray-200">
                <th class="sr-only" scope="row">Elige tu plan</th>
                <td></td>
                <td v-if="isLoadingPayment" colspan="4" class="px-6 py-5">
                  <Spinner class="mx-auto mt-10" />
                </td>
                <td
                  v-else
                  v-for="(plan,i) in plans"
                  :key="i + 'last button'"
                  class="px-6 pt-5"
                >
                  <div style="text-decoration: none">
                    <ButtonPricing
                      @click="goToPay(plan.id)"
                      variant="primary"
                      class="mt-8"
                    >
                      Lo quiero
                    </ButtonPricing>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { formatToDashedDate } from "@/utils/formatters"
import routerMixin from "@/mixins/routerMixin.js"
import PricingComparisonMobile from "@/components/Pricing/ComparisonMobile.vue"
import plans from "@/data/plans.js"
import IconCheck from '@/components/Icons/IconCheck.vue'
import XMarkIcon from '@/components/Icons/XMark.vue'
import Spinner from '@/components/Spinner2.vue'
import ButtonPricing from '@/components/Pricing/Button.vue'

export default {
  name: "Subscription",
  mixins: [routerMixin],
  data() {
    return {
      validatedOrder: this.$store.state.validatedOrder,
      marketplace: this.$store.state.marketplace,
      user: [],
      productsBase: [],
      productsBank: [],
      subscriptions: [],
      plans: plans,
      totalBank: 1,
      totalCompany: 1,
      totalEmployees: 0,
      price: 0,
      todayUf: 0,
      clpValue: 0,
      ivaValue: 0,
      totalAmount: 0,
      methodPay: "webpay",
      totalMovements: 100,
      error: "",
      isLoadingPayment: false,
    }
  },
  components: {
    PricingComparisonMobile,
    IconCheck,
    XMarkIcon,
    Spinner,
    ButtonPricing,
  },
  watch: {},
  created() {
    this.getTodayUf()
  },
  mounted() {
    this.user = this.$auth.user()
  },
  methods: {
    formatToDashedDate,
    calculateNewPrice(plan) {
      this.clpValue = this.formatClp(plan.price, this.todayUf)
      this.ivaValue = this.getIva(this.clpValue)
      this.totalAmount = this.getTotalAmount(this.clpValue, this.ivaValue)
    },
    getIva(value) {
      let response = value * 0.19
      return response
    },
    getTotalAmount(clpValue, ivaValue) {
      let response = clpValue + ivaValue
      return response
    },
    formatClp(value, todayUf) {
      let response = value * todayUf
      return response
    },
    getTodayUf() {
      let cur = this
      const date = new Date()
      const today_date = cur.formatToDashedDate(date)
      return this.axios
        .get("https://mindicador.cl/api/uf/" + today_date, {
          transformRequest: (data, headers) => {
            delete headers.common
            return data
          },
        })
        .then((response) => {
          cur.todayUf = response.data.serie[0]["valor"]
        })
    },
    goToPay(id) {
      let plan = this.plans.find((e) => e.id == id)
      this.calculateNewPrice(plan)
      const cur = this
      const accessToken = cur.$cookie.get("access-token")
      this.axios
        .post(cur.marketplace + "/api/v1/orders", {
          user_id: cur.user.id,
          total_bank: plan.banks,
          // total_company: cur.totalCompany,
          total_employee: plan.employees,
          user_name: cur.user.name,
          plan_name: plan.title,
          transaction_date: new Date(),
          total_amount: cur.totalAmount,
          method_pay: cur.methodPay,
          total_movement: cur.totalMovements,
          subtotal: cur.clpValue,
          iva: cur.ivaValue,
          price_uf: plan.price,
          headers: {
            authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          cur.goTo("PaymentPlan", { id: response.data.id })
        })
        .catch((error) => {
          this.error = error.response.data.transaction_date[0]
        })
    },
    calculateTotalMovements(event) {
      this.totalMovements = event
      this.calculatePrice()
    },
    calculateTotalCompany(event) {
      this.totalCompany = event
      this.calculatePrice()
    },
    calculateTotalEmployees(event) {
      this.totalEmployees = event
      this.calculatePrice()
    },
    calculateTotalBank(event) {
      this.totalBank = event
      this.calculatePrice()
    },
    calculatePrice() {
      let uf_movements = 0
      switch (true) {
        case this.totalMovements < 500:
          uf_movements = 0
          break
        case this.totalMovements < 1000:
          uf_movements = 2
          break
        case this.totalMovements < 5000:
          uf_movements = 5
          break
        case this.totalMovements < 100000:
          uf_movements = 10
          break
        default:
          break
      }
      console.log("uf_movements", uf_movements)
      this.price =
        2 +
        this.totalCompany * 0.2 +
        this.totalBank * 0.8 +
        this.totalEmployees * 0.5 +
        uf_movements
      this.clpValue = this.formatClp(this.price, this.todayUf)
      this.ivaValue = this.getIva(this.clpValue)
      this.totalAmount = this.getTotalAmount(this.clpValue, this.ivaValue)
    },
    getProductsBase() {
      const cur = this
      const accessToken = cur.$cookie.get("access-token")
      this.axios
        .get(cur.marketplace + "/api/v1/products.json", {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            type_product: 0,
          },
        })
        .then((response) => {
          cur.productsBase = response.data
        })
    },
    getProductsBank() {
      const cur = this
      const accessToken = cur.$cookie.get("access-token")
      this.axios
        .get(cur.marketplace + "/api/v1/products.json", {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            type_product: 2,
          },
        })
        .then((response) => {
          cur.productsBank = response.data
        })
    },
  },
}
</script>
<style scoped>
.columnas {
  column-count: 3;
  column-gap: 20px;
}
@media (max-width: 1024px) {
  .columnas {
    column-count: 1;
    column-gap: 20px;
  }
}
.vertical-lr {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}

</style>
