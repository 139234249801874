 <template>
  <div class="py-2 mx-6">
    <div class="flex flex-col gap-4">
      <div class="w-full flex gap-4">
        <div class="lg:text-4xl text-3xl font-semibold items-center dark:text-white flex-auto flex gap-4">
          Plan de cuentas
        </div>
        <div class="flex items-center pr-4 gap-1 ">
            Exportar en <a @click.prevent="exportIn()" class="dark:text-cian-dark"> Excel</a>
        </div>
      </div>
      <div>
        <p>Listado que comprende todas las cuentas contables que pueden ser utilizadas al desarrollar la contabilidad de una empresa.</p>
      </div>
    </div>
      <newAccount
        v-if="showModal"
        @close="showModal = false"
        @getAccounts="getAccounts"
        :account="account"
        :parents="parents"
        :action="action"
        :parent_id="parent_id"
        :parent_children="parent_children"
      ></newAccount>
    <div v-if="loading" class="h-80 flex items-center justify-center">
      <Loader class="h-9 w-9" bgcolor="#4c1760"/>
    </div>
    <div v-else class="mb-6 relative">
      <vue-ads-table-tree
        :columns="columns"
        :rows="rows"
        :filter="filterValue"
        :page="page"
        @filter-change="filterChanged"
        @page-change="pageChanged"
      >
        <template slot="label_fantasy" slot-scope="props">
          {{ props.row.id }}.- {{ props.row.label_fantasy }}
        </template>

        <template slot="count" slot-scope="props">
          <p v-if="props.row.count === 1" class="inline text-xs">
            {{ props.row.count }} Movimiento
          </p>
          <p v-else-if="props.row.count > 0" class="text-xs">
            {{ props.row.count }} Movimientos
          </p>
        </template>

        <template slot="option" slot-scope="props">
          <div class="flex items-center gap-3 px-2">
            <a v-on:click="editFunction(props.row)">
              <Edit/>
            </a>
            <a
              v-on:click="deleteFunction(props.row, props.row.value.id)"
            >
              <Times />
            </a>
            <a
              @click.prevent="createFunction(props.row.value.id, props.row._children.length)"
            >
              <PlusCircle />
            </a>
          </div>
        </template>
      </vue-ads-table-tree>
    </div>
  </div>
</template>

<script>
import "vue-ads-table-tree/dist/vue-ads-table-tree.css";

import VueAdsTableTree from "vue-ads-table-tree";
import newAccount from "./newAccount";
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';
import PlusCircle from '@/components/Icons/PlusCircle.vue';
import Loader from "@/components/loader.vue";
export default {
  name: "app",
  components: {
    VueAdsTableTree,
    newAccount,
    Edit,
    Times,
    PlusCircle,
    Loader,
  },
  data() {
    return {
      showModal: false,
      showFileInput: false,
      parents: [],
      action: null,
      account: null,
      page: 1,
      uploadFile: null,
      excelName: "",
      filterValue: "",
      parent_id: 0,
      parent_children: 0,
      loading: true,
      columns: [
        {
          property: "label_fantasy",
          title: "Plan de cuentas",
          filterable: true,
          direction: null,
          collapseIcon: true,
        },
        {
          property: "count",
          title: "Nº de Movimientos",
        },
        {
          property: "option",
          title: "Opciones",
          direction: null,
        },
      ],
      rows: [],
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  mounted() {
    this.user = this.$auth.user();
    this.getAccounts();
    this.getAllParents();
  },
  watch: {
    company_id() {
      this.getAccounts();
    },
  },
  methods: {
    getParents(id) {
      this.axios
        .get("/get_parents", {
          params: {
            company_id: this.company_id,
            account_id: id,
          },
        })
        .then((response) => {
          this.parents = response.data;
        });
    },
    editFunction(obj) {
      this.showModal = true;
      this.account = obj;
      this.getParents(obj.value.id);
      this.action = "update";
    },
    deleteFunction(obj, id) {
      this.$swal
        .fire({
          title: "¿Seguro de eliminar el plan de Cuenta?",
          text: "El plan de cuenta sera eliminado",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, estoy seguro",
        })
        .then((result) => {
          if (result.value) {
            if (obj._children.length == 0) {
              this.$swal.fire(
                "Modificado",
                "Tu plan de cuenta ha sido modificado",
                "success"
              );
              this.axios.delete("accounts/" + id).then(() => {
                this.getAccounts();
              });
            } else {
              this.$swal.fire(
                "Cancelado",
                "El plan de cuenta no puede ser modificado por que contiene informacion protegida.",
                "error"
              );
            }
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El plan de cuenta no ha sido modificado",
              "error"
            );
          }
        });
    },
    getAllParents() {
      this.axios
        .get("/all_parents", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.parents = response.data;
        });
    },
    createFunction(parent_id, parent_children) {
      this.showModal = true;
      this.getAllParents();
      this.account = {
        label: "Create",
        code: null,
        value: { code: null, name: null },
      };
      this.parent_children = parent_children
      this.parent_id = parent_id
      this.action = "create";
    },
    getAccounts() {
      this.loading = true;
      this.axios
        .get("/accounts", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.rows = response.data[0]._children;
          this.loading = false;
        });
    },
    eliminateFile() {
      this.uploadFile = null;
      this.excelName = "";
    },
    filterChanged(filter) {
      this.filter = filter;
    },
    exportIn() {
      const params = {
        company_id: this.company_id,
      };
      // const baseUrl = "http://localhost:3000/accounts-export-excel";
      const baseUrl = "https://api.cymasuite.com/accounts-export-excel";
      const url = baseUrl + "?company_id=" + params.company_id;
      window.open(url, "_blank");
    },
    selectedFile(e) {
      //Save the information of the selected File
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.excelName = files[0].name;
    },
    upload() {
      //POST File using FormData
      let formData = new FormData();
      formData.append("company_id", this.company_id);
      formData.append("file", this.uploadFile);
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.axios
        .post("accounts-import-excel", formData, config)
        .then((response) => {
          console.log(response);
          this.getAccounts();
          this.uploadFile = null;
          this.excelName = "";
          this.showFileInput = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    pageChanged(page) {
      this.page = page;
    },
  },
};
</script>
<style scoped>
.botton-created {
  top: 10px;
  right: 30%;
}
</style>
