<template>
  <div class="py-2 mx-6" :class="{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold">
        Impuestos mensuales
      </h1>
      <div class="flex items-center gap-4">
        <div>
          Exportar en <a v-on:click.prevent="exportIn('excel')">Excel</a> o <a v-on:click.prevent="exportIn('pdf')">Pdf</a>
        </div>
        <div>
          <date-picker
            input-class="xmx-input"
            v-model="dateRange"
            type="date"
            range
            format='DD-MM-YYYY'
            placeholder="Selecciona un rango de Fecha"
            :style="{
              border: '1px solid #e2e8f0',
              width: '100%',
              'background-color': 'white',
              'border-radius': '2px',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.80rem',
              'padding-bottom': '0.80rem',
              'border-radius': '0px'
            }"
            v-on:input="reload"
          ></date-picker>
        </div>
      </div>
    </div>
    <br>
    <h2 class="text-sm font-bold mb-2 text-gray-500">Tabla de débitos del periodo</h2>
    <BeeGridTable
      :serverSide="true"
      border
      ref="selection"
      :showFilter="false"
      :showSummary="true"
      :summary-method="customSummary"
      :showPager="false"
      :data="groupSales"
      :loading="loading"
      >
      <BeeColumn
        field="doc_type"
        title="Tipo documento"
        type="text"
        :width="400"
      >
        <template slot-scope="{ row }">
          {{ formatDocType(row.doc_type) }}
        </template>
      </BeeColumn>

      <BeeColumn
        field="number_movements"
        title="Cantidad"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ row.number_movements }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="doc_type___"
        title="Exento"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          0 {{ row.id }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="neto"
        title="Neto"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.neto) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="tax_number"
        title="Iva"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.tax_number) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="non_recoverable_tax"
        title="Iva no recuperable"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.non_recoverable_tax) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="total"
        title="Total"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.total) }}
        </template>
      </BeeColumn>
    </BeeGridTable>
    <h2 class="text-sm font-bold mb-2 text-gray-500 pt-10">Tabla de créditos del periodo</h2>
    <BeeGridTable
      :serverSide="true"
      border
      ref="selection"
      :showFilter="false"
      :showSummary="true"
      :summary-method="customSummary"
      :showPager="false"
      :data="groupBuys"
      :loading="loading"
      >
      <BeeColumn
        field="doc_type"
        title="Tipo documento"
        type="text"
        :width="400"
      >
        <template slot-scope="{ row }">
          {{ formatDocType(row.doc_type) }}
        </template>
      </BeeColumn>

      <BeeColumn
        field="number_movements"
        title="Cantidad"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ row.number_movements }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="doc_type___"
        title="Exento"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          0 {{ row.id }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="neto"
        title="Neto"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.neto) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="tax_number"
        title="Iva"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.tax_number) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="non_recoverable_tax"
        title="Iva no recuperable"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.non_recoverable_tax) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="total"
        title="Total"
        type="text"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ formatNumber(row.total) }}
        </template>
      </BeeColumn>
    </BeeGridTable>

    <div class="flex gap-4 pt-10">
      <div>
        <h2 class="text-sm font-bold mb-2 text-gray-500">Tabla de valores proceso de calculo de impuestos</h2>
        <BeeGridTable
          :serverSide="true"
          border
          ref="selection"
          :showFilter="false"
          :showPager="false"
          :data="groupTax"
          :loading="loading"
        >
          <BeeColumn
            field="name"
            title="Concepto"
            type="text"
            :width="400"
          >
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </BeeColumn>
          <BeeColumn
            field="value"
            title="Valor"
            type="text"
            :width="200"
          >
            <template slot-scope="{ row }">
              {{ formatNumber(row.value) }}
            </template>
          </BeeColumn>
        </BeeGridTable>
      </div>

      <div>
        <h2 class="text-sm font-bold mb-2 text-gray-500">Tabla de información contable del periodo (flujo)</h2>
        <BeeGridTable
          :serverSide="true"
          border
          ref="selection"
          :showFilter="false"
          :showPager="false"
          :data="groupStats"
          :loading="loading"
        >
          <BeeColumn
            field="name"
            title="Concepto"
            type="text"
            :width="400"
          >
            <template slot-scope="{ row }">
              {{ row.name }}
            </template>
          </BeeColumn>
          <BeeColumn
            field="value"
            title="Valor"
            type="text"
            :width="200"
          >
            <template slot-scope="{ row }">
              {{ formatNumber(row.value) }}
            </template>
          </BeeColumn>
        </BeeGridTable>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatDocType, formatNumber } from "@/utils/formatters.js";
  import DatePicker from 'vue2-datepicker';

  export default {
    name: "monthlyTax",
    props: {},
    data() {
      return {
        movements: [],
        groupSales: [],
        groupTax: [],
        groupBuys: [],
        groupStats: [],
        loading: false
      };
    },
    computed: {
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      },
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
    },
    watch: {
      company_id(){
        this.reload();
      },
    },
    components: {
      DatePicker
    },
    mounted() {
      this.getMovements();
    },
    methods: {
      formatDocType,
      formatNumber,
      reload() {
        this.getMovements();
      },
      exportIn(file) {
        const params = {
          company_id: this.company_id,
          from: this.dateRange[0],
          to: this.dateRange[1],
        }
        // const baseUrl = 'http://localhost:3000/tax-' + file
        const baseUrl = 'https://api.cymasuite.com/tax-' + file
        const url = (baseUrl +
                    '?company_id=' + params.company_id +
                    '&from=' + params.from +
                    '&to=' + params.to)
        window.open(url, '_blank');
      },
      customSummary({ columns, data }) {
        const sums = {};
        columns.forEach((column, index) => {
          index
          const key = column.key;
          if (column.key === "doc_type") {
            sums[column.key] = {
              key: column.key,
              value: "Totales",
            };
            return;
          }
          // sum money
          if (column.key === "total" || column.key === "neto" || column.key === "tax_number" || column.key === "non_recoverable_tax") {
            const values = data.map((item) => Number(item[key]));
            if (!values.every((value) => isNaN(value))) {
              const v = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return prev + curr;
                } else {
                  return prev;
                }
              }, 0);
              sums[key] = {
                key,
                value: "$" + this.formatNumber(v),
              };
            } else {
              sums[key] = {
                key,
                value: "N/A",
              };
            }
          }
        });

        return sums;
      },
      getMovements() {
        this.loading = true;
        let app = this;
        this.axios
        .get("/summary-movements", {
          params: {
            company_id: app.company_id,
            from_period: app.dateRange[0],
            to_period: app.dateRange[1],
          },
        })
        .then((response) => {
          console.log(response)
          app.movements = response.data;
          app.groupSales = response.data.group_sales;
          app.groupBuys = response.data.group_buys;
          app.groupTax = response.data.group_tax;
          app.groupStats = response.data.group_stats;
          this.loading = false;
        });
      },
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
