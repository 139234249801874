<template>
  <div>
    <Modal :loading="loading" @close="$emit('close')">
      <template #title>
        <h1 class="font-bold dark:text-white text-3xl">
          {{ creditNote.business_name }}
        </h1>
      </template>
      <template #body>
        <div>
          <p class="text-gray-500 dark:text-white">
            {{ creditNote.folio }}
            {{ formatDocType(creditNote.doc_type) }}
          </p>
          <p class="text-xl">
            <span class="font-semibold mr-2 pt-1 text-2xl dark:text-white"
              >$ {{ formatNumber(creditNote.total) }}</span
            >
          </p>
          <div
            class="bg-gray-100 px-2 py-4 mt-2 rounded-lg dark:bg-black-dark dark:border-black-dark flex gap-2"
            v-if="
              creditNote.docto_reference_type && creditNote.docto_reference_folio
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
              />
            </svg>
            <p class="text-sm">
              Esta nota de crédito vincular con
              <span class="font-bold">{{
                formatDocType(creditNote.docto_reference_type)
              }}</span>
              N° de folio
              <span class="font-bold">{{
                creditNote.docto_reference_folio
              }}</span>
            </p>
          </div>
          <div v-if="
                    (!creditNote.is_credit_note && creditNotes.length == 0) ||
                    (creditNote.is_credit_note && creditNote.credit_note_id == null ) ||
                    toggleCredit
                  " class="modal-body">
            <form class="h-full grid grid-cols-3 gap-4 pt-4">
              <div class="col-span-3">
                <input
                  type="text"
                  class="appearance-none w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 dark:text-white dark:bg-black-dark dark:border-black-dark"
                  v-model="query"
                  @input="handleKeyPress"
                  placeholder="Buscar movimientos"
                />
                <div
                  v-if="loadingMovement"
                  class="flex justify-center items-center p-10"
                >
                  <Spinner />
                </div>
                <div v-else class="newbox mt-2">
                  <div
                    v-for="(movement, i) in movements"
                    :key="i"
                    class="bg-emerald-200"
                  >
                    <div
                      class="bg-gray-100 px-2 py-4 mt-2 rounded-lg dark:bg-black-dark dark:border-black-dark"
                    >
                      <div
                        class="mx-2 flex gap-2 items-center font-bold text-lg dark:text-white"
                      >
                        <input
                          type="radio"
                          :value="movement.id"
                          v-model="picked"
                          :disabled="creditNote?.balance > movement?.balance"
                          class="form-tick h-5 w-5 border-white rounded-lg checked:bg-blue-600 checked:border-transparent focus:outline-none"
                        />
                        {{ movement.business_name }}
                      </div>
                      <div
                        class="flex flex-col md:flex-row justify-between mx-2"
                      >
                        <div class="leading-5 text-gray-500 text-sm w-2/3 pt-1">
                          <div class="text-sm tracking-normal text-gray-500">
                            {{ movement.folio }} /
                            {{ formatDocType(movement.doc_type) }}
                          </div>
                          <div class="text-sm tracking-normal text-gray-500">
                            {{ formatSimpleDate(movement.period) }}
                          </div>
                        </div>
                        <div class="mr-24">
                          <div class="leading-5 text-gray-500">Total</div>
                          <div class="flex justify-between items-center gap-4">
                            <div class="text-lg font-semibold dark:text-white">
                              $ {{ formatNumber(movement.total) }}
                            </div>
                            <div
                              v-if="
                                movementsSelected.length >= 1 &&
                                movementsSelected.includes(
                                  movement.id.toString()
                                )
                              "
                            >
                              <a v-on:click="updateEditBalance(movement.id)">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                                  />
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div v-if="movement.balance > 0">
                            Abono $ {{ formatNumber(movement.balance) }}
                          </div>
                          <currency-input
                            v-if="
                              movementsSelected.length >= 1 &&
                              movementsSelected.includes(
                                movement.id.toString()
                              ) &&
                              editBalanceArray.includes(movement.id)
                            "
                            currency="CLP"
                            locale="es-CL"
                            :valueRange="{
                              max: movement.balance
                                ? movement.balance
                                : movement.total,
                            }"
                            placeholder="Monto abonar"
                            @change="
                              updateBalanceManual(
                                movement.id,
                                movement.balance_manual
                              )
                            "
                            class="text-grey-darker border border-grey-lighter py-2 px-3"
                            v-model="movement.balance_manual"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div v-else>
            <div v-if="creditNote?.is_credit_note">
              <p class="text-gray-500 dark:text-white pt-4">Factura Asignada</p>
              <div
                class="bg-gray-100 border-cyma-grey dark:text-white border rounded-lg p-4 my-4 dark:bg-black-dark dark:border-black-dark"
              >
                <p class="text-2xl font-bold">{{ creditNote?.credit_note.business_name }}</p>
                <p class="text-sm text-gray-500">
                  {{ creditNote?.credit_note.folio }} {{ formatDocType(creditNote?.credit_note.doc_type) }}
                </p>
              </div>
              <div class="flex justify-center">
                <button
                  class="button-cymasuite"
                  @click.prevent="deleteCreditNote(creditNote?.id)"
                >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                  Eliminar Asignación
                </button>
              </div>
            </div>
            <div v-else>
              <p class="text-gray-500 dark:text-white pt-4">Notas de Credito Asignadas</p>
              <div
                v-for="(credit_note , k) in creditNotes" :key="'creditNote' + k" class="bg-gray-100 border-cyma-grey dark:text-white border rounded-lg p-4 my-4 dark:bg-black-dark dark:border-black-dark"
              >
                <p class="text-2xl font-bold">{{ credit_note.business_name }}</p>
                <p class="text-sm text-gray-500">
                  {{ credit_note.folio }} {{ formatDocType(credit_note.doc_type) }}
                </p>
                <div class="flex justify-center">
                  <button
                    class="button-cymasuite"
                    @click.prevent="deleteCreditNote(credit_note.id)"
                  >
                    Eliminar Asignación
                  </button>
                </div>
              </div>
              <div class="flex justify-center modal-footer gap-4 pt-4">
                <button
                  v-if="creditNote?.is_credit_note"
                  class="dark:bg-cian-dark dark:border-cian-dark rounded-full hover:bg-black bg-white text-black border border-black hover:text-white font-bold py-2 px-4 text-center mb-4"
                  @click.prevent="toggleCredit = true"
                >
                  Editar Asignación
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex justify-center">
          <button
            v-if="picked"
            class="dark:bg-cian-dark dark:border-cian-dark rounded-full bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white font-bold py-2 px-4 text-center mb-4"
            @click.prevent="submit"
          >
            Asignar Factura
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>
// import the component
// import the styles
import Modal from "@/components/CustomModal.vue";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Spinner from "@/components/Icons/Spinner.vue";
import "@/assets/stylesheets/modal.css";
import { formatDocType, formatNumber, formatSimpleDate } from "@/utils/formatters.js";

export default {
  name: "newAccount",
  props: {
    action: String,
    account: Object,
    parents: Array,
    id: Number,
  },
  data() {
    return {
      user: null,
      query: null,
      movements: [],
      creditNote: [],
      toggleCredit: false,
      movementsSelected: [],
      editBalanceArray: [],
      balance: 0,
      sum: 0,
      total: 0,
      loading: true,
      loadingMovement: false,
      picked: null,
      factura: {},
      searchTimer: null,
      creditNotes: []
    };
  },
  created() {
    this.setCreditNote();
  },
  mounted() {
    this.user = this.$auth.user;
    // this.getCredit();
  },
  components: {
    Spinner,
    Modal,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    updateEditBalance(id) {
      let app = this;
      const index = app.editBalanceArray.indexOf(id);
      if (index > -1) {
        app.editBalanceArray.splice(index, 1);
      } else {
        app.editBalanceArray.push(id);
      }
    },
    updateBalanceManual(movement_id, value) {
      console.log("updateBalanceManual");
      this.axios
        .patch("movements/" + movement_id, {
          balance_manual: value,
        })
        .then((res) => {
          console.log(res);
        });
    },
    checked(event, movement) {
      if (event.target.checked) {
        this.movementsSelected.push(event.target.id);
        this.movementsSelected = []
        console.log(movement)
      } else {
        const index = this.movementsSelected.indexOf(event.target.id);
        this.movementsSelected.splice(index, 1);
      }

      this.balance = this.balance - this.sum;
    },
    submit() {
      this.action === "update" ? this.update() : this.create();
    },
    setCreditNote() {
      this.loading = true
      const app = this;
      this.axios.get("/movements/" + app.id).then((response) => {
        app.creditNote = response.data;
        app.creditNotes = response.data.credit_notes;
        this.query = response.data.docto_reference_folio
        this.getSales()
        this.loading = false
        app.total = response.data.amount;
        // value abs
        app.balance = response.data.total;
      });
    },
    create() {
      // console.log(this.account)
      // this.axios
      //   .post("/accounts", {
      //     parent_id: this.account.parent_id,
      //     name: this.account.value.name,
      //     company_id: this.company_id
      //   })
      //   .then(() => {
      //     this.$emit("getAccounts");
      //     this.$emit("close");
      //   });
    },
    update() {
      // update transactions
      let app = this;
      app.loading = true;
      app.movements = [];
      this.axios
        .patch("/movements/" + app.id, {
          credit_note_id: app.picked,
        })
        .then(() => {
          app.loading = false;
          this.$emit("close");
          this.$emit("getSales");
        });
    },
    deleteCreditNote(id) {
      // update transactions
      let app = this;
      app.loading = true;
      app.movements = [];
      this.axios
        .patch("/movements/" + id, {
          credit_note_id: null,
        })
        .then(() => {
          app.loading = false;
          this.$emit("close");
          this.$emit("getSales");
        });
    },
    getSales() {
      this.loadingMovement = true;
      let app = this;
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_sale",
            company_id: app.company_id,
            query: app.query,
          },
        })
        .then((response) => {
          app.movements = response.data.data;
          app.loadingMovement = false;
        });
    },
    handleKeyPress(){
      clearTimeout(this.searchTimer); // Cancelar temporizador existente
      this.searchTimer = setTimeout(() => {
        this.getSales(); // Función de búsqueda
      }, 500); // Establecer un tiempo de espera (en milisegundos)
    },
  },
};
</script>
<style>
.newbox {
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
