<script>
  import { Pie, mixins } from "vue-chartjs";
  const { reactiveProp } = mixins

  export default {
    extends: Pie,
    mixins: [reactiveProp],
    props: { chartData: [] },
    data() {
      return {
        options: {
          hoverBorderWidth: 2,
          legend: false,
          responsive: true,
          maintainAspectRatio: false,
          tooltips: {
            callbacks: {
              // this callback is used to create the tooltip label
              label: function(tooltipItem, data) {
                // get the data label and data value to display
                // convert the data value to local string so it uses a comma seperated number
                var dataLabel = data.labels[tooltipItem.index].toUpperCase();
                var value = ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString();
                let formatValue = value.replace(/,/g, '.');
                // return the text to display on the tooltip
                return dataLabel + formatValue;
              }
            }
          }
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options, { responsive: true })
    }
};
</script>