<template>
  <div class="py-2 mx-4 lg:mr-1 lg:ml-6">
    <div class="w-full flex lg:gap-4 flex-wrap">
      <div
        class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex lg:gap-4 gap-2"
      >
        $ {{ formatNumber(buy_total || 0) }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#fab6b8">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
      <div class="flex gap-2 items-center">
        <a
          data-cy="addEgress"
          @click.prevent="createButton"
          class="button-cymasuite"
        >
          <span class="hidden sm:block">Agregar egreso</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </a>
      </div>
    </div>
    <div class="flex mb-2 lg:mb-6">
      <h2
        class="flex-auto lg:text-lg sm:text-sm text-gray-500 sm:text-left"
        v-if="without_account > 0"
      >
        {{ formatNumber(without_account || 0) }}
        <span
          v-if="without_account === 1"
        >
          egreso sin categorizar
        </span>
        <span v-else-if="without_account > 1">
          egresos sin categorizar
        </span>
      </h2>
    </div>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar movimientos"
          v-model="query"
          data-cy="search"
        />
      </div>
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem'
          }"
          v-model="dateRange"
          type="date"
          format='DD-MM-YYYY'
          range
          placeholder="Rango de Fecha"
        ></date-picker>
      </div>
      <div v-if="exists_pending_sii > 0">
        <v-select
          class="style-chooser custom-border"
          v-model="pending_sii"
          :options="pending_sii_options"
          :reduce="(state) => state.value"
          placeholder='Acuse de Recibo'
          label="name"
        ></v-select>
      </div>
      <div>
        <v-select
          class="style-chooser custom-border"
          v-model="movement_state"
          :options="status"
          :reduce="(state) => state.value"
          label="name"
          placeholder='Estado de pago'
        ></v-select>
      </div>
      <div>
        <v-select
          class="style-chooser custom-border"
          v-model="not_classified"
          :options="classified_status"
          :reduce="(state) => state.value"
          label="name"
          placeholder="Estado clasificación"
        ></v-select>
      </div>
      <div v-if="category_movement !== 5">
        <v-select
          class="style-chooser custom-border"
          v-model="doc_type"
          :options="doc_types"
          :reduce="(state) => state.value"
          label="name"
          placeholder="Tipo de Documento"
        ></v-select>
      </div>
      <div>
        <v-select
          class="style-chooser custom-border"
          v-model="category_movement"
          :options="types"
          :reduce="(type) => type.value"
          label="name"
          placeholder="Tipo de egreso"
        ></v-select>
      </div>

      <div v-if="category_movement == 5">
        <v-select
          class="style-chooser custom-border"
          v-model="settlement_type"
          :options="settlement_types"
          :reduce="(state) => state.value"
          label="name"
          placeholder="Tipo de remuneración"
        ></v-select>
      </div>
      <modalDocument
        v-if="showModalDocument"
        @close="showModalDocument = false"
        :buy_id="buy_id"
        :action="action"
      ></modalDocument>
      <modalVersions
        v-if="showModalVersions"
        @close="showModalVersions = false"
        :buy_id="buy_id"
        :action="action"
      ></modalVersions>
      <editCreditNote
        v-if="showCreditModal"
        @close="showCreditModal = false"
        :id="buy_id"
        :action="action"
        @getBuys="getBuys"
      >
      </editCreditNote>
      <newBuys
        v-if="showModal"
        @close="showModal = false"
        :buy_id="buy_id"
        :action="action"
        @getBuys="getBuys"
      ></newBuys>
      <newMovement
        v-if="showMovement"
        @getBuys="getBuys"
        @close="closeFunction"
        :buy_id="buy_id"
      ></newMovement>
      <newPayment
        v-if="showPayment"
        @getBuys="getBuys"
        @close="showPayment = false"
        :buy_id="buy_id"
      ></newPayment>
      <massClasification
        v-if="showModalCheked"
        @close="showModalCheked = false"
        :action="action"
        @getBuys="getBuys"
        :selected_checks="checkeds_id"
      >
      </massClasification>
      <massBuy
        v-if="showmassBuy"
        @getBuys="getBuys"
        @close="showmassBuy = false"
        :buy_id="buy_id"
        :selected_checks="checkeds_id"
        :status="status_selecteds_boolean"
        >
      </massBuy>

      <!-- <div>
        <v-select
          class="style-chooser custom-border"
          v-model="state_openfactura"
          :options="options_openfactura"
          :reduce="(type) => type.value"
          label="name"
          placeholder="Documento"
        ></v-select>
      </div> -->
      <div>
        <v-select
          class="style-chooser custom-border"
          :options="accounts"
          v-model="account"
          placeholder="Plan de cuenta"
        ></v-select>
      </div>
      <div
        v-if="cost_centers.length > 0"
      >
        <v-select
          class="style-chooser custom-border"
          :options="cost_centers"
          v-model="cost_center"
          placeholder="Centro de Costo"
        >
          <div slot="no-options">Sin centro de costo en uso</div>
        </v-select>
      </div>
      <div
        v-if="branch_offices.length > 0"
      >
        <v-select
          class="style-chooser custom-border"
          :options="branch_offices"
          v-model="branch_office"
          placeholder="Sucursal"
          multiple
        >
          <div slot="no-options">Sin sucursal en uso</div>
        </v-select>
      </div>
      <div class="">
        <div class="text-xs font-light mb-1 dark:text-gray-400">Nota de crédito</div>
        <label class="switch label_switch">
          <input
            class="check"
            type="checkbox"
            v-model="onlynotecredit"
            :checked="onlynotecredit"
          />
          <span><slot></slot></span>
        </label>
      </div>
      <div
        v-if="dateRange[0] != null || pending_sii != null || category_movement != null || movement_state != null ||  state_openfactura != null || not_classified != null || query != null || account != null || cost_center != null || branch_office != null"
        class="fade-in flex items-center"
      >
        <button @click="cleanFilters()" class="flex items-center focus:outline-none text-cian-400 hover:text-cian-500 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">
          Limpiar Filtros
        </button>
      </div>
    </div>

    <transition name="modal-fade" mode="out-in">
      <modalDte
        v-if="showModalDte"
        :documents="documents"
        @close="closeModalDte"
      />
    </transition>
    <modalF29
        v-if="showModalF29"
        @close="showModalF29 = false"
        :action="action"
        :domHTML="domHTML"
    ></modalF29>
    <div class="flex flex-wrap gap-2 pb-4">
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark: dark:hover:text-cian-dark"
        @click="handleSelectAll()"
      >
        <div class="flex gap-1 items-center dark:text-white dark:hover:text-cian-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
          </svg> Todos
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="cleanSelectAll()"
        v-if="checkeds_id.length > 0"
      >
        <div class="flex gap-1 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
          Limpiar
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="selecteds_checks()"
        v-if="checkeds_id.length > 0"
      >
        <div class="flex gap-1 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
          </svg>
          Clasificar
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="massAcknowledgmentOfReceipt()"
        v-if="checkeds_id.length > 0 && pending_sii"
        >
          <div class="flex gap-1 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
            </svg>

            Acuse Recibo
          </div>
      </button>

      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="massBuyCheked()"
        v-if="checkeds_id.length > 0"
        >
          <div class="flex gap-1 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            Pagar
          </div>
      </button>

      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="massBuyDeletedCheked()"
        v-if="checkeds_id.length > 0"
        >
          <div class="flex gap-1 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Eliminar
          </div>
      </button>
      <button class="flex items-center gap-2 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" v-on:click.prevent="exportInExcel()">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
      <!-- o
      <a class="dark:text-cian-dark" v-on:click.prevent="exportInPdf()">Pdf</a> -->
    </div>
    <BeeGridTable
      :serverSide="true"
      stripe
      border
      ref="selection"
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :row-class-name="rowClassName"
      :data="buys"
      :loading="loading"
    >
      <BeeColumnHeader field="select" fixed="left">
        <template>
          <input
            type="checkbox"
            :checked="principalCheckbox"
            v-model="principalCheckbox"
            @change="toggleSelectAll()"
            />
        </template>
      </BeeColumnHeader>
      <BeeColumn field="select" :width="60" fixed="left">
        <template slot-scope="{ row }">
          <input
            type="checkbox"
            :checked="checkeds_id.includes(row.id)"
            @change="handleSelect(row.id)"
          />
        </template>
      </BeeColumn>


      <BeeColumn
        field="business_name"
        title="Razón social"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="400"
        fixed="left"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow" v-if="row.ticket_honorary">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('serviceProviderShow', {
                  service_provider_id: row.service_provider_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else-if="row.category_movement == 'remuneration'">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('remunerationShow', {
                  employee_id: row.employee_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else-if="row.category_movement == 'previred'">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('previredShow', {
                  movement_id: row.id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else-if="row.category_movement == 'form_f29'">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('f29Show', {
                  movement_id: row.id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else-if="row.category_movement == 'form_22'">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('f22Show', {
                  movement_id: row.id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else>
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('Vendors', {
                  vendor_id: row.vendor_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
            <i class="text-gray-400 block">{{ formatStateMovement(row.state_movement) }}</i>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="folio"
        title="Folio"
        type="date"
        :width="210"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ row.folio }}
          <div
            v-if="row.ticket_honorary"
            class="might-overflow text-sm tracking-normal text-gray-400"
          >
            {{ formatDocType(row.ticket_honorary.type_ticket) }}
          </div>
          <div v-else class="might-overflow text-sm tracking-normal text-gray-400">
            {{ formatDocType(row.doc_type) }}
          </div>
          <div v-if="row.category_movement === 'remuneration'" class="text-sm text-gray-400">
            {{ formatRemuneration(row.settlement_type) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="period"
        title="Fecha"
        type="date"
        :width="130"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.period) }}
        </template>
      </BeeColumn>

      <BeeColumn
      field="estimated_payment"
      title="Fecha Caducidad"
      type="date"
      :width="200"
      :resizable="true"
      :sortable="true"
      >
      <template slot-scope="{ row }">
        {{ formatSimpleDate(row.estimated_payment)}}
        <div
          class="text-sm tracking-normal text-gray-400"
          v-if="row.movement_status === 'not_paid' && row.estimated_payment"
        >
          {{ differenceInDays(row.estimated_payment) }} días vencidos
        </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="total"
        title="Total"
        type="number"
        :sortable="true"
        :resizable="true"
        :width="120"
      >
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.total) }}
          <div class="text-sm tracking-normal text-gray-400">
            $ {{ formatNumber(row.balance) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="pagado" title="Pagado" type="text" :width="100" >
        <template slot-scope="{ row }">
          <div v-if="row.classified && row.credit_note_statement != 'voided'">
            <label class="switch label_switch">
              <input
                class="check"
                type="checkbox"
                :class="{ notPaid: row.movement_status === 'paid_subscriber' }"
                :checked="checkedFunction(row.movement_status)"
                @click.prevent="updatePay($event.target.checked, row.id)"
              />
              <span><slot></slot></span>
            </label>
          </div>
        </template>
      </BeeColumn>
      <BeeColumn field="state_openfactura" title="Documento" type="text" :width="280" :resizable='true' :sortable='true'>
        <template slot-scope="{ row }">
          <div
            class="flex gap-5 items-center"
            v-if="row.category_movement != 'remuneration'"
          >
            <!-- {{ row.state_openfactura }} -->
            <!-- I Validation -->
            <div v-if="(row.checksii === 'reject_sii')">

              <ToolTip>
                <!-- Documento rechazado en el SII -->
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999" data-tooltip-target="tooltip-default">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </template>
                <!-- No encontrado en el RCV SII -->
                No encontrado en el SII
              </ToolTip>
            </div>
            <div v-if="(row.checksii === 'success_sii')">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa" data-tooltip-target="tooltip-default">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </template>
                Sincronización exitosa con SII
              </ToolTip>
            </div>
            <div v-if="row.category_movement == 'monthly_expenses'">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </template>
                Gasto manual
              </ToolTip>
            </div>
            <div v-if="((row.state_openfactura == 'success_openfactura') && row.documents.length > 0) || row.documents.length > 0">
              <a v-on:click="openModalDte(row.documents[0])">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>
              </a>
            </div>
            <div v-else-if="row.f29_id">
              <a v-on:click="openModalF29(row.id)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>
              </a>
            </div>
            <div v-else>
              <ToolTip v-if="row.file_movement_url || row.salary_id">
                <template #icon>
                  <a :href="row.file_movement_url" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                    </svg>
                  </a>
                </template>
                Documento guardado manualmente.
              </ToolTip>
              <ToolTip v-else-if="row.ticket_file">
                <template #icon>
                  <a :href="row.ticket_file" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                    </svg>
                  </a>
                </template>
                Documento guardado.
              </ToolTip>
              <ToolTip v-else>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#bdc5cf">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                  </svg>
                </template>
                Documento no encontrado
              </ToolTip>
            </div>
            <div v-if="!row.doble_match && row.classified">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                  </svg>
                </template>
                No cumple partida doble.
              </ToolTip>
            </div>
            <div v-if="row.category_movement != 'form_f29'">
              <div
                v-if="pending_sii"
              >
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                    </svg>
                  </template>
                  Acuse de recibo pendiente.
                </ToolTip>
              </div>
              <div v-else>
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4" />
                    </svg>
                  </template>
                  Acuse de recibo aceptado.
                </ToolTip>
              </div>
            </div>
            <div v-if="row.duplicate_paid">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </template>
                Pago duplicado.
              </ToolTip>
            </div>
            <div v-if="row.count_version > 0">
              <div class="flex gap-2 text-gray-400">
                <ToolTip>
                  <template #icon>
                    <svg @click.prevent="openModalVersions(row.id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>

                  </template>
                  Versiones
                </ToolTip>
              </div>
            </div>
            <div v-if="(row.category_movement != 'form_f29') && row.vendor_id">
              <ToolTips
                :id="row.vendor_id"
                :buy_id="row.buy_id"
              />
            </div>
          </div>
          <div
            v-if="row.category_movement === 'remuneration'"
          >
            <div class="flex gap-5 text-gray-400">
              <div v-if="(row.checksii === 'running_sii')" >
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-gray-400 animate-spin">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                    </svg>
                  </template>
                  Sincronizando con Talana
                </ToolTip>
              </div>
              <div v-if="(row.checksii === 'success_sii')">
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa" data-tooltip-target="tooltip-default">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </template>
                  Sincronización exitosa Talana
                </ToolTip>
              </div>
              <div v-if="(row.checksii === 'reject_sii')" >
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-cyma-red-icon" stroke="currentColor" fill="none" viewBox="0 0 24 24" data-tooltip-target="tooltip-default">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                    </svg>
                  </template>
                  Sincronización rechazada Talana
                </ToolTip>
              </div>
              <div v-if="row.remuneration_file">
                <ToolTip>
                  <template #icon>
                    <a :href="row.remuneration_file" target="blank">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                      </svg>
                    </a>
                  </template>
                  Documento guardado manualmente.
                </ToolTip>
              </div>
              <div v-if="!row.doble_match">
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                    </svg>
                  </template>
                  Partida Doble
                </ToolTip>
              </div>
              <ToolTip>
                <template #icon>
                  <svg @click.prevent="openModalVersions(row.id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                    </svg>
                </template>
                Versiones
              </ToolTip>
              <div class="flex gap-px">
                <ToolTip>
                  <template #icon>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                    </svg>
                  </template>
                  Costo Empresa
                </ToolTip>
                <i>{{ formatNumber(row.company_cost) }}</i>
              </div>
            </div>
          </div>
        </template>
      </BeeColumn>
      <BeeColumn
        field="pending_sii"
        title="Acuse de recibo"
        type="text"
        :width="150"
        :resizable="true"
        :sortable="false"
      >
        <template slot-scope="{ row }">
          <div v-if="row.pending_sii">
            <select
              ref="seleccionado"
              @change="updateMassAcknowledgment(row.id, $event)"
              class="appearance-none"
            >
              <option
                v-for="(option, i) in options"
                :key="i + 'pendingSii'"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </select>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="account_neto_name"
        title="Cuenta"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="270"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow text-xs">
            {{ row.account_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="cost_center_neto_name"
        title="Centro de costo"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="250"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow uppercase">
            {{ row.cost_center_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="branch_office_neto_name"
        title="Sucursal"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="250"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow uppercase">
            {{ row.branch_office_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="date_sii"
        title="Periodo SII"
        type="date"
        :width="130"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatDateSII(row.date_sii) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="mobile"
        title="Gastos Manuales"
        type="text"
        :width="180"
        :resizable="true"
        :sortable="false"
        v-if="!onlynotecredit"
      >
        <template slot-scope="{ row }">
          <div
            class="flex flex-row text-center items-center gap-2"
            v-if="row.mobile"
          >
            <div
              class="block rounded-full h-5 w-5 bg-cover bg-center"
              :style="{
                'background-image': `url('${
                  row.owner.avatar_url || defaultImage
                }')`,
              }"
            ></div>
            <div v-if="row.owner_id">
              {{ row.owner.name }} {{ row.owner.lastname }}
            </div>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="confirmado"
        title="Confirmar"
        type="text"
        :width="100"
        :resizable="true"
        :sortable="false"
        v-if="!onlynotecredit"
      >
        <template slot-scope="{ row }">
          <label class="switch label_switch">
            <input
              class="check"
              type="checkbox"
              :class="{
                notConfirmation: row.confirmation_status == 'accepted',
              }"
              :checked="checkedFunctionValidate(row.confirmation_status)"
              @click.prevent="updateConfirmation($event.target.checked, row.id)"
            />
            <span><slot></slot></span>
          </label>
        </template>
      </BeeColumn>

      <!-- <BeeColumn field="fintoc" title="Fintoc" type="text" :width="100" v-if="!onlynotecredit">
        <template slot-scope="{ row }">
          <div>
          </div>
          <div >
            <label class="switch label_switch">
              <input
                class="check"
                type="checkbox"
                :checked="checkedFunction(row.movement_status)"
                @click.prevent="fintocPay($event.target.checked, row.id)"
              />
              <span><slot></slot></span>
            </label>
          </div>
        </template>
      </BeeColumn> -->
      <BeeColumn
        field="factura"
        title="Anulación"
        type="text"
        :width="140"
        :sortable="false"
        key="factura"
      >
        <template slot-scope="{ row }">
          <div>
            <div v-if="row.credit_note_statement == 'balance' && !row.is_credit_note" class="flex gap-1" >
              Saldo
            </div>
            <a v-else
              @click="creditNotesModal(row.id)"
            >
              <div v-if="row.credit_note_statement == 'voided'" class="flex items-center gap-2">
                Anulada
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                </svg>
              </div>
              <div v-else-if="onlynotecredit" class="flex gap-2 items-center" >
                Vincular <Icon icon="heroicons:document-text" class="h-5 w-5" />
              </div>
            </a>
          </div>
        </template>
      </BeeColumn>
      <BeeColumn
        field="eliminar"
        title="Opciones"
        type="text"
        class="text-center"
        :width="120"
      >
        <template slot-scope="{ row, index }">
          <div class="flex gap-4">
            <button v-if="row.has_credit_notes" @click="updatePay($event.target.checked, row.id)" class="dark:text-cian-dark dark:hover:text-white">
              <Eye />
            </button>
            <button v-if="row.category_movement != 'pending_buy_sii' && row.category_movement != 'form_f29' && row.category_movement != 'form_22' && row.category_movement != 'previred'" @click="editFunction(row.id)" >
              <Edit />
            </button>
            <button :data-cy="'delete' + index" @click="deleteFunction(row.id)" >
              <Times />
            </button>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import {
  formatDocType,
  formatNumber,
  formatStateMovement,
  formatSimpleDate,
  formatDateSII,
  differenceInDays,
} from "@/utils/formatters.js";
import routerMixin from "@/mixins/routerMixin";
import newBuys from "./newBuys";
import newMovement from "./newMovement";
import newPayment from "./newPayment";
import massClasification from "./massClasification.vue";
import modalDocument from "./modalDocument.vue"
import modalVersions from "./modalVersions.vue"
import modalDte from "@/components/documents/modalDte.vue";
import modalF29 from "../tax/modalF29.vue";
import massBuy from "./massBuy.vue";
import editCreditNote from "./editCreditNote.vue"

import "@/assets/stylesheets/switch.scss";
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';
import Eye from '@/components/Icons/Eye.vue';
import ToolTips from "../toolTips.vue";
import ToolTip from '@/components/ToolTip.vue';

export default {
  name: "buys",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      principalCheckbox: false,
      settlement_type: null,
      accounts: [],
      cost_centers: [],
      branch_offices: [],
      showModalVersions: false,
      documents: null,
      showModalDocument: false,
      showmassBuy: false,
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      account_name: "No clasificado",
      buy_id: null,
      checkeds_id: [],
      select: this.$refs.selection,
      status_selecteds_boolean: true,
      action: "create",
      showMovement: false,
      showPayment: false,
      showModal: false,
      showModalCheked: false,
      showModalDte: false,
      showModalF29: false,
      domHTML: '',
      showCreditModal: false,
      buys: [],
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      nameSorting: null,
      sorting: null,
      current_page: 1,
      per_page: 8,
      total_pages: 0,
      total_items: 0,
      without_account: 0,
      options_openfactura: [],
      exists_monthly_expense: 0,
      exists_pending_sii: 0,
      options: [
        {
          value: null,
          text: "Acuse de Recibo",
        },
        {
          value: "ERM",
          text: "Aceptar documento",
        },
        {
          value: "RFT",
          text: "Rechazar documento",
        },
        // {
        //   value: "ERG",
        //   text: "Acuse de Recibo Mercaderías en Guía Despacho del Mes Anterior",
        // },
      ],
      pending_sii_options: [],
      status: [],
      classified_status: [],
      doc_types: [],
      types: [],
      settlement_types: [],
      columns: [
        {
          type: "selection",
          key: "select",
          width: 60,
          align: "center",
        }
      ],
      buy_total: 0,
      loading: true,
      showFilters: false,
      debounceTimer: null,
      status_selecteds: [],
      classified_selecteds: [],
      credit_note_statement: null,
      not_credit_note_statement: null,
    };
  },
  components: {
    newPayment,
    DatePicker,
    newBuys,
    newMovement,
    massClasification,
    modalDocument,
    modalDte,
    modalF29,
    massBuy,
    editCreditNote,
    Edit,
    Times,
    Eye,
    ToolTips,
    ToolTip,
    modalVersions,
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    query: {
      get(){
        return this.$store.state.buy_query;
      },
      set(newValue){
        this.$store.commit('buyQuerySate', newValue);
      }
    },
    onlynotecredit: {
      get(){
        return this.$store.state.buy_onlynotecredit;
      },
      set(newValue){
        this.$store.commit('buyOnlyNoteCreditState', newValue);
      }
    },
    state_openfactura: {
      get(){
        return this.$store.state.buy_state_openfactura;
      },
      set(newValue){
        this.$store.commit('buyStateOpenFacturaState', newValue);
      }
    },
    pending_sii: {
      get(){
        return this.$store.state.buy_pending_sii;
      },
      set(newValue){
        this.$store.commit('buyPendingSiiState', newValue);
      }
    },
    date_type: {
      get(){
        return this.$store.state.buy_date_type;
      },
      set(newValue){
        this.$store.commit('buyDateType', newValue);
      }
    },
    movement_state: {
      get(){
        return this.$store.state.buy_movement_state;
      },
      set(newValue){
        this.$store.commit('buyMovementState', newValue);
      }
    },
    not_classified: {
      get(){
        return this.$store.state.buy_not_classified;
      },
      set(newValue){
        this.$store.commit('buyNotClassifiedState', newValue);
      }
    },
    category_movement: {
      get(){
        return this.$store.state.buy_category_movement;
      },
      set(newValue){
        this.$store.commit('buyCategoryMovementState', newValue);
      }
    },
    doc_type: {
      get(){
        return this.$store.state.buy_doc_type;
      },
      set(newValue){
        this.$store.commit('buyDocTypeState', newValue);
      }
    },
    account: {
      get(){
        return this.$store.state.buy_account_state;
      },
      set(newValue){
        this.$store.commit('buyAccountState', newValue);
      }
    },
    branch_office: {
      get(){
        return this.$store.state.buy_branch_office_state;
      },
      set(newValue){
        this.$store.commit('buyBranchOfficeState', newValue);
      }
    },
    cost_center: {
      get(){
        return this.$store.state.buy_cost_center_state;
      },
      set(newValue){
        this.$store.commit('buyCostCenterState', newValue);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    refresh() {
      return this.$store.state.refresh;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    query(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getBuys();
      }, 500);
    },
    dateRange(){
      this.dateChange();
    },
    settlement_type(){
      this.getBuys();
    },
    refresh(){
      this.getBuys();
    },
    category_movement(){
      this.getBuys();
    },
    state_openfactura(){
      this.getBuys();
    },
    pending_sii(){
      this.getBuys();
    },
    date_type() {
      this.getBuys();
    },
    movement_state(val){
      if(val == 1){
        this.not_credit_note_statement = 'voided';
        this.credit_note_statement = null;
      } else {
        this.credit_note_statement = null;
        this.not_credit_note_statement = null;
      }
      this.getBuys();
    },
    not_classified(){
      this.getBuys();
    },
    doc_type() {
      this.getBuys();
    },
    account(){
      this.getBuys();
    },
    cost_center(){
      this.getBuys();
    },
    branch_office(){
      this.getBuys();
    },
    company_id() {
      this.account = null
      this.getBuys();
      this.getAccountsOnUse();
      this.getCostCentersOnUse();
      this.getBranchOfficesOnUse();
    },
    onlynotecredit() {
      this.getBuys();
    },
  },
  created() {
    this.action = "create";
    this.account = null;
    if( this.$route.query && this.$route.query?.category_movement && this.$route.query?.from && this.$route.query?.to) {
      this.$store.commit('buyCategoryMovementState', parseInt(this.$route.query?.category_movement))
      const [fromYear, fromMonth, fromDay] = this.$route.query?.from.split("-").map(Number);
      const fromDate = new Date(fromYear, fromMonth - 1, fromDay);
      const [toYear, toMonth, toDay] = this.$route.query?.to.split("-").map(Number);
      const toDate = new Date(toYear, toMonth - 1, toDay);
      this.$store.commit('addDateRange', [fromDate, toDate]);
    }
    this.getBuys();
    this.getAccountsOnUse();
    this.getCostCentersOnUse();
    this.getBranchOfficesOnUse();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.pending_sii_options = [
      { value: true, name: "Pendiente de Acuso de Recibo" },
    ]

    this.status = [
      { value: 1, name: "No pagado" },
      { value: 0, name: "Pagado" },
      { value: 4, name: "Abonado" },
    ]

    this.classified_status = [
      { value: true, name: "No Clasificados" },
    ]
    this.doc_types = [
      { value: "30", name: "Factura" },
      { value: "32", name: "Factura de ventas y servicios no afectos o exentos de IVA" },
      { value: "33", name: "Factura Electrónica" },
      { value: "34", name: "Factura no afecta o exenta electrónica" },
      { value: "35", name: "Boleta" },
      { value: "38", name: "Boleta exenta" },
      { value: "39", name: "Boleta electrónica" },
      { value: "40", name: "Liquidación factura" },
      { value: "41", name: "Boleta exenta electrónica" },
      { value: "43", name: "Liquidación factura electrónica" },
      { value: "45", name: "Factura de compra" },
      { value: "46", name: "Factura de compra electrónica" },
      { value: "48", name: "Pago electrónico" },
      { value: "50", name: "Guía de despacho" },
      { value: "52", name: "Guía de despacho electrónica" },
      { value: "55", name: "Nota de débito" },
      { value: "56", name: "Nota de débito electrónica" },
      { value: "60", name: "Nota de crédito" },
      { value: "61", name: "Nota de crédito electrónica" },
      { value: "103", name: "Liquidación" },
      { value: "110", name: "Factura de exportación electrónica" },
      { value: "111", name: "Nota de débito de exportación electrónica" },
      { value: "112", name: "Nota de crédito de exportación electrónica" },
      { value: "honorary", name: "Boleta de Honorarios" },
      { value: "bte", name: "Boleta de Honorarios de terceros" },
      { value: "914", name: "Declaración de Ingreso" },
      { value: "200000000", name: "Crédito Banacario" }
    ]

    this.types = [
      { value: 2, name: "Compras" },
      { value: 3, name: "Boleta de Honorarios" },
      { value: 4, name: "Gastos Manuales" },
      { value: 5, name: "Remuneraciones" },
      { value: 6, name: "Retiros" },
      { value: 13, name: "Formulario 29" },
      { value: 14, name: "Previred" },
      { value: 15, name: "Formulario 22" },
      { value: 18, name: "Creditos" }
    ]

    this.settlement_types = [
      { value: 0, name: 'Anticipo' },
      { value: 1, name: 'Sueldo' },
      { value: 2, name: 'Sueldo Reprocesada' },
      { value: 3, name: 'Finiquito' },
      { value: 4, name: 'Histórico' }
    ]

    this.options_openfactura = [
      { value: 0, name: 'Documento encontrado' },
      { value: 1, name: 'Documento no encontrado' }
    ]
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    formatDocType,
    formatNumber,
    formatStateMovement,
    formatSimpleDate,
    formatDateSII,
    differenceInDays,
    handleSelect(data){
      const index = this.checkeds_id.indexOf(data);
      if (index !== -1) {
          this.checkeds_id.splice(index, 1);
      } else {
          this.checkeds_id.push(data);
      }
    },
    toggleSelectAll(){
      if(!this.principalCheckbox){
        this.cleanSelectAll();
      } else {
        this.handleSelectAll();
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    dateChange(){
      this.account = null
      this.getBuys();
      this.getAccountsOnUse();
      this.getCostCentersOnUse();
      this.getBranchOfficesOnUse();
    },
    cleanFilters(){
      this.movement_state = null
      this.category_movement = null
      this.doc_type = null
      this.pending_sii = null
      this.not_classified = null
      this.state_openfactura = null
      this.query = null
      this.account = null
      this.cost_center = null
      this.branch_office = null
      this.dateRange = [null, null]
    },
    toggleFilters(){
      this.showFilters = !this.showFilters
    },
    openModalDte(documents) {
      this.showModalDte = true;
      this.documents = documents;
    },
    openModalF29(id) {
      let app = this;
      this.showModalF29 = true;
      this.axios
        .get("/movement-f29", {
          params: {
            id: id,
          },
        })
        .then((response) => {
          app.domHTML = response.data;
        });
    },
    openModalVersions(id){
      this.showModalVersions = true,
      this.buy_id = id;
    },
    closeModalDte() {
      this.showModalDte = false;
    },
    checkedDocument(status) {
      if (!status) return false;
      return status
    },
    selecteds_checks() {
      this.showModalCheked = true;
      this.action = "massClassification";
    },
    rowClassName() {
      // update array with ids movements
      // let selecteds_checks = this.$refs.selection.getSelection();
      // this.prueba = selecteds_checks
      // this.checkeds_id = selecteds_checks.map((e) => {
      //   return e.id;
      // });
    },
    setNameOpenfactura(name){
      if(name === 'success_openfactura') {
        return 'Documento obtenido exitosamente';
      } else if (name === 'not_found_openfactura') {
        return 'Documento no encontrado'
      }
    },
    openModalDocument(id){
      this.showModalDocument = true;
      this.buy_id = id;
    },
    massBuyDeletedCheked() {
      let cur = this;
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás esta lista de egresos",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalos",
          cancelButtonText: "No, conservalos",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminados",
              "Tus egresos han sido eliminados",
              "success"
            );
            this.axios.delete('delete-mass', {
              data: {
                company_id: cur.company_id,
                movements: cur.checkeds_id
                }
              }).then(() => cur.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tus egresos no han sido eliminados",
              "error"
            );
          }
        });
    },
    massAcknowledgmentOfReceipt() {
      let cur = this;
      cur.loading = true;
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Acuse de recibo a todos los movimientos seleccionados",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, aceptalos",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Aceptados",
              "Tus documentos fueron aceptados exitosamente",
              "success"
            );
            this.axios.post('acknowledgment-of-receipt-mass', {
              company_id: cur.company_id,
              movements: cur.checkeds_id
            }).then(() => {
              cur.getBuys();
              cur.loading = false;
            });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tus documentos no han sido modificados",
              "error"
            );
            cur.loading = false;
          }
        });
    },
    massBuyCheked(){
      this.showmassBuy = true;
      this.action = "massBuys";
      this.status_selecteds_boolean = this.status_selecteds.includes('paid') || this.classified_selecteds.includes(false)
    },
    handleSelectAll() {
      this.buys.forEach((buy) => {
        this.checkeds_id.push(buy.id);
        this.status_selecteds.push(buy.movement_status);
        this.classified_selecteds.push(buy.classified);
      });
    },
    cleanSelectAll(){
      // this.principalCheckbox = false;
      this.checkeds_id = [];
      this.status_selecteds = [];
      this.classified_selecteds = [];
    },
    updateMassAcknowledgment(movement_id, event) {
      let message = event.target.value === "ERM" ? "aceptar" : "rechazar";
      this.$swal
        .fire({
          title: "¿Estás seguro de " + message + " esta compra?",
          text: "El documento será modificado en Cymasuite y enviado al Servicio de Impuestos Internos.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          showConfirmButton: true,
          confirmButtonText: "Si, estoy seguro",
          showLoaderOnConfirm: true, // Muestra el loader en el botón de confirmación
          allowOutsideClick: false,
          preConfirm: () => {
            return this.axios.post("update-mass-acknowledgment", {
              id: movement_id,
              dedCodEvento: event.target.value,
            })
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire(
              "Modificado",
              "Tu egreso ha sido modificado",
              "success"
            )
            this.getBuys();
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido modificado",
              "error"
            );
          }
        });
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getBuys();
    },
    deleteFunction(id) {
      // /movements/:id
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu egreso ha sido eliminado",
              "success"
            );
            this.axios.delete(`movements/${id}`).then(() => this.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    formatRemuneration(value) {
      switch (value) {
        case "salary":
          return "Sueldo";
        case "advance":
          return "Anticipo";
        case "reprocessed_salary":
          return "Sueldo";
        case "settlement":
          return "Finiquito";
        case "historical":
          return "Sueldo";
      }
    },
    reload() {
      this.$mount();
    },
    updatePay(event, id) {
      console.log(event);
      this.showMovement = true;
      this.buy_id = id;
    },
    fintocPay(event, id){
      console.log(event);
      this.showPayment = true;
      this.buy_id = id;
    },
    updateConfirmation(status, id) {
      if (status) {
        status = 0;
      } else {
        status = 1;
      }
      this.$swal
        .fire({
          title: "¿Seguro de modificar el pago?",
          text: "Este egreso sera modificado",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, estoy seguro",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Modificado",
              "Tu egreso ha sido modificado",
              "success"
            );
            this.axios
              .post(`update-confirmation/${id}`, {
                confirmation_status: status,
              })
              .then(() => this.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido modificado",
              "error"
            );
          }
        });
    },
    closeFunction() {
      this.showMovement = false;
    },
    checkedFunction(status) {
      if (status == "not_paid") return false;
      return status === "paid_subscriber" || status === "paid";
    },
    checkedFunctionValidate(status) {
      if (status == "rejected") return false;
      return status === "accepted";
    },
    exportInExcel() {
      this.loadingFile = true
      let app = this;
      this.axios.post('/movements-excel', {
        type_movement: "type_buy",
        category_movement: app.category_movement || [2, 3, 4, 5, 6, 10, 11, 12, 18],
        company_id: app.company_id,
        from: app.dateRange[0],
        to: app.dateRange[1],
        movement_status: app.movement_state,
        state_openfactura: app.state_openfactura,
        query: app.query,
        onlynotecredit: app.onlynotecredit,
        not_classified: app.not_classified,
        doc_type: app.doc_type,
        pending_sii: app.pending_sii,
        settlement_type: app.settlement_type,
        order_date: 'desc',
        account: app.account,
        branch_office: app.branch_office,
        cost_center: app.cost_center,
        date_type: app.date_type,
      }, {
          responseType: 'blob'
      }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', ('Egresos-Excel-' + this.formatSimpleDate(this.dateRange[0]) + 'al' + this.formatSimpleDate(this.dateRange[1])))
          document.body.appendChild(link)
          link.click()
          this.loadingFile = false
      });
    },
    createButton() {
      this.buy_id = null;
      this.showModal = true;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.buy_id = id;
      this.action = "update";
    },
    creditNotesModal(id) {
      this.showCreditModal = true;
      this.buy_id = id;
      this.action = "update";
    },
    showAccount(id) {
      this.axios.get("/account/" + id).then((response) => {
        if (response.length >= 0) {
          this.account_name = response.name;
        }
      });
    },
    getBuys() {
      this.loading = true;
      let app = this;
      let categoriesMovement
      if(app.category_movement == 2){
        categoriesMovement = [2, 10]
      } else {
        categoriesMovement = app.category_movement
      }
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: categoriesMovement || [2, 3, 4, 5, 6, 10, 11, 12, 18],
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            pending_sii: app.pending_sii,
            sorting: app.sorting,
            state_openfactura: app.state_openfactura,
            query: app.query,
            onlynotecredit: app.onlynotecredit,
            not_classified: app.not_classified,
            doc_type: app.doc_type,
            settlement_type: app.settlement_type,
            order_date: 'desc',
            account: app.account,
            branch_office: app.branch_office,
            cost_center: app.cost_center,
            date_type: app.date_type,
            credit_note_statement: app.credit_note_statement,
            not_credit_note_statement: app.not_credit_note_statement,
          },
        })
        .then((response) => {
          app.buys = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.without_account = response.data.without_account;
          app.movement_status = response.data.movement_status;
          app.buy_total = response.data.buy_total;
          app.exists_monthly_expense = response.data.monthly_expense;
          app.exists_pending_sii = response.data.pending_sii;
          app.loading = false;
        });
    },
    getAccountsOnUse() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/accounts-on-use", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [2, 3, 4, 5, 6, 10, 12, 18],
            sorting: app.sorting,
            order_date: 'desc',
            account: app.account
          },
        })
        .then((response) => {
          app.accounts = response.data
          app.loading = false;
        });
    },
    getCostCentersOnUse() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/cost-centers-on-use", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [2, 3, 4, 5, 6, 10, 12, 18],
            sorting: app.sorting,
            order_date: 'desc',
            // cost_center: app.cost_center
          },
        })
        .then((response) => {
          if(response.data.length > 0){
            let array = [ 'SIN CENTRO DE COSTO' ]
            let data_array = response.data
            app.cost_centers = array.concat(data_array)
          } else {
            app.cost_centers = response.data
          }
          app.loading = false;
        });
    },
    getBranchOfficesOnUse() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/branch-offices-on-use", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [2, 3, 4, 5, 6, 10, 12, 18],
            sorting: app.sorting,
            order_date: 'desc',
            // branch_office: app.branch_office
          },
        })
        .then((response) => {
          if(response.data.length > 0){
            let array = [ 'SIN SUCURSAL' ]
            let data_array = response.data
            app.branch_offices = array.concat(data_array)
          } else {
            app.branch_offices = response.data
          }
          app.loading = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
.notPaid {
  &:checked {
    + span {
      &:before {
        background-color: rgba(#f9fd00, 0.5);
      }
      &:after {
        background-color: #f9fd00;
        transform: translate(80%, -50%);
      }
    }
  }
}
.notConfirmation {
  &:checked {
    + span {
      &:before {
        background-color: rgba(#0e606b, 0.5);
      }
      &:after {
        background-color: #0e606b;
        transform: translate(80%, -50%);
      }
    }
  }
}
.tooltip{
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
</style>
