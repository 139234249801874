<template>
  <div class="py-2 mx-6 gap-5 flex flex-col">
    <div class="flex items-center gap-4 flex-col">
      <div class=" flex w-full items-center">
        <h1 class="text-lg sm:text-xl font-semibold dark:text-white">
        Historial
        </h1>
      </div>
      <div class="flex flex-wrap flex-col md:flex-row gap-2 mb-6 w-full">

        <div class="filter ">
          <input
            type="text"
            class="input-text"
            placeholder="Buscar"
            @input="getHistorial"
            v-model="query"
          />
        </div>
        <div>
          <v-select
            class="style-chooser custom-border"
            v-model="formatType"
            :options="formatTypes"
            :reduce="(formatType) => formatType.value"
            @input="getHistorial"
            :clearable="true"
            placeholder="Formato Reporte"
          ></v-select>
        </div>
        <div>
          <v-select
            class="style-chooser custom-border"
            v-model="reportType"
            :options="reportTypes"
            :reduce="(reportType) => reportType.value"
            @input="getHistorial"
            :clearable="true"
            placeholder="Tipo de Reporte"
          ></v-select>
        </div>
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            v-model="dateRange"
            type="date"
            range
            format='DD-MM-YYYY'
            placeholder="Fecha"
            v-on:input="getHistorial"
            :style="{
              width: '100%',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.65rem',
              'padding-bottom': '0.65rem',
              'border-radius': '0.5rem'
            }"
          ></date-picker>
        </div>
      </div>
    </div>
    <BeeGridTable
      :serverSide="true"
      :showFilter="false"
      :showSummary="false"
      border
      height="560"
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :columns="columns"
      :data="log"
      :loading="loading"
      :fetchMethod="fetchMethod"
    >
      <BeeColumn field="email" title="Usuario" type="text" :width="200">
        <template slot-scope="{ row }">
          <p>
            {{ row.user.email }}
          </p>
        </template>
      </BeeColumn>
      <BeeColumn field="created_at" title="Fecha de solicitud" type="text" :width="200" :sortable="true">
        <template slot-scope="{ row }">
          <p>
            {{ formatSimpleDate(row.created_at) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="format_type" title="Formato" type="text"  :width="100">
        <template slot-scope="{ row }">
          <p>
            {{ capitalize(row.format_type) }}
          </p>
        </template>
      </BeeColumn>
      <BeeColumn field="report_type" title="Reporte" type="text"  :width="200">
        <template slot-scope="{ row }">
          <p>
            {{ formatTypeLog(row.report_type) }}
          </p>
        </template>
      </BeeColumn>
      <BeeColumn field="url" title="Url" type="text"  :width="200">
        <template slot-scope="{ row }">
          <a @click.prevent="downloadFile(row.url , row.format_type)" class="dark:text-cian-dark dark:hover:text-white">Descargar</a>
        </template>
      </BeeColumn>

      <BeeColumn field="from" title="Rango Solicitado" type="text"  :width="400">
        <template slot-scope="{ row }">
          <p v-if="row.from || row.to">
            {{ formatSimpleDate(row.from) }}
            al
            {{ formatSimpleDate(row.to  ) }}
          </p>
        </template>
      </BeeColumn>
      <BeeColumn
        field="eliminar"
        title="Opciones"
        type="text"
        class="text-center"
        :width="120"
      >
        <template slot-scope="{ row }">
          <a v-on:click="deleteFunction(row.id)" class="pl-8">
            <Times />
          </a>
        </template>
      </BeeColumn>

    </BeeGridTable>
  </div>
</template>

<script>
import { formatSimpleDate } from "@/utils/formatters";
import DatePicker from 'vue2-datepicker';
import Times from "@/components/Icons/Times.vue"
export default {
  data() {
    return {
      log: [],
      loading: true,
      filterTotal: 0,
      total: 0,
      page: 1,
      current_page: 1,
      pageSize: 0,
      pageCount: 0,
      per_page: 10,
      columns: [],
      query: '',
      formatTypes: [],
      reportTypes: []
    };
  },
  components: {
    DatePicker,
    Times
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id() {
      this.getHistorial();
    },
  },
  created() {
    this.getHistorial();
    this.formatTypes = [
      { label: 'Todos', value: null },
      { label: 'Excel', value: 'excel' },
      { label: 'PDF', value: 'pdf' }
    ]

    this.reportTypes = [
      { label: 'Todos', value: null },
      { label: 'Libro Diario', value: 'daily_book' },
      { label: 'Libro Mayor', value: 'higher_book' },
      { label: 'Balance', value: 'balance' },
      { label: 'Estado de Resultado', value: 'statement_of_income' },
      { label: 'Estado de Resultado Comparativo', value: 'statement_of_income_comparative' },
      { label: 'Flujo de Caja', value: 'cashflow' },
      { label: 'Remuneraciones', value: 'remuneration' },
      { label: 'Impuestos', value: 'tax' },
    ]
  },
  methods: {
    formatSimpleDate,
    deleteFunction(id) {
      // /movements/:id
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Sera borrado este registro del Historial",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "El registro a sido eliminado",
              "success"
            );
            this.axios.delete(`logs/${id}`)
            .then(() => this.getHistorial());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Se a conservado el registro",
              "error"
            );
          }
        });
    },
    downloadFile(url, type){
      let nameFile
      if(type == 'excel'){
        nameFile = url.split('xls/')[1]
      } else if ( type == 'pdf'){
        nameFile = url.split('pdf/')[1]
      }
      fetch(url)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = nameFile;
          link.click();
      })
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getHistorial();
    },
    getHistorial() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/logs", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            from: app.dateRange[0],
            to: app.dateRange[1],
            query: app.query,
            format_type: app.formatType,
            report_type: app.reportType
          },
        })
        .then((response) => {
          app.log = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
    },
    capitalize(word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
    },
    formatTypeLog(value){
      try {
        let typeLog = {
          daily_book: 'Libro Diario',
          higher_book: 'Libro Mayor',
          tax: 'Impuestos',
          balance: 'Balance',
          statement_of_income: 'Estado Resultado',
          statement_of_income_comparative: 'Estado Resultado Comparativo',
          cashflow: 'Flujo de Caja',
          remuneration: 'Remuneraciones',
        }
        return typeLog[value]
      } catch {
        return ''
      }
    }
  },
};
</script>
<style scoped>
.filter .style-chooser {
  min-width: 250px;
}
</style>
