import Login from "./user/Login.vue";
import Register from "./user/Register";
import sendEmail from "./user/sendEmail";
import ChangePassword from "./user/changePassword.vue";

// Only Graph
import OnlyGraph from "@/views/private/components/graph/index";

export const PublicRoutes = [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: {
      auth: false,
    },
  },
  {
    path: "/only-graph",
    name: "onlyGraph",
    meta: {
      auth: false,
    },
    component: OnlyGraph,
    props: true,
  },
  {
    path: "/register",
    component: Register,
    name: "register",
    meta: {
      auth: false,
    },
  },
  {
    path: "/resendEmail",
    component: sendEmail,
    name: "sendEmail",
    meta: {
      auth: false,
    },
  },
  {
    path: "/changePassword",
    component: ChangePassword,
    name: "changePassword",
    meta: {
      auth: false,
    },
  }
  
];
