<template  >
  <div class="py-2 mx-6">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold dark:text-white mb-4">Auditoria Contable</h1>
      <BeeGridTable
        :serverSide="true"
        border
        height="580"
        :showFilter="false"
        :showSummary="false"
        :filterTotal="filterTotal"
        :total="total"
        :page="page"
        :pageSize="pageSize"
        :pageCount="pageCount"
        :fetchMethod="fetchMethod"
        :columns="columns"
        :data="data"
        :loading="loading"
      >
        <BeeColumn field="event" title="Evento" type="text">
          <template slot-scope="{ row }">
            {{ formatterAttr(row.event) }}
          </template>
        </BeeColumn>

        <BeeColumn field="item_type" title="Objeto" type="text">
          <template slot-scope="{ row }">
            {{ row.item_type }}
          </template>
        </BeeColumn>

        <BeeColumn field="item_id" title="Id" type="text">
          <template slot-scope="{ row }">
            {{ row.item_id }}
          </template>
        </BeeColumn>

        <BeeColumn field="whodunnit" title="Usuario" type="text">
          <template slot-scope="{ row }">
            {{ row.whodunnit }}
          </template>
        </BeeColumn>

        <BeeColumn field="created_at" title="Actualización" type="text">
          <template slot-scope="{ row }">
            {{ formatCompleteDate(row.created_at) }}
          </template>
        </BeeColumn>

        <BeeColumn field="created_at" title="Acciones" type="text">
          <template slot-scope="{ row }">
            <div class="flex gap-2 text-gray-400">
              <ToolTip>
                <template #icon>
                  <svg @click.prevent="openModalVersions(row.movement_id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
                  </svg>
                </template>
                Versiones
              </ToolTip>
            </div>
          </template>
        </BeeColumn>
      </BeeGridTable>
      <modalVersions
        v-if="showModalVersions"
        @close="showModalVersions = false"
        :buy_id="buy_id"
        :action="action"
      ></modalVersions>
    </div>
  </div>
</template>

<script>
import { formatCompleteDate } from "@/utils/formatters";
import modalVersions from "../buys/modalVersions.vue";
import ToolTip from '@/components/ToolTip.vue';

export default {
  name: "Versions",
  props: {},
  data() {
    return {
      data: [],
      user: {},
      showModalVersions: false,
      loading: false,
      columns: [],
      total: 0,
      current_page: 1,
      page: 1,
      pageSize: 0,
      pageCount: 0,
      filterTotal: 0,
      per_page: 8,
      total_pages: 0,
      total_items: 0,
      action: "create"
    };
  },
  components: {
    modalVersions,
    ToolTip
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  mounted() {
    this.user = this.$auth.user();
    this.getVersions();
  },
  watch: {
    company_id() {
      this.getVersions();
    },
  },
  methods: {
    formatCompleteDate,
    formatterObj(attr){
      let formatter = {
        'Movement':  "Moviemiento",
        'AccountMovement': 'Asiento Contable'
      }
      return formatter[attr]
    },
    formatterAttr(attr){
      let formatter = {
        'description':  "Descripción",
        'update': 'Actualización',
        'create': 'Creación'
      }
      return formatter[attr]
    },
    openModalVersions(id){
      this.showModalVersions = true,
      this.buy_id = id;
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getVersions();
    },
    getVersions() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/versions", {
          params: {
            company_id: app.company_id,
            user_id: app.user.id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
          },
        })
        .then((response) => {
          app.data = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
    },
  },
};
</script>
