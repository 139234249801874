<template>
  <div>
    <transition name="modal">
      <div
        class="h-screen w-screen fixed top-0 left-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        v-if="loading_file || loading"
      >
        <Loader />
      </div>
      <div v-else class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="dark:bg-background-dark bg-white w-screen h-screen md:h-auto overflow-auto py-5 px-4 md:w-full md:max-h-screen md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="font-semibold text-grey-darker dark:text-white py-8 lg:text-4xl text-3xl"
                  v-if="action === 'create'"
                >
                  Crear nuevo egreso
                </h1>
                <h1
                  class="font-semibold text-grey-darker dark:text-white py-8 lg:text-4xl text-3xl"
                  v-else
                >
                  Actualizar egreso
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="$emit('close')">
                  &times;
                </a>
              </div>
            </div>
            <div class="" id="modalWidth">
              <slot name="body">
                <form
                  class="grid gap-5 grid-cols-1 md:grid-cols-1 lg:grid-cols-1"
                  enctype="multipart/form-data"
                >
                  <div class="flex flex-col">
                    <label
                      data-id="selectType"
                      class="label-cymasuite"
                      >Tipo</label
                    >
                    <div
                      :class="{
                        disable: [2, 3].includes(buyObj.category_movement),
                      }"
                    >
                      <v-select
                        data-cy="type"
                        :class="{ error: $v.buyObj.category_movement.$error }"
                        class="style-chooser custom-border"
                        :options="types"
                        :selectable="(type) => !type.name.includes('SII')"
                        :reduce="(type) => type.value"
                        label="name"
                        append-to-body
                        v-model="$v.buyObj.category_movement.$model"
                      ></v-select>
                      <div
                        class="text_error"
                        v-if="$v.buyObj.category_movement.$error"
                      >
                        Este campo es requerido
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      $v.buyObj.category_movement.$model == 5 ||
                      $v.buyObj.category_movement.$model == 6
                    "
                    class="flex flex-col gap-4 w-full h-screen md:w-full md:h-full"
                  >
                    <newRemuneration :buy_id="buy_id" :action="action">
                    </newRemuneration>
                  </div>

                  <div
                    v-else-if="
                      $v.buyObj.category_movement.$model == 4 ||
                      $v.buyObj.category_movement.$model == 2 ||
                      $v.buyObj.category_movement.$model == 3
                    "
                  >
                    <div class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                      <div
                        class="flex flex-col"
                        v-if="buyObj.category_movement === 3"
                      >
                        <label
                          class="label-cymasuite"
                          >Prestador de Servicios</label
                        >
                        <div
                          :class="{
                            disable: [2, 3].includes(buyObj.category_movement),
                          }"
                        >
                          <v-select
                            :class="{
                              error: $v.buyObj.service_provider_id.$error,
                            }"
                            class="style-chooser custom-border"
                            :options="service_providers"
                            :reduce="(service_provider) => service_provider.id"
                            label="name"
                            v-model="$v.buyObj.service_provider_id.$model"
                          ></v-select>
                          <div
                            class="text_error"
                            v-if="$v.buyObj.service_provider_id.$error"
                          >
                            Este campo es requerido
                          </div>
                        </div>
                      </div>

                      <div v-if="buyObj.category_movement != 3" class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Proveedor</label
                        >
                        <div
                          :class="{
                            disable: [2, 3].includes(buyObj.category_movement),
                          }"
                        >
                          <v-select
                            :class="{ error: $v.buyObj.vendor_id.$error }"
                            class="style-chooser custom-border"
                            :options="vendors"
                            :reduce="(vendor) => vendor.id"
                            label="name"
                            v-model="$v.buyObj.vendor_id.$model"
                            placeholder="Proveedor"
                            data-id='provider'
                            data-cy="provider"
                          ></v-select>
                          <div
                            class="text_error"
                            v-if="$v.buyObj.vendor_id.$error"
                          >
                            Este campo es requerido
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >N° Documento</label
                        >
                        <input
                          :class="{
                            error: $v.buyObj.folio.$error,
                            disable: [2, 3].includes(buyObj.category_movement),
                          }"
                          data-cy="folio"
                          type="text"
                          class="input-text"
                          placeholder="Folio"
                          v-model="$v.buyObj.folio.$model"
                        />
                        <div class="text_error" v-if="$v.buyObj.folio.$error">
                          Este campo es requerido
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Monto</label
                        >
                        <currency-input
                          :class="{
                            error: $v.buyObj.total.$error,
                            disable: [2, 3].includes(buyObj.category_movement),
                          }"
                          currency="CLP"
                          data-cy="amount"
                          locale="es-CL"
                          placeholder="Monto"
                          class="input-text"
                          v-model="$v.buyObj.total.$model"
                        />
                        <div class="text_error" v-if="$v.buyObj.total.$error">
                          Este campo es requerido
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Fecha</label
                        >
                        <date-picker
                          data-cy=date
                          :class="{
                            disable: [2, 3].includes(buyObj.category_movement),
                          }"
                          input-class="xmx-input"
                          :style="{
                            width: '100%',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.80rem',
                            'padding-bottom': '0.80rem',
                            'border-radius': '0rem'
                          }"
                          type="date"
                          format='DD-MM-YYYY'
                          v-model="$v.buyObj.period.$model"
                          placeholder="Fecha"
                        ></date-picker>
                        <div class="text_error" v-if="$v.buyObj.period.$error">
                          Este campo es requerido
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div class="flex gap-2">
                          <label
                            class="label-cymasuite"
                            >Fecha de Caducidad
                          </label>
                          <ToolTip additionalClass="sm:w-64 lg:w-24 w-52 bg-gray-muted">
                            <template #icon>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                              </svg>
                            </template>
                            <p class="text-xs">
                              Si es vacío, tomará el valor de caducidad del proveedor.
                            </p>
                          </ToolTip>
                        </div>
                        <date-picker
                          input-class="xmx-input"
                          :style="{
                            width: '100%',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.80rem',
                            'padding-bottom': '0.80rem',
                            'border-radius': '0rem'
                          }"
                          type="date"
                          format='DD-MM-YYYY'
                          v-model="buyObj.estimated_payment"
                          placeholder="Fecha"
                        ></date-picker>
                      </div>
                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Descripción</label
                        >
                        <input
                          type="text"
                          data-cy="description"
                          class="input-text"
                          placeholder="Descripción"
                          v-model="buyObj.description"
                        />
                      </div>

                      <div class="flex justify-between">
                        <div class="flex flex-col">
                          <label
                            class="label-cymasuite"
                            >Adjuntar</label
                          >
                          <input
                            type="file"
                            id="files"
                            data-cy="file-input"
                            class="
                              dark:bg-black-dark
                              dark:border-background-dark
                              appearance-none
                              block
                              w-full
                              bg-grey-lighter
                              text-grey-darker
                              border border-grey-lighter
                              rounded
                            "
                            ref="inputFile"
                            @change.prevent="uploadFile()"
                          />
                          <br />
                          <div v-if="!!url">
                            <a :href="url" target="blank">
                              {{ filename }}
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <label
                          class="label-cymasuite"
                          >Tipo Compra</label
                        >
                        <div :class="{ disable: disableTypePurchase }">
                          <v-select
                            class="style-chooser custom-border"
                            :options="optionsTypePurchase"
                            :reduce="(type) => type.value"
                            label="name"
                            v-model="buyObj.doc_value"
                            placeholder="Tipo Compra"
                            data-cy="typePurchase"
                          ></v-select>
                          <div
                            class="text_error"
                            v-if="$v.buyObj.category_movement.$error"
                          >
                            Este campo es requerido
                          </div>
                        </div>
                        <button class="pt-1.5 ml-auto text-gray-600 hover:text-cyma-purple dark:text-gray-200 dark:hover:text-white focus:outline-none" @click.prevent="changeTypePurchase" data-cy="lock">
                          {{ nameTypePurchase }}
                        </button>
                      </div>
                    </div>

                    <div class="col-span-full overflow-y-auto">
                      <addAccountMovement
                        :action="action"
                        :dataAcc="dataAcc"
                        :typeMovement="'buy'"
                        :totalAmount="buyObj.total"
                        :neto="buyObj.neto"
                        :ids="ids"
                        @addAttribute="addAttribute"
                      ></addAccountMovement>
                    </div>
                    <div class="flex flex-col">
                      <label
                        class="flex justify-start items-center pr-2 mt-4"
                        v-if="
                          buyObj.vendor &&
                          !buyObj.vendor.account_id &&
                          dataAcc.length === 1
                        "
                      >
                        <div
                          class="
                            bg-white
                            border-2
                            rounded
                            border-gray-400
                            w-6
                            h-6
                            flex flex-shrink-0
                            justify-center
                            items-center
                            mr-2
                            focus-within:border-blue-500
                          "
                        >
                          <input
                            type="checkbox"
                            class="opacity-0 absolute"
                            v-model="buyObj.movement_checked"
                          />
                          <svg
                            class="
                              fill-current
                              hidden
                              w-4
                              h-4
                              text-green-500
                              pointer-events-none
                            "
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        </div>
                        <div
                          class="
                            select-none
                            mr-2
                            text-sm
                            tracking-normal
                            text-gray-600
                          "
                        >
                          Categorizar a
                          <span class="font-black">{{
                            buyObj.vendor.name
                          }}</span>
                          siempre en está cuenta.
                        </div>
                      </label>
                    </div>
                    <div class="modal-footer flex lg:justify-end justify-center py-2">
                      <slot name="footer">
                        <div class="">
                          <button
                            :disabled="submitValidation"
                            class="button-cymasuite"
                            @click.prevent="submit"
                          >
                            Guardar Información
                          </button>
                        </div>
                      </slot>
                    </div>
                  </div>
                </form>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ToolTip from '@/components/ToolTip.vue';
import DatePicker from "vue2-datepicker";
import addAccountMovement from "./addAccountMovement";
import { required, requiredIf } from "vuelidate/lib/validators";
import newRemuneration from "./newRemuneration.vue";
import Loader from "@/components/loader.vue";
export default {
  name: "newBuy",
  props: {
    buy_id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      loading: false,
      loading_file: false,
      showAddEmployee: false,
      submitValidation: false,
      file: null,
      inputActive: false,
      disableTypePurchase: true,
      employees: [],
      employee_id: null,
      employee_rut: null,
      rut: null,
      dateRangeRemuneration: [],
      nameTypePurchase: "Desbloquear",
      dataAcc: [
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          neto: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "buy",
          state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
        },
      ],
      users: [],
      vendors: [],
      accounts: [],
      inputPicture: "",
      coins: [
        { value: 0, name: "PEN" },
        { value: 1, name: "CHL" },
      ],
      types: [
        { value: 2, name: "Compras SII" },
        { value: 3, name: "Honorarios SII" },
        { value: 4, name: "Gastos Manuales" },
        { value: 5, name: "Remuneraciones" },
        // { value: 6, name: "Retiros" },
      ],
      optionsTypePurchase: [
        { name: "Del Giro", value: 1 },
        { name: "Supermercados", value: 2 },
        { name: "Bienes Raíces", value: 3 },
        { name: "Activo Fijo", value: 4 },
        { name: "IVA Uso Común", value: 5 },
        { name: "IVA no Recuperable", value: 6 },
        { name: "No Corresp. Incluir", value: 7 },
      ],
      filename: null,
      url: null,
      ids: [],
      buyObj: {
        type_movement: "type_buy",
        category_movement: null,
        coin: null,
        total: null,
        vendor: null,
        period: "",
        responsable: null,
        folio: null,
        account_neto_id: null,
        company_id: null,
        user_id: null,
        vendor_id: null,
        neto: null,
        file_movement: null,
        description: null,
        service_provider_id: null,
        movement_status: "not_paid",
        doc_value: null,
        account_movements_attributes: [],
        estimated_payment: null,
      },
    };
  },
  components: {
    addAccountMovement,
    newRemuneration,
    Loader,
    DatePicker,
    ToolTip
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
    buyObj: {
      service_provider_id: {
        requiredIfHonorary: requiredIf( function(){
          return this.buyObj.category_movement == 3
        })
      },
      vendor_id: {
        requiredIfHonorary: requiredIf( function(){
          return this.buyObj.category_movement != 3
        })
      },
      period: {
        required,
      },
      category_movement: {
        required,
      },
      folio: {
        required,
      },
      total: {
        required,
      },
    },
  },
  watch: {
    buyObj: {
      handler: function (val) {
        if (this.action === "create") {
          if (this.dataAcc.length === 1) {
            this.buyObj.neto = val.total;
            this.buyObj.date_sii = val.period;
            this.dataAcc[0].total_amount = val.total;
            this.dataAcc[0].max = val.total;
          }
        }
      },
      deep: true,
    },
    employee_id() {
      this.getEmployee();
    },
  },
  created(){
    if(this.action == 'update'){
      this.loading = true
      this.getBuy();
    }
  },
  mounted() {
    this.getUsers();
    this.getVendors();
    this.getServiceProviders();
    this.getFile();
    this.getAllEmployees();
  },

  methods: {
    getEmployee() {
      let app = this;
      this.axios
        .get("/employees/" + app.employee_id, {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          this.employee_rut = response.data.rut;
        });
    },
    submited(event) {
      this.employee_id = event;
    },
    getAllEmployees() {
      let app = this;
      this.axios
        .get("/employees", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          app.employees = response.data.data;
        });
    },
    changeTypePurchase() {
      if (this.disableTypePurchase) {
        this.disableTypePurchase = false;
        this.nameTypePurchase = "Bloquear";
      } else {
        this.disableTypePurchase = true;
        this.nameTypePurchase = "Desbloquear";
      }
    },
    addAttribute(e) {
      this.buyObj.account_movements_attributes = e;
      this.dataAcc = e;
    },
    getFile() {
      if (this.buy_id) {
        this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
          this.url = e.data.url;
          this.filename = e.data.name;
        });
      }
    },
    getUsers() {
      this.axios
        .get("users")
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getVendors() {
      let app = this;
      this.axios
        .get("all-vendors", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          app.vendors = response.data;
        });
    },
    getServiceProviders() {
      let app = this;
      this.axios
        .get("/all-service-provider", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          app.service_providers = response.data;
        });
    },
    getBuy() {
      this.loading = true
      if (this.buy_id) {
        this.axios.get(`movements/${this.buy_id}`).then((res) => {
          switch (res.data.category_movement) {
            case "buy_sii":
              this.buyObj.category_movement = 2;
              break;
            case "honorary_sii":
              this.buyObj.category_movement = 3;
              break;
            case "monthly_expenses":
              this.buyObj.category_movement = 4;
              break;
            case "remuneration":
              this.buyObj.category_movement = 5;
              break;
            case "withdraw":
              this.buyObj.category_movement = 6;
          }
          this.buyObj.coin = res.data.coin === "pen" ? 0 : 1;
          this.buyObj.total = Number(res.data.total);
          this.buyObj.neto = Number(res.data.neto);
          this.buyObj.period = new Date(res.data.period);
          let estimatedPayment = new Date(res.data.estimated_payment)
          estimatedPayment.setMinutes(estimatedPayment.getMinutes() + estimatedPayment.getTimezoneOffset())
          this.buyObj.estimated_payment = estimatedPayment;
          this.buyObj.responsable = res.data.responsable;
          this.buyObj.folio = res.data.folio;
          this.buyObj.company_id = res.data.company_id;
          this.buyObj.vendor_id = res.data.vendor_id;
          this.buyObj.service_provider_id = res.data.service_provider_id;
          this.buyObj.user_id = res.data.user_id;
          this.buyObj.vendor = res.data.vendor;
          this.buyObj.account_neto_id = res.data.account_neto_id;
          this.buyObj.movement_status = res.data.movement_status;
          this.buyObj.movement_checked = res.data.movement_checked;
          this.buyObj.doc_name = res.data.doc_name;
          this.buyObj.doc_value = res.data.doc_value;
          this.buyObj.description = res.data.description;
          this.dataAcc = res.data.account_movements;
          this.setMaxMin(res.data.account_movements_only_neto);
          this.loading = false
        });
      }
    },
    uploadFile() {
      this.file = this.$refs.inputFile.files[0];
    },
    setMaxMin(account_movements) {
      if(account_movements.length == 0 && this.action == 'update'){
        account_movements = [
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: this.buyObj.total,
          neto: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "buy",
          state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 100,
          divide: null,
          min: 0,
          max: this.buyObj.total,
          date_sii: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
          company_id: this.$store.state.company_id,
          disable: false,
        },
        ]
      }
      let a = account_movements.map((element, index) => {
        if (
          this.action === "create" ||
          element.state === "neto" ||
          element.state === "exempt_amount" ||
          element.state === "gross" ||
          this.action === "update"
        ) {
          let porcentaje = (element.total_amount / this.buyObj.neto) * 100;
          element.percentage = Math.round(porcentaje, 2);
          element.min = 0;
          element.max = Math.floor(this.buyObj.neto * element.percentage) / 100;
          this.ids.push(index);
          element.disable = true;
          if(index == account_movements.length - 1){
            element.disable = false
          }
          return element;
        }
        return element;
      });
      this.dataAcc = a;
      console.log(a);
    },
    updateFile(id) {
      let data = new FormData();
      data.append("file", this.file);
      data.append("id", id);
      this.loading_file = true
      this.axios
        .patch("/update_file", data)
        .then(() => {
          this.$swal.fire(
            "Actualizado",
            "Tu egreso ha sido actualizado con exito",
            "success"
            );
            this.loading_file = false
            this.$emit("getBuys");
            this.$emit("close");
        })
        .catch((e) => console.log(e));
    },
    create(data) {
      this.submitValidation = true;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$swal.fire("Oops..", "Parece que te olvidaste de llenar algunos campos", "warning");
        this.submitValidation = false;
        return
      }
      this.axios
        .post("/movements", data)
        .then((obj) => {
          if(this.file){
            this.updateFile(obj.data.id)
          } else {
            this.$swal.fire(
              "Creado",
              "Tu egreso ha sido creado con exito",
              "success"
            );
            this.$emit("getBuys");
            this.$emit("close");
          }
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    update(data, id) {
      this.submitValidation = true;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.$swal.fire("Oops..", "Parece que te olvidaste de llenar algunos campos", "warning");
        this.submitValidation = false;
        return
      }
      this.axios
        .patch("movements/" + id, data)
        .then((obj) => {
          if(this.file){
            this.updateFile(obj.data.id)
          }else {
            this.$swal.fire(
            "Actualizado",
            "Tu egreso ha sido actualizado con exito",
            "success"
            );
            this.$emit("getBuys");
            this.$emit("close");
          }
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit() {
      let totalPorcentaje = 0;
      this.buyObj.company_id = this.$store.state.company_id;
      if (this.buyObj.account_movements_attributes.length <= 0) {
        this.buyObj.account_movements_attributes = this.dataAcc;
      }
      this.buyObj.account_movements_attributes.forEach(
        (e) => (totalPorcentaje += e.percentage)
      );
      if (totalPorcentaje < 100) {
        throw this.$swal.fire(
          "Oops...",
          "Algo ha salido mal con el porcentaje",
          "error"
        );
      }
      this.action == "create"
        ? this.create(this.buyObj)
        : this.update(this.buyObj, this.buy_id);
    },
  },
};
</script>
<style>
textarea:focus,
input:focus {
  outline: none;
}
</style>
