<template>
  <div class="h-screen flex relative overflow-hidden bg-white">
    <div class="items-center self-end justify-end hidden h-full lg:flex lg:w-1/2">
      <div class="max-w-sm mx-auto">
        <header class="flex items-center justify-center gap-3 py-2 mb-12">
          <Prisma class="flex-shrink-0 w-auto h-9 text-cyma-purple"/>
          <Cymasuite class="w-auto h-6 text-cyma-purple"/>
        </header>
        <img
          src="@/assets/images/success.png"
          alt="Digitalización de documentos"
          class="w-10/12 mx-auto mt-16 mb-5 pointer-events-none select-none sm:mb-12"
          style=""
        >
        <div>
          <h3 class="mb-5 text-xl font-semibold text-center text-cyma-purple">
            Somos tu partner contable
          </h3>
          <p class="text-base text-center text-cyma-purple">
            Equipo de contadores dedicados a tu empresa, ofreciendo atención personalizada y datos siempre actualizados.
          </p>

        </div>
      </div>
    </div>
    <div class="flex items-center w-full p-4 pb-4 space-y-8 bg-white lg:w-1/2">
      <div class="w-full border px-6 py-10 mx-auto bg-white shadow-xl fade-in md:max-w-xl sm:px-10 sm:py-24 rounded-2xl">
        <h2 class="text-2xl sm:text-3xl font-yesevaOne text-cyma-purple select-none text-center">
          ¿No recibió instrucciones de confirmación?
        </h2>
        <p class="mt-5 mb-8 text-sm text-center text-cyma-purple">
          ¡No te preocupes! Para acceder a ellas, ingresa tu correo electrónico a continuación:
        </p>
        <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert" v-if="errors">
          <p class="font-bold">Ha ocurrido un problema </p>
          <p>{{errors}}</p>
        </div>
        <form class="mt-8 space-y-6"  @submit.prevent="resendConfirmation">
          <div class="space-y-10">
            <div class="relative focus-within:text-gray-900 dark:focus-within:text-gray-800">
              <div aria-hidden="true" class="absolute inset-y-0 flex items-center px-4 pointer-events-none">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#9c9c9c" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                </svg>
              </div>
              <input
                class="appearance-none auth block rounded w-full pl-11 pr-4 lg:pr-11 py-3 focus:outline-none focus:ring-1 focus:ring-cyma-purple border"
                id="email"
                v-model="email"
                type="text"
                placeholder="Dirección de correo electrónico"
                autocomplete="off"
                required
              />
            </div>
            <div class="flex justify-end flex-wrap gap-4">
              <button
                type="button"
                @click="goTo('login')"
                class="relative flex justify-center w-full px-4 py-2 text-sm font-semibold duration-300 transform border rounded-md sm:w-auto group border-gray text-cyma-light-purple hover:bg-white hover:text-cyma-purple active:scale-105 focus:outline-none hover:border-cyma-purple">
                Volver
              </button>
              <button
                type="submit"
                class="relative flex justify-center w-full px-4 py-2 overflow-hidden text-sm font-medium text-white transition-all duration-300 ease-out rounded-md sm:w-auto md:px-8 group bg-cyma-purple hover:bg-gradient-to-r hover:from-cyma-purple hover:to-cyma-light-purple hover:ring-2 ring-cyma-light-purple"
              >
                <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-80 ease"></span>
                <span class="relative">Enviar instrucciones</span>
              </button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import routerMixin from '@/mixins/routerMixin';
import Prisma from "@/components/Icons/Prisma.vue"
import Cymasuite from "@/components/Icons/Cymasuite.vue"

export default {
  name: "sendEmail",
  mixins: [routerMixin],
  components: {
    Prisma,
    Cymasuite
  },
  data() {
    return {
      email: null,
      errors: null
    };
  },
  methods: {
    resendConfirmation() {
      this.axios
        .post("/auth/confirmation", {
          email: this.email
        })
        .then(() => {
          this.$swal.fire(
            "Enviado",
            "Se ha enviado un correo electrónico con las instrucciones",
            "success"
          );
          this.goTo("login");
        })
        .catch(e => {
          this.errors = e.errors;
        });
    },
  },
};
</script>
<style scoped>
.auth::placeholder {
  /* font-family: Aileron; */
  text-transform: unset;
  font-variant: normal;
}
.auth:focus {
  border: 1px solid #f4f4f4;
}
</style>
