<template>
  <div>
    <transition name="modal">
      <div class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="dark:bg-background-dark bg-white w-screen h-screen md:h-auto overflow-auto py-5 px-4 md:w-full md:max-h-screen md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  v-if="this.action === 'create'"
                  class="font-bold py-8 lg:text-4xl text-2xl dark:text-white"
                >
                  Crear nuevo ingreso
                </h1>
                <h1
                  v-else
                  class="font-bold py-8 lg:text-4xl text-2xl dark:text-white"
                >
                  Actualizar ingreso
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="$emit('close')">
                  &times;
                </a>
              </div>
            </div>
            <div class="">
              <slot name="body">
                <form
                  class="grid gap-5 grid-cols-1 md:grid-cols-2 lg:grid-cols-3"
                  enctype="multipart/form-data"
                >
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Tipo</label
                    >
                    <div>
                      <v-select
                        data-cy="typeIncome"
                        :class="{'error': $v.salesObj.category_movement.$error, }"
                        class="style-chooser custom-border"
                        :options="types"
                        :selectable="type => ! type.name.includes('Venta')"
                        :reduce="(type) => type.value"
                        label="name"
                        :disabled="salesObj.category_movement === 0 "
                        v-model="$v.salesObj.category_movement.$model"
                        placeholder="Tipo"
                      ></v-select>
                      <div class="text_error" v-if="$v.salesObj.category_movement.$error">Este campo es requerido</div>
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Cliente</label
                    >
                    <div>
                      <v-select
                        data-cy="client"
                        :class="{'error': $v.salesObj.client_id.$error }"
                        class="style-chooser custom-border"
                        :options="clients"
                        :reduce="(client) => client.id"
                        label="name"
                        :disabled="salesObj.category_movement === 0"
                        v-model="$v.salesObj.client_id.$model"
                        placeholder="Cliente"
                      ></v-select>
                      <div class="text_error" v-if="$v.salesObj.client_id.$error">Este campo es requerido</div>
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >N° Documento</label
                    >
                    <input
                      data-cy="folio"
                      :class="{'error': $v.salesObj.folio.$error, }"
                      type="text"
                      placeholder="Folio"
                      class="input-text"
                      :disabled="salesObj.category_movement === 0 "
                      v-model="$v.salesObj.folio.$model"
                    />
                    <div class="text_error" v-if="$v.salesObj.folio.$error">Este campo es requerido</div>
                  </div>

                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Monto</label
                    >
                    <currency-input
                      data-cy="amount"
                      :class="{'error':$v.salesObj.total.$error, }"
                      currency="CLP"
                      :disabled="salesObj.category_movement === 0"
                      locale="es-CL"
                      class="input-text"
                      placeholder="Monto"
                      v-model="$v.salesObj.total.$model"
                    />
                    <div class="text_error" v-if="$v.salesObj.total.$error">Este campo es requerido</div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Fecha</label
                    >
                    <date-picker
                      data-cy="date"
                      :class="[{ 'error':$v.salesObj.period.$error }]"
                      type="date"
                      format="DD-MM-YYYY"
                      input-class="xmx-input"
                      :disabled="salesObj.category_movement === 0"
                      :style="{
                        width: '100%',
                        'border-radius': '2px',
                        'padding-left': '0.3rem',
                        'padding-right': '0.3rem',
                        'padding-top': '0.3rem',
                        'padding-bottom': '0.3rem',
                        'border-radius': '0.5rem'
                      }"
                      v-model="$v.salesObj.period.$model"
                      placeholder="Selecciona una Fecha"
                    ></date-picker>
                    <div class="text_error" v-if="$v.salesObj.period.$error">Este campo es requerido</div>
                  </div>
                  <div class="flex flex-col">
                    <div class="flex gap-2">
                      <label
                        class="label-cymasuite"
                        >Fecha de Caducidad
                      </label>
                      <ToolTip additionalClass="sm:w-64 lg:w-24 w-52 bg-gray-muted">
                        <template #icon>
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                          </svg>
                        </template>
                        <p class="text-xs text-">
                          Si es vacio, tomará el valor de caducidad del cliente.
                        </p>
                      </ToolTip>
                    </div>
                    <date-picker
                      data-cy="dateCaducity"
                      input-class="xmx-input"
                      :style="{
                        width: '100%',
                        'padding-left': '0.55rem',
                        'padding-right': '0.55rem',
                        'padding-top': '0.80rem',
                        'padding-bottom': '0.80rem',
                        'border-radius': '0.5rem'
                      }"
                      type="date"
                      format='DD-MM-YYYY'
                      v-model="salesObj.estimated_payment"
                      placeholder="Fecha"
                    ></date-picker>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Descripción</label
                    >
                    <input
                      data-cy="description"
                      type="text"
                      class="input-text"
                      placeholder="Descripción"
                      v-model="salesObj.description"
                    />
                  </div>

                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Adjuntar</label
                    >
                    <input
                      type="file"
                      id="files"
                      class="appearance-none block w-full bg-grey-lighter text-grey-darker dark:text-white"
                      ref="inputFile"
                      @input.prevent="uploadFile()"
                    />
                    <br />
                    <div v-if="!!url">
                      <a :href="url" target="blank">
                        {{ filename }}
                      </a>
                    </div>
                  </div>
                  <div class="col-span-full overflow-y-auto">
                    <addAccountMovement
                      :action="action"
                      :dataAcc="dataAcc"
                      :typeMovement="'sale'"
                      :totalAmount="salesObj.total"
                      :neto="salesObj.neto"
                      :ids="ids"
                      @addAttribute="addAttribute"
                    ></addAccountMovement>
                  </div>
                </form>
              </slot>
            </div>

            <div class="modal-footer flex justify-end py-3">
              <slot name="footer">
                <div class="">
                  <button
                    :disabled="submitValidation"
                    class="bg-black dark:bg-cian-dark dark:border-cian-dark rounded-full w-full hover:bg-white hover:text-black border hover:border-black text-white font-bold p-2 px-4"
                    @click.prevent="submit"
                  >
                    Guardar Información
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import ToolTip from '@/components/ToolTip.vue';
import DatePicker from 'vue2-datepicker'
import addAccountMovement from "./addAccountMovement";
import { required } from 'vuelidate/lib/validators'

export default {
  name: "newSales",
  props: {
    sale_id: { default: null },
    action: { default: null },
  },
  components: {
    addAccountMovement,
    DatePicker,
    ToolTip
  },
  data() {
    return {
      submitValidation: false,
      file: null,
      users: [],
      clients: [],
      accounts: [],
      dataAcc: [{
        movement_id: null,
        debit: 0,
        credit: 0,
        total_amount: 0,
        neto: 0,
        period: null,
        comentary: null,
        account_id: null,
        type_account_movement: "record",
        type_movement: "sale",
        state: "neto",
        account_name: null,
        branch_office_id: null,
        cost_center_id: null,
        percentage: 0,
        divide: null,
        min: 0,
        max: 0,
        date_sii: null,
        company_id: this.$store.state.company_id,
        disable: false
      }],
      inputPicture: "",
      coins: [
        { value: 0, name: "PEN" },
        { value: 1, name: "CHL" },
      ],
      types: [
        { value: 0, name: "Venta SII" },
        { value: 8, name: "Ventas con facturas" },
        { value: 7, name: "Ventas con boletas" },
        { value: 1, name: "Otros ingresos" },
      ],
      url: null,
      filename: null,
      submitStatus: null,
      ids: [],
      salesObj: {
        type_movement: "type_sale",
        category_movement: null,
        coin: null,
        total: null,
        period: '',
        responsable: null,
        folio: null,
        account_neto_id: null,
        company_id: null,
        client_id: null,
        user_id: null,
        neto: null,
        description: null,
        movement_status: null,
        estimated_payment: null,
        account_movements_attributes: [],
      },
    };
  },
  mounted() {
    this.getSale();
    this.getUsers();
    this.getClients();
    this.getFile();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
    salesObj:{
      client_id:{
        required
      },
      period:{
        required
      },
      category_movement: {
        required
      },
      folio:{
        required,
      },
      total:{
        required,
      }
    }
  },
  watch: {
    'salesObj': {
        handler: function (val) {
          if(this.action === 'create') {
            if (this.dataAcc.length === 1) {
              this.salesObj.neto = val.total;
              this.salesObj.date_sii = val.period;
              this.dataAcc[0].total_amount = val.total;
              this.dataAcc[0].max = val.total;
              this.salesObj.account_movements_attributes = this.dataAcc;
            }
          }
        },
        deep: true
    },
  },
  methods: {
    validateCategoryMovement(category) {
      switch (category) {
        case "sales_sii":
          return 0;
        case "invoice_sales":
          return 8;
        case "ticket_sales":
          return 7;
        case "other_sales":
          return 1;
      }
    },
    addAttribute(e) {
      this.salesObj.account_movements_attributes = e;
      this.dataAcc = e;
    },
    assingValue(event) {
      this.salesObj.movement_status = event.target.value;
    },
    getFile() {
      if (this.sale_id) {
        this.axios.get('movements/' + this.sale_id + '/file_movement').then((e) => {
          this.url = e.data.url;
          this.filename = e.data.name;
        });
      }
    },
    getUsers() {
      this.axios
        .get("users", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.users = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getClients() {
      this.axios
        .get("all-clients", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.clients = response.data;
        });
    },
    getSale() {
      if (this.sale_id) {
        this.axios.get("movements/" + this.sale_id).then((res) => {
          this.salesObj.category_movement = this.validateCategoryMovement(res.data.category_movement);
          this.salesObj.coin = res.data.coin === "pen" ? 0 : 1;
          this.salesObj.total = Number(res.data.total);
          this.salesObj.neto = Number(res.data.neto);
          this.salesObj.period = new Date(res.data.period);
          let estimatedPayment = new Date(res.data.estimated_payment)
          estimatedPayment.setMinutes(estimatedPayment.getMinutes() + estimatedPayment.getTimezoneOffset())
          this.salesObj.estimated_payment = estimatedPayment
          this.salesObj.responsable = res.data.responsable;
          this.salesObj.folio = res.data.folio;
          this.salesObj.company_id = res.data.company_id;
          this.salesObj.client_id = res.data.client_id;
          this.salesObj.user_id = res.data.user_id;
          this.salesObj.account_neto_id = res.data.account_neto_id;
          this.salesObj.movement_status = res.data.movement_status;
          this.salesObj.description = res.data.description;
          this.setMaxMin(res.data.account_movements_only_neto);
        });
      }
    },
    setMaxMin(account_movements){
      let a = account_movements.map((element, index) =>{
        if(this.action === 'create' || element.state === 'neto' || element.state === 'exempt_amount' || element.state === 'gross' ){
          let porcentaje = (element.total_amount / this.salesObj.neto) * 100;
          element.percentage = Math.round(porcentaje, 2);
          element.min = 0;
          element.max = Math.floor(this.salesObj.neto * element.percentage)/100;
          this.ids.push(index)
          element.disable = false;
          return element
        }
        return element
      })
      this.dataAcc = a
    },
    uploadFile(){
      this.file = this.$refs.inputFile.files[0]
    },
    updateFile(id) {
      let data = new FormData();
      data.append("file", this.file)
      data.append("id", id)
      this.axios
        .patch("/update_file", data)
        .then(()=> console.log("sucess"))
        .catch((e) => console.log(e))
    },
    create(data) {
      this.submitValidation = true;
      this.axios
        .post("/movements", data)
        .then((obj) => {
          this.$swal.fire(
              "Creado",
            "Tu ingreso ha sido creado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file")
          this.$emit("getSales");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    update(data, id) {
      this.submitValidation = true;
      this.axios
        .patch('movements/' + id, data)
        .then((obj) => {
          this.$swal.fire(
            "Actualizado",
            "Tu ingreso ha sido actualizado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file")
          this.$emit("getSales");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit() {
      let totalPorcentaje = 0;
      this.salesObj.company_id = this.$store.state.company_id;
      if (this.salesObj.account_movements_attributes.length <= 0) {
        this.salesObj.account_movements_attributes = this.dataAcc;
      }
      this.salesObj.account_movements_attributes.forEach((e) => (totalPorcentaje += e.percentage));
      if (totalPorcentaje < 100 || this.$v.salesObj.$invalid) {
        throw this.$swal.fire("Oops...", "Algo ha salido mal", "error");
      }
      this.action == "create" ? this.create(this.salesObj) : this.update(this.salesObj, this.sale_id);
    },
  },
};
</script>

<style>
textarea:focus,
input:focus {
  outline: none;
}
</style>
