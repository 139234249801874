<template>
  <div
    class="flex items-center p-4 bg-white border dark:bg-black-dark border-cyma-gray dark:border-black-dark rounded-lg"
    :class="colors[color].bg"
  >
    <div v-if="loading" class="flex items-center justify-center w-full">
      <Spinner :color="colors[color].spinner" />
    </div>
    <div v-else class="flex flex-col">
      <div class="flex flex-row items-center">
        <div class="flex-shrink pr-4">
          <div class="p-2">
            <slot name="icon"></slot>
          </div>
        </div>
        <div class="flex-1">
          <h5 
          class="text-xs font-bold uppercase"
          :class="colors[color].text"
          >
            {{ title }}
          </h5>

          <h3 v-if="text" class="font-bold xl:text-xl lg:text-base">
            {{ text }}
          </h3>

          <h3 v-if="number" class="font-bold xl:text-xl lg:text-base">
            {{ formatPrice(number) }}
          </h3>
          <p
            v-if="accumulated"
            class="text-xs font-medium text-gray-600"
          >
            Acumulado $ {{ formatPrice(accumulated || 0) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner";
export default {
  props: {
    loading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    title: {
      type: String,
      default() {
        return true;
      },
    },
    text: {
      type: String,
    },
    number: {
    },
    accumulated: {
    },
    color: {
      type: String,
      default() {
        return 'white';
      },
    },
  },
  name: "ResumeCard",
  components: {
    Spinner,
  },
  data() {
    return {
    };
  },
  computed:{
    colors(){
      return {
        red: {
          bg: 'bg-cyma-red dark:bg-red-dark',
          text: 'text-cyma-red-title',
          spinner: '#fab6b8'
        },
        white:{
          bg: 'bg-white border border-gray',
          text: '',
          spinner: '#d1d5db'
        },
        green:{
          bg: 'bg-cyma-green dark:bg-green-dark',
          text: 'text-cyma-green-title',
          spinner: '#a9d2be'
        }
      }
    }
  },
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
