<template>
  <button
    type="button"
    class="button-cymasuite"
    :disabled="disabled"
    @click.prevent="$emit('click')"
    :class="{
      'hover:bg-opacity-90': !disabled,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  name: "CymaButton",
}
</script>
