import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    company_id: null,
    dateRange: [null, null],
    booleanSlider: false,
    validatedOrder: false,
    validatedOrderUser: false,
    validatedOrderCurrentUser: false,
    marketplace: "https://clients.cymasuite.com",
    // marketplace: "http://localhost:3001",
    // Permissions
    role: null,
    permitted: true,

    refresh: false,
    // Buys vuex
    buy_pending_sii: null,
    buy_movement_state: null,
    buy_not_classified: null,
    buy_category_movement: null,
    buy_state_openfactura: null,
    buy_onlynotecredit: null,
    buy_account_state: null,
    buy_branch_office_state: null,
    buy_cost_center_state: null,
    buy_query: '',
    buy_doc_type: null,
    buy_date_type: 'date',

    // Sale vuex
    sale_movement_state: null,
    sale_category_movement: null,
    sale_onlynotecredit: null,
    sale_query: '',
    sale_cost_center: null,
    sale_branch_office: null,
    // balance
    exchange_rate: null,
    // Transactions
    transaction_query: '',
    transaction_bank_bill: null,
    transaction_movement_sales: true,
    transaction_movement_buys: true,
    transaction_reconsile: false,
    transaction_without_reconciling: false,
    transaction_only_recommended: false,
    transaction_only_archived: false,

  },
  plugins: [createPersistedState()],
  mutations: {
    addTransactionMovementSales(state, value) {
      state.transaction_movement_sales = value;
    },
    addTransactionMovementBuys(state, value) {
      state.transaction_movement_buys = value;
    },
    addTransactionReconsile(state, value) {
      state.transaction_reconsile = value;
    },
    addTransactionWithoutReconciling(state, value) {
      state.transaction_without_reconciling = value;
    },
    addTransactionOnlyRecommended(state, value) {
      state.transaction_only_recommended = value;
    },
    addTransactionOnlyArchived(state, value) {
      state.transaction_only_archived = value;
    },
    addTransactionQuery(state, value) {
      state.transaction_query = value;
    },
    addTransactionBankBill(state, value) {
      state.transaction_bank_bill = value;
    },
    addRefresh(state, value) {
      state.refresh = value;
    },
    addValidatedOrderUser(state, value) {
      state.validatedOrderUser = value;
    },
    addValidatedOrderCurrentUser(state, value) {
      state.validatedOrderCurrentUser = value;
    },
    addRole(state, role) {
      state.role = role;
    },
    addPermitted(state, permitted) {
      state.permitted = permitted;
    },
    // Buys Mutations
    buyDocTypeState(state, value) {
      state.buy_doc_type = value;
    },
    buyAccountState(state, value) {
      state.buy_account_state = value;
    },
    buyBranchOfficeState(state, value) {
      state.buy_branch_office_state = value;
    },
    buyCostCenterState(state, value) {
      state.buy_cost_center_state = value;
    },
    buyOnlyNoteCreditState(state, value) {
      state.buy_onlynotecredit = value;
    },
    buyStateOpenFacturaState(state, value) {
      state.buy_state_openfactura = value;
    },
    buyPendingSiiState(state, value) {
      state.buy_pending_sii = value;
    },
    buyDateType(state, value){
      state.buy_date_type = value;
    },
    buyMovementState(state, value) {
      state.buy_movement_state = value;
    },
    buyNotClassifiedState(state, value) {
      state.buy_not_classified = value;
    },
    buyCategoryMovementState(state, value) {
      state.buy_category_movement = value;
    },
    buyQuerySate(state, value) {
      state.buy_query = value;
    },
    // Sale Mutations
    saleMovementState(state, value) {
      state.sale_movement_state = value;
    },
    saleCategoryMovementState(state, value) {
      state.sale_category_movement = value;
    },
    saleBranchOfficeState(state, value) {
      state.sale_branch_office = value;
    },
    saleCostCenterState(state, value) {
      state.sale_cost_center = value;
    },
    saleOnlyNoteCreditState(state, value) {
      state.sale_onlynotecredit = value;
    },
    saleQuerySate(state, value) {
      state.sale_query = value;
    },
    addCompanyId(state, id) {
      state.company_id = id;
    },
    addDateRange(state, dates) {
      state.dateRange = dates;
    },
    addbooleanSlider(state, value) {
      state.booleanSlider = value;
    },
    addValidatedOrder(state, value) {
      state.validatedOrder = value;
    },
    addExchangeRate(state, value) {
      state.exchange_rate = value;
    },
  },
  getters: {
    pending_sii: (state) => {
      return state.pending_sii;
    },
    getDateRange(state) {
      let from = state.dateRange[0] == null ? null : new Date(state.dateRange[0])
      let to = state.dateRange[1] == null ? null : new Date(state.dateRange[1])
      return [from, to]
    }
  },
  actions: {},
});
export default store;
