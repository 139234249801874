<template>
  <!-- <VueRutInfo :disabled="action == 'update'" :rut="obj.rut" @updateRut="obj.rut = $event" @updateName="obj.name = $event"/> -->
  <div>
    <div class="relative">
      <input
        v-model="rut_s"
        name="rut_billing"
        ref="rut_billing"
        id="rut_billing"
        type="text"
        placeholder="12.345.678-0"
        autofocus
        maxlength="12"
        :disabled="disabled"
        class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4 dark:bg-black-dark dark:border-black-dark active:bg-gray-50 border-gray-300"
      />
      <Icon
        v-if="rut_loading"
        icon="svg-spinners:tadpole"
        class="animate-spin h-5 w-5 text-gray-600 absolute right-0 top-0 mt-3 mr-4"
      />
      <Icon
        v-else-if="!rut_error && rut_error != null"
        icon="heroicons:check-20-solid"
        class="w-5 h-5 absolute right-0 top-0 mt-3 mr-4 text-green-500"
      />
      <Icon
        v-else-if="rut_error"
        icon="heroicons:x-mark"
        class="w-5 h-5 absolute right-0 top-0 mt-3 mr-4 text-red-400"
      />
      <Icon
        v-else
        icon="heroicons:magnifying-glass"
        class="w-5 h-5 absolute right-0 top-0 mt-3 mr-4 text-gray-500"
      />
    </div>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  props: {
    rut: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Icon,
  },
  data() {
    return {
      rut_error: null,
      rut_loading: false,
      error: "",
      rut_s: "",
      data: "",
      timeoutId: null,
    };
  },
  mounted() {
    this.rut_s = this.rut;
  },
  watch: {
    rut_s() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.rut_s = this.cleanRut(this.rut_s);
        let len = this.rut_s.length - 1;
        if (len > 3) {
          let dv = this.rut_s[len];
          let beforeDv = this.rut_s
            .substring(0, len)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
          this.rut_s = beforeDv + "-" + dv;
        }
        if (len > 6) {
          this.getRutData();
        }
        this.$emit("updateRut", this.rut_s);
      }, 500);
    },
  },
  methods: {
    cleanRut(value) {
      if (value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
      }
    },
    getRutData() {
      if (this.disabled) {
        return;
      }
      this.$emit("updateRutVerified", false);
      this.rut_loading = true;
      this.axios
        .get("api/v1/rut_data", {
          params: {
            rut: this.rut_s,
          },
        })
        .then((response) => {
          this.rut_loading = false;
          if (response.data.error) {
            this.rut_error = true;
            this.$emit("updateName", "");
            return;
          } else {
            this.rut_error = false;
            this.data = response.data;
            this.$emit("updateRutVerified", true);
            this.$emit("updateName", response.data.razon_social);
          }
        });
    },
  },
};
</script>
