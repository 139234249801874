<template>
  <div class="relative flex h-screen overflow-hidden bg-white">
    <div class="items-center self-end justify-end hidden h-full lg:flex lg:w-1/2">
      <div class="max-w-sm mx-auto">
        <header class="flex items-center justify-center gap-3 py-2 mb-12">
          <Prisma class="flex-shrink-0 w-auto h-9 text-cyma-purple"/>
          <Cymasuite class="w-auto h-6 text-cyma-purple"/>
        </header>
        <img
          src="@/assets/images/success.png"
          alt="Digitalización de documentos"
          class="w-10/12 mx-auto mt-16 mb-5 pointer-events-none select-none sm:mb-12"
          style=""
        >
        <div>
          <h3 class="mb-5 text-xl font-semibold text-center text-cyma-purple">
            Somos tu partner contable
          </h3>
          <p class="text-base text-center text-cyma-purple">
            Equipo de contadores dedicados a tu empresa, ofreciendo atención personalizada y datos siempre actualizados.
          </p>

        </div>
      </div>
    </div>
    <div
      class="flex items-center w-full p-4 space-y-8 lg:w-1/2"
    >
      <form
        @submit.prevent="register()"
        class="w-full px-6 py-10 mx-auto bg-white shadow-xl fade-in md:max-w-xl sm:px-10 sm:py-14 rounded-2xl"
      >
        <h2
          class="text-2xl text-center select-none font-yesevaOne sm:text-3xl text-cyma-purple"
        >
          Bienvenido a Cymasuite
        </h2>
        <p class="mt-5 mb-8 text-sm text-center lg:text-base text-cyma-purple">
          Regístrate con tus datos y descubre cómo administrar tu negocio con
          Cymasuite. Comienza ahora y simplifica la gestión empresarial con
          nuestra plataforma líder.
        </p>
        <div
          class="p-4 text-red-700 bg-red-100 border-l-4 border-red-500"
          role="alert"
          v-if="errors || errorRegister"
        >
          <p class="font-bold">Ha ocurrido un problema</p>
          <p>{{ errors }}</p>
          <p v-if="errorRegister">
            Esta dirección de correo electrónico ya está siendo usada.
          </p>
        </div>
        <div class="space-y-6 fade-in lg:space-y-8">
          <div class="mb-6 -mx-3 md:flex">
            <div
              class="relative px-3 mb-8 md:w-1/2 md:mb-0 focus-within:text-gray-900 dark:focus-within:text-gray-800"
            >
              <div
                aria-hidden="true"
                class="absolute inset-y-0 flex items-center px-4 pointer-events-none"
              >
                <Icon icon="heroicons:user" class="w-5 h-5 text-gray-400" />
              </div>
              <input
                class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                id="name"
                v-model="name"
                autocomplete="off"
                type="text"
                placeholder="Nombre"
              />
            </div>

            <div
              class="relative px-3 md:w-1/2 focus-within:text-gray-900 dark:focus-within:text-gray-800"
            >
              <div
                aria-hidden="true"
                class="absolute inset-y-0 flex items-center px-4 pointer-events-none"
              >
                <Icon
                  icon="heroicons:chevron-right"
                  class="w-5 h-5 text-gray-400"
                />
              </div>
              <input
                class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                id="last-name"
                v-model="lastname"
                type="text"
                placeholder="Apellido"
              />
            </div>
          </div>
          <div class="space-y-6 lg:flex lg:items-center lg:space-y-0 lg:gap-6">
            <vue-tel-input
              v-model="phone"
              class="w-full borderc lg:w-1/2 custom-placeholder"
            ></vue-tel-input>

            <div class="relative w-full lg:w-1/2">
              <select
                class="block w-full px-4 py-3.5 leading-tight text-gray-300 bg-white border border-gray-200 rounded focus:outline-none focus:bg-white focus:border-cyma-purple"
                id="empresa"
                name="empresa"
                v-model="selected"
                placeholder="Tamaño de empresa"
                :class="selected === '' ? 'text-gray-400' : 'text-gray-700'"
              >
                <option class="text-gray-300" value="">
                  Tamaño de empresa
                </option>
                <option
                  :key="option.value"
                  class="text-gray-700"
                  v-for="option in plans"
                  :value="option.value"
                >
                  {{ option.title }}
                </option>
              </select>
            </div>
          </div>
          <div
            class="relative mb-6 -mx-3 lg:flex focus-within:text-gray-900 dark:focus-within:text-gray-800"
          >
            <div class="px-3 md:w-full">
              <div
                aria-hidden="true"
                class="absolute inset-y-0 flex items-center px-4 pointer-events-none"
              >
                <Icon icon="heroicons:envelope" class="w-5 h-5 text-gray-400" />
              </div>
              <input
                class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                id="email"
                v-model="email"
                type="text"
                autocomplete="off"
                placeholder="Correo electrónico"
              />
            </div>
          </div>
          <div
            class="relative mb-6 -mx-3 md:flex focus-within:text-gray-900 dark:focus-within:text-gray-800"
          >
            <div class="px-3 md:w-full">
              <div
                class="relative focus-within:text-gray-900 dark:focus-within:text-gray-800"
              >
                <div
                  aria-hidden="true"
                  class="absolute inset-y-0 flex items-center px-4 pointer-events-none"
                >
                  <Icon
                    icon="heroicons:lock-closed"
                    class="w-5 h-5 text-gray-400"
                  />
                </div>
                <input
                  class="block w-full py-3 pr-4 border rounded appearance-none auth pl-11 lg:pr-11 focus:outline-none focus:ring-1 focus:ring-cyma-purple"
                  id="password"
                  name="password"
                  v-model="password"
                  :type="!isshow ? 'text' : 'password'"
                  required
                  placeholder="Contraseña"
                />
                <div
                  class="absolute right-0 z-30 flex items-center px-4 inset-y-1"
                >
                  <button
                    type="button"
                    @click="isshow = !isshow"
                    class="z-30 focus:outline-none"
                  >
                    <Icon
                      :icon="isshow ? 'heroicons:eye' : 'heroicons:eye-slash'"
                      class="w-5 h-5 text-gray-400"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="mt-4 font-medium text-gray-400">
          Al crear tu cuenta estás aceptando
          <a
            href="https://www.cymasuite.com/terminos-y-condiciones"
            class="text-cyma-purple hover:text-indigo-500 hover:underline"
            target="_blank"
          >
            Nuestros términos y condiciones
          </a>
        </p>
        <div class="flex justify-end mt-8">
          <button
            type="button"
            @click="goTo('login')"
            class="relative flex justify-center w-full px-4 py-2 text-sm font-semibold duration-300 transform border rounded-md sm:w-auto group border-gray text-cyma-light-purple hover:bg-white hover:text-cyma-purple active:scale-105 focus:outline-none hover:border-cyma-purple"
          >
            Volver
          </button>
          <button
            type="submit"
            class="relative flex justify-center w-full px-4 py-2 ml-4 overflow-hidden text-sm font-medium text-white transition-all duration-300 ease-out rounded-md sm:w-auto md:px-8 group bg-cyma-purple hover:bg-gradient-to-r hover:from-cyma-purple hover:to-cyma-light-purple hover:ring-2 ring-cyma-light-purple"
          >
            <span
              class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-72 ease"
            ></span>
            <span>Registrar</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import routerMixin from "@/mixins/routerMixin"
import { Icon } from "@iconify/vue2"
import Prisma from "@/components/Icons/Prisma.vue"
import Cymasuite from "@/components/Icons/Cymasuite.vue"

export default {
  name: "Register",
  mixins: [routerMixin],
  components: {
    Icon,
    Prisma,
    Cymasuite,
  },
  data() {
    return {
      marketplace: this.$store.state.marketplace,
      email: "",
      phone: "",
      name: "",
      lastname: "",
      password: "",
      loading: false,
      errors: null,
      errorRegister: false,
      isshow: true,
      selected: "",
      plans: [
        {
          id: 1,
          title: "Micro Empresa",
          value: "entrepreneur",
        },
        {
          id: 2,
          title: "Pequeña",
          value: "small",
        },
        {
          id: 3,
          title: "Mediana",
          value: "medium",
        },

        {
          id: 5,
          title: "Gran Empresa",
          value: "big",
        },
        {
          id: 7,
          title: "Invitado",
          value: null,
        },
      ],
    }
  },
  created() {
    if (this.$route.query.plan != undefined) {
      this.selected = this.$route.query.plan
    }
  },
  watch: {},
  methods: {
    register() {
      this.$auth
        .register({
          data: {
            name: this.name,
            lastname: this.lastname,
            phone: this.phone,
            suggested_plan: this.selected,
            email: this.email,
            password: this.password,
            confirm_success_url: "/",
          },
        })
        .then(() => {
          this.$swal.fire(
            "Registrado",
            "Por favor, confirme su dirección de correo electrónico haciendo clic en el enlace de verificación que acabamos de enviar a su bandeja de entrada. Gracias.",
            "success"
          )
          this.goTo("login")
        })
        .catch((error) => {
          this.errorRegister = error.response.status == 400
          if (error.response.data.errors != undefined) {
            this.errors = error.response.data.errors.full_messages[0]
          }
        })
    },
  },
}
</script>
<style scoped>
form {
  border: 1px solid #d1cdcd;
}
.auth::placeholder {
  /* font-family: Aileron; */
  text-transform: unset;
  font-variant: normal;
}
.auth:focus {
  border: 1px solid #f4f4f4;
}

.borderc {
  border: 1px solid rgb(229, 231, 235);
  padding-top: 4px;
  padding-bottom: 4px;
}

.vue-tel-input:focus-within {
  border: 1px solid #4c1760;
  box-shadow: none;
}
.vue-tel-input,
select {
  height: 47px;
}

.custom-placeholder ::-webkit-input-placeholder {
  font-family: Aileron;
  text-transform: capitalize;
  font-variant: normal;
}
</style>
