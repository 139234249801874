<template>
  <div class="flex gap-8 py-10 px-4 lg:px-20 justify-center">
    <!-- <div class="flex min-h-full border-solid border-2 rounded-md py-8 px-8 w-3/6 dark:bg-black-dark dark:border-black-dark">
      <div class="flex flex-col grid w-full">
        <div class="flex flex-col items-center">
          <div class="text-2xl text-gray-600 font-bold border-b w-full pb-4 dark:text-white dark:border-black-dark">¿Qué módulos incluye?</div>
        </div>
        <div class="flex grid grid-cols-3 justify-between gap-2 pt-8" v-if="order && order['subscription']">
          <div v-for="(product, index) in order['subscription']['products']" :key="index">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#33d399" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
              </svg>
              <div class="text-sm text-gray-500 dark:text-white mb-1 w-11/12">{{ product.name }}</div>
            </div>
          </div>
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="#33d399" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
            </svg>
            <div class="text-sm text-gray-500 dark:text-white mb-1 w-11/12">Usuarios {{ order["subscription"].maximum_admin_users }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="flex min-h-full border-solid border-2 rounded-md py-8 px-4 sm:px-8 max-w-sm mx-auto w-full dark:bg-black-dark dark:border-black-dark shadow-lg border-gray-200">
      <div class="flex flex-col w-full">
        <div class="flex flex-col items-start">
          <div class="text-2xl  font-bold border-b border-gray-300 w-full pb-4 dark:text-white text-cyma-purple dark:border-black-dark text-center">Detalle de compra</div>
        </div>
        <div class="flex flex-col gap-4 py-8">
          <div class="flex justify-center w-full">
            <img class="h-5" src="@/assets/icons/WebPay.svg" alt="">
          </div>
          <div class="flex items-center justify-center  w-full">
            <p class="text-5xl">
              $ {{ formatNumber(order.total_amount) }}
            </p>
          </div>
          <div>
            <div class="flex justify-between text-base text-gray-500 mb-1 dark:text-white">
              <div class="flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-purple">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21" />
                </svg>
                Plan
              </div>
              <div>
                {{order.subscription.plan_name}}
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between text-base text-gray-500 mb-1 dark:text-white">
              <div class="flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-purple">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Comienzo Periodo
              </div>
              <div>
                {{order.subscription.beginning}}
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between text-base text-gray-500 mb-1 dark:text-white">
              <div class="flex gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6 text-cyma-purple">
                  <path fill-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75h4.5a.75.75 0 000-1.5h-3.75V6z" clip-rule="evenodd" />
                </svg>
                Final Periodo
              </div>
              <div class="ml-auto">
                {{order.subscription.expiration}}
              </div>
            </div>
          </div>
          <div>
            <div class="flex justify-between text-base text-gray-500 mb-1 dark:text-white">
              <div class="flex gap-2 font-bold">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-purple">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                Precio UF
              </div>
              <div>
                {{order.price_uf}}
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-center w-full ">
          <form method="post" :action="order.url">
            <input type="hidden" name="token_ws" :value="order.token_ws"/>
            <button
              class="w-full rounded-md py-3 px-10 text-sm font-bold text-white text-center bg-cyma-purple/95 hover:bg-cyma-purple transition-colors"
              style="display: flex; justify-content: center"
              type="submit"
            >
              Confirmar pago
            </button>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { formatNumber } from "@/utils/formatters";

  export default {
    name: "PaymentPlan",
    components: {
    },
    data() {
      return {
        order: {
          subscription: {
            plan_name: ''
          }
        },
        marketplace: this.$store.state.marketplace
      }
    },
    mounted() {
      this.getOrder()
    },
    methods: {
      formatNumber,
      getOrder(){
        const cur = this
        const userId = cur.$auth.user().id
        const accessToken = cur.$cookie.get("access-token")
        cur.axios
        .get(cur.marketplace + "/api/v1/orders/" + this.$route.params.id, {
          headers: {
            authorization: "Bearer " + accessToken
          },
          params: {
            user_id: userId
          }
        })
        .then((response) => {
          cur.order = response.data
        })
      }
    }
  };
</script>
