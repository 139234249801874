<template  >
  <div class="py-2 mx-6">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold dark:text-white">Impuestos y Retenciones</h1>
    </div>
    <div class="m-4">
      <newTax
        v-if="showModal"
        @close="showModal = false"
        @getTax="getTax"
        :tax_id="tax_id"
        :action="action"
      ></newTax>
    </div>
    <BeeGridTable
      border
      height="560"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :data="tax"
      :loading="loading"
    >
      <BeeColumn field="name" title="Descripción" type="text">
        <template slot-scope="{ row }">
          {{ row.name }}
        </template>
      </BeeColumn>

      <BeeColumn field="account_sale_id" title="Ventas" type="date">
        <template slot-scope="{ row }">
          {{ row.account_sale.name }}
        </template>
      </BeeColumn>

      <BeeColumn field="account_buy_id" title="Compras" type="date">
        <template slot-scope="{ row }">
          {{ row.account_buy.name }}
        </template>
      </BeeColumn>

      <BeeColumn field="editar" title="Opciones" type="text">
        <template slot-scope="{ row }">
          <div class="flex items-center">
            <a v-on:click="editFunction(row.id)" >
                <Edit />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import Edit from '@/components/Icons/Edit.vue';
import newTax from "./newTax";

export default {
  name: "tax",
  props: {},
  data() {
    return {
      tax_id: "",
      action: "create",
      showModal: false,
      tax: [],
      columns: [
        { title: "Nombre", key: "name", width: 250 },
        { title: "Nombre", key: "account_sale_id", width: 250 },
        { title: "Nombre", key: "account_buy_id", width: 250 },
        {
          title: "Editar",
          key: "editar",
          width: 10,
        },
      ],
      loading: true,
    };
  },
  components: {
    newTax,
    Edit,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  mounted() {
    this.user = this.$auth.user();
    this.getTax();
  },
  watch: {
    company_id() {
      this.getTax();
    },
  },
  methods: {
    editFunction(id) {
      this.showModal = true;
      this.tax_id = id;
      this.action = "update";
    },
    getTax() {
      this.loading = true;
      this.axios
        .get("/taxes", {
          params: {
            company_id: this.company_id
          },
        })
        .then((response) => {
          this.tax = response.data;
          this.loading = false;
        });
    },
  },
};
</script>
