<template>
  <div class="flex flex-col bg-comentary-gray dark:bg-black-dark py-4 px-4">
    <div class="flex justify-between">
      <div class="flex gap-2 items-center mb-4">
        <img class="w-6 h-6 rounded-full bg-gray-300" :src="comentary.user.avatar_url || defaultImage " :alt="comentary.user.name">
        <p class="text-sm font-bold text-gray-700 dark:text-white">
          {{ comentary.user.name }} {{ comentary.user.lastname }}
        </p>
        <p class="text-sm text-gray-400">
          {{ formatAbbreviatedDate(comentary.created_at || null) }}
        </p>
      </div>
      <!-- Dropdown -->
      <div class="inline-block relative dark:text-white" v-if="$auth.user().id == comentary.user_id">
        <svg @click.prevent="openOptions = !openOptions" xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
          <path stroke-linecap="round" stroke-linejoin="round"
            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" />
        </svg>

        <ul class="absolute right-0  text-gray-500 border rounded bg-white dark:text-white dark:bg-background-dark pt-1 dark:border-stroke-dark"
          :class="openOptions ? 'block' : 'hidden'">
          <li class="">
            <a @click.prevent="edit()" class="py-2 px-6 flex whitespace-no-wrap">Editar</a>
          </li>
          <li class="">
            <a @click="deleteComentary()" class="py-2 px-6 flex whitespace-no-wrap border-t dark:border-stroke-dark">Eliminar</a>
          </li>
        </ul>
      </div>

    </div>
    <textarea
      v-model="comentary.body"
      class="w-full resize-none text-sm bg-comentary-gray dark:bg-black-dark scroll-mod"
      :class="readonly ? 'text-gray-500 dark:text-gray-400 cursor-default' : ' text-black dark:text-white'"
      :readonly="readonly"
      cols="30" rows="4"
      ref="comentary"
      />
    <!-- <div  class="flex gap-2">
      <svg @click="edit()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
        stroke="currentColor" class="w-5 h-5 cursor-pointer">
        <path stroke-linecap="round" stroke-linejoin="round"
          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
      </svg>
      <svg @click="deleteComentary()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
        stroke-width="1.5" stroke="currentColor" class="w-5 h-5 cursor-pointer text-cyma-red-title">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </div> -->
    <div
      v-if="!readonly"
      class="flex fade-in justify-end gap-2"
    >
      <button @click="update()" class="dark:text-white hover:bg-green-100 hover:bg-opacity-20 p-1.5 rounded-sm shadow-sm dark:shadow-sm-dark">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
          class="w-4 h-4 text-cyma-green-title">
          <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
        </svg>
      </button>
      <button @click="cancelEdit" class="dark:text-white hover:bg-red-100 hover:bg-opacity-20 p-1.5 rounded-sm shadow-sm dark:shadow-sm-dark">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
          class="w-4 h-4 text-cyma-red-title">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
            d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import { formatAbbreviatedDate } from '@/utils/formatters'

export default {
  props: {
    comentary: { default: null },
  },
  data() {
    return {
      readonly: true,
      openOptions: false,
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      originalComentary: ''
    }
  },
  methods: {
    formatAbbreviatedDate,
    edit() {
      this.originalComentary = this.comentary.body
      this.openOptions = false
      this.readonly = false
      this.$refs.comentary.focus() // focus on textarea
    },
    cancelEdit() {
      this.comentary.body = this.originalComentary
      this.readonly = true;
    },
    update() {
      this.axios.patch('comentaries/' + this.comentary.id, {
        body: this.comentary.body
      }).then((res) => {
        console.log(res)
        this.readonly = true
      }).catch((err) => {
        console.log(err)
      });
    },
    deleteComentary() {
      this.axios.delete("comentaries/" + this.comentary.id).then(() => {
        this.$emit('getComentaries');
      });
    }
  },
}
</script>
<style scoped>
.dropdown:hover .dropdown-menu {
  display: block;
}
</style>
