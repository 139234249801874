<template>
  <div>
    <div
      class="space-y-4 h-96 overflow-y-scroll dark:text-white rounded-lg border px-4"
    >
      <h2 class="font-bold">
        CONTRATO DE PRESTACIÓN DEL SERVICIO DE ACCESO A LA PLATAFORMA CYMASUITE
      </h2>

      <p>
        El presente contrato se celebra entre CYMASUITE TECHNOLOGIES SpA, rol
        único tributario número 77.484.531-3, representada legalmente por don
        Franco Palma Salinas, cédula de identidad número 18.334.188-3, ambos
        domiciliados en Archivaldo Morales 102, Villa San Francisco, de la
        comuna de San Fernando, en la Región del Libertador Bernardo O´Higgins,
        correo electrónico franco@cymasuite.com, en adelante “CYMASUITE” o
        “PROVEEDOR” y la persona natural o jurídica, cuyos datos de fueron
        proporcionados en el formulario de inscripción para acceder a la
        plataforma tecnológica objeto de este contrato, en adelante el
        “CLIENTE”.
      </p>

      <h3 class="font-bold">ANTECEDENTES</h3>
      <p>
        CYMASUITE TECHNOLOGIES SpA, es una persona jurídica dedicada al
        desarrollo de plataformas tecnológicas, arriendo, prestación de servicio
        de software, desarrollo de soluciones integrales en el ámbito de la
        contabilidad, así como también servicios de carácter informático.
      </p>

      <h3 class="font-bold">OBJETO</h3>
      <p>
        Constituye el objeto del presente contrato, establecer los términos y
        condiciones bajo los cuales CYMASUITE proporcionará al CLIENTE acceso a
        su plataforma de gestión contable, así como todos los servicios
        complementarios al uso de dicha plataforma por el CLIENTE, tales como
        alojamiento de datos, actualizaciones de la aplicación y servicio de
        soporte, con el fin de permitir al CLIENTE gestionar y registrar sus
        actividades contables, financieras y tributarias de acuerdo con las
        leyes y regulaciones aplicables.
      </p>

      <h3 class="font-bold">VIGENCIA DEL CONTRATO</h3>

      <p>
        3.1 El presente contrato comenzara a producir sus efectos desde el
        momento en que el CLIENTE suscriba su firma electrónica o acepte
        electrónicamente sus términos y condiciones. Mediante dicho acto, se
        considerará que el CLIENTE ha leído y comprendido el presente contrato y
        manifiesta de manera inequívoca su consentimiento para la plena adhesión
        a todas las disposiciones contenidas en el mismo, el cual ambas partes
        acuerdan tener como vinculante.
      </p>

      <p>
        3.2 La duración del presente contrato será de un período de un mes o un
        año, de acuerdo al plan seleccionado por el CLIENTE al momento de la
        suscripción, y se prorrogará automáticamente de forma mensual a partir
        de la fecha de vencimiento de cada período, a menos que el CLIENTE
        ejerza la facultad descrita en el apartado.
      </p>

      <p>
        3.3 El CLIENTE tiene la facultad de cancelar la presente suscripción,
        con una antelación de al menos treinta (30) días antes del inicio del
        nuevo periodo de facturación, que para estos efectos es el 28 de cada
        mes. Para ello, debe enviar una comunicación escrita y verificable a
        CYMASUITE, la cual deberá dirigirse a la dirección de correo electrónico
        contacto@cymasuite.com, con copia a hugo@cymasuite.com. En el asunto del
        correo, es fundamental especificar el nombre de la empresa seguido de
        las palabras "DAR DE BAJA EL SERVICIO". Se recomienda, siempre que sea
        posible, proporcionar de forma concisa las razones que motivan la
        cancelación del mismo. Asimismo, es dable indicar que no se considerara
        valida la cancelación del servicio realizada por un medio distinto al
        descrito anteriormente.
      </p>

      <p>
        3.4 En el supuesto de inobservancia del plazo de previamente indicado,
        CYMASUITE se reserva el derecho de emitir una factura correspondiente al
        mes completo subsiguiente, sin perjuicio de la utilización efectiva de
        la plataforma por parte del CLIENTE.
      </p>

      <p>
        3.5 En relación a los clientes que han suscrito un contrato que
        establece un período de permanencia mínima, como es el caso de aquellos
        que han optado por un plan anual, en el evento en que el CLIENTE decida
        poner fin a este contrato o suspender la utilización del Servicio por
        cualquier motivo, inclusive si el CLIENTE ha realizado pagos por
        adelantado por un período que exceda el tiempo durante el cual hizo uso
        del software, no se generará ningún derecho a indemnización,
        compensación o reembolso por parte del PROVEEDOR, a menos que exista un
        acuerdo o plan preestablecido entre las partes que expresamente autorice
        la restitución de fondos.
      </p>

      <p>
        3.6 Lo anterior, sin perjuicio del derecho de retracto, conforme a lo
        establecido en el artículo 3 bis de la ley 19.496. El cual deberá
        ejercerse dentro de los plazos legales establecidos, notificando
        adecuadamente a CYMASUITE a través del correo electrónico dirigido a
        contacto@cymasuite.com.
      </p>

      <p>
        3.7 Previo a la eventual cancelación de la suscripción, CYMASUITE se
        encuentra facultado para realizar una auditoría del estado de
        cumplimiento de las obligaciones de pago por parte del CLIENTE que haya
        solicitado la cancelación servicio. En virtud de este procedimiento, se
        establece como requisito esencial que el CLIENTE se halle al día en el
        cumplimiento de sus obligaciones de pago.
      </p>

      <h3 class="font-bold">PRECIO Y FORMA DE PAGO</h3>

      <p>
        4.1 Los diversos esquemas tarifarios se encuentran especificados en el
        sitio web de CYMASUITE, accesible a través de la dirección
        https://www.cymasuite.com/precios. Los clientes disponen de la
        alternativa de elegir entre el pago de una tarifa mensual o anual, con
        el propósito de hacer uso de la plataforma o software. El monto a pagar
        se determinará en función del plan seleccionado y se detallará en las
        facturas emitidas por CYMASUITE. Estas tarifas se expresarán en Unidades
        de Fomento (UF) más el correspondiente Impuesto al Valor Agregado (IVA).
      </p>

      <p>
        4.2 CYMASUITE, se reserva el derecho a ofrecer promociones, planes y
        tarifas especiales de manera personalizada a cada CLIENTE. Estas
        promociones no necesariamente serán divulgadas en nuestra página web. No
        obstante, el PROVEEDOR notificará al CLIENTE por escrito acerca de los
        detalles del plan adquirido, proporcionándole un desglose completo que
        incluya cualquier paquete o servicio adicional que el CLIENTE haya
        contratado.
      </p>

      <p>
        4.3 En atención lo dispuesto en las campañas comerciales promovidas por
        CYMASUITE, se establece que las promociones y descuentos anunciados
        tendrán validez exclusivamente para clientes que no hayan mantenido
        previamente una relación comercial con CYMASUITE, aplicándose dichos
        beneficios únicamente a nuevas contrataciones realizadas en el marco de
        las referidas campañas comerciales.
      </p>

      <p>
        4.4 El PROVEEDOR se encuentra facultad para alterar las tarifas del
        servicio y las características propias de cada plan. Tales
        modificaciones serán notificadas al cliente mediante comunicación
        escrita enviada por correo electrónico, con una antelación mínima de
        treinta (30) a la fecha de renovación de los planes. Si el CLIENTE no
        esta de acuerdo con los nuevos términos y precios de los planes, debe
        ejercer la facultad indicada en el apartado 3.3.
      </p>

      <p>
        4.5 En la plataforma, ciertos servicios, como la funcionalidad opcional
        del módulo de facturación, inventario y proyectos, así como otros que
        pudieran ser desarrollados en el futuro, podrán ser objeto de
        facturación individualizada. CYMASUITE determinará de forma discrecional
        los valores más apropiados para cada servicio de manera independiente.
        Cabe destacar que la facultad de fijar estos valores de manera
        individual no implica una obligación por parte del PROVEEDOR de mantener
        de manera continua la disponibilidad de dichos servicios.
      </p>

      <p>
        4.6 El ciclo de facturación del Plan adquirido inicia el día 28 del mes
        posterior al inicio del servicio. El cobro se efectuará de manera
        proporcional, teniendo en cuenta los días 28 de cada mes para calcular
        la tarifa mensual correspondiente.
      </p>

      <p>
        4.7 Se considerará que existe retardo en el pago si el CLIENTE no ha
        efectuado el pago de la suscripción hasta el sexto día del mes
        subsiguiente al periodo de facturación correspondiente. La mora en el
        pago de la factura emitida facultará a CYMASUITE para suspender o
        cancelar la suscripción del CLIENTE, procediendo a la desactivación del
        acceso del CLIENTE a la plataforma. Esta medida se llevará a cabo una
        vez transcurridos 20 días desde el inicio del período de retardo y
        previo aviso por parte de CYMASUITE, notificado al CLIENTE mediante
        correo electrónico.
      </p>

      <p>
        4.8 En caso de persistencia en el incumplimiento, mediando previamente
        al menos 3 gestiones de cobranza realizadas directamente por el
        PROVEEDOR, CYMASUITE se reserva la facultad de emprender las medidas
        extrajudiciales que considere necesarias con el fin de obtener el pago
        íntegro del monto adeudado.
      </p>

      <p>
        4.9 El cobro extrajudicial será llevado a cabo por la empresa de
        cobranza, COBRANZA ONLINE. La ley faculta a CYMASUITE a cobrar al
        CLIENTE los gastos que se generen a propósito de la cobranza
        extrajudicial, los que serán calculados según la siguiente escala.
      </p>

      <ul class="pl-5 list-disc">
        <li>9% respecto de deudas vencidas inferiores a 10 UF.</li>
        <li>6% por la parte que exceda entre 10 UF y 50 UF.</li>
        <li>3% por la parte que exceda sobre 50 UF.</li>
      </ul>

      <p>
        4.10 El medio de pago será a través de transferencia electrónica cuyos
        datos se entregaran oportunamente una vez firmado el presente contrato.
      </p>

      <h3 class="font-bold">5. AUTONOMIA DE LAS CLAUSULAS DEL CONTRATO</h3>

      <p>
        Si cualquier cláusula, disposición o condición del presente contrato
        fuere declarada ilegal, inválida, nula o ineficaz, a través de sentencia
        firme y ejecutoriada por un Tribunal de jurisdicción competente, dicha
        ilegalidad, invalidez, nulidad o ineficacia no afectará otra cláusula
        disposición o condición del presente contrato. Dejando de lado la
        cláusula, disposición o condición ilegal, inválida, nula o ineficaz el
        contrato continuará en plena vigencia y efecto como si tal cláusula,
        disposición o condición ilegal, inválida, nula o ineficaz no hubiere
        nunca formado parte del mismo contrato.
      </p>

      <h3 class="font-bold">6. PROPIEDAD INTELECTUAL</h3>

      <p>
        Las disposiciones de este contrato no se pueden interpretar en el
        sentido que éste otorga al CLIENTE, explícita o implícitamente, una
        licencia o cesión, ni cualquier tipo de transmisión, directa o
        indirectamente.
      </p>
      <p>
        La plataforma web de CYMASUITE, junto con su diseño, código fuente, las
        API’s, gráficos, textos, y otros elementos creativos, son de propiedad
        exclusiva de CYMASUITE.
      </p>

      <h3 class="font-bold">7. RESPONSABILIDAD</h3>

      <p>
        7.1 CYMASUITE, con el objeto de mejorar la experiencia del usuario
        realiza continuamente mejoras en términos de seguridad, sin embargo, aún
        bajo la observancia de estándares de seguridad profesionalmente
        aceptados, la plataforma y la información almacenada allí, puede ser
        objeto de ataques informáticos. En consecuencia, CYMASUITE no se hará
        responsable en ningún caso por los daños y perjuicios de cualquier tipo
        sean directos o indirectos, previstos o imprevistos que se puedan
        ocasionar como resultado de la naturaleza misma del servicio ofrecido
        por CYMASUITE como:
      </p>

      <ul class="pl-5 list-disc space-y-2">
        <li>
          Los perjuicios o deterioro que pueda experimentar la información
          suministrada a través de CYMASUITE debido a la acción de piratas
          informáticos.
        </li>
        <li>
          La presencia de, Virus, Troyanos, Spyware o cualquier otro tipo de
          Malware.
        </li>
        <li>El uso de la plataforma fuera de sus recomendaciones.</li>
        <li>
          Los perjuicios ocasionados al CLIENTE debido a las tareas de
          mantenimiento, propias de la naturaleza misma de los servicios
          ofrecidos por CYMASUITE. Sean mantenimientos programados o no.
        </li>
        <li>
          El funcionamiento deficiente de la plataforma a causa de la
          utilización de un equipo inadecuado o una conexión a internet de baja
          calidad.
        </li>
        <li>
          El PROVEEDOR no garantiza la exactitud, integridad ni actualidad de
          los datos. Los usuarios son responsables de verificar cualquier
          información crítica antes de tomar decisiones basadas en ella.
        </li>
        <li>
          De las sanciones y los intereses resultantes del incumplimiento por
          parte del CLIENTE de sus obligaciones tributarias. El CLIENTE asume
          exclusivamente la responsabilidad del pago de los impuestos legales
          propios de su empresa.
        </li>
        <li>
          Las sanciones derivadas del incumplimiento de las obligaciones
          individuales de cada CLIENTE, relacionadas con la correcta gestión de
          documentos laborales o tributarios, a los que la ley ordena al
          gestionar electrónicamente, como por ejemplo la carga de contratos a
          la Dirección del Trabajo. A menos que el CLIENTE haya contratado
          específicamente ese servicio, el cual tendrá un costo adicional.
        </li>
        <li>
          Ante cualquier disfunción en las plataformas con las cuales CYMASUITE
          mantiene integración, como es el caso de Talana o Fintoc.
        </li>
        <li>
          Los daños ocasionados por un tercero al que el usuario le dio acceso a
          su cuenta.
        </li>
        <li>
          Los casos descritos anteriormente son de carácter descriptivo y no
          taxativo, en razón de ello es que se exime de toda responsabilidad a
          CYMASUITE por perjuicios ocasionados por causa no imputable a
          CYMASUITE, aun cuando no estén dentro de los casos mencionados
          primitivamente.
        </li>
      </ul>

      <h3 class="font-bold">8. TOTALIDAD DEL CONTRATO</h3>
      <p>
        Este Contrato constituye la totalidad del Acuerdo entre las Partes y
        sustituye cualquier otro acuerdo, contrato, declaración, manifestación,
        promesa, información, arreglo y entendimiento, ya sea verbal o escrito,
        expreso o implícito, entre las Partes en relación con el objeto de este
        Acuerdo de Términos de Servicio y el Servicio que dicho Acuerdo
        delimita.
      </p>
    </div>
    <form class="w-full" enctype="multipart/form-data">
      <!-- <div>
                  <iframe src="/Migration.pdf" frameborder="0" width="100%" height="600px"></iframe>
                </div> -->
      <div class="mt-8 flex flex-col gap-2 dark:text-white">
        <label for="service" class="flex justify-start items-center">
          <div
            class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 dark:bg-background-dark"
          >
            <input
              id="service"
              type="checkbox"
              class="opacity-0 absolute"
              v-model="checkServices"
            />
            <svg
              viewBox="0 0 20 20"
              class="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"></path>
            </svg>
          </div>
          <div>
            Acepto todos los términos del contrato de prestación del servicio de
            acceso a la plataforma Cymasuite
          </div>
        </label>
        <label for="terms" class="flex justify-start items-center">
          <div
            class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 dark:bg-background-dark"
          >
            <input
              id="terms"
              type="checkbox"
              class="opacity-0 absolute"
              v-model="checkTerms"
            />
            <svg
              viewBox="0 0 20 20"
              class="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
            >
              <path d="M0 11l2-2 5 5L18 3l2 2L7 18z"></path>
            </svg>
          </div>
          <div>
            Confirmo que he leído y acepto los
            <a
              class="font-bold underline"
              href="https://www.cymasuite.com/terminos-y-condiciones"
              >Términos y Condiciones</a
            >
          </div>
        </label>
        <div class="text-red-500 dark:text-red-400" v-if="errorMessage">
          {{ errorMessage }}
        </div>
      </div>
      <div
        class="modal-footer py-3 flex flex-wrap gap-3 lg:gap-x-8 justify-center items-center mt-5"
      >
        <button
          @click="$emit('cancel')"
          class="focus:outline-none hover:text-red-500 text-gray-400 dark:hover:text-gray-500"
        >
          Cancelar
        </button>
        <div v-if="submitValidation">
          <Spinner />
        </div>
        <button
          v-else
          type="submit"
          @click.prevent="submit"
          class="hover:bg-black dark:bg-cian-500 dark:hover:bg-cian-600 rounded-full w-full md:w-auto bg-white text-black dark:text-white border border-black hover:text-white font-bold p-2 px-5 duration-200 transition"
        >
          Sí, acepto.
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import Spinner from '@/components/Spinner2.vue';


export default defineComponent( {
  name: 'TermsAndConditions',
  components: { Spinner },
  data() {
    return {
      submitValidation: false,
      checkServices: false,
      checkTerms: false,
      errorMessage: ''
    };
  },
  methods: {
    submit() {
      this.submitValidation = true;
      if (!this.checkServices || !this.checkTerms) {
        this.errorMessage = 'Debes aceptar los términos y condiciones'
        setTimeout(() => {
          this.submitValidation = false;
        }, 1500);
        return;
      }
      this.errorMessage = ''
      this.axios
        .post("/accept-contract", {
          contract_service: this.checkServices,
          terms_and_conditions: this.checkTerms
        })
        .then(() => {
          console.log("enviado")
          this.$emit("accept-contract");
          this.$emit("updateNavbar")
        })
        .catch((error) => console.error({error}))
        .finally(() => {
          this.submitValidation = false;
        });
    }
  },
})

</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
