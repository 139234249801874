<template>
  <div class="py-2 mx-6">
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto mb-4 text-lg font-semibold md:text-3xl dark:text-white">Solicitudes</h1>
      <div class="flex gap-2">
        <button :disabled="!isAccountant" :class="{ 'opacity-50 cursor-not-allowed': !isAccountant}" @click.prevent="showModalRequest = true" class="flex items-center justify-center gap-2 p-2 bg-gray-200 rounded-full dark:bg-cian-dark dark:text-white">
          <span class="hidden sm:block">
            Agregar Solicitud
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </div>
    </div>
    <div class="flex flex-col flex-wrap gap-2 mb-6 sm:flex-row">
      <div class="filter">
        <input
          type="text"
          class="block w-full px-4 py-3 border appearance-none bg-grey-lighter text-grey-darker border-grey-lighter dark:bg-black-dark dark:border-black-dark"
          placeholder="Buscar solicitudes"
          @input="getAllRequests"
          v-model="query"
        />
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="status"
          :options="statusOptions"
          :reduce="(state) => state.value"
          label="name"
          @input="getAllRequests"
        ></v-select>
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="typeRequest"
          :options="typeRequestsOptions"
          :reduce="(state) => state.value"
          label="name"
          @input="getAllRequests"
        ></v-select>
      </div>

      <div class="filter">
        <date-picker
          input-class="xmx-input"
          :style="{
            width: '100%',
            height: '47px',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem',
          }"
          v-model="dateRange"
          type="date"
          format='DD-MM-YYYY'
          range
          placeholder="Seleccionar un rango de Fecha"
          v-on:input="getAllRequests"
        ></date-picker>
      </div>
    </div>

    <div>
      <BeeGridTable
      :serverSide="true"
      border
      :filterTotal="totalItems"
      :total="perPage"
      :page="currentPage"
      :pageSize="perPage"
      :pageCount="totalPages"
      :showSummary="false"
      :showFilter="false"
      :columns="columns"
      :data="data"
      :loading="loading"
      :fetchMethod="fetchMethod"
    >
      <BeeColumn field="name" title="Solicitud" type="text" :sortable="true" :width="320" :resizable="true"
      >
        <template slot-scope="{ row }">
          <a
            class="uppercase dark:text-cian-dark dark:hover:text-white"
            @click.prevent="
              goTo('Requests', {
                request_id: row.id,
              })
            "
          >
            {{ row.name }}
          </a>
        </template>
      </BeeColumn>

      <BeeColumn field="status" title="Estado" type="text" :width="130">
        <template slot-scope="{ row }">
          <span
            class="text-xs font-semibold px-2 py-0.5 rounded"
            :class="statusColor(row.status)"
          >
            {{ formatStatus(row.status) }}
          </span>
        </template>
      </BeeColumn>

      <BeeColumn field="type_request" title="Tipo de solicitud" type="text" :sortable="true" :width="160">
        <template slot-scope="{ row }">
          <div class="text-sm tracking-normal">
            {{ formatTypeRequest(row.type_request) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="description" title="Descripción" type="text" :sortable="true" :width="250" :resizable="true">
        <template slot-scope="{ row }">
          <div class="overflow-hidden text-sm tracking-normal whitespace-nowrap overflow-ellipsis">
            {{ (row.description) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="user" title="Solicitante" type="text" :sortable="true" :width="130" :resizable="true">
        <template slot-scope="{ row }">
          <div class="overflow-hidden text-sm tracking-normal whitespace-nowrap overflow-ellipsis">
            {{ (row.user.name)}} {{ (row.user.lastname)}}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="assigned_to" title="Asignado" type="text" :sortable="true" :width="130" :resizable="true">
        <template slot-scope="{ row }">
          <div v-if="row.assigned_to" class="overflow-hidden text-sm tracking-normal whitespace-nowrap overflow-ellipsis">
            {{ (row.assigned_to.name)}} {{ (row.assigned_to.lastname)}}
          </div>
          <div v-else class="text-sm tracking-normal">
            No asignado
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="created_at" title="Fecha de creación" type="text" :sortable="true" :width="250" :resizable="true">
        <template slot-scope="{ row }">
          <div class="text-sm tracking-normal">
            {{ formatCompleteDate(row.created_at) }}
          </div>
        </template>
      </BeeColumn>


    </BeeGridTable>
    </div>
    <ModalRequest
      v-if="showModalRequest"
      @getAllRequests="getAllRequests"
      @close="showModalRequest = false"
    ></ModalRequest>
  </div>
</template>

<script>
import { formatCompleteDate } from '@/utils/formatters';
import routerMixin from '@/mixins/routerMixin';
import ModalRequest from '@/components/forms/modalRequest.vue';
import DatePicker from "vue2-datepicker";

export default {
  name: 'AllRequests',
  props: {},
  mixins: [routerMixin],
  components: {
    DatePicker,
    ModalRequest
  },
  data() {
    return {
      columns: [],
      currentPage: 1,
      showModalRequest: false,
      data: [],
      filterTotal: 0,
      loading: true,
      page: 1,
      pageCount: 0,
      pageSize: 0,
      perPage: 10,
      total: 0,
      totalItems: 0,
      totalPages: 0,
      query: '',
      status: null,
      statusOptions: [
        { value: null, name: "Seleccionar estado" },
        { value: 0, name: "Pendiente" },
        { value: 1, name: "En progreso" },
        { value: 2, name: "Completado" },
      ],
      typeRequest: null,
      typeRequestsOptions: [
        { value: null, name: "Seleccionar tipo de solicitud" },
        { value: 0, name: "Contabilidad" },
        { value: 1, name: "Recursos humanos" },
      ],
      dateRange: [],
      isAccountant: false,
    }
  },
  created(){
    this.getPermissions()
  },
  mounted() {
    this.getAllRequests();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id() {
      this.getPermissions();
      this.getAllRequests();
    },
  },
  methods: {
    formatCompleteDate,
    getPermissions() {
      this.axios.get("/permissions",{
        params:{
          company_id: this.company_id
        }
      }).then((response) => {
        let roles = response.data.map(element => element.role)
        this.isAccountant = roles.includes('accountant')
      });
    },
    getAllRequests() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/requests", {
          params: {
            company_id: app.company_id,
            page: app.currentPage,
            per_page: app.perPage,
            query: app.query,
            status: app.status,
            type_request: app.typeRequest,
            from: this.dateRange[0],
            to: this.dateRange[1],
          }
        })
        .then((response) => {
          app.currentPage  = response.data.current_page;
          app.totalItems   = response.data.total_items;
          app.data         = response.data.data;
          app.perPage      = response.data.per_page;
          app.totalPages   = response.data.total_pages;
          app.loading      = false;
        })
    },
     // set dynamic bg-color badge
    statusColor(status) {
      return {
        'bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900': status === 'pending',
        'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900': status === 'in_progress',
        'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900': status === 'completed',
        'bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-900': status === 'rejected',
      }
    },
    formatStatus(state) {
      let status = {
        pending: "Pendiente",
        in_progress: "En progreso",
        completed: "Completado",
        rejected: "Rechazado",
      };
      return status[state];
    },
    formatTypeRequest(req) {
      let request = {
        accounting: "Contabilidad",
        rrhh: "Recursos humanos",
      };
      return request[req];
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      console.log(sorting);
      this.currentPage = offset;
      this.perPage = size;
      this.getAllRequests();
      // request api example
    },
  }


}

</script>
