<template>
  <div class="flex flex-col justify-between gap-4 py-2 mx-4 sm:mx-6 lg:flex-row">
    <div class="w-full bg-white rounded-lg shadow-xl dark:border-black-dark lg:mx-0 lg:w-2/5 xl:w-2/6 dark:bg-black-dark">
      <div class="w-full p-4 space-y-6 md:p-6 lg:px-3 xl:px-6">
        <div class="w-full">
          <div class="flex justify-between mb-5">
            <h2 class="self-end text-2xl font-bold text-black dark:text-white">
              Suscripción
            </h2>
            <div class="relative flex p-2 pr-6 mr-4 font-bold bg-gray-100 border-2 border-gray-400 rounded-lg dark:bg-background-dark dark:border-gray-700">
              <span class="w-9/12 text-base leading-none text-center text-gray-500 dark:text-gray-100">
                Plan {{ order.subscription.plan_name}}
              </span>
              <span class="absolute right-0 px-3 py-1 -mr-6 text-xl text-white transform translate-y-0 bg-cyma-purple rounded-xl dark:bg-cian-500">
                {{order?.subscription?.total_amount || 0}}
              </span>
            </div>
          </div>
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm" role="presentation">
              <tbody class="w-full overflow-x-auto">
                <tr class="w-full bg-gray-100 border-t border-b dark:bg-black-dark">
                  <td class="w-1/2 px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Fecha Inicio
                  </td>
                  <td v-if="order.subscription" class="w-1/2 px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 w-full h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatSimpleDate(order.subscription.beginning) }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Fecha termino
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 w-full h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatSimpleDate(order.subscription.expiration) }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Método de pago
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ formatMethodPay(order.method_pay) }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Estado
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ formatStatus(order.status) }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Usuarios permitidos admin
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatNumber(order.subscription.maximum_admin_users) }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Usuarios permitidos app
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatNumber(order.subscription.maximum_mobile_users) }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Número de empresas
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatNumber(order.subscription.maximum_companies) }}
                    </div>
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Cuentas bancarias
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    <div v-if="loadingSubscription" class="bg-gray-100 h-5 animate-pulse"></div>
                    <div v-else>
                      {{ formatNumber(order.subscription.maximum_bank_accounts) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-end py-5">
            <div>
              <span  class="block p-3 font-semibold rounded-lg tex bg-cyma-pink">
                Total Deuda $ {{ formatNumber(to_paid) }}
              </span>
              <small class="dark:text-gray-200">*Revisa tus órdenes de pago pendientes</small>
            </div>
          </div>
        </div>

        <!-- DATOS DE FACTURACIÓN  -->
        <div class="w-full">
          <h2 class="mb-5 text-2xl font-bold text-black dark:text-white">
            Datos Facturación
          </h2>
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm" role="presentation">
              <tbody>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Rut
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.rut_s }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Giro
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.giro }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Dirección
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.address }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Fecha de emisión
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.beginning }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Fecha de Vencimiento
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.expiration }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Correo
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.emails }}
                  </td>
                </tr>
                <tr class="bg-gray-100 border-b dark:bg-black-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Teléfono
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.contact }}
                  </td>
                </tr>
                <tr class="bg-white border-b dark:bg-background-dark">
                  <td class="px-6 py-4 font-semibold text-gray-900 border-r dark:text-white whitespace-nowrap">
                    Nombre de contacto
                  </td>
                  <td class="px-6 py-4 font-semibold text-gray-900 dark:text-white whitespace-nowrap">
                    {{ order?.subscription?.razon_social }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="w-full px-4 pt-2.5 pb-4 rounded-lg space bg-cyma-pink ">
          <h2 class="mb-4 text-2xl font-semibold">Métodos de pago</h2>
          <div class="flex flex-col-reverse sm:flex-row max-w-md lg:max-w-sm gap-3 md:gap-6 lg:gap-3 sm:justify-between mx-auto">
            <div class="w-full lg:w-5/12 xl:w-1/2 pt-3 border-t border-gray-500 sm:border-transparent sm:pt-0">
              <p class="mb-3 font-semibold text-center">Pago online</p>
              <div class="w-8/12 sm:w-full max-w-sm mx-auto">
                <img src="@/assets/images/logos-transbank.png" alt="transferencia bancaria" class="object-cover">
              </div>
            </div>
            <div class="w-full lg:w-7/12 xl:w-1/2 border-transparent md:border-solid lg:border-transparent md:border-gray-500 md:pl-4 flex flex-col justify-center items-center" >
              <p class="mb-4 font-semibold lg:self-start">Transferencia Bancaria</p>
              <p class="text-xs font-semibold">
                CYMASUITE TECHNOLOGIES SpA <br>
                RUT 77.484.531-3 <br>
                Banco Santander - Cuenta Corriente <br>
                N° 84776220 <br>
                facturacion@cymasuite.com
              </p>
            </div>
          </div>
        </div>
        <!--  DATOS DE FACTURACIÓN -->

      </div>
    </div>
    <div class="w-full lg:w-3/5 xl:w-4/6 flex-grow px-6 dark:border-black-dark lg:mx-0">
      <div class="h-full overflow-x-auto">
        <div class="mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          Mis órdenes
        </div>
        <div class="flex flex-col justify-start lg:flex-row">
          <BeeGridTable
          :serverSide="true"
          border
          :showSummary="false"
          :data="listOfOrders"
          :showFilter="false"
          :loading="loading"
          :filterTotal="filterTotal"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :fetchMethod="fetchMethod"

          >
            <BeeColumn field="transaction_date" title="Fecha" type="text" :width="200">
              <template slot-scope="{ row }">
                {{ formatSimpleDate(row.transaction_date) }}
              </template>
            </BeeColumn>

            <BeeColumn field="plan_name" title="Descripción" type="text" :width="200">
              <template slot-scope="{ row }">
                {{ row.subscription.plan_name }}
              </template>
            </BeeColumn>

            <BeeColumn field="Mount" title="Monto" type="text" :width="200">
              <template slot-scope="{ row }">
                $ {{ formatNumber(row.total_amount) }}
              </template>
            </BeeColumn>

            <BeeColumn field="status" title="Estado" type="text" :width="200">
              <template slot-scope="{ row }">
                <div v-if="row.status == 'authorized' || row.status == 'expired'">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-cyma-green-title w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                  </svg>
                </div>
                <!-- <div v-else-if="row.status == 'expired'">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="text-cyma-green-title w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg>
                </div> -->
                <div v-else
                  @click.prevent="nexToPay(row.status, row.id)"
                  :class="row.status != 'initialized'? 'pointer-events-none': 'cursor-pointer bg-cyma-purple text-white'"
                  class="flex justify-center items-center rounded-full uppercase  px-2 py-1 text-xs font-bold mr-3 ">
                  {{ formatStatus(row.status) }}
                </div>
              </template>
            </BeeColumn>


            <BeeColumn field="file" title="Documento" type="text" :width="200">
              <template slot-scope="{ row }">
                <div class="flex justify-center items-center">
                  <svg v-if="row.status && false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-red-icon hover:text-red-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                  </svg>
                  <i>Proximamente</i>
                </div>
              </template>
            </BeeColumn>
          </BeeGridTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import routerMixin from "@/mixins/routerMixin";
import { formatNumber, formatSimpleDate } from "@/utils/formatters"

export default {
  name: "MyPlan",
  mixins: [routerMixin],
  data() {
    return {
      order: {
        subscription: {
          plan_name: '',
        }
      },
      listOfOrders: [],
      marketplace: this.$store.state.marketplace,
      loading: true,
      loadingSubscription: true,
      current_page: 1,
      per_page: 10,
      total_pages: 0,
      total_items: 0,
      filterTotal: 0,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      page: 0,
      to_paid: 0,
    }
  },
  computed: {
    validatedOrder() {
      return this.$store.state.validatedOrder
    },
  },
  components: {
  },
  created() {
    this.getAllOrders()
    this.getOrder()
  },
  methods: {
    formatNumber,
    formatSimpleDate,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      console.log(sortInfos, filterInfos, offset, size);
      // print sort column infomations、filter column infomations
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      // this.page = offset;
      if(sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function(value) {
          return value.field;
        });

        _sorting = sortInfos.map(function(value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      console.log(sorting);
      this.current_page = offset;
      this.per_page = size;
      this.getAllOrders();
      // request api example
    },
    nexToPay(state,id){
      if(state == 'initialized'){
        this.goTo("PaymentPlan", { id: id } )
      }
    },
    formatMethodPay(value) {
      let response = ""
      switch (value) {
        case "stripe":
          response = "Stripe"
          break
        case "webpay":
          response = "Webpay"
          break
        case "paypal":
          response = "PayPal"
          break
        case "manual_payment":
          response = "Pago manual"
          break
        case "other":
          response = "Otro"
          break
        default:
      }
      return response
    },
    formatStatus(value) {
      let response = ""
      switch (value) {
        case "initialized":
          response = "Pagar"
          break
        case "authorized":
          response = "Autorizado"
          break
        case "failed":
          response = "Fallido"
          break
        case "nullified":
          response = "Anulado"
          break
        case "partially_nullified":
          response = "Parcialmente anulado"
          break
        case "reversed":
          response = "Revertido"
          break
        case "disabled":
          response = "Próximos"
          break
        default:
      }
      return response
    },
    getOrder() {
      const cur = this
      cur.loadingSubscription = true
      const userId = cur.$auth.user().id
      const accessToken = cur.$cookie.get("access-token")
      this.axios
        .get(cur.marketplace + "/api/v1/client/access", {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            user_id: userId,
            status: 2,
          },
        })
        .then((response) => {
          cur.order = response.data
          cur.loadingSubscription = false
        })
    },
    orderPaid(){
      let initialValue = 0
      let listInitializedOrder = this.listOfOrders.filter(e => {
        return e.status == 'initialized'
      })
      console.log(listInitializedOrder, 'LIO')
      let total = listInitializedOrder.reduce((acc, v) => {
        console.log(parseInt(v.total_amount))
        return acc + parseInt(v.total_amount)
      }, initialValue)
      return total
    },
    getAllOrders() {
      const cur = this
      cur.loading = true
      const userId = cur.$auth.user().id
      const accessToken = cur.$cookie.get("access-token")
      this.axios
        .get(cur.marketplace + "/api/v1/orders", {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            per_page: this.per_page,
            page: this.current_page,
            user_id: userId,
            // status: 2,
            order: "asc",
          },
        })
        .then((response) => {
          cur.filterTotal = response.data.total_items; //total filtered data count
          cur.total = response.data.per_page; // total data count
          cur.page = response.data.current_page; // current page offset from server
          cur.current_page = response.data.current_page;
          cur.pageSize = response.data.per_page;
          cur.pageCount = response.data.total_pages;
          cur.total_items = response.data.total_items;
          cur.listOfOrders = response.data.data;
          cur.to_paid = response.data.to_paid;
          cur.loading = false
        })
    },
  },
}
</script>


<style>

</style>
