<template>
  <div>
    <Spinner v-if="isLoading" class="mx-auto" />
    <button
      v-else
      @click="handleClick"
      :disabled="isLoading"
      class="inline-block w-full px-5 py-3 font-bold tracking-wide text-center rounded"
      :class="variant"
    >
      <slot />
    </button>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue'

export default {
  components: {
    Spinner,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    // decidir entre 2 variantes
    variant() {
      return this.$attrs.variant === 'primary'
        ? 'bg-cyma-purple text-gray-50'
        : 'bg-gray-100 text-cyma-purple'
    },
  },

  methods: {
    handleClick() {
      this.isLoading = true
      this.$emit('click', () => {
        this.isLoading = false // Desactivamos el estado de carga en el componente del botón después de que se ejecute la función
      })
    },
  },
}
</script>
