<template>
  <div
    class="py-2 mx-6"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider
    }"
  >
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto text-xl font-semibold dark:text-white">
        {{ this.title }}
      </h1>
      <button
        @click.prevent="createButton"
        data-cy="button-new"
        class="button-cymasuite"
      >
        Agregar
        <Plus />
      </button>
    </div>
    <div class="m-2">
      <New
        v-if="showModal"
        @close="showModal = false"
        @getData="getData"
        :id="id"
        :action="action"
        :route="route"
      ></New>
    </div>
    <!-- Filter -->
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          data-cy="search"
          type="text"
          class="input-text"
          :placeholder="'Buscar ' + title"
          v-model="query"
          @input="onInput"
        />
      </div>
    </div>
    <BeeGridTable
      border
      height="560"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :data="data"
      :loading="loading"
    >
      <BeeColumn field="name" title="Nombre" type="text">
        <template slot-scope="{ row }">
          {{ row.name }}
        </template>
      </BeeColumn>

      <BeeColumn field="editar" title="Opciones" type="text">
        <template slot-scope="{ row, index }">
          <div class="flex gap-2">
            <button @click.prevent="editFunction(row.id)">
              <Edit />
            </button>
            <button
              :data-cy="'delete' + index"
              v-if="(title == 'Centro de Costos' || title == 'Sucursales') && !row.have_account_movements"
              @click.prevent="deleteFunction(row.id)"
            >
              <Times />
            </button>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>
<script>
import Plus from '@/components/Icons/Plus.vue';
import New from "./new";
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';

export default {
  name: "crud",
  props: {
    title: String,
    route: String
  },
  data() {
    return {
      loading: true,
      id: null,
      action: "create",
      showModal: false,
      data: [],
      columns: [
        { title: "Nombre", key: "name", width: 150 },
        // { title: "Icon", key: "icon", width: 150 },
      ],
      query: "",
      delayTimer: null,
    };
  },
  components: {
    New,
    Plus,
    Edit,
    Times,
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    company_id() {
      this.getData();
    },
  },
  methods: {
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    onInput() {
      clearTimeout(this.delayTimer); // Borrar el temporizador existente

      // Establecer un nuevo temporizador para llamar a getData después de 500ms
      this.delayTimer = setTimeout(() => {
        this.getData();
      }, 500);
    },
    getData() {
      this.loading = true;
      this.axios
        .get(this.route, {
          params: {
            from: this.dateRange[0],
            to: this.dateRange[1],
            company_id: this.company_id,
            query: this.query,
          },
        })
        .then((response) => {
          this.loading = false
          this.data = response.data;
          this.loading = false;
        });
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Se eliminara el Centro de Costo",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminar",
          cancelButtonText: "No, conservar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "El Centro de Costo ha sido eliminado",
              "success"
            );
            this.axios
              .delete(this.route + '/' + id, )
              .then(() => this.getData());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El Centro de Costo no ha sido eliminado",
              "error"
            );
          }
        });
    },
  },
};
</script>
