import Vue from 'vue';
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueAuth from "@websanova/vue-auth";
import auth from "./auth.js";
import router from "./router/index";
import vSelect from "vue-select";
import 'beegridtable/dist/styles/beegridtable.css';
import "vue2-datepicker/index.css";
import "./assets/stylesheets/datepicker.scss";
import "./assets/stylesheets/application.scss";
import "./assets/stylesheets/vselect-transparent.scss";
import "vue-select/dist/vue-select.css";
import Vuelidate from "vuelidate";
import BeeGridTable from "beegridtable";

import BeeEn from "./assets/javascripts/es-ES.js";
import store from './store'
import VueSweetalert2 from "vue-sweetalert2";
import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';

import Chart from 'vue2-frappe'
import VueCurrencyInput from "vue-currency-input";

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
// config sentry
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
// Config Vue GTM
import VueGtm from '@gtm-support/vue2-gtm';
// Calendly
import VueCalendly from 'vue-calendly';
// Prime Vue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'

import Dialog from 'primevue/dialog';

// Vue number Input
import VueNumberInput from '@chenfengyuan/vue-number-input';

// import VueRouter from "vue-router";
// const router2 = new VueRouter({ routes, mode, linkActiveClass });

// Check what is the active theme and change theme when user clicks on the theme button in header.
if (localStorage.theme === 'dark') {
  document.documentElement.classList.add('dark')
} else {
  document.documentElement.classList.remove('dark')
}

Vue.use(VueGtm, {
  id: "GTM-5PFSDR2", // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryPararms: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}]
  queryParams: {
    // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: "HKOrbeup0o79t11cwgpWFA",
    gtm_preview: "env-1",
    gtm_cookies_win: "x",
  },
  defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: true, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  // vueRouter: router2, // Pass the router instance to automatically sync with router (optional)
  ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});
if (process.env.VUE_APP_ENV === "production"){
  console.log("env", process.env.VUE_APP_ENV)
  Sentry.init({
    Vue,
    environment: process.env.VUE_APP_ENV,
    dsn: "https://6f04e67ef15c46f8b07c289296213416@o891111.ingest.sentry.io/5839793",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}
Vue.use(Chart)
Vue.use(VueCurrencyInput);
Vue.use(VueTelInput, {
  defaultCountry: 'cl',
  mode: 'international',
  onlyCountries: ['cl'],
  inputOptions: {
    placeholder: 'Introduzca N° de teléfono',
    showDialCode: true,
  },

});

Vue.use(Vuelidate);
// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueCalendly);
Vue.use(VueSweetalert2);
Vue.use(VueIziToast);
var VueCookie = require("vue-cookie");
// Prime
Vue.use(PrimeVue);
Vue.component('Dialog', Dialog);


Vue.use(BeeGridTable, {
  capture: true,
  transfer: true,
  zIndex: 2000,   //set z-index of beegridtable
});

import VueI18n from "vue-i18n";
Vue.use(VueI18n);
Vue.locale = () => {}; //necessary

import us from "beegridtable/src/locale/lang/en-US";

const messages = {
  en: Object.assign(us, BeeEn),
};

const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "en",
  messages: messages,
});

Vue.config.productionTip = false;

Vue.use(VueCookie);

Vue.config.productionTip = false;
// axios.defaults.baseURL = "http://localhost:3000/";
const instance = axios.create({
  baseURL: "https://api.cymasuite.com/",
  // baseURL: "http://localhost:3000/",
  params: {}, // do not remove this, its added to add params later in the config
});
  Vue.component('v-number-input', VueNumberInput);
  Vue.component("v-select", vSelect);
  Vue.use(VueAxios, instance);
  Vue.router = router;
  App.router = Vue.router;
Vue.use(VueAxios, axios);
Vue.use(VueAuth, auth);

new Vue({
  render: (h) => h(App),
  router,
  axios,
  store,
  i18n,
}).$mount("#app");

