<template>
  <transition name="modal">
    <div class="modal-mask">
      <div
        class="h-screen w-screen flex justify-center items-center"
      >
        <div class="modal-container max-w-3xl md:w-1/2 sm:w-4/5 w-full sm:mx-0 mx-6 dark:bg-background-dark">
          <div class="flex items-center">
            <div class="flex-auto">
              <h1 class=" text-grey-darker py-8 lg:text-4xl text-2xl dark:text-white">
                Nuevo permiso
              </h1>
            </div>
            <div class="flex justify-end items-center dark:text-white">
              <a class="text-4xl dark:hover:text-cian-dark" @click.prevent="$emit('close')"> &times; </a>
            </div>
          </div>
          <div>
            <div>
              <form class="w-full">
                <div>
                  <div>
                    <label
                      class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
                      for="inline-full-name"
                    >
                      Correo electrónico
                    </label>
                    <input
                      class="input-text"
                      type="text"
                      placeholder="Ingrese Correo electrónico"
                      v-model="permission.email"
                    />
                  </div>
                  <div class="pt-4 modal-footer flex justify-end py-3">
                    <button
                      class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
                      type="button"
                      @click.prevent="add"
                    >
                      Guardar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import "@/assets/stylesheets/modal.css";
export default {
  name: "modalAddCompany",
  props: {
    company: { default: null },
  },
  data() {
    return {
      permission: {
        email: null,
      }
    };
  },
  mounted() {
  },
  methods: {
    add() {
      this.permission.sii_config_id = this.company.sii_config_id
      this.permission.company_id = this.company.id
      this.axios.post("permissions", this.permission)
        .then(() => {
          this.$emit("close")
          this.$emit("getData")
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>