<template>
  <div class="py-4 lg:py-5 mx-4 md:mx-6">
    <!-- {{ form }} -->
    <form class="w-full flex" enctype="multipart/form-data">

      <div class="flex flex-col p-5 rounded-lg w-full lg:w-1/2 m-auto shadow-sm">
        <div class="">
          <div id="avatar" class="w-48 h-48 m-auto my-4 shadow-xl rounded-full bg-cover bg-center" :style="{ 'background-image': `url('${this.user.avatar_url || this.defaultImage }')`}"></div>
          <input class="" accept="image/*" type="file" @change=uploadFile ref="inputFile">
        </div>
        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white">
            Nombre
          </label>
        </div>
        <div class="">
          <input
            class="input-text"
            type="text"
            v-model="user.name"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white" >
            Apellidos
          </label>
        </div>
        <div class="">
          <input
            class="input-text"
            type="text"
            v-model="user.lastname"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >RUT</label
          >
        </div>
        <div class="">
          <input
            type="text"
            v-model="user.rut"
            class="input-text"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >Correo electrónico</label
          >
        </div>
        <div class="">
          <input
            type="text"
            v-model="user.email"
            class="input-text"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >Fecha de nacimiento</label>
        </div>
        <date-picker
          type="date"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.80rem',
            'padding-bottom': '0.80rem',
            'border-radius': '0rem'
          }"
          format='DD-MM-YYYY'
          v-model="user.birth_date"
          input-class="xmx-input"
          placeholder="Selecciona una Fecha"></date-picker>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >Dirección</label>
        </div>
        <div class="">
          <input
            type="text"
            v-model="user.address"
            class="input-text"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >Celular</label>
        </div>
        <div class="">
          <input
            type="text"
            v-model="user.phone"
            class="input-text"
          />
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >Cargo</label>
        </div>
        <div class="">
          <input
            type="text"
            v-model="user.position"
            class="input-text"
          />
        </div>
        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >API KEY</label>
        </div>

        <div class="appearance-none w-full border rounded py-3 px-4 dark:border-black-dark dark:bg-black-dark dark:text-white flex justify-between gap-1">
          <input
            :type="show ? 'text' : 'password'"
            v-model="user.api_key"
            class="flex-1 dark:bg-black-dark dark:text-white"
          />
          <div class="cursor-pointer" @click="show=!show">
            <svg v-if="show" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
        </div>
        <!-- <h2 class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mt-8">Integracion Fintoc</h2>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
          >Public key</label>
        </div>

        <div class="relative">
          <input
            :type="show ? 'text' : 'password'"
            v-model="user.fintoc_public_key"
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
          />
          <div class="eyeButton">
            <i class="fas fa-eye"
              @mousedown="show = !show"
              @mouseup="show = !show"
              @touchstart="show = !show"
              @touchend="show = !show"
              style="cursor: pointer"
              ></i>
          </div>
        </div>

        <div class="mt-4">
          <label class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
          >Secret key</label>
        </div>
        <div class="relative">
          <input
            :type="showSecret ? 'text' : 'password'"
            v-model="user.fintoc_secret_key"
            class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
          />
          <div class="eyeButton">
            <i class="fas fa-eye"

              @mouseup="showSecret = !showSecret"
              style="cursor: pointer"
              ></i>
          </div>
        </div> -->



        <div class="mt-4">
          <button
            class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
            type="button"
            @click.prevent="update(user)"
          >
            Enviar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
  import routerMixin from "@/mixins/routerMixin";
  import DatePicker from "vue2-datepicker";
  export default {
    name: "editUser",
    props: {},
    mixins: [routerMixin],
    data() {
      return {
        showSecret: false,
        show: false,
        previewImage:null,
        user: { avatar: null },
        defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      };
    },
    components: {
      DatePicker,
    },
    mounted() {
      this.user = this.$auth.user();
      this.user.birth_date = this.user.birth_date ? new Date(this.user.birth_date) : null;
    },
    methods: {
      uploadFile(e) {
        console.log("inputfile", this.$refs.inputFile)
        this.user.avatar = this.$refs.inputFile.files[0];
        const file = e.target.files[0]
        // this.defaultImage = window.URL.createObjectURL(file)
        this.defaultImage = URL.createObjectURL(file)
      },
      updateImage(id, img) {
        let data = new FormData();
        data.append("image", img);
        data.append("id", id);
        this.axios
          .patch("user/update_image", data)
          .then(()=> console.log("sucess"))
          .catch((e) => console.log(e))
      },
      update(params) {
        this.axios
          .patch("users/" + this.user.id, params)
          .then(() => {
            this.goTo("profile");
            this.updateImage(this.user.id, this.user.avatar)
            alert("Actualizado");
            this.$router.go()
          })
          .catch((e) => alert(e));
      },
      changeImage(e) {
        const file = e.target.files[0]
        // this.defaultImage = window.URL.createObjectURL(file)
        this.defaultImage = `url(' ${URL.createObjectURL(file)} ')`
      }
    },
  };
</script>
<style>
.eyeButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  top: 13px;
}
</style>
