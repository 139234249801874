<template>
  <div class="px-10 flex flex-col gap-10">
    <h1
      class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex lg:gap-4 gap-2"
    >
      {{ credit.name }}
    </h1>
    <div class="grid grid-cols-3 gap-4">
      <ResumeCard
        :loading="loading"
        :text="formatSimpleDate(credit.end_date)"
        title="Fecha Final"
        color="white"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-green-title"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
        </template>
      </ResumeCard>
      <ResumeCard :loading="loading" :text="credit.annual_interest_rate" title="Tasa Anual" color="white">
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-icon-gray"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </template>
      </ResumeCard>
      <ResumeCard
        :loading="loading"
        :number="credit.total_amount"
        title="Monto"
        color="white"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-icon-gray"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
            />
          </svg>
        </template>
      </ResumeCard>
      <ResumeCard
        :loading="loading"
        :text="formatSimpleDate(credit.next_payment)"
        title="Proximo Pago"
        color="white"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-red-title"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
            />
          </svg>
        </template>
      </ResumeCard>
      <ResumeCard
        :loading="loading"
        :text="credit.number_of_payments_paid + '/' +credit.number_of_payments"
        title="N° de Cuotas Pagadas"
        color="white"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-icon-gray"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
            />
          </svg>
        </template>
      </ResumeCard>
      <ResumeCard
        :loading="loading"
        :number="credit.total_amount - credit.fee_paid"
        title="Saldo Adeudado"
        color="white"
      >
        <template v-slot:icon>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 text-cyma-icon-gray"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
            />
          </svg>
        </template>
      </ResumeCard>
    </div>
    <BeeGridTable
      :serverSide="true"
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="movements"
      :loading="loading"
    >
    <BeeColumn
        field="business_name"
        title="Nombre"
        type="text"
        :sortable="true"
        :width="300"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ row.business_name }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="folio"
        title="Folio"
        type="date"
        :width="200"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ row.folio }}
          <div class="might-overflow text-sm tracking-normal text-gray-400">
            {{ formatDocType(row.doc_type) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="interest"
        title="Interes"
        type="text"
        :sortable="true"
        :width="180"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatNumber(row.interest) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="capital"
        title="Capital"
        type="text"
        :sortable="true"
        :width="180"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatNumber(row.capital) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="total"
        title="Total"
        type="text"
        :sortable="true"
        :width="180"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatNumber(row.total) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn
        field="period"
        title="Periodo"
        type="date"
        :width="200"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.period) }}
        </template>
      </BeeColumn>

      <BeeColumn
        field="expiration_date"
        title="Expiración"
        type="text"
        :sortable="true"
        :width="200"
        :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatSimpleDate(row.expiration_date) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="pagado" title="Pagado" type="text" :width="100" >
        <template slot-scope="{ row }">
          <div v-if="row.classified && !(row.credit_note_id)">
            <label class="switch label_switch">
              <input
                class="check"
                type="checkbox"
                :class="{ notPaid: row.movement_status === 'paid_subscriber' }"
                :checked="checkedFunction(row.movement_status)"
                @click.prevent="updatePay($event.target.checked, row.id)"
              />
              <span><slot></slot></span>
            </label>
          </div>
          <div v-else-if="!(row.credit_note_id)">S/C</div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="eliminar"
        title="Opciones"
        type="text"
        class="text-center"
        :width="120"
      >
        <template slot-scope="{ row }">
          <div class="flex gap-4">
            <a v-on:click="deleteFunction(row.id)" >
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
    <newMovement
      v-if="showMovement"
      @getBuys="reload"
      @close="closeFunction"
      :buy_id="buy_id"
    ></newMovement>
  </div>
</template>

<script>
import { formatDocType, formatNumber, formatSimpleDate } from "@/utils/formatters.js";
import ResumeCard from "@/components/ResumeCard";
import newMovement from "../buys/newMovement";
import "@/assets/stylesheets/switch.scss";
import Times from '@/components/Icons/Times.vue';

export default {
  name: "CreditShow",
  props: {},
  data() {
    return {
      credit: {},
      loading: true,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      nameSorting: null,
      sorting: null,
      current_page: 1,
      per_page: 8,
      showMovement: false,
      buy_id: null,
      movements: [],
      columns: []
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id() {
      this.reload();
    },
  },
  created() {
    this.reload();
  },
  components: {
    ResumeCard,
    newMovement,
    Times
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    checkedFunction(status) {
      if (status == "not_paid") return false;
      return status === "paid_subscriber" || status === "paid";
    },
    updatePay(event, id) {
      console.log(event);
      this.showMovement = true;
      this.buy_id = id;
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      console.log(sorting);
      this.current_page = offset;
      this.per_page = size;
      this.getCredits();
      // request api example
    },
    deleteFunction(id) {
      // /movements/:id
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu egreso ha sido eliminado",
              "success"
            );
            this.axios.delete(`movements/${id}`).then(() => this.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    getCredit() {
      this.loading = true;
      let app = this;
      let id = parseInt(this.$route.params.id);
      this.axios.get("/credits/" + id).then((response) => {
        app.credit = response.data;
        this.loading = false;
      });
    },
    closeFunction() {
      this.showMovement = false;
    },
    reload(){
      this.getCredit();
      this.getMovements()
    },
    getMovements() {
      console.log('eo')
      this.loading = true;
      let id = parseInt(this.$route.params.id);
      let app = this;
      this.axios
        .get("/movements", {
          params: {
            company_id: app.company_id,
            // from: app.dateRange[0],
            // to: app.dateRange[1],
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            // query: app.query,
            credit_id: id,
            order_date: "asc",
          },
        })
        .then((response) => {
          app.movements = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.notPaid {
  &:checked {
    + span {
      &:before {
        background-color: rgba(#f9fd00, 0.5);
      }
      &:after {
        background-color: #f9fd00;
        transform: translate(80%, -50%);
      }
    }
  }
}
.notConfirmation {
  &:checked {
    + span {
      &:before {
        background-color: rgba(#0e606b, 0.5);
      }
      &:after {
        background-color: #0e606b;
        transform: translate(80%, -50%);
      }
    }
  }
}
.tooltip{
  visibility: hidden;
  position: absolute;
}
.has-tooltip:hover .tooltip {
  visibility: visible;
  z-index: 100;
}
</style>
