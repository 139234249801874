export const formatNumber = (value) => {
  if(value === null || value === undefined) return 0;
  let val = (value / 1).toFixed(0).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

/**
 * Formats a number as a USD currency string.
 * @param {string} value - The value to format as USD currency.
 * @returns {string} The formatted USD currency string.
 */
export const formatNumberUsd = (value) => {
  // Extract cents and dollars
  const cent = value.slice(-2);
  const dollars = value.slice(0, -2);
  // Format dollars with commas
  const formattedDollars = dollars.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  // Combine dollars and cents with a comma
  const formattedAmount = formattedDollars + "," + cent;
  return formattedAmount;
}

// "17/10/2023"
export const formatSimpleDate = (date) => {
  if(date == null){
    return ''
  }
  return new Date(date).toLocaleDateString("es-CL", { });
}

// "Lunes, 17 de octubre de 2023"
export const formatCompleteDate = (date) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric"
  };
  return new Date(date).toLocaleString("es-CL", options);
}

// 17-10-2023
export const formatToDashedDate = (value) => {
  let dd = value.getDate()
  let mm = value.getMonth() + 1
  let yyyy = value.getFullYear()
  if (dd < 10) { dd = '0' + dd }
  if (mm < 10) { mm = '0' + mm }
  const response = `${dd}-${mm}-${yyyy}`
  return response
}

// "10/2023"
export const formatDateSII = (date) => {
  const options = {
    year: "numeric",
    month: "numeric"
  };
  return new Date(date).toLocaleString("es-CL", options);
}

// "Oct. 17, 2023"
export const formatAbbreviatedDate = (dateString) => {
  const months = [
    "Ene.", "Feb.", "Mar.", "Abr.", "May.", "Jun.",
    "Jul.", "Ago.", "Sep.", "Oct.", "Nov.", "Dic."
  ];

  const date = new Date(dateString);
  const monthIndex = date.getMonth();
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${months[monthIndex]} ${day}, ${year}`;
  return formattedDate;
}

// "2023-10-17 15:30:45"
export const formatDateTimeToLongFormat = (date) => {
  let options = {
    year: "numeric",
    month: "2-digit",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  }
  let response = new Date(date).toLocaleString("es-CL", options)
  return response;
}

export const formatPercentage = (value) => {
  let val = (value / 1).toFixed(1).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export const formatRut = (target) => {
  if(!target) return;
  let value = target.replace(/\./g, "").replace("-", "");
  if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
    value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
  } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
    value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
  } else if (value.match(/^(\d)(\d{0,2})$/)) {
    value = value.replace(/^(\d)(\d{0,2})$/, "$1.$2");
  }
  return value;
}

/**
 * Formats a document type based on its code.
 * @param {string} value - The code of the document type to format.
 * @returns {string} The formatted document type, or an empty string if the code is not recognized.
 */
export const formatDocType = (value) => {
  const docTypes = {
    "1": "Boleta Honorario",
    "2": "Boleta Honorario de terceros",
    "30": "Factura",
    "32": "Factura de ventas y servicios no afectos o exentos de IVA",
    "33": "Factura Electrónica",
    "34": "Factura no afecta o exenta electrónica",
    "35": "Boleta",
    "38": "Boleta exenta",
    "39": "Boleta electrónica",
    "40": "Liquidación factura",
    "41": "Boleta exenta electrónica",
    "43": "Liquidación factura electrónica",
    "45": "Factura de compra",
    "46": "Factura de compra electrónica",
    "48": "Pago electrónico",
    "50": "Guía de despacho",
    "52": "Guía de despacho electrónica",
    "55": "Nota de débito",
    "56": "Nota de débito electrónica",
    "60": "Nota de crédito",
    "61": "Nota de crédito electrónica",
    "103": "Liquidación",
    "110": "Factura de exportación electrónica",
    "111": "Nota de débito de exportación electrónica",
    "112": "Nota de crédito de exportación electrónica",
    "honorary": "Boleta de Honorarios",
    "bte": "Boleta de Honorarios de terceros",
    "914": "Declaración de Ingreso",
    "100000": "Gasto Manual",
    "100001": "Otros Ingresos",
    "1000004": "Previred",
    "200000000": "Valor Cuota Crédito"
  };
  return docTypes[value] || "";
}

export const formatStateMovement = (state_movement) => {
  const stateMovements = {
    active: "",
    archived: "Archivado",
    draff: "Borrador",
    not_included_accounting: "Factura no incluida en la contabilidad"
  };
  return stateMovements[state_movement]
}

export const differenceInDays = (from) => {
  const fromTimestamp = new Date(from).getTime();
  const currentTimestamp = Date.now();
  const differenceInMilliseconds = currentTimestamp - fromTimestamp;
  const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 3600 * 24));
  return differenceInDays;
};
