<template>
  <div class="modal-mask h-full flex overflow-scroll">
    <div class="flex justify-center items-center m-auto w-11/12 max-w-4xl">
      <div class="absolute top-0 left-0"></div>
      <div class="modal-container sm:w-100 z-10 w-full md:h-full flex flex-col justify-center bg-white">
        <div class="relative z-20">
          <div class="absolute right-0 top-0">
            <button @click.prevent="$emit('close')" class="focus:outline-none">
              <Icon icon="heroicons:x-mark" class="w-8 h-8 text-gray-800 hover:text-gray-400" />
            </button>
          </div>
        </div>
        <div class="space-y-10 py-8 px-2 md:px-8">
          <div class="space-y-8">
            <div class="grid grid-cols-4 gap-4">
              <div class="hidden sm:flex items-center justify-center bg-cyma-purple min-h-max py-6 rounded-lg">
                <p class="font-semibold text-xl sm:text-2xl text-white text-center">
                  Logo Empresa
                </p>
              </div>
              <div class="col-span-4 sm:col-span-3 md:col-span-2 space-y-2">
                <div
                  v-if="sender && sender.hasOwnProperty('RznSoc')"
                  class="text-cyma-purple font-semibold uppercase"
                >
                  {{ sender["RznSoc"] }}
                </div>
                <div
                  v-if="sender && sender.hasOwnProperty('GiroEmis')"
                  class="text-cyma-purple font-semibold uppercase"
                >
                  {{ sender["GiroEmis"] }}
                </div>
                <div
                  v-if="sender && sender.hasOwnProperty('DirOrigen')"
                  class="text-cyma-purple font-semibold uppercase"
                >
                  <span>{{ sender["DirOrigen"] }}</span>
                  <span v-if="sender && sender.hasOwnProperty('CmnaOrigen')">
                    , {{ sender["CmnaOrigen"] }}
                  </span>
                </div>
                <div
                  v-if="sender && sender.hasOwnProperty('Telefono')"
                  class="text-cyma-purple font-semibold uppercase"
                >
                  {{ sender["Telefono"] }}
                </div>
              </div>
              <div class="col-span-3 sm:col-span-2 col-start-2 sm:col-start-3 md:col-auto flex flex-col items-center border-4 border-red-500 min-h-max py-6">
                <div
                  v-if="sender && sender.hasOwnProperty('RUTEmisor')"
                  class="text-red-500 font-bold uppercase"
                >
                  R.U.T.: {{ formatRut(sender["RUTEmisor"]) }}
                </div>
                <div
                  v-if="dte && dte.hasOwnProperty('TipoDTE')"
                  class="text-center text-red-500 font-bold uppercase"
                >
                  {{ dteType(dte["TipoDTE"].toString()) }}
                </div>
                <div
                  v-if="dte && dte.hasOwnProperty('Folio')"
                  class="text-center text-red-500 font-bold uppercase"
                >
                  Nº {{ dte["Folio"] }}
                </div>
              </div>
            </div>

            <div class="flex flex-col col-span-2 bg-cyma-purple-200 min-h-max p-2">
              <div
                v-if="receiver && receiver.hasOwnProperty('RUTRecep')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  R.U.T.
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ formatRut(receiver["RUTRecep"]) }}</div>
                </div>
              </div>
              <div
                v-if="receiver && receiver.hasOwnProperty('RznSocRecep')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  SEÑOR(ES)
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ receiver["RznSocRecep"] }}</div>
                </div>
              </div>
              <div
                v-if="receiver && receiver.hasOwnProperty('GiroRecep')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  GIRO
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ receiver["GiroRecep"] }}</div>
                </div>
              </div>
              <div
                v-if="receiver && receiver.hasOwnProperty('DirRecep')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  DIRECCIÓN
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ receiver["DirRecep"] }}</div>
                </div>
              </div>
              <div
                v-if="receiver && receiver.hasOwnProperty('CmnaRecep')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  COMUNA
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ receiver["CmnaRecep"] }}</div>
                </div>
              </div>
              <div
                v-if="receiver && receiver.hasOwnProperty('Contacto')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  EMAIL
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ receiver["Contacto"] }}</div>
                </div>
              </div>
              <div
                v-if="dte && dte.hasOwnProperty('FchEmis')"
                class="flex"
              >
                <div class="text-black font-bold uppercase w-1/4">
                  FECHA
                </div>
                <div class="flex gap-3 col-span-6">
                  <div class="text-black font-bold">:</div>
                  <div>{{ dte["FchEmis"] }}</div>
                </div>
              </div>
            </div>
            <!-- Tabla detalles -->
            <div>
              <header class="flex items-center justify-center bg-cyma-purple gap-5">
                <span class="font-bold text-white uppercase py-1">
                  DETALLE DE ITEMS
                </span>
              </header>
              <div class="relative overflow-x-auto">
                <table class="w-full table-auto divide-y divide-gray-200 border">
                  <thead class="bg-cyma-purple-200">
                    <tr class="divide-x divide-gray-200">
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4">Codigo</th>
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4 text-left">Descripción</th>
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4">Cantidad</th>
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4">Precio</th>
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4 whitespace-nowrap">Imp. Ad.</th>
                      <!-- <th scope="col" class="font-bold text-cyma-purple py-1 px-4">Desc/Rec</th> -->
                      <th scope="col" class="font-bold text-cyma-purple py-1 px-4">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="detail in documents['detail']" :key="detail"
                      class="divide-x divide-gray-200"
                    >
                      <td class="font-bold text-gray-900 py-1 px-4">
                        {{ detail['NroLinDet'] }}
                      </td>
                      <td class="col-span-3 text-gray-900 py-1 px-4 whitespace-nowrap">
                        {{ detail['NmbItem'] }} {{ detail['DscItem'] }}
                      </td>
                      <td class="text-gray-900 py-1 px-4">
                        {{ detail['QtyItem'] }} {{ detail['UnmdItem'] }}
                      </td>
                      <td class="text-gray-900 py-1 px-4">
                        {{ formatNumber(detail['PrcItem']) }}
                      </td>
                      <!-- <td class="font-bold text-gray-900 py-1 px-4"></td> -->
                      <td class="font-bold text-gray-900 py-1 px-4"></td>
                      <td class="font-bold text-gray-900 py-1 px-4 ">
                        ${{ formatNumber(detail['MontoItem']) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="flex items-end justify-end">
            <div class="flex flex-col border-2 border-indigo-100">
              <div
                v-if="totals && totals.hasOwnProperty('MntNeto')"
                class="grid grid-cols-4 gap-5 py-1"
              >
                <div class="justify-self-end col-span-3 font-bold text-cyma-purple uppercase">
                  Monto Neto
                </div>
                <div class="flex justify-between gap-2">
                  <div class="font-bold text-black">$</div>
                  <div class="font-bold text-black pr-2">
                    {{ formatNumber(totals["MntNeto"]) }}
                  </div>
                </div>
              </div>
              <div
                v-if="totals && totals.hasOwnProperty('IVA')"
                class="grid grid-cols-4 gap-5 py-1"
              >
                <div class="justify-self-end col-span-3 font-bold text-cyma-purple uppercase">
                  Iva 19%
                </div>
                <div class="flex justify-between gap-2">
                  <div class="font-bold text-black">$</div>
                  <div class="font-bold text-black pr-2">
                    {{ formatNumber(totals["IVA"]) }}
                  </div>
                </div>
              </div>
              <div
                v-if="totals && totals.hasOwnProperty('MntExe')"
                class="grid grid-cols-4 gap-5 py-1"
              >
                <div class="justify-self-end col-span-3 font-bold text-cyma-purple uppercase">
                  Impuestos adicionales
                </div>
                <div class="flex justify-between gap-2">
                  <div class="font-bold text-black">$</div>
                  <div class="font-bold text-black pr-2">
                    {{ formatNumber(totals["MntExe"]) }}
                  </div>
                </div>
              </div>
              <div
                v-if="totals && totals.hasOwnProperty('MntTotal')"
                class="grid grid-cols-4 gap-5 py-1"
              >
                <div class="justify-self-end col-span-3 font-bold text-cyma-purple uppercase">
                  Monto total
                </div>
                <div class="flex justify-between gap-2">
                  <div class="font-bold text-black">$</div>
                  <div class="font-bold text-black pr-2">
                    {{ formatNumber(totals["MntTotal"]) }}
                  </div>
                </div>
              </div>
              <div
                v-if="totals && totals.hasOwnProperty('MntTotal')"
                class="grid grid-cols-4 bg-cyma-purple gap-5 py-1"
              >
                <div class="justify-self-end col-span-3 font-bold text-white uppercase">
                  Valor a pagar
                </div>
                <div class="flex justify-between gap-2">
                  <div class="font-bold text-white">$</div>
                  <div class="font-bold text-white pr-2">
                    {{ formatNumber(totals["MntTotal"]) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatRut, formatNumber } from "@/utils/formatters"
import { Icon } from "@iconify/vue2"

  export default {
    name: "ModalDTE",
    props: ["documents"],
    components: {
      Icon,
    },
    data() {
      return {
        dte: null,
        sender: null,
        receiver: null,
        totals: null,
      }
    },
    mounted() {
      this.dte      = this.documents["header"]["IdDoc"]
      this.sender   = this.documents["header"]["Emisor"]
      this.receiver = this.documents["header"]["Receptor"]
      this.totals   = this.documents["header"]["Totales"]
    },
    methods: {
      formatRut,
      formatNumber,
      dteType(value) {
        let response = ""
        switch (value) {
          case "30":
            response = "Factura"
            break
          case "32":
            response = "Factura de ventas y servicios no afectos o exentos de IVA"
            break
          case "33":
            response = "Factura electrónica"
            break
          case "34":
            response = "Factura no afecta o exenta electrónica"
            break
          case "35":
            response = "Boleta anulado"
            break
          case "38":
            response = "Boleta exenta"
            break
          case "39":
            response = "Boleta electrónica"
            break
          case "40":
            response = "Liquidación factura"
            break
          case "41":
            response = "Boleta exenta electrónica"
            break
          case "43":
            response = "Liquidación factura electrónica"
            break
          case "45":
            response = "Factura de compra"
            break
          case "46":
            response = "Factura de compra electrónica"
            break
          case "48":
            response = "Pago electrónico"
            break
          case "50":
            response = "Guía de despacho"
            break
          case "52":
            response = "Guía de despacho electrónica"
            break
          case "55":
            response = "Nota de débito"
            break
          case "56":
            response = "Nota de débito electrónica"
            break
          case "60":
            response = "Nota de crédito"
            break
          case "61":
            response = "Nota de crédito electrónica"
            break
          case "103":
            response = "Liquidación"
            break
          case "110":
            response = "Factura de exportación electrónica"
            break
          case "111":
            response = "Nota de débito de exportación electrónica"
            break
          case "112":
            response = "Nota de crédito de exportación electrónica"
            break
          case "914":
            response = "Declaración de ingreso (DIN)"
            break
          default:
        }
        return response
      },
    }
  };
</script>
