const plans = [
  {
    id: 1,
    title: "Microempresa",
    price: "2.5",
    value: 'entrepreneur',
    ifSellBetween: "0 a 2.500 UF anuales",
    accountingManagement: {
      numberCompaniesConnected: "1",
      hasAutomaticSIISynchronization: true,
      hasDashboard: true,
      hasAutomaticClassification: true,
      hasMonitoringTaxObligations: true,
      hasSalesRegister: true,
      hasPurchasesRegister: true,
      hasFeeRegister: true,
      hasThirdPartyFeeRegister: true,
      hasExpenseRegister: true,
      hasF29: true,
      hasCustomerRegistrationManagement: false,
      hasCollectionControl: false,
      hasSupplierRegistrationControl: false,
      hasPaymentControl: false,
      hasF22Company: false,
      hasPurchaseCategorization: false,
      hasClassificationRecommendation: false
    },
    myBank: {
      numberSynchronizedBanks: "0",
      hasAutomaticSynchronization: false,
      hasBankingMetricsAndBalances: false,
      hasMultiBankConnection: false,
      hasReconciliationRecommendation: false,
      hasAutomaticReconciliation: false,
      hasFinancialCreditControl: false,
    },
    peopleManagement: {
      numberEmployeeManagement: "0",
      hasAutomaticContracts: false,
      hasSettlements: false,
      hasSeverancePay: false,
      hasWorkerMobileApp: false,
      hasWorkerProfile: false,
      hasVacationRequestsControl: false,
      hasPreviredIntegration: false,
    },
    myCompany: {
      hasSynchronizationSettings: false,
      hasCustomChartOfAccounts: false,
      hasBranchesConfiguration: false,
      hasCostCenterConfiguration: false,
      hasNotificationsCenter: false,
      hasMultiCompany: false,
      hasIntegrations: false,
      hasAPIConnection: false,
    },
    myTeam: {
      hasPermissionControl: false,
      hasTaskManagement: false,
      hasCompanyAssignmentToUsers: false,
      hasOngoingMonitoringDashboardForAccountants: false,
      hasUserActivityMonitoring: false,
      numberUsers: "1"
    },
    mobileApp: {
      hasMetrics: true,
      hasNotifications: true,
      hasExpenseRegister: true,
    },
    reportingAndReports: {
      hasWeeklyReport: true,
      hasTaxFolder: true,
      hasGeneralJournal: false,
      hasGeneralLedger: false,
      hasBalanceSheet: false,
      hasIncomeStatement: false,
      hasPayrollRegister: false,
      hasCashFlow: false,
    },
    antiFraud: {
      hasDigitalFingerprint: true,
      hasReceipt: false, //Acuse de recibo
      hasAccountingMonitoring: false,
      hasAnomalyDetection: false,
      hasNotifications: false,
    },
    support: {
      hasChat: true,
      hasEmail: false,
      hasTelephonic: false,
    },
    backOfficeCT: {
      hasMonthlyTaxManagementF29: true,
      hasAnualTaxManagementF22: false,
      platformUsageSupport: false,
      hasContinuousMonitoringInformation: false,
      hasMeet: false,
      AccessAccountingTeam: false,
    },
    laborBackOffice: {
      hasStandardContractManagement: false,
      hasSettlementManagement: false,
      hasSeverancePayManagement: false, //Finiquito
      hasMeet: false,
      AccessLaborTeam: false,
    },
    additionalCosts: {
      additionalBankConnection: '0,5 UF + iva',
      additionalWorker:'0,5 UF + iva',
      user: 'No aplica'
    }
  },
  {
    id: 2,
    title: "Pequeña",
    price: "5",
    value: 'small',
    ifSellBetween: "2.500 a 10.000 UF anuales",
    accountingManagement: {
      numberCompaniesConnected: "1",
      hasAutomaticSIISynchronization: true,
      hasDashboard: true,
      hasAutomaticClassification: true,
      hasMonitoringTaxObligations: true,
      hasSalesRegister: true,
      hasPurchasesRegister: true,
      hasFeeRegister: true,
      hasThirdPartyFeeRegister: true,
      hasExpenseRegister: true,
      hasF29: true,
      hasCustomerRegistrationManagement: true,
      hasCollectionControl: true,
      hasSupplierRegistrationControl: true,
      hasPaymentControl: true,
      hasF22Company: true,
      hasPurchaseCategorization: false,
      hasClassificationRecommendation: false
    },
    myBank: {
      numberSynchronizedBanks: "3",
      hasAutomaticSynchronization: true,
      hasBankingMetricsAndBalances: true,
      hasMultiBankConnection: true,
      hasReconciliationRecommendation: true,
      hasAutomaticReconciliation: false,
      hasFinancialCreditControl: false,
    },
    peopleManagement: {
      numberEmployeeManagement: "10",
      hasAutomaticContracts: true,
      hasSettlements: true,
      hasSeverancePay: true,
      hasWorkerMobileApp: true,
      hasWorkerProfile: true,
      hasVacationRequestsControl: true,
      hasPreviredIntegration: true,
    },
    myCompany: {
      hasSynchronizationSettings: true,
      hasCustomChartOfAccounts: true,
      hasBranchesConfiguration: true,
      hasCostCenterConfiguration: true,
      hasNotificationsCenter: true,
      hasMultiCompany: false,
      hasIntegrations: false,
      hasAPIConnection: false,
    },
    myTeam: {
      hasPermissionControl: true,
      hasTaskManagement: true,
      hasCompanyAssignmentToUsers: false,
      hasOngoingMonitoringDashboardForAccountants: false,
      hasUserActivityMonitoring: false,
      numberUsers: "5"
    },
    mobileApp: {
      hasMetrics: true,
      hasNotifications: true,
      hasExpenseRegister: true,
    },
    reportingAndReports: {
      hasWeeklyReport: true,
      hasTaxFolder: true,
      hasGeneralJournal: true,
      hasGeneralLedger: true,
      hasBalanceSheet: true,
      hasIncomeStatement: true,
      hasPayrollRegister : true,
      hasCashFlow: false,
    },
    antiFraud: {
      hasDigitalFingerprint: true,
      hasReceipt: true, //Acuse de recibo
      hasAccountingMonitoring: false,
      hasAnomalyDetection: false,
      hasNotifications: false,
    },
    support: {
      hasChat: true,
      hasEmail: true,
      hasTelephonic: false,
    },
    backOfficeCT: {
      hasMonthlyTaxManagementF29: true,
      hasAnualTaxManagementF22: true,
      platformUsageSupport: true,
      hasContinuousMonitoringInformation: false,
      hasMeet: false,
      AccessAccountingTeam: false,
    },
    laborBackOffice: {
      hasStandardContractManagement: true,
      hasSettlementManagement: true,
      hasSeverancePayManagement: true, //Finiquito
      hasMeet: false,
      AccessLaborTeam: false,
    },
    additionalCosts: {
      additionalBankConnection: '0,5 UF + iva',
      additionalWorker:'0,5 UF + iva',
      user: '0,1 UF + iva'
    }
  },
  {
    id: 3,
    title: "Mediana",
    price: "10",
    value: 'medium',
    ifSellBetween: "10.000 a 30.000 UF anuales",
    accountingManagement: {
      numberCompaniesConnected: "1",
      hasAutomaticSIISynchronization: true,
      hasDashboard: true,
      hasAutomaticClassification: true,
      hasMonitoringTaxObligations: true,
      hasSalesRegister: true,
      hasPurchasesRegister: true,
      hasFeeRegister: true,
      hasThirdPartyFeeRegister: true,
      hasExpenseRegister: true,
      hasF29: true,
      hasCustomerRegistrationManagement: true,
      hasCollectionControl: true,
      hasSupplierRegistrationControl: true,
      hasPaymentControl: true,
      hasF22Company: true,
      hasPurchaseCategorization: true,
      hasClassificationRecommendation: true
    },
    myBank: {
      numberSynchronizedBanks: "4",
      hasAutomaticSynchronization: true,
      hasBankingMetricsAndBalances: true,
      hasMultiBankConnection: true,
      hasReconciliationRecommendation: true,
      hasAutomaticReconciliation: false,
      hasFinancialCreditControl: false,
    },
    peopleManagement: {
      numberEmployeeManagement: "20",
      hasAutomaticContracts: true,
      hasSettlements: true,
      hasSeverancePay: true,
      hasWorkerMobileApp: true,
      hasWorkerProfile: true,
      hasVacationRequestsControl: true,
      hasPreviredIntegration: true,
    },
    myCompany: {
      hasSynchronizationSettings: true,
      hasCustomChartOfAccounts: true,
      hasBranchesConfiguration: true,
      hasCostCenterConfiguration: true,
      hasNotificationsCenter: true,
      hasMultiCompany: true,
      hasIntegrations: false,
      hasAPIConnection: false,
    },
    myTeam: {
      hasPermissionControl: true,
      hasTaskManagement: true,
      hasCompanyAssignmentToUsers: true,
      hasOngoingMonitoringDashboardForAccountants: true,
      hasUserActivityMonitoring: false,
      numberUsers: "10"
    },
    mobileApp: {
      hasMetrics: true,
      hasNotifications: true,
      hasExpenseRegister: true,
    },
    reportingAndReports: {
      hasWeeklyReport: true,
      hasTaxFolder: true,
      hasGeneralJournal: true,
      hasGeneralLedger: true,
      hasBalanceSheet: true,
      hasIncomeStatement: true,
      hasPayrollRegister : true,
      hasCashFlow: true,
    },
    antiFraud: {
      hasDigitalFingerprint: true,
      hasReceipt: true, //Acuse de recibo
      hasAccountingMonitoring: true,
      hasAnomalyDetection: false,
      hasNotifications: false,
    },
    support: {
      hasChat: true,
      hasEmail: true,
      hasTelephonic: true,
    },
    backOfficeCT: {
      hasMonthlyTaxManagementF29: true,
      hasAnualTaxManagementF22: true,
      platformUsageSupport: true,
      hasContinuousMonitoringInformation: true,
      hasMeet: true,
      AccessAccountingTeam: false,
    },
    laborBackOffice: {
      hasStandardContractManagement: true,
      hasSettlementManagement: true,
      hasSeverancePayManagement: true, //Finiquito
      hasMeet: true,
      AccessLaborTeam: false,
    },
    additionalCosts: {
      additionalBankConnection: '0,5 UF + iva',
      additionalWorker:'0,5 UF + iva',
      user: '0,1 UF + iva'
    }
  },
  {
    id: 4,
    title: "Gran Empresa",
    price: "12",
    value: 'big',
    ifSellBetween: "30.000 a 100.000 UF anuales",
    accountingManagement: {
      numberCompaniesConnected: "1",
      hasAutomaticSIISynchronization: true,
      hasDashboard: true,
      hasAutomaticClassification: true,
      hasMonitoringTaxObligations: true,
      hasSalesRegister: true,
      hasPurchasesRegister: true,
      hasFeeRegister: true,
      hasThirdPartyFeeRegister: true,
      hasExpenseRegister: true,
      hasF29: true,
      hasCustomerRegistrationManagement: true,
      hasCollectionControl: true,
      hasSupplierRegistrationControl: true,
      hasPaymentControl: true,
      hasF22Company: true,
      hasPurchaseCategorization: true,
      hasClassificationRecommendation: true
    },
    myBank: {
      numberSynchronizedBanks: "5",
      hasAutomaticSynchronization: true,
      hasBankingMetricsAndBalances: true,
      hasMultiBankConnection: true,
      hasReconciliationRecommendation: true,
      hasAutomaticReconciliation: true,
      hasFinancialCreditControl: true,
    },
    peopleManagement: {
      numberEmployeeManagement: "30",
      hasAutomaticContracts: true,
      hasSettlements: true,
      hasSeverancePay: true,
      hasWorkerMobileApp: true,
      hasWorkerProfile: true,
      hasVacationRequestsControl: true,
      hasPreviredIntegration: true,
    },
    myCompany: {
      hasSynchronizationSettings: true,
      hasCustomChartOfAccounts: true,
      hasBranchesConfiguration: true,
      hasCostCenterConfiguration: true,
      hasNotificationsCenter: true,
      hasMultiCompany: true,
      hasIntegrations: true,
      hasAPIConnection: true,
    },
    myTeam: {
      hasPermissionControl: true,
      hasTaskManagement: true,
      hasCompanyAssignmentToUsers: true,
      hasOngoingMonitoringDashboardForAccountants: true,
      hasUserActivityMonitoring: true,
      numberUsers: "15"
    },
    mobileApp: {
      hasMetrics: true,
      hasNotifications: true,
      hasExpenseRegister: true,
    },
    reportingAndReports: {
      hasWeeklyReport: true,
      hasTaxFolder: true,
      hasGeneralJournal: true,
      hasGeneralLedger: true,
      hasBalanceSheet: true,
      hasIncomeStatement: true,
      hasPayrollRegister : true,
      hasCashFlow: true,
    },
    antiFraud: {
      hasDigitalFingerprint: true,
      hasReceipt: true, //Acuse de recibo
      hasAccountingMonitoring: true,
      hasAnomalyDetection: true,
      hasNotifications: true,
    },
    support: {
      hasChat: true,
      hasEmail: true,
      hasTelephonic: true,
    },
    backOfficeCT: {
      hasMonthlyTaxManagementF29: true,
      hasAnualTaxManagementF22: true,
      platformUsageSupport: true,
      hasContinuousMonitoringInformation: true,
      hasMeet: true,
      AccessAccountingTeam: true,
    },
    laborBackOffice: {
      hasStandardContractManagement: true,
      hasSettlementManagement: true,
      hasSeverancePayManagement: true, //Finiquito
      hasMeet: true,
      AccessLaborTeam: true,
    },
    additionalCosts: {
      additionalBankConnection: '0,5 UF + iva',
      additionalWorker:'0,5 UF + iva',
      user: '0,1 UF + iva'
    }
  },
]

export default plans
