<template>
  <div>
    <div class="px-4 md:px-6 lg:px-0 xl:pl-6 xl:pr-3">
      <div
        class="flex items-center justify-between w-full py-4 lg:py-6"
      >
        <div class="flex flex-wrap items-center w-full gap-2 sm:gap-4">
          <!-- <div class="block sm:mr-4 lg:hidden">
            <SidebarResponsive/>
          </div> -->
          <!-- <ul class="flex items-center"> -->
          <button @click="openSidebarMobile" class="focus:outline-none lg:hidden p-1.5">
            <Icon icon="heroicons:bars-3-bottom-left" class="w-5 h-5" />
          </button>
          <div class="order-last w-screen mt-1.5 md:w-64 md:order-none md:mt-0 navbar-select">
            <v-select
              class="style-chooser custom-border"
              :options="dataCompanies"
              :clearable="false"
              :reduce="(company) => company.id"
              v-model="defaultSelected"
              @input="submited"
              label="business_name"
            ></v-select>
          </div>
          <div
            v-if="validatedOrder"
          >
            <a
              @click.prevent="createButton"
              class="button-cymasuite flex-1"
            >
              <span class="font-medium hidden sm:inline-block">
                Subir <span class="hidden lg:inline-block">empresas</span>
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M9 19l3 3m0 0l3-3m-3 3V10" />
              </svg>
            </a>
          </div>
          <div v-else-if="validatedOrderUser">
            <!-- <p
              class="flex items-center gap-2 p-2 rounded-full md:px-6 bg-cyma-gray hover:text-current dark:bg-cian-dark dark:text-white"
            >
              <span class="hidden lg:flex">Empresa Compartida</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
              </svg>
            </p> -->
          </div>
          <div v-else>
            <a
              @click.prevent="goTo('Subscription')"
              class="md:px-6 font-semibold text-white flex gap-2 items-center py-1.5 sm:py-2 px-4 rounded-full bg-cyma-purple dark:bg-cian-dark dark:text-white"
            >
              <span class="hidden lg:font-medium sm:flex md:hidden lg:flex">
                Comienza Gratis
              </span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
              </svg>

            </a>
          </div>
          <ToolTip class="hidden sm:block">
            <template #icon>
              <div v-if="loadFinish">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="#77dfaa" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                </svg>
              </div>
              <!-- update -->
              <button
                @click.prevent="updateToday()"
                v-else
                class="p-1.5 rounded-lg hover:shadow-sm dark:hover:shadow-sm-dark text-gray-500 focus:outline-none dark:text-white hover:text-cian-dark dark:hover:text-cian-400 transition-all duration-200 ease-out"
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5" :class="loadIcon === true ? 'animate-spin' : '' ">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </button>
            </template>
            Se actualizaran todos los documentos de <u>principio de mes hasta hoy</u>.
          </ToolTip>

          <div class="lg:ml-auto order-none sm:w-44 border border-transparent hover:border-stroke-dark dark:hover:border-stroke-dark rounded-lg transition duration-200 hover:border-opacity-10 dark:hover:border-opacity-50">
            <v-select
              class="transparent-chooser"
              v-model="date_type"
              :options="date_types"
              :reduce="(state) => state.value"
              label="name"
              placeholder='Tipo de Fecha'
              :clearable="false"
              :searchable="false"
            ></v-select>
          </div>
          <div class="ml-auto md:ml-0 flex items-center gap-4">
            <div class="hidden sm:flex items-center justify-between px-2 gap-2">
              <a class="px-2.5 py-2 rounded-lg focus:outline-none hover:shadow-sm dark:hover:shadow-sm-dark dark:text-white cursor-pointer" @click.prevent="openModal" title="Agendemos una reunión">
                <Icon icon="heroicons:calendar-days" class="w-5 h-5" />
              </a>
              <ThemeSwitcher />
            </div>
            <!-- <RouterLink
              to="/beneficios"
              title="Comparte y gana"
            >
              <Icon icon="solar:cup-star-broken" class="w-5 h-5 hover:text-yellow-400 dark:hover:text-yellow-300 dark:text-white transition-all" />
            </RouterLink> -->
            <div>
              <button v-on:click="isActive = !isActive" class="flex text-sm bg-gray-800 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white" id="user-menu" aria-haspopup="true">
                <span class="sr-only">Open user menu</span>
                <img
                  v-if="this.user.avatar_url"
                  class="object-cover w-12 h-12 rounded-full"
                  :src="this.user.avatar_url"
                  alt=""
                >
                <div v-else class="relative w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                  <svg class="absolute w-12 h-12 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                </div>
              </button>
              <button v-if="isActive" v-on:click="isActive = false" tabindex="-1" class="fixed inset-0 top-0 w-full h-full bg-black opacity-0 cursor-default"></button>

              <div v-show="isActive" class="absolute right-0 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-100" v-on:focusin="isActive = !isActive" role="menu" aria-orientation="vertical" aria-labelledby="user-menu" >
                <a
                  @click.prevent="goTo('profile')"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Tu perfil
                </a>
                <a class="block sm:hidden px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" @click.prevent="openModal" title="Agendemos una reunión">
                  Agendar Reunión
                </a>
                <a
                  @click.prevent="goTo('MyPlan')"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  v-if="validatedOrderUser"
                >
                  Facturación
                </a>
                <a
                  @click.prevent="goTo('editProfile')"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Editar Perfil
                </a>
                <a
                  @click.prevent="goTo('changePasswordProfile')"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Cambiar Contraseña
                </a>
                <a class="block sm:hidden px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 select-none" @click.prevent="toggleTheme">
                  Modo <span> {{ theme == "light" ? "oscuro" : "claro"  }}</span>
                </a>
                <a
                  @click.prevent="showContractModal"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                >
                  Ver contrato
                </a>
                <a
                  @click.prevent="logout()"
                  role="menuitem"
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SidebarMobile v-if="showSidebarMobile" @close="showSidebarMobile = false" />
      <PaymentAdvice/>
      <AccountantAdvice/>
      <modalCalendly
        v-if="showModal"
        @close="showModal = false"
      />
      <ModalTermsAndConditions
        v-if="showContract"
        @close="showContract = false"
      />
    </div>
  </div>
</template>
<script>
import routerMixin from "@/mixins/routerMixin";
import "vue-select/dist/vue-select.css";
import { Icon } from "@iconify/vue2"
// import SidebarResponsive from "@/components/etc/SidebarResponsive";
import SidebarMobile from "@/components/etc/SidebarMobile.vue";
import AccountantAdvice from "@/components/etc/AccountantAdvice";
import modalCalendly from "@/components/etc/modalCalendly";
import ThemeSwitcher from './ThemeSwitcher';
import PaymentAdvice from './PaymentAdvice.vue';
import ToolTip from '@/components/ToolTipFull.vue';
import ModalTermsAndConditions from "@/views/private/components/onboarding/components/ModalTermsAndConditions.vue";

export default {
  name: "Navbar",
  props: ['dataCompanies'],
  mixins: [routerMixin],
  data() {
    return {
      theme: localStorage.getItem("theme") || "light",
      date_types: [
        { value: "period", name: "Fecha Registro SII" },
        { value: "date", name: "Fecha Emisión" },
        // { value: "estimated_payment", name: "Fecha Caducidad" },
      ],
      showContract: false,
      showFintoc: false,
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      isActive: false,
      showModal: false,
      companies: [],
      currentTab: "",
      hasCompany: false,
      company: [],
      user: { avatar_url: "" },
      marketplace: this.$store.state.marketplace,
      loadIcon: false,
      loadFinish: false,
      defaultSelected: {
        id: null,
        business_name: null
      },
      showSidebarMobile: false,
    };
  },
  created(){
    this.$store.commit('addRefresh', false)
    this.setCompany();
    this.user = this.$auth.user();
    // set value initial company id
    const cookie = this.$store.state.company_id;
    if (cookie != 'undefined' && cookie != null) {
      this.$store.commit('addCompanyId', parseInt(cookie));
    } else {
      this.$store.commit('addCompanyId', this.company_id);
    }
    if( this.$route.query && this.$route.query.company_id ) {
      this.$store.commit('addCompanyId', parseInt(this.$route.query.company_id));
    }
  },
  computed: {
    refresh() {
      return this.$store.state.refresh;
    },
    company_id() {
      return this.$store.state.company_id;
    },
    validatedOrder() {
      return this.$store.state.validatedOrder;
    },
    validatedOrderUser(){
      return this.$store.state.validatedOrderUser;
    },
    validatedOrderCurrentUser(){
      return this.$store.state.validatedOrderCurrentUser;
    },
    date_type: {
      get(){
        return this.$store.state.buy_date_type;
      },
      set(newValue){
        this.$store.commit('buyDateType', newValue);
      }
    },
  },
  watch: {
    company_id(){
      this.defaultSelected = this.$store.state.company_id
    }
  },
  components: {
    // SidebarResponsive,
    ThemeSwitcher,
    PaymentAdvice,
    AccountantAdvice,
    ToolTip,
    modalCalendly,
    SidebarMobile,
    Icon,
    ModalTermsAndConditions
  },
  methods: {
    toggleTheme() {
      this.theme == "dark" ? (this.theme = "light") : (this.theme = "dark"),
        (localStorage.theme = this.theme);
      this.theme == "dark"
        ? document.querySelector("html").classList.add("dark")
        : document.querySelector("html").classList.remove("dark");
    },
    showContractModal() {
      this.showContract = true;
    },
    openSidebarMobile() {
      this.showSidebarMobile = true;
    },
    openModal(){
      this.showModal = true;
    },
    createButton() {
      this.$router.push({ name: "Onboarding" })
    },
    getPermissionState(event){
      const response =  this.axios.get("/permission_state",{
        params: {
          company_id: event,
        }
      })
      return response
    },
    formatDate(d) {
      var date = new Date(d)
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      var strTime = hours + ':' + minutes + ' ' + ampm;
      return date.toLocaleDateString("es-CL", { timeZone: "UTC" }) + ' ' + strTime;
    },
    async submited(event) {
      this.$store.commit('addCompanyId', event);
      let role_state = await this.getPermissionState(event)
      this.$store.commit('addRole', role_state.data)
      this.permissionRequest(role_state.data)
    },
    updateToday(){
      let app = this;
      app.loadIcon = true;
      this.axios.get("/update-today",{
        params: {
          id: this.company_id,
        }
      }).then(() => {
        app.loadIcon = false;
        app.loadFinish = true;
        this.$store.commit('addRefresh', true)
      });
    },
    permissionRequest(role){
      this.$store.commit("addPermitted", false);
      if(this.$route.meta.auth){
        if (this.$route.meta.adminAuth) {
          if (role == 'admin'){
            this.$store.commit('addPermitted', true)
          }
        }
        if (this.$route.meta.egressDigitizerAuth) {
          if (role == 'egress_digitizer'){
            this.$store.commit('addPermitted', true)
          }
        }
        if (this.$route.meta.accountantAuth) {
          if (role == 'accountant'){
            this.$store.commit('addPermitted', true)
          }
        }
      }
    },
    tabs(id, event) {
      let element = event.target;
      element.classList.add("bg-black-600");
      this.currentTab = id;
    },
    setCompany() {
      const cookie = this.$store.state.company_id;
        this.hasCompany = this.dataCompanies.length > 0;
        if (cookie != 'undefined' && cookie != null) {
          var item = this.dataCompanies.find(item => item.id == cookie);
          if (item != null) {
            this.defaultSelected.id = item.id;
            this.defaultSelected.business_name = item.business_name;
            this.submited(item.id);
          }
        }
    },
    getCompany() {
      const company_id = this.$store.state.company_id;
      this.axios.get("/companies",{
        params: {
          id: company_id,
        }
      }).then((response) => {
        this.company =  response.data.data[0];
      });
    },
    logout() {
      localStorage.removeItem('company_id');
      this.$auth.logout({
        makeRequest: true,
        redirect: { name: "login" },
      });
    },
  },
};
</script>
<style>
 .navbar-select .style-chooser .vs__selected{
  white-space: nowrap;
  width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vs__search:focus {
  caret-color: transparent;
}
 .navbar-select .style-chooser .vs__dropdown-option{
  overflow-x: hidden;
}
.style-chooser .vs__dropdown-toggle {
  height: 47px;
}
</style>
