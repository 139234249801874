<template >
  <div class="max-h-96">
    <form class="flex flex-col" id="form-bigger">
      <div class="sticky bg-white dark:bg-background-dark top-0 z-10 hidden lg:block sm:block md:block">
        <div class="filter">
          <h2
            class="label-cymasuite mt-4"
          >
            Clasificación
          </h2>
        </div>
        <div class="hidden lg:flex gap-2 mt-2">
          <div class="filter">
            <h3 class="label-cymasuite">Sucursal</h3>
          </div>
          <div class="filter">
            <h3 class="label-cymasuite">
              Centro de costo
            </h3>
          </div>
          <div class="filter">
            <h3 class="label-cymasuite">
              Clasificación
            </h3>
          </div>
          <div class="flex gap-2">
            <h3 class="label-cymasuite px-2">
              Monto Neto {{ formatNumber(neto) }}
            </h3>
            <span class="tracking-normal text-xs text-gray-400 dark:text-white">
              Saldo: $ {{ formatNumber(balance) }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="form-group"
        v-for="(input, k) in this.$v.dataAcc.$each.$iter"
        :key="k + 1"
      >
        <div v-if="!input.destroy_manual.$model">
          <div class="flex gap-2 items-top" id="form">
            <div class="filter">
              <v-select
                append-to-body
                class="style-chooser custom-border"
                :options="branchOffices"
                :reduce="(option) => option.id"
                v-model="input.branch_office_id.$model"
                placeholder="Sucursal"
                label="name"
              ></v-select>
            </div>
            <div class="filter">
              <v-select
                append-to-body
                class="style-chooser custom-border"
                :options="costCenter"
                :reduce="(option) => option.id"
                v-model="input.cost_center_id.$model"
                placeholder="Centro de Costos"
                label="name"
              ></v-select>
            </div>
            <div class="filter">
              <v-select
                append-to-body
                class="style-chooser custom-border"
                :options="accounts"
                :reduce="(account) => account.id"
                label="fantasy_name"
                placeholder="Plan de cuenta"
                v-model="input.account_id.$model"
              ></v-select>
              <div class="text_error" v-if="!input.account_id.$error">
                Este campo es requerido.
              </div>
            </div>
            <div class="flex items-start filter w-full">
              <range-slider
                class="slider w-full"
                :min="input.min.$model"
                :max="input.max.$model"
                @change="calculatePorcentaje($event, k)"
                v-model="input.total_amount.$model"
              >
              </range-slider>
              <currency-input
                currency="CLP"
                locale="es-CL"
                class="appearance-none block text-grey-darker p-3 dark:bg-background-dark dark:text-white whitespace-nowrap"
                @input="calculatePorcentaje($event, k)"
                v-model="input.total_amount.$model"
              />
              <span class="w-1/4 appearance-none block text-gray-500 py-3 whitespace-nowrap">
                {{ input.percentage.$model || 0 }} %
              </span>
              <div class="w-1/4">
                <button
                  v-if="k != 0"
                  class="my-3 flex self-start items-center justify-center text-cian-400 hover:text-cian-600 dark:text-gray-200 dark:hover:text-white focus:outline-none"
                  @click.prevent="
                    input.destroy_manual.$model = true;
                    remove(input.total_amount.$model,k);
                  "
                >
                  <Trash />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button
      @click.prevent="add()"
      class="my-2 h-10 text-cian-dark focus:outline-none"
      v-if="balance > 0"
    >
      Dividir en más
    </button>
  </div>
</template>
<script>
import { formatNumber } from '@/utils/formatters'
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from "vuelidate/lib/validators";
import Trash from "@/components/Icons/Trash.vue";

export default {
  name: "addAccountMovement",
  props: {
    dataAcc: Array,
    action: String,
    totalAmount: Number,
    neto: Number,
    typeMovement: String,
    movement: [],
    ids: Array,
  },
  data() {
    return {
      balance: 0,
      accounts: [],
      branchOffices: [],
      costCenter: [],
      showAcc: false,
      lines_sum: 0,
    };
  },
  components: {
    RangeSlider, Trash,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  mounted() {
    this.getBranchOffice();
    this.getCostCenter();
    this.getAccounts();
  },
  validations: {
    dataAcc: {
      required,
      $each: {
        cost_center_id: {},
        min: {},
        max: {},
        branch_office_id: {},
        account_id: {
          required,
        },
        total_amount: {},
        percentage: {},
        _destroy: {},
        destroy_manual: {},
        disable:{},
        type_account_movement: {},
      },
    },
  },
  watch: {
    dataAcc() {
      this.$emit("addAttribute", this.dataAcc);
    },
    totalAmount() {
      if(this.dataAcc.length == 1 ){
        this.calculatePorcentaje(this.neto, 0);
      }
    },
  },
  methods: {
    formatNumber,
    add() {
      let cur = this;

      if (cur.ids.length > 0) {
        cur.dataAcc[cur.ids[cur.ids.length - 1]].disable = true;
      }
      cur.dataAcc.push({
        branch_office_id: null,
        cost_center_id: null,
        min: 0,
        max: cur.balance,
        divide: null,
        percentage: 0,
        date_sii: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        total_amount: null,
        state: "exempt_amount",
        company_id: this.company_id,
        type_movement: this.typeMovement,
        type_account_movement: "record",
        _destroy: null,
        destroy_manual: false,
        disable: false,
      });
      this.$emit("addAttribute", cur.dataAcc);
    },
    calculatePorcentaje(value, id) {
      let cur = this;
      let porcentaje = (value / cur.neto) * 100;
      this.dataAcc[id].percentage = Math.round(porcentaje, 2);
      this.dataAcc[id].period = cur.$parent.buyObj.period;
      this.dataAcc[id].date_sii = cur.$parent.buyObj.period;
      this.calculateBalance(value)
    },
    calculateBalance(value) {
      // the array starts at zero and the count starts at 1, so -2
      let cur = this;
      let lastAccountMovements = cur.dataAcc.slice(0, cur.dataAcc.length);
      let sumAccountMovements = lastAccountMovements.reduce(
        (acc, e) => acc + e.total_amount,
        0
      );
      if (cur.dataAcc.length > 1) {
        cur.balance = cur.neto - sumAccountMovements;
      } else {
        cur.balance = cur.neto - value;
      }
    },
    getAccounts() {
      this.axios
        .get("select_dashboard", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.accounts = response.data;
        });
    },
    remove(value,index) {
      this.calculatePorcentaje(value, index)
      let account = this.dataAcc[index];
      if (account.id == null) {
        this.dataAcc.splice(index, 1);
      } else {
        account._destroy = "1";
      }
    },
    getBranchOffice() {
      this.axios
        .get("branch_offices", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.branchOffices = response.data;
        });
    },
    getCostCenter() {
      this.axios
        .get("cost_centers", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.costCenter = response.data;
        });
    },

  },
};
</script>
