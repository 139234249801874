var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200"},[_vm._v(" Usuarios permitidos ")]),_vm._l((_vm.company.permissions_attributes),function(permission,i){return _c('div',{key:i,staticClass:"relative grid gap-4 my-4 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6"},[_c('div',{},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"inline-full-name"}},[_vm._v(" Nombre ")]),_c('input',{staticClass:"input-text uppercase",attrs:{"disabled":"","type":"text"},domProps:{"value":permission.user_id == null ? 'Pendiente' : permission.user.name}})]),_c('div',{},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"inline-full-name"}},[_vm._v(" Apellido ")]),_c('input',{staticClass:"input-text uppercase",attrs:{"disabled":"","type":"text"},domProps:{"value":permission.user_id == null ? 'Pendiente' : permission.user.lastname}})]),_c('div',{},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"inline-full-name"}},[_vm._v(" Email ")]),_c('input',{staticClass:"input-text",attrs:{"disabled":"","type":"text"},domProps:{"value":permission.user_id == null
            ? permission.email
            : permission.user.email}})]),_c('div',{},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"inline-full-name"}},[_vm._v(" Estado de Invitacion ")]),_c('input',{staticClass:"input-text uppercase",attrs:{"disabled":"","type":"text"},domProps:{"value":_vm.stateFormat(permission.state)}})]),_c('div',{},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"inline-full-name"}},[_vm._v(" Permisos ")]),_c('v-select',{staticClass:"style-chooser custom-border",attrs:{"clearable":false,"disabled":_vm.company.sii_config_attributes.user_id === permission.user_id,"reduce":(role) => role.value,"options":[
          { value: 'admin', name: 'Administrador' },
          { value: 'accountant', name: 'Contador' },
          { value: 'egress_digitizer', name: 'Digitador de Egreso' },
        ],"label":"name"},model:{value:(permission.role),callback:function ($$v) {_vm.$set(permission, "role", $$v)},expression:"permission.role"}})],1),_c('div',{staticClass:"flex items-center"},[(_vm.company.sii_config_attributes.user_id === _vm.user.id)?_c('button',{staticClass:"px-4 text-sm dark:text-cian-dark",attrs:{"type":"button"},on:{"click":function($event){return _vm.deletePermission(permission.id)}}},[_vm._v(" Eliminar Permisos ")]):_vm._e()])])}),_c('div',{staticClass:"relative col-end-2 my-2"},[(_vm.company.sii_config_attributes.user_id === _vm.user.id)?_c('button',{staticClass:"dark:text-cian-dark",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('showModal', true)}}},[_vm._v(" Agregar nuevo usuario ")]):_vm._e()]),_c('div',{staticClass:"flex items-center justify-center my-12"},[(_vm.loading)?_c('Spinner'):_c('button',{staticClass:"flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_vm._v(" Guardar Configuración ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }