<template>
  <div v-if="validatedOrder" class="pt-6 pb-12 mx-6 flex flex-col lg:flex-row justify-center">
    <div class="rounded-lg shadow-lg bg-white lg:mx-0 w-5/12 dark:bg-black-dark">
      <div class="p-4 md:p-12">
        <div class="flex text-3xl text-gray-900 font-semibold mb-2">
          <div class="text-center dark:text-white">
            Detalle Transacción
          </div>
        </div>
        <table class="text-base my-4 dark:text-white">
          <tbody>
            <tr v-if="order.method_pay">
              <td class="py-2 text-gray-500">Metodo de pago</td>
              <td class="p-2 capitalize px-2">{{ order.method_pay }}</td>
            </tr>
            <tr v-if="order.buy_order">
              <td class="py-2 text-gray-500">Orden de compra</td>
              <td class="p-2 px-2">{{ order.buy_order }}</td>
            </tr>
            <tr v-if="order.status">
              <td class="py-2 text-gray-500">Estado</td>
              <td class="p-2 capitalize px-2">{{ formatStatus(order.status) }}</td>
            </tr>
            <tr v-if="order.vci">
              <td class="py-2 text-gray-500">VCI</td>
              <td class="p-2 capitalize px-2">{{ formatVci(order.vci) }}</td>
            </tr>
            <tr v-if="order.response_code">
              <td class="py-2 text-gray-500">Respuesta</td>
              <td class="p-2 capitalize px-2">{{ formatResponseCode(order.response_code) }}</td>
            </tr>
            <tr v-if="order.payment_type_code">
              <td class="py-2 text-gray-500">Tipo de pago</td>
              <td class="p-2 capitalize px-2">{{ formatPaymentTypeCode(order.payment_type_code) }}</td>
            </tr>
            <tr v-if="order.authorization_code">
              <td class="py-2 text-gray-500">Código de autorización</td>
              <td class="p-2">{{ order.authorization_code }}</td>
            </tr>
            <tr v-if="order.card_number">
              <td class="py-2 text-gray-500">Número de tarjeta</td>
              <td class="p-2">{{ order.card_number }}</td>
            </tr>
            <tr v-if="order.transaction_date">
              <td class="py-2 text-gray-500">Fecha de la Transacción</td>
              <td class="p-2 capitalize">{{ formatDateTimeToLongFormat(order.transaction_date) }}</td>
            </tr>
            <tr v-if="order.installments_number">
              <td class="py-2 text-gray-500">Número de cuotas</td>
              <td class="p-2">{{ order.installments_number }}</td>
            </tr>
            <tr v-if="order.installments_amount">
              <td class="py-2 text-gray-500">Monto de las cuotas</td>
              <td class="p-2">$ {{ formatNumber(order.installments_amount) }}</td>
            </tr>
            <tr v-if="order.subtotal">
              <td class="py-2 text-gray-500">Subtotal</td>
              <td class="p-2">$ {{ formatNumber(order.subtotal) }}</td>
            </tr>
            <tr v-if="order.iva">
              <td class="py-2 text-gray-500">IVA</td>
              <td class="p-2">$ {{ formatNumber(order.iva) }}</td>
            </tr>
            <tr v-if="order.total_amount">
              <td class="py-2 text-gray-500">Monto total</td>
              <td class="p-2">$ {{ formatNumber(order.total_amount) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatNumber, formatDateTimeToLongFormat } from "@/utils/formatters";

  export default {
    name: "TransactionDetails",
    data() {
      return {
        order: {},
        marketplace: this.$store.state.marketplace
      }
    },
    computed: {
      validatedOrder() {
        return this.$store.state.validatedOrder;
      }
    },
    components: {
    },
    mounted() {
      this.getOrder();
    },
    methods: {
      formatNumber,
      formatDateTimeToLongFormat,
      formatStatus(value) {
        let response = ""
        switch (value) {
          case "initialized":
            response = "Pagar"
            break
          case "authorized":
            response = "Autorizado"
            break
          case "failed":
            response = "Fallido"
            break
          case "nullified":
            response = "Anulado"
            break
          case "partially_nullified":
            response = "Parcialmente anulado"
            break
          case "reversed":
            response = "Revertido"
            break
          default:
        }
        return response
      },
      formatVci(value) {
        let response = ""
        switch (value) {
          case "successful_authentication":
            response = "Autenticación exitosa"
            break
          case "Autenticación fallida":
            response = "Autorizado"
            break
          case "maximum_time_exceeded":
            response = "Tiempo máximo excedido para autenticación"
            break
          case "authentication_aborted":
            response = "Autenticación abortada por tarjetahabiente"
            break
          case "internal_authentication_error":
            response = "Error interno en la autenticación"
            break
          case "not_participate":
            response = "No Participa, probablemente por ser una tarjeta extranjera que no participa en el programa 3DSecure"
            break
          case "foreign_failed_authentication":
            response = "Autenticación fallida extranjera"
            break
          default:
        }
        return response
      },
      formatResponseCode(value) {
        let response = ""
        switch (value) {
          case "approved_transaction":
            response = "Transacción aprobada"
            break
          case "data_entry_error":
            response = "Posible error en el ingreso de datos de la transacción"
            break
          case "failure_process_transactions":
            response = "Se produjo fallo al procesar la transacción"
            break
          case "transaction_error":
            response = "Error en Transacción"
            break
          case "rejected_by_issuer":
            response = "Rechazada por parte del emisor"
            break
          case "risk_possible_fraud":
            response = "Transacción con riesgo de posible fraude"
            break
          default:
        }
        return response
      },
      formatPaymentTypeCode(value) {
        let response = ""
        switch (value) {
          case "debit_sale":
            response = "Venta Débito"
            break
          case "normal_sale":
            response = "Venta Normal"
            break
          case "sale_installments":
            response = "Venta en cuotas"
            break
          case "three_installments_without_interest":
            response = "3 cuotas sin interés"
            break
          case "two_installments_without_interest":
            response = "2 cuotas sin interés anulado"
            break
          case "n_installments_without_interest":
            response = "N Cuotas sin interés"
            break
          case "prepaid_sale":
            response = "Venta Prepago"
            break
          default:
        }
        return response
      },
      getOrder() {
        const cur = this
        const userId = this.$auth.user().id
        const accessToken = cur.$cookie.get("access-token")
        this.axios
        .get(cur.marketplace + "/api/v1/client/access", {
          headers: {
            authorization: "Bearer " + accessToken
          },
          params: {
            user_id: userId,
            status: 2
          }
        })
        .then((response) => {
          cur.order = response.data
        })
      }
    }
  };
</script>
