<template>
  <AsideModal :loading="loading" @close="closeModal()">
    <template #title>
      <p class="text-gray-700 font-bold dark:text-white uppercase text-sm tracking-wide">
        {{ transaction?.description }}
      </p>
      <h2 class="text-3xl font-bold text-gray-900 dark:text-white">
        {{ formatNumber(transaction?.amount || 0) }}
      </h2>
      <div
        v-if="transaction && transaction.transfer_account_holder_name"
        class="text-gray-600 dark:text-white"
      >
        <p>{{ transaction.transfer_account_holder_name }}</p>
        <p v-if="transaction && transaction.transfer_account_holder_id">
          {{ formatRut(transaction.transfer_account_holder_id) }}
        </p>
      </div>
      <div
        v-else-if="transaction && transaction.recipient_account.hasOwnProperty('holder_name')"
        class="text-gray-600 dark:text-white"
      >
        <p>{{ transaction.recipient_account.holder_name }}</p>
        <p>{{ formatRut(transaction.recipient_account.holder_id) }}</p>
      </div>
      <div v-else>
      </div>
      <div class="flex items-center gap-4">
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco Santander'">
          <IconBancoSantander class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco de Chile'">
          <IconBancoDeChile class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco Estado'">
          <IconBancoEstado class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco BCI'">
          <IconBancoBCI class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco Itaú'">
          <IconBancoItau class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco Security'">
          <IconBancoSecurity class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco BICE'">
          <IconBancoBICE class="w-5 h-10" />
        </div>
        <div v-if="transaction?.bank_bill.bank.institution_country === 'Banco Scotiabank'">
          <IconBancoScotiabank class="w-5 h-5" />
        </div>
        <div class="flex">
          <p class="text-gray-500 dark:text-white">{{ transaction?.bank_bill.name }} -</p>
          <p class="text-gray-500 dark:text-white">- {{ transaction?.bank_bill.number }}</p>
        </div>
      </div>
    </template>
    <template #body>
      <div class="rounded-lg border bg-comentary-gray dark:bg-black-dark dark:border-stroke-dark flex flex-col">
        <textarea
          v-model="comentary.body"
          placeholder="Escribe un comentario..."
          class="p-4 w-full resize-none bg-comentary-gray dark:bg-black-dark dark:text-white"
          cols="30"
          rows="4"
        />
        <div class="border-t flex items-center px-4 py-2 dark:border-stroke-dark">
          <button
            class="px-6 py-2 bg-blue-700 rounded-2xl text-white dark:bg-cian-500 dark:hover:bg-cian-600 transition-all duration-200"
            @click.prevent="addComentary()"
          >
            Publicar
          </button>
        </div>
      </div>
      <div class="mb-2 mt-6">
        <div class="flex flex-col gap-4">
          <div v-if="loadingComentaries" class="flex justify-center pt-10">
            <Spinner2 />
          </div>
          <div v-else v-for="(comentary, i) in comentaries" :key="i + 'Comentary'">
            <Comentary :comentary="comentary" @getComentaries="getComentaries" />
          </div>
        </div>
      </div>
      <!-- <Spinner2 v-if="loadingMoreComentaries" />
      <a v-else @click.prevent="getMoreComentaries()">
        Ver Mas...
      </a> -->
    </template>
  </AsideModal>
</template>
<script>
import { formatRut, formatNumber } from '@/utils/formatters'
import Spinner2 from '@/components/Spinner2.vue'
import Comentary from "./comentary"
import AsideModal from "@/components/modals/aside.vue"
import IconBancoSantander from '@/components/Icons/BancoSantander.vue'
import IconBancoDeChile from '@/components/Icons/BancoDeChile.vue'
import IconBancoEstado from '@/components/Icons/BancoEstado.vue'
import IconBancoBCI from '@/components/Icons/BancoBCI.vue'
import IconBancoItau from '@/components/Icons/BancoItau.vue'
import IconBancoSecurity from '@/components/Icons/BancoSecurity.vue'
import IconBancoBICE from '@/components/Icons/BancoBICE.vue'

export default {
  props: {
    transaction_id: { default: null },
  },
  data() {
    return {
      loading: true,
      transaction: null,
      offset: 0,
      comentary: {
        user_id: this.$auth.user().id,
        company_id: null,
        transaction_id: this.transaction_id,
        body: null,
      },
      comentaries: [],
      loadingComentaries: true,
      loadingMoreComentaries: false,
    }
  },
  components: {
    AsideModal,
    Comentary,
    Spinner2,
    IconBancoSantander,
    IconBancoDeChile,
    IconBancoEstado,
    IconBancoBCI,
    IconBancoItau,
    IconBancoSecurity,
    IconBancoBICE,
  },
  created() {
    this.setTransaction()
    this.getComentaries()
  },
  methods: {
    formatRut,
    formatNumber,
    closeModal(){
      this.$emit("getTransactions");
      this.$emit('close')
    },
    setTransaction() {
      const app = this;
      app.loading = true;
      this.axios.get("/transactions/" + app.transaction_id).then((response) => {
        app.transaction = response.data;
        app.comentary.company_id = response.data.company_id
        app.loading = false;
      });
    },
    addComentary() {
      this.axios
        .post("comentaries", this.comentary)
        .then(() => {
          this.getComentaries()
          this.comentary.body = null
        })
        .catch((err) => {
          console.log(err)
        });
    },
    getComentaries() {
      this.loadingComentaries = true
      this.axios.get("comentaries", {
        params: {
          user_id: this.$auth.user().id,
          transaction_id: this.transaction_id,
        }
      })
        .then((res) => {
          this.comentaries = res.data;
          this.offset += res.data.length;
          // this.comentaries = res.data
          this.loadingComentaries = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    getMoreComentaries() {
      this.loadingMoreComentaries = true
      this.axios.get("comentaries", {
        params: {
          user_id: this.$auth.user().id,
          transaction_id: this.transaction_id,
          offset: this.offset
        }
      })
        .then((res) => {
          this.comentaries = [...this.comentaries, ...res.data];
          this.offset += res.data.length;
          // this.comentaries = res.data
          this.loadingMoreComentaries = false
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
