<template>
  <div class="flex justify-center p-4">
    <div class="w-9/12 flex flex-col gap-4">
      <h2 class="text-4xl">Cymasuite Gpt</h2>
      <Icon v-if="loading" icon="svg-spinners:tadpole" class="animate-spin h-10 w-10" />
      <div v-else>
        <button @click="$refs.docFile.click()" class="button-cymasuite">
          Sube tu archivo
        </button>
        <div class="flex flex-row items-center">
          <input class="hidden" ref="docFile" id="docFile" @change="selectedFile" type="file" name="file" />
          <div class="flex flex-col">
            <p>{{ docName }}</p>
            <div v-if="docName" class="flex gap-2">
              <a @click.prevent="upload()">Enviar</a>
              <a @click.prevent="eliminateFile()">Cancelar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";
export default {
  data() {
    return {
      docName: '',
      loading: false,
      uploadFile: null,
    }
  },
  components: {
    Icon,
  },
  methods: {
    eliminateFile() {
      this.uploadFile = null;
      this.docName = "";
    },
    selectedFile(e) {
      //Save the information of the selected File
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.docName = files[0].name;
    },
    upload() {
      this.loading = true;
      //POST File using FormData
      let formData = new FormData();
      formData.append("file", this.uploadFile);
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.axios
        .post("/gpt-doc", formData, config)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  }
}
</script>
