<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div class="py-2 mx-4 sm:mx-6 lg:mr-0 xl:mr-6">
    <div class="flex flex-wrap items-center pb-2">
      <h1 class="flex-auto text-2xl font-semibold dark:text-white">
        Cuentas bancarias
      </h1>
    </div>
    <!-- <div>
      <a @click.prevent="updateAllBanks()" class="flex gap-2 items-center">Sincronizar todos los Banco</a>
    </div> -->
    <div class="flex flex-wrap gap-3 py-4 max-w-full">
      <div v-if="loading" class="w-full card px-4 h-64 bg-cyma-gray-card-light dark:bg-black-dark rounded-lg animate-pulse"></div>
      <template v-else>
        <div class="bg-black rounded-lg overflow-hidden sm:max-w-sm" v-for="(bank, i) in banks" :key="i">
          <div class="flex">
            <div class="w-full p-4">
              <div class="flex justify-between items-center text-white">
                <span class="text-3xl font-bold">
                    <span v-if="bank.currency == 'CLP'"> {{ formatNumber(bank.balance_available) }}</span>
                    <span v-if="bank.currency == 'USD'"> {{ formatNumberUsd(bank.balance_available) }}</span>
                  <small class="text-sm font-light"> {{ bank.currency }}</small>
                </span>
                <a v-on:click="deleteFunction(bank.id)" >
                  <Times/>
                </a>
              </div>
              <p class="text-white">Última sincronización: {{ formatCompleteDate(bank.updated_at) }}</p>
              <div class="flex justify-between items-center mt-10">
                <div class="flex flex-row"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> </div>
                <div class="flex flex-row"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> </div>
                <div class="flex flex-row"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> <img class="w-4 h-4" src="https://i.imgur.com/FNef1H8.png"> </div>
                <div class="flex flex-row">
                  <span class="text-white text-lg mr-1 font-bold tracking-widest">{{ bank.number.slice(-4) }}</span>
                </div>
              </div>
              <div class="mt-8 flex justify-between items-center text-white">
                <div class="flex flex-col">
                  <span class="font-bold text-gray-300 text-sm">Cuenta {{ bank.official_name }}</span>
                  <span class="font-bold text-gray-300 text-sm">{{ bank.bank.institution_country }}</span>
                  <span class="capitalize font-bold ">{{ bank.holder_name }}</span>
                </div>

                <div v-if="bank.bank.institution_country === 'Ventipay'">
                  <svg class="w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
                    <defs>
                      <clipPath id="a">
                        <path d="M0 0h210v55H0z"/>
                      </clipPath>
                    </defs>
                    <g clip-path="url(#a)" data-name="Group 120">
                      <path fill="#4c64f4" d="M26.379 51.482a4.841 4.841 0 0 1-4.646-3.332L5.465 9.916A4.605 4.605 0 0 1 5 7.992 4.03 4.03 0 0 1 9.12 4a4.282 4.282 0 0 1 4.18 2.925l13.413 33.53L40.326 6.661A4.198 4.198 0 0 1 44.24 4a3.962 3.962 0 0 1 4.054 3.927 4.766 4.766 0 0 1-.4 1.719L31.557 48.15a4.864 4.864 0 0 1-4.715 3.332h-.463Z" data-name="Union 1"/>
                    </g>
                  </svg>
                </div>
                <!-- Flow -->
                <div v-if="bank.bank.institution_country === 'Flow'" class="p-2 bg-gray-300 rounded-sm">
                  <IconFlow class="w-10 h-auto" />
                </div>
                <!-- Mercado Pago -->
                <div v-if="bank.bank.institution_country === 'Mercadopago'">
                  <IconMercadoPago />
                </div>
                <!-- Santander -->
                <div v-if="bank.bank.institution_country === 'Banco Santander'">
                  <IconBancoSantander class="h-10 w-10" />
                </div>
                <!-- Banco Chile -->
                <div v-if="bank.bank.institution_country === 'Banco de Chile'">
                  <IconBancoDeChile class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Estado'">
                  <IconBancoEstado class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco BCI'">
                  <IconBancoBCI class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Itaú'">
                  <IconBancoItau class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Security'">
                  <IconBancoSecurity class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco BICE'">
                  <IconBancoBICE class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Scotiabank'">
                  <IconBancoScotiabank class="h-10 w-10" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import { formatNumber, formatNumberUsd, formatCompleteDate } from '@/utils/formatters'
  import DatePicker from "vue2-datepicker";
  import Times from '@/components/Icons/Times.vue';
  import IconMercadoPago from '@/components/Icons/MercadoPago.vue';
  import IconBancoSantander from '@/components/Icons/BancoSantander.vue';
  import IconBancoDeChile from '@/components/Icons/BancoDeChile.vue';
  import IconBancoEstado from '@/components/Icons/BancoEstado.vue';
  import IconBancoBCI from '@/components/Icons/BancoBCI.vue';
  import IconBancoItau from '@/components/Icons/BancoItau.vue';
  import IconBancoSecurity from '@/components/Icons/BancoSecurity.vue';
  import IconBancoBICE from '@/components/Icons/BancoBICE.vue';
  import IconBancoScotiabank from '@/components/Icons/BancoScotiabank.vue';
  import IconFlow from '@/components/Icons/Flow.vue';

  export default {
    name: "banks",
    props: {},
    data() {
      return {
        showModal: false,
        id: null,
        action: null,
        without_account: 0,
        columns: [],
        banks: [],
        loading: true,
      };
    },
    components: {
      DatePicker,
      Times,
      IconMercadoPago,
      IconBancoSantander,
      IconBancoDeChile,
      IconBancoEstado,
      IconBancoBCI,
      IconBancoItau,
      IconBancoSecurity,
      IconBancoBICE,
      IconBancoScotiabank,
      IconFlow,
    },
    created() {
      this.getBankBills();
      let user_id = this.$auth.user().id;
    },
    computed: {
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      },
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
    },
    watch: {
      company_id() {
        this.reload();
      },
    },
    methods: {
      formatNumber,
      formatNumberUsd,
      formatCompleteDate,
      openFintoc() {
        let cur = this;
        let user_id = this.$auth.user().id;
        this.widget = Fintoc.create({
          holderType: "business",
          product: "movements",
          publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
          webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${cur.company_id}_${user_id}`,
        });
        this.widget.open();
      },
      reload() {
        this.getBankBills();
      },
      updateCron(bank_bill_id) {
        let selectedBankBill = this.banks.find((e) => e.id == bank_bill_id)
        this.$swal
          .fire({
            title: "¿Estas seguro?",
            text: "Vas a sincronizar toda la información de Bancos.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sincronizar",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value) {
              this.$swal.fire(
                "Actualizado",
                "Tu banco ha sido sincronizado.",
                "success"
              );
              selectedBankBill.status_conection = 'waiting'
              this.axios.post("/company/update_bank", { bank_bill_id: bank_bill_id }).then(() => {
                this.getBankBills();
              })
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "Tu banco no ha sido sincronizado.",
                "error"
              );
            }
          })
      },
      getBankBills() {
        let app = this;
        app.loading = true;
        this.axios
          .get("/bank_bills", {
            params: {
              company_id: app.company_id,
              page: app.current_page, // will_paginate
              per_page: app.per_page
            }
          }).then((response) => {
            app.banks = response.data.data;
          }).catch((err) => {
            console.error(err)
          })
          .finally(() => {
            app.loading = false;
          });
      },
      deleteFunction(id) {
      // /movements/:id
        this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No recuperarás esta cuenta bancaria",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminalo",
            cancelButtonText: "No, conservalo",
          })
          .then((result) => {
            if (result.value) {
              this.$swal.fire(
                "Eliminado",
                "Tu egreso ha sido eliminado",
                "success"
              );
              this.axios.delete(`bank_bills/${id}`).then(() => this.getBankBills());
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "Tu egreso no ha sido eliminado",
                "error"
              );
            }
          });
      },
    },
  };
</script>

<style scoped>
.card {
  max-width: 356px;
}
</style>
