<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Datos de la empresa
    </h2>
    <div class="grid gap-4 my-4 md:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Nombre</label
        >
        <input
          type="text"
          disabled
          v-model="company.business_name"
          class="input-text"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          RUT
        </label>

        <input
          v-model="rutctnr"
          disabled
          name="rut"
          ref="rut"
          id="rut"
          type="text"
          placeholder="12.345.678-0"
          required
          maxlength="12"
          class="input-text"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Actividad economica
        </label>
        <input
          disabled
          class="input-text"
          type="text"
          v-model="company.activity_gloss"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Dirección casa matriz SII
        </label>
        <input
          class="input-text"
          type="text"
          v-model="company.directorate_operational_unit"
          disabled
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Dirección
        </label>
        <div
          class="input-text dark:text-cyma-icon-gray dark:bg-background-dark-disabled disabled"
          v-for="(address, index) in company.addresses"
          :key="index"
          disabled
        >
          <p>{{ address.street }} {{ address.number }}, {{ address.city }}</p>
        </div>
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Sucursales
        </label>
        <input
          class="input-text"
          type="text"
          disabled
          v-model="company.operational_unit_description"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Sistema Tributario
        </label>
        <input
          class="input-text"
          type="text"
          disabled
          v-model="company.subtype_taxpayer_descrip"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Inicio de actividades</label
        >
        <date-picker
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.80rem',
            'padding-bottom': '0.80rem',
            'border-radius': '0rem'
          }"
          format='DD-MM-YYYY'
          v-model="company.start_date_activities"
          input-class="xmx-input"
          type="date"
          disabled
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
      <div class="flex flex-col items-center justify-center w-full my-2">
        <input
          class="w-full dark:text-white"
          accept="image/*"
          type="file"
          @change="uploadFile"
          ref="inputFile"
        />
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import { formatRut } from '@/utils/formatters'
import Spinner from '@/components/Spinner2.vue';
import DatePicker from "vue2-datepicker";
export default {
  name: "CompanyData",
  components: {
    Spinner,
    DatePicker
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      rut: this.company.rut || '',
      dv: this.company.dv || '',
      rutctnr: ''
    }
  },
  created() {
    this.$emit('updateTab', "CompanyData")
    this.rutctnr = this.formatRut(this.company.rut + '-' + this.company.dv)
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    formatRut,
    cleanRut(value) {
      if (value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase()
      }
    },
    uploadFile(e) {
      // console.log("inputfile", this.$refs.inputFile)
      this.company.logo_url = this.$refs.inputFile.files[0]
      const file = e.target.files[0]
      this.defaultImage = URL.createObjectURL(file)
    },
    updateImage(id, img) {
      let data = new FormData();
      data.append("logo", img);
      data.append("id", id);
      this.axios
        .patch("company/update_logo", data)
        .then(()=> {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
              );
        })
        .catch((e) => console.log(e))
    },
    update() {
      this.loading = true,
      this.axios
      .patch("companies/" + this.company_id, this.company)
      .then(() => {
        if(this.company.logo_url) {
          this.updateImage(this.company_id, this.company.logo_url)
        } else{
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
              );
            }
          // this.$toast.success('Guardado Exitosamente', 'OK', {
          //   theme: 'dark',
          //   position: "topRight",
          //   timeout: 2000,
          //   backgroundColor: '#4c1760',
          // });
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>
