<template>
  <div class="relative overflow-hidden flex-1 bg-white h-auto">
    <section class="relative mx-auto pb-3 flex flex-col md:flex-row gap-5">
      <div
        class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-5/12 flex flex-col bg-cyma-purple-200 order-2 lg:order-none dark:bg-background-dark min-h-screen"
      >
        <div class="max-w-sm mx-auto">
          <header class="flex justify-center items-center mb-12">
            <button
              href="#"
              @click.prevent="$router.go()"
              class="block p-4 lg:p-6 outline-none border-transparent appearance-none focus:outline-none"
            >
              <img
                src="https://ptishwor.sirv.com/cyma-imgs/logo.png?format=webp"
                width="220"
                height="48"
                style="aspect-ratio: 151/29;"
                alt="cymasuite logo"
              />
            </button>
          </header>
          <div class="text-base text-center dark:text-gray-200">
            <p>
              Presentamos el contrato de servicios que establece los términos y condiciones de nuestra colaboración, detallando los servicios ofrecidos para satisfacer tus necesidades.
            </p>
          </div>
          <img
            src="@/assets/images/onboarding-step4.svg"
            alt="Ilustración de una persona con un escudo de seguridad"
            class="w-10/12 mx-auto mb-5 select-none pointer-events-none mt-16 sm:mb-12"
            style="aspect-ratio: 137/68;"
          >
          <div>
            <p class="text-base dark:text-gray-200 text-center mb-5">
              Puedes disfrutar aún más de nuestros servicios descargando nuestra aplicación móvil
            </p>
            <div class="flex justify-center mb-5">
              <Icon icon="heroicons:chevron-double-down" class="w-8 h-8 text-cyma-purple" />
            </div>
            <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="https://apps.apple.com/us/app/cymasuite/id1582243635" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:apple" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Download on the</div>
                  <div class="font-sans text-sm font-semibold">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gonozer.cymasuitemobile&hl=es_CL&gl=US" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:google-play" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Get in on</div>
                  <div class="font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-7/12 order-1 lg:order-none">
        <!-- Contrato de prestación de servicio -->
        <div class="max-w-xl mx-auto">
          <h2 class="pt-20 uppercase text-2xl font-yesevaOne font-bold mb-4 text-cyma-purple dark:text-white">
            Contrato de prestación de servicio
          </h2>
          <TermsAndConditions
            @cancel="$emit('next-step', 'configuration_step')"
            @accept-contract="finishConfig"
            @update-navbar="updateNavbar"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue2";
import TermsAndConditions from "./components/TermsAndConditions.vue";

export default {
  name: "ContractStep",
  components: {
    Icon,
    TermsAndConditions,
  },
  data() {
    return {};
  },
  computed: {
    hasContractService() {
      return this.$auth.user().contract_service;
    },
  },
  created() {
    if(this.hasContractService) {
      this.finishConfig()
    }
  },
  methods: {
    finishConfig() {
      this.$emit("next-step", 'summary_step');
      this.updateSiiStep()
    },
    updateNavbar() {
      this.$emit("update-navbar")
    },
    updateSiiStep(id) {
      if (!id) {
        id = this.$cookie.get("sii_config_id");
      }
      this.axios.patch("/sii_configs/" + id, {
        step: 3,
      });
    },
  },
};
</script>
