<template>
  <div class="rounded-lg h-10 w-10 flex justify-center items-center">
    <img :src="src" :alt="value">
  </div>
</template>
<script>

export default {
  name: "Icons",
  props: ["value"],
  data() {
    return {
      src: "",
    }
  },
  mounted() {
    this.handleValue(this.value)
  },
  methods:{
    switchIcon(icon) {
      this.src = require('@/assets/icons/'+icon+'.svg');
    },
    handleValue(icon) {
      switch (icon) {
        case "CLIENTES NACIONALES":
          this.switchIcon('user-tick')
          break;
        case "MATERIALES":
          this.switchIcon("ruler&pen")
          break;
        case "MERCADERIAS":
          this.switchIcon("shopping-cart")
          break;
        case "MANTENC. EQUIP. Y EDIFICIOS":
          this.switchIcon("group-3")
          break;
        case "GASTOS GENERALES":
          this.switchIcon("box")
          break;
        case "GASTOS DE OFICINA":
          this.switchIcon("buildings")
          break;
        case "HONORARIOS PROFESIONALES":
          this.switchIcon("personalcard")
          break;
        case "PUBLICIDAD":
          this.switchIcon("like-dislike")
          break;
        case "TRANSPORTE":
          this.switchIcon("airplane")
          break;
        case "COMBUSTIBLES ":
          this.switchIcon("gas-station")
          break;
        case "SISTEMAS INFORMATICOS":
          this.switchIcon("cpu")
          break;
        case "GASTOS BANCARIOS":
          this.switchIcon("bank")
          break;
        case "ASESORIAS CONTABLES Y TRIBUTARIAS":
          this.switchIcon("math")
          break;
        case "COMBUSTIBLES Y LUBRICANTES":
          this.switchIcon("gas-station")
          break;
        case "SERVICIOS PROFESIONALES":
          this.switchIcon("profile-2user")
          break;
        case "TELEFONOS":
          this.switchIcon("call-calling")
          break;
        case "SEGUROS DE PRODUCCION":
          this.switchIcon("safe-home")
          break;
        case "OTROS GASTOS DE REMUNERACIONES":
          this.switchIcon("user-tick")
          break;
        case "MAQUINARIAS":
          this.switchIcon("group-7")
          break;
        case "MANT VEHICULOS Y EQ TRANSP":
          this.switchIcon("group-7")
          break;
        case "SEGUROS":
          this.switchIcon("security-user")
          break;
        case "OTROS GASTOS VENTAS":
          this.switchIcon("bag-happy")
          break;
        case "ARRIENDO MAQUINAS Y EQUIPOS":
          this.switchIcon("group-6")
          break;
        case "INTERNET":
          this.switchIcon("global")
          break;
        case "COSTO DE VENTA":
          this.switchIcon("bag-2")
          break;
        case "OTROS GASTOS DE EXPLOTACION":
          this.switchIcon("box")
          break;
        case "SOFTWARE":
          this.switchIcon("monitor")
          break;
        case "EXISTENCIAS":
          this.switchIcon("briefcase")
          break;
        case "MANTENCION MAQUINARIAS":
          this.switchIcon("gas-station")
          break;
        case "COMISIONES VENDEDORES":
          this.switchIcon("group")
          break;
        case "ARRIENDO DE OFICINA":
          this.switchIcon("house-2")
          break;
        case "ACCIONES CAPITAL SUSCRITO":
          this.switchIcon("")
          break;
        case "ASESORIAS JURIDICAS":
          this.switchIcon("judge")
          break;
        case "GASTOS LEGALES Y NOTARIALES":
          this.switchIcon("judge")
          break;
        case "MUEBLES UTILES E INSTALACIONES":
          this.switchIcon("lamp-1")
          break;
        case "GASTOS DESPACHO":
          this.switchIcon("math")
          break;
        case "VEHICULOS":
          this.switchIcon("car")
          break;
        case "ASESORIAS FINANCIERA":
          this.switchIcon("frame-5")
          break;
        case "MATERIAS PRIMAS":
          this.switchIcon("shop")
          break;
        case "BANCO":
          this.switchIcon("bank")
          break;
        case "Banco Chile":
          this.switchIcon("bank")
          break;
        case "GASTOS CAPACIT. PERSONAL":
          this.switchIcon("user-square")
          break;
        case "GASTOS LABORATORIO":
          this.switchIcon("glass")
          break;
        case "ACCIONES SUSCRITAS":
          this.switchIcon("card-pos")
          break;
        case "SERVICIOS COMPUTACIONALES":
          this.switchIcon("monitor-mobbile")
          break;
        case "EGRESOS NO OPERACIONALES":
          this.switchIcon("more-2")
          break;
        case "GASTOS BODEGA":
          this.switchIcon("group-7")
          break;
        case "EFECTIVO":
          this.switchIcon("money-recive")
          break;
        case "IVA CREDITO FISCAL":
          this.switchIcon("money-send")
          break;
        case "CAPITAL PROPIO INICIAL":
          this.switchIcon("money-send")
          break;
        case "UTILIDAD (PERDIDA) EJERC.":
          this.switchIcon("money-send")
          break;
        case "RETENCIONES PROFESIONALES":
          this.switchIcon("profile-2user")
          break;
        case "UTILIDADES O PERDIDAS ACUMULADAS (-)":
          this.switchIcon("calculator")
          break;
        case "REVALORIZAC CAPITAL PROPIO":
          this.switchIcon("money-send")
          break;
        case "PROVISION PPM":
          this.switchIcon("fatrows")
          break;
        case " FORMULARIO 29 POR PAGAR":
          this.switchIcon("fatrows")
          break;
        case "GASTOS COBRANZA":
          this.switchIcon("card-pos")
          break;
        case "GASTOS DE RECURSOS HUMANOS":
          this.switchIcon("profile-2user")
          break;
        case "GANADO":
          this.switchIcon("status-up")
          break;
        case "VENTAS":
          this.switchIcon("shop")
          break;
        case "ENERGIA DE PRODUCCION":
          this.switchIcon("status")
          break;
        case "GASTOS DE REPRESENTACION":
          this.switchIcon("user-tag")
          break;
        case "REMUNERACIONES":
          this.switchIcon("user-tag")
          break;
        case "HERRAMIENTAS Y ENSERES":
          this.switchIcon("brush-3")
          break;
        case "OTRAS CONSTRUCCIONES":
          this.switchIcon("brush-2")
          break;
        case "CORRECION MONETARIA":
          this.switchIcon("money-tick")
          break;
        case "IMPUESTOS NO RECUPERABLES":
          this.switchIcon("money-send")
          break;
        case "IVA DEBITO FISCAL":
          this.switchIcon("money-send")
          break;
        case "IMPUESTO UNICO TRABAJADORES":
          this.switchIcon("money-send")
          break;
        case "LEYES SOCIALES POR PAGAR":
          this.switchIcon("money-send")
          break;
        case "SEGUROS DE TRANSPORTES":
          this.switchIcon("driving")
          break;
        case "RETIRO SOCIOS":
          this.switchIcon("dollar-circle")
          break;
        case "PAGOS PROVISIONALES":
          this.switchIcon("wallet-money")
          break;
        case " IVA CREDITO FISCAL":
          this.switchIcon("money-send")
          break;
        case "FORMULARIO 29 POR PAGAR":
          this.switchIcon("money-send")
          break;
        case "previred":
          this.switchIcon("user-tag")
          break;
        case "form_f29":
          this.switchIcon("F29")
          break;
        case "form_22":
          this.switchIcon("F22")
          break;
        default:
          this.switchIcon("wallet-1")
          break;
      }
    }
  }
}
</script>
