var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 mx-6"},[_c('div',{staticClass:"flex flex-wrap"},[_c('h1',{staticClass:"flex-auto text-xl font-semibold dark:text-white"},[_vm._v("Indicadores financieros")]),_c('div',{staticClass:"flex items-center gap-4"},[_c('div',{staticClass:"filter"},[_c('v-select',{staticClass:"style-chooser custom-border",attrs:{"options":_vm.options_exchange_rate,"reduce":(type) => type.value,"label":"name"},model:{value:(_vm.exchange_rate),callback:function ($$v) {_vm.exchange_rate=$$v},expression:"exchange_rate"}})],1),_c('div',{staticClass:"filter"},[_c('date-picker',{style:({
            width: '100%',
            'border-radius': '1px',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.50rem',
            'padding-bottom': '0.50rem',
          }),attrs:{"input-class":"xmx-input","type":"date","format":"DD-MM-YYYY","range":"","placeholder":"Seleccionar un rango de Fecha"},on:{"input":_vm.getCurrencies},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)])]),_c('br'),_c('BeeGridTable',{attrs:{"border":"","height":"560","showFilter":false,"showSummary":false,"columns":_vm.columns,"data":_vm.currencies,"loading":_vm.loading}},[_c('BeeColumn',{attrs:{"field":"name","title":"Nombre","type":"text"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.name)+" ")]}}])}),_c('BeeColumn',{attrs:{"field":"date","title":"Fecha","type":"date"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.date)+" ")]}}])}),_c('BeeColumn',{attrs:{"field":"price","title":"Precio","type":"date"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.price)+" ")]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }