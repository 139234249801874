<template>
  <div>
    <form>
      <label class="mx-2 pt-4 text-sm text-gray-500 dark:text-white flex gap-1 items-center">
        <svg v-if="movement.recommended" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#d1d5db" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
        </svg>
        Clasificación
        <span class="underline" v-if="movement.recommended">recomendada</span>
      </label>
      <div class="form-group" v-for="(input, k) in this.$v.dataAcc.$each.$iter" :key="k">
        <div
          class="grid grid-cols-2 lg:grid-cols-3 justify-center"
          id="form"
        >
          <div class="w-full my-2 mx-0 lg:mx-2 col-span-2 lg:col-span-1">
            <v-select
              class="style-chooser custom-border v-select"
              :options="accounts"
              :reduce="(account) => account.id"
              label="fantasy_name"
              @input="setDataMovement($event)"
              v-model="input.account_id.$model"
              placeholder="Plan de cuenta"
            ></v-select>
            <div class="text_error" v-if="input.account_id.$error">Este campo es requerido.</div>
          </div>
          <div class="mx-1 w-full flex items-center col-span-2">
            <range-slider
              class="slider w-3/4 mr-2 md:mx-8"
              :min="input.min.$model"
              :max="input.max.$model"
              :disabled="input.disable.$model"
              @change="calculatePorcentaje($event, k)"
              v-model="input.total_amount.$model"
            >
            </range-slider>
            <currency-input
              class="w-1/4 appearance-none float-right bg-grey-lighter text-grey-darker rounded py-3 dark:bg-background-dark dark:text-white"
              @input.prevent="calculatePorcentaje($event, k)"
              v-model="input.total_amount.$model"
              :disabled="input.disable.$model"
              currency="CLP"
              locale="es-CL"
            />
            <p class="mx-2 w-1/6 appearance-none block bg-grey-lighter text-gray-500 py-3 dark:text-cian-dark">{{input.percentage.$model}} %</p>
          </div>
          <div v-if="k == dataAcc.length - 1 &&  k!= 0">
            <a class="mx-2" @click.prevent="remove(k)">
              Eliminar
            </a>
          </div>
        </div>
      </div>
    </form>
    <a v-if="balance > 0" @click.prevent="add" class="my-2 h-10 mx-2 w-5/12">
        Dividir en más
      </a>
    <div class="mt-10 grid grid-cols-2 border-t dark:border-black-dark">
      <Spinner v-if="loadingButton" class="col-start-2 mx-2 justify-self-end mt-4">
      </Spinner>
      <button
        v-else
        @click.prevent="submit"
        :class="{'opacity-50 cursor-not-allowed': !validate_amount}"
        class="col-start-2 mx-2 h-10 border bg-black text-white w-40 justify-self-end mt-4 dark:bg-cian-dark rounded-full dark:border-cian-dark"
      >
        Clasificar
      </button>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/Spinner2.vue";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from 'vuelidate/lib/validators'

export default {
  name: "addAccountMovementModal",
  props: {
    dataAcc: Array,
    neto: Number,
    movementId: Number,
    movement: Object,
    ids: Array,
    type_movement: String,
    accounts: Array,
    loadingButton: Boolean,
  },
  components: {
    RangeSlider,
    Spinner
  },
  data() {
    return {
      isInputActive: false,
      balance: 0,
      showAcc: false,
      showAmount: false,
      validate_amount: false
    };
  },
  validations: {
    dataAcc: {
      required,
      $each: {
        min:{},
        max:{},
        account_id: {
          required,
        },
        total_amount:{
        },
        percentage:{},
        disable: {}
      }
    }
  },
  created(){
    this.validateTotalAmount()
    this.dataAcc.map(e => {
      e.disable = true
    })
    let lenght = this.dataAcc.length
    let last_value = this.dataAcc[lenght - 1]
    last_value.disable = false
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    disabledInputs(){
      this.dataAcc.map(e => {
      e.disable = true
      })
      let lenght = this.dataAcc.length
      let last_value = this.dataAcc[lenght - 1]
      last_value.disable = false
    },
    validateTotalAmount(){
      let initialValue = 0
      let amount = this.dataAcc.reduce((acc, v) => {
        return acc + v.total_amount
      }, initialValue)
      this.validate_amount = this.neto - amount == 0
    },
    add() {
      let cur = this;
      let lastMovement = cur.dataAcc[cur.ids[cur.ids.length - 1]]
      lastMovement.disable = true;
      cur.dataAcc.push({
        date_sii: new Date().toJSON().slice(0, 10).replace(/-/g, "/"),
        company_id: this.company_id,
        branch_office_id: null,
        cost_center_id: null,
        min: 0,
        max: cur.balance,
        disable: false,
        validate: false,
        divide: null,
        percentage: 0,
        total_amount: 0,
        state: "exempt_amount",
        movement_id: cur.movementId,
        account_id: null,
        type_movement: cur.type_movement,
        type_account_movement: "record",
      });
      this.$emit("addAttribute", cur.dataAcc);
      this.validateTotalAmount()
      this.disabledInputs();
    },
    submit(){
      if(this.validate_amount){
        this.$emit('submit')
      }
    },
    setDataMovement(event) {
      this.$emit("addVendorAccountId", event);
    },
    calculatePorcentaje(value, id) {
      let cur = this;
      let porcentaje = (value / cur.neto) * 100;
      this.dataAcc[id].percentage = Math.round(porcentaje, 2);
      this.calculateBalance(value)
      this.validateTotalAmount()
    },
    calculateBalance(value) {
      let cur = this;
      let lastAccountMovements = cur.dataAcc.slice(0, cur.dataAcc.length);
      let sumAccountMovements = lastAccountMovements.reduce((acc,e)=> acc + e.total_amount, 0);
      if(cur.dataAcc.length > 1) {
        cur.balance = cur.neto - sumAccountMovements;
      } else {
        cur.balance = cur.neto - value;
      }
    },
    remove(index) {
      this.dataAcc.splice(index, 1);
      this.validateTotalAmount()
      this.disabledInputs();
    },
  },
};
</script>
<style scoped lang="scss">

.v-select {
  --vs-dropdown-option--active-bg: #4c1760;
  --vs-selected-padding: 0.5rem;
  --vs-dropdown-min-width: 420px;
  --vs-dropdown-max-height: 200px;
  /* --vs-dropdown-option-padding: 3px 1px; */
}
 .v-select::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px grey; */
  border-radius: 10px;
}

    /* Handle */
 .v-select::-webkit-scrollbar-thumb {
  background: #4c1760;/*rgb(7, 7, 7);*/
  border-radius: 10px;
}


.range-slider-fill {
  background-color: #4c1760;
}
</style>
