<template  >
  <div class="py-2 mx-6">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold dark:text-white">Integraciones</h1>
    </div>
    <h2 class="text-lg font-semibold dark:text-gray-200 mt-5 mb-4">Sistema de impuesto interno</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Company -->
      <CardIntegration
        title="Datos Generales de Empresa"
        description="Obtén datos de los contribuyentes, su situación tributaria, su autorización de DTEs, los usuarios y más."
        :object="company"
        cronType="company"
        @reload="getDataCompany()"
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>
      <!-- Sale and Buys -->
      <CardIntegration
        title="Registro Compra y Venta"
        description="Accede al registro oficial del SII de compras y ventas. El único registro legal y válido para los impuestos."
        :object="sale_and_buy"
        cronType="sale_and_buy"
        @reload="getDataSaleAndBuy()"
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>
      <CardIntegration
        title="Registro Solo Compra"
        description="Accede al registro oficial del Servicio de Impuesto Interno (SII) de solo compras. El único registro legal y válido para los impuestos."
        :object="buy"
        cronType="buy"
        @reload="getDataBuy()"
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>
      <CardIntegration
        title="Registro Solo Venta"
        description="Accede al registro oficial del Servicio de Impuesto Interno (SII) de solo ventas. El único registro legal y válido para los impuestos."
        :object="sale"
        cronType="sale"
        @reload="getDataSale()"
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>
      <!-- Honorary -->
      <CardIntegration
        title="Boletas de Honorarios"
        description="Emite, revisa y anula tanto boletas de honorarios como boletas de terceros para servicios de personas."
        :object="honorary"
        cronType="honorary"
        @reload="getDataHonorary()"
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>
      <!-- Document XML -->
      <CardIntegration
        title="Document XML"
        description="Descarga los documentos XML de tus facturas, boletas y notas de crédito."
        :object="document_xml"
        cronType="document_xml"
        @reload="getDataDocumentXML()">
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>

      <!-- F29 -->
      <CardIntegration
        title="Formulario 29"
        description="El Formulario 29 corresponde a las declaraciones de carácter mensual de impuestos que, legalmente, deben ser retenidos y enterados en arcas fiscales."
        :object="f29"
        cronType="f29"
        @reload="getDataF29()">
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>

      <!-- F22 -->
      <!-- v-if="f22" -->
      <CardIntegration
        title="Formulario 22"
        description="El Formulario 22 permite a contribuyentes, empresas y personas, declarar sus rentas o ingresos anuales."
        :object="f22"
        cronType="f22"
        @reload="getDataF22()">
      >
        <template #icon>
          <SiiIcon class="text-cyma-green-icon"/>
        </template>
      </CardIntegration>

      <!-- F50 -->
      <!-- v-if="f50" -->
      <CardIntegration
        title="Formulario 50"
        description="Declaración Mensual y Pago Simultáneo de Impuestos"
        :object="f50"
        cronType="f50"
        @reload="getDataF50()">
      >
      <template #icon>
        <SiiIcon class="text-cyma-green-icon"/>
      </template>
      </CardIntegration>
    </div>

    <h2 class="text-lg font-semibold dark:text-gray-200 mt-8 mb-4" v-if="banks.length != 0" >Integraciones con bancos</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Fintoc -->
      <template v-for="(bank, i) in banks">
        <CardIntegration
          v-if="bank"
          :title=bank.job
          description="Fintoc nos permite acceder a tu información bancaria."
          :object="bank"
          cronType="fintoc"
          @reload="getDataBank()"
          :key="i"
        >
          <template #icon>
            <!-- Banco Santander -->
            <IconBancoSantander v-if="bank.job === 'Banco Santander'" />
            <!-- Banco Chile -->
            <IconBancoDeChile v-if="bank.job === 'Banco de Chile'" class="w-10 h-10" />
            <!-- Banco estado -->
            <IconBancoEstado v-if="bank.job === 'Banco Estado'" class="w-10 h-10" />
            <!-- Banco BCI -->
            <IconBancoBCI v-if="bank.job === 'Banco BCI'" class="w-10 h-10" />
            <!-- Banco Itaú -->
            <IconBancoItau v-if="bank.job === 'Banco Itaú'" class="w-10 h-10" />
            <!-- Banco security -->
            <IconBancoSecurity v-if="bank.job === 'Banco Security'" class="w-10 h-10" />
            <!-- Banco BICE -->
            <IconBancoBICE v-if="bank.job === 'Banco BICE'" class="w-10 h-10" />
            <!-- Banco Scotiabank -->
            <IconBancoScotiabank v-if="bank.job === 'Banco Scotiabank'" class="h-10 w-10" />
          </template>
        </CardIntegration>
      </template>
    </div>

    <h2 class="text-lg font-semibold dark:text-gray-200 mt-8 mb-4">Integraciones con plataformas de pago
    </h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Flow -->
      <CardIntegration
        title="Flow"
        description="Pagos monederos electrónicos — La forma de vender cambio, cambia tu también y comienza a vender online."
        :object="flow"
        cronType="flow"
        @reload="getDataFlow()"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="135" height="80" viewBox="0 0 135 80" fill="none" class="w-12 h-auto">
            <path d="M77.1895 77.3333L54.2561 6.40002C53.1895 3.20002 55.5895 1.28766e-05 58.9228 1.28766e-05H103.056C106.389 1.28766e-05 108.789 3.20002 107.723 6.40002L84.6561 77.3333C83.4561 80.8 78.3895 80.8 77.1895 77.3333Z" fill="#4CC8EF"/>
            <path d="M23.3229 77.3333L0.256257 6.40002C-0.810409 3.20002 1.58959 1.28766e-05 4.92292 1.28766e-05H49.0563C52.3896 1.28766e-05 54.7896 3.20002 53.7229 6.40002L30.6563 77.3333C29.5896 80.8 24.5229 80.8 23.3229 77.3333Z" fill="#4CC8EF"/>
            <path d="M104.256 77.3334L81.1892 6.40009C80.1225 3.20009 82.5225 9.12299e-05 85.8558 9.12299e-05H129.989C133.323 9.12299e-05 135.723 3.20009 134.656 6.40009L111.589 77.2001C110.523 80.8001 105.323 80.8001 104.256 77.3334Z" fill="#111111"/>
            <path d="M50.2559 77.3333L27.1892 6.40002C26.1225 3.20002 28.5225 1.28766e-05 31.8559 1.28766e-05H75.9892C79.3225 1.28766e-05 81.7225 3.20002 80.6559 6.40002L57.7225 77.3333C56.5225 80.8 51.4559 80.8 50.2559 77.3333Z" fill="#111111"/>
          </svg>
        </template>
      </CardIntegration>
      <!-- Mercado Pago -->
      <CardIntegration
        title="Mercado Pago"
        description="Cobra con Mercado Pago — Tus clientes no necesitan una cuenta en Mercado Pago."
        :object="mercado_pago"
        cronType="mercado_pago"
        @reload="getDataMercadoPago()">
        <template #icon>
          <IconMercadoPago class="w-12 h-auto"/>
        </template>
      </CardIntegration>
      <!-- Ventipay -->
      <CardIntegration
        title="Ventipay"
        description="VentiPay te permite aceptar pagos con débito en cuotas, crédito en cuotas y transferencias electrónicas en tu ecommerce."
        :object="ventipay"
        cronType="ventipay"
        @reload="getDataVentipay()">
      >
        <template #icon>
          <svg class="w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
            <defs>
              <clipPath id="a">
                <path d="M0 0h210v55H0z"/>
              </clipPath>
            </defs>
            <g clip-path="url(#a)" data-name="Group 120">
              <path fill="#4c64f4" d="M26.379 51.482a4.841 4.841 0 0 1-4.646-3.332L5.465 9.916A4.605 4.605 0 0 1 5 7.992 4.03 4.03 0 0 1 9.12 4a4.282 4.282 0 0 1 4.18 2.925l13.413 33.53L40.326 6.661A4.198 4.198 0 0 1 44.24 4a3.962 3.962 0 0 1 4.054 3.927 4.766 4.766 0 0 1-.4 1.719L31.557 48.15a4.864 4.864 0 0 1-4.715 3.332h-.463Z" data-name="Union 1"/>
            </g>
          </svg>
        </template>
      </CardIntegration>
    </div>

    <h2 class="text-lg font-semibold dark:text-gray-200 mt-8 mb-4">Control de inventario</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Bsale -->
      <CardIntegration
        title="Bsale"
        description="Bsale es un software de venta con boleta electrónica pensado para las Pymes."
        :object="bsale"
        cronType="bsale"
        @reload="getDataBsale()">
      >
        <template #icon>
          <IconBSale />
        </template>
      </CardIntegration>
    </div>

    <h2 class="text-lg font-semibold dark:text-gray-200 mt-8 mb-4">Recursos Humanos</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Talana -->
      <CardIntegration
        title="Talana"
        description="Talana es una plataforma que busca digitalizar las áreas Recursos Humanos."
        :object="talana"
        cronType="talana"
        @reload="getDataTalana()"
      >
        <template #icon>
          <IconTalana />
        </template>
      </CardIntegration>
      <!-- Previred -->
      <CardIntegration
        title="Previred"
        description="Plataforma que permite a los empleadores realizar la declaración y pago electrónico de las cotizaciones previsionales de sus trabajadores."
        :object="previred"
        cronType="previred"
        @reload="getDataPrevired()"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 1575.2 205.5" style="enable-background:new 0 0 1575.2 205.5;" xml:space="preserve">
            <g id="a">
            </g>
            <g id="b">
              <g id="c">
                <g>
                  <g>
                    <path style="fill: #502D7D;" d="M1213,87.1c4.8-19.2,2.5-40.8-9-57.3c-6.6-9.5-16.8-16.4-28.2-18.7c-15.5-2.7-31.2-3.4-46.9-3.2      c-27,0-53.9,0-80.9,0c0,63,0,126.1,0,189.1c17.3,0,34.6,0,51.8,0c-0.1-22.7,0.1-45.4-0.1-68.2c10.6,0,21.1,0,31.7,0      c13,22.7,26.2,45.3,39,68.2c19.6,0.1,39.2,0.1,58.9,0c-15-25.4-30.3-50.6-45.4-76C1198.1,114.8,1208.7,101.8,1213,87.1      L1213,87.1z M1160.6,78.2c-4.1,4.2-10.4,4.6-16,4.8c-14.9,0.2-29.8,0-44.7,0.1c-0.1-9.7,0-19.5-0.1-29.2      c17.3,0.4,34.6-0.8,51.8,1.1c4.1,0.5,9.1,1.7,10.3,6.3C1163.4,66.7,1163.8,73.3,1160.6,78.2z"/>
                    <path style="fill: #502D7D;" d="M1379.2,124c-0.1-15.1,0-30.2-0.1-45.4c-33.9,0-67.9,0.1-101.8-0.1c2-7.2,4.8-14.9,11.2-19.3      c6.9-5,15.7-6.7,24.1-6.2c22.2,0.1,44.3,0,66.5,0c0.1-15.1,0.1-30.1,0-45.2c-26,0.3-52,0.2-77.9,0.9c-20,0.4-41.1,5.9-55.9,20      c-12.9,13.2-20.2,31-23.2,48.9c-1.9,18.2-3.7,36.9,0.3,55c4,16,11.5,31.4,23,43.3c12.8,12.8,30.9,19.9,48.9,20.1      c28.3,1.4,56.6,0.5,84.9,0.8c0.1-15.1,0.1-30.2,0-45.3c-24.6,0.1-49.1-0.1-73.7,0.1c-7.7,0.3-15.9-1.6-21-7.8      c-5.4-5.2-6.6-12.8-7.2-20C1311.3,123.9,1345.3,123.9,1379.2,124L1379.2,124z"/>
                    <path style="fill: #502D7D;" d="M1552.1,31.7c-11.9-12.7-28.8-19.9-46-22.1c-15-2.4-30.2-1.7-45.3-1.8c-21.3,0-42.6,0-63.9,0      c0,63,0,126.1,0,189.1c27,0.1,53.9,0,80.9,0c14-0.1,28.2-0.4,41.8-4.1c13-3.8,24.1-11.9,34.4-20.4c10.7-9.1,16-23,18.6-36.5      c2.1-15.6,2.4-31.3,2.4-47C1574.9,67.9,1566.6,47.1,1552.1,31.7L1552.1,31.7z M1514.9,136c-2.5,8.2-9.9,14.7-18.7,15.1      c-15.8,1.2-31.6,0.2-47.4,0.6c-0.1-32.9,0-65.8,0-98.8c14,0.2,27.9-0.2,41.9,0.2c7,0.6,15.3,1.2,20,7.2c6.5,8.6,7.2,19.7,8.5,30      C1519.7,105.6,1520,121.4,1514.9,136L1514.9,136z"/>
                  </g>
                  <g>
                    <path style="fill: #F9BA00;" d="M749.4,152c-23.7-0.2-47.4-0.1-71.1-0.1c-7.6,0.2-15.9-0.5-21.8-5.9c-7-4.9-9.1-14-8.9-22.1      c33.9-0.2,67.8,0.1,101.8-0.2c0.1-14.8-0.1-29.5,0.1-44.3c-7.7-1.4-15.4-0.8-23.2-0.8c-26.4,0-52.7,0.1-79.1-0.1      c2-5.5,3.1-11.7,7.6-15.9c5.8-6.7,14.8-9.5,23.5-9.6c23.7-0.1,47.4,0.1,71.1-0.1c-0.1-15,0.5-30.1-0.2-45.1      c-29.7,0.3-59.5-0.4-89.2,1.5c-15.2,2.2-30.8,6.8-42.5,17.4c-15.8,14.5-23.8,35.7-26.3,56.6c-0.2,15.1-0.7,30.2,0.2,45.2      c4.3,21.9,15.7,43.6,34.7,56.3c12.3,7.4,26.7,12.4,41.2,10.6l-2.2,1c2.8,0.2,5.7,0.5,8.5,0.4c25,0.5,49.9,0,74.9,0.3      C750.4,182.2,749,167,749.4,152L749.4,152z"/>
                    <path style="fill: #F9BA00;" d="M563.1,136.4c-3.2-5.2-6.4-10.4-8.9-16c13.2-5.1,22.8-17,27.6-30c4.4-14.8,4-31-0.5-45.8      c-2.3-7.7-7.1-14.2-11.9-20.5c-4.7-3.9-9.3-8-14.9-10.4c-5.5-2.5-11.6-3-17.6-3.7c-9.3-2.1-18.8-2.1-28.3-2.2      c-28.7,0-57.5,0-86.2,0c-2.1-0.3-5.2,0.5-4.6,3.3c-0.2,56.1,0,112.2-0.1,168.3c-0.1,5.9,0,11.9,0.9,17.8      c16.6-0.2,33.3-0.2,49.9,0c0.7-4,1.2-8.1,1.1-12.1c-0.1-18.7,0-37.3-0.1-56c10.7,0,21.5,0,32.2,0.1c4.3,8.9,9.7,17.2,14.6,25.8      c8,14.1,15.9,28.2,24.1,42.2c19.2-0.2,38.5,0.1,57.7-0.2c-3-7.8-7.8-14.7-12.1-21.9C578.5,162.2,570.9,149.3,563.1,136.4      L563.1,136.4z M531.6,77.9c-4,2.4-8.2,4.8-13,4.7c-16.2,0.5-32.5,0.2-48.8,0.2c-0.2-9.5,0.2-19.1-0.2-28.6      c15.4,0,30.9-0.2,46.3,0.2c5,0,9.8,1.7,14.5,3.2c1.1,3.3,2.3,6.7,2.8,10.2C532.9,71.2,532.1,74.5,531.6,77.9L531.6,77.9z"/>
                    <path style="fill: #F9BA00;" d="M911.9,7.8c-14.5,33.1-28.2,66.6-42.3,99.9c-2.5,5.5-4.8,11.1-6.5,16.9c-3.7-6.2-6.2-12.9-8.9-19.6      c-12.9-31.4-25.8-62.8-38.5-94.3c-1-3.4-4.7-2.8-7.5-2.9c-17,0.1-34,0-50.9,0c0.9,5.4,3.3,10.3,5.7,15.2      c17.3,38.9,34.1,78,51.5,116.9c8.4,19.1,16.3,38.5,25.5,57.2c15.8-0.2,31.7-0.1,47.5-0.1c18.8-43.8,36.9-87.9,55.5-131.8      c7.8-19.1,16.5-38,23.7-57.4C948.5,7.6,930.2,8,911.9,7.8L911.9,7.8z"/>
                    <path style="fill: #F9BA00;" d="M1036.2,169.5c0.1-53.6-0.2-107.2,0.1-160.8c-10-1.6-20.2-0.6-30.3-0.8c-8.2,0.3-16.5-0.9-24.5,1.1      c1.9,9.6,0.7,19.5,0.8,29.2c-1.2,5.6,0.3,11.3,0.2,16.9c-0.1,42.4,0.1,84.8-0.1,127.2c-0.3,5-1,9.9,0.1,14.9      c17.7-0.1,35.4-0.1,53.1-0.1C1036.8,187.9,1036,178.6,1036.2,169.5L1036.2,169.5z"/>
                    <path style="fill: #F9BA00;" d="M371.7,12.1h-0.6c-4.7-2-9.8-3.1-14.9-3.1c-18.3-1.8-36.7-1.1-55.1-1.2c-20,0.1-40.1-0.2-60.1,0.2      c-0.2,63.1-0.2,126.1,0,189.2c17.3,0.1,34.7,0.2,52-0.1c0.5-20.3,0-40.7,0.2-61c22.1-1.3,44.5,1.7,66.5-1.5      c9.5-2.6,19.5-4.3,27.7-10.1c13.4-8.9,20.6-24.5,24.1-39.7c2.4-17.8-0.2-36.9-10.5-52C394.2,22.5,383.2,16,371.7,12.1      L371.7,12.1z M354.9,76.3c-3.2,5.3-10,6.4-15.6,6.4c-15.3,0-30.7,0-46,0c0-9.5-0.1-19,0-28.5c17.6-0.1,35.2-0.7,52.7,0.5      c2.2,0.9,4.4,1.8,6.6,2.7C356.8,62.7,357.6,70.2,354.9,76.3L354.9,76.3z"/>
                  </g>
                  <!-- <g>
                    <path style="fill: #502E7E" d="M197.9,58.8c-11.5-24.4-33.1-43.7-58.5-52.7C123.4,0.2,105.9-1.2,89,0.9C65.4,4.4,43,16.1,26.9,33.7      C14.2,47.6,5.3,65.1,1.9,83.6c-4.2,20.5-1.4,42.2,7.2,61.2c7.9,17.4,20.8,32.5,36.8,43.1c28.6,19.4,67,23.1,98.7,9.4      c33.4-13.6,58.5-46.1,62.5-82C209.2,96.2,206.4,76.3,197.9,58.8L197.9,58.8z M177.1,163c-22,26.5-59.1,39.5-92.8,31.9      c-21.1-4.1-40.4-16-54.1-32.5c-14.1-17-22.2-39.1-21.4-61.2c-0.2-13.6,3.5-27.1,9.3-39.3C33,31,65.6,9.7,99.8,8.7      c18.7-0.8,37.7,3.8,53.6,13.6c20.1,11.9,35.5,31.7,41.9,54.2C204.3,106,197.2,139.6,177.1,163L177.1,163z"/>
                    <path style="fill: #502E7E" d="M117.5,53.3c8.5,0.4,15.4-8.8,12.7-16.9c-2-7.6-11.2-11.6-18.4-8.6c-4.5,1.9-7.7,6.5-8.1,11.3      C103.1,46.7,110,53.7,117.5,53.3z"/>
                    <path style="fill: #502E7E" d="M34.8,91.8c-5,2.4-8.5,7.9-7.6,13.4c0.5,8.2,9.9,14.2,17.5,11.3c7.2-2,11.5-11,8.3-17.8      C50.2,91.8,41.5,88.6,34.8,91.8z"/>
                    <path style="fill: #502E7E" d="M141,122c-3.9,1.2-7.2,4.3-8.6,8.2c-3,6.9,1.8,15.4,8.9,17.3c8.2,2.8,18-4.4,17.1-13.2      C158.7,125.5,149,119.1,141,122z"/>
                    <path style="fill: #502E7E" d="M141.9,82.7c7.8,2.1,16.7-4.1,16.5-12.4c1-8.6-8.3-15.7-16.4-13.4c-4.4,0.9-8.1,4.2-9.5,8.4      C129.4,72.2,134.4,81.3,141.9,82.7z"/>
                    <path style="fill: #502E7E" d="M79.9,90.9c-6.4,1.2-11.3,7.6-10.3,14.2c0.1,8.6,10.3,14.6,18,11.2c7-2.3,10.9-11.1,7.6-17.7      C92.8,92.7,86,89.5,79.9,90.9z"/>
                    <path style="fill: #502E7E" d="M119.7,91.6c-5.9,2.4-9.3,9.3-7.6,15.5c1.5,7.4,10.6,12.1,17.5,9.2c7.2-2.2,11.2-11.3,7.7-18      C134.6,91.7,126.2,88.8,119.7,91.6z"/>
                    <path style="fill: #502E7E" d="M161.7,91.7c-5.5,2.4-8.8,8.7-7.6,14.6c1,8.1,10.8,13.4,18.2,9.9c7.1-2.3,10.4-11.4,7.1-17.9      C176.7,91.6,168.1,88.8,161.7,91.7z"/>
                    <path style="fill: #502E7E" d="M116.1,152.1c-6.7,0.6-12.2,6.5-12.3,13.2c-0.3,7.3,6.4,13.8,13.7,13.5c7.2,0.3,13.6-6.4,13.3-13.5      C131,157.7,123.5,151.3,116.1,152.1z"/>
                  </g> -->
                </g>
              </g>
            </g>
          </svg>
        </template>
      </CardIntegration>
    </div>

    <h2 class="text-lg font-semibold dark:text-gray-200 mt-8 mb-4">Sistema de Facturación</h2>
    <!-- OpenFactura -->
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <CardIntegration
        title="Open Factura"
        description="Open Factura es una plataforma digital para facturación electrónica y gestión de documentos tributarios."
        :object="openfactura"
        cronType="open_factura"
        @reload="getDataOpenFactura()"
      >
        <template #icon>
          <IconOpenFactura />
        </template>
      </CardIntegration>
    </div>
    <h2 class="text-lg font-semibold dark:text-gray-200 mt-5 mb-4">Inteligencia Artificial</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-8">
      <!-- Company -->
      <CardIntegration
        title="IA Entrenar Recomendador"
        description="Entrenamiento de un sistema de recomendación para clasificar documentos tributarios."
        :object="mlTraining"
        cronType="ml_training_recommended"
        @reload="getDataMlTrainingRecommendation()"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="text-cyma-green-icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
          </svg>
        </template>
      </CardIntegration>
      <!-- Sale and Buys -->
      <CardIntegration
        title="IA Recomendación de Clasificaciones"
        description="Explorar sugerencias generadas por nuestro motor de inteligencia artificial."
        :object="mlfetch"
        cronType="ml_fetch_recommended"
        @reload="getDataMlFetchRecommended()"
      >
        <template #icon>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="text-cyma-green-icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 3v1.5M4.5 8.25H3m18 0h-1.5M4.5 12H3m18 0h-1.5m-15 3.75H3m18 0h-1.5M8.25 19.5V21M12 3v1.5m0 15V21m3.75-18v1.5m0 15V21m-9-1.5h10.5a2.25 2.25 0 0 0 2.25-2.25V6.75a2.25 2.25 0 0 0-2.25-2.25H6.75A2.25 2.25 0 0 0 4.5 6.75v10.5a2.25 2.25 0 0 0 2.25 2.25Zm.75-12h9v9h-9v-9Z" />
          </svg>
        </template>
      </CardIntegration>
    </div>
  </div>
</template>

<script>
import {
  formatDateTimeToLongFormat,
} from "@/utils/formatters.js";

import CardIntegration from "@/components/CardIntegration.vue";
import SiiIcon from "@/components/Icons/SiiIcon.vue";
import IconMercadoPago from "@/components/Icons/MercadoPago.vue";
import IconBancoSantander from "@/components/Icons/BancoSantander.vue";
import IconBancoDeChile from "@/components/Icons/BancoDeChile.vue";
import IconBancoEstado from "@/components/Icons/BancoEstado.vue";
import IconBancoBCI from "@/components/Icons/BancoBCI.vue";
import IconBancoItau from "@/components/Icons/BancoItau.vue";
import IconBancoSecurity from "@/components/Icons/BancoSecurity.vue";
import IconBancoBICE from "@/components/Icons/BancoBICE.vue";
import IconBancoScotiabank from "@/components/Icons/BancoScotiabank.vue";
import IconTalana from "@/components/Icons/Talana.vue";
import IconBSale from "@/components/Icons/BSale.vue";
import IconOpenFactura from "@/components/Icons/OpenFactura.vue";

export default {
  name: "Integrations",
  props: {},
  data() {
    return {
      currencies: [],
      columns: [],
      loading: true,
      company: {},
      sale_and_buy: {},
      sale: {},
      buy: {},
      honorary: {},
      flow: {},
      mercado_pago: {},
      ventipay: {},
      document_xml: {},
      f29: {},
      f22: {},
      f50: {},
      bsale: {},
      talana: {},
      previred: {},
      openfactura: {},
      mlTraining: {},
      mlfetch: {},
      banks: []
    };
  },
  components: {
    CardIntegration,
    SiiIcon,
    IconMercadoPago,
    IconBancoSantander,
    IconBancoDeChile,
    IconBancoEstado,
    IconBancoBCI,
    IconBancoItau,
    IconBancoSecurity,
    IconBancoBICE,
    IconBancoScotiabank,
    IconTalana,
    IconBSale,
    IconOpenFactura
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id() {
      this.reload();
    }
  },
  mounted() {
    this.reload();
  },
  methods: {
    formatDateTimeToLongFormat,
    reload() {
      this.getDataBank();
      this.getDataCompany();
      this.getDataSaleAndBuy();
      this.getDataBuy();
      this.getDataSale();
      this.getDataHonorary();
      this.getDataFlow();
      this.getDataMercadoPago();
      this.getDataVentipay();
      this.getDataDocumentXML();
      this.getDataF29();
      this.getDataF22();
      this.getDataF50();
      this.getDataBsale();
      this.getDataTalana();
      this.getDataPrevired();
      this.getDataOpenFactura();
      this.getDataMlTrainingRecommendation();
      this.getDataMlFetchRecommended();
    },
    getDataCompany() {
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Data Company"
        },
      }).then((response) => {
        app.company = response.data.data;
        app.loading = false;
      });
    },
    getDataSaleAndBuy() {
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Sale And Buy"
        },
      }).then((response) => {
        app.sale_and_buy = response.data.data;
        app.loading = false;
      });
    },
    getDataSale() {
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Sale"
        },
      }).then((response) => {
        app.sale = response.data.data;
        app.loading = false;
      });
    },
    getDataBuy() {
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Buy"
        },
      }).then((response) => {
        app.buy = response.data.data;
        app.loading = false;
      });
    },
    getDataHonorary() {
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Data Honorary"
        },
      }).then((response) => {
        app.honorary = response.data.data;
        app.loading = false;
      });
    },
    getDataFlow(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Flow"
        },
      }).then((response) => {
        app.flow = response.data.data;
        app.loading = false;
      });
    },
    getDataMercadoPago(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "MercadoPago"
        },
      }).then((response) => {
        app.mercado_pago = response.data.data;
        app.loading = false;
      });
    },
    getDataVentipay(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Ventipay"
        },
      }).then((response) => {
        app.ventipay = response.data.data;
        app.loading = false;
      });
    },
    getDataDocumentXML(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Document XML"
        },
      }).then((response) => {
        app.document_xml = response.data.data;
        app.loading = false;
      });
    },
    getDataF29(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "F29"
        },
      }).then((response) => {
        app.f29 = response.data.data;
        app.loading = false;
      });
    },
    getDataF22(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "F22"
        },
      }).then((response) => {
        app.f22 = response.data.data;
        app.loading = false;
      });
    },
    getDataF50(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "F50"
        },
      }).then((response) => {
        app.f50 = response.data.data;
        app.loading = false;
      });
    },
    getDataBsale(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Bsale"
        },
      }).then((response) => {
        app.bsale = response.data.data;
        app.loading = false;
      });
    },
    getDataTalana(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Talana"
        },
      }).then((response) => {
        app.talana = response.data.data;
        app.loading = false;
      });
    },
    getDataPrevired(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "Previred"
        },
      }).then((response) => {
        app.previred = response.data.data;
        app.loading = false;
      });
    },
    getDataOpenFactura(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "OpenFactura"
        },
      }).then((response) => {
        app.openfactura = response.data.data;
        app.loading = false;
      });
    },
    getDataMlFetchRecommended(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "IA Save Recommendation"
        },
      }).then((response) => {
        app.mlfetch = response.data.data;
        app.loading = false;
      });
    },
    getDataMlTrainingRecommendation(){
      let app = this;
      app.loading = true;
      this.axios.get("/integrations", {
        params: {
          company_id: app.company_id,
          job: "IA Training"
        },
      }).then((response) => {
        app.mlTraining = response.data.data;
        app.loading = false;
      });
    },
    getDataBank(){
      let app = this;
      app.loading = true;
      this.axios.get("/all-banks", {
        params: {
          company_id: app.company_id
        },
      }).then((response) => {
        app.banks = response.data;
        app.loading = false;
      });
    },

  },
};
</script>
<style>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-icon {
  animation: rotate 2s linear infinite;
}

.st0{fill:#FF6815;}
.st1{fill:none;}
.st2{display:none;}
.st3{display:inline;}
.st4{fill:#FF00FF;}
</style>
