<template>
  <div class="relative overflow-hidden flex-1 bg-white dark:bg-background-dark h-auto">
    <section class="relative mx-auto pb-3 flex flex-col md:flex-row gap-5">
      <div
        class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-5/12 flex flex-col bg-cyma-purple-200 order-2 lg:order-none dark:bg-background-dark min-h-screen"
      >
        <div class="max-w-sm mx-auto">
          <header class="flex justify-center items-center mb-12">
            <button
              href="#"
              @click.prevent="$router.go()"
              class="block p-4 lg:p-6 outline-none border-transparent appearance-none focus:outline-none"
            >
              <img
                src="https://ptishwor.sirv.com/cyma-imgs/logo.png?format=webp"
                width="220"
                height="48"
                style="aspect-ratio: 151/29;"
                alt="cymasuite logo"
              />
            </button>
          </header>
          <div class="text-base text-center dark:text-gray-200">
            <p>
              Proceso completado! Accede a tu dashboard y explora tus principales KPI para tomar decisiones estratégicas para optimizar tus operaciones.
            </p>
          </div>
          <img
            src="@/assets/images/success.png"
            alt=""
            class="w-9/12 mx-auto mb-5 select-none pointer-events-none mt-4 sm:mb-12"
            style="aspect-ratio: 1/1;"
          >
          <!-- Apps móviles -->
          <div>
            <p class="text-base dark:text-gray-200 text-center mb-5">
              Puedes disfrutar aún más de nuestros servicios descargando nuestra aplicación móvil
            </p>
            <div class="flex justify-center mb-5">
              <Icon icon="heroicons:chevron-double-down" class="w-8 h-8 text-cyma-purple dark:text-white" />
            </div>
            <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="https://apps.apple.com/us/app/cymasuite/id1582243635" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:apple" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Download on the</div>
                  <div class="font-sans text-sm font-semibold">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gonozer.cymasuitemobile&hl=es_CL&gl=US" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:google-play" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Get in on</div>
                  <div class="font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-7/12 order-1 lg:order-none">
        <div class="max-w-xl mx-auto">
          <h2
            class="pt-20 uppercase text-2xl font-yesevaOne font-bold text-cyma-purple dark:text-white mb-4"
          >
            Conexión exitosa
          </h2>
          <p class="text-base dark:text-gray-200 mb-14">
            ¡Genial! Nuestro sistema está conectado al Servicio de Impuestos Internos y está obteniendo toda la información necesaria. Esto es lo que está sucediendo:
          </p>
          <div>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="success_conection_sii ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <p>Conexión exitosa con el Servicio de impuestos internos</p>
                <Spinner
                  v-if="!success_conection_sii"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="company_creation ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="company_creation" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Tu empresa ha sido creada con éxito</p>
                <Spinner
                  v-if="!company_creation"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                v-if="movement_sale_validate"
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="movement_sale_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="movement_sale_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Validar ingresos</p>
                <Spinner
                  v-if="!movement_sale_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="movement_buy_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="movement_buy_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p> Tus gastos están siendo validados</p>
                <Spinner
                  v-if="!movement_buy_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                v-if="movement_honorary_validate"
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="movement_honorary_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="movement_honorary_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Validar boleta de honorarios</p>
                <Spinner
                  v-if="!movement_honorary_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                v-if="client_validate"
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="client_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="client_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Creación de clientes</p>
                <Spinner
                  v-if="!client_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="vendor_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="vendor_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Proveedores agregados con éxito</p>
                <Spinner
                  v-if="!vendor_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                v-if="service_provider_validate"
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="service_provider_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="service_provider_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Creación de prestadores de servicios.</p>
                <Spinner
                  v-if="!service_provider_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="account_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div v-if="account_validate" class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Tu plan de cuentas está completo y listo para usar</p>
                <Spinner
                  v-if="!account_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class="taxes_validate ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div
                  v-if="taxes_validate"
                  class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>Impuestos configurados correctamente</p>
                <Spinner
                  v-if="!taxes_validate"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
            <Transition name="fade" mode="out-in">
              <div
                class="relative flex flex-row gap-6 p-4 dark:text-white"
              >
                <Icon icon="heroicons:check-circle-solid" class="w-8 h-8" :class=" sii_active ? ' text-cyma-purple-light' : 'text-gray-300'" />
                <div
                  v-if="sii_active"
                  class="absolute left-8 -top-5 border-l dark:border-white h-10" :class="company_creation ? 'border-cyma-purple-light' : 'border-gray-300'"></div>
                <p>¡Tu cuenta ha sido creada exitosamente!</p>
                <Spinner
                  v-if="!sii_active"
                  class="text-cyma-purple ml-auto dark:text-cian-500"
                />
              </div>
            </Transition>
          </div>
          <div class="flex justify-center">
            <CymaButton
              @click="updateSiiConfig()"
              :disabled="!stateConfig"
              :class="{ 'opacity-70 cursor-not-allowed': !stateConfig }"
              class="font-semibold bg-cyma-purple text-white mt-4"
            >
              Revisar mi dashboard
            </CymaButton>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import confetti from 'canvas-confetti';
import Spinner from "@/components/Spinner2.vue"
import CymaButton from "@/components/CymaButton.vue"
import { Icon } from "@iconify/vue2"

export default {
  components: {
    Spinner,
    CymaButton,
    Icon,
  },
  data() {
    return {
      show: false,
      sii_active: false,
      company_id: null,
      taxes_validate: false,
      account_validate: false,
      service_provider_validate: false,
      vendor_validate: false,
      client_validate: false,
      movement_sale_validate: false,
      company_creation: false,
      success_conection_sii: false,
      movement_buy_validate: false,
      movement_honorary_validate: false,
      stateConfig: false,
      // GO TO PAY
    }
  },
  created() {
    this.checkSummary()
  },
  methods: {
    async submited(event) {
      this.$store.commit('addCompanyId', event);
    },
    goDashboard() {
      this.$cookie.delete("sii_config_id")
      window.location.href = "https://app.cymasuite.com"
      // window.location.href = "http://localhost:8080"
    },
    updateSiiConfig() {
      let id = this.$cookie.get("sii_config_id")
      this.axios
        .patch("sii_configs/" + id, { onboarding: true, step: 4 })
        .then(() => {
          this.goDashboard()
        })
    },
    // checkSummary(id) {
    //   if (!id) {
    //     id = this.$cookie.get("sii_config_id")
    //   }
    //   this.axios
    //     .get("sii-config-validate/" + id)
    //     .then((response) => {
    //       console.log(response.data[0].monitoring_progress)
    //       setTimeout(() => {
    //         this.success_conection_sii =
    //           response.data[0].monitoring_progress.success_conection_sii
    //       }, 1800)
    //       setTimeout(() => {
    //         this.company_creation =
    //           response.data[0].monitoring_progress.company_creation
    //       }, 2400)
    //       // Opcional
    //       setTimeout(() => {
    //         this.movement_sale_validate =
    //           response.data[0].monitoring_progress.movement_sale_validate
    //       }, 2800)
    //       setTimeout(() => {
    //         this.movement_buy_validate =
    //           response.data[0].monitoring_progress.movement_buy_validate
    //       }, 3400)
    //       setTimeout(() => {
    //         this.movement_honorary_validate =
    //           response.data[0].monitoring_progress.movement_honorary_validate
    //       }, 3700)
    //       // GRUPOS
    //       setTimeout(() => {
    //         this.client_validate =
    //           response.data[0].monitoring_progress.client_validate
    //       }, 4300)
    //       setTimeout(() => {
    //         this.vendor_validate =
    //           response.data[0].monitoring_progress.vendor_validate
    //       }, 4800)
    //       setTimeout(() => {
    //         this.service_provider_validate =
    //           response.data[0].monitoring_progress.service_provider_validate
    //       }, 5200)
    //       //
    //       setTimeout(() => {
    //         this.account_validate =
    //           response.data[0].monitoring_progress.account_validate
    //       }, 5800)
    //       setTimeout(() => {
    //         this.taxes_validate =
    //           response.data[0].monitoring_progress.taxes_validate
    //       }, 6400)
    //       setTimeout(() => {
    //         this.sii_active = response.data[0].monitoring_progress.sii_active
    //       }, 6800)
    //       this.company_id = response.data[0].company_id;

    //       if(this.company_id) {
    //         this.submited(this.company_id);
    //       }
    //       this.stateConfig =
    //         this.success_conection_sii &&
    //         this.company_creation &&
    //         this.account_validate &&
    //         this.taxes_validate &&
    //         this.sii_active
    //       if (!this.stateConfig) {
    //         setTimeout(() => {
    //           this.checkSummary()
    //         }, 1000)
    //       }
    //     })
    //     .catch((e) => console.log(e))
    // },
    checkSummary(id) {
      if (!id) {
        id = this.$cookie.get("sii_config_id")
      }
      this.axios
        .get("sii-config-validate/" + id)
        .then((response) => {
          const progress = response.data[0].monitoring_progress;
          const keys = Object.keys(progress);
          let delay = 0;
          keys.forEach((key) => {
            if (progress[key]) {
              delay += 600;
              setTimeout(() => {
                this[key] = progress[key];
              }, delay);
            } else {
              this[key] = progress[key];
            }
          });
          this.company_id = response.data[0].company_id;
          if(this.company_id) {
            this.submited(this.company_id);
          }
          this.stateConfig =
            this.success_conection_sii &&
            this.company_creation &&
            this.account_validate &&
            this.taxes_validate &&
            this.sii_active

          if (this.stateConfig) {
            confetti({
              particleCount: 150,
              spread: 55,
              origin: { x: 0 }
            })
            confetti({
              particleCount: 150,
              spread: 55,
              origin: { x: 1 }
            })
          } else {
            setTimeout(() => {
              this.checkSummary()
            }, 1000)
          }
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
