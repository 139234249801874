<template>
  <div
    class="py-2 mx-6"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider,
    }"
  >
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <h1 class="flex-auto text-2xl font-semibold text-center sm:text-left dark:text-white">
        Empresas
        <span class="block text-sm font-medium text-gray-400"
          >Directorio de empresas conectadas.</span
        >
      </h1>
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar empresas"
          @input="getCompanies"
          v-model="query"
        />
      </div>

      <div class="text-md font-semibold text-gray-500">
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            :style="{
              width: '100%',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.65rem',
              'padding-bottom': '0.65rem',
              'border-radius': '0.5rem'
            }"
            v-model="dateRange"
            type="date"
            range
            format='DD-MM-YYYY'
            placeholder="Fecha"
            v-on:input="getCompanies"
          ></date-picker>
        </div>
      </div>
    </div>
    <BeeGridTable
      border
      stripe
      :serverSide="true"
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :fetchMethod="fetchMethod"
      :data="companies"
      :showSummary="false"
      :columns="columns"
      :showFilter="false"
      :loading="loading"
    >
      <BeeColumn
        field="business_name"
        title="Razón social"
        type="text"
        sortable="true"
        :width="600"
      >
        <template slot-scope="{ row }">
          <a
            class="dark:text-cian-dark dark:hover:text-white uppercase"
            @click.prevent="goTo('CompanyIndex', { props_company_id: row.id })"
            >{{ row.business_name }}</a
          >
        </template>
      </BeeColumn>

      <BeeColumn field="rut" title="RUT" type="text" sortable="true" :width="200">
        <template slot-scope="{ row }">
          {{ formatRut(row.rut + (row.dv || 0)) }}
        </template>
      </BeeColumn>

      <BeeColumn
        field="email"
        title="Correo electronico"
        type="text"
        sortable="true"
        :width="200"
      >
        <template slot-scope="{ row }">
          {{ row.email }}
        </template>
      </BeeColumn>

      <BeeColumn field="email" title="Teléfono" type="number" sortable="true" :width="200">
        <template slot-scope="{ row }">
          {{ row.mobile_phone }}
        </template>
      </BeeColumn>

      <BeeColumn field="from" title="Sincronización" type="number" :width="200">
        <template slot-scope="{ row }">
          <div>
            <p>Desde {{ formatDateSII(row.sii_config.from) }}</p>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="id" title="Opciones" type="text" :width="200">
        <template slot-scope="{ row }">
          <div class="flex gap-4 items-center">
            <a
              class="dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
              setCompanyGoto(row.id, row.sii_config_id)

              "
            >
              <Edit/>
            </a>
            <a
              class="dark:text-cian-dark dark:hover:text-white"
              v-if="row.sii_config.user_id === user.id"
              v-on:click="deleteFunction(row.id)">
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatRut, formatDateSII, formatDateTimeToLongFormat } from '@/utils/formatters'
import "@/assets/stylesheets/switch.scss";
import DatePicker from "vue2-datepicker";
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';
// import ToolTips from './toolTips.vue';
export default {
  name: "Contability",
  props: {},
  data() {
    return {
      companies: [],
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      filterTotal: 0,
      columns: [],
      loading: true,
      query: null,
      dateRange: [],
      status: true,
      user: this.$auth.user()
    };
  },
  components: {
    DatePicker,
    Edit,
    Times,
    // ToolTips
  },
  mounted() {
    this.getCompanies();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },

  methods: {
    formatRut,
    formatDateSII,
    formatDateTimeToLongFormat,
    setCompanyGoto(id, sii_config_id){
      this.$store.commit('addCompanyId', id)
      this.goTo('editCompanyData', {
                                company_id: id,
                                action: 'update',
                                sii_config_id: sii_config_id,
                              })
    },
    checkedState(status) {
      if (!status) return false;
      return status
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      console.log()
      this.getCompanies();
    },
    goTo(url, data = null) {
      this.$store.commit("addCompanyId", data.company_id);
      this.$router.push({ name: url, params: data }).catch(() => {});
    },
    getCompanies() {
      this.loading = true;
      // wait for the user to wait to type in the browser
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
        // console.log('estoy escribiendo', this.timer)
      }
      this.timer = setTimeout(() => {
        // your code
        let app = this;
        this.axios.get("/companies", {
          params: {
            query: app.query,
            order: "asc",
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            from: app.dateRange[0],
            to: app.dateRange[1],
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
          },
        }).then((response) => {
          app.companies = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.loading = false;
        });
      }, 800);
    },
    formatSummary(value){
      let summary = {
        sales_sii: "Ingresos SII",
        other_sales: "Otros Ingresos",
        buy_sii: "Egresos SII",
        honorary_sii: "Honorarios SII",
        monthly_expenses: "Gastos Mensuales",
        remuneration: "Remuneraciones",
        withdraw: "Retiros",
        ticket_sales: "Tickets de Ventas",
        invoice_sales: "Factura de Ventas",
        accounting_voucher: "Comprobantes",
        pending_buy_sii: "Compra Pendiente",
      }
      return summary[value]
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu compañia ha sido eliminado",
              "success"
            );
            this.axios
              .delete(`companies/${id}`)
              .then(() => this.getCompanies());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu compañia no ha sido eliminado",
              "error"
            );
          }
        });
    },
  },
};
</script>
