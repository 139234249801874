<template>
  <div class="w-full hidden lg:block">
    <div class="flex justify-center w-full">
      <div class="relative xl:h-64 lg:h-44 ">
        <div v-if="banks.length > 1">
          <div
            v-for="(e, i) in banks.length < 4 ? banks.length : 4"
            :key="i"
            class="absolute xl:h-64 h-44 transform rounded-lg shadow xl:w-96 w-60"
            :class="'left-' + (i + 2 * (i + 1)) + ' ' + classCardProperties(i)"
          ></div>
        </div>
        <VueSlickCarousel
          :v-if="banks.length > 0"
          lazyLoad="ondemand"
          :arrow="true"
          cssEase="ease"
          :dots="false"
          @afterChange="afterChange($event)"
          :fade="true"
          :infinite="true"
          :speed="1000"
          class="w-80 xl:w-96 lg:w-60 sm:w-96 xl:h-60 lg:h-44 sm:h-60 z-40"
        >
          <div
            v-for="(bank, i) in banks"
            :key="i"
            class="justify-center xl:h-64 h-60 lg:h-44 sm:h-64 overflow-hidden transition-all duration-500 transform rounded-lg bg-cyma-purple"
          >
            <div class="w-full h-full p-4 xl:p-5 lg:p-2">
              <div class="flex items-center justify-between text-white">
                <span
                  v-if="bank.currency == 'CLP'"
                  class="xl:text-2xl text-base font-bold"
                  >{{ formatNumber(bank.balance_available) }}
                  <small class="xl:text-sm lg:text-xs font-light">{{ bank.currency }}</small>
                </span>

                <span
                  v-if="bank.currency == 'USD'"
                  class="xl:text-2xl text-base font-bold"
                  >{{ formatNumberUsd(bank.balance_available) }}
                  <small class="xl:text-sm lg:text-xs font-light">{{ bank.currency }}</small>
                </span>
              </div>
              <a
                @click.prevent="updateCron(bank.id)"
                class="flex text-medium-custom text-xs xl:text-sm items-center gap-2 text-white"
              >
                Sincronizar Banco
              </a>
              <p class="text-medium-custom text-white xl:text-xs">
                Última sincronización: {{ formatCompleteDate(bank.updated_at) }}
              </p>
              <div class="flex items-center gap-4 mt-8 xl:mt-8 lg:mt-4">
                <div class="flex flex-row">
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png"/>
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png"/>
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png"/>
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png"/>
                </div>
                <div class="flex flex-row">
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                </div>
                <div class="flex flex-row">
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                  <img class="w-3 h-3" src="https://i.imgur.com/FNef1H8.png" />
                </div>
                <div class="flex flex-row">
                  <span
                    class="mr-1 xl:text-lg lg:text-base font-bold tracking-widest text-white"
                    >{{ bank.number.slice(-4) }}</span
                  >
                </div>
              </div>
              <div class="flex items-center justify-between mt-2 xl:mt-8 text-white">
                <div class="flex flex-col">
                  <span class=" text-medium-custom xl:text-xs">
                    {{ bank.official_name }}
                  </span>
                  <span class=" text-medium-custom xl:text-xs">
                    {{ bank.bank.institution_country }}
                  </span>
                  <span class=" text-medium-custom xl:text-xs">
                    {{ bank.holder_name }}
                  </span>
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Santander'">
                  <IconBancoSantander class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco de Chile'">
                  <IconBancoDeChile class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Estado'">
                  <IconBancoEstado class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco BCI'">
                  <IconBancoBCI class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Itaú'">
                  <IconBancoItau class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Security'">
                  <IconBancoSecurity class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco BICE'">
                  <IconBancoBICE class="h-10 w-10" />
                </div>
                <div v-if="bank.bank.institution_country === 'Banco Scotiabank'">
                  <IconBancoScotiabank class="h-10 w-10" />
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <!-- <v-draggable
        v-model="banks"
        @start="drag = true"
        @end="drag = false" >
        <transition-group> -->

      <!-- </transition-group>
        </v-draggable> -->
    </div>
  </div>
</template>

<script>
// import Hologram from '../Hologram.vue';}
import { formatNumber, formatNumberUsd, formatCompleteDate } from "@/utils/formatters"
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import IconBancoSantander from '@/components/Icons/BancoSantander.vue'
import IconBancoDeChile from '@/components/Icons/BancoDeChile.vue'
import IconBancoEstado from '@/components/Icons/BancoEstado.vue'
import IconBancoBCI from '@/components/Icons/BancoBCI.vue'
import IconBancoItau from '@/components/Icons/BancoItau.vue'
import IconBancoSecurity from '@/components/Icons/BancoSecurity.vue'
import IconBancoBICE from '@/components/Icons/BancoBICE.vue'
import IconBancoScotiabank from '@/components/Icons/BancoScotiabank.vue'

export default {
  name: "CreditCards",
  components: {
    VueSlickCarousel,
    IconBancoSantander,
    IconBancoDeChile,
    IconBancoEstado,
    IconBancoBCI,
    IconBancoItau,
    IconBancoSecurity,
    IconBancoBICE,
    IconBancoScotiabank,
  },
  // components: { Hologram },
  props: {
    banks: {
      type: Array,
      default() {
        return []
      }
    },
  },
  // data() {
  //   return {
  //     ctse: null,
  //     p: 0,
  //     startPosition: null,
  //     ct: null,
  //     cts: null,
  //     x: null,
  //     el: null,
  //     el_class: null,
  //     el_class_end: null,
  //   };
  // },
  data() {
    return {
      myArray: [],
      selectedCard: null,
      ease: null
    };
  },
  mounted() {
    this.handlerDraggable();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    formatNumber,
    formatNumberUsd,
    formatCompleteDate,
    afterChange(slideIndex) {
      this.$emit("afterChange", slideIndex);
    },
    handlerDraggable() {
      const app = this;
      const cards = document.querySelectorAll(".test");

      cards.forEach((card, index) => {
        if (index == 0) {
          card.setAttribute("draggable", "true");

          card.addEventListener("dragstart", function () {});

          card.addEventListener("mouseUp", function () {
            console.log(app.banks);
            let bankDeleted = app.banks.shift();
            app.banks.push(bankDeleted);
          });
        }
      });
    },
    classCardProperties(i) {
      let bgColor = {
        0: "bg-transparent",
        1: "bg-cyma-purple/90",
        2: "bg-cyma-purple/80",
        3: "bg-cyma-purple/70",
        5: 40,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
      };
      let z_index = {
        0: "z-30",
        1: "z-20",
        2: "z-10",
        3: "z-0",
      };
      let height_px = {
        1: "scale-y-95",
        2: "scale-y-90",
        3: "scale-y-85",
        4: "scale-y-80",
        5: "scale-y-75",
      };
      if (i != 0) {
        return z_index[i] + " " + bgColor[i] + " " + height_px[i];
      }
    },
    heightCard(i) {
      let height_px = {
        1: "236px",
        2: "216px",
        3: "196px",
        4: "175px",
        5: "155px",
        6: "135px",
      };
      if (i != 0) {
        return { height: height_px[i] };
      }
    },
    updateCron(bank_bill_id) {
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar toda la información de Bancos.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Actualizado",
              "Tu banco ha sido sincronizado.",
              "success"
            );
            this.axios
              .post("/company/update_bank", { bank_bill_id: bank_bill_id })
              .then(() => {
                this.getBankBills();
              });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu banco no ha sido sincronizado.",
              "error"
            );
          }
        });
    },
  },
};
</script>
