import { render, staticRenderFns } from "./AccountantAdvice.vue?vue&type=template&id=e2f95e84&scoped=true&"
import script from "./AccountantAdvice.vue?vue&type=script&lang=js&"
export * from "./AccountantAdvice.vue?vue&type=script&lang=js&"
import style0 from "./AccountantAdvice.vue?vue&type=style&index=0&id=e2f95e84&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2f95e84",
  null
  
)

export default component.exports