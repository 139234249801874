<template>
  <div>
    <transition name="modal">
      <div
        class="
          h-screen
          w-screen
          fixed
          top-0
          left-0
          bg-black bg-opacity-50
          flex
          justify-center
          items-center
          z-50
        "
        v-if="loading"
      >
        <Loader />
      </div>
      <div class="modal-mask flex md:justify-center md:items-center" v-else>
        <div class="h-screen w-screen"></div>
        <div class="absolute md:w-full lg:w-10/12">
          <div
            class="
              dark:bg-background-dark
              bg-white
              w-screen
              overflow-auto
              h-screen
              md:h-auto
              md:max-h-screen
              py-5
              px-4
              md:w-full
              md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1 class="flex-auto font-semibold text-grey-darker py-4 lg:text-4xl text-3xl dark:text-white">
                  {{tab}}
                  <span v-if="buyObj.accounting_type_buy == 'accounting_receivable'">
                    cuenta por cobrar
                  </span>
                  <span v-if="buyObj.accounting_type_buy == 'accounting_payable'">
                    cuenta por pagar
                  </span>
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="closeFunction"> &times; </a>
              </div>
            </div>
            <div class="modal-body flex flex-col lg:flex-row justify-between">
              <slot name="body">
                <div class="lg:w-7/12 px-2">
                  <div class="">
                    <div class="">
                      <p
                        class="uppercase tracking-wide text-sm font-bold text-gray-500 dark:text-white"
                      >
                        {{ buyObj.business_name }}
                      </p>
                      <p class="text-gray-500">
                        {{ formatDocType(buyObj.doc_type) }}
                      </p>
                      <p class="text-gray-500">
                        {{ buyObj.folio }}
                      </p>
                      <p class="text-4xl text-gray-500 font-bold dark:text-white">
                        ${{ formatNumber(buyObj.total) }}
                      </p>
                      <p class="text-gray-500">
                        {{ formatCompleteDate(buyObj.period) }}
                      </p>
                    </div>
                  </div>
                  <div class="overflow-auto my-8">
                    <table class="table-auto border min-w-full">
                      <thead class="sticky top-0 ">
                        <tr class="border w-full">
                          <th
                            class="py-2 whitespace-nowrap text-left pl-2 md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold dark:text-white"
                          >
                            Fecha
                          </th>
                          <th
                            class="py-2 whitespace-nowrap text-left md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold dark:text-white"
                          >
                            Plan de cuentas
                          </th>
                          <th
                            class="
                              py-2
                              whitespace-nowrap
                              text-left
                              md:text-left
                              uppercase
                              tracking-wide
                              text-grey-darker text-xs
                              font-bold
                              dark:text-white
                            "
                          >
                            Debe
                          </th>
                          <th
                            class="
                              py-2
                              whitespace-nowrap
                              text-left
                              md:text-left
                              uppercase
                              tracking-wide
                              text-grey-darker text-xs
                              font-bold
                              dark:text-white
                            "
                          >
                            Haber
                          </th>
                          <th
                            class="
                              py-2
                              whitespace-nowrap
                              text-left
                              md:text-left
                              uppercase
                              tracking-wide
                              text-grey-darker text-xs
                              font-bold
                              dark:text-white
                            "
                          >
                            Eliminar
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(
                            account_movement, index
                          ) in account_movements_record"
                          :key="index"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2 dark:text-white" v-if="!account_movement.new">
                            {{ formatSimpleDate(account_movement.period) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white uppercase" v-if="!account_movement.new">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white" v-if="!account_movement.new">
                            {{ formatNumber(account_movement.debit) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white" v-if="!account_movement.new">
                            {{ formatNumber(account_movement.credit) }}
                          </td>
                          <td></td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>
                      </tbody>
                      <tbody
                        v-if="account_movements_paid.length > 0"
                      >
                        <tr
                          v-for="(
                            account_movement, index
                          ) in account_movements_paid"
                          :key="index"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2 dark:text-white">
                            {{ formatSimpleDate(account_movement.period) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white uppercase">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white">
                            {{ formatNumber(account_movement.debit) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white">
                            {{ formatNumber(account_movement.credit) }}
                          </td>
                          <td
                            v-if="index === account_movements_paid.length - 1"
                            class="text-center"
                          >
                            <a v-on:click="deleteFunction(account_movement.id)">
                              <Times
                              />
                            </a>
                          </td>
                          <td v-else></td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>
                      </tbody>
                      <tbody
                        v-if="account_movements_bill.length > 0"
                      >
                        <tr
                          v-for="(
                            account_movement, index
                          ) in account_movements_bill"
                          :key="index"
                          class="bg-emerald-200"
                        >
                          <td class="py-2 whitespace-nowrap pl-2 dark:text-white">
                            {{ formatSimpleDate(account_movement.period) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white uppercase">
                            {{ account_movement.account_name }}
                            <span v-if="account_movement.other_tax_code">COD. {{ account_movement.other_tax_code }}</span>
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white">
                            {{ formatNumber(account_movement.debit) }}
                          </td>
                          <td class="py-2 whitespace-nowrap dark:text-white">
                            {{ formatNumber(account_movement.credit) }}
                          </td>
                          <td
                            v-if="index === account_movements_bill.length - 1"
                            class="text-center"
                          >
                            <a v-on:click="deleteFunction(account_movement.id)">
                              <Times
                              />
                            </a>
                          </td>
                          <td v-else></td>
                          <!-- <td class="py-2 whitespace-nowrap">{{ account_movement.state }}</td>
                          <td class="py-2 whitespace-nowrap">{{ account_movement.type_account_movement }}</td> -->
                        </tr>
                      </tbody>
                    </table>
                    <span v-if="!buyObj.doble_match" class="float-right mt-4">
                      <a class="flex gap-4" @click.prevent="movementSii()">
                        Solucionar Problema
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-cyma-gray-card">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                        </svg>
                      </a>
                    </span>
                    <span
                      v-if="!buyObj.doble_match"
                      class="float-right mt-4 mr-4"
                    >
                      <a class="gap-4 flex" @click.prevent="modifiedAccounting()">
                        Modificar <Edit />
                      </a>
                    </span>
                  </div>
                </div>
                <div
                  v-if="buyObj.movement_status == 'paid' && !buyObj.credit_note_id"
                  class="w-full lg:w-5/12 h-full py-12 px-3"
                >
                  <div class="flex justify-center items-center">
                    <Check />
                  </div>
                </div>
                <div v-else-if="buyObj.credit_note" class="w-5/12 h-full flex flex-col px-8">
                  <div class="text-sm pb-4 text-gray-500 uppercase font-bold">
                    Movimiento vinculado
                  </div>
                  <p
                  class="uppercase tracking-wide text-sm font-bold text-gray-500 dark:text-white"
                  >
                    {{ buyObj.credit_note.business_name }}
                  </p>
                  <p class="text-gray-500">
                    {{ formatDocType(buyObj.credit_note.doc_type) }}
                  </p>
                  <p class="text-gray-500">
                    {{ buyObj.credit_note.folio }}
                  </p>
                  <p class="text-4xl text-gray-500 font-bold dark:text-white">
                    ${{ formatNumber(buyObj.credit_note.total) }}
                  </p>
                  <p class="text-gray-500">
                    {{ formatCompleteDate(buyObj.credit_note.period) }}
                  </p>
                  <form v-if="(buyObj.total > buyObj.credit_note.total) && buyObj.movement_status != 'paid'" class="w-full h-full flex flex-col mt-4">
                    <div class="h-1 mb-10">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                          dark:text-white
                        "
                      >
                        ¿En qué fecha se pago?</label
                      >
                      <date-picker
                        type="date"
                        format="dd-MM-yyyy"
                        :style="{
                          width: '100%',
                          height: '47px',
                          'border-radius': '1px',
                          'padding-left': '0.55rem',
                          'padding-right': '0.55rem',
                          'padding-top': '0.55rem',
                          'padding-bottom': '0.55rem',
                          'margin-top': '2px',
                        }"
                        v-model="$v.payObj.period.$model"
                        :class="[{ error: $v.payObj.period.$error }, 'border dark:bg-black-dark dark:border-background-dark']"
                        placeholder="Seleccionar Fecha"
                      ></date-picker>
                      <div class="text_error" v-if="$v.payObj.period.$error">
                        Este campo es requerido
                      </div>
                    </div>
                    <div class="h-1 mt-12 mb-10">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                          dark:text-white
                        "
                        >Cuenta corriente involucrada</label
                      >
                      <div class="box-select">
                        <v-select
                          :class="[{ error: $v.payObj.account_id.$error }, 'mt-2']"
                          class="style-chooser custom-border"
                          :options="accounts"
                          :reduce="(account) => account.id"
                          label="fantasy_name"
                          v-model="payObj.account_id"
                        ></v-select>
                        <div
                          class="text_error"
                          v-if="$v.payObj.account_id.$error"
                        >
                          Este campo es requerido
                        </div>
                      </div>
                    </div>
                    <div class="h-1 mt-12 mb-10">
                      <label
                        class="
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          my-2
                          dark:text-white
                        "
                        >Monto que se pago en esa fecha</label
                      >
                      <div class="flex flex-row">
                        <currency-input
                          :class="{ error: $v.payObj.total_amount.$error }"
                          currency="CLP"
                          locale="es-CL"
                          class="
                            appearance-none
                            w-1/2
                            bg-grey-lighter
                            text-grey-darker
                            border border-grey-lighter
                            rounded
                            py-3
                            px-4
                            mr-4
                            mt-2
                            dark:bg-black-dark
                            dark:border-black-dark
                          "
                          v-model="$v.payObj.total_amount.$model"
                        />
                        <div
                          class="text_error"
                          v-if="$v.payObj.total_amount.$error"
                        >
                          Este campo es requerido
                        </div>
                        <range-slider
                          class="slider"
                          :min="min"
                          :max="max"
                          @change="formatMoney"
                          v-model="$v.payObj.total_amount.$model"
                        >
                        </range-slider>
                      </div>
                    </div>
                    <div class="h-1 mt-12 mb-10">
                      <label
                        class="
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          my-2
                          dark:text-white
                        "
                        >Comentarios (recomendado)</label
                      >
                      <input
                        type="text"
                        class="
                          appearance-none
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                          mt-2
                          dark:bg-black-dark
                          dark:border-black-dark
                        "
                        v-model="payObj.comentary"
                      />
                    </div>
                    <div class="h-1 mt-12">
                      <button
                        class="
                          bg-black
                          w-full
                          border
                          hover:border-black
                          text-white
                          font-bold
                          p-3
                          dark:bg-cian-dark
                          dark:border-cian-dark
                          rounded-full
                          hover:bg-white
                          hover:text-black
                        "
                        :disabled="disabled"
                        @click.prevent="submit"
                      >
                        Guardar
                      </button>
                    </div>
                  </form>
                </div>
                <div class="w-full lg:w-5/12 px-4" v-else>
                  <div class="flex items-center gap-4 border-b pb-3 mb-2">
                    <a class="text-sm" @click="tab = 'Registrar Pago'">Registrar Pago</a>
                    <!-- <a class="text-sm" @click="tab = 'Transferencia'">Transferencia</a> -->
                  </div>
                  <div class="w-full" v-if="tab == 'Transferencia'">
                    <newPayment
                      :movement_name='buyObj.business_name'
                      :movement_id='buyObj.movement_id'
                      :mov_rut='mov_rut'
                      :vendor='vendor'
                      :service_provider='service_provider'
                      :client='client'
                      @getBuy='getBuy'
                      >
                    </newPayment>
                  </div>
                  <div class="w-full h-full" v-else-if="'Registrar Pago'">
                    <form v-if="buyObj.movement_status != 'paid' && !showAccouting && !buyObj.credit_note_id" class="w-full  h-full flex flex-col">
                      <div class="">
                        <label
                          class="
                            block
                            md:text-left
                            uppercase
                            tracking-wide
                            text-grey-darker text-xs
                            font-bold
                            my-2
                            dark:text-white
                          "
                        >
                          ¿En qué fecha se pago?</label
                        >
                        <date-picker
                          type="date"
                          format="dd-MM-yyyy"
                          :style="{
                            width: '100%',
                            height: '47px',
                            'border-radius': '1px',
                            'padding-left': '0.55rem',
                            'padding-right': '0.55rem',
                            'padding-top': '0.55rem',
                            'padding-bottom': '0.55rem',
                            'margin-top': '2px',
                          }"
                          v-model="$v.payObj.period.$model"
                          :class="[{ error: $v.payObj.period.$error }, 'border dark:bg-black-dark dark:border-background-dark']"
                          placeholder="Seleccionar Fecha"
                        ></date-picker>
                        <div class="text_error" v-if="$v.payObj.period.$error">
                          Este campo es requerido
                        </div>
                      </div>
                      <div class="">
                        <label
                          class="
                            block
                            md:text-left
                            uppercase
                            tracking-wide
                            text-grey-darker text-xs
                            font-bold
                            my-2
                            dark:text-white
                          "
                          >Cuenta corriente involucrada</label
                        >
                        <div class="box-select">
                          <v-select
                            :class="[{ error: $v.payObj.account_id.$error }, 'mt-2']"
                            class="style-chooser custom-border"
                            :options="accounts"
                            :reduce="(account) => account.id"
                            label="fantasy_name"
                            v-model="payObj.account_id"
                          ></v-select>
                          <div
                            class="text_error"
                            v-if="$v.payObj.account_id.$error"
                          >
                            Este campo es requerido
                          </div>
                        </div>
                      </div>
                      <div class="">
                        <label
                          class="
                            md:text-left
                            uppercase
                            tracking-wide
                            text-grey-darker text-xs
                            font-bold
                            my-2
                            dark:text-white
                          "
                          >Monto que se pago en esa fecha</label
                        >
                        <div class="flex flex-row">
                          <currency-input
                            :class="{ error: $v.payObj.total_amount.$error }"
                            currency="CLP"
                            locale="es-CL"
                            class="
                              appearance-none
                              w-1/2
                              bg-grey-lighter
                              text-grey-darker
                              border border-grey-lighter
                              rounded
                              py-3
                              px-4
                              mr-4
                              mt-2
                              dark:bg-black-dark
                              dark:border-black-dark
                            "
                            v-model="$v.payObj.total_amount.$model"
                          />
                          <div
                            class="text_error"
                            v-if="$v.payObj.total_amount.$error"
                          >
                            Este campo es requerido
                          </div>
                          <range-slider
                            class="slider"
                            :min="min"
                            :max="max"
                            @change="formatMoney"
                            v-model="$v.payObj.total_amount.$model"
                          >
                          </range-slider>
                        </div>
                      </div>
                      <div class="">
                        <label
                          class="
                            md:text-left
                            uppercase
                            tracking-wide
                            text-grey-darker text-xs
                            font-bold
                            my-2
                            dark:text-white
                          "
                          >Comentarios (recomendado)</label
                        >
                        <input
                          type="text"
                          class="
                            appearance-none
                            w-full
                            bg-grey-lighter
                            text-grey-darker
                            border border-grey-lighter
                            rounded
                            py-3
                            px-4
                            mt-2
                            dark:bg-black-dark
                            dark:border-black-dark
                          "
                          v-model="payObj.comentary"
                        />
                      </div>
                    </form>
                  </div>

                </div>

              </slot>
            </div>

            <div v-if="showAccouting">
              <editAccounting
                :typeMovement="'manual'"
                :dataAcc="dataAcc"
                :ids="ids"
                @addAttribute="addAttribute"
                @permissionSave="permissionSave"
                :name_accounting="buyObj.bussines_name"
              ></editAccounting>
              <button
                v-if="permission"
                class="mx-2 my-2 h-10 border w-2/12 bg-black text-white"
                @click.prevent="submitAccouting()"
              >
                Modificar Asiento Contable
              </button>
            </div>
            <div
              v-if="buyObj.movement_status != 'paid'"
              class="modal-footer flex justify-end pr-5 pb-3 pt-8"
            >
              <slot name="footer" v-if="!showAccouting && !buyObj.credit_note_id && tab =='Registrar'">
                <div class="">
                  <button
                    class="
                      bg-black
                      w-full
                      border
                      hover:border-black
                      text-white
                      font-bold
                      p-3
                      dark:bg-cian-dark
                      dark:border-cian-dark
                      rounded-full
                      hover:bg-white
                      hover:text-black
                    "
                    :disabled="disabled"
                    @click.prevent="submit"
                  >
                    Guardar
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { formatDocType, formatNumber, formatSimpleDate, formatCompleteDate } from "@/utils/formatters.js";
import newPayment from './newPayment.vue'
import "@/assets/stylesheets/modal.css";
import RangeSlider from "vue-range-slider";
import "vue-range-slider/dist/vue-range-slider.css";
import editAccounting from "./editAccounting.vue";
import { required } from "vuelidate/lib/validators";
import Loader from "@/components/loader.vue";
import Check from "@/components/Icons/Check.vue";
import Times from '@/components/Icons/Times.vue';
import Edit from '@/components/Icons/Edit.vue';
export default {
  name: "newBuy",
  props: {
    buy_id: { default: null },
  },

  data() {
    return {
      switchState: null,
      tab: 'Registrar',
      user: {},
      clients: [],
      providers: [],
      permission: false,
      accounts: [],
      saldo: 0,
      ids: [],
      min: 0,
      max: 0,
      mov_rut: '',
      dataAcc: [
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "accounting",
          // state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
        },
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "accounting",
          // state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
        },
      ],
      client: {
        id: null,
        recipient_accounts: [],
      },
      vendor: {
        id: null,
        recipient_accounts: [],
      },
      service_provider: {
        id: null,
        recipient_accounts: [],
      },
      showAccouting: false,
      disabled: false,
      buyObj: {
        type_movement: 2,
        coin: null,
        total: null,
        period: null,
        responsable: null,
        folio: null,
        company_id: this.$store.state.company_id,
        client_id: null,
        file_movement: null,
        movement_status: null,
        doble_match: null,
        classified: null,
        checksii: null,
        account_movements: [],
        credit_note: [],
        account_movements_attributes: [],
        accounting_type_buy: null
      },
      payObj: {
        account_id: null,
        movement_id: null,
        total_amount: 0,
        period: null,
        comentary: null,
        type_account_movement: null,
        type_movement: "accounting",
        company_id: null,
        accounting_type_buy: null,
      },
      loading: true,
    };
  },
  components: {
    RangeSlider,
    Loader,
    editAccounting,
    Check,
    Times,
    newPayment,
    Edit
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    movement_status() {
      return this.buyObj.movement_status;
    },
    account_movements_record() {
      return this.buyObj.account_movements.filter(
        (account_movement) => account_movement.type_account_movement == "record"
      );
    },
    account_movements_paid() {
      return this.buyObj.account_movements.filter(
        (account_movement) => account_movement.type_account_movement == "paid" && account_movement.state != "draff"
      );
    },
    account_movements_bill() {
      return this.buyObj.account_movements.filter(
        (account_movement) => account_movement.type_account_movement == "bill" && account_movement.state != "draff"
      );
    },
  },
  watch: {
    movement_status() {
      this.buyObj.movement_status;
    },
    switchState(value){
      if (value) {
        this.payObj.type_account_movement = 'paid'
        this.payObj.accounting_type_buy = 'accounting_payable'
      } else {
        this.payObj.type_account_movement = 'bill'
        this.payObj.accounting_type_buy = 'accounting_receivable'
      }
    }
  },
  validations: {
    payObj: {
      account_id: {
        required,
      },
      total_amount: {
        required,
        minValue(value) {
          return value > 0;
        },
      },
      period: {
        required,
      },
    },
  },
  mounted() {
    this.getBuy();
    this.getAccounts();
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    formatCompleteDate,
    addAttribute(e) {
      this.buyObj.account_movements_attributes = e;
    },
    getAccounts() {
      let app = this;
      this.axios
        .get("select_paid_or_bill", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          this.accounts = response.data;
        });
    },
    permissionSave(e) {
      this.permission = e;
    },
    modifiedAccounting() {
      this.showAccouting = !this.showAccouting;
    },
    closeFunction() {
      this.$emit("getBuys");
      this.$emit("close");
    },
    totalAmount(amount) {
      let sum = amount.reduce((acc, e) => acc + e.credit, 0);
      this.min = 0;
      this.max = this.buyObj.total - sum;
      // Balance with credit note
      if (this.buyObj.credit_note_id && this.buyObj.total > this.buyObj.credit_note.total) {
        this.max = this.buyObj.total - sum - this.buyObj.credit_note.total;
      }
    },
    getFile() {
      this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
        this.url = e.data.url;
        this.filename = e.data.name;
      });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getClients() {
      this.axios.get("clients").then((response) => {
        this.clients = response.data;
      });
    },
    formatMoney(e) {
      // this.payObj.total_amount = "$" + this.formatNumber(e)
      this.payObj.total_amount = e;
    },
    getBuy() {
      this.axios.get(`movements/${this.buy_id}`).then((res) => {
        this.mov_rut = res.data.rut
        this.payObj.movement_id = res.data.id;
        this.buyObj.movement_id = res.data.id;
        this.buyObj.type_movement = res.data.type_movement
        this.buyObj.total = Number(res.data.total);
        this.buyObj.business_name = res.data.business_name;
        this.buyObj.doc_type = res.data.doc_type;
        this.buyObj.period = res.data.period;
        this.buyObj.company_id = res.data.company_id;
        this.buyObj.user_id = res.data.user_id;
        this.buyObj.folio = res.data.folio;
        this.buyObj.account_movements = res.data.account_movements;
        this.buyObj.movement_status = res.data.movement_status;
        this.buyObj.doble_match = res.data.doble_match;
        this.buyObj.classified = res.data.classified;
        this.buyObj.checksii = res.data.checksii;
        this.buyObj.category_movement = res.data.category_movement;
        this.buyObj.credit_note_id = res.data.credit_note_id;
        this.buyObj.credit_note = res.data.credit_note;
        this.buyObj.accounting_type_buy = res.data.accounting_type_buy;
        this.client = res.data.client;
        this.vendor = res.data.vendor;
        this.service_provider = res.data.service_provider;
        this.dataAcc = res.data.account_movements;
        this.getUser(res.data.user_id);
        // tengo la duda if paid or bill

        if(res.data.accounting_type_buy == 'accounting_receivable') {
          this.totalAmount(
            res.data.account_movements.filter(
              (account_movement) =>
                account_movement.type_account_movement == "bill"
            )
          );
        }

        if(res.data.accounting_type_buy == 'accounting_payable') {
          this.totalAmount(
            res.data.account_movements.filter(
              (account_movement) =>
                account_movement.type_account_movement == "paid"
            )
          );
        }
        if(res.data.accounting_type_buy == 'accounting_payable'){
          this.switchState = true;
        } else {
          this.switchState = false;
        }
        this.loading = false;
      });
    },
    movementSii() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/movement-sii", {
          params: {
            company_id: app.company_id,
            id: app.buyObj.movement_id,
          },
        })
        .then((response) => {
          response
          app.getBuy();
          setTimeout(() => {
            app.loading = false;
          }, 5000);
        });
    },
    replaceName(name) {
      switch (name) {
        case "paid":
          return "Pagado";
      }
    },
    deleteFunction(account_movement_id) {
      this.axios.delete("account_movements/" + account_movement_id).then(() => {
        this.getBuy();
      });
    },
    submitAccouting() {
      this.update(this.buyObj, this.buy_id);
    },
    update(data, id) {
      this.axios
        .patch("movements/" + id, data)
        .then((obj) => {
          this.$swal.fire(
            "Actualizado",
            "Tu egreso ha sido actualizado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file");
          this.$emit("getBuys");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        this.disabled = true;
        this.payObj.company_id = this.$store.state.company_id;
        this.axios
          .post("/account_movements", this.payObj)
          .then(() => {
            this.getBuy();
            this.payObj = {
              account_total_id: null,
              account_neto_id: null,
              movement_id: null,
              debit: null,
              total_amount: 0,
              credit: 0,
              period: null,
              comentary: null,
              type_account_movement: null,
              type_movement: "accounting",
              company_id: null,
              accounting_type_buy: null
            };
            this.switchState = false
          })
          .catch((error) => alert(error));
        setTimeout(() => {
          this.submitStatus = "OK";
          this.disabled = false;
        }, 500);
      }
    },
  },
};
</script>
<style lang="scss">
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
</style>
