<template>
  <a class="inline-block p-1.5 rounded-lg focus:outline-none hover:shadow-sm dark:hover:shadow-sm-dark" @click.prevent="toggleTheme">
    <Icon :icon="theme == 'light' ? 'heroicons:moon' : 'heroicons:sun'" class="w-5 h-5" :class="theme == 'light' ? 'text-gray-500' : 'text-yellow-400'" />
  </a>
</template>

<script>
import { Icon } from "@iconify/vue2";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      theme: localStorage.getItem("theme") || "light",
    };
  },
  methods: {
    toggleTheme() {
      this.theme == "dark" ? (this.theme = "light") : (this.theme = "dark"),
        (localStorage.theme = this.theme);
      this.theme == "dark"
        ? document.querySelector("html").classList.add("dark")
        : document.querySelector("html").classList.remove("dark");
    },
  },
};
</script>
