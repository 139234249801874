<template>
  <div
    class="py-2 mx-6"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider,
    }"
  >
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto text-xl font-semibold dark:text-white">
        Todos los Trabajadores
      </h1>

      <div class="flex gap-2">
        <a
          @click.prevent="createButton"
          class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        >
          <span class="hidden sm:block"> Agregar Trabajador </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </a>
      </div>
      <div
        class="w-full flex-none text-sm font-medium text-gray-500 mt-2"
      ></div>
    </div>
    <div class="filter w-full sm:w-2/12 pb-4 mt-2">
      <input
        type="text"
        class="input-text"
        placeholder="Buscar Trabajadores"
        @input="getAllEmployees"
        v-model="query"
      />
    </div>
    <Add
      v-if="showModal"
      @close="showModal = false"
      @getAllEmployees="getAllEmployees"
      :id="id"
      :action="action"
    ></Add>

    <div class="flex gap-4 pb-4">
      <!-- <a v-on:click.prevent="uploadSalary()" > Salario</a> -->
      <div>
        <button
          @click="exportIn()"
          class="flex items-center gap-2 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none"
        >
          <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
            <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
          </svg>
          Exportar
        </button>
      </div>
      <div>
        <button
          v-if="!excelName"
          @click="$refs.file_excel.click()"
          class="dark:text-white dark:hover:text-cian-500 flex items-center gap-2 text-gray-500  hover:text-light-blue focus:outline-none"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
          </svg>
          Importar Trabajadores
        </button>
        <div class="flex gap-2" v-else>
          <p class="truncate">{{ excelName }} |</p>
          <div class="flex gap-2">
            <a v-on:click.prevent="upload()">Subir</a>
            <a v-on:click.prevent="eliminateFile()">
              <svg class="text-red-400 w-6 h-6 hover:text-cyma-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>
        </div>
        <input
          ref="file_excel"
          class="hidden"
          @change="selectedFile"
          type="file"
          name="file"
        />
      </div>

      <div>
        <a
          href="https://cymasuite-excel-prod.s3.amazonaws.com/formatos/formato-empleado-salario-excel.xlsx"
          class="dark:text-white dark:hover:text-cian-500 flex items-center gap-2 text-gray-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 3.104v5.714a2.25 2.25 0 01-.659 1.591L5 14.5M9.75 3.104c-.251.023-.501.05-.75.082m.75-.082a24.301 24.301 0 014.5 0m0 0v5.714c0 .597.237 1.17.659 1.591L19.8 15.3M14.25 3.104c.251.023.501.05.75.082M19.8 15.3l-1.57.393A9.065 9.065 0 0112 15a9.065 9.065 0 00-6.23-.693L5 14.5m14.8.8l1.402 1.402c1.232 1.232.65 3.318-1.067 3.611A48.309 48.309 0 0112 21c-2.773 0-5.491-.235-8.135-.687-1.718-.293-2.3-2.379-1.067-3.61L5 14.5" />
          </svg>

          Descargar Formato
        </a>
      </div>
      <div>
        <a
          v-if="!salaryExcelName"
          @click.prevent="$refs.file_salary_excel.click()"
          class="dark:text-white dark:hover:text-cian-500 flex items-center gap-2 text-gray-500"
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z" />
          </svg>
          Importar Salarios
        </a>
        <div class="flex gap-2" v-else>
          <p class="truncate">{{ salaryExcelName }} |</p>
          <div class="flex gap-2">
            <a v-on:click.prevent="salaryUpload()">Subir</a>
            <a v-on:click.prevent="salaryEliminateFile()">
              <svg class="text-red-400 w-6 h-6 hover:text-cyma-purple" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </a>
          </div>
        </div>
        <input
          ref="file_salary_excel"
          class="hidden"
          @change="salarySelectedFile"
          type="file"
          name="file"
        />
      </div>
    </div>
    <BeeGridTable
      :serverSide="true"
      border
      stripe
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showSummary="false"
      :showFilter="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="employees"
      :loading="loading"
    >
      <BeeColumn field="name" title="Trabajador" type="text" :resizable="true">
        <template slot-scope="{ row }">
          <a
            href=""
            class="dark:text-cian-dark dark:hover:text-white uppercase"
            @click.prevent="
              goTo('Employees', {
                employee_id: row.id,
              })
            "
          >
            {{ row.name }} {{ row.last_name }} {{ row.mothers_last_name }}
          </a>
        </template>
      </BeeColumn>

      <!-- <BeeColumn field="amount_receivable" title="Por Cobrar" type="text">
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.balance_receivable) }}
          <div class="text-sm tracking-normal text-gray-500">
           Nº {{ formatNumber(row.number_of_movements) }}
          </div>
        </template>
      </BeeColumn> -->

      <BeeColumn field="rut" title="Rut" type="text" :resizable="true">
        <template slot-scope="{ row }">
          {{ formatRut(row.rut) }}
        </template>
      </BeeColumn>

      <BeeColumn field="date_of_birth" title="Fecha de nacimiento" type="text">
        <template slot-scope="{ row }">
          {{ formatDate(row.date_of_birth || null) }}
        </template>
      </BeeColumn>

      <BeeColumn field="position" title="Cargo" type="text">
        <template slot-scope="{ row }">
          {{ row.position }}
        </template>
      </BeeColumn>

      <!-- <BeeColumn field="cost_center_id" title="Centro De Costo Predeterminada" type="text">
        <template slot-scope="{ row }">
          {{ row.cost_center.name }}
        </template>
      </BeeColumn> -->

      <!-- <BeeColumn field="branch_office_id" title="Sucursal Predeterminada" type="text">
        <template slot-scope="{ row }">
          {{ row.branch_office.name }}
        </template>
      </BeeColumn> -->

      <BeeColumn field="editar" title="Opciones" type="text" :width="120">
        <template slot-scope="{ row }">
          <div class="flex gap-2 items-center">
            <a
              v-on:click="editFunction(row.id)"
              class="dark:text-cian-dark dark:hover:text-white"
            >
              <Edit />
            </a>
            <a
              v-on:click="deleteFunction(row.id)"
              class="dark:text-cian-dark dark:hover:text-white"
            >
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatRut, formatNumber, formatPercentage } from '@/utils/formatters'
import routerMixin from "@/mixins/routerMixin";
import Edit from "@/components/Icons/Edit.vue";
import Times from "@/components/Icons/Times.vue";
import Add from "./Add";

export default {
  name: "AllEmployees",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      id: null,
      action: null,
      showModal: false,
      sale_total: 0,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      columns: [],
      employees: [],
      excelName: "",
      salaryExcelName: "",
      attribute_sorting: null,
      sorting: null,
      loading: true,
      uploadFile: null,
      salaryUploadFile: null,
      query: null,
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.getAllEmployees();
    },
  },
  components: {
    Add,
    Edit,
    Times,
  },
  mounted() {
    this.getAllEmployees();
  },
  methods: {
    formatRut,
    formatNumber,
    formatPercentage,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getAllEmployees();
      // request api example
      // axios
      //   .post("yourDomain/yourApiRoute", {
      //     sorting,
      //     dynamicFilterInfo: getFilterCondition(filterInfos),
      //     count: size,
      //     page: offset,
      //   })
      //   .then((res) => {
      //     this.data = res.data.items; // table data
      //     this.total = res.data.total; // total data count
      //     this.page = res.data.page; // current page offset from server
      //     this.filterTotal = res.data.filteredTotal; //total filtered data count
      //   });
    },
    exportIn() {
      const params = {
        company_id: this.company_id,
      };
      // const baseUrl = "http://localhost:3000/employee/csv_export.csv";
      const baseUrl = "https://api.cymasuite.com/employee/csv_export.csv";
      const url = baseUrl + "?company_id=" + params.company_id;
      window.open(url, "_blank");
    },
    salaryEliminateFile() {
      this.salaryUploadFile = null;
      this.salaryExcelName = "";
    },
    eliminateFile() {
      this.uploadFile = null;
      this.excelName = "";
    },
    salarySelectedFile(e) {
      //Save the information of the selected File
      e.preventDefault();
      let files = e.target.files;
      this.salaryUploadFile = files[0];
      this.salaryExcelName = files[0].name;
    },
    selectedFile(e) {
      //Save the information of the selected File
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.excelName = files[0].name;
    },
    upload() {
      //POST File using FormData
      let formData = new FormData();
      formData.append("company_id", this.company_id);
      formData.append("file", this.uploadFile);
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.axios
        .post("employee/csv_import", formData, config)
        .then((response) => {
          console.log(response);
          this.getAllEmployees();
          this.eliminateFile();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    salaryUpload() {
      //POST File using FormData
      let formData = new FormData();
      formData.append("company_id", this.company_id);
      formData.append("file", this.salaryUploadFile);
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.axios
        .post("/salary_import", formData, config)
        .then((response) => {
          console.log(response);
          this.getAllEmployees();
          this.eliminateFile();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Se eliminara el trabajador",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "El trabajador ha sido eliminado",
              "success"
            );
            this.axios
              .delete(`employees/${id}`)
              .then(() => this.getAllEmployees());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El trabajador no ha sido eliminado",
              "error"
            );
          }
        });
    },
    formatDate(date) {
      if(date == null ){
        return ''
      }

      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
      };
      return new Date(date).toLocaleString("es-CL", options);
    },
    getAllEmployees() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/employees", {
          params: {
            query: app.query,
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
          },
        })
        .then((response) => {
          app.employees = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          this.loading = false;
        });
    },
  },
};
</script>

<style>
@keyframes bounceOrig {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounceNew {
  0%,
  100% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }

  50% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
}

.hover\:bounceOrig:hover {
  animation: bounceOrig 1s infinite;
}
.hover\:bounceNew:hover {
  animation: bounceNew 1s infinite;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
