<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Notificaciones
    </h2>
    <div class="grid w-full gap-4 my-3 grid-cols-1">
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Correos</label
        >
        <div class="w-full max-w-2xl">
          <v-select
            class="style-chooser custom-border"
            v-model="company.email_remuneration"
            :options="emails"
            taggable
            multiple
          />
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner2.vue';

export default {
  name: "NotificationData",
  props: {
    company: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {Spinner},
  data() {
    return {
      emails: [],
      loading: false,
    }
  },
  created(){
    this.$emit('updateTab', "NotificationData")
    this.emails = this.company.email_remuneration
  },
  methods: {
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company.id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>


<style scoped>
.style-chooser {
  --vs-controls-color: #adadad;
  --vs-selected-bg: #eee;
}
.dark .style-chooser {
  --vs-controls-color: #fafafa;
  --vs-selected-bg: #10172a;
  --vs-selected-color: #eeeeee;
}
</style>
