<template>
  <div>
    <transition name="modal">
      <div class="modal-mask-f29 flex md:justify-center">
        <div class="h-screen w-screen"></div>
        <div class="absolute w-10/12">
          <div class="
              dark:bg-background-dark
              bg-white
              py-5
              px-4
              md:w-full md:h-full md:py-4 md:px-10">
            <div class="modal-header">
              <slot name="header">
                <div class="w-full flex items-center py-4">
                  <h1
                    class="font-semibold text-grey-darker w-full text-2xl dark:text-white"
                  >
                    Formulario JSON F29
                  </h1>
                  <a
                    class="text-6xl dark:hover:text-cian-dark"
                    @click.prevent="$emit('close')"> &times; </a>
                </div>
              </slot>
            </div>
            <div class="">
              <pre> {{ domJSON }}</pre>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "@/assets/stylesheets/switch.scss";
import "@/assets/stylesheets/modal.css";
export default {
  name: "modalF29",
  props: {
    domJSON: String
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.modal-mask-f29 {
  bottom:0;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease;
}
</style>