<template>
  <div class="py-4 lg:py-5 mx-4 md:mx-6 text-grey-dark">
    <form class="w-full flex" enctype="multipart/form-data">
      <div
        class="flex flex-col p-5 rounded-lg w-full lg:w-1/2 m-auto"
      >
        <p class="font-bold text-3xl dark:text-white">Cambia tu contraseña</p>
        <div class="mt-8">
          <label
            class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >
            Contraseña Actual
          </label>
        </div>
        <div class="appearance-none w-full border rounded py-3 px-4 dark:border-black-dark dark:bg-black-dark dark:text-white flex justify-between gap-1">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="oldPassword"
            class="flex-1 dark:bg-black-dark dark:text-white"
          />
          <div class="cursor-pointer" @click="showPassword=!showPassword">
            <svg v-if="showPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
        </div>
        <div class="text_error" v-if="this.$v.oldPassword.$error">
          Este campo es requerido.
        </div>
        <div class="mt-4">
          <label
            class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >
            Nueva Contraseña
          </label>
        </div>
        <div class="appearance-none w-full border rounded py-3 px-4 dark:border-black-dark dark:bg-black-dark dark:text-white flex justify-between gap-1">
          <input
            :type="showNewPassword ? 'text' : 'password'"
            v-model="newPassword"
            class="flex-1 dark:bg-black-dark dark:text-white"
          />
          <div class="cursor-pointer" @click="showNewPassword=!showNewPassword">
            <svg v-if="showNewPassword" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
        </div>
        <div class="text_error" v-if="this.$v.newPassword.$error">
          Este campo es requerido.
        </div>
        <div class="mt-4">
          <label
            class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1 dark:text-white"
          >
            Confirme nueva Contraseña
          </label>
        </div>
        <div class="appearance-none w-full border rounded py-3 px-4 dark:border-black-dark dark:bg-black-dark dark:text-white flex justify-between gap-1">
          <input
            :type="showNewPasswordConfirm ? 'text' : 'password'"
            v-model="confirmNewPassword"
            class="flex-1 dark:bg-black-dark dark:text-white"
          />
          <div class="cursor-pointer" @click="showNewPasswordConfirm=!showNewPasswordConfirm">
            <svg v-if="showNewPasswordConfirm" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
              <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
          </div>
        </div>
        <div class="text_error" v-if="this.$v.confirmNewPassword.$error">
          Este campo es requerido.
        </div>
        <div class="mt-4">
          <button
            class="bg-black hover:bg-white hover:text-black border hover:border-black text-white py-2 px-4 rounded-full dark:bg-cian-dark dark:border-cian-dark mt-4"
            type="button"
            @click.prevent="onSubmit"
          >
            Cambiar Contraseña
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "changePassword",
  data() {
    return {
      showPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false,
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    };
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      required,
    },
    confirmNewPassword: {
      required,
    },
  },
  mounted() {
    this.resetPasswordToken = this.$route.query.reset_password_token;
  },
  methods: {
    goTo(url) {
      this.$router.push(url).catch(() => {});
    },
    onSubmit() {
      const cur = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$swal.fire(
          "Oops..",
          "Parece que te olvidaste de llenar algunos campos",
          "error"
        );
      } else {
        this.$swal
          .fire({
            title: "Estas seguro de Cambiar su contraseña?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No, regresar",
          })
          .then((result) => {
            if (result.value) {
              cur.axios
                .put("auth/password", {
                  current_password: cur.oldPassword,
                  password: cur.newPassword,
                  password_confirmation: cur.confirmNewPassword,
                })
                .then(() => {
                  this.$swal.fire(
                    "Se a Cambiado tu contraseña",
                    "Tu contraseña se a cambiado correctamente",
                    "success"
                  );
                  cur.goTo("/");
                })
                .catch(() => {
                  this.$swal.fire(
                    "Error",
                    "Se a ingresado mal los datos",
                    "error"
                  );
                });
            } else {
              this.$swal.fire(
                "Cancelado",
                "Tu contraseña no se cambiara",
                "error"
              );
            }
          });
      }
    },
  },
};
</script>
<style>
.eyeButton {
  position: absolute;
  right: 5px;
  bottom: 5px;
  top: 13px;
  color: grey;
}
</style>
