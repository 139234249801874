<script src="https://js.fintoc.com/v1/"></script>

<template>
  <button @click="abrirFintoc()">Conectar Cuenta con Fintoc</button>
</template>
<script>
export default {
  name: "addFintoc",
  props: {},
  data() {
    return {
      url: null,
      rutctnr: null,
      rut: null,
      dv: null,
      password: null,
      from: null,
      widget: null
    };
  },
  created() {},
  mounted() {
    let user_id = this.$auth.user().id;
    this.widget = Fintoc.create({
      holderType: "business",
      product: "movements",
      publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
      webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${this.company_id}_${user_id}`,
    });

  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  methods: {
    abrirFintoc() {
      this.widget.open()
    }
  },
};
</script>
<style lang="scss">
.link-frame {
  height: inherit !important;
  width: inherit !important;
}
</style>
