<script>
  import { Bar, mixins } from 'vue-chartjs'
  const { reactiveProp } = mixins

  export default {
    extends: Bar,
    mixins: [reactiveProp],
    props: { chartData: [] },
    data() {
      return {
        options: {
          tooltips: {
            callbacks: {
              label: function(tooltipItem) {
                let val = (tooltipItem.yLabel / 1).toFixed(0).replace(".", ",");
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
              }
            }
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  let val = (value / 1).toFixed(0).replace(".", ",");
                  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false,
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options, {responsive: true })
    }
  }
</script>