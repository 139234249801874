<template>
  <div class="relative overflow-hidden flex-1 bg-white h-auto">
    <section class="relative mx-auto pb-3 flex flex-col md:flex-row gap-5">
      <div
        class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-5/12 flex flex-col bg-cyma-purple-200 order-2 lg:order-none dark:bg-background-dark min-h-screen"
      >
        <div class="max-w-sm mx-auto">
          <header class="flex justify-center items-center mb-12">
            <button
              href="#"
              @click.prevent="$router.go()"
              class="block p-4 lg:p-6 outline-none border-transparent appearance-none focus:outline-none"
            >
              <img
                src="https://ptishwor.sirv.com/cyma-imgs/logo.png?format=webp"
                width="220"
                height="48"
                style="aspect-ratio: 151/29;"
                alt="cymasuite logo"
              />
            </button>
          </header>
          <div class="text-base text-center dark:text-gray-200">
            <p>
              Para finalizar, necesitamos tus datos de facturación y correo electrónico. Así, podremos enviarte la información tributaria sin problemas.
            </p>
          </div>
          <img
            src="@/assets/images/onboarding-step3.png"
            alt=""
            class="w-10/12 mx-auto mb-5 select-none pointer-events-none mt-16 sm:mb-12"
            style="aspect-ratio: 137/68;"
          >
          <div>
            <p class="text-base dark:text-gray-200 text-center mb-5">
              Puedes disfrutar aún más de nuestros servicios descargando nuestra aplicación móvil
            </p>
            <div class="flex justify-center mb-5">
              <Icon icon="heroicons:chevron-double-down" class="w-8 h-8 text-cyma-purple" />
            </div>
            <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="https://apps.apple.com/us/app/cymasuite/id1582243635" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:apple" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Download on the</div>
                  <div class="font-sans text-sm font-semibold">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gonozer.cymasuitemobile&hl=es_CL&gl=US" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:google-play" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Get in on</div>
                  <div class="font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-7/12 order-1 lg:order-none">
        <!-- Datos de Facturación -->
        <div class="max-w-xl mx-auto">
            <div class="mb-3 flex flex-col gap-4">
              <h2
                class="pt-20 uppercase text-2xl font-yesevaOne font-bold text-cyma-purple dark:text-white"
              >
                Datos de Facturación
              </h2>
              <p class="text-base dark:text-white mb-8">
                Por favor, asegúrate de llenar todos los campos para procesar tu factura correctamente. ¡Gracias por tu colaboración!
              </p>
              <div>
                <label class="block text-left uppercase text-grey-darker text-sm mb-2">
                  Rut
                </label>
                <VueRutInfo
                  :rut="billing_data.billing_data_rut"
                  @updateRut="billing_data.billing_data_rut = $event"
                  @updateName="billing_data.billing_data_business_name = $event"
                  @updateRutVerified="rut_verified_billing = $event"
                />
              </div>
              <div v-if="billing_data.billing_data_business_name">
                <label class="block text-left uppercase text-grey-darker text-sm mb-2">
                  Razón Social
                </label>
                <input
                  class="input-text"
                  type="text"
                  disabled
                  :value="billing_data.billing_data_business_name"
                />
              </div>
              <div>
                <label class="block text-left uppercase text-grey-darker text-sm mb-2">
                  Correo de Facturación
                </label>
                <input
                  type="text"
                  class="appearance-none dark:text-white block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 dark:bg-black-dark dark:border-background-dark placeholder-base"
                  placeholder="Ingrese el correo de facturación"
                  v-model="billing_data.billing_data_email"
                />
              </div>
            </div>
            <!-- <div v-else-if="chapter == 2">
              <h2 class="uppercase text-base font-bold pt-24 mb-4 text-cyma-purple dark:text-white">
                Contrato de prestación de servicio
              </h2>
              <ContractService
                @cancel="chapter = 1"
                @accept-contract="finishConfig"
                @update-navbar="updateNavbar"
              />
            </div> -->
        </div>

        <!-- botones -->
        <div class="flex mt-10 items-center gap-2 justify-center">
          <button
            @click="skip()"
            class="text-cyma-purple dark:text-white dark:hover:text-cian-dark font-semibold hover:underline focus:outline-none mx-8 p-2"
          >
            Omitir
          </button>
          <CymaButton
            @click="updateBillingData()"
            :disabled="!billing_data.billing_data_email"
            class="font-semibold text-white"
            :class="!billing_data.billing_data_email ? 'cursor-not-allowed bg-cyma-purple-light' : 'bg-cyma-purple/95 '"
          >
            Siguiente
          </CymaButton>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CymaButton from "@/components/CymaButton";
import VueRutInfo from "@/components/etc/VueRutInfo.vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "ConfigData",
  props: {
    rut: String,
    dv: String,
    rutctnr: String,
    name: String,
  },
  components: {
    CymaButton,
    VueRutInfo,
    Icon,
  },
  data() {
    return {
      showTermsAndConditions: false,
      validateRepresentative: true,
      error: "",
      emails: [],
      rut_error: null,
      sii_config: null,
      company: {
        rutcntr_partner: "",
        password_partner: "",
      },
      billing_data: {
        billing_data_rut: '',
        billing_data_business_name: '',
        billing_data_email: '',
      },
      rut_loading: false,
      rut_verified: false,
      rut_verified_billing: false,
      // name: "",
      name_billing: '',
      from: null,
      showPassword: false,
      password_error: false,
      company_id: "",
      remunerations: {
        salary_payment_date: null,
      },
      email: '',
    };
  },
  computed: {
    hasContractService() {
      return this.$auth.user().contract_service;
    },
  },
  validations: {
    company: {
      rutcntr_partner: { required },
      password_partner: { required },
    },
    billing_data: {
      billing_data_email: { required },
    },
  },
  created() {
    this.getSiiConfig(this.$cookie.get("sii_config_id"));
  },
  methods: {
    updateNavbar() {
      this.$emit("update-navbar")
    },
    getSiiConfig(id) {
      this.axios
        .get("sii_configs/" + id)
        .then((response) => {
          this.company_id = response.data.company.id;
        })
        .catch((e) => console.log(e));
    },
    updateCompany(data) {
      this.axios
        .patch("companies/" + this.company_id, data)
        .then((response) => {
          console.log(response.data);
        })
        .catch((e) => console.log(e));
    },
    skip() {
      this.updateNavbar()
      this.$emit("next-step", 'contract_step');
    },
    updateBillingData() {
      this.updateCompany(this.billing_data)
      this.skip()
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.3, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.placeholder-base::placeholder {
  font-size: 16px;
}
.style-chooser {
  --vs-controls-color: #adadad;
  --vs-selected-bg: #eee;
}
.dark .style-chooser {
  --vs-controls-color: #fafafa;
  --vs-selected-bg: #10172a;
  --vs-selected-color: #eeeeee;
}
</style>
