<template>
  <div
    class="py-2 mx-4 sm:mx-6"
  >
    <div id="profile" class="rounded-lg shadow-lg bg-white lg:mx-0 w-full dark:bg-black-dark">
      <div class="p-4 md:p-12 lg:px-4 xl:px-12">
        <div class="flex gap-4">
          <img :src='company.logo_url' class="rounded-full shadow-xl h-40 w-40 m-auto bg-cover bg-center">
          <h1 class="mt-6 text-2xl font-bold pt-8 lg:pt-0 dark:text-white w-full">{{company.business_name}}</h1>
        </div>

        <h2 class="mt-8 text-lg font-bold">Datos de empresa</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">RUT</td>
              <td class="px-2 py-2 dark:text-white" v-if="company.rut && company.dv">{{formatRut(company.rut + company.dv)}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Actividad económica</td>
              <td class="px-2 py-2 dark:text-white">
                {{company.activity_gloss}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Correo electrónico</td>
              <td class="px-2 py-2 dark:text-white uppercase">
                {{ company.email }}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Sistema tributario</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.subtype_taxpayer_descrip }}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Sucursales</td>
              <td class="px-2 py-2 dark:text-white">
                {{company.operational_unit_description}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Inicio de actividades</td>
              <td class="px-2 py-2 dark:text-white">
                {{formatSimpleDate(company.start_date_activities)}}
              </td>
            </tr>

            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Tipo de contribuyente</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.type_taxpayer_description}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Segmento</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.segment_description}}
              </td>
            </tr>

            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Tipo</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.company_person}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Tipo de contribuyente</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.type_taxpayer_description}}
              </td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Subtipo descripción del contribuyente</td>
              <td class="px-2 py-2 dark:text-white">
                {{ company.subtype_taxpayer_descrip}}
              </td>
            </tr>
          </tbody>
        </table>

        <h2 class="mt-8 text-lg font-bold">Actividades económicas</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
            <tr
              v-for="(activity, index) in economic_activities"
              :key="index"
            >
              <td class="px-2 py-2 dark:text-white">{{ activity.description }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="mt-8 text-lg font-bold">Direcciones</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
            <tr
              v-for="(address, index) in addresses"
              :key="index"
            >
              <td class="px-2 py-2 dark:text-white">{{ address.street }} {{ address.number }} {{ address.city }}</td>
            </tr>
          </tbody>
        </table>
        <!-- partners -->
        <h2 class="mt-8 text-lg font-bold">Socios</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
            <tr
            v-for="(partner, index) in partners"
              :key="index"
              >
              <td class="px-2 py-2 dark:text-white">{{ partner.names }} {{ partner.last_name }} {{ partner.mothers_last_name }}</td>
              <td class="px-2 py-2 dark:text-white">{{ formatRut(partner.rut + "-" + partner.dv) }}</td>
              <td class="px-2 py-2 dark:text-white">{{ partner.date_of_incorporation }}</td>
              <td class="px-2 py-2 dark:text-white">{{ partner.profit_sharing }} %</td>
              <td class="px-2 py-2 dark:text-white">{{ partner.equity_participation }} %</td>
            </tr>
          </tbody>
        </table>

        <h2 class="mt-8 text-lg font-bold">Representante legales</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
            <tr
            v-for="(representative, index) in representatives"
              :key="index"
              >
              <td class="px-2 py-2 dark:text-white">{{ representative.first_names }} {{ representative.paternal_surname }} {{ representative.maternal_surname }}</td>
              <td class="px-2 py-2 dark:text-white">{{ formatRut(representative.rut + "-" + representative.dv) }}</td>
              <td class="px-2 py-2 dark:text-white">{{ formatSimpleDate(representative.start_date) }}</td>
            </tr>
          </tbody>
        </table>
        <h2 class="mt-8 text-lg font-bold">Formulario 29</h2>
        <table class="text-xs my-3 w-full">
          <tbody>
          <tr v-for="movement in movements" v-bind:key="movement.id">
            <td class="px-2 py-2 dark:text-white">{{movement.business_name}}</td>
            <td class="px-2 py-2 dark:text-white">{{movement.folio}}</td>
            <td class="px-2 py-2 dark:text-white">{{ formatSimpleDate(movement.date_sii) }}</td>
            <td class="px-2 py-2 dark:text-white">$ {{formatNumber(movement.total)}}</td>
          </tr>
        </tbody>
        </table>
      </div>
    </div>
    <div class="rounded-lg shadow-lg bg-white dark:bg-black-dark w-full lg:mx-0 p-4 md:p-12 flex-grow">
      <transition name="modal-fade" mode="out-in">
        <div
          class="grid sm:grid-cols-2 gap-x-3 gap-y-4 fade-in"
        >

        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { formatRut, formatNumber, formatSimpleDate } from '@/utils/formatters'

export default {
  name: "CompanyIndex",
  props: {
    props_company_id: null,
  },
  data() {
    return {
      company: [],
      representatives: [],
      addresses: [],
      partners: [],
      movements: [],
      economic_activities: [],
      defaultImage: "url('https://source.unsplash.com/MP0IUfwrn0A')",
      profileMenu: "company",
    };
  },
  mounted() {
    this.reload();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.reload();
    },
  },
  methods: {
    formatRut,
    formatNumber,
    formatSimpleDate,
    validatePartner(){
      if(this.partners[0] != undefined){
        this.partners[0].error_code === 0
      } else {
        return false
      }
    },
    reload(){
      this.getCompany();
      this.getMovementForm29();
    },
    getMovementForm29() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            category_movement: 13,
            movement_status: app.movement_state,
            page: app.current_page,
            per_page: app.per_page,
            order_date: 'desc'
          },
        })
        .then((response) => {
          app.movements = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.without_account = response.data.without_account;
          app.movement_status = response.data.movement_status;
          app.buy_total = response.data.buy_total;
          app.exists_monthly_expense = response.data.monthly_expense;
          app.exists_pending_sii = response.data.pending_sii;
          app.loading = false;
        });
    },
    getCompany() {
      const app = this;
      this.axios.get("companies/" + app.company_id).then((response) => {
        app.company = response.data;
        app.representatives = response.data.representative_actives;
        app.addresses = response.data.addresses;
        app.partners = response.data.partners;
        app.economic_activities = response.data.economic_activities;
        app.last_movements = response.data.last_movements;
      });
    },
  },
};
</script>
