<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div id="main" ref="main" class="flex flex-col">
    <div
      @mouseover="close"
      :class="isOpen ? '' : 'hidden'"
      class="absolute bg-black h-screen left-0 w-screen opacity-25"
    ></div>
    <div
      @mouseover="open"
      @mouseleave="close"
      :class="isOpen ? 'pr-0' : 'pr-2'"
      class="z-10 h-screen flex flex-col duration-300 pl-2 rounded-r-3xl w-16 hover:w-56 relative"
    >
      <div
        :class="isOpen ? 'w-56' : 'w-16'"
        class="bg-white absolute left-0 h-screen rounded-r-3xl menu-bg transition-width duration-300 dark:bg-background-dark transition-bg"
      ></div>
      <div class="pl-4 mt-8 flex items-center gap-3.5">
        <Prisma class="h-7 flex-shrink-0 w-auto text-cyma-purple dark:text-white" />
        <Cymasuite :class="isOpen ? 'opacity-1' : 'opacity-0'" class="h-4 w-auto text-cyma-purple dark:text-white" />
      </div>

      <div class="overflow-y-auto mt-6">
        <div
          :class="isOpen ? 'w-56' : 'w-16'"
          class="container-carrousel overscroll-contain flex pl-2 gap-3"
        >
          <div v-for="(accountant, i) in accountants" :key="i + 'Accountant'" class="item flex items-center p-2 space-x-3">

            <div
              v-if="accountant.user.avatar_url"
              id="logo"
              class="w-5 h-5 bg-center bg-cover rounded-full flex-shrink-0"
              :style="{ 'background-image': `url('${accountant.user.avatar_url}')` }">
            </div>
            <div v-else class="relative w-7 h-7 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
              <svg class="absolute w-9 h-9 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
            </div>

            <!-- <img :src="accountant.user.avatar_url || defaultImage" alt="" class="w-5 h-5 bg-center bg-cover rounded-full dark:bg-gray-500" height="100" width="100"> -->
            <div :class="isOpen ? 'opacity-1' : 'opacity-0'">
              <h2 class="dark:text-white font-semibold whitespace-nowrap capitalize">
                <a
                  @click.prevent="goToWithProps('profile-accountants', accountant.user.id)"
                  class="hover:text-cyma-purple dark:hover:text-cian-dark"
                >
                  {{ accountant.user.name }} {{ accountant.user.lastname }}
                </a>
              </h2>
              <span class="flex items-center space-x-1">
                <span class="text-xs text-gray-500 dark:text-gray-400 select-none whitespace-nowrap">
                  Contador asignado
                </span>
              </span>
            </div>
          </div>
        </div>
        <div :class="openOne" >
          <a
            @mouseover="open"
            class="flex items-center cursor-pointer overflow-hidden transition duration-300 hover:bg-cyma-purple text-cyma-purple hover:text-white hover:px-2 rounded-lg dark:hover:bg-black-dark"
            @click.prevent="goTo('dashboard')"
          >
            <div class="flex dark:text-white pl-4">
              <svg class="fill-current w-5 " viewBox="0 0 24 24">
                <path
                  d="M23.9 11.437A12 12 0 000 13a11.878 11.878 0 003.759 8.712A4.84 4.84 0 007.113 23h9.767a4.994 4.994 0 003.509-1.429A11.944 11.944 0 0023.9 11.437zm-4.909 8.7A3 3 0 0116.88 21H7.113a2.862 2.862 0 01-1.981-.741A9.9 9.9 0 012 13a10.014 10.014 0 013.338-7.457A9.881 9.881 0 0111.986 3a10.553 10.553 0 011.174.066 9.994 9.994 0 015.831 17.076zM7.807 17.285a1 1 0 01-1.4 1.43A8 8 0 0112 5a8.072 8.072 0 011.143.081 1 1 0 01.847 1.133.989.989 0 01-1.133.848 6 6 0 00-5.05 10.223zm12.112-5.428A8.072 8.072 0 0120 13a7.931 7.931 0 01-2.408 5.716 1 1 0 01-1.4-1.432 5.98 5.98 0 001.744-5.141 1 1 0 011.981-.286zm-5.993.631a2.033 2.033 0 11-1.414-1.414l3.781-3.781a1 1 0 111.414 1.414z"
                />
              </svg>
            </div>
            <span class="font-medium flex-grow icon-text py-3 pl-4 w-full dark:text-white">Dashboard</span>
          </a>
        </div>
        <div>
          <a
            @mouseover="open"
            class="link mt-2"
            @click.prevent="goTo('dashboardTax')"
          >
            <div class="flex pl-4 dark:text-white">

              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
              </svg>
            </div>
            <span
              :class="isOpen ? 'w-32' : 'w-0'"
              class="font-medium flex-grow icon-text pl-4 w-full dark:text-white">
              Impuestos</span
            >
          </a>
        </div>

        <div>
          <div
            @mouseover="open"
            class="group flex items-center text-gray-700 cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
            @click.prevent="goTo('Sales')"
          >
            <div
              class="link"
            >
              <div class="flex dark:text-white px-4">
                <svg class="fill-current w-5" viewBox="0 0 24 24">
                  <path
                    d="M13.932 4A3.071 3.071 0 0117 7.068a1 1 0 002 0v-.123A5.073 5.073 0 0013.932 2H13V1a1 1 0 00-2 0v1h-.932a5.068 5.068 0 00-1.6 9.875L11 12.72V20h-.932A3.071 3.071 0 017 16.932a1 1 0 00-2 0v.123A5.073 5.073 0 0010.068 22H11v1a1 1 0 002 0v-1h.932a5.068 5.068 0 001.6-9.875L13 11.28V4zm.97 10.021a3.068 3.068 0 01-.97 5.979H13v-6.613zM11 10.613l-1.9-.634A3.068 3.068 0 0110.068 4H11z"
                  />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text w-full dark:text-white">Ingresos</span>
            </div>
            <div
              @click.stop
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Ingresos</div>
              <a
                :class="sales_and_buy ? 'hover:text-white hover:bg-cyma-purple' : ''"
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 mt-8 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Sales', 'saleCategoryMovementState', 0) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Ventas</span
                >
                <span class="pl-10 group-hover-white">Resumen de ingresos</span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Sales', 'saleCategoryMovementState', 1) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Otros Ingresos</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen de otros ingresos manuales</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Sales', 'saleCategoryMovementState', 7) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Ventas con Boleta</span
                >
                <span class="pl-10 group-hover-white"
                  >Boleta de ventas y servicios electrónica</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Sales', 'saleCategoryMovementState', 17) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Ventas Bsale</span
                >
                <span class="pl-10 group-hover-white"
                  >Boleta de ventas desde Bsale</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Sales', 'saleOnlyNoteCreditState', true) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Notas de Créditos</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen notas de créditos</span
                >
              </a>
            </div>
          </div>
        </div>
        <div>
          <div
            @mouseover="open"
            class="group flex items-center text-gray-700 cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
            @click.prevent="goTo('Buys')"
          >
            <div
              class="link"
            >
              <div class="flex dark:text-white px-4">
                <svg class="fill-current w-5" viewBox="0 0 24 24">
                  <path
                    d="M22.713 4.077A2.993 2.993 0 0020.41 3H4.242L4.2 2.649A3 3 0 001.222 0H1a1 1 0 000 2h.222a1 1 0 01.993.883l1.376 11.7A5 5 0 008.557 19H19a1 1 0 000-2H8.557a3 3 0 01-2.82-2h11.92a5 5 0 004.921-4.113l.785-4.354a2.994 2.994 0 00-.65-2.456zM21.4 6.178l-.786 4.354A3 3 0 0117.657 13H5.419l-.941-8H20.41a1 1 0 01.99 1.178z"
                  />
                  <circle cx="7" cy="22" r="2" />
                  <circle cx="17" cy="22" r="2" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text w-full dark:text-white">Egresos</span>
            </div>
            <div
              @click.stop
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Egresos</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 mt-8 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 2) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Compras</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen factura proveedores</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 3) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Honorarios</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen de boletas de honorarios</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 4) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Otros egresos</span
                >
                <span class="pl-10 group-hover-white"
                  >Gastos manuales - invoices</span
                >
              </a>
              <!-- <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-black transition-width duration-300 group"
                @click.prevent="goToWithProps('Buys', { category_id: 6 })"
              >
                <span
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Impuestos</span
                >
                <span class="pl-10 group-hover-white">F29 F50 F22</span>
              </a> -->
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 5) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Remuneraciones</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen de sueldos y cotizaciones</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 18) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Creditos</span
                >
                <span class="pl-10 group-hover-white"
                  >Instrumentos financieros</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 14) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Cotizaciones</span
                >
                <span class="pl-10 group-hover-white"
                  >Previred</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 13) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Declaración Impuestos</span
                >
                <span class="pl-10 group-hover-white"
                  >Formulario 29</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys','buyCategoryMovementState', 15) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Operación Renta</span
                >
                <span class="pl-10 group-hover-white"
                  >Formulario 22</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="sales_and_buy ? goToWithState('Buys', 'buyOnlyNoteCreditState', true) : ''"
              >
                <span
                  :class="sales_and_buy ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Notas de Créditos</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen Notas de Créditos</span
                >
              </a>
            </div>
          </div>
        </div>
        <!-- <div>
          <div
            @mouseover="open"
            class="group flex items-center text-gray-700 cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
            @click.prevent="goTo('F29')"
          >
            <div
              class="link"
            >
              <div class="flex text-gray-600 px-4 dark:text-white">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text w-full dark:text-white">Impuestos</span>
            </div>
            <div
              @click.stop
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Impuestos</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-black transition-width duration-300 group mt-8"
                @click.prevent="goTo('F29')"
              >
                <span
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Formulario F29</span
                >
                <span class="pl-10 group-hover-white">Declaraciones mensual de impuestos F29</span>
              </a>
            </div>
          </div>
        </div> -->
        <div>
          <div
            :class="myBank ? '' : 'text-gray-500'"
            @mouseover="open"
            class="group flex items-center cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
          >
            <div
              :class="myBank ? '' : 'text-gray-500 hover:bg-gray-500'"
              class="link"
            >
              <div class="flex pl-4 dark:text-white">
                <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="myBank">
                  <path
                    d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                  />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text pl-4 w-full dark:text-white"
                >Banco</span
              >
            </div>
            <div
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Banco</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 mt-8 group dark:hover:bg-background-dark"
                @click.prevent="myBank ? openFintoc() : ''"
              >
                <span
                  :class="myBank ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Agregar cuenta bancaria</span
                >
                <span class="pl-10 group-hover-white"
                  >Integración con Fintoc</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="myBank ? goTo('transaction') : ''"
              >
                <span
                  :class="myBank ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Movimientos</span
                >
                <span class="pl-10 group-hover-white"
                  >Supercartola todos tus movimientos</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="myBank ? goTo('banks') : ''"
              >
                <span
                  :class="myBank ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Cuentas bancarias</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen de cuentas bancarias</span
                >
              </a>
            </div>
          </div>
        </div>

        <div>
          <div
            :class="contact ? '' : 'text-gray-500'"
            @mouseover="open"
            class="group flex items-center cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
          >
            <div
              :class="contact ? '' : 'text-gray-500 hover:bg-gray-500'"
              class="link"
            >
              <div class="flex pl-4 dark:text-white">
                <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="contact">
                  <path
                    d="M12 12a6 6 0 10-6-6 6.006 6.006 0 006 6zm0-10a4 4 0 11-4 4 4 4 0 014-4zm0 12a9.01 9.01 0 00-9 9 1 1 0 002 0 7 7 0 0114 0 1 1 0 002 0 9.01 9.01 0 00-9-9z"
                  />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text pl-4 w-full dark:text-white">Contactos</span>
            </div>
            <div
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Contactos</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 mt-8 group dark:hover:bg-background-dark"
                @click.prevent="contact ? goTo('AllClients') : ''"
              >
                <span
                  :class="contact ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Clientes</span
                >
                <span class="pl-10 group-hover-white"
                  >Receptores de un bien y servicio</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="contact ? goTo('AllVendors') : ''"
              >
                <span
                  :class="contact ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Proveedores</span
                >
                <span class="pl-10 group-hover-white"
                  >Abastece a otras empresas con existencias</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="contact ? goTo('serviceProvider') : ''"
              >
                <span
                  :class="contact ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Proveedores de servicios</span
                >
                <span class="pl-10 group-hover-white"
                  >Lista de proveedores de servicios</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="contact ? goTo('AllEmployees') : ''"
              >
                <span
                  :class="contact ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Trabajadores</span
                >
                <span class="pl-10 group-hover-white"
                  >Persona contratada por la empresa</span
                >
              </a>
              <!-- <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-black transition-width duration-300 group"
                @click.prevent="contact ? goTo('AllPartners') : ''"
              >
                <span
                :class="contact ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Socios</span
                >
                <span class="pl-10 group-hover-white"
                  >Persona asociadas a la empresa</span
                >
              </a> -->
            </div>
          </div>
        </div>
        <div>
          <a
            :class="accounting ? 'mt-2' : 'text-gray-500 hover:bg-gray-500 mt-2'"
            @mouseover="open"
            class="link"
            @click.prevent="accounting ? goTo('accounting') : ''"
          >
            <div class="flex pl-4 dark:text-white">
              <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="accounting">
                <path
                  d="M18 24H6a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h12a5.006 5.006 0 015 5v14a5.006 5.006 0 01-5 5zM6 2a3 3 0 00-3 3v14a3 3 0 003 3h12a3 3 0 003-3V5a3 3 0 00-3-3zm10 8H8a3 3 0 010-6h8a3 3 0 010 6zM8 6a1 1 0 000 2h8a1 1 0 000-2zm-2 7a1 1 0 101 1 1 1 0 00-1-1zm4 0a1 1 0 101 1 1 1 0 00-1-1zm4 0a1 1 0 101 1 1 1 0 00-1-1zm-8 4a1 1 0 101 1 1 1 0 00-1-1zm4 0a1 1 0 101 1 1 1 0 00-1-1zm8-4a1 1 0 101 1 1 1 0 00-1-1zm1 5a1 1 0 00-1-1h-4a1 1 0 000 2h4a1 1 0 001-1z"
                />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <span
              :class="isOpen ? 'w-32' : 'w-0'"
              class="font-medium flex-grow icon-text pl-4 w-full dark:text-white"
            >
              Comprobantes
            </span>
          </a>
        </div>

        <div>
          <a
            :class="accounting ? 'mt-2' : 'text-gray-500 hover:bg-gray-500 mt-2'"
            @mouseover="open"
            class="link"
            @click.prevent="accounting ? goTo('CreditsIndex') : ''"
          >
            <div class="flex pl-4 dark:text-white">
              <svg xmlns="http://www.w3.org/2000/svg" v-if="accounting" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <span
              :class="isOpen ? 'w-32' : 'w-0'"
              class="font-medium flex-grow icon-text pl-4 w-full dark:text-white"
            >
              Creditos
            </span>
          </a>
        </div>
        <div>
          <div
            :class="report ? '' : 'text-gray-500'"
            @mouseover="open"
            class="group flex items-center text-gray-700 cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
          >
            <div
              :class="report ? '' : 'text-gray-500 hover:bg-gray-500'"
              class="link"
            >
              <div class="flex pl-4 dark:text-white">
                <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="report">
                  <path
                    d="M17 14a1 1 0 01-1 1H8a1 1 0 010-2h8a1 1 0 011 1zm-4 3H8a1 1 0 000 2h5a1 1 0 000-2zm9-6.515V19a5.006 5.006 0 01-5 5H7a5.006 5.006 0 01-5-5V5a5.006 5.006 0 015-5h4.515a6.958 6.958 0 014.95 2.05l3.484 3.486A6.951 6.951 0 0122 10.485zm-6.949-7.021A5.01 5.01 0 0014 2.684V7a1 1 0 001 1h4.316a4.983 4.983 0 00-.781-1.05zM20 10.485c0-.165-.032-.323-.047-.485H15a3 3 0 01-3-3V2.047c-.162-.015-.321-.047-.485-.047H7a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3z"
                  />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text pl-4 w-full dark:text-white"
                >Reportes</span
              >
            </div>
            <div
              class="absolute h-full left-1 overflow-y-scroll hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl bg-gray-200 dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white sticky top-0 bg-gray-200 dark:bg-black-dark">Reportes</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 mt-8 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('diaryBook') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Libro diario</span
                >
                <span class="pl-10 group-hover-white"
                  >Registro diario de los hechos economicos</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('ledger') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Libro Mayor</span
                >
                <span class="pl-10 group-hover-white"
                  >Registro consolidado de cuentas contables</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="goTo('remunerations')"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Libro de Remuneraciones</span
                >
                <span class="pl-10 group-hover-white"
                  >Registro consolidado de remuneraciones</span
                >
              </a>

              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('reports') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                >
                Balance</span>
                <span class="pl-10 group-hover-white"
                  >Situación económica y financiera
                </span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('statementOfIncome') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                >
                Estado Resultado</span>
                <span class="pl-10 group-hover-white"
                  >Situación económica y financiera
                </span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('statementOfIncomeComparative') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                >
                Estado Resultado Comparativo</span>
                <span class="pl-10 group-hover-white"
                  >Situación económica y financiera
                </span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('cashflow') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                >
                Flujo de Caja</span>
                <span class="pl-10 group-hover-white"
                  >Movimiento real o virtual de dinero.
                </span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                @click.prevent="report ? goTo('historial') : ''"
              >
                <span
                  :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                >
                Historial</span>
                <span class="pl-10 group-hover-white"
                  >Historial de solicitudes realizadas
                </span>
              </a>
              <!-- <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-black transition-width duration-300 group"
                @click.prevent="goTo('rli')"
              >
                <span
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >RLI</span
                >
                <span class="pl-10 group-hover-white"
                  >Renta Líquida Imponible
                </span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-black transition-width duration-300 group"
                @click.prevent="goTo('reportsTax')"
              >
                <span
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Impuestos</span
                >
                <span class="pl-10 group-hover-white"
                  >Impuestos mensuales
                </span>
              </a> -->
            </div>
          </div>
        </div>

        <!--
        <div>
          <div
            :class="myBusiness ? '' : 'text-gray-500'"
            @mouseover="open"
            class="group flex items-center cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
          >
            <div
              class="link"
              :class="myBusiness ? '' : 'text-gray-500 hover:bg-gray-500'"
              @click.prevent="myBusiness ? goTo('Company') : ''"
            >
              <div class="flex px-4 dark:text-white">
                <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="myBusiness">
                  <path
                    d="M19 4h-1.1A5.009 5.009 0 0013 0h-2a5.009 5.009 0 00-4.9 4H5a5.006 5.006 0 00-5 5v10a5.006 5.006 0 005 5h14a5.006 5.006 0 005-5V9a5.006 5.006 0 00-5-5zm-8-2h2a3 3 0 012.816 2H8.184A3 3 0 0111 2zM5 6h14a3 3 0 013 3v3H2V9a3 3 0 013-3zm14 16H5a3 3 0 01-3-3v-5h9v1a1 1 0 002 0v-1h9v5a3 3 0 01-3 3z"
                  />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span class="font-medium flex-grow icon-text w-full dark:text-white">Empresas</span>
            </div>
            <div
              class="absolute h-full bg-gray-200 left-1 overflow-y-scroll hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl mb-8 dark:text-white bg-gray-200 dark:bg-black-dark sticky top-0 ">Empresas</div>
              <div v-for="(company, k) in dataCompanies" :key="k">
                <a
                  class="flex items-center py-4 text-gray-500 hover:text-white hover:bg-cyma-purple transition-width duration-300 group dark:hover:bg-background-dark"
                  @click.prevent="myBusiness ? goToCompany('CompanyIndex', { props_company_id: company.id }) : ''">
                  <span
                    :class="report ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                    class="flex flex-col pl-10 text-black group-hover-white font-bold"
                  >
                    <span>{{ company.business_name }}</span>
                    <span class="text-xs text-gray-500 group-hover-white">{{ formatRut(company.rut + company.dv) }}</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div> -->

        <div>
          <div
            :class="configAccounting ? '' : 'text-gray-500'"
            class="group flex items-center cursor-pointer transition duration-300 overflow-hidden hover-trigger mt-2"
          >
            <div
              class="link"
              :class="myBusiness ? '' : 'text-gray-500 hover:bg-gray-500'"
            >
              <div class="flex pl-4 dark:text-white">
                <svg class="fill-current w-5" viewBox="0 0 24 24" v-if="configAccounting">
                  <path
                    d="m20.537 12.7-1.13-.7 1.131-.7a4.126 4.126 0 0 0 1.729-2.031 3.919 3.919 0 0 0 -3.28-5.272 4.124 4.124 0 0 0 -2.586.654l-.401.249v-.728a4.116 4.116 0 0 0 -3.607-4.153 4 4 0 0 0 -4.393 3.981v.9l-.4-.25a4.122 4.122 0 0 0 -2.587-.657 3.918 3.918 0 0 0 -3.283 5.27 4.123 4.123 0 0 0 1.73 2.031l1.133.706-1.131.7a4.126 4.126 0 0 0 -1.729 2.031 3.918 3.918 0 0 0 3.286 5.272 4.124 4.124 0 0 0 2.581-.651l.4-.252v.9a4 4 0 0 0 8 0v-.9l.4.251a4.126 4.126 0 0 0 2.58.653 3.918 3.918 0 0 0 3.284-5.272 4.128 4.128 0 0 0 -1.727-2.032zm-.311 4.418a1.916 1.916 0 0 1 -2.639.613l-2.059-1.282a1 1 0 0 0 -1.528.851v2.7a2 2 0 0 1 -4 0v-2.7a1 1 0 0 0 -1.528-.849l-2.059 1.284a1.915 1.915 0 1 1 -2.025-3.252l2.625-1.634a1 1 0 0 0 0-1.7l-2.625-1.633a1.915 1.915 0 0 1 2.025-3.252l2.059 1.282a1 1 0 0 0 1.528-.846v-2.593a2.075 2.075 0 0 1 1.664-2.08 2 2 0 0 1 2.336 1.973v2.7a1 1 0 0 0 1.528.848l2.059-1.281a1.915 1.915 0 1 1 2.025 3.252l-2.625 1.634a1 1 0 0 0 0 1.7l2.625 1.634a1.914 1.914 0 0 1 .614 2.638z"
                  />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                </svg>
              </div>
              <span
                :class="isOpen ? 'w-32' : 'w-0'"
                class="font-medium flex-grow icon-text pl-4 w-full dark:text-white"
                >Configuración</span
              >
            </div>
            <div
              class="absolute h-full bg-gray-200 left-1 overflow-hidden hover-target top-0 flex flex-col transform -translate-x-6 submenu-bg rounded-r-3xl dark:bg-black-dark"
            >
              <div class="font-bold pl-10 pt-10 text-3xl dark:text-white">Configuración</div>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark mt-8"
                @click.prevent="myBusiness ? goTo('Company') : ''"
              >
                <span
                  :class="myBusiness ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Empresas</span
                >
                <span class="pl-10 group-hover-white">Directorio de configuración de empresas</span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="myBusiness ? goTo('account') : ''"
              >
                <span
                  :class="myBusiness ? 'text-black group-hover-white dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Plan de cuentas</span
                >
                <span class="pl-10 group-hover-white"
                  >Resumen de todas las cuentas contables</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="myBusiness ? goTo('tax') : ''"
              >
                <span
                  :class="myBusiness ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Impuesto</span
                >
                <span class="pl-10 group-hover-white"
                  >Clase de tributo regido por derecho público</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="myBusiness ? goTo('costCenter') : ''"
              >
                <span
                  :class="myBusiness ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Centro de Costos</span
                >
                <span class="pl-10 group-hover-white"
                  >Unidad del negocio que genera costos</span
                >
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="myBusiness ? goTo('branchOffice') : ''"
              >
                <span
                  :class="myBusiness ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Sucursales</span
                >
                <span class="pl-10 group-hover-white">Salida de una empresa</span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="myBusiness ? goTo('Currency') : ''"
              >
                <span
                  :class="myBusiness ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Indicadores Financieros</span
                >
                <span class="pl-10 group-hover-white"> Comisión para el Mercado Financiero</span>
              </a>
              <a
                class="flex flex-col py-4 text-gray-500 hover:text-white hover:bg-cyma-purple group dark:hover:bg-background-dark"
                @click.prevent="support ? goTo('AllRequests') : ''"
              >
                <span
                  :class="support ? 'dark:text-white' : 'text-gray-500'"
                  class="pl-10 text-black group-hover-white uppercase font-bold"
                  >Soporte</span
                >
                <span class="pl-10 group-hover-white"> Servicios externos a la plataforma</span>
              </a>
            </div>
          </div>
        </div>
         <div>
          <a
            @mouseover="open"
            class="link"
            :class="myBusiness ? 'mt-2' : 'text-gray-500 hover:bg-gray-500 mt-2'"
            @click.prevent="myBusiness ? goTo('integration') : ''"
          >
            <div class="flex pl-4 dark:text-white">
              <svg  v-if="myBusiness" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="m21 7.5-9-5.25L3 7.5m18 0-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" :stroke="'currentColor'">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
              </svg>
            </div>
            <span
              :class="isOpen ? 'w-32' : 'w-0'"
              class="font-medium flex-grow icon-text pl-4 w-full dark:text-white">
              Integraciónes</span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatRut } from '@/utils/formatters'
import Prisma from '@/components/Icons/Prisma.vue';
import Cymasuite from '@/components/Icons/Cymasuite.vue';

export default {
  name: "Slider",
  props: ['dataCompanies'],
  components: {
    Prisma,
    Cymasuite
  },
  data() {
    return {
      isOpen: false,
      companies: [],
      products: [],
      accounting: false,
      sales_and_buy: false,
      contact: false,
      configAccounting: false,
      myFiles: false,
      appCymasuite: false,
      audit: false,
      myBank: false,
      myBusiness: false,
      myTeam: false,
      support: false,
      budget: false,
      myExpenses: false,
      warehouseManagement: false,
      dailyCollection: false,
      tax: false,
      report: false,
      marketplace: this.$store.state.marketplace,
      companyUserId: null,
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      appTheme: localStorage.getItem('theme'),
      accountants: [
        {
          user: {
            name: '',
            lastname: '',
            avatar_url: 'https://source.unsplash.com/MP0IUfwrn0A'
          }
        }
      ]
    };
  },
  created() {
    this.setCompany();
    this.getPermissions();
  },
  mounted() {
  },
  computed: {
    user(){
      return this.$auth.user()
    },
    openSlider() {
      return this.$store.state.booleanSlider;
    },
    company_id() {
      return this.$store.state.company_id;
    },
    openOne() {
      return {
        'pt-1': this.isOpen && this.accountants.length == 1,
      }
    }
  },
  watch: {
    company_id() {
      this.setCompany();
      this.getPermissions();
    },
    companyUserId(){
      this.validateCompanySubscriptions();
      this.validateCurrentUserOrders();
    }
  },
  methods: {
    formatRut,
    getPermissions() {
      this.axios.get("/permissions",{
        params:{
          company_id: this.company_id,
          role: 1,
          state: 0,
        }
      }).then((response) => {
        this.accountants = response.data
      });
    },
    openFintoc() {
      let cur = this;
      let user_id = this.$auth.user().id;
      this.widget = Fintoc.create({
        holderType: "business",
        product: "movements",
        publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
        webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${cur.company_id}_${user_id}`,
      });
      this.widget.open();
    },
    goToWithState(url, mutation, value) {
      if(url == 'Buys' && mutation != 'buyOnlyNoteCreditState' ){
        this.$store.commit('buyOnlyNoteCreditState', false)
      } else {
        this.$store.commit('buyCategoryMovementState', null)
      }
      if(url == 'Sales' && mutation != 'saleOnlyNoteCreditState' ){
        this.$store.commit('saleOnlyNoteCreditState', false)
      } else{
        this.$store.commit('saleCategoryMovementState', null)
      }
      this.$store.commit(mutation, value );
      this.$router.push({ name: url, params: { company_id: this.company_id }}).catch(() => {});
    },
    goToWithProps(url, data = null) {
      this.$router.push({ name: url, params: {accountant: data}}).catch(() => {});
    },
    goTo(url) {
      this.$router.push({ name: url, params: { company_id: this.company_id }}).catch(() => {});
    },
    goToCompany(url, data = null) {
      this.$store.commit("addCompanyId", data.company_id);
      this.$router.push({ name: url, params: data }).catch(() => {});
    },
    goToProfile(url, data = null) {
      this.$router.push({ name: url, params: data }).catch(() => {});
    },
    toggle() {
      this.isOpen = !this.isOpen;
    },
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
    setCompany() {
      var item = this.dataCompanies.find(item => item.id == this.company_id);
      if(item) {
        this.companyUserId = item.sii_config.user_id
      }
    },
    validateCompanySubscriptions() {
      const cur = this;
      const accessToken = cur.$cookie.get("access-token")
      // Contabilidad
      cur.accounting = false;
      // Ingresos y Egresos
      cur.sales_and_buy = false;
      // Contactos
      cur.contact = false;
      // Mis archivos
      cur.myFiles = false;
      // App cymasuite
      cur.appCymasuite = false;
      // Auditoria
      cur.audit = false;
      // Banco
      cur.myBank = false;
      // Empresa
      cur.myBusiness = false;
      // Mi equipo
      cur.myTeam = false;
      // Presupuesto
      cur.budget = false;
      // Mis gastos
      cur.myExpenses = false;
      // Gestión de bodega
      cur.warehouseManagement = false;
      // Cobranza al dia
      cur.dailyCollection = false;
      // Tributario
      cur.tax = false;
      // Configuración Contable
      cur.configAccounting = false;
      // Reportes
      cur.report = false;
      // Soporte
      cur.support = false;
      this.axios.get(cur.marketplace + "/api/v1/client/access", {
        headers: {
          authorization: "Bearer " + accessToken
        },
        params: {
          user_id: cur.companyUserId,
          status: 2,
          active: true
        }
      })
      .then((response) => {
        if ((response.data && response.data?.subscription && response.data?.subscription.products) ||  response.data?.subscription.demo == true ) {
          if (response.data.user_id == this.user.id){
            cur.$store.commit("addValidatedOrder", true);
          } else {
            cur.$store.commit("addValidatedOrder", false);
          }
          // activo storage
          cur.$store.commit("addValidatedOrderUser", true);
          // Contabilidad
          cur.accounting = true;
          // Ingresos y Egresos
          cur.sales_and_buy = true;
          // Contactos
          cur.contact = true;
          // Mis archivos
          cur.myFiles = true;
          // App cymasuite
          cur.appCymasuite = true;
          // Auditoria
          cur.audit = true;
          // Banco
          cur.myBank = true;
          // Empresa
          cur.myBusiness = true;
          // Mi equipo
          cur.myTeam = true;
          // Presupuesto
          cur.budget = true;
          // Mis gastos
          cur.myExpenses = true;
          // Gestión de bodega
          cur.warehouseManagement = true;
          // Cobranza al dia
          cur.dailyCollection = true;
          // Tributario
          cur.tax = true;
          // Configuración Contable
          cur.configAccounting = true;
          // Reportes
          cur.report = true;
          // Soporte
          cur.support = true;
        } else{
          cur.$store.commit("addValidatedOrder", false);
          cur.$store.commit("addValidatedOrderUser", false);
        }
      });
    },
    validateCurrentUserOrders() {
      const cur = this;
      const accessToken = cur.$cookie.get("access-token")
      this.axios.get(cur.marketplace + "/api/v1/client/access", {
        headers: {
          authorization: "Bearer " + accessToken
        },
        params: {
          user_id: cur.user.id,
          status: 2,
          active: true
        }
      })
      .then((response) => {
        if ((response.data && response.data.subscription && response.data.subscription.products) ||  response.data.subscription.demo == true ) {
          cur.$store.commit("addValidatedOrderCurrentUser", true);
          return
        } else{
          cur.$store.commit("addValidatedOrderCurrentUser", false);
        }
      }).catch(() => {
        cur.$store.commit("addValidatedOrderCurrentUser", false);
      });
    },
  },
};
</script>

<style scoped>
.closebtn {
  top: 0;
  font-size: 36px;
  /* margin-left: 50px; */
}
::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 5px;
}

.container-carrousel::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:active {
  background: #315079;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.container-carrousel {
  transition: all .2 ease;
}
.container-carrousel::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 6px;
  border: 2px solid transparent;
}

.container-carrousel:hover::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
  border: 2px solid transparent;
}

.container-carrousel {
  overflow: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 8px;
}
.container-carrousel .item {
  scroll-snap-align: start;
  flex: 0 0 100%;
}
.h-slider {
  height: calc(100vh - 2rem);
}
.left-1 {
  left: 100%;
}
.hover-trigger > .hover-target {
  width: 0;
  transition: 0.2s;
}
.hover-trigger:hover > .hover-target {
  width: 150%;
}
.submenu-bg {
  z-index: -9999999;
}
.menu-bg {
  z-index: -999;
}
.padl-hover {
  transition: 0.1s;
}
.padl-hover:hover {
  padding-left: 1em;
}
.group:hover > .group-hover-white {
  color: white;
}
.dark .slider-icon path{
  stroke: white;
}
</style>
