<template>
  <div
    class="relative w-full bg-gray-50 shadow-[6px_6px_10px_-1px_rgba(0,0,0,0.10)] rounded-lg overflow-hidden"
  >
    <input
      type="checkbox"
      class="peer absolute top-0 inset-x-0 h-28 opacity-0 z-10 cursor-pointer"
    />
    <!-- Header -->
    <div class="p-4">
      <h2 class="text-lg font-medium leading-6 text-gray-900">{{ title }}</h2>
      <p class="mt-4">
        <span class="text-4xl font-extrabold text-gray-900">
          {{ price }} UF
        </span>
        <span class="text-base font-medium text-gray-500">+ IVA</span>
      </p>
    </div>
    <!-- Arrow -->
    <div
      class="arrow absolute top-10 right-5 transition-transform duration-300 rotate-0 peer-checked:rotate-180"
    >
      <ChevronDown class="w-6 h-6 text-gray-400" />
    </div>
    <!-- Content -->
    <div class="content bg-white max-h-0 overflow-hidden peer-checked:max-h-full">
      <slot />
    </div>
  </div>
</template>

<script>
import ChevronDown from '@/components/Icons/ChevronDown.vue'

export default {
  components: { ChevronDown },
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>
input:checked ~ .arrow {
  transform: rotate(180deg);
}

input:checked ~ .content {
  max-height: 100%;
}


</style>
