<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Usuarios permitidos
    </h2>
    <div
      class="relative grid gap-4 my-4 mb-12 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6"
      v-for="(permission, i) in company.permissions_attributes"
      :key="i"
    >
      <div class="">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Nombre
        </label>
        <input
          disabled
          class="input-text uppercase"
          type="text"
          :value="
            permission.user_id == null ? 'Pendiente' : permission.user.name
          "
        />
      </div>
      <div class="">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Apellido
        </label>
        <input
          disabled
          class="input-text uppercase"
          type="text"
          :value="
            permission.user_id == null ? 'Pendiente' : permission.user.lastname
          "
        />
      </div>
      <div class="">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Email
        </label>
        <input
          disabled
          class="input-text"
          type="text"
          :value="
            permission.user_id == null
              ? permission.email
              : permission.user.email
          "
        />
      </div>
      <div class="">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Estado de Invitacion
        </label>
        <input
          disabled
          class="input-text uppercase"
          type="text"
          :value="stateFormat(permission.state)"
        />
      </div>
      <div class="">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          Permisos
        </label>
        <v-select
          class="style-chooser custom-border"
          :clearable="false"
          :disabled="
            company.sii_config_attributes.user_id === permission.user_id
          "
          :reduce="(role) => role.value"
          :options="[
            { value: 'admin', name: 'Administrador' },
            { value: 'accountant', name: 'Contador' },
            { value: 'egress_digitizer', name: 'Digitador de Egreso' },
          ]"
          v-model="permission.role"
          label="name"
        ></v-select>
        <!-- <input
              disabled
              class="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter dark:bg-black-dark dark:border-black-dark"
              type="text"
              :value="stateFormat(permission.state)"
            /> -->
      </div>
      <div class="flex items-center">
        <button
          type="button"
          class="px-4 text-sm dark:text-cian-dark"
          v-on:click="deletePermission(permission.id)"
          v-if="company.sii_config_attributes.user_id === user.id"
        >
          Eliminar Permisos
        </button>
      </div>
    </div>
    <div class="relative col-end-2 my-2">
      <button
        class="dark:text-cian-dark"
        type="button"
        v-if="company.sii_config_attributes.user_id === user.id"
        @click.prevent="$emit('showModal', true)"
        >
        Agregar nuevo usuario
      </button>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue';
export default {
  name: "UsersAllowed",
  props: {
    company: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  components: {Spinner},
  data () {
    return {
      user: this.$auth.user(),
      loading: false
    }
  },
  created() {
    this.$emit('updateTab', "UsersAllowed")
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
    stateFormat(value) {
      !value ? (value = "accepted") : value
      const data = {
        accepted: "Aceptado",
        pending: "Pendiente",
        rejected: "Rechazado",
      }
      return data[value]
    },
    deletePermission(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Se retiraran los permisos asociadas a esta cuenta",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminar permisos",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Los permisos asociados a la cuenta han sido eliminados",
              "success"
            )
            this.axios
              .delete(`permissions/${id}`)
              .then(() => this.getData(this.sii_config_id))
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Los permisos no han sido eliminados",
              "error"
            )
          }
        })
    },
  },
}
</script>
