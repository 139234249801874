<template>
  <div>
    <div class="w-full">
      <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
        Integraciones
      </h2>
      <div class="grid w-full gap-4 sm:grid-cols-2 lg:grid-cols-3">
        <div class="flex flex-col h-full my-1.5">
          <label
            class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
            >Token Openfactura</label
          >
          <div
          class="flex justify-between input-text bg-white"
          >
            <input
              type="text"
              :disabled="company.integrations_connections.openfactura"
              :class="[company.integrations_connections.openfactura ? 'no-integration': '']"
              v-model="company.openfactura_key"
              :placeholder="company.integrations_connections.openfactura ? '*********': 'Ingresar Contraseña'"
              class="flex-1 bg-transparent"
            />
            <div
              class="cursor-pointer"
              @click="company.integrations_connections.openfactura = !company.integrations_connections.openfactura"
            >
              <LockOpen v-if="!company.integrations_connections?.openfactura" class="w-5 h-5" />
              <LockClosed v-else class="w-5 h-5" />
            </div>
          </div>
          <div
            class="text-red-500 select-none"
            v-if="company.open_factura_state == 'denied'"
          >
            La contraseña actual es incorrecta
          </div>
        </div>
      </div>

      <p
        class="block text-xs font-bold tracking-wide text-gray-500 select-none md:text-left dark:text-white"
      >
        Puede descargar los DTEs desde la
        <a
          href="https://www.openfactura.cl/factura-electronica/api/"
          target="_blank"
          >API de OpenFactura.</a
        >
      </p>
    </div>

    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full my-1.5">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Token Bsales</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.bsales"
            :class="[company.integrations_connections.bsales ? 'no-integration': '']"
            v-model="company.bsale_token"
            :placeholder="company.integrations_connections.bsales ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent"
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.bsales = !company.integrations_connections.bsales"
          >
            <LockOpen v-if="!company.integrations_connections?.bsales" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full my-1.5">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          input-class="xmx-input"
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.40rem',
            'padding-right': '0.40rem',
            'padding-top': '0.40rem',
            'padding-bottom': '0.40rem',
            'border-radius': '5px',
          }"
          type="date"
          format="DD-MM-YYYY"
          v-model="company.bsale_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>

    <!-- Ventipay -->
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full my-1.5">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Token Ventipay</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.ventipay"
            :class="[company.integrations_connections.ventipay ? 'no-integration': '']"
            v-model="company.ventipay_token"
            :placeholder="company.integrations_connections.ventipay ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent "
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.ventipay = !company.integrations_connections.ventipay"
          >
            <LockOpen v-if="!company.integrations_connections.ventipay" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full my-1.5">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          input-class="xmx-input"
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.40rem',
            'padding-right': '0.40rem',
            'padding-top': '0.40rem',
            'padding-bottom': '0.40rem',
            'border-radius': '5px',
          }"
          type="date"
          format="DD-MM-YYYY"
          v-model="company.ventipay_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>
    <!-- Mercado Pago -->
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Token Mercado Pago</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.mercadopago"
            :class="[company.integrations_connections.mercadopago ? 'no-integration': '']"
            v-model="company.mercado_pago_token"
            :placeholder="company.integrations_connections.mercadopago ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent "
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.mercadopago = !company.integrations_connections.mercadopago"
          >
            <LockOpen v-if="!company.integrations_connections.mercadopago" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full my-1.5">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.40rem',
            'padding-right': '0.40rem',
            'padding-top': '0.40rem',
            'padding-bottom': '0.40rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.mercado_pago_from"
          class="border dark:bg-black-dark dark:border-background-dark"
          input-class="xmx-input"
          type="date"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>
    <!-- Flow -->
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Api Key Flow</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.flow_api"
            :class="[company.integrations_connections.flow_api ? 'no-integration': '']"
            v-model="company.flow_api_key"
            :placeholder="company.integrations_connections.flow_api ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent "
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.flow_api = !company.integrations_connections.flow_api"
          >
            <LockOpen v-if="!company.integrations_connections.flow_api" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Secret Key Flow</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.flow_secret"
            :class="[company.integrations_connections.flow_secret ? 'no-integration': '']"
            v-model="company.flow_secret_key"
            :placeholder="company.integrations_connections.flow_secret ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent "
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.flow_secret = !company.integrations_connections.flow_secret"
          >
            <LockOpen v-if="!company.integrations_connections.flow_secret" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full my-1.5">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.40rem',
            'padding-right': '0.40rem',
            'padding-top': '0.40rem',
            'padding-bottom': '0.40rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.flow_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          input-class="xmx-input"
          type="date"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>

    <!-- Talana -->

    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Usuario Talana</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.talana_username"
            :class="[company.integrations_connections.talana_username ? 'no-integration': '']"
            v-model="company.username_talana"
            :placeholder="company.integrations_connections.talana_username ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent"
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.talana_username = !company.integrations_connections.talana_username"
          >
            <LockOpen v-if="!company.integrations_connections?.talana_username" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
        <h2
          class="block mt-2 text-xs font-bold tracking-wide text-gray-500 md:text-left dark:text-white"
        >
          Puede investigar en
          <a
            href="https://ayuda.talana.com/documentacion/pack-de-bienvenida/integraciones/"
            target="_blank"
            class="underline hover:underline"
          >
            API de Talana.
          </a>
        </h2>
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Contraseña Talana</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.talana_password"
            :class="[company.integrations_connections.talana_password ? 'no-integration': '']"
            v-model="company.password_talana"
            :placeholder="company.integrations_connections.talana_password ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent"
          />
          <div
            class="cursor-pointer"
            @click="company.integrations_connections.talana_password = !company.integrations_connections.talana_password"
          >
            <LockOpen v-if="!company.integrations_connections?.talana_password" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full my-1.5">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.40rem',
            'padding-right': '0.40rem',
            'padding-top': '0.40rem',
            'padding-bottom': '0.40rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.from_talana_date"
          class="border dark:bg-black-dark dark:border-background-dark"
          input-class="xmx-input"
          type="date"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>

    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Obtener PDF del Documento
    </h2>
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          RUT REPRESENTANTE LEGAL
        </label>
        <VueRutCntr
          class="input-text"
          :rutcntr="company.rutcntr_partner"
          @input_rut="company.rutcntr_partner = $event"
        />
      </div>

      <div class="flex flex-col">
        <label
          for="password"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Contraseña Representante legal</label
        >
        <div
          class="flex justify-between input-text bg-white"
        >
          <input
            type="text"
            :disabled="company.integrations_connections.document"
            :class="[company.integrations_connections.document ? 'no-integration': '']"
            v-model="company.password_partner"
            :placeholder="company.integrations_connections.document ? '*********': 'Ingresar Contraseña'"
            class="flex-1 bg-transparent "
          />
          <div
            class="cursor-pointer"
            @click="unlockDocument(company.integrations_connections.document, 'password_partner')"
          >
            <LockOpen v-if="!company.integrations_connections.document" class="w-5 h-5" />
            <LockClosed v-else class="w-5 h-5" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue';
import DatePicker from "vue2-datepicker";
import LockOpen from '@/components/Icons/LockOpen.vue';
import LockClosed from '@/components/Icons/LockClosed.vue';
import VueRutCntr from "@/components/etc/VueRutCntr.vue"

export default {
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  components: {
    Spinner,
    DatePicker,
    LockOpen,
    LockClosed,
    VueRutCntr
  },
  data() {
    return {
      showTalana: false,
      loading: false,
      showOpenfactura: false,
      showOpenVentipay: false,
      showMercadoPago: false,
      showPartner: false,
      showOpenBsale: false
    };
  },
  created() {
    this.$emit('updateTab', "Integrations")
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    unlockDocument(state, attr) {
      if (state) {
        this.company.password_partner = '';
        this.company.integrations_connections.document = false;
      } else {
        delete this.company[attr];
        this.company.integrations_connections.document = true;
      }
    },
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
};

</script>

<style scoped>
div :has(> .no-integration) {
  @apply bg-gray-200 dark:bg-background-dark-disabled;
}

</style>
