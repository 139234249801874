<template>
  <div class="py-2 mx-4 lg:mx-6">
    <div class="w-full flex gap-4">
      <div class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex gap-4">
        $ {{formatNumber(buy_total || 0 )}}
      </div>
    </div>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar movimientos"
          @input="reload"
          v-model="query"
        />
      </div>
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          v-model="dateRange"
          type="date"
          range
          format='DD-MM-YYYY'
          placeholder="Selecciona un rango de Fecha"
          v-on:input="reload"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem'
          }"
        ></date-picker>
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="movement_state"
          :options="status"
          :reduce="(state) => state.value"
          label="name"
          @input="reload"
          placeholder="Estado"
        ></v-select>
      </div>
    </div>

    <div class="">
      <newBuys
        v-if="showModal"
        @close="showModal = false"
        :buy_id="buy_id"
        :action="action"
        @getBuys="getBuys"
      ></newBuys>
      <newMovement
        v-if="showMovement"
        @getBuys="getBuys"
        @close="closeFunction"
        :buy_id="buy_id"
      ></newMovement>
    </div>

    <div class="flex flex-col lg:flex-row justify-start gap-4 lg:pr-2 space-y-2 lg:space-y-0 pt-6">
      <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="flex items-center h-full p-4 bg-cyma-red dark:bg-red-dark rounded-lg">
          <div v-if="loading" class="flex items-center justify-center w-full">
            <Spinner color="#fdaba8" />
          </div>
          <div v-else class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cyma-red-title">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <h5 class="text-xs font-bold uppercase text-cyma-red-title">Total por pagar</h5>
              <h3 class="font-bold xl:text-xl lg:text-base">
                $ {{ formatNumber(buy_total_not_paid || 0) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="flex items-center h-full p-4 bg-cyma-green dark:bg-green-dark rounded-lg">
          <div v-if="loading" class="flex items-center justify-center w-full">
            <Spinner color="#a9d2be" />
          </div>
          <div v-else class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cyma-green-title">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <h5 class="text-xs font-bold uppercase text-cyma-green-title">Total pagado</h5>
              <h3 class="font-bold xl:text-xl lg:text-base">
                $ {{ formatNumber(buy_total_paid || 0) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="bg-white border border-gray rounded p-4 dark:bg-black-dark dark:border-black-dark">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div></div>
            </div>
            <div class="flex-1">
              <h5 class="font-bold uppercase text-gray-500">
                Promedio de pago
              </h5>
              <h3 class="font-bold text-2xl">-</h3>
            </div>
          </div>
        </div>
      </div>-->


    </div>

    <div class="flex flex-col lg:flex-row justify-around gap-4">
      <div class="w-12/12 lg:w-6/12 pt-6">
        <div class="bg-white white border border-gray rounded-lg p-5 dark:bg-black-dark dark:border-black-dark">
          <h3 class="text-gray-400 font-bold text-lg dark:text-white mb-8">Facturas por cobrar por mes de emisión</h3>
          <div class="relative">
            <bar-chart :chart-data="dataCollection"> </bar-chart>
          </div>
        </div>
      </div>
       <div class="w-12/12 lg:w-6/12 pt-6">
        <div class="white bg-white border border-gray rounded-lg p-5 dark:bg-black-dark dark:border-black-dark">
          <h3 class="text-gray-400 font-bold text-lg dark:text-white mb-8">Pagos por mes de facturas</h3>
          <div class="relative">
            <stacked-chart :chart-data="dataCollectionStacked"> </stacked-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row justify-around">
      <div class="w-full w-12/12">
        <h3 class="uppercase text-gray-500 py-4">Facturacion Historica</h3>
        <BeeGridTable
          :serverSide="true"
          stripe
          border
          :showFilter="false"
          :showSummary="false"
          :filterTotal="filterTotal"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :fetchMethod="fetchMethod"
          :columns="columns"
          :data="movements"
          :loading="loading"
        >
          <BeeColumn field="folio" title="Folio" type="date" sortable="true" :width="120">
            <template slot-scope="{ row }">
              {{ row.folio }}
              <div class="text-sm tracking-normal text-gray-500">
                {{ formatDocType(row.doc_type) }}
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="period" title="Fecha" type="date" :width="180">
            <template slot-scope="{ row }">
              {{ formatSimpleDate(row.period) }}
              <div class="text-sm tracking-normal text-gray-500" v-if="row.movement_status === 'not_paid'">
                {{ differenceInDays(row.period) }} días vencidos
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="dateSII" title="Periodo SII" type="date" :width="120">
            <template slot-scope="{ row }">
              {{ formatDateSII(row.date_sii) }}
            </template>
          </BeeColumn>

          <BeeColumn field="id" title="Clasificación" type="text" :width="300">
            <template slot-scope="{ row }">
              {{row.account_neto_name || 'No clasificado'}}
            </template>
          </BeeColumn>

          <BeeColumn field="total" title="Total" type="number" sortable="true" >
            <template slot-scope="{ row }">
              $ {{formatNumber(row.total || 0)}}
              <div class="text-sm tracking-normal text-gray-500">
                $ {{ formatNumber(row.balance || 0) }}
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="pagado" title="Pagado" type="text" :width="80">
            <template slot-scope="{ row }">
              <div v-if="row.classified && !(row.credit_note_id)">
                <label class="switch label_switch">
                  <input
                    class="check"
                    type="checkbox"
                    :class="{ notPaid: row.movement_status === 'paid_subscriber' }"
                    :checked="checkedFunction(row.movement_status)"
                    @click.prevent="updatePay($event.target.checked, row.id)"
                  />
                  <span><slot></slot></span>
                </label>
              </div>
              <div v-else-if="!(row.credit_note_id)">S/C</div>
            </template>
          </BeeColumn>
          <BeeColumn field="eliminar" title="Opciones" type="text" class="text-center" :width="120">
            <template slot-scope="{ row }">
              <div class="flex gap-4">
                <a v-on:click="editFunction(row.id)" >
                  <Edit/>
                </a>

                <a v-on:click="deleteFunction(row.id)" >
                  <Times/>
                </a>
              </div>
            </template>
          </BeeColumn>
        </BeeGridTable>
      </div>
    </div>

    <Add
      v-if="showModalVendor"
      @close="showModalVendor = false"
      @getAllClients="reload"
      :id="id"
      :action="action"
    ></Add>
  </div>
</template>

<script>
import {
  formatDocType,
  formatNumber,
  formatPercentage,
  formatSimpleDate,
  formatDateSII,
  differenceInDays,
} from "@/utils/formatters.js";
import DatePicker from "vue2-datepicker";
import BarChart from "../BarChart";
import StackedChart from "../StackedChart";
// import Add from "./Add";
import Times from '@/components/Icons/Times.vue';
import Edit from '@/components/Icons/Edit.vue';
// import Check from '@/components/Icons/CheckBadge.vue';
import newBuys from "../buys/newBuys";
import newMovement from "../buys/newMovement";
import Spinner from '@/components/Spinner.vue';
import "@/assets/stylesheets/switch.scss";
export default {
  name: "RemunerationMovement",
  data() {
    return {
      movements: [],
      per_page: 10,
      without_account: 0,
      employee_id: parseInt(this.$route.params.employee_id),
      columns: [],
      sale_total: 0,
      vendor: [],
      showModal: false,
      showModalVendor: false,
      showMovement: false,
      dataCollection: null,
      dataCollectionStacked: null,
      data_graph_sales: [],
      data_graph_buys: [],
      data_graph_buy_total_paid: [],
      data_graph_buy_total_not_paid: [],
      buy_total: 0,
      buy_total_not_paid: 0,
      buy_total_paid: 0,
      movement_state: null,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      current_page: 1,
      nameSorting: null,
      sorting: null,
      query: null,
      status: [],
      category_movement: null,
      types: [],
      loading: true,
      settlement_type: null,
      settlement_types: [
        { value: 0, name: 'Anticipo' },
        { value: 1, name: 'Sueldo' },
        { value: 2, name: 'Sueldo Reprocesada' },
        { value: 3, name: 'Finiquito' },
        { value: 4, name: 'Histórico' }
      ]
    };
  },
  components: {
    DatePicker,
    BarChart,
    StackedChart,
    // Add,
    newBuys,
    newMovement,
    Times,
    Edit,
    Spinner
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.getMovements();
      this.getStas();
    },
  },
  created() {
    this.getMovements()
  },
  mounted() {
    this.getStas();
    this.status = [
      { value: null, name: 'Todos los estados' },
      { value: 1, name: 'No pagado' },
      { value: 0, name: 'Pagado' },
    ]
    this.types = [
      { value: null, name: 'Todos los tipo de egreso' },
      { value: 2, name: "Compras" },
      { value: 3, name: "Boleta de Honorarios" },
      { value: 4, name: "Gastos Manuales" },
      { value: 5, name: "Remuneraciones" },
      { value: 6, name: "Retiros" },
    ]
  },
  methods: {
    formatDocType,
    formatNumber,
    formatPercentage,
    formatSimpleDate,
    formatDateSII,
    differenceInDays,
    reload() {
      this.getMovements();
      this.getStas();
    },
    editFunctionVendor(id) {
      this.showModalVendor = true;
      this.id = id;
      this.buy_id = id;
      this.action = "update";
    },
    editFunction(id) {
      console.log("update", id);
      this.showModal = true;
      this.buy_id = id;
      this.action = "update";
    },
    checkedFunction(status) {
      if (status == "not_paid") return false;
      return status === "paid_subscriber" || status === "paid";
    },
    updatePay(event, id) {
      console.log(event);
      this.showMovement = true;
      this.buy_id = id;
    },
    closeFunction() {
      this.showMovement = false;
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getMovements();
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu egreso ha sido eliminado",
              "success"
            );
            this.axios.delete(`movements/${id}`).then(() => this.getBuys());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    fillData() {
      const app = this;
      this.dataCollection = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Ventas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            pointBorderColor: "#2554FF",
            data: app.data_graph_sales,
          },
          {
            label: "Compras",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBorderColor: "#2554FF",
            data: app.data_graph_buys,
          },
        ],
      };
    },
    fillDataStacked() {
      const app = this;
      this.dataCollectionStacked = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Pagado",
            borderWidth: 1,
            backgroundColor: "rgba(75, 192, 192, 0.2)",
            borderColor: "rgba(75, 192, 192, 1)",
            pointBorderColor: "#2554FF",
            data: app.data_graph_buy_total_paid,
          },
          {
            label: "No Pagado",
            borderWidth: 1,
            backgroundColor: "rgba(255, 99, 132, 0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBorderColor: "#2554FF",
            data: app.data_graph_buy_total_not_paid,
          },
        ],
      };
    },
    getMovements() {
      this.loading = true
      let app = this
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            category_movement: 'form_22',
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            pending_sii: app.pending_sii,
            sorting: app.sorting,
            query: app.query,
            from: app.dateRange[0],
            to: app.dateRange[1],
          },
        })
        .then((response) => {
          app.movements = response.data.data
          app.filterTotal = response.data.total_items //total filtered data count
          app.total = response.data.per_page // total data count
          app.page = response.data.current_page // current page offset from server
          app.current_page = response.data.current_page
          app.pageSize = response.data.per_page
          app.pageCount = response.data.total_pages
          app.without_account = response.data.without_account
          app.buy_total = response.data.buy_total;
          app.buy_total_not_paid = response.data.buy_total_not_paid;
          app.buy_total_paid = response.data.buy_total_paid;
          app.loading = false
        })
    },
    getStas() {
      let app = this;
      this.axios
        .get("/stats", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            per_page: 5,
            query: app.query,
            movement_status: app.movement_state,
            category_movement: 'form_22'
          },
        })
        .then((response) => {
          this.labels = response.data.labels;
          this.data_graph_sales = response.data.data_graph_sales;
          this.data_graph_buys = response.data.data_graph_buys;
          this.data_graph_buy_total_paid = response.data.data_graph_buy_total_paid;
          this.data_graph_buy_total_not_paid = response.data.data_graph_buy_total_not_paid;
          this.fillData();
          this.fillDataStacked();
        });
    },
  },
};
</script>
