<template>
  <!-- remember to add the class "group" to the parent element -->
  <span
    class="absolute mt-2 left-1/2 top-2/3 -translate-y-1/2 rounded bg-inherit px-2 py-1.5 text-xs font-medium text-white opacity-0 group-hover:opacity-100 transition-all ease-in duration-200 hidden group-hover:block"
  >
    {{ text }}
  </span>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.bg-inherit {
  background-color: inherit;
}
</style>
