<template>
  <div class="flex flex-col gap-4">
    <div class="w-full flex lg:gap-4 flex-wrap">
      <div
        class="items-center dark:text-white flex-auto flex lg:gap-4 gap-2"
      >
        <div class="flex flex-col">
          <h1 class="lg:text-4xl text-2xl font-semibold">{{ total_movements }} Movimientos</h1>
          <h2 class="flex-auto text-xl">
            {{ filterTotal }} movimientos con recomendación
          </h2>
        </div>
      </div>
      <div class="flex gap-2 items-center">
        <button @click.prevent="massClasificationRecommended()" class="button-cymasuite">Asignar Recomendados</button>
      </div>
    </div>
    <BeeGridTable
      :serverSide="true"
      border
      ref="selection"
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :row-class-name="rowClassName"
      :data="movements"
      :loading="loadingTable"
    >
      <BeeColumn
        field="business_name"
        title="Razón social"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="400"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow" v-if="row.ticket_honorary">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('serviceProviderShow', {
                  service_provider_id: row.service_provider_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else>
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('Vendors', {
                  vendor_id: row.vendor_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
        </template>
      </BeeColumn>
      <BeeColumn
        field="folio"
        title="Folio"
        type="date"
        :width="180"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ row.folio }}
          <div class="text-sm tracking-normal text-gray-500">
            {{ formatDocType(row.doc_type) }}
          </div>
          <div
            v-if="row.ticket_honorary"
            class="text-sm tracking-normal text-gray-500"
          >
            {{ formatDocType(row.ticket_honorary.type_ticket) }}
          </div>
        </template>
      </BeeColumn>
      <BeeColumn
        field="period"
        title="Fecha"
        type="date"
        :width="150"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.period) }}
          <div
            class="text-sm tracking-normal text-gray-500"
            v-if="row.movement_status === 'not_paid'"
          >
            {{ differenceInDays(row.period) }} días vencidos
          </div>
        </template>
      </BeeColumn>
      <BeeColumn
        field="date_sii"
        title="Periodo SII"
        type="date"
        :width="120"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          {{ formatDateSII(row.date_sii) }}
        </template>
      </BeeColumn>
      <BeeColumn
        field="recommended"
        title="Recomendado"
        type="text"
        :width="300"
        :resizable="true"
        :sortable="true"
      >
        <template slot-scope="{ row }">
          <p>{{row.recommended_name}}</p>
          <!-- <v-select
            class="style-chooser custom-border border-0 bg-red-500"
            append-to-body
            :options="accounts"
            :reduce="(account) => account.id"
            v-model="row.recommended"
            label="name"
            :clearable="false"
          ></v-select> -->
          <!-- v-on:input="onChangeRecommended($event, movement.id, trIndex)" -->
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatDocType, formatSimpleDate, formatDateSII, differenceInDays } from "@/utils/formatters.js";
export default {
  name: "recommended",
  props: {},
  data() {
    return {
      accounts: [],
      loading: false,
      loadingTable: false,
      checkedMovementsArr: [],
      movements: [],
      columns: [
        {
          type: "selection",
          key: "select",
          width: 60,
          align: "center",
        },
      ],
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      total_pages: 0,
      total_items: 0,
      total_movements: 0,
      without_account: 0,
      checkeds_id: [],
    };
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.reload();
    },
  },
  mounted() {
    this.user = this.$auth.user();
    this.getMovements();
    this.getAccounts();
  },
  components: {
  },
  methods: {
    formatDocType,
    formatSimpleDate,
    formatDateSII,
    differenceInDays,
    massClasificationRecommended() {
      this.axios.post("update_mass_recommended", {
        array_movements: this.checkeds_id,
      }).then( () => {
        this.loadingTable = true;
        this.getMovements();
      });
    },
    getAccounts() {
      this.axios.get("select_dashboard", {
        params: {
          user_id: this.user.id,
          company_id: this.company_id
        }})
      .then((response) => {
        console.log("response=>", response)
        this.accounts = response.data;
      });
    },
    rowClassName() {
      // update array with ids movements
      let selecteds_checks = this.$refs.selection.getSelection();
      this.checkeds_id = selecteds_checks.map((e) => {
        return e.id;
      });
    },
    reload() {
      this.getAccounts()
      this.getMovements();
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getMovements();
    },
    getMovements() {
      this.loadingTable = true;
      let app = this;
      console.log(app.dateRange[0]);
      this.axios
        .get("/movement-only-recommended", {
          params: {
            company_id: app.company_id,
            // type_movement: 'type_buy',
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            // recommended: true,
            from: app.dateRange[0],
            to: app.dateRange[1],
          },
        })
        .then((response) => {
          app.movements = response.data.data;
          app.current_page = response.data.current_page;
          app.page = response.data.current_page; // current page offset from server
          app.total = response.data.per_page;
          app.filterTotal = response.data.total_items;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.per_page = response.data.per_page;
          app.total_movements = response.data.total_movements;
          app.loadingTable = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
