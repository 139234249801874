<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div class="lg:w-full">
    <div
      class="dark:bg-background-dark bg-white w-screen h-screen overflow-auto md:w-full md:h-full"
    >
      <div
        v-if="!contact.recipient_accounts.length > 0"
        class="w-full flex flex-col gap-4"
      >
        <p class=" text-cyma-purple">No se encontro una cuenta registrada para <span class=" font-bold">{{movement_name}}</span> , por favor cree una</p>
        <div class="flex flex-col">
          <label
            class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
            >RUT</label
          >
          <input
            type="text"
            :class="{ error: $v.recipient_account.holder_id.$error }"
            class="appearance-none block dark:bg-black-dark dark:border-background-dark w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-2 px-4"
            :value="mov_rut"
            disabled
            placeholder="Introduce Rut del destinatario"
          />
          <div class="text_error" v-if="$v.recipient_account.holder_id.$error">
            Este campo es requerido
          </div>
        </div>
        <div class="flex flex-col">
          <label
            class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
            >NUMERO DE CUENTA</label
          >
          <input
            :class="{ error: $v.recipient_account.number.$error }"
            type="text"
            class="appearance-none block dark:bg-black-dark dark:border-background-dark w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-2 px-4"
            v-model="$v.recipient_account.number.$model"
            placeholder="Introduce Cuenta destinataria"
          />
          <div class="text_error" v-if="$v.recipient_account.number.$error">
            Este campo es requerido
          </div>
        </div>
        <div class="flex flex-col">
          <label
            class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
            >Tipo de Cuenta a Pagar</label
          >
          <v-select
            :class="{ error: $v.recipient_account.type_account.$error }"
            class="style-chooser custom-border"
            :options="types"
            :reduce="(type) => type.value"
            v-model="$v.recipient_account.type_account.$model"
            label="name"
          ></v-select>
          <div
            class="text_error"
            v-if="$v.recipient_account.type_account.$error"
          >
            Este campo es requerido
          </div>
        </div>
        <div class="flex flex-col">
          <label
            class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-1"
            >Banco</label
          >
          <v-select
            :class="{ error: $v.recipient_account.institution_id.$error }"
            class="style-chooser custom-border"
            :options="banks"
            :reduce="(bank) => bank.value"
            v-model="$v.recipient_account.institution_id.$model"
            append-to-body
            label="name"
          ></v-select>
          <div
            class="text_error"
            v-if="$v.recipient_account.institution_id.$error"
          >
            Este campo es requerido
          </div>
        </div>
        <button
          v-if="!loading"
          class="bg-black w-full border hover:border-black text-white font-bold p-2 dark:bg-cian-dark dark:border-cian-dark rounded-full hover:bg-white hover:text-black"
          @click.prevent="saveRecipientAccount()"
        >
          Guardar Cuenta
        </button>
        <div class="flex justify-center items-center mt-4 py-2 px-4" v-else>
          <Spinner
          />
        </div>
      </div>
      <div v-else>
        <label
          class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs my-2"
        >
          Cuenta Bancaria
        </label>
        <v-select
          class="style-chooser custom-border"
          :options="contact.recipient_accounts"
          v-model="select_recipient_account"
        >
          <template slot="selected-option" slot-scope="option">
            <div>
              <p v-if="option.id == '' || option.id == null">
                Numero de Cuenta
              </p>
              <p v-else class="">Numero de Cuenta: {{ option.number }}</p>
            </div>
          </template>
          <template v-slot:option="option">
            Numbero de cuenta: {{ option.number }}
          </template>
        </v-select>
        <div class="flex flex-col">
          <label
            class="dark:text-white block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs my-2"
            >Monto</label
          >
          <currency-input
            :class="{ error: $v.amount.$error }"
            currency="CLP"
            data-cy="amount"
            locale="es-CL"
            placeholder="Monto"
            class="appearance-none block dark:bg-black-dark dark:border-background-dark w-full bg-grey-lighter text-grey-darker border border-grey-lighter py-3 px-4"
            v-model="$v.amount.$model"
          />
        </div>
        <div class="text_error" v-if="$v.amount.$error">
          Este campo es requerido
        </div>
        <button
          class="bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white font-bold p-2 dark:bg-cian-dark dark:border-cian-dark rounded-full hover:bg-white hover:text-black mt-4"
          @click.prevent="paymentIntent()"
        >
          Transferir Monto
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/stylesheets/modal.css";
import { required } from "vuelidate/lib/validators";
import Spinner from '@/components/Spinner2.vue';

export default {
  name: "newPayment",
  props: ["mov_rut", "vendor", "service_provider", "client", 'movement_id', 'movement_name'],
  data() {
    return {
      loading: false,
      select_recipient_account: {},
      recipient_account: {
        holder_id: this.mov_rut, //rut
        number: null, //numero
        type_account: null,
        institution_id: null,
        vendor_id: this.vendor ? this.vendor.id : null,
        client_id: this.client ? this.client.id : null,
        service_provider_id: this.service_provider
          ? this.service_provider.id
          : null,
      },
      amount: 0,
      currency: "CLP",
      bank_bills: [],
      mount: 0,
      holder_id: null,
      number: null,
      widget: null,
      bankSelected: null,
      typeSelected: null,
      types: [
        { value: "checking_account", name: "Cuenta Corriente" },
        { value: "sight_account", name: "Cuenta a la Vista" },
      ],
      banks: [
        { name: "Banco Ripley", value: "cl_banco_ripley" },
        { name: "Banco Bci 360", value: "cl_banco_bci_360" },
        { name: "Banco Bice", value: "cl_banco_bice" },
        { name: "Banco Deutsche", value: "cl_banco_deutsche" },
        { name: "Banco Corpbanca", value: "cl_banco_corpbanca" },
        { name: "Banco Security", value: "cl_banco_security" },
        { name: "Banco Internacional", value: "cl_banco_internacional" },
        { name: "Banco Falabella", value: "cl_banco_falabella" },
        { name: "Banco Estado", value: "cl_banco_estado" },
        { name: "Banco BCI", value: "cl_banco_bci" },
        { name: "Banco Scotiabank", value: "cl_banco_scotiabank" },
        { name: "Banco Itau", value: "cl_banco_itau" },
        { name: "Banco BBVA", value: "cl_banco_bbva" },
        { name: "Banco Santander", value: "cl_banco_santander" },
        { name: "Banco de Chile", value: "cl_banco_de_chile" },
        { name: "Tenpo", value: "cl_tenpo" },
      ],
    };
  },
  components: {
    Spinner
  },
  validations: {
    amount: {
      required,
    },
    recipient_account: {
      holder_id: {
        required,
      },
      number: {
        required,
      },
      type_account: {
        required,
      },
      institution_id: {
        required,
      },
    },
  },
  computed: {
    contact() {
      if (this.vendor) {
        return this.vendor;
      } else if (this.client) {
        return this.client;
      } else if (this.service_provider) {
        return this.service_provider;
      } else {
        return {};
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {},
  created() {
  },
  methods: {
    closeFunction() {
      this.$emit("close");
    },
    saveRecipientAccount() {
      let cur = this;
      cur.loading = true;
      this.axios.post("recipient_accounts", cur.recipient_account).then(() => {
        cur.loading = false;
        this.$emit("getBuy");
      });
    },
    paymentIntent() {
      let cur = this;
      let user_id = this.$auth.user().id;
      let webhook =  `https://api.cymasuite.com/fintoc_payment?credentials=${cur.company_id}_${user_id}`
      // let webhook =   `http://localhost:3000/fintoc_payment?credentials=${cur.company_id}_${user_id}`
      this.axios
        .post("paymentFintoc", {
          data: {
            company_id: cur.company_id,
            movement_id: cur.movement_id, 
            amount: this.amount,
            currency: "CLP",
            recipient_account: {
              holder_id: this.select_recipient_account.holder_id, //rut
              number: this.select_recipient_account.number, //numero
              type: this.select_recipient_account.type_account,
              institution_id: this.select_recipient_account.institution_id,
            },
          },
        })
        .then((response) => {
          this.widget = Fintoc.create({
            holderType: "individual",
            widgetToken: response.data.widget_token,
            product: "payments",
            publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF", // PRODUCTION
            // publicKey: "pk_test_ymMNeuUD7dBDxKT5Ko_zww_isXsXaumn", // TEST - Development
            // webhookUrl: `https://api.cymasuite.com/fintoc_payment?credentials=${cur.company_id}_${user_id}`,
            webhookUrl: webhook,
            onSuccess: () => {
              this.axios.post("/payment_create_account_movement", response).then(() => {
                this.$emit("getBuy");
              });
            },
          });
          this.widget.open();
        });
    },
    getBankBills() {
      let app = this;
      this.axios
        .get("bank_bills/", {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          this.bank_bills = response.data.data;
        });
    },
    getUser(id) {
      this.axios
        .get("users/" + id)
        .then((response) => {
          this.user = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style lang="scss">
.slider {
  width: 50%;
  height: 50px;
}
.range-slider-fill {
  background-color: blue;
  vertical-align: center;
}
</style>
