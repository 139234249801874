<template>
  <div class="relative overflow-hidden flex-1 bg-white dark:bg-background-dark h-auto">
    <section class="relative mx-auto flex flex-col md:flex-row gap-5">
      <div
        class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-5/12 flex flex-col bg-cyma-purple-200 order-2 lg:order-none dark:bg-background-dark min-h-screen"
      >
        <div class="max-w-sm mx-auto">
          <header class="flex justify-center items-center mb-12">
            <button
              href="#"
              @click.prevent="$router.go()"
              class="block p-4 lg:p-6 outline-none border-transparent appearance-none focus:outline-none"
            >
              <img
                src="https://ptishwor.sirv.com/cyma-imgs/logo.png?format=webp"
                width="220"
                height="48"
                style="aspect-ratio: 151/29;"
                alt="cymasuite logo"
              />
            </button>
          </header>
          <div class="text-base text-center dark:text-gray-400">
            <p>
              Comienza tu viaje contable con nosotros. Te guiaremos en la integración para acceder al Servicio de impuestos internos.
            </p>
          </div>
          <img
            src="@/assets/images/onboarding-step1.png"
            alt=""
            class="w-1/2 mx-auto my-5 select-none pointer-events-none"
            style="aspect-ratio: 223/323;"
          >
          <!-- Apps móviles -->
          <div>
            <p class="text-base dark:text-gray-200 text-center mb-5">
              Puedes disfrutar aún más de nuestros servicios descargando nuestra aplicación móvil
            </p>
            <div class="flex justify-center mb-5">
              <Icon icon="heroicons:chevron-double-down" class="w-8 h-8 text-cyma-purple dark:text-white" />
            </div>
            <div class="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <a href="https://apps.apple.com/us/app/cymasuite/id1582243635" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:apple" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Download on the</div>
                  <div class="font-sans text-sm font-semibold">App Store</div>
                </div>
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.gonozer.cymasuitemobile&hl=es_CL&gl=US" class="w-full sm:w-auto bg-gray-800 hover:bg-gray-700 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 hover:text-white">
                <Icon icon="fa-brands:google-play" class="mr-3 w-7 h-7" />
                <div class="text-left">
                  <div class="text-xs">Get in on</div>
                  <div class="font-sans text-sm font-semibold">Google Play</div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 sm:py-8 lg:p-8 w-full md:w-6/12 lg:w-7/12 order-1 lg:order-none">
        <div class="max-w-xl mx-auto">
          <h2 class="pt-20 text-4xl text-cyma-purple dark:text-white font-yesevaOne mb-4">
            Bienvenido a Cymasuite
          </h2>
          <div class="text-base dark:text-gray-200">
            <p>
              Facilitamos la toma de decisiones proporcionándote datos empresariales en tiempo real. Para continuar, requerimos la siguiente información.
            </p>
          </div>

          <div v-if="siiLoading" class="grid place-items-center align-text-bottom h-96">
            <Spinner2 class="text-cyma-purple dark:text-cian-500" customClass="h-16 w-16" />
          </div>
          <template v-else>
            <div class="flex flex-col dark:text-white mt-14">
              <label class="block text-left uppercase text-grey-darker text-sm mb-2">
                RUT de Empresa
              </label>
              <VueRutInfo
              :rut="rutctnr"
              @updateRut="rutctnr= $event"
              @updateName="name = $event"
              @updateRutVerified="rut_verified = $event"
              />
            </div>
            <div v-if="rut_verified" class="fade-in flex flex-col justify-center w-full mt-6">
              <!-- Nombre de empresa -->
              <div class="w-full mb-4">
                <label class="block text-left uppercase text-grey-darker text-sm dark:text-white mb-2">
                Nombre Empresa
                </label>
                <input
                  v-model="name"
                  type="text"
                  disabled
                  class="select-none appearance-none block w-full bg-white border border-grey-lighter py-3 px-4 dark:bg-black-dark dark:border-black-dark focus:outline-none rounded"
                />
              </div>
              <!-- Contraseña -->
              <div class="flex items-center gap-2 mb-2">
                <label
                  for="password"
                  class="text-left uppercase text-grey-darker text-sm dark:text-white"
                >
                  Contraseña
                  <span class="hidden xl:inline-block">Servicio de impuesto interno</span>
                  <span class="xl:hidden">sii</span>
                </label>
                <ToolTip additionalClass="sm:w-64 w-52 bg-gray-muted">
                  <template #icon>
                    <Icon icon="heroicons:information-circle" class="w-5 h-5 text-cyma-purple-light dark:text-cian-300" />
                  </template>
                  Tu conexión está encriptada de extremo a extremo, Tus credenciales no serán compartidas con nadie.
                </ToolTip>
              </div>
              <div class="w-full relative">
                <input
                  v-model="$v.password.$model"
                  :type="show ? 'text' : 'password'"
                  class="appearance-none dark:text-white block w-full bg-grey-lighter text-grey-darker border border-red rounded py-3 px-4 mb-1 dark:bg-black-dark dark:border-background-dark"
                  :class="{ error: $v.password.$error }"
                  placeholder="Contraseña"
                />
                <button
                  type="button"
                  class="absolute top-0 right-0 mt-3 mr-4 focus:outline-none"
                  @click.prevent="show = !show"
                >
                  <Icon class="h-5 w-5 text-gray-300" :icon="show ? 'heroicons:eye-slash' : 'heroicons:eye'" />
                </button>
                <div class="text_error" v-if="$v.password.$error">
                  Este campo es requerido
                </div>
                <div
                  v-if="fail_connection_sii || connected_sii"
                  class="flex font-semibold"
                >
                  <span v-if="fail_connection_sii" class="text-red-500">La contraseña del SII es incorrecta</span>
                  <span v-else-if="connected_sii" class="text-green-500">Conectando con el SII</span>
                </div>
              </div>
              <!-- Fecha -->
              <div class="w-full mt-4">
                <div class="flex items-center gap-2 mb-2">
                  <label
                    for="date"
                    class="text-left uppercase text-grey-darker text-sm dark:text-white"
                  >
                    Fecha de Inicio
                  </label>
                  <ToolTip additionalClass="sm:w-64 w-52 bg-gray-muted">
                    <template #icon>
                      <Icon icon="heroicons:information-circle" class="w-5 h-5 text-cyma-purple-light dark:text-cian-300" />
                    </template>
                    Fecha de inicio de período y de asignación F29 (sugerimos poner fecha desde el 01
                    de enero del presente año)
                  </ToolTip>
                </div>
                <div class="">
                  <date-picker
                    input-class="xmx-input"
                    :style="{
                      width: '100%',
                      height: '47px',
                      'border-radius': '4px',
                      'padding-left': '0.55rem',
                      'padding-right': '0.55rem',
                      'padding-top': '0.50rem',
                      'padding-bottom': '0.50rem',
                    }"
                    :class="{ error: $v.from.$error }"
                    format='DD-MM-YYYY'
                    type="date"
                    v-model="$v.from.$model"
                    placeholder="Selecciona una Fecha"
                  ></date-picker>
                  <div class="text_error" v-if="$v.from.$error">
                    Este campo es requerido.
                  </div>
                </div>
              </div>
              <div class="flex justify-center my-8">
                <!-- <button
                  class="mr-4 focus:outline-none text-cyma-purple hover:text-cyma-purple/80 hover:underline dark:text-white dark:hover:text-gray-300"
                  @click.prevent="$emit('next-step', 'company_step')"
                >
                  Atrás
                </button> -->
                <div class="space-y-1">
                  <CymaButton
                    class="button-cymasuite"
                    @click="newSii"
                  >
                    Conectar empresa
                  </CymaButton>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import CymaButton from "@/components/CymaButton"
import VueRutInfo from "@/components/etc/VueRutInfo.vue"
import ToolTip from "@/components/ToolTip.vue";
import Spinner2 from "@/components/Spinner2.vue";
import DatePicker from "vue2-datepicker";
import { Icon } from "@iconify/vue2"

export default {
  components: {
    CymaButton,
    VueRutInfo,
    ToolTip,
    Spinner2,
    DatePicker,
    Icon
  },
  data() {
    return {
      rutctnr: "",
      name: "",
      rut_verified: false,
      // form password sii connection and date
      password: null,
      from: "",
      show: false,
      siiLoading: false,
      connected_sii: false,
      fail_connection_sii: false,

    }
  },
  computed: {
    dv() {
      return this.rutctnr.split("-")[1];
    },
    rut() {
      return this.rutctnr.split("-")[0].split('.').join('');
    },
  },
  watch: {
    rut_verified() {
      if (this.rut_verified) {
        this.$emit("fullrut", this.rut)
        this.$emit("updateName", this.name)
        this.$emit("rut", this.rut.split("-")[0].split('.').join(''))
        this.$emit("dv", this.rut.split("-")[1])
      }
    },
  },
  validations: {
    password: {
      required,
    },
    from: {
      required,
    },
  },
  methods: {
    updateSiiStep(id) {
      if (!id) {
        id = this.$cookie.get("sii_config_id");
      }
      this.axios.patch("/sii_configs/" + id, {
        step: 1,
      }).then((response) => {
        console.log(response.data)
      });
    },
    newSii() {
      //  VALIDATE INPUT
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.siiLoading = true;
      this.axios
        .post("sii_configs", {
          rutcntr: this.rutctnr,
          rut: this.rut,
          dv: this.dv,
          user_id: this.user_id,
          password: this.password,
          from: this.from,
        })
        .then((result) => {
          this.siiconfig = result.data;
          this.$cookie.delete("sii_config_id");
          this.$cookie.set("sii_config_id", result.data.id);
          this.SiiConfig(result.data.id);
        })
        .catch((e) => alert(e));
    },
    SiiConfig(id) {
      if (!id) {
        id = this.$cookie.get("sii_config_id");
      }
      setTimeout(() => {
        this.axios
          .get("sii-config-validate/" + id)
          .then((response) => {
            this.connected_sii =
              response.data[0].monitoring_progress.connected_sii;
            this.fail_connection_sii =
              response.data[0].monitoring_progress.fail_connection_sii;
            if (this.connected_sii) {
              this.siiLoading = false;
              this.$cookie.set("sii_config_id", id);
              this.updateSiiStep(id)
              this.$emit("next-step", "bank_step");
            } else if (this.fail_connection_sii) {
              this.$cookie.delete("sii_config_id");
              this.siiLoading = false;
            } else {
              this.SiiConfig();
            }
          })
          .catch((e) => {console.log(e.code)});
      }, 5000);
    },
  },
}
</script>

<style scoped>
p {
  text-wrap: pretty;
}

</style>
