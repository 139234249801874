<template>
  <div class="py-2 mx-4 lg:mx-6">
    <div class="w-full flex lg:gap-4 flex-wrap">
      <div class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex lg:gap-4 gap-2">
        $ {{ formatNumber(sale_total) }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="#a8d3bd">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
        </svg>
      </div>
      <div class="flex gap-2 items-center">
        <a
          @click.prevent="createButton"
          class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 lg:px-4"
        > <span class="hidden sm:block">
            Agregar ingreso
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </a>
      </div>
    </div>
    <div class="flex mb-2 lg:mb-6">
      <h2
        v-if="without_account === 0 && sales.length > 0"
        class="flex-auto lg:text-lg sm:text-sm text-gray-400 sm:text-left"
      >
        Todos los movimientos ({{ formatNumber(filterTotal) }} documentos) estan clasificados.
      </h2>
      <h2
        v-else
        class="flex-auto lg:text-lg sm:text-sm text-gray-500 sm:text-left"
      >
        {{ formatNumber(without_account || 0) }} ingresos sin categorizar.
      </h2>
    </div>

    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          data-cy="search"
          type="text"
          class="input-text"
          placeholder="Buscar movimientos"
          v-model="query"
        />
      </div>
      <div>
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            :style="{
              width: '100%',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.65rem',
              'padding-bottom': '0.65rem',
              'border-radius': '0.5rem',
            }"
            v-model="dateRange"
            type="date"
            range
            format='DD-MM-YYYY'
            placeholder="Fecha"
          ></date-picker>
        </div>
      </div>
      <div class="">
        <v-select
          class="style-chooser custom-border"
          v-model="movement_state"
          :options="status"
          :reduce="(state) => state.value"
          label="name"
          placeholder="Estado"
        ></v-select>
      </div>
      <div class="">
        <v-select
          class="style-chooser custom-border"
          v-model="category_movement"
          :options="types"
          :reduce="(type) => type.value"
          label="name"
          placeholder="Tipo de ingreso"
        ></v-select>
      </div>
      <editCreditNote
        v-if="showCreditModal"
        @close="showCreditModal = false"
        :id="sale_id"
        :action="action"
        @getSales="getSales"
      />
      <newSales
        v-if="showModal"
        @close="showModal = false"
        @getSales="getSales"
        :sale_id="sale_id"
        :action="action"
      ></newSales>
      <newMovement
        v-if="showMovement"
        @getSales="getSales"
        @close="closeFunction"
        :buy_id="buy_id"
      ></newMovement>
      <massClasification
        v-if="showModalCheked"
        @close="showModalCheked = false"
        :action="action"
        @getSales="getSales"
        :selected_checks="checkeds_id"
      >
      </massClasification>
      <massSale
        v-if="showmassSale"
        @getSales="getSales"
        @close="showmassSale = false"
        :sale_id="sale_id"
        :selected_checks="checkeds_id"
        :status="status_selecteds_boolean"
      >
      </massSale>
      <!-- <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="state_openfactura"
          :options="options_openfactura"
          :reduce="(type) => type.value"
          label="name"
          @input="getSales"
        ></v-select>
      </div> -->
      <div
        v-if="cost_centers.length > 0"
      >
        <v-select
          class="style-chooser custom-border"
          :options="cost_centers"
          v-model="cost_center"
          placeholder="Centro de Costo"
        ></v-select>
      </div>
      <div
        v-if="branch_offices.length > 0"
      >
        <v-select
          class="style-chooser custom-border"
          :options="branch_offices"
          v-model="branch_office"
          placeholder="Sucursal"
        ></v-select>
      </div>
      <div class="">
        <div class="text-xs font-light mb-1 dark:text-gray-400">Nota de crédito</div>
        <label class="switch label_switch">
          <input
            class="check"
            type="checkbox"
            v-model="onlynotecredit"
            :checked="onlynotecredit"
          />
          <span><slot></slot></span>
        </label>
      </div>
      <div v-if="category_movement != null || movement_state != null || query != null || dateRange[0] != null" class="filter fade-in flex items-center">
        <button @click="cleanFilters()" class="flex items-center focus:outline-none text-cian-400 hover:text-cian-500 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">
          Limpiar Filtros
        </button>
      </div>
    </div>
    <div class="flex gap-2 pb-4">
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="handleSelectAll()"
      >
        <div class="flex gap-1 items-center dark:text-white dark:hover:text-cian-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
          </svg> Todos
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="cleanSelectAll()"
        v-if="checkeds_id.length > 0"
      >
        <div class="flex gap-1 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
          </svg>
          Limpiar
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="selecteds_checks()"
        v-if="checkeds_id.length > 0"
      >
        <div class="flex gap-1 items-center">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
          </svg>
          Clasificar
        </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="massSaleCheked()"
        v-if="checkeds_id.length > 0"
        >
          <div class="flex gap-1 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
            </svg>
            Pagar
          </div>
      </button>
      <button
        class="text-gray-500 hover:text-light-blue pr-4 focus:outline-none dark:text-white dark:hover:text-cian-dark"
        @click="massSaleDeletedCheked()"
        v-if="checkeds_id.length > 0"
        >
          <div class="flex gap-1 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
            </svg>
            Eliminar
          </div>
      </button>
      <button class="flex items-center gap-2 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" v-on:click.prevent="exportInExcel()">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
      <!-- o
      <a class="dark:text-cian-dark" v-on:click.prevent="exportInPdf()">Pdf</a> -->
    </div>
    <BeeGridTable
      :serverSide="true"
      stripe
      border
      ref="selection"
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :row-class-name="rowClassName"
      :data="sales"
      :loading="loading"
    >
      <BeeColumnHeader field="select">
        <template>
          <input
            type="checkbox"
            :checked="principalCheckbox"
            v-model="principalCheckbox"
            @change="toggleSelectAll()"
            />
        </template>
      </BeeColumnHeader>
      <BeeColumn field="select" :width="120">
        <template slot-scope="{ row }">
          <input
            type="checkbox"
            :checked="checkeds_id.includes(row.id)"
            @change="handleSelect(row.id)"
          />
        </template>
      </BeeColumn>
      <BeeColumn
        field="business_name"
        title="Clientes"
        type="text"
        sortable='true'
        :width="480"
        :resizable='true'
      >
        <template slot-scope="{ row }">
          <div v-if="row.client_id" class="might-overflow uppercase">
            <a
              :title="row.business_name"
              class="text-cyma-purple hover:text-cian-dark dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('Clients', {
                  client_id: row.client_id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="might-overflow" v-else-if="row.category_movement == 'form_22'">
            <a
              :title="row.business_name"
              class="uppercase dark:text-cian-dark dark:hover:text-white"
              @click.prevent="
                goTo('f22ShowSales', {
                  movement_id: row.id,
                })
              "
            >
              {{ row.business_name }}
            </a>
          </div>
          <div class="flex gap-2">
            <div class="text-sm tracking-normal text-gray-400" v-if="row.rut">
              {{ formatRut(row.rut) }}
            </div>
            <i class="text-gray-400 block">{{ formatStateMovement(row.state_movement) }}</i>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="folio" title="Folio" type="date" :width="220" :resizable='true' :sortable='true'>
        <template slot-scope="{ row }">
          {{row.folio}}
          <div class="text-sm tracking-normal text-gray-400" v-if="row.doc_type">
            {{ formatDocType(row.doc_type) }}
          </div>
        </template>
      </BeeColumn>

      <!-- <BeeColumn field="state_openfactura" title="DTE" type="text" :width="120" :resizable='true' :sortable='true'>
        <template slot-scope="{ row }">
          {{ row.state_openfactura }}
          <div v-if="row.state_openfactura">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
          </div>
        </template>
      </BeeColumn> -->
      <BeeColumn field="period" title="Fecha" type="date" :width="160" :sortable='true' :resizable="true">
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.period) }}
        </template>
      </BeeColumn>

      <!-- Caducidad -->
      <BeeColumn field="estimated_payment" title="Fecha Caducidad" type="date" :width="200" :sortable='true' :resizable="true">
        <template slot-scope="{ row }">
          {{ formatSimpleDate(row.estimated_payment) || null }}
          <div class="text-sm tracking-normal text-gray-400" v-if="row.movement_status === 'not_bill'">
            {{ differenceInDays(row.estimated_payment) }} días vencidos
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="date_sii" title="Periodo SII" type="date" :width="120" :sortable='true' :resizable="true">
        <template slot-scope="{ row }">
          <div class="text-sm tracking-normal">
            {{ formatDateSII(row.date_sii) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="total" title="Monto" type="number" :width="180" :sortable='true' :resizable="true">
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.total) }}
          <div
            v-if="row.sum_bsale > 0"
            class="text-sm tracking-normal text-gray-400"
          >
            Bsale $ {{ formatNumber(row.sum_bsale) }}
          </div>
          <div v-else class="text-sm tracking-normal text-gray-400">
            $ {{ formatNumber(row.balance) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="account_neto_name" title="Cuenta" type="text" :width="120" :sortable='true' :resizable="true">
        <template slot-scope="{ row }">
          <div class="text-xs">
            {{ row.account_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="state_openfactura" title="Documento" type="text" :width="160" :resizable='true' :sortable='true'>
        <template slot-scope="{ row }">
          <div class="flex gap-5 items-center">
            <!-- {{ row.state_openfactura }} -->
            <!-- I Validation -->
            <div v-if="(row.checksii === 'reject_sii')">

              <ToolTip>
                <!-- Documento rechazado en el SII -->
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999" data-tooltip-target="tooltip-default">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg>
                </template>
                <!-- No encontrado en el RCV SII -->
                No encontrado en el SII
              </ToolTip>
            </div>
            <div v-if="(row.checksii === 'success_sii')">
              <ToolTip>
                <!-- Documento rechazado en el SII -->
                <template #icon>
                  <Icon icon="heroicons:document-arrow-down" class="h-5 w-5" color="#77dfaa" />
              </template>
              <!-- No encontrado en el RCV SII -->
              Sincronización exitosa con SII
            </ToolTip>
            </div>
            <div v-if="((row.state_openfactura == 'success_openfactura') && row.documents.length > 0) || row.documents.length > 0">
              <a v-on:click="openModalDte(row.documents[0])">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>
              </a>
            </div>
            <div v-else-if="row.f29_id">
              <a v-on:click="openModalF29(row.id)">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                </svg>
              </a>
            </div>
            <div v-else>
              <ToolTip v-if="row.file_movement_url || row.salary_id">
                <template #icon>
                  <a :href="row.file_movement_url" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                    </svg>
                  </a>
                </template>
                Documento guardado manualmente.
              </ToolTip>
              <ToolTip v-else-if="row.ticket_file">
                <template #icon>
                  <a :href="row.ticket_file" target="blank">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#77dfaa">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z" />
                    </svg>
                  </a>
                </template>
                Documento guardado.
              </ToolTip>
              <ToolTip v-else>
                <template #icon>
                  <Icon icon="heroicons:document-magnifying-glass" color="#fa9999" class="h-5 w-5" />
                </template>
                Documento no encontrado
              </ToolTip>
            </div>

            <div v-if="!row.doble_match && row.classified">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                  </svg>
                </template>
                No cumple partida doble.
              </ToolTip>
            </div>
            <div v-if="row.claim_date">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                  </svg>
                </template>
                <span class='tooltip p-1  bg-black text-white mt-1 rounded-lg px-2'>Documento pendiente.</span>
              </ToolTip>
            </div>
            <div v-else>
              <ToolTip>
                <template #icon>
                  <Icon icon="heroicons:document-check" color="#77dfaa" class="h-5 w-5" />
                </template>
                Documento aceptado.
              </ToolTip>
            </div>

            <div v-if="row.duplicate_paid">
              <ToolTip>
                <template #icon>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="#fa9999" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </template>
                Pago duplicado.
              </ToolTip>
            </div>

            <div v-if="row.count_version > 0">
              <div class="flex gap-2 text-gray-400">
                <ToolTip>
                  <template #icon>
                    <button @click.prevent="openModalVersions(row.id)">
                      <Icon icon="heroicons:finger-print" class="w-5 h-5" />
                    </button>
                  </template>
                  Versiones
                </ToolTip>
              </div>
            </div>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="cobrado" title="Cobrado" type="text" :width="95">
        <template slot-scope="{ row }">
          <div v-if="row.classified && row.credit_note_statement != 'voided'">
            <label class="switch label_switch">
              <input
                class="check"
                type="checkbox"
                :class="{ notBill: row.movement_status === 'bill_subscriber' }"
                :checked="checkedFunction(row.movement_status)"
                @click.prevent="updatePay($event.target.checked, row.id)"
              />
              <span><slot></slot></span>
            </label>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="factura"
        title="Anulación"
        type="text"
        :width="140"
        :sortable="false"
        key="factura"
      >
        <template slot-scope="{ row }">
          <div>
            <!-- :class="row.credit_note_id ? 'text-xs dark:text-cian-dark dark:hover:text-white text-blue-400': 'text-xs dark:hover:text-white'" -->
            <div v-if="row.credit_note_statement == 'balance' && !row.is_credit_note" class="flex gap-1" >
              Saldo
            </div>
            <a
              v-else
              @click="creditNotesModal(row.id)"
              >
              <div v-if="row.credit_note_statement == 'voided'" class="flex items-center gap-2">
                Anulada
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                </svg>
              </div>
              <div v-else-if="onlynotecredit" class="flex gap-2 text-xs items-center dark:text-white dark:hover:text-cian-dark" >
                Vincular
                <Icon icon="heroicons:document-text" class="text-current h-5 w-5" />
              </div>
            </a>
          </div>
        </template>
      </BeeColumn>
      <BeeColumn field="editar" title="Opciones" type="text" :width="120">
        <template slot-scope="{ row, index }">
          <div class="flex gap-4">
            <a v-if="row.has_credit_notes" @click.prevent="updatePay($event.target.checked, row.id)" class="dark:text-cian-dark dark:hover:text-white">
              <Eye/>
            </a>
            <a v-if="row.category_movement != 'form_22'" @click.prevent="editFunction(row.id)" >
              <Edit />
            </a>
            <a :data-cy="'delete' + index" @click.prevent="deleteFunction(row.id)" >
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
    <modalDte
      v-if="showModalDte"
      :documents="documents"
      @close="showModalDte = false"
    ></modalDte>

    <modalVersions
      v-if="showModalVersions"
      @close="showModalVersions = false"
      :buy_id="sale_id"
      :action="action"
    ></modalVersions>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {
  formatDocType,
  formatRut,
  formatNumber,
  formatStateMovement,
  formatSimpleDate,
  formatDateSII,
  differenceInDays
} from "@/utils/formatters.js";
import routerMixin from "@/mixins/routerMixin";
import newSales from "./newSales";
import newMovement from "./newMovement"
import massClasification from "./massClasification.vue";

import "@/assets/stylesheets/switch.scss";
import massSale from "./massSale.vue";
import editCreditNote from "./editCreditNote.vue"
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';
import Eye from '@/components/Icons/Eye.vue';
import ToolTip from '@/components/ToolTip.vue';
import modalDte from "@/components/documents/modalDte.vue";
import modalVersions from "@/views/private/components/buys/modalVersions.vue"
import { Icon } from '@iconify/vue2';

export default {
  name: "sales",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      principalCheckbox: false,
      branch_offices: [],
      cost_centers: [],
      documents: null,
      state_openfactura: null,
      sale_id: "",
      checkeds_id: [],
      showmassSale: false,
      showModalCheked: false,
      select: this.$refs.selection,
      status_selecteds_boolean: true,
      nameAccount: {},
      action: "create",
      showModal: false,
      showModalDte: false,
      showMovement: false,
      showModalVersions: false,
      sales: [],
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      nameSorting: null,
      sorting: null,
      current_page: 1,
      per_page: 9,
      without_account: 0,
      showCreditModal: false,
      options_openfactura: [
        { value: null, name: 'Seleccionar por Documento' },
        { value: 0, name: 'Documento encontrado' },
        { value: 1, name: 'Documento no encontrado' }
      ],
      status: [],
      types: [],
      columns: [
        {
          type: "selection",
          key: "select",
          width: 60,
          align: "center",
        },
      ],
      sale_total: 0,
      loading: true,
      debounceTimer: null,
      status_selecteds: [],
      classified_selecteds: [],
    };
  },
  components: {
    editCreditNote,
    DatePicker,
    newSales,
    newMovement,
    massClasification,
    massSale,
    Edit,
    Times,
    Eye,
    ToolTip,
    modalDte,
    modalVersions,
    Icon,
},
  computed: {
    query: {
      get(){
        return this.$store.state.sale_query;
      },
      set(newValue){
        this.$store.commit('saleQuerySate', newValue);
      }
    },
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    onlynotecredit: {
      get(){
        return this.$store.state.sale_onlynotecredit;
      },
      set(newValue){
        this.$store.commit('saleOnlyNoteCreditState', newValue);
      }
    },
    movement_state: {
      get(){
        return this.$store.state.sale_movement_state;
      },
      set(newValue){
        this.$store.commit('saleMovementState', newValue);
      }
    },
    category_movement: {
      get(){
        return this.$store.state.sale_category_movement;
      },
      set(newValue){
        this.$store.commit('saleCategoryMovementState', newValue);
      }
    },
    cost_center: {
      get(){
        return this.$store.state.sale_cost_center;
      },
      set(newValue){
        this.$store.commit('saleCostCenterState', newValue);
      }
    },
    branch_office: {
      get(){
        return this.$store.state.sale_branch_office;
      },
      set(newValue){
        this.$store.commit('saleBranchOfficeState', newValue);
      }
    },
    date_type: {
      get(){
        return this.$store.state.buy_date_type;
      },
      set(newValue){
        this.$store.commit('buyDateType', newValue);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
    refresh() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    query(){
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        this.getSales();
      }, 500);
    },
    dateRange(){
      this.dateChange();
    },
    cost_center(){
      this.getSales();
    },
    branch_office(){
      this.getSales();
    },
    refresh(){
      this.getSales();
    },
    date_type() {
      this.getSales();
    },
    category_movement(value) {
      if(value !== null) {
        this.onlynotecredit = false;
      }
      this.getSales();
    },
    movement_state() {
      this.getSales();
    },
    onlynotecredit(value) {
      if(value) {
        this.category_movement = null;
      }
      this.getSales();
    },
    company_id(){
      this.getSales();
      this.getCostCentersOnUse();
      this.getBranchOfficesOnUse();
    },
  },
  created() {
    this.action = "create";
    this.getSales();
    this.getCostCentersOnUse();
    this.getBranchOfficesOnUse();
  },
  mounted() {
    this.status = [
      { value: 3, name: 'No cobrado' },
      { value: 2, name: 'Cobrado' },
      ]
    this.types = [
      { value: '0', name: "Ventas con facturas" },
      { value: 1, name: "Otros ingresos" },
      { value: 7, name: "Ventas con boletas" },
      { value: 17, name: "Ventas Bsale" },
      { value: 15, name: "Formulario 22"}]
  },
  methods: {
    formatDocType,
    formatRut,
    formatNumber,
    formatStateMovement,
    formatSimpleDate,
    formatDateSII,
    differenceInDays,
    handleSelect(data){
      const index = this.checkeds_id.indexOf(data);
      if (index !== -1) {
          this.checkeds_id.splice(index, 1);
      } else {
          this.checkeds_id.push(data);
      }
    },
    toggleSelectAll(){
      if(!this.principalCheckbox){
        this.cleanSelectAll();
      } else {
        this.handleSelectAll();
      }
    },
    dateChange(){
      this.getSales();
      // this.getAccountsOnUse();
      this.getCostCentersOnUse();
      this.getBranchOfficesOnUse();
    },
    getCostCentersOnUse() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/cost-centers-on-use", {
          params: {
            type_movement: "type_sale",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [0, 1, 7, 8, 15, 17],
            sorting: app.sorting,
            order_date: 'desc',
            // cost_center: app.cost_center
          },
        })
        .then((response) => {
          if(response.data.length > 0){
            let array = [ 'SIN CENTRO DE COSTO' ]
            let data_array = response.data
            app.cost_centers = array.concat(data_array)
          } else {
            app.cost_centers = response.data
          }
          app.loading = false;
        });
    },
    getBranchOfficesOnUse() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/branch-offices-on-use", {
          params: {
            type_movement: "type_sale",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: [0, 1, 7, 8, 15, 17],
            sorting: app.sorting,
            order_date: 'desc',
            // branch_office: app.branch_office
          },
        })
        .then((response) => {
          if(response.data.length > 0){
            let array = [ 'SIN SUCURSAL' ]
            let data_array = response.data
            app.branch_offices = array.concat(data_array)
          } else {
            app.branch_offices = response.data
          }
          app.loading = false;
        });
    },
    openModalVersions(id){
      this.showModalVersions = true,
      this.sale_id = id;
    },
    cleanFilters(){
      this.movement_state = null
      this.category_movement = null
      this.query = null
      this.dateRange = [null, null]
    },
    openModalDte(documents) {
      this.documents = documents;
      this.showModalDte = true;
    },
    creditNotesModal(id) {
      this.showCreditModal = true;
      this.sale_id = id;
      this.action = "update";
    },
    checkedDocument(status) {
      console.log(status)
      if (!status) return false;
      return status
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if(sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function(value) {
          return value.field;
        });

        _sorting = sortInfos.map(function(value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getSales();
    },
    selecteds_checks() {
      this.showModalCheked = true;
      this.action = "massClassification";
    },
    rowClassName() {
      // update array with ids movements
      // let selecteds_checks = this.$refs.selection.getSelection();
      // this.checkeds_id = selecteds_checks.map((e) => {
      //   return e.id;
      // });
    },
    massSaleDeletedCheked() {
      let cur = this;
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás esta lista de Ingresos",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalos",
          cancelButtonText: "No, conservalos",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminados",
              "Tus ingresos han sido eliminados",
              "success"
            );
            this.axios.delete('delete-mass', {
              data: {
                company_id: cur.company_id,
                movements: cur.checkeds_id
                }
              }).then(() => cur.getSales());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tus ingresos no han sido eliminados",
              "error"
            );
          }
        });
    },
    massSaleCheked(){
      this.showmassSale = true;
      this.action = "massSales";
      this.status_selecteds_boolean = this.status_selecteds.includes("bill") || this.classified_selecteds.includes(false)
    },
    handleSelectAll() {
      this.sales.forEach((sale) => {
        this.checkeds_id.push(sale.id);
        this.status_selecteds.push(sale.movement_status);
        this.classified_selecteds.push(sale.classified);
      });
    },
    cleanSelectAll(){
      this.checkeds_id = [];
      this.status_selecteds = [];
      this.classified_selecteds = [];
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este ingreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu ingreso ha sido eliminado",
              "success"
            );
            this.axios.delete(`movements/${id}`).then(() => this.getSales());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu ingreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    updatePay(event,id) {
      console.log(event)
      this.showMovement = true
      this.buy_id = id
    },
    closeFunction(){
      this.showMovement = false
    },
    checkedFunction(status){
      if(status == "not_paid") return false
      return status === "bill_subscriber" || status === "bill"
    },
    createButton() {
      this.showModal = true;
      this.sale_id = null;
      this.action = "create";
    },
    editFunction(id) {
      console.log("update",id)
      this.showModal = true;
      this.sale_id = id;
      this.action = "update";
    },
    exportInExcel() {
      this.loadingFile = true
      this.axios.post('/movements-excel', {
        type_movement: "type_sale",
        category_movement: this.category_movement || [0, 1, 7, 8, 15, 17],
        company_id: this.company_id,
        from: this.dateRange[0],
        to: this.dateRange[1],
        movement_status: this.movement_state,
        state_openfactura: this.state_openfactura,
        query: this.query,
        onlynotecredit: this.onlynotecredit,
        not_classified: this.not_classified,
        attribute_sorting: this.nameSorting,
        sorting: this.sorting,
        branch_office: this.branch_office,
        cost_center: this.cost_center,
        date_type: this.date_type,
      }, {
          responseType: 'blob'
      }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', ('Ingresos-Excel-' + this.formatSimpleDate(this.dateRange[0]) + 'al' + this.formatSimpleDate(this.dateRange[1])))
          document.body.appendChild(link)
          link.click()
          this.loadingFile = false
      });
    },
    getSales() {
      this.loading = true;
      let app = this;

      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_sale",
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            category_movement: app.category_movement || [0, 1, 7, 8, 15, 17],
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query,
            state_openfactura: app.state_openfactura,
            onlynotecredit: app.onlynotecredit,
            order_date: 'desc',
            branch_office: app.branch_office,
            cost_center: app.cost_center,
            date_type: app.date_type,
          },
        })
        .then((response) => {
          app.sales           = response.data.data;
          app.filterTotal     = response.data.total_items; //total filtered data count
          app.total           = response.data.per_page; // total data count
          app.page            = response.data.current_page; // current page offset from server
          app.current_page    = response.data.current_page;
          app.pageSize        = response.data.per_page;
          app.pageCount       = response.data.total_pages;
          app.without_account = response.data.without_account;
          app.sale_total      = response.data.sale_total || 0;
          app.loading         = false;
        });
    },
  },
};
</script>
<style scoped lang='scss'>
  .notBill {
    &:checked {
      + span {
        &:before {
          background-color: rgba(#f9fd00, 0.5);
        }
        &:after {
          background-color: #f9fd00;
          transform: translate(80%, -50%);
        }
      }
    }
  }
  .tooltip{
    visibility: hidden;
    position: absolute;
  }
  .has-tooltip:hover .tooltip {
    visibility: visible;
    z-index: 100;
  }
</style>
