<template>
  <div v-if="!loading" class="mx-4 lg:flex gap-4">
    <div
      id="profile"
      class="rounded-lg shadow-xl bg-white dark:bg-black-dark lg:mx-0"
    >
      <div class="p-4 md:p-12">
        <div
          class="block  shadow-xl h-48 w-48 m-auto bg-cover bg-center rounded-full" :style="{ 'background-image': `url('${this.user.avatar_url || this.defaultImage}')`  }">
        </div>
        <h1 class="mt-6 text-2xl font-bold pt-8 lg:pt-0 text-center lg:text-center dark:text-white">
          {{user.name}} {{user.lastname}}
        </h1>
        <div class="flex justify-center pb-3 text-grey-dark pt-3">
          <!-- <div class="text-center mr-3 pr-3">
            <h2 class="text-base font-semibold dark:text-white">{{user.companies.length}}</h2>
            <span class="text-gray-500 text-xs">Empresas</span>
          </div> -->
          <div class="text-center mr-3 pr-3">
            <h2 class="text-base font-semibold dark:text-white">{{formatNumber(user.movements_count || '')}}</h2>
            <span class="text-gray-500 text-xs">Movimientos</span>
          </div>
          <div class="text-center mr-3 pr-3">
            <h2 class="text-base font-semibold dark:text-white">{{formatNumber(user.movements_classified_count || '')}}</h2>
            <span class="text-gray-500 text-xs">Clasificados</span>
          </div>
          <div class="text-center">
            <h2 class="text-base font-semibold dark:text-white">{{user.sign_in_count}}</h2>
            <span class="text-gray-500 text-xs">Inicios</span>
          </div>
        </div>
        <table class="text-xs my-3">
          <tbody>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">RUT</td>
              <td class="px-2 py-2 dark:text-white" v-if="user.rut">{{formatRut(user.rut)}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Cargo</td>
              <td class="px-2 py-2 dark:text-white">{{user.position || 'Contador'}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Dirección</td>
              <td class="px-2 py-2 dark:text-white">{{user.address}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Teléfono</td>
              <td class="px-2 py-2 dark:text-white">{{user.phone}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Correo Electrónico</td>
              <td class="px-2 py-2 dark:text-white">{{user.email}}</td>
            </tr>
            <tr>
              <td class="px-2 py-2 text-gray-500 font-semibold">Fecha de nacimiento</td>
              <td class="px-2 py-2 dark:text-white">{{formatSimpleDate(user.birth_date)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="rounded-lg bg-white dark:bg-black-dark lg:mx-0 p-12 flex-grow">
      <div class="flex gap-3 mb-4">
        <div
          class="cursor-pointer px-2 text-2xl font-bold text-gray-500 dark:text-white"
        >
          Mis Movimientos
        </div>
      </div>
      <transition name="component-fade" mode="out-in">
        <div
          v-if="profileMenu === 'company'"
          class="grid lg:grid-cols-2 gap-x-3 gap-y-4"
        >
          <!-- <div v-for="(company, k) in user.companies.slice(0, 8)" :key="k" class="border p-3 rounded-lg dark:border-background-dark dark:bg-background-dark">
            <a class="dark:text-white font-semibold" @click.prevent="goTo('CompanyIndex', { props_company_id: company.id })" >
              {{company.business_name}}
            </a>
            <p class="text-gray-500" v-if="company.rut">{{formatRut(company.rut + company.dv)}}</p>
          </div> -->
        </div>
        <div v-if="profileMenu === 'transaction'">
        </div>
      </transition>
      <div class="pt-8 w-full">
        <div v-if="showFrappe">
          <VueFrappe
              id="test"
              title="Movimientos en último el año"
              type="heatmap"
              :colors="['#ebedf0', '#c0ddf9', '#73b3f3', '#3886e1', '#17459e']"
              :height="200"
              :dataPoints="data"
              :startDate="start"
              :endDate="end"
            >
          </VueFrappe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatRut, formatNumber, formatSimpleDate } from '@/utils/formatters'
import { VueFrappe } from 'vue2-frappe'
export default {
  name: 'profile',
  data(){
    return{
      user: {companies: []},
      profileMenu: "company",
      defaultImage: "https://source.unsplash.com/MP0IUfwrn0A",
      data: [],
      showFrappe: false,
      accountant_id: null,
      loading: true,
    }
  },
  components: {
    VueFrappe,
  },
  created(){
    this.accountant_id = this.$route.params.accountant
    this.getAccountant(this.$route.params.accountant)
  },
  mounted(){
    this.graphHeatmap();
    // this.user = this.$auth.user();
  },
  watch: {
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    start() {
      let date = new Date();
      // date.setMonth(1);
      var myFutureDate = new Date(date);
      myFutureDate.setDate(myFutureDate.getDate()- 300);
      return myFutureDate;
    },
    end() {
      let date = new Date();
      var myFutureDate = new Date(date);
      myFutureDate.setDate(myFutureDate.getDate()+ 30);
      return myFutureDate;
    },
  },
  methods:{
    formatRut,
    formatNumber,
    formatSimpleDate,
    getAccountant(id){
      this.loading = true
      this.axios.get('/users/' + id).then( (response) => {
        this.loading = false,
        this.user = response.data
      })
    },
    graphHeatmap(){
      let app = this;
      this.axios
        .get("/graph-heatmap", {
          params: {
          },
        })
        .then((response) => {
          app.data = response.data;
          app.showFrappe = true
        });
    },
    goTo(url, data = null) {
      this.$router.push({ name: url, params: data }).catch(() => {});
    },
    goToProfile(url, data = null) {
      this.$store.commit("addCompanyId", data.company_id);
      this.$router.push({ name: url, params: data }).catch(() => {});
    },
  }
}
</script>
<style scoped>
.not_image{
  background-image: url('https://source.unsplash.com/MP0IUfwrn0A')
}
</style>
