<template class="h-full">
  <div>
    <form class="flex flex-col" id="form-bigger">
      <div class="mx-2">
        <h2 class="block font-bold md:text-left uppercase tracking-wide text-grey-darker text-md dark:text-white">
          Modificar asiento Contable
        </h2>
      </div>
      <div class="grid grid-cols-4 mt-4">
        <div class="mx-2">
          <h3
            class="
              block
              font-bold
              md:text-left
              uppercase
              tracking-wide
              text-grey-darker text-xs
              dark:text-white
            "
          >
            Clasificación
          </h3>
        </div>

        <div class="mx-2">
          <h3
            class="
              block
              font-bold
              md:text-left
              uppercase
              tracking-wide
              text-grey-darker text-xs
              dark:text-white
            "
          >
            Debe
          </h3>
        </div>

        <div class="mx-2">
          <h3
            class="
              block
              font-bold
              md:text-left
              uppercase
              tracking-wide
              text-grey-darker text-xs
              dark:text-white
            "
          >
            Haber
          </h3>
        </div>
      </div>
      <div
        class="form-group"
        v-for="(input, k) in this.$v.dataAcc.$each.$iter"
        :key="k"
      >
        <div v-if="!input.destroy_manual.$model">
          <div class="grid grid-cols-4 my-2" :id="'form' + k">
            <div class="mx-2">
              <v-select
                :id="'formSelect' + k"
                class="style-chooser custom-border"
                :options="accounts"
                :reduce="(account) => account.id"
                label="fantasy_name"
                v-model="input.account_id.$model"
              ></v-select>
              <div class="text_error" v-if="!input.account_id.required">
                Este campo es requerido.
              </div>
            </div>
            <div class="mx-2">
              <currency-input
                currency="CLP"
                locale="es-CL"
                class="input-text"
                @change="calculatePorcentaje(k)"
                v-model="input.debit.$model"
                :disabled="input.credit.$model > 0"
              />
            </div>
            <div class="mx-2">
              <currency-input
                currency="CLP"
                locale="es-CL"
                class="input-text"
                @change="calculatePorcentaje(k)"
                v-model="input.credit.$model"
                :disabled="input.debit.$model > 0"
              />
            </div>
            <div class="mx-2">
              <a
                v-if="k != 0 && input.new.$model"
                class="mx-2 dark:text-white dark:hover:text-cian-dark"
                @click.prevent="
                  input.destroy_manual.$model = true;
                  remove(k);
                "
              >
                Eliminar
              </a>
            </div>
            <!-- <div v-if="k == dataAcc.length - 1 && k > 0">
            <a class="mx-2" @click.prevent="remove(k)"> Eliminar </a>
          </div> -->
          </div>
        </div>
      </div>
    </form>

    <div v-if="!double_match" class="p-2">
      <button
        @click.prevent="add()"
        class="button-cymasuite"
      >
        Dividir en más
      </button>

      <span v-if="double_match">Saldo 0</span>
    </div>
  </div>
</template>
<script>
import { formatNumber } from '@/utils/formatters'
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from "vuelidate/lib/validators";

export default {
  name: "editAccountMovement",
  props: {
    dataAcc: Array,
    action: String,
    typeMovement: String,
    ids: Array,
    name_accounting: String,
  },
  data() {
    return {
      balance: 0,
      accounts: [],
      showAcc: false,
      double_match: false,
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
    dataAcc: {
      required,
      $each: {
        min: {},
        max: {},
        account_id: {
          required,
        },
        debit: {},
        credit: {},
        total_amount: {},
        percentage: {},
        _destroy: {},
        destroy_manual: {},
        type_account_movement: {},
        new: {}
      },
    },
  },
  components: {},
  mounted() {
    this.getAccounts();
  },
  watch: {
  },
  methods: {
    formatNumber,
    // create object null ready to add
    add() {
      let cur = this;
      cur.dataAcc.push({
        min: 0,
        max: cur.balance,
        divide: null,
        percentage: 0,
        debit: 0,
        credit: 0,
        date_sii: null,
        total_amount: null,
        state: 4,
        company_id: this.company_id,
        type_movement: this.typeMovement,
        type_account_movement: "record",
        _destroy: null,
        destroy_manual: false,
        new: true
      });
      this.$emit("addAttribute", cur.dataAcc);
    },
    remove(index) {
      let account = this.dataAcc[index];
      if (account.id == null) {
        this.dataAcc.splice(index, 1);
      } else {
        account._destroy = "1";
      }
    },
    calculatePorcentaje(id) {
      let cur = this;
      this.dataAcc[id].period = cur.$parent.buyObj.period;
      this.dataAcc[id].date_sii = cur.$parent.buyObj.period;
      this.calculateBalance();
      this.$emit("permissionSave", this.double_match);
      this.$emit("addAttribute", this.dataAcc);
    },
    calculateBalance() {
      let cur = this;
      // let lastAccountMovements = cur.dataAcc.slice(0, cur.dataAcc.length);
      let newLastAccountMovements = cur.dataAcc.filter((e) => {
        return e.destroy_manual == false;
      });
      let sumCredit = newLastAccountMovements.reduce(
        (acc, e) => acc + e.credit,
        0
      );
      let sumDebit = newLastAccountMovements.reduce(
        (acc, e) => acc + e.debit,
        0
      );
      if (sumDebit === sumCredit) {
        cur.double_match = true;
      } else {
        cur.double_match = false;
      }
    },
    getAccounts() {
      this.axios
        .get("all_parents", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.accounts = response.data;
        });
    },
  },
};
</script>
