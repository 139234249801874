<template class="h-full">
  <div>
    <form class="flex flex-col" id="form-bigger">
      <div class="mx-2">
        <h2
          class="label-cymasuite"
        >
          Clasificación
        </h2>
      </div>
      <div class="hidden xl:grid grid-cols-5 mt-6">
        <div class="mx-2">
          <h3
            class="label-cymasuite"
          >
            Sucursal
          </h3>
        </div>
        <div class="mx-2">
          <h3
            class="label-cymasuite"
          >
            Centro de costo
          </h3>
        </div>
        <div class="mx-2">
          <h3
            class="label-cymasuite"
          >
            Clasificación
          </h3>
        </div>

        <div class="mx-2">
          <h3
            class="label-cymasuite"
          >
            Debe
          </h3>
        </div>

        <div class="mx-2">
          <h3
            class="label-cymasuite"
          >
            Haber
          </h3>
        </div>
      </div>
      <div
        class="form-group"
        v-for="(input, k) in this.$v.dataAcc.$each.$iter"
        :key="k + 'addVoucher'"
      >
        <div v-if="!input.destroy_manual.$model" :id="k">
          <div class="grid grid-cols-1 gap-y-2 lg:grid-cols-3 xl:grid-cols-5 my-2" id="form">
            <div class="mx-2">
              <v-select
                class="style-chooser custom-border"
                :options="branchOffices"
                :reduce="(option) => option.id"
                v-model="input.branch_office_id.$model"
                label="name"
                placeholder="Sucursal"
              ></v-select>
            </div>
            <div class="mx-2">
              <v-select
                class="style-chooser custom-border"
                :options="costCenter"
                :reduce="(option) => option.id"
                v-model="input.cost_center_id.$model"
                label="name"
                placeholder="Centro de Costos"
              ></v-select>
            </div>
            <div class="mx-2">
              <v-select
                :data-cy="'account-select' + k"
                class="style-chooser custom-border"
                :options="accounts"
                :reduce="(account) => account.id"
                @input="validateAccounts()"
                label="fantasy_name"
                v-model="input.account_id.$model"
                placeholder="Plan de Cuentas"
              ></v-select>
              <div class="text_error" v-if="!input.account_id.required">
                Este campo es requerido.
              </div>
            </div>

            <div class="mx-2">
              <currency-input
                :data-cy="'debit' + k"
                currency="CLP"
                locale="es-CL"
                class="input-text"
                @change="calculatePorcentaje(k)"
                v-model="input.debit.$model"
                :disabled="input.credit.$model > 0 || ((accounting_type_buy === 1) && (k == 0))"
              />
            </div>

            <div class="mx-2">
              <currency-input
                :data-cy="'credit' + k"
                currency="CLP"
                locale="es-CL"
                class="input-text"
                @change="calculatePorcentaje(k)"
                v-model="input.credit.$model"
                :disabled="input.debit.$model > 0 || ((accounting_type_buy === 0) && (k == 0))"
              />
            </div>
            <div class="mx-2 py-4">
              <p
                class="mx-2 text-cian-dark"
                v-if="(accounting_type_buy === 1) && (k == 0)">
                Cuenta por Pagar
              </p>
              <p
                class="mx-2 text-cian-dark"
                v-if="(accounting_type_buy === 0) && (k == 0)">
                Cuenta por Cobrar
              </p>
              <a
                v-if="k != 0"
                class="text-cian-dark"
                @click.prevent="
                  input.destroy_manual.$model = true;
                  remove(k);
                "
              >
                Eliminar
              </a>
            </div>
            <!-- <div v-if="k == dataAcc.length - 1 && k > 0">
            <a class="mx-2" @click.prevent="remove(k)"> Eliminar </a>
          </div> -->
          </div>
          <span class="first:hidden h-px bg-gray-200 dark:bg-gray-muted w-11/12 mx-auto absolute transform -translate-y-6 mt-2"></span>
        </div>
      </div>
    </form>

    <button
      @click.prevent="add()"
      class="my-2 mx-4 text-cian-dark dark:text-white dark:hover:text-cian-dark focus:outline-none"
    >
      Dividir en más
    </button>

    <span v-if="double_match">Diferencia 0</span>
  </div>
</template>
<script>
import "vue-range-slider/dist/vue-range-slider.css";
import { required } from "vuelidate/lib/validators";

export default {
  name: "addAccountMovement",
  props: {
    dataAcc: Array,
    action: String,
    totalAmount: Number,
    neto: Number,
    typeMovement: String,
    ids: Array,
    name_accounting: String,
    accounting_type_buy: { default: null }
  },
  data() {
    return {
      balance: 0,
      accounts: [],
      branchOffices: [],
      costCenter: [],
      showAcc: false,
      double_match: false,
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  validations: {
    dataAcc: {
      required,
      $each: {
        cost_center_id: {},
        min: {},
        max: {},
        branch_office_id: {},
        account_id: {
          required,
        },
        debit: {},
        credit: {},
        total_amount: {},
        percentage: {},
        destroy_manual: {},
        type_account_movement: {},
        new: {},
      },
    },
  },
  components: {},
  mounted() {
    this.getBranchOffice();
    this.getCostCenter();
    this.getAccounts();
  },
  watch: {
    name_accounting() {
      this.calculatePorcentaje(this.dataAcc.length - 1);
    },
  },
  methods: {
    validateAccounts(){
      this.$emit("validateAcc", this.$v.$invalid);
    },
    // create object null ready to add
    add() {
      let cur = this;
      cur.dataAcc.push({
        account_id: null,
        branch_office_id: null,
        cost_center_id: null,
        min: 0,
        max: cur.balance,
        divide: null,
        percentage: 0,
        debit: 0,
        credit: 0,
        date_sii: null,
        total_amount: null,
        state: "exempt_amount",
        company_id: this.company_id,
        type_movement: this.typeMovement,
        type_account_movement: "record",
        _destroy: null,
        destroy_manual: false,
        new: true,
      });
      this.$emit("addAttribute", cur.dataAcc);
      this.$emit("validateAcc", this.$v.$invalid)
    },
    calculatePorcentaje(id) {
      let cur = this;
      this.dataAcc[id].period = cur.$parent.buyObj.period;
      this.dataAcc[id].date_sii = cur.$parent.buyObj.period;
      this.calculateBalance();
      this.$emit("validateAcc", this.$v.$invalid)
      this.$emit("permissionSave", this.double_match);
      this.$emit("addAttribute", this.dataAcc);
    },
    calculateBalance() {
      let cur = this;
      // let lastAccountMovements = cur.dataAcc.slice(0, cur.dataAcc.length);
      let newLastAccountMovements = cur.dataAcc.filter((e) => {
        return e.destroy_manual == false && e.type_account_movement == 'record';
      });
      let sumCredit = newLastAccountMovements.reduce(
        (acc, e) => acc + e.credit,
        0
      );
      let sumDebit = newLastAccountMovements.reduce((acc, e) => acc + e.debit, 0);
      if (sumDebit === sumCredit) {
        cur.$parent.buyObj.total = sumDebit || sumCredit;
        cur.double_match = true;
      } else {
        cur.double_match = false;
      }
    },
    getAccounts() {
      this.axios
        .get("all_parents", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.accounts = response.data;
        });
    },
    remove(index) {
      this.calculatePorcentaje(index)
      let account = this.dataAcc[index];
      if (account.id == null) {
        this.dataAcc.splice(index, 1);
      } else {
        account._destroy = "1";
      }
      this.$emit("validateAcc", this.$v.$invalid)
    },
    getBranchOffice() {
      this.axios
        .get("branch_offices", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.branchOffices = response.data;
        });
    },
    getCostCenter() {
      this.axios
        .get("cost_centers", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.costCenter = response.data;
        });
    },
  },
};
</script>
