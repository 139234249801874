<template>
  <div>
    <transition name="modal">
      <div class="modal-mask h-screen flex justify-center items-center">
        <div class="">
          <div class="modal-container rounded-sm w-50 dark:bg-background-dark">
            <div class="modal-header">
              <slot name="header">
                <div class="w-full flex items-center mt-4">
                  <h1
                    class="text-grey-darker w-full text-2xl dark:text-white"
                  >
                    Clasificar
                  </h1>
                  <a
                    class="text-4xl dark:text-white dark:hover:text-cian-dark"
                    @click.prevent="$emit('close')"> &times; </a>
                </div>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <form class="h-full" enctype="multipart/form-data">
                  <div
                    class="w-full flex flex-col justify-between"
                  >
                    <div class="w-full py-4">
                      <label
                        class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-2 dark:text-white"
                      >
                        Impuesto
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        disabled=true
                        v-model="tax.name"
                      />
                    </div>
                    <div class="w-full py-2">
                      <label
                        class="block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-2 dark:text-white"
                        >Ventas</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="accounts"
                        v-model="tax.account_sale_id"
                        :reduce="(account) => account.id"
                        label="name"
                      >
                      </v-select>
                    </div>
                    <div class="w-full py-2">
                      <label
                        class="block font-bold md:text-left uppercase tracking-wide text-grey-darker text-xs mb-2 dark:text-white"
                        >Compras</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="accounts"
                        v-model="tax.account_buy_id"
                        :reduce="(account) => account.id"
                        label="fantasy_name"
                      >
                      </v-select>
                    </div>
                  </div>
                </form>
              </slot>
            </div>

            <div class="modal-footer flex justify-center">
              <slot name="footer">
                <div class="">
                  <button
                    class="bg-black w-full hover:bg-white hover:text-black border hover:border-black text-white py-2 px-4 rounded-full text-center mb-4 dark:bg-cian-dark dark:border-cian-dark"
                    @click.prevent="submit()"
                  >
                    Guardar Información
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "@/assets/stylesheets/switch.scss";
import "@/assets/stylesheets/modal.css";
export default {
  name: "newAccount",
  props: {
    tax_id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      accounts: [],
      tax: {
        name: null,
        account_buy_id: null,
        account_sale_id: null
      },
    };
  },
  mounted() {
    this.getAccounts();
    this.getTax();

  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    getAccounts() {
      this.axios.get("all_parents",{
          params: {
            company_id: this.company_id
          },
        })
        .then((response) => {
          this.accounts = response.data;
        });
    },    
    getTax() {
      this.axios.get(`taxes/${this.tax_id}`).then((res) => {
        this.tax = res.data;
      });
    },
    submit() {
      this.axios
        .patch(`taxes/${this.tax_id}`, this.tax)
        .then(() => {
          this.$emit("close");
          this.$emit("getTax");
        })
        .catch(() => {
          alert("error");
        });
    },
  },
};
</script>
