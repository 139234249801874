<template>
  <div class="max-w-2xl mx-auto lg:hidden space-y-6">
    <div class="mb-20 text-center font-ubuntu">
      <h2 class="text-3xl font-bold">Compare cada plan</h2>
    </div>

    <PricingCardMobile v-for="plan in plans" :title="plan.title" :price="plan.price" :key="plan.id">
      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Gestión Contable
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Empresas conectadas
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.accountingManagement?.numberCompaniesConnected }}
              </span>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Sincronización Automática SII
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasAutomaticSIISynchronization">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Dashboard
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasDashboard">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Clasificación automática
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasAutomaticClassification">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Monitoreo Obligaciones Fiscales
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasMonitoringTaxObligations">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de Ventas
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasSalesRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de Compras
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasPurchasesRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de Honorarios
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasFeeRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de Honorarios de Terceros
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasThirdPartyFeeRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de Gastos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasExpenseRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Formulario 29
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasF29">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro y Control de clientes
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasCustomerRegistrationManagement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Control de cobros
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasCollectionControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro y Control de proveedores
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasSupplierRegistrationControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Control de Pagos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasPaymentControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Formulario 22 empresa
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasF22Company">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Categorización de compras
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasPurchaseCategorization">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Recomendación de Clasificación
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.accountingManagement?.hasClassificationRecommendation">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Mi Banco
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Cantidad de Bancos Sincronizados hasta
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.myBank?.numberSynchronizedBanks }}
              </span>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Sincronización automática
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasAutomaticSynchronization">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Métrica y Saldos Bancarios
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasBankingMetricsAndBalances">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Conexión MultiBancos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasMultiBankConnection">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Recomendación de conciliación
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasReconciliationRecommendation">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Conciliación automática
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasAutomaticReconciliation">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Control de créditos financieros
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myBank?.hasFinancialCreditControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Gestión de Personas
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de trabajadores hasta
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.peopleManagement?.numberEmployeeManagement }}
              </span>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Contratos Automáticos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasAutomaticContracts">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Liquidaciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasSettlements">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Finiquitos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasSeverancePay">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              App Móvil Trabajador
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasWorkerMobileApp">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Perfil Trabajador
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasWorkerProfile">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Control de vacaciones y solicitudes
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasVacationRequestsControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Previred
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.peopleManagement?.hasPreviredIntegration">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Mi empresa
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Configuración de sincronización, impuestos y otros
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasSynchronizationSettings">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Plan de Cuentas Personalizado
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasCustomChartOfAccounts">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Configuración Sucursales
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasBranchesConfiguration">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Configuración Centro de costos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasCostCenterConfiguration">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Centro de Notificaciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasNotificationsCenter">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Multiempresa
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasMultiCompany">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Integraciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasIntegrations">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Conexión API
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myCompany?.hasAPIConnection">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Mi equipo
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Control de permisos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myTeam?.hasPermissionControl">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de Personas
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myTeam?.hasTaskManagement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Asignación de empresas a usuarios
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myTeam?.hasCompanyAssignmentToUsers">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Dashboard monitoreo continuo contador
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myTeam?.hasOngoingMonitoringDashboardForAccountants">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Monitoreo de actividades usuarios
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.myTeam?.hasUserActivityMonitoring">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Usuarios
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.myTeam?.numberUsers }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          App Móvil
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Métricas
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.mobileApp?.hasMetrics">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Notificaciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.mobileApp?.hasNotifications">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Registro de gastos
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.mobileApp?.hasExpenseRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Reportería e informes
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Informe semanal
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasWeeklyReport">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Carpeta tributaria
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasTaxFolder">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Libro diario
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasGeneralJournal">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Libro mayor
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasGeneralLedger">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Balance
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasBalanceSheet">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Estado de resultado
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasIncomeStatement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Libro de remuneraciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasPayrollRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Flujo de caja
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasCashFlow">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Antifraudes
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Huella Digital
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.antifraud?.hasDigitalFingerprint">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Acuse de Recibo
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.antifraud?.hasReceipt">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Monitoreo Contable
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.antifraud?.hasAccountingMonitoring">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Detección anomalías
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.antifraud?.hasAnomalyDetection">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Notificaciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.antifraud?.hasNotifications">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Estado de resultado
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasIncomeStatement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Libro de remuneraciones
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasPayrollRegister">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Flujo de caja
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.reportingAndReports?.hasCashFlow">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Soporte
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Chat
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.support?.hasChat">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Correo
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.support?.hasEmail">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Telefónico
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.Support?.hasTelephonic">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          BackOffice Contable Tributario
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de impuestos mensuales F29
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.backOfficeCT?.hasMonthlyTaxManagementF29">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de impuestos anuales F22 empresa
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.backOfficeCT?.hasAnualTaxManagementF22">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Monitoreo continuo de tu información
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.backOfficeCT?.hasContinuousMonitoringInformation">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Reuniones Mensuales
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.backOfficeCT?.hasMeet">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Acceso a equipo contable tributario especializado
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.backOfficeCT?.AccessAccountingTeam">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          BackOffice Laboral
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de contrato estándar
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.laborBackOffice?.hasStandardContractManagement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de Liquidación
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.laborBackOffice?.hasSettlementManagement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de Finiquito
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.laborBackOffice?.hasSeverancePayManagement">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Reuniones Mensuales
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.laborBackOffice?.hasMeet">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Acceso a equipo laboral especializado
            </th>
            <td class="py-5 pr-4">
              <template v-if="plan.laborBackOffice?.AccessLaborTeam">
                <IconCheck class="ml-auto" />
                <span class="sr-only">Sí</span>
              </template>
              <template v-else>
                <XMarkIcon class="ml-auto" />
                <span class="sr-only">No</span>
              </template>
            </td>
          </tr>
        </tbody>
      </table>

      <table class="w-full mt-8">
        <caption
          class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-gray-50"
        >
          Costos adicionales
        </caption>
        <thead>
          <tr>
            <th class="sr-only" scope="col">Características</th>
            <th class="sr-only" scope="col">Incluidas</th>
          </tr>
        </thead>

        <tbody class="divide-y divide-gray-200">
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Conexión de banco adicional
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.additionalCosts?.AdditionalBankConnection }}
              </span>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              Gestión de Trabajador adicional
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.additionalCosts?.AdditionalWorker }}
              </span>
            </td>
          </tr>
          <tr class="border-t border-gray-200">
            <th
              class="px-4 py-5 text-sm font-normal text-left text-gray-500"
              scope="row"
            >
              1 Usuario
            </th>
            <td class="py-5 pr-4">
              <span class="block text-sm text-right text-gray-700">
                {{ plan.additionalCosts?.user }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>

    </PricingCardMobile>
  </div>
</template>

<script>
import PricingCardMobile from '@/components/Pricing/CardMobile.vue'
import IconCheck from '@/components/Icons/IconCheck.vue'
import XMarkIcon from '@/components/Icons/XMark.vue'

export default {
  components: {
    PricingCardMobile,
    IconCheck,
    XMarkIcon,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
  }
}
</script>
