<template>
  <div class="py-2 mx-4 lg:mx-6">
    <div class="w-full flex gap-4">
      <div class="lg:text-4xl text-2xl font-semibold items-center dark:text-white flex-auto flex gap-4">
        $ {{formatNumber(sale_total || 0 )}}
      </div>
      <div>
        <a
          v-on:click="editFunctionClient(client_id)"
        >
          <Edit/>
        </a>
      </div>
    </div>
    <div class="flex mb-2 lg:mb-6">
      <h2 class="flex-auto lg:text-lg sm:text-sm text-gray-500 sm:text-left">
        {{client.name}} {{ formatRut(client.rut) }}
      </h2>
    </div>

    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar movimientos"
          @input="reload"
          v-model="query"
        />
      </div>
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          v-model="dateRange"
          type="date"
          range
          format='DD-MM-YYYY'
          placeholder="Selecciona un rango de Fecha"
          v-on:input="reload"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem'
          }"
        ></date-picker>
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="movement_state"
          :options="status"
          :reduce="(state) => state.value"
          label="name"
          @input="reload"
          placeholder="Estado"
        ></v-select>
      </div>
      <div class="filter">
        <v-select
          class="style-chooser custom-border"
          v-model="category_movement"
          :options="types"
          :reduce="(type) => type.value"
          label="name"
          @input="reload"
          placeholder="Tipo de ingreso"
        ></v-select>
      </div>
      <div class="">
        <div class="text-xs font-light mb-1 dark:text-gray-400">Nota de crédito</div>
        <label class="switch label_switch">
          <input
            class="check"
            type="checkbox"
            v-model="onlynotecredit"
            :checked="onlynotecredit"
          />
          <span><slot></slot></span>
        </label>
      </div>
    </div>
    <div>
      <div class="w-full flex-none text-sm font-medium mt-2">
        <p class="text-sm tracking-normal" v-if="client.contact">
          Contacto: {{client.contact}}
        </p>
        <p class="text-sm tracking-normal">
          Días de Vencimiento: {{ formatNumber(client.expiration_date_days) }}
        </p>
        <p class="text-sm tracking-normal">
          Numero de documentos: {{ formatNumber(client.number_of_movements) }}
        </p>
      </div>
    </div>
    <div>
      <newSales
        v-if="showModal"
        @close="showModal = false"
        @getSales="getSales"
        :sale_id="sale_id"
        :action="action"
      ></newSales>
      <newMovement
        v-if="showMovement"
        @getSales="getSales"
        @close="closeFunction"
        :buy_id="sale_id"
      ></newMovement>
    </div>

    <div class="flex flex-col lg:flex-row justify-start gap-4 lg:pr-2 space-y-2 lg:space-y-0 pt-6">
      <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="flex items-center h-full p-4 bg-cyma-red dark:bg-red-dark rounded-lg">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cyma-red-title">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <h5 class="text-xs font-bold uppercase text-cyma-red-title">Total por cobrar</h5>
              <h3 class="font-bold xl:text-xl lg:text-base">
                $ {{ formatNumber(sale_total_not_bill || 0 ) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="flex items-center h-full p-4 bg-cyma-green dark:bg-green-dark rounded-lg">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cyma-green-title">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <h5 class="text-xs font-bold uppercase text-cyma-green-title">Total Cobrado</h5>
              <h3 class="font-bold xl:text-xl lg:text-base">
                $ {{ formatNumber(sale_total_bill || 0 ) }}
              </h3>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="bg-white border border-gray rounded p-4 dark:bg-black-dark dark:border-black-dark">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div></div>
            </div>
            <div class="flex-1">
              <h5 class="font-bold uppercase text-gray-500">
                Promedio de pago
              </h5>
              <h3 class="font-bold text-2xl">-</h3>
            </div>
          </div>
        </div>
      </div> -->
      <div class="w-1/1 md:w-1/2 lg:w-1/4">
        <div class="bg-white border border-gray rounded-lg p-4 dark:bg-black-dark dark:border-black-dark">
          <div class="flex flex-row items-center">
            <div class="flex-shrink pr-4">
              <div class="p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 text-cyma-gray-card">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                </svg>
              </div>
            </div>
            <div class="flex-1">
              <h5 class="text-xs font-bold uppercase text-gray-500">Porcentaje de deuda</h5>
              <h3 class="font-bold xl:text-xl lg:text-base dark:text-white">
                {{formatPercentage(client.percentage_receivable || 0 )}} %
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row justify-around gap-4">
      <div class="w-12/12 lg:w-6/12 pt-6">
        <div class="white bg-white border border-gray rounded-lg p-5 dark:bg-black-dark dark:border-black-dark">
          <h3 class="text-gray-400 font-bold text-lg dark:text-white mb-8">Grafico historico de facturación cliente</h3>
          <div class="relative">
            <bar-chart :chart-data="dataCollection"> </bar-chart>
          </div>
        </div>
      </div>
      <div class="w-12/12 lg:w-6/12 pt-6">
        <div class="white bg-white border border-gray rounded-lg p-5 dark:bg-black-dark dark:border-black-dark">
          <h3 class="text-gray-400 font-bold text-lg dark:text-white mb-8">Pagos por mes de facturas</h3>
          <div class="relative">
            <stacked-chart :chart-data="dataCollectionStacked"> </stacked-chart>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col lg:flex-row justify-around">
      <div class="w-full w-12/12">
        <h3 class="uppercase text-gray-500 py-4">Facturacion Historica</h3>
        <BeeGridTable
          :serverSide="true"
          stripe
          border
          :showFilter="false"
          :showSummary="false"
          :filterTotal="filterTotal"
          :total="total"
          :page="page"
          :pageSize="pageSize"
          :pageCount="pageCount"
          :fetchMethod="fetchMethod"
          :columns="columns"
          :data="sales"
          :loading="loading"
        >
          <BeeColumn field="folio" title="Folio" type="date" sortable="true" :width="180">
            <template slot-scope="{ row }">
              {{ row.folio }}
              <div class="text-sm tracking-normal text-gray-400">
                {{ formatDocType(row.doc_type) }}
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="period" title="Fecha" type="date" :width="180">
            <template slot-scope="{ row }">
              {{ formatSimpleDate(row.period) }}
              <div class="text-sm tracking-normal text-gray-400" v-if="row.movement_status === 'not_bill'">
                {{ differenceInDays(row.period) }} días vencidos
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="dateSII" title="Periodo SII" type="date" :width="180">
            <template slot-scope="{ row }">
              <div class="text-sm tracking-normal flex" v-if="row.claim_date">
                {{ formatDateSII(row.date_sii) }}
                <span class="red pl-3">
                  <Times/>
                </span>
              </div>
              <div class="text-sm tracking-normal flex" v-else>
                {{ formatDateSII(row.date_sii) }}
                <span class="green pl-3">
                  <Check class="w-5 h-5 text-cyma-green-title"/>
                </span>
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="id" title="Clasificación" type="text" :width="300">
            <template slot-scope="{ row }">
              {{ row.account_neto_name }}
            </template>
          </BeeColumn>

          <BeeColumn field="total" title="Total" type="number" sortable="true" :width="180">
            <template slot-scope="{ row }">
              $ {{formatNumber(row.total)}}
              <div class="text-sm tracking-normal text-gray-400">
                $ {{ formatNumber(row.balance) }}
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="cobrado" title="Cobrado" type="text" :width="180">
            <template slot-scope="{ row }">
              <div v-if="row.classified">
                <label class="switch label_switch">
                  <input
                    class="check"
                    type="checkbox"
                    :class="{ notBill: row.movement_status === 'bill_subscriber' }"
                    :checked="checkedFunction(row.movement_status)"
                    @click.prevent="updatePay($event.target.checked, row.id)"
                  />
                  <span><slot></slot></span>
                </label>
              </div>
              <div v-else>
                S/C
              </div>
            </template>
          </BeeColumn>

          <BeeColumn field="editar" title="Opciones" type="text" :width="180">
            <template slot-scope="{ row }">
              <div class="flex gap-5">
                <a v-on:click="editFunction(row.id)">
                  <Edit/>
                </a>

                <a v-on:click="deleteFunction(row.id)">
                  <Times/>
                </a>
              </div>
            </template>
          </BeeColumn>

        </BeeGridTable>
      </div>
    </div>

    <Add
      v-if="showModalClient"
      @close="showModalClient = false"
      @getAllClients="reload"
      :id="id"
      :action="action"
    ></Add>
  </div>
</template>

<script>
  import { formatDocType,
    formatNumber,
    formatPercentage,
    formatSimpleDate,
    formatDateSII,
    differenceInDays
  } from "@/utils/formatters.js";
  import DatePicker from 'vue2-datepicker';
  import BarChart from "../BarChart";
  import StackedChart from "../StackedChart";
  import Add from "./Add";
  import newSales from "../sales/newSales";
  import newMovement from "../sales/newMovement";
  import Check from '@/components/Icons/CheckBadge.vue';
  import Times from '@/components/Icons/Times.vue';
  import Edit from '@/components/Icons/Edit.vue';
  import "@/assets/stylesheets/switch.scss";

  export default {
    name: "Clients",
    props: {},
    data() {
      return {
        sales: [],
        current_page: 1,
        per_page: 10,
        total_pages: 0,
        total_items: 0,
        filterTotal: 0,
        page: 1,
        total: 0,
        pageCount: 0,
        pageSize: 0,
        nameSorting: null,
        sorting: null,
        query: null,
        without_account: 0,
        client_id: parseInt(this.$route.params.client_id),
        columns: [],
        sale_total: 0,
        sale_total_bill: 0,
        sale_total_not_bill: 0,
        client: [],
        showModal: false,
        showModalClient: false,
        showMovement: false,
        dataCollection: null,
        dataCollectionStacked: null,
        data_graph_sales: [],
        data_graph_buys: [],
        data_graph_sale_total_bill: [],
        data_graph_sale_total_not_bill: [],
        movement_state: null,
        status: [],
        category_movement: null,
        types: [],
        loading: true,
        onlynotecredit: false,
      };
    },
    computed: {
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      },
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
    },
    watch: {
      company_id(){
        this.getSales();
        this.getClients();
      },
      onlynotecredit(){
        this.getSales();
        this.getStas();
      },
    },
    components: {
      DatePicker,
      BarChart,
      StackedChart,
      Add,
      newSales,
      newMovement,
      Check,
      Times,
      Edit
    },
    mounted() {
      this.getSales();
      this.getClients();
      this.getStas();

      this.status = [
        { value: null, name: 'Todos los estados' },
        { value: 3, name: 'No cobrado' },
        { value: 2, name: 'Cobrado' },
      ]

      this.types = [
        { value: null, name: 'Todos los tipo de ingreso' },
        { value: 0, name: "Ventas con facturas" },
        { value: 1, name: "Otros ingresos" },
        { value: 2, name: "Ventas con boletas" }
      ]
    },
    methods: {
      formatDocType,
      formatNumber,
      formatPercentage,
      formatSimpleDate,
      formatDateSII,
      differenceInDays,
      reload() {
        this.getSales();
        this.getClients();
        this.getStas();
      },
      fetchMethod(sortInfos, filterInfos, offset, size) {
        // print sort column infomations、filter column infomations
        console.log(sortInfos, filterInfos, offset, size);
        let sorting = "";
        for (let i = 0; i < sortInfos.length; i++) {
          sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
          if (i !== sortInfos.length - 1) {
            sorting += ",";
          }
        }
        console.log(sorting);
        // this.page = offset;
        if (sortInfos.length > 0) {
          let _nameSorting = [];
          let _sorting = [];

          _nameSorting = sortInfos.map(function (value) {
            return value.field;
          });

          _sorting = sortInfos.map(function (value) {
            return value.sortType;
          });
          this.sorting = _sorting.toString();
          this.nameSorting = _nameSorting.toString();
        }
        this.current_page = offset;
        this.per_page = size;
        this.getSales();
      },
      editFunctionClient(id) {
        this.showModalClient = true;
        this.id = id;
        this.action = "update";
      },
      editFunction(id) {
        console.log("update",id)
        this.showModal = true;
        this.sale_id = id;
        this.action = "update";
      },
      closeFunction(){
        this.showMovement = false
      },
      updatePay(event,id) {
        console.log(event)
        this.showMovement = true
        this.sale_id = id
      },
      deleteFunction(id) {
        this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No recuperarás este ingreso",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminalo",
            cancelButtonText: "No, conservalo",
          })
          .then((result) => {
            if (result.value) {
              this.$swal.fire(
                "Eliminado",
                "Tu ingreso ha sido eliminado",
                "success"
              );
              this.axios.delete(`movements/${id}`).then(() => this.getBuys());
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "Tu ingreso no ha sido eliminado",
                "error"
              );
            }
          });
      },
      checkedFunction(status){
        if(status == "not_paid") return false
          return status === "bill_subscriber" || status === "bill"
      },
      fillData() {
        const app = this;
        this.dataCollection = {
          labels: this.labels,
          responsive: true,
          datasets: [
            {
              label: "Ventas",
              borderWidth: 1,
              stack: "ventas",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sales,
            },
            {
              label: "Compras",
              borderWidth: 1,
              stack: "compras",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_buys,
            },
          ],
        };
      },
      fillDataStacked(){
        const app = this;
        this.dataCollectionStacked = {
          labels: this.labels,
          datasets: [
            {
              label: "Pagado",
              borderWidth: 1,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sale_total_bill
            },
            {
              label: "No Pagado",
              borderWidth: 1,
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sale_total_not_bill
            }
          ]
        }
      },
      formatRut(target) {
        if(target != undefined) {
          let value = target.replace(/\./g, "").replace("-", "");
          if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
            value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
          } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
            value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4");
          } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
            value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3");
          } else if (value.match(/^(\d)(\d{0,2})$/)) {
            value = value.replace(/^(\d)(\d{0,2})$/, "$1.$2");
          }
          return value
        }
      },
      getClients() {
        this.loading = true;
        let app = this;
        this.axios.get("/clients/" + app.client_id, {
          params: {
            company_id: app.company_id,
          }
        },
        ).then((response) => {
          app.client = response.data;
        });
      },
      getSales() {
        this.loading = true;
        let app = this;
        this.axios.get("/movements",{
          params: {
            type_movement: 'type_sale',
            company_id: app.company_id,
            client_id: app.client_id,
            category_movement: app.category_movement || [0, 1, 2],
            movement_status: app.movement_state,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            pending_sii: app.pending_sii,
            sorting: app.sorting,
            query: app.query,
            from: app.dateRange[0],
            to: app.dateRange[1],
            onlynotecredit: app.onlynotecredit,
          }
        },
        ).then((response) => {
          app.sales = response.data.data;
          app.filterTotal = response.data.total_items; //total filtered data count
          app.total = response.data.per_page; // total data count
          app.page = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.without_account = response.data.without_account;
          app.sale_total = response.data.sale_total;
          app.sale_total_bill = response.data.sale_total_bill;
          app.sale_total_not_bill = response.data.sale_total_not_bill;
          app.loading = false;
        });
      },
      getStas() {
        this.loading = true;
        let app = this;
        this.axios
        .get("/stats", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            client_id: app.client_id,
            query: app.query,
            category_movement: app.category_movement || [0, 1, 2],
            movement_status: app.movement_state,
            per_page: 5,
            onlynotecredit: this.onlynotecredit
          },
        })
        .then((response) => {
          this.total_items = response.data.total_items;
          this.labels = response.data.labels;
          this.data_graph_sales = response.data.data_graph_sales;
          this.data_graph_buys = response.data.data_graph_buys;
          this.data_graph_sale_total_bill = response.data.data_graph_sale_total_bill;
          this.data_graph_sale_total_not_bill = response.data.data_graph_sale_total_not_bill;
          this.fillData();
          this.fillDataStacked();
          this.loading = false;
        });
      }
    },
  };
</script>
