<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Datos de Facturación
    </h2>
    <div class="grid w-full gap-4 my-3 lg:grid-cols-3 sm:grid-cols-2">
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Rut</label
        >
        <!-- VUE RUT MANUAL-->
        <div>
          <input
            :value="company.billing_data_rut"
            type="text"
            placeholder="12.345.678-0"
            autofocus
            maxlength="12"
            @input.prevent="format($event.target.value)"
            class="input-text"
          />
          <div v-if="rut_loading">
            <Spinner
            />
          </div>
          <div v-else>
            <p v-if="rut_error" class="text-red-400">No es un rut valido</p>
            <p
              v-else-if="!rut_error && rut_error != null"
              class="text-green-500"
            >
              Rut valido y verificado
            </p>
          </div>
        </div>
        <!--  -->
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Razón Social
        </label>
        <input
          class="input-text"
          type="text"
          disabled
          :value="company.billing_data_business_name"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Correo de Facturación
        </label>
        <input
          class="input-text"
          type="email"
          v-model="company.billing_data_email"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Dia de Emisión</label
        >
        <input
          type="text"
          pattern="\\d{1,31}"
          value="28"
          class="input-text"
          placeholder=""
          disabled
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Dia de Vencimiento</label
        >
        <input
          class="input-text"
          type="number"
          :max="31"
          value="5"
          disabled
        />
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner2.vue';

export default {
  props: ["company"],
  name: "BillingData",
  components: { Spinner },
  data() {
    return {
      rut: "",
      rut_loading: false,
      rut_error: null,
      loading: false,
    };
  },
  created() {
    this.$emit('updateTab', "BillingData")
  },
  methods: {
    format(value) {
      this.company.billing_data_rut = this.cleanRut(value)
      let cleanRut = this.cleanRut(value);
      let len = cleanRut.length - 1;
      if (len > 3) {
        let dv = cleanRut[len];
        let beforeDv = cleanRut
          .substring(0, len)
          .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        this.company.billing_data_rut = beforeDv + "-" + dv;
        let rut = beforeDv + "-" + dv;
        if (len > 6 && !cleanRut.endsWith("-" || ".")) {
          this.getRutData(rut);
        }
      }
    },
    cleanRut(value) {
      if (value != undefined) {
        return value.replace(/^0+|[^0-9kK]+/g, "").toUpperCase();
      }
    },
    getRutData(rut) {
      this.rut_loading = true;
      this.axios
        .get("api/v1/rut_data", {
          params: {
            rut: rut,
          },
        })
        .then((response) => {
          this.rut_loading = false;
          if (response.data.error) {
            this.rut_error = true;
          } else {
            this.rut_error = false;
            this.company.billing_data_business_name = response.data.razon_social;
          }
        });
    },
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company.id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
};
</script>
