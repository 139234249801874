<template>
  <div class="lds-dual-ring" :style="cssProps"></div>
</template>
<script>
export default {
  name: 'Spinner',
  props: ['color'],
  computed: {
    cssProps() {
      // Set the color of the spinner againt the color prop
      return {
        '--color': this.color || '#fff'
      }
    }
  },
  data() {
    return {

    }
  },
}
</script>
<style scoped>
.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 44px;
  height: 44px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--color);
  border-color: var(--color) transparent var(--color) transparent;
  animation: lds-dual-ring 1.4s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
