import Vue from "vue";
import Router from "vue-router";
import * as Sentry from "@sentry/vue";
import Private from "../views/private/application.vue";
import Public from "../views/public/application.vue";
import NotFound from "../components/pages/404.vue";
/* Components */
import { PublicRoutes } from "../views/public/router";
import { PrivateRoutes } from "../views/private/router";
/* Routes */
import store from "../store"

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Private,
      children: PrivateRoutes,
      meta: {
        auth: true,
      },
    },
    {
      path: "/public",
      component: Public,
      children: PublicRoutes,
      meta: {
        auth: false,
      },
    },
    {
      path: "/404",
      component: NotFound
    },
    {
      path: "*",
      redirect: "/404"
    },
  ],
});
Sentry.init({
  Vue,
  dsn: "https://6f04e67ef15c46f8b07c289296213416@o891111.ingest.us.sentry.io/5839793",
  integrations: [
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

router.beforeEach((to, from, next) => {
  store.commit("addPermitted", false);
  let role = store.state.role
  if(to.meta.auth){
    if (to.meta.adminAuth) {
      if (role == "admin") {
        store.commit("addPermitted", true);
      }
    }
    if (to.meta.egressDigitizerAuth) {
      if (role == "egress_digitizer") {
        store.commit("addPermitted", true);
      }
    }
    if (to.meta.accountantAuth) {
      if (role == "accountant") {
        store.commit("addPermitted", true);
      }
    }
    next()
  } else{
    next()
  }
});

export default router;
