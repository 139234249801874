import Dashboard from "@/views/private/components/index.vue";
import Accounting from "@/views/private/components/accounting/Index.vue";

import Company from "@/views/private/components/company/Company";
import AddCompany from "@/views/private/components/company/AddCompany";
import editCompanyData from "@/views/private/components/company/tabs/CompanyData"
import editLegalRepresentatives from "@/views/private/components/company/tabs/LegalRepresentatives"
import editPartners from "@/views/private/components/company/tabs/Partners"
import editBillingData from "@/views/private/components/company/tabs/BillingData"
import editNotificationData from "@/views/private/components/company/tabs/NotificationData"
import editIntegrations from "@/views/private/components/company/tabs/Integrations"
import editSii from "@/views/private/components/company/tabs/SII"
import editF29 from "@/views/private/components/company/tabs/F29"
import editUsersAllowed from "@/views/private/components/company/tabs/UsersAllowed"
import editCompanyProperty from "@/views/private/components/company/tabs/CompanyProperty"

import CompanyIndex from "@/views/private/components/company/Index";
import Sales from "@/views/private/components/sales/Sales";
import Buys from "@/views/private/components/buys/Buys";

import Vendors from "@/views/private/components/vendors/Index";
import AllVendors from "@/views/private/components/vendors/All";

import Clients from "@/views/private/components/clients/Index";
import AllClients from "@/views/private/components/clients/All";

import Employees from "@/views/private/components/employees/Index";
import AllEmployees from "@/views/private/components/employees/All";

import Partners from "@/views/private/components/partners/Index";
import AllPartners from "@/views/private/components/partners/All";

import Requests from "@/views/private/components/requests/Index";
import AllRequests from "@/views/private/components/requests/All";

import Accounts from "@/views/private/components/accounts/Index";
import Representatives from "@/views/private/components/representative/index";
import DashboardTax from "@/views/private/components/tax/dashboard";
import Tax from "@/views/private/components/tax";
import F29 from "@/views/private/components/tax/f29";
import Profile from "@/views/private/components/profile/index";
import ProfileAccountant from "@/views/private/components/profile/accountants";
import EditProfile from "@/views/private/components/profile/editUser";
import ChangePasswordProfile from "@/views/private/components/profile/changePassword";

import Integrations from "@/views/private/components/integration/index";

import remunerationShow from "@/views/private/components/buys/remunerationShow";
import previredShow from "@/views/private/components/buys/previredShow"
import f29Show from "@/views/private/components/buys/f29Show"
import f22Show from "@/views/private/components/buys/f22Show"
import f22ShowSales from "@/views/private/components/sales/f22Show"

import Recommended from "@/views/private/components/recommended/index";

import ServiceProvider from "@/views/private/components/serviceProviders/index";
import serviceProviderShow from "@/views/private/components/serviceProviders/show";
import CostCenter from "@/views/private/components/cost_center/index";
import BranchOffice from "@/views/private/components/branch_office/index";

import ReportBalance from "@/views/private/components/reports/balance";
import ReportRli from "@/views/private/components/reports/rli";
import ReportTax from "@/views/private/components/reports/tax";
import ReportDiaryBook from "@/views/private/components/reports/diaryBook";
import ReportLedger from "@/views/private/components/reports/ledger";
import ReportCashFlow from "@/views/private/components/reports/cashflow";
import ReportStatementOfIncome from "@/views/private/components/reports/statementOfIncome";
import ReportStatementOfIncomeComparative from "@/views/private/components/reports/statementOfIncomeComparative";
import Historial from "@/views/private/components/reports/historial";
import RemunerationBook from "@/views/private/components/reports/remunerationBook";

import AddFintoc from "@/views/private/components/banks/addFintoc";
import Banks from "@/views/private/components/banks/index";
import Transaction from "@/views/private/components/banks/transaction";
import TabContacts from "@/views/private/components/TabContact/index";

import onboarding from "@/views/private/components/onboarding/Index"

import Subscription from "@/views/private/components/subscriptions/index";
import SelectPayment from "@/views/private/components/subscriptions/selectPayment";

import PaymentPlan from "@/views/private/components/orders/paymentPlan";
import TransactionDetails from "@/views/private/components/orders/transactionDetails";
import MyPlan from "@/views/private/components/orders/myPlan";

import Versions from "@/views/private/components/versions/index";

import Currency from "@/views/private/components/currencies/index";

import MercadoPago from "@/views/private/components/mercado_pago/index";
import Credit from "@/views/private/components/credit/index"
import CreditShow from "@/views/private/components/credit/Show"

import Gpt from "@/views/private/components/gpt/index";
import GptDoc from "@/views/private/components/gpt/doc";

import Observation from "@/views/private/components/observations/index";

export const PrivateRoutes = [
  {
    path: "/",
    name: "dashboard",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Dashboard,
    props: true,
  },
  {
    path: "/integraciones",
    name: "integration",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Integrations,
    props: true,
  },
  {
    path: "/impuestos",
    name: "dashboardTax",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: DashboardTax,
    props: true,
  },
  {
    path: "/recommended",
    name: "recommended",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Recommended,
    props: true,
  },
  {
    path: "/observation",
    name: "observation",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Observation,
    promps: true,
  },
  {
    path: "/accounting",
    name: "accounting",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Accounting,
    props: true,
  },
  {
    path: "/companies",
    name: "Company",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Company,
  },
  {
    path: "/account",
    name: "account",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Accounts,
  },
  {
    path: "/tax",
    name: "tax",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Tax,
  },
  {
    path: "/f29",
    name: "F29",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: F29,
  },
  {
    path: "/add-company",
    // name: "AddCompany",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    children: [
      {
        path: "",
        name: "editCompanyData",
        component: editCompanyData,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "legal-representatives",
        name: "editLegalRepresentatives",
        component: editLegalRepresentatives,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "partners",
        name: "editPartners",
        component: editPartners,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "billing-data",
        name: "editBillingData",
        component: editBillingData,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "notification-data",
        name: "editNotificationData",
        component: editNotificationData,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "integrations",
        name: "editIntegrations",
        component: editIntegrations,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "sii",
        name: "editSii",
        component: editSii,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "F29",
        name: "editF29",
        component: editF29,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "users-allowed",
        name: "editUsersAllowed",
        component: editUsersAllowed,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
      {
        path: "company-property",
        name: "editCompanyProperty",
        component: editCompanyProperty,
        meta: {
          auth: true,
          adminAuth: true,
          accountantAuth: true,
        },
      },
    ],
    component: AddCompany,
    props: true,
  },
  {
    path: "company/sales",
    name: "Sales",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Sales,
  },
  {
    path: "enterprise/:props_company_id",
    name: "CompanyIndex",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: CompanyIndex,
    props: true,
  },
  {
    path: "company/buys/vendor/:vendor_id",
    name: "Vendors",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Vendors,
  },
  {
    path: "company/buys/service-provider/:service_provider_id",
    name: "serviceProviderShow",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: serviceProviderShow,
  },
  {
    path: "company/buys/remuneration/:employee_id",
    name: "remunerationShow",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: remunerationShow,
  },
  {
    path: "company/buys/previred/:movement_id",
    name: "previredShow",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: previredShow,
  },
  {
    path: "company/buys/form_29/:movement_id",
    name: "f29Show",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: f29Show,
  },
  {
    path: "company/buys/form_22/:movement_id",
    name: "f22Show",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: f22Show,
  },
  {
    path: "company/sales/form_22/:movement_id",
    name: "f22ShowSales",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: f22ShowSales,
  },
  {
    path: "company/sales/client/:client_id",
    name: "Clients",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Clients,
  },
  {
    path: "company/clients",
    name: "AllClients",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: AllClients,
  },

  {
    path: "company/sales/employee/:employee_id",
    name: "Employees",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Employees,
  },
  {
    path: "company/employees",
    name: "AllEmployees",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: AllEmployees,
  },
  {
    path: "company/sales/partner/:partner_id",
    name: "Partners",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Partners,
  },
  {
    path: "company/partners",
    name: "AllPartners",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: AllPartners,
  },
  {
    path: "company/vendors",
    name: "AllVendors",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: AllVendors,
  },
  {
    path: "company/representatives",
    name: "Representatives",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Representatives,
  },
  {
    path: "company/buys",
    name: "Buys",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
      egressDigitizerAuth: true,
    },
    component: Buys,
  },
  {
    path: "profile",
    name: "profile",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Profile,
  },
  {
    path: "profile-accountants",
    name: "profile-accountants",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ProfileAccountant,
    props: true,
  },
  {
    path: "editProfile",
    name: "editProfile",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: EditProfile,
  },
  {
    path: "changePasswordProfile",
    name: "changePasswordProfile",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ChangePasswordProfile,
  },
  {
    path: "serviceProvider",
    name: "serviceProvider",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ServiceProvider,
  },
  {
    path: "costCenter",
    name: "costCenter",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: CostCenter,
  },
  {
    path: "branchOffice",
    name: "branchOffice",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: BranchOffice,
  },
  {
    path: "reports/balance",
    name: "reports",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportBalance,
  },
  {
    path: "reports/rli",
    name: "rli",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportRli,
  },
  {
    path: "reports/tax",
    name: "reportsTax",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportTax,
  },
  {
    path: "reports/libro-diario",
    name: "diaryBook",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportDiaryBook,
  },
  {
    path: "reports/estado-resultado",
    name: "statementOfIncome",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportStatementOfIncome,
  },
  {
    path: "reports/estado-resultado-comparativo",
    name: "statementOfIncomeComparative",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportStatementOfIncomeComparative,
  },
  {
    path: "reports/historial",
    name: "historial",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Historial,
  },
  {
    path: "reports/remuneration-book",
    name: "remunerations",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: RemunerationBook,
  },
  {
    path: "reports/libro-mayor",
    name: "ledger",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportLedger,
  },
  {
    path: "reports/flujo-caja",
    name: "cashflow",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: ReportCashFlow,
  },
  {
    path: "AddFintoc",
    name: "AddFintoc",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: AddFintoc,
  },
  {
    path: "banks",
    name: "banks",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Banks,
  },
  {
    path: "transaction",
    name: "transaction",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Transaction,
    props: true,
  },
  {
    path: "tabs_contacts",
    name: "tabs_contacts",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: TabContacts,
  },
  {
    path: "payment-plan/:id",
    name: "PaymentPlan",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: PaymentPlan,
    props: true,
  },
  {
    path: "transaction-details",
    name: "TransactionDetails",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: TransactionDetails,
    props: true,
  },
  {
    path: "my-plan",
    name: "MyPlan",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: MyPlan,
  },

  {
    path: "subscription",
    name: "Subscription",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Subscription,
  },
  {
    path: "select-payment",
    name: "SelectPayment",
    component: SelectPayment,
    meta: {
      auth: true,
    },
    props: true,
    beforeEnter: (to, from, next) => {
      if (!to.params.subscriptionId) next({ name: "Subscription" });
      else next();
    },
  },
  {
    path: "/request/:request_id",
    name: "Requests",
    component: Requests,
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
  },
  {
    path: "requests",
    name: "AllRequests",
    component: AllRequests,
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
  },
  {
    path: "credits",
    name: "CreditsIndex",
    component: Credit,
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
  },
  {
    path: "credits/:id",
    name: "CreditShow",
    component: CreditShow,
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    props: true,
  },
  {
    path: "onboarding",
    name: "Onboarding",
    component: onboarding,
    meta: {
      auth: true,
      adminAuth: true,
    },
  },
  {
    path: "versions",
    name: "Versions",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Versions,
  },
  {
    path: "currencies",
    name: "Currency",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Currency,
  },
  {
    path: "mercado-pago",
    name: "MercadoPago",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: MercadoPago,
  },
  {
    path: "gpt",
    name: "gpt",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: Gpt,
  },
  {
    path: "GptDoc",
    name: "GptDoc",
    meta: {
      auth: true,
      adminAuth: true,
      accountantAuth: true,
    },
    component: GptDoc,
  },
  // {
  //   path: "profile/:accountant_id",
  //   name: "AccountantProfile",
  //   meta: {
  //     auth: true,
  //     adminAuth: true,
  //     accountantAuth: true,
  //   },
  //   component: () => import("@/views/private/components/accountantProfile"),
  //   props: true,
  // },
];
