<template>
  <div>
    <transition name="modal">
      <div class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="max-h-screen overflow-y-auto absolute md:w-full xl:w-11/12">
          <div
            class="
              dark:bg-background-dark
              bg-white
              w-screen
              h-auto
              py-5
              px-4
              md:w-full
              md:py-4 lg:px-7 xl:px-10
            "
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="
                    font-semibold
                    text-grey-darker
                    py-8
                    text-4xl
                    capitalize
                    dark:text-white
                  "
                  v-if="this.action === 'create'"
                >
                  Crear {{ setName(buyObj.category_movement) }}
                </h1>
                <h1
                  class="
                    font-semibold
                    text-grey-darker
                    py-8
                    text-4xl
                    capitalize
                    dark:text-white"
                  v-else
                >
                  Actualizar {{ setName(buyObj.category_movement) }}
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="$emit('close')">
                  &times;
                </a>
              </div>
            </div>
            <div class="" id="modalWidth">
              <slot name="body">
                <div class="flex flex-col items-end">
                  <div class="flex flex-col">
                    <div :class="{'opacity-40 pointer-events-none': buyObj.accounting_not_include }" class="flex items-center gap-5">
                      <p>Por Cobrar</p>
                      <label class="switch label_switch_active w-10">
                        <input
                          class="check"
                          type="checkbox"
                          v-model="switchState"
                        />
                        <span class=""><slot></slot></span>
                      </label>
                      <p>Por Pagar</p>
                    </div>
                    <div class="flex items-center gap-3">
                      <input @change="changeState($event)" v-model="buyObj.accounting_not_include" type="checkbox" name="not_include" id="not_include">
                      <label for="not_include">No incluir</label>
                    </div>
                  </div>
                </div>
                <form
                  class="
                    grid
                    gap-5
                    grid-cols-1
                    md:grid-cols-2
                    lg:grid-cols-3
                    pt-8
                  "
                  enctype="multipart/form-data"
                >
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Nombre</label
                    >
                    <input
                      :class="{ error: $v.buyObj.name.$error }"
                      type="text"
                      class="input-text"
                      data-cy="voucherName"
                      v-model="$v.buyObj.name.$model"
                    />
                    <div class="text_error" v-if="$v.buyObj.name.$error">
                      Este campo es requerido
                    </div>
                  </div>

                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Fecha</label
                    >
                    <date-picker
                      :class="[{ error: $v.buyObj.period.$error }]"
                      input-class="xmx-input"
                      type="date"
                      data-cy="date"
                      format='DD-MM-YYYY'
                      :style="{
                        width: '100%',
                        'padding-left': '0.55rem',
                        'padding-right': '0.55rem',
                        'padding-top': '0.65rem',
                        'padding-bottom': '0.65rem',
                        'border-radius': '0.5rem'
                      }"
                      v-model="$v.buyObj.period.$model"
                      placeholder="Selecciona una Fecha"
                      :disabled="[2, 3].includes(buyObj.category_movement)"
                    ></date-picker>
                    <div class="text_error" v-if="$v.buyObj.period.$error">
                      Este campo es requerido
                    </div>
                  </div>
                  <div class="flex flex-col">
                    <label
                      class="label-cymasuite"
                      >Descripción</label
                    >
                    <input
                     data-cy="description"
                      type="text"
                      class="input-text"
                      v-model="buyObj.description"
                    />
                  </div>

                  <div class="col-span-1 md:col-span-2 lg:col-span-3">
                    <div class="w-full mb-6"></div>
                    <addAccountVoucher
                      :action="action"
                      :dataAcc="dataAccountVourcher"
                      :typeMovement="'accounting'"
                      :totalAmount="buyObj.total"
                      :neto="buyObj.neto"
                      :ids="ids"
                      @addAttribute="addAttribute"
                      @permissionSave="permissionSave"
                      @validateAcc="validateAcc"
                      :name_accounting="buyObj.name"
                      :accounting_type_buy="buyObj.accounting_type_buy"
                    ></addAccountVoucher>
                  </div>
                </form>
              </slot>
            </div>

            <div class="modal-footer flex justify-end py-3">
              <slot name="footer">
                <div v-if="permission">
                  <button
                    v-if="!loadingSave"
                    class="
                      bg-black
                      w-full
                      hover:bg-white hover:text-black
                      border
                      hover:border-black
                      text-white
                      font-bold
                      p-2
                      px-4
                      rounded-full
                      dark:border-cian-dark
                      dark:bg-cian-dark
                    "
                    @click.prevent="submit"
                  >
                    Guardar Comprobante
                  </button>
                  <Spinner2 v-else customClass="h-5 w-5 dark:text-white" />
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

import DatePicker from "vue2-datepicker";
import addAccountVoucher from "./addAccountVoucher";
import { required } from "vuelidate/lib/validators";
import Spinner2 from "@/components/Spinner2.vue";

export default {
  name: "newAccouting",
  props: {
    buy_id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      loading: true,
      loadingSave: false,
      switchState: false,
      file: null,
      inputActive: false,
      dataAcc: [],
      permission: false,
      dataAccountVourcher: [
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "accounting",
          state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
          destroy_manual: false,
          accounting_type_buy: null
        },
        {
          movement_id: null,
          debit: 0,
          credit: 0,
          total_amount: 0,
          period: null,
          comentary: null,
          account_id: null,
          type_account_movement: "record",
          type_movement: "accounting",
          state: "neto",
          account_name: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 0,
          divide: null,
          min: 0,
          max: 0,
          date_sii: null,
          company_id: this.$store.state.company_id,
          disable: false,
          destroy_manual: false
        },
      ],
      accounts: [],
      dataAccValidate: true,
      types: [{ value: 9, name: "Comprobantes contables" }],
      filename: null,
      url: null,
      ids: [],
      buyObj: {
        type_movement: "type_accounting",
        category_movement: 9,
        coin: null,
        total: null,
        period: "",
        responsable: null,
        folio: null,
        account_neto_id: null,
        company_id: this.$store.state.company_id,
        user_id: null,
        vendor_id: null,
        neto: null,
        file_movement: null,
        name: null,
        description: null,
        movement_status: "not_paid",
        account_movements_attributes: [],
        accounting_type_buy: null,
        accounting_not_include: true,
      },
    };
  },
  components: {
    addAccountVoucher,
    DatePicker,
    Spinner2
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    switchState(value){
      if(this.buyObj.accounting_not_include){
        this.buyObj.accounting_type_buy = null
        this.dataAccountVourcher[0].accounting_type_buy = null
        return
      }
      if(value){
        this.buyObj.accounting_type_buy = 1
        this.dataAccountVourcher[0].accounting_type_buy = 1
      }else{
        this.buyObj.accounting_type_buy = 0
        this.dataAccountVourcher[0].accounting_type_buy = 0
      }
    }
  },
  validations: {
    buyObj: {
      name: {
        required,
      },
      period: {
        required,
      },
      category_movement: {
        required,
      },
    },
  },
  created() {
    this.getBuy();
    this.getFile();
  },
  methods: {
    changeState(event){
      if(event.target.checked){
        this.dataAccountVourcher[0].accounting_type_buy = null
         this.buyObj.accounting_type_buy = null
      } else {
        this.switchState = false
        this.dataAccountVourcher[0].accounting_type_buy = 0
        this.buyObj.accounting_type_buy = 0
      }
    },
    validateAcc(status){
      this.dataAccValidate = status
    },
    setName(id) {
      if (id) {
        const resultado = this.types.find((row) => row.value === id);
        return resultado.name;
      }
    },
    permissionSave(e) {
      this.permission = e;
    },
    addAttribute(e) {
      this.buyObj.account_movements_attributes = e;
    },
    getFile() {
      if (this.buy_id) {
        this.axios.get(`movements/${this.buy_id}/file_movement`).then((e) => {
          this.url = e.data.url;
          this.filename = e.data.name;
        });
      }
    },
    getBuy() {
      if (this.buy_id) {
        this.axios.get(`movements/${this.buy_id}`).then((res) => {
          switch (res.data.category_movement) {
            case "buy_sii":
              this.buyObj.category_movement = 2;
              break;
            case "honorary_sii":
              this.buyObj.category_movement = 3;
              break;
            case "monthly_expenses":
              this.buyObj.category_movement = 4;
              break;
            case "remuneration":
              this.buyObj.category_movement = 5;
              break;
            case "withdraw":
              this.buyObj.category_movement = 6;
              break;
            case "accounting_voucher":
              this.buyObj.category_movement = 9;
              break;
          }
          this.buyObj.coin = res.data.coin === "pen" ? 0 : 1;
          this.buyObj.name = res.data.name;
          this.buyObj.total = Number(res.data.total);
          this.buyObj.neto = Number(res.data.neto);
          this.buyObj.period = new Date( res.data.period);
          this.buyObj.responsable = res.data.responsable;
          this.buyObj.folio = res.data.folio;
          this.buyObj.company_id = res.data.company_id;
          this.buyObj.vendor_id = res.data.vendor_id;
          this.buyObj.user_id = res.data.user_id;
          this.buyObj.account_neto_id = res.data.account_neto_id;
          this.buyObj.description = res.data.description;
          this.buyObj.accounting_type_buy = res.data.accounting_type_buy
          this.switchState = res.data.accounting_type_buy == 'accounting_receivable' ? false : true
          this.buyObj.accounting_not_include = res.data.accounting_not_include
          this.buyObj.movement_status = res.data.movement_status
          this.dataAcc = res.data.account_movements;
          this.dataAccountVourcher = res.data.account_movements.sort((a, b) => {
            return a.id - b.id;
          });
          this.setMaxMin(res.data.account_movements_only_neto);
        });
      }
    },
    uploadFile() {
      this.file = this.$refs.inputFile.files[0];
    },
    setMaxMin(account_movements) {
      let a = account_movements.map((element, index) => {
        if (
          this.action === "create" ||
          element.state === "neto" ||
          element.state === "exempt_amount" ||
          element.state === "gross"
        ) {
          let porcentaje = (element.total_amount / this.buyObj.neto) * 100;
          element.percentage = Math.round(porcentaje, 2);
          element.min = 0;
          element.max = Math.floor(this.buyObj.neto * element.percentage) / 100;
          this.ids.push(index);
          element.disable = false;
          return element;
        }
        return element;
      });
      this.dataAcc = a;
      console.log(a);
    },
    updateFile(id) {
      let data = new FormData();
      data.append("file", this.file);
      data.append("id", id);
      this.axios
        .patch("/update_file", data)
        .then(() => console.log("sucess"))
        .catch((e) => console.log(e));
    },
    create(data) {
      let selectedYear = this.buyObj.period.getFullYear();
      let selectedMonth = this.buyObj.period.getMonth();
      if (selectedYear < new Date().getFullYear() || selectedMonth < new Date().getMonth()) {
        this.createValidateDate(data)
        return
      }
      this.axios
        .post("/movements", data)
        .then((obj) => {
          this.$swal.fire(
            "Creado",
            "Tu comprobante ha sido creado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file");
          this.loadingSave = false;
          this.$emit("getBuys");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    createValidateDate(data) {
        this.$swal
          .fire({
            title: "¿Estas seguro?",
            text: "La fecha registrada es de un mes o año anterior.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Aceptar",
            cancelButtonText: "Cancelar",
          })
          .then((result) => {
            if (result.value){
              this.axios.post("/movements", data)
              .then((obj) => {
              this.$swal.fire(
                "Creado",
                "Tu comprobante ha sido creado con exito",
                "success"
              );
              this.file ? this.updateFile(obj.data.id) : console.log("error file");
              this.loadingSave = false;
              this.$emit("getBuys");
              this.$emit("close");
            })
            .catch(() => {
              this.$swal.fire("Oops...", "Algo ha salido mal", "error");
            });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "No se ha creado el comprobante",
                "error"
              );
            }
          })
    },
    update(data, id) {
      this.loadingSave = true;
      this.axios
        .patch("movements/" + id, data)
        .then((obj) => {
          this.$swal.fire(
            "Actualizado",
            "Tu egreso ha sido actualizado con exito",
            "success"
          );
          this.file ? this.updateFile(obj.data.id) : console.log("error file");
          this.loadingSave = false;
          this.$emit("getBuys");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid || this.dataAccValidate ) {
        this.submitStatus = "ERROR";
      } else {
        if (this.buyObj.category_movement != 9) {
          let totalPorcentaje = 0;
          this.buyObj.account_movements_attributes.forEach(
            (e) => (totalPorcentaje += e.percentage)
          );
          if (totalPorcentaje < 100) {
            throw this.$swal.fire(
              "Oops...",
              "Algo ha salido mal al guardar",
              "error"
            );
          }
        }
        this.action == "create"
          ? this.create(this.buyObj)
          : this.update(this.buyObj, this.buy_id);
      }
    },
  },
};
</script>
<style>
textarea:focus,
input:focus {
  outline: none;
}
</style>
