<template>
  <div>
    <transition name="modal">
      <div class="modal-mask h-screen flex justify-center items-center">
        <div class="">
          <div class="modal-container rounded-sm w-50 dark:bg-background-dark">
            <div class="modal-header">
              <slot name="header">
                <div class="w-full flex items-center mt-4">
                  <h1 v-if="action == 'create'" class="text-2xl font-semibold text-grey-darker w-full dark:text-white">
                    Crear nueva cuenta
                  </h1>
                  <h1 v-else class="font-semibold text-grey-darker w-full text-2xl dark:text-white">
                    Actualizara la cuenta {{parent_account.name}}
                  </h1>
                  <a
                    class="text-4xl dark:hover:text-cian-dark"
                    @click.prevent="$emit('close')"
                  >
                    &times;
                  </a>
                </div>
              </slot>
              <div v-if="action=='create'">
                <p>Se esta creando una nueva cuenta relacionada a: </p>
                <p class="uppercase text-lg dark:text-white">{{parent_account.code}}.- {{parent_account.name}}</p>
              </div>
            </div>
            <div class="modal-body">
              <slot name="body">
                <form class="h-full">
                  <div class="w-full my-2">
                    <!-- <treeselect 
                      :disable-branch-nodes="true" 
                      v-model="parent_id" 
                      :options="parents" /> -->
                    <!-- <label
                      class="
                      block
                      md:text-left
                      uppercase
                      tracking-wide
                      text-grey-darker text-xs
                      font-bold
                      mb-1
                      "
                    >Cuentas</label
                      > -->
                    <!-- <v-select
                      v-if="state == 'create'"
                      class="style-chooser custom-border"
                      :options="parents"
                      :reduce="(parent) => parent.id"
                      label="name"
                      v-model="account.parent_id"
                    ></v-select> -->
                    <!-- <v-select
                      v-bind:class="{ disabled: true }"
                      v-if="state == 'update'"
                      class="style-chooser custom-border"
                      :options="parents"
                      :reduce="(parent) => parent.id"
                      label="name"
                      v-model="account.parent_id"
                    ></v-select> -->
                    <div class="w-full py-4">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-2
                          dark:text-white
                        "
                        >Código</label
                      >
                      <input
                        v-if="state == 'update'"
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                          dark:bg-black-dark
                          dark:border-black-dark
                        "
                        v-model="account.value.code"
                      />
                      <input
                        v-if="state == 'create'"
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                          dark:bg-black-dark
                          dark:border-black-dark
                        "
                        v-model="account_code"
                      />
                    </div>
                 <!--    <div class="w-full py-4">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-1
                        "
                        >Nombre</label
                      >

                      <input
                        v-if="state == 'create'"
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                        "
                        v-model="account.value.name"
                      />
                      <input
                        v-if="state == 'update'"
                        disabled
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                        "
                        v-model="account.value.name"
                      />
                    </div> -->
                    <div class="w-full py-4">
                      <label
                        class="
                          block
                          md:text-left
                          uppercase
                          tracking-wide
                          text-grey-darker text-xs
                          font-bold
                          mb-2
                          dark:text-white
                        "
                        >Nombre</label
                      >
                      <input
                        v-if="action == 'create'"
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                          dark:bg-black-dark
                          dark:border-black-dark
                        "
                        v-model="account.value.name"
                      />
                      <input
                        v-if="action == 'update'"
                        type="text"
                        class="
                          appearance-none
                          block
                          w-full
                          bg-grey-lighter
                          text-grey-darker
                          border border-grey-lighter
                          rounded
                          py-3
                          px-4
                          dark:bg-black-dark
                          dark:border-black-dark
                        "
                        v-model="account.value.fantasy_name"
                      />
                    </div>
                  </div>
                </form>
              </slot>
            </div>

            <div class="modal-footer flex justify-center">
              <slot name="footer">
                <div>
                  <button
                    class="
                      bg-black
                      w-full
                      hover:bg-white hover:text-black
                      border
                      hover:border-black
                      text-white
                      font-bold
                      py-2
                      px-4
                      rounded-full
                      text-center
                      mb-4
                      dark:bg-cian-dark
                      dark:border-cian-dark
                    "
                    @click.prevent="submit()"
                  >
                    Guardar Información
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
// import the component
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "@/assets/stylesheets/modal.css";
export default {
  name: "newAccount",
  props: {
    action: String,
    account: Object,
    parents: Array,
    parent_id: Number,
    parent_children: Number,
  },
  data() {
    return {
      user: null,
      state: this.action,
      account_code: "",
      parent_account: {},
    };
  },
  mounted() {
    this.user = this.$auth.user;
    this.getParentAccount();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    getParentAccount() {
      this.axios
        .get("/accounts/" + this.parent_id, {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.parent_account = response.data
          this.account_code = response.data.code + "." + (this.parent_children + 1).toString()
        });
    },
    submit() {
      this.action === "update" ? this.update() : this.create();
    },
    create() {
      this.axios
        .post("/accounts", {
          parent_id: this.parent_id,
          name: this.account.value.name,
          company_id: this.company_id,
          code: this.account_code,
        })
        .then(() => {
          this.$emit("getAccounts");
          this.$emit("close");
        });
    },
    update() {
      this.$swal
        .fire({
          title: "¿Seguro de modificar el plan de Cuenta?",
          text: "El plan de cuenta sera modificado",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, estoy seguro",
        })
        .then((result) => {
          if (result.value) {
            if (this.account._children.length == 0) {
              this.$swal.fire(
                "Modificado",
                "Tu plan de cuenta ha sido modificado",
                "success"
              );
              this.axios
                .patch("accounts/" + this.account.value.id, {
                  parent_id: this.account.parent_id,
                  fantasy_name: this.account.value.fantasy_name,
                  code: this.account.value.code,
                })
                .then(() => {
                  this.$emit("getAccounts");
                  this.$emit("close");
                });
            } else {
              this.$swal.fire(
                "Cancelado",
                "El plan de cuenta no puede ser modificado por que contiene informacion protegida.",
                "error"
              );
            }
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El plan de cuenta no ha sido modificado",
              "error"
            );
          }
        });
    },
  },
};
</script>
<style>
.w-50 {
  width: 30vw;
}
.disabled {
  pointer-events: none;
  color: #bfcbd9;
  cursor: not-allowed;
  background-image: none;
  background-color: #eef1f6;
  border-color: #d1dbe5;
}
</style>
