<template>
  <div class="py-2 mx-4 lg:mx-6">
    <h1 class="flex-auto text-xl font-semibold dark:text-white mb-6">
      Libro de Remuneraciones
    </h1>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar "
          @input="getMovements"
          v-model="query"
        />
      </div>
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          v-model="dateRange"
          type="date"
          range
          format='DD-MM-YYYY'
          placeholder="Fecha"
          v-on:input="getMovements"
          :style="{
            width: '100%',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.65rem',
            'padding-bottom': '0.65rem',
            'border-radius': '0.5rem'
          }"
        ></date-picker>
      </div>
    </div>
    <div v-if="loadingFile" class="w-14">
      <Spinner2 />
    </div>
    <div class="flex gap-2 pb-4">
      <button class="flex items-center gap-2 pr-4 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" @click="exportInExcel()">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
      <button class="flex items-center gap-2 pr-4 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" @click="exportInPdf()">
        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="15px" height="15px" viewBox="0 0 50.000000 50.000000" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0.000000,50.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
          <path d="M70 250 l0 -230 180 0 180 0 0 163 0 163 -68 67 -68 67 -112 0 -112 0 0 -230z m210 145 l0 -65 65 0 65 0 0 -145 0 -145 -160 0 -160 0 0 210 0 210 95 0 95 0 0 -65z m110 -42 c0 -2 -20 -3 -45 -3 l-45 0 0 47 0 47 45 -44 c25 -24 45 -45 45 -47z"/>
          <path d="M222 263 c7 -52 -9 -91 -43 -109 -16 -8 -29 -21 -29 -29 0 -22 37 -18 53 5 19 27 58 39 106 33 33 -5 41 -3 41 10 0 18 -30 30 -52 22 -22 -9 -42 22 -43 63 0 28 -5 38 -19 40 -16 3 -18 -1 -14 -35z m38 -74 c0 -5 -7 -9 -15 -9 -9 0 -12 6 -9 15 6 15 24 11 24 -6z"/>
          </g>
          </svg>
        Exportar
      </button>
    </div>
    <!-- {{balances}} -->
    <BeeGridTable
      :serverSide="true"
      stripe
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :fetchMethod="fetchMethod"
      :data="movements"
      :loading="loading"
    >

      <BeeColumn field="business_name" title="Trabajador" type="text" :width="350"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ row.business_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="rut_company" title="Rut Compañia" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatRut(row.company.rut + '-' + row.company.dv) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="period" title="Fecha" type="text" :width="150"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatDateSII(row.period) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="rut_employee" title="Rut Trabajador" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatRut(row.employee.rut) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="working_hours" title="Horas Trabajadas" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ row.working_hours }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="remuneration_total" title="Remuneración Total" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.total || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="total_legal_discounts" title="Descuentos Legales" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.total_legal_discounts || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="taxes" title="Impuestos" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.taxes_salary || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="advance" title="Anticipos" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.advance || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="liquid_to_payment" title="Sueldo Liquido a Pago" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.liquid_to_payment || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="account_neto_name"
        title="Cuenta"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="250"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow">
            {{ row.account_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="cost_center_neto_name"
        title="Centro de costo"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="250"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow uppercase">
            {{ row.cost_center_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="branch_office_neto_name"
        title="Sucursal"
        type="text"
        :resizable="true"
        :sortable="true"
        :width="250"
      >
        <template slot-scope="{ row }">
          <div class="might-overflow uppercase">
            {{ row.branch_office_neto_name }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="insurance_disability_and_survival_company_SIS" title="Seguro Invalidez y Supervivenvia Empresarial (SIS)" type="text" :width="300"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div>
            {{ formatNumber(row.salary.data_talana['seguro invalidez y supervivencia empresarial (sis)'] || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="insurance_labor_accidents" title="Seguro Accidente del Trabajo" type="text" :width="300"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div >
            {{ formatNumber(row.salary.insurance_labor_accidents || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="insurance_cessantia_employer" title="Seguro Cesantia Empleador" type="text" :width="300"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div >
            {{ formatNumber(row.salary.insurance_cessantia_employer || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="insurance_cesantia_solidarity_background" title="Seguro Cesantia (Fondo Solidario)" type="text" :width="300"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div >
            {{ formatNumber(row.salary.insurance_cesantia_solidarity_background || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="insurance_disability_and_survival_SIS" title="Seguro Invalidez y Supervivenvia (SIS)" type="text" :width="300"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div >
            {{ formatNumber(row.salary.insurance_disability_and_survival_SIS || 0) }}
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="company_cost" title="Costo de Empresa" type="text" :width="200"
        :resizable="true"
        :sortable="true">
        <template slot-scope="{ row }">
          <div >
            {{ formatNumber(row.company_cost || 0) }}
          </div>
        </template>
      </BeeColumn>

    </BeeGridTable>
  </div>
</template>
<script>
import { formatRut, formatNumber, formatSimpleDate, formatDateSII } from '@/utils/formatters'
import DatePicker from 'vue2-datepicker';
export default {
  data() {
    return {
      movements: [],
      filterTotal: null,
      total: 0,
      current_page: 1,
      pageSize: 0,
      pageCount: 0,
      page: 1,
      per_page: 10,
      query: '',
      loading: true,
    }
  },
  components: {
    DatePicker,
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id(){
      this.getMovements();
    },
  },
  created() {
    this.getMovements()
  },
  methods: {
    formatRut,
    formatNumber,
    formatSimpleDate,
    formatDateSII,
    exportInExcel() {
      this.loadingFile = true
      let app = this;
      this.axios.post('/remuneration-book-excel', {
        type_movement: "type_buy",
        company_id: app.company_id,
        category_movement: [5],
        attribute_sorting: app.nameSorting,
        sorting: app.sorting,
        query: app.query,
        from: app.dateRange[0],
        to: app.dateRange[1],
        get_salary: true

      }, {
          responseType: 'blob'
      }).then((response) => {
          const url = URL.createObjectURL(new Blob([response.data], {
              type: 'application/vnd.ms-excel'
          }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', ('Libro-Remuneraciones-Excel-' + this.formatSimpleDate(this.dateRange[0]) + 'al' + this.formatSimpleDate(this.dateRange[1])))
          document.body.appendChild(link)
          link.click()
          app.loadingFile = false
      });
    },
    exportInPdf() {
      let app = this;
      app.loadingFile = true
      this.axios
        .post(
          "/remuneration-book-pdf",
          {
            type_movement: "type_buy",
            company_id: app.company_id,
            category_movement: [5],
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query,
            from: app.dateRange[0],
            to: app.dateRange[1],
            get_salary: true
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/pdf",
            })
          )
          var link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            "Libro-de-Remuneraciones-PDF-" +
              app.formatSimpleDate(app.dateRange[0]) +
              "al" +
              app.formatSimpleDate(app.dateRange[1])
          )
          document.body.appendChild(link)
          link.click()
          app.loadingFile = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    formatRemuneration(value) {
      switch (value) {
        case "salary":
          return "Sueldo";
        case "advance":
          return "Anticipo";
        case "reprocessed_salary":
          return "Sueldo";
        case "settlement":
          return "Finiquito";
        case "historical":
          return "Sueldo";
      }
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if(sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function(value) {
          return value.field;
        });

        _sorting = sortInfos.map(function(value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getMovements();
    },
    getMovements() {
      this.loading = true
      let app = this
      this.axios
        .get("/movements", {
          params: {
            type_movement: "type_buy",
            company_id: app.company_id,
            category_movement: [5],
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query,
            from: app.dateRange[0],
            to: app.dateRange[1],
            get_salary: true
          },
        })
        .then((response) => {
          app.movements = response.data.data
          app.filterTotal = response.data.total_items //total filtered data count
          app.total = response.data.per_page // total data count
          app.page = response.data.current_page // current page offset from server
          app.current_page = response.data.current_page
          app.pageSize = response.data.per_page
          app.pageCount = response.data.total_pages
          app.loading = false
        })
    },
  },
};
</script>
