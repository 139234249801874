var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-2 mx-6",class:{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('h1',{staticClass:"flex-auto text-xl font-semibold"},[_vm._v(" Rli ")]),_c('div',[_c('date-picker',{style:({
          border: '1px solid #e2e8f0',
          width: '100%',
          'background-color': 'white',
          'border-radius': '2px',
          'padding-left': '0.55rem',
          'padding-right': '0.55rem',
          'padding-top': '0.80rem',
          'padding-bottom': '0.80rem',
          'border-radius': '0px'
        }),attrs:{"input-class":"xmx-input","type":"date","range":"","format":"DD-MM-YYYY","placeholder":"Selecciona un rango de Fecha"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('a',{staticClass:"px-8",attrs:{"href":'https://api.cymasuite.com/rli?company_id=' + _vm.company_id + '&from=' + _vm.dateRange[0] + '&to=' + _vm.dateRange[1],"target":"_blank"}},[_vm._v("Descargar Rli")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }