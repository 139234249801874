<template>
  <div class="spinner border-4  animate-spin rounded-full" :class="customClass"></div>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: 'h-7 w-7',
    },
  }
}
</script>
<style scoped>
.spinner {
  border: 0.22rem solid rgba(0, 0, 0, 0.1);
  border-left-color: currentColor;
}
</style>
