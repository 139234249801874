<template>
<div v-if="!loading">
  <div v-if="ordersPendingCount > 0" class="py-3 text-center bg-red-900 lg:px-4">
    <div class="flex items-center p-2 pl-6 leading-none text-indigo-100 bg-red-700 lg:rounded-full lg:inline-flex" role="alert">
      <span class="flex-auto mr-2 font-semibold text-left">Tienes {{ ordersPendingCount }} pagos pendientes de ${{ formatNumber(ordersPendingTotal) }} en Cymasuite</span>
      <span @click.prevent="goTo('MyPlan')"  class="flex px-2 py-1 mr-3 text-xs font-bold uppercase bg-red-500 rounded-full cursor-pointer">Pagar Deuda</span>
      <svg class="w-4 h-4 opacity-75 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
    </div>
  </div>

  <div v-else-if="seeAdvice && user.suggested_plan" class="py-3 text-center bg-cyma-purple lg:px-4">
    <div class="flex items-center p-2 pl-6 leading-none text-purple-100 bg-purple-600 lg:rounded-full lg:inline-flex" role="alert">
      <span class="flex-auto mr-2 font-semibold text-left">Estas a un paso de activar tu plan <span v-if="plan"> {{plan.title}}  </span> en Cymasuite</span>
      <span v-if="isOrder" @click.prevent="goTo('PaymentPlan', { id: order.id })"  class="flex px-2 py-1 mr-3 text-xs font-bold uppercase bg-indigo-500 rounded-full cursor-pointer">Pagar plan</span>
      <div v-else>
        <span v-if="!loadingOrder" @click.prevent="goToPay()"  class="flex px-2 py-1 mr-3 text-xs font-bold uppercase bg-indigo-500 rounded-full cursor-pointer">Pagar plan</span>
        <Spinner2 v-else customClass="h-6 w-6"/>
      </div>
      <svg class="w-4 h-4 opacity-75 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
    </div>
  </div>
</div>
</template>
<script>
import { formatNumber, formatToDashedDate } from '@/utils/formatters'
import routerMixin from '@/mixins/routerMixin'
import Spinner2 from '../Spinner2.vue'

export default {
  components: { Spinner2},
  mixins: [routerMixin],
  data(){
    return{
      loading: true,
      user: this.$auth.user(),
      marketplace: this.$store.state.marketplace,
      plans: [
        {
          id: 1,
          title: "Emprendedor",
          price: 2.5,
          banks: 1,
          meet: 0,
          employees: 0,
          f29: true,
          f22: false,
          owners: 0,
          app_employee: false,
          api: false,
          value: 'entrepreneur'
        },
        {
          id: 2,
          title: "PyMe",
          price: 4,
          banks: 1,
          meet: 1,
          employees: 3,
          f29: true,
          f22: false,
          owners: 0,
          app_employee: true,
          api: false,
          value: 'small',
        },
        {
          id: 3,
          title: "Mediana",
          price: 6,
          banks: 1,
          meet: 1,
          employees: 8,
          f29: true,
          f22: true,
          owners: 0,
          app_employee: true,
          api: false,
          value: 'medium',
        },
        {
          id: 4,
          title: "En Crecimiento",
          price: 8,
          banks: 2,
          meet: 2,
          employees: 12,
          f29: true,
          f22: true,
          owners: 0,
          app_employee: true,
          api: false,
          value: 'grown'
        },
        {
          id: 5,
          title: "Gran Empresa",
          price: 12,
          banks: 3,
          meet: 4,
          employees: 20,
          f29: true,
          f22: true,
          owners: 3,
          app_employee: true,
          api: true,
          value: 'big'
        },
        {
          id: 6,
          title: "Corporación",
          price: 20,
          banks: 4,
          meet: 4,
          employees: 35,
          f29: true,
          f22: true,
          owners: 5,
          app_employee: true,
          api: true,
          value: 'corporation'
        },
      ],
      price: 0,
      todayUf: 0,
      clpValue: 0,
      ivaValue: 0,
      totalAmount: 0,
      methodPay: "webpay",
      plan: {
        title: ''
      },
      order: {},
      isOrder: false,
      seeAdvice: false,
      // Pending
      ordersPending: [],
      ordersPendingCount: 0,
      ordersPendingTotal: null,
      isPendingOrder: false,
      subscriptions: null,
      loadingOrder: false
    }
  },
  created() {
    this.loading = true
    Promise.allSettled([
      this.setPlan(),
      this.getTodayUf(),
      this.findOrder(),
      this.pendingOrder(),
      this.getSubscriptions(),
    ]).then(() => this.loading = false)
  },
  mounted(){
  },
  computed: {
    validatedOrder() {
      return this.$store.state.validatedOrder
    },
  },
  methods: {
    formatNumber,
    formatToDashedDate,
    setPlan(){
      this.plan = this.plans.find((e) => e.value == this.$auth.user().suggested_plan)
    },
    calculateNewPrice(plan) {
      this.clpValue = this.formatClp(plan.price, this.todayUf);
      this.ivaValue = this.getIva(this.clpValue);
      this.totalAmount = this.getTotalAmount(this.clpValue, this.ivaValue);
    },
    getIva(value) {
      let response = value * 0.19;
      return response;
    },
    getTotalAmount(clpValue, ivaValue) {
      let response = clpValue + ivaValue;
      return response;
    },
    formatClp(value, todayUf) {
      let response = value * todayUf;
      return response;
    },
    getTodayUf() {
      let cur = this;
      const date = new Date();
      const today_date = cur.formatToDashedDate(date);
      return this.axios
        .get("https://mindicador.cl/api/uf/" + today_date, {
          transformRequest: (data, headers) => {
            delete headers.common;
            return data;
          },
        })
        .then((response) => {
          cur.todayUf = response.data.serie[0]["valor"];
        });
    },
    goToPay() {
      this.loadingOrder = true
      let plan = this.plan
      this.calculateNewPrice(plan)
      const cur = this;
      const accessToken = cur.$cookie.get("access-token");
      this.axios
        .post(cur.marketplace + "/api/v1/orders", {
          user_id: cur.user.id,
          total_bank: plan.banks,
          // total_company: cur.totalCompany,
          total_employee: plan.employees,
          user_name: cur.user.name,
          plan_name: plan.title,
          transaction_date: new Date(),
          total_amount: cur.totalAmount,
          method_pay: cur.methodPay,
          total_movement: cur.totalMovements,
          subtotal: cur.clpValue,
          iva: cur.ivaValue,
          price_uf: plan.price,
          headers: {
            authorization: "Bearer " + accessToken,
          },
        })
        .then((response) => {
          this.findOrder()
          cur.goTo("PaymentPlan", { id: response.data.id });
          this.loadingOrder = false
        })
        .catch((error)=>{
          this.error = error.response.data.transaction_date[0]
          this.loadingOrder = false
        })
        ;
    },
    findOrder() {
      let cur = this
      const accessToken = cur.$cookie.get("access-token");
      this.axios
        .get(cur.marketplace + "/api/v1/find_order",  {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            user_id: cur.user.id,
          },
        })
        .then((response) => {
          if (response.data.orders_approved_count <= 1){
            this.seeAdvice = true
            if (response.data.orders_approved_count == 1){
              this.isOrder = true
              this.order  = response.data.orders_approved[0]
            }
          } else {
            this.seeAdvice = false
          }
          if (response.data.orders_count > 2){
            this.seeAdvice = false
          }
        })
        .catch((error)=>{
          this.error = error.response.data.transaction_date[0]
        })
        ;
    },
    pendingOrder() {
      let cur = this
      const accessToken = cur.$cookie.get("access-token");
      this.axios
        .get(cur.marketplace + "/api/v1/pending_order",  {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            user_id: cur.user.id,
          },
        })
        .then((response) => {
          this.ordersPending   = response.data.data
          this.ordersPendingCount     = response.data.orders_count
          this.ordersPendingTotal     = response.data.orders_total
          this.isPendingOrder   = response.data.orders_pending
        })
        .catch((error)=>{
          this.error = error.response.data.transaction_date[0]
        })
        ;
    },
    getSubscriptions() {
      let cur = this
      const accessToken = cur.$cookie.get("access-token");
      this.axios
        .get(cur.marketplace + "/api/v1/subscriptions",  {
          headers: {
            authorization: "Bearer " + accessToken,
          },
          params: {
            user_id: cur.user.id,
          },
        })
        .then((response) => {
          this.subscriptions = response.data
        })
        .catch((error)=>{
          this.error = error.response.data.transaction_date[0]
        })
        ;
    },
  }
}
</script>
