<template>
  <div class="py-2 mx-4 lg:mx-6" :class="{ widthVal: this.booleanSlider }">
    <div class="flex gap-2.5 md:gap-4 flex-wrap md:flex-nowrap">
      <div class="w-full p-4 bg-white dark:bg-black-dark rounded-lg">
        <h3 class="text-base font-semibold items-center dark:text-white flex-auto flex gap-4 mb-2">
          Ingresos
        </h3>

        <div class="flex justify-between gap-2">
          <div>
            <div class="text-lg font-semibold items-center dark:text-white flex-auto flex gap-4 whitespace-nowrap">
              {{ percentage_of_reconciled + percentage_of_balance + percentage_of_archived + percentage_of_transfer }} % Conciliado
            </div>
          </div>
          <div>
            <div class="text-lg font-semibold items-center dark:text-white flex-auto flex gap-4 whitespace-nowrap">
              $ {{ formatNumber(balance_total) }}
              <div v-if="balance_available > 0">
                <Icon icon="heroicons:chevron-up" class="w-6 h-6 text-cyma-green-icon" />
              </div>
              <div v-else>
                <Icon icon="heroicons:chevron-down" class="w-6 h-6 text-cyma-red-icon" />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="flex">
            <div :style="{ 'min-width': percentage_of_reconciled + '%' }" class="text-xs font-semibold inline-block text-amber-600 dark:text-white" v-if="percentage_of_reconciled > 0"></div>
            <div :style="{ 'min-width': percentage_of_balance + '%' }" class="text-xs font-semibold inline-block text-amber-600 dark:text-white" v-if="percentage_of_balance > 0"> </div>
            <div :style="{ 'min-width': percentage_of_archived + '%' }" class="text-xs font-semibold inline-block text-amber-600 dark:text-white" v-if="percentage_of_archived > 0"></div>
            <div :style="{ 'min-width': percentage_of_pending + '%' }" class="text-xs font-semibold inline-block text-amber-600 dark:text-white" v-if="percentage_of_pending > 0"></div>
            <div :style="{ 'min-width': percentage_of_transfer + '%' }" class="text-xs font-semibold inline-block text-amber-600 dark:text-white" v-if="percentage_of_transfer > 0"></div>
          </div>
          <div class="h-2 mb-2 text-xs flex rounded bg-amber-200">
            <div :style="{ 'width': percentage_of_reconciled + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-400 hover:bg-green-500 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Conciliado ' + formatNumber(total_reconciled) + ' (' +  percentage_of_reconciled + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_balance + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500 hover:bg-green-600 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Balance ' + formatNumber(total_balance) + ' (' + percentage_of_balance + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_transfer + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500 hover:bg-green-600 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Transferencia Entre cuentas ' + percentage_of_transfer + ' %' " />
            </div>
            <div :style="{ 'width': percentage_of_archived + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-600 hover:bg-green-600 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Archivado ' + formatNumber(total_archived) +  ' (' + percentage_of_archived + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_pending + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-200 hover:bg-gray-400 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Pendiente ' + formatNumber(total_pending) + ' (' + percentage_of_pending + ' %)' " />
            </div>
          </div>
        </div>

        <div class="flex justify-between gap-2">
          <div>
            <div class="text-xs text-gray-400 flex-auto">
              {{ formatNumber(count_reconciled) }}
              <span v-if="count_reconciled == 1">depósito</span>
              <span v-else>depósitos</span>
            </div>
          </div>
          <div>
            <div class="text-xs text-gray-400 flex-auto">
              {{ formatNumber(count_income) }}
              <span v-if="count_income == 1">depósito</span>
              <span v-else>depósitos</span>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full p-4 bg-white dark:bg-black-dark rounded-lg">
        <h3 class="text-base font-semibold items-center dark:text-white flex-auto flex gap-4 mb-2">
          Egresos
        </h3>

        <div class="flex justify-between gap-2">
          <div>
            <div class="text-lg font-semibold items-center dark:text-white flex-auto flex gap-4 whitespace-nowrap">
              {{ percentage_of_reconciled_expense + percentage_of_balance_expense + percentage_of_archived_expense }} % Conciliado
            </div>
          </div>
          <div>
            <div class="text-lg font-semibold items-center dark:text-white flex-auto flex gap-4 whitespace-nowrap">
              $ {{ formatNumber(balance_total_expense) }}
              <div v-if="balance_available > 0">
                <Icon icon="heroicons:chevron-down" class="w-6 h-6 text-cyma-red-icon" />
              </div>
              <div v-else>
                <Icon icon="heroicons:chevron-down" class="w-6 h-6 text-cyma-red-icon" />
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <div class="h-2 mb-2 text-xs flex rounded bg-amber-200">
            <div :style="{ 'width': percentage_of_reconciled_expense + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-400 hover:bg-red-500  hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Gastos conciliados $ ' + formatNumber(total_reconciled_expense) + ' (' + percentage_of_reconciled_expense + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_balance_expense + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-red-500 hover:bg-red-600 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Gasto de saldo $ ' + formatNumber(total_balance_expense) + ' ('+ percentage_of_balance_expense + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_archived_expense + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-yellow-100 hover:bg-yellow-300 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Gasto archivado $ ' + formatNumber(total_archived_expense) + ' (' + percentage_of_archived_expense + ' %)'" />
            </div>
            <div :style="{ 'width': percentage_of_pending_expense + '%' }" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-gray-200 hover:bg-gray-400 hover:translate-y-0.5 transform transition-all duration-300 group">
              <TooltipInfo :text="'Gasto pendiente $ ' + formatNumber(total_pending_expense) + ' (' + percentage_of_pending_expense + ' %)'" />
            </div>
          </div>
        </div>

        <div class="flex justify-between gap-2">
          <div>
            <div class="text-xs text-gray-400 flex-auto">
              {{ formatNumber(count_reconciled_expense) }}
              <span v-if="count_reconciled_expense == 1">depósito</span>
              <span v-else>depósitos</span>
            </div>
          </div>
          <div>
            <div class="text-xs text-gray-400 flex-auto">
              {{ formatNumber(count_expense) }}
              <span v-if="count_income == 1">depósito</span>
              <span v-else>depósitos</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex sm:mb-4 items-center my-1.5">
      <h2
        class="inline-flex text-lg text-gray-400 text-left rounded-lg"
      >
        Saldo disponible ${{ formatNumber(balance_available) }}
      </h2>
    </div>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mt-5">
      <div class="text-md font-semibold text-gray-500">
        <div class="flex flex-wrap flex-col sm:flex-row sm:gap-2 gap-2 mb-6">
          <div class="filter">
            <input
              type="text"
              class="input-text"
              v-model="query"
              placeholder="Buscar movimientos"
            />
          </div>
          <div class="filter">
            <date-picker
              input-class="xmx-input"
              v-model="dateRange"
              type="date"
              range
              format='DD-MM-YYYY'
              placeholder="Fecha"
              :style="{
                width: '100%',
                'padding-left': '0.55rem',
                'padding-right': '0.55rem',
                'padding-top': '0.65rem',
                'padding-bottom': '0.65rem',
                'border-radius': '0.5rem'
              }"
            ></date-picker>
          </div>
          <div class="filter">
            <v-select
              class="style-chooser custom-border"
              v-model="bank_bill"
              :options="banks"
              :reduce="(state) => state.id"
              label="name"
              placeholder="Cuenta Bancaria"
            >
              <template slot="selected-option" slot-scope="option">
                <div>
                  <p v-if="option.id == '' || option.id == null">{{option.name}}</p>
                  <p v-else class="">
                    {{option.bank_name}} : {{ option.name }} - <span class="text-xs opacity-80">{{option.number}}</span>
                  </p>
                </div>
              </template>
              <template v-slot:option="option">
                <div class="flex items-center gap-2 w-full">
                  <p v-if="option.id == ''">{{option.name}}</p>
                  <p v-else class="">
                    {{option.bank_name}} : {{ option.name }} - {{option.number}}
                  </p>
                </div>
              </template>
            </v-select>
          </div>
          <div class="sm:ml-4 flex justify-between flex-wrap">
            <div>
              <div class="text-xs font-light mb-1">Ingresos</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionSales(movement_sales)"
                  @click.prevent="getTransactions('sales')"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div>
              <div class="text-xs font-light mb-1">Egresos</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionBuys(movement_buys)"
                  @click.prevent="getTransactions('buys')"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div>
              <div class="text-xs font-light mb-1">Conciliados</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionReconsile(reconsile)"
                  @click.prevent="getTransactions('reconsile')"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div>
            <div class="text-xs font-light mb-1">Sin Conciliar</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionWithoutReconsile(without_reconciling)"
                  @click.prevent="getTransactions('without_reconciling')"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div>
              <div class="text-xs font-light mb-1">Recomendado</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionRecommended(only_recommended)"
                  @click.prevent="getTransactions('only_recommended')"
                />
                <span><slot></slot></span>
              </label>
            </div>
            <div>
              <div class="text-xs font-light mb-1">Archivado</div>
              <label class="switch label_switch">
                <input
                  class="check"
                  type="checkbox"
                  :checked="checkedFunctionArchived(only_archived)"
                  @click.prevent="getTransactions('only_archived')"
                />
                <span><slot></slot></span>
              </label>
            </div>
          </div>
          <div
            v-if="dateRange[0] != null || query != '' || bank_bill != null || reconsile != false || without_reconciling != false || only_recommended != false || only_archived != false "
            class="fade-in flex items-center"
          >
            <button @click="cleanFilters()" class="flex items-center focus:outline-none text-cian-400 hover:text-cian-500 transition-colors duration-200 dark:text-gray-300 dark:hover:text-white">
              Limpiar Filtros
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 pb-4">
      <Spinner v-if="loadingFile"></Spinner>
      <button v-else class="flex items-center gap-2 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" @click="exportExcel()">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
    </div>
    <BeeGridTable
      :serverSide="true"
      stripe
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="vendors"
      :loading="loading"
    >
      <BeeColumn field="date" title="Fecha" type="text" :width="120" sortable="true">
        <template slot-scope="{ row }">
          <p>{{ formatSimpleDate(row.date) }}</p>
        </template>
      </BeeColumn>

      <BeeColumn field="bank_bill.name" title="Cuenta" type="text" :width="200">
        <template slot-scope="{ row }">
          <div class="flex items-center gap-4">
            <div v-if="row.bank_bill.bank.institution_country === 'Ventipay'">
              <svg class="w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55 55">
                <defs>
                  <clipPath id="a">
                    <path d="M0 0h210v55H0z"/>
                  </clipPath>
                </defs>
                <g clip-path="url(#a)" data-name="Group 120">
                  <path fill="#4c64f4" d="M26.379 51.482a4.841 4.841 0 0 1-4.646-3.332L5.465 9.916A4.605 4.605 0 0 1 5 7.992 4.03 4.03 0 0 1 9.12 4a4.282 4.282 0 0 1 4.18 2.925l13.413 33.53L40.326 6.661A4.198 4.198 0 0 1 44.24 4a3.962 3.962 0 0 1 4.054 3.927 4.766 4.766 0 0 1-.4 1.719L31.557 48.15a4.864 4.864 0 0 1-4.715 3.332h-.463Z" data-name="Union 1"/>
                </g>
              </svg>
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Mercadopago'">
              <IconMercadoPago class="w-8" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco Santander'">
              <IconBancoSantander class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco de Chile'">
              <IconBancoDeChile class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco Estado'">
              <IconBancoEstado class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco BCI'">
              <IconBancoBCI class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco Itaú'">
              <IconBancoItau class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco Security'">
              <IconBancoSecurity class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco BICE'">
              <IconBancoBICE class="w-5 h-10" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Banco Scotiabank'">
              <IconBancoScotiabank class="w-5 h-5" />
            </div>
            <div v-if="row.bank_bill.bank.institution_country === 'Flow'">
              <IconFlow class="w-6 h-auto" />
            </div>
            <div>
              <p>{{ row.bank_bill.name }}</p>
              <p class="text-sm text-gray-400">{{ row.bank_bill.number }}</p>
            </div>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="transfer_account_holder_name" title="Emisor" type="text" :width="300" sortable="true">
        <template slot-scope="{ row }">
          <div>
            <div v-if="row && row.transfer_account_holder_name">
              <p>{{ row.transfer_account_holder_name }}</p>
              <p class="text-sm text-gray-400" v-if="row && row.transfer_account_holder_id">{{ formatRut(row.transfer_account_holder_id) }}</p>
            </div>
            <div v-else-if="row && row.recipient_account.hasOwnProperty('holder_name')">
              <p>{{ row.recipient_account.holder_name }}</p>
              <p class="text-sm text-gray-400">{{ formatRut(row.recipient_account.holder_id) }}</p>
            </div>
            <div v-else>
            </div>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="description" title="Descripción" type="text" :width="320" sortable="true">
        <template slot-scope="{ row }">
          <p class="text-gray-400 text-sm">{{ row.description }}</p>
        </template>
      </BeeColumn>

      <BeeColumn field="amount" title="Ingresos" type="text" :width="140" sortable="true">
        <template slot-scope="{ row }">
          <p v-if="row.amount > 0" class="flex justify-between items-center">
            {{ formatNumber(row.amount) }}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-cyma-green-title">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
            </svg>
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="id" title="Egresos" type="text" :width="140" sortable="true">
        <template slot-scope="{ row }">
          <p
            v-if="row.amount < 0"
            class="flex justify-between items-center"
          >
            {{ formatNumber(row.amount) }}
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-cyma-red-title">
              <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
            </svg>
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="editar" title="Conciliación" type="text" :width="300">
        <template slot-scope="{ row }">
          <div v-if="row.bank_reconciliations.length > 0">
            <p
              class="text-xs cursor-pointer"
              @click="showModalMovementsReconsile(row.id)"
            >
              <span
                v-if="row.bank_reconciliations.length === 1"
              >
                {{ row.bank_reconciliations.length }} Movimiento
              </span>
              <span
                v-else-if="row.bank_reconciliations.length > 1"
              >
                {{ row.bank_reconciliations.length }} Movimientos
              </span>
            </p>
            <p class="text-xs text-gray-400">
              {{ setNameState(row.state) }} <span v-if="row.state === 'balance'">$ {{ formatNumber(Math.abs(row.amount) - row.balance) }}</span>
            </p>
            <div v-if="row.state != 'transfer'">
              <a
                v-on:click="editFunction(row.id)"
                v-if="validateState(row.state)"
                class="text-xs flex gap-2 items-center dark:text-white dark:hover:text-cian-dark"
              >
                Conciliar
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                </svg>
              </a>
            </div>
          </div>
          <!-- Recomended -->
          <div class="py-1 flex flex-col relative" v-else-if="row.recommended_movement.length === 1">
            <div
              v-for="item in row.recommended_movement"
              :key="item"
              >
              <div class="flex gap-2 items-center py-1">
                <div>
                  <span class="text-xs font-bold block truncate">
                    {{ item.business_name }}
                  </span>
                  <span class="text-xs block text-gray-400">$ {{ formatNumber(item.total) }} - {{ formatSimpleDate(item.period) }} - {{ item.folio }}</span>
                  <div v-if="!item.pending_sii && item.classified" class="flex gap-4">
                    <div>
                      <a
                        v-on:click="applyConsiliation(row.id, [item.id, item.total])"
                        v-if="validateState(row.state)"
                        class="text-xs flex gap-1 items-center"
                      >
                        Aplicar
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                        </svg>
                      </a>
                    </div>
                    <div>
                      <a
                        v-on:click="editFunction(row.id)"
                        v-if="validateState(row.state)"
                        class="text-xs flex gap-1 items-center"
                      >
                        Buscar
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div v-else>
                    <p v-if="item.pending_sii" class="text-red-400 text-xs font-bold" >Pendiente Acuse de Recibo</p>
                    <p v-else-if="!item.classified" class="text-xs font-bold text-gray-500" >Movimiento sin categorizar</p>
                    <div>
                      <a
                        v-on:click="editFunction(row.id)"
                        v-if="validateState(row.state)"
                        class="text-xs flex gap-1 items-center"
                      >
                        Buscar
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="py-1 flex flex-col relative" v-else-if="row.recommended_movement.length > 1">
            <SelectCustom  :options="row.recommended_movement" :selected="row.selected_recommended" @updateRecommended="row.selected_recommended = $event">
            </SelectCustom>
            <div v-if="!row.selected_recommended.pending_sii && row.selected_recommended.classified" class="flex gap-4">
              <div>
                <a
                  v-on:click="applyConsiliation(row.id, [row.selected_recommended.id, row.selected_recommended.total])"
                  v-if="validateState(row.state)"
                  class="text-xs flex gap-1 items-center"
                >
                  Aplicar
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
                  </svg>
                </a>
              </div>
              <div>
                <a
                  v-on:click="editFunction(row.id)"
                  v-if="validateState(row.state)"
                  class="text-xs flex gap-1 items-center"
                >
                  Buscar
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </a>
              </div>
            </div>
            <div v-else>
              <p v-if="row.selected_recommended.pending_sii" class="text-red-400 text-xs font-bold" >Pendiente Acuse de Recibo</p>
              <p v-else-if="!row.selected_recommended.classified" class="text-xs font-bold text-gray-500" >Movimiento sin categorizar</p>
              <div>
                <a
                  v-on:click="editFunction(row.id)"
                  v-if="validateState(row.state)"
                  class="text-xs flex gap-1 items-center"
                >
                  Buscar
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div v-else class="text-gray-500 text-xs">
            {{ setNameState(row.state) }} <span v-if="row.state === 'balance'">$ {{ formatNumber(Math.abs(row.amount) - row.balance) }}</span>
            <a
              v-on:click="editFunction(row.id)"
              v-if="validateState(row.state)"
              class="text-xs flex gap-2 items-center dark:text-white dark:hover:text-cian-dark"
            >
              Conciliar
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z" />
              </svg>
            </a>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="status" title="Estado" type="text" :width="130">
        <template slot-scope="{ row }">
          <span
            class="text-xs font-semibold px-2 py-0.5 rounded"
            :class="statusColor(row.state)"
          >
            {{ formatStatus(row.state) }}
          </span>
        </template>
      </BeeColumn>

      <BeeColumn field="traspaso" title="Traspaso" type="text" :width="140">
        <template slot-scope="{ row }">
          <div>
            <a
              v-on:click="setTransfer(row.id)"
              class="text-xs flex gap-2 items-center dark:text-white pt-1 dark:hover:text-cian-dark"
              v-if="(row.state != 'reconciled') && (row.state != 'balance') && (row.state != 'archived') && row.state != 'transfer'"
            >
              Traspaso
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.4" stroke="#C2BFC4" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                </svg>
              </div>
            </a>
            <a
              class="text-xs flex gap-2 items-center dark:text-white pt-1 dark:hover:text-cian-dark"
              v-else-if="row.state === 'transfer'"
              @click="showModalMovementsReconsile(row.id)"
            >
              Entre cuentas
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.4" stroke="#C2BFC4" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                </svg>
              </div>
            </a>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="state" title="Archivar" type="text" :width="140">
        <template slot-scope="{ row }">
          <div
            v-if="validateArchived(row.state)"
          >
            <a
              v-on:click="setArchived(row.id, 'archived')"
              class="text-xs flex gap-2 items-center"
            >
              Archivar
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
              </svg>
            </a>
          </div>
          <div v-else-if="row.state == 'archived'">
            <a
              v-on:click="setArchived(row.id, null)"
              class="text-xs flex gap-2 items-center"
            >
              Desarchivar
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z" />
              </svg>
            </a>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn field="comentary" title="Comentarios" type="text" :width="300">
        <template slot-scope="{row }">
          <div>
            <p class="text-xs">
              {{ limitText( row.first_comentary || '') }}
            </p>
            <a
              @click.prevent="openComentaries(row.id)"
              class="text-xs flex gap-2 items-center dark:text-white pt-1 dark:hover:text-cian-dark"
            >
              Comentar
              <div class="flex gap-1 items-center">
                <span
                  v-if="row.count_comentary > 0"
                >
                  {{ row.count_comentary }}
                </span>
                <svg v-if="row.count_comentary > 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

                <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-4 h-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 011.037-.443 48.282 48.282 0 005.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>

              </div>
            </a>
          </div>
        </template>
      </BeeColumn>

      <BeeColumn
        field="eliminar"
        title="Opciones"
        type="text"
        class="text-center"
        :width="120"
      >
        <template slot-scope="{ row }">
          <div class="flex items-center">
            <div v-if="row.check_transaction == 'reject_transaction'">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#C2BFC4" class="w-5 h-5 text-cyma-red-icon">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
              </svg>
            </div>
            <a v-on:click="deleteFunction(row.id)" class="pl-4">
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
    <Comentaries
      v-if="showComentaries"
      @close="showComentaries = false"
      @getTransactions="reload"
      :transaction_id = transaction_id
    >
    </Comentaries>
    <newMovement
      v-if="showMovement"
      @getBuys="getTransactions"
      @close="closeFunction"
      :buy_id="buy_id"
    ></newMovement>
    <editTransaction
      v-if="showModal"
      :showModal="showModal"
      @close="showModal = false"
      @getTransactions="reload"
      :action="action"
      :id="id"
    ></editTransaction>
    <movementsReconsile
      v-if="showMovementsReconsile"
      @close="showMovementsReconsile = false"
      @getTransactions="reload"
      :transaction_id="id"
    ></movementsReconsile>
  </div>
</template>
<script>
import { formatRut, formatNumber, formatSimpleDate } from '@/utils/formatters'
import Spinner from "@/components/Spinner2.vue";
import Comentaries from "./Comentaries"
import DatePicker from "vue2-datepicker";
import editTransaction from "./editTransaction";
import newMovement from "./showMovement";
import movementsReconsile from "./movementsReconsile.vue"
import "@/assets/stylesheets/switch.scss";
import Times from '@/components/Icons/Times.vue';
import IconMercadoPago from '@/components/Icons/MercadoPago.vue';
import IconBancoSantander from '@/components/Icons/BancoSantander.vue';
import IconBancoDeChile from '@/components/Icons/BancoDeChile.vue';
import IconBancoEstado from '@/components/Icons/BancoEstado.vue';
import IconBancoBCI from '@/components/Icons/BancoBCI.vue';
import IconBancoItau from '@/components/Icons/BancoItau.vue';
import IconBancoSecurity from '@/components/Icons/BancoSecurity.vue';
import IconBancoBICE from '@/components/Icons/BancoBICE.vue';
import IconBancoScotiabank from '@/components/Icons/BancoScotiabank.vue';
import IconFlow from '@/components/Icons/Flow.vue';
import TooltipInfo from './TooltipInfo.vue';
import SelectCustom from '@/components/inputs/SelectCustom.vue'

import { Icon } from '@iconify/vue2'
// import Add from "./add";
export default {
  name: "transaction",
  props: {},
  data() {
    return {
      // debounce
      debounceQuery: null,
      loadingFile: false,
      showComentaries: false,
      showModal: false,
      showMovement: false,
      showMovementsReconsile: false,
      id: null,
      buy_id: null,
      action: null,
      without_account: 0,
      pageSize: 0,
      pageCount: 0,
      filterTotal: 0,
      total: 0,
      page: 1,
      per_page: 10,
      columns: [],
      vendors: [],
      loading: true,
      query_sales: null,
      query_buys: null,
      banks: [],
      balance_available: 0,
      balance_current: 0,
      balance_limit: 0,
      balance_total: 0,
      type_movement: [],
      query_reconsile: null,
      query_without_reconciling: null,
      query_only_recommended: null,
      query_only_archived: null,
      percentage_of_reconciled: 0,
      percentage_of_balance: 0,
      percentage_of_archived: 0,
      percentage_of_pending: 0,
      percentage_of_transfer: 0,
      total_reconciled: 0,
      count_reconciled: 0,
      total_balance: 0,
      total_archived: 0,
      total_pending: 0,
      balance_total_expense: 0,
      percentage_of_reconciled_expense: 0,
      percentage_of_balance_expense: 0,
      percentage_of_archived_expense: 0,
      percentage_of_pending_expense: 0,
      percentage_of_transfer_expense: 0,
      total_reconciled_expense: 0,
      count_reconciled_expense: 0,
      count_income: 0,
      count_expense: 0,
      circumference: 50 * 2 * Math.PI,
      total_balance_expense: 0,
      total_archived_expense: 0,
      total_pending_expense: 0,
      activeGraph: 'sale',
    };
  },
  components: {
    // Add,
    DatePicker,
    editTransaction,
    newMovement,
    movementsReconsile,
    Times,
    TooltipInfo,
    Comentaries,
    SelectCustom,
    Spinner,
    IconMercadoPago,
    IconBancoSantander,
    IconBancoDeChile,
    IconBancoEstado,
    IconBancoBCI,
    IconBancoItau,
    IconBancoSecurity,
    IconBancoBICE,
    IconBancoScotiabank,
    IconFlow,
    Icon,
  },
  computed: {
    movement_sales: {
      get(){
        return this.$store.state.transaction_movement_sales;
      },
      set(newValue){
        this.$store.commit('addTransactionMovementSales', newValue);
      },
    },
    movement_buys: {
      get(){
        return this.$store.state.transaction_movement_buys;
      },
      set(newValue){
        this.$store.commit('addTransactionMovementBuys', newValue);
      },
    },
    reconsile: {
      get(){
        return this.$store.state.transaction_reconsile;
      },
      set(newValue){
        this.$store.commit('addTransactionReconsile', newValue);
      },
    },
    without_reconciling: {
      get(){
        return this.$store.state.transaction_without_reconciling;
      },
      set(newValue){
        this.$store.commit('addTransactionWithoutReconciling', newValue);
      },
    },
    only_recommended: {
      get(){
        return this.$store.state.transaction_only_recommended;
      },
      set(newValue){
        this.$store.commit('addTransactionOnlyRecommended', newValue);
      },
    },
    only_archived: {
      get(){
        return this.$store.state.transaction_only_archived;
      },
      set(newValue){
        this.$store.commit('addTransactionOnlyArchived', newValue);
      },
    },
    bank_bill: {
      get(){
        return this.$store.state.transaction_bank_bill;
      },
      set(newValue){
        this.$store.commit('addTransactionBankBill', newValue);
      },
    },
    query: {
      get(){
        return this.$store.state.transaction_query;
      },
      set(newValue){
        this.$store.commit('addTransactionQuery', newValue);
      }
    },
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    dateRange(){
      this.getTransactions();
    },
    query(){
      this.getTransactions();
    },
    company_id() {
      this.reload();
    },
    bank_bill(){
      this.getTransactions();
    }
  },
  created(){
    if(this.$route.query?.query){
      this.$store.commit('addTransactionQuery', this.$route.query?.query)
    }
  },
  mounted() {
    this.getBankBills();
    this.getTransactions();
  },
  methods: {
    formatRut,
    formatNumber,
    formatSimpleDate,
    // set dynamic bg-color badge
    statusColor(status) {
      // pending archived reconciled balance transfer
      return {
        'bg-purple-100 text-purple-800 dark:bg-purple-200 dark:text-purple-900': status === 'pending',
        'bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-900': status === 'archived',
        'bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-900': status === 'reconciled',
        'bg-indigo-100 text-indigo-800 dark:bg-indigo-200 dark:text-indigo-900': status === 'balance',
        'bg-yellow-100 text-yellow-800 dark:bg-yellow-200 dark:text-yellow-900': status === 'transfer',
      }
    },
    formatStatus(state) {
      let status = {
        pending : 'Pendiente',
        archived : 'Archivado',
        reconciled : 'Conciliado',
        balance : 'Saldo',
        transfer : 'Transferencia',
      };
      return status[state];
    },
    cleanFilters(){
      this.query = '';
      this.dateRange = [null, null];
      this.bank_bill = null;
      this.reconsile = false;
      this.without_reconciling = false;
      this.only_recommended = false;
      this.only_archived = false;
    },
    exportExcel(){
      let app = this;
      app.loadingFile = true
      this.axios
        .post(
          "/transactions-excel",
          {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            query: app.query,
            bank_bill_id: app.bank_bill,
            movement_sales: app.query_sales,
            movement_buys: app.query_buys,
            reconsile: app.query_reconsile,
            without_reconciling: app.query_without_reconciling,
            only_recommended: app.query_only_recommended,
            only_archived: app.query_only_archived,
          },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: "application/vnd.ms-excel",
            })
          )
          const link = document.createElement("a")
          link.href = url
          link.setAttribute(
            "download",
            "Cartola-Banco-Excel-" +
              app.formatSimpleDate(app.dateRange[0]) +
              "al" +
              app.formatSimpleDate(app.dateRange[1])
          )
          document.body.appendChild(link)
          link.click()
          app.loadingFile = false
        })
    },
    limitText(text){
      let count = 80;
      let result = text.slice(0, count) + (text.length > count ? " ..." : "");
      return result
    },
    openComentaries(id){
      this.transaction_id = id
      this.showComentaries = true
    },
    changeGraph(graph) {
      this.activeGraph = graph;
    },
    applyConsiliation(transaction_id, movement) {
      // update transactions
      let app = this;
      app.loading = true;
      this.axios
        .patch("transactions/" + transaction_id, {
          movementsAmountArray: [movement]
        })
        .then(() => {
          app.getTransactions();
          app.loading = false;
        }
      );
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if(sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function(value) {
          return value.field;
        });

        _sorting = sortInfos.map(function(value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getTransactions();
    },
    checkedFunctionBuys(status) {
      if (!status) return false;
      return status
    },
    checkedFunctionSales(status) {
      if (!status) return false;
      return status
    },
    checkedFunctionReconsile(status) {
      if (!status) return false;
      return status
    },
    checkedFunctionWithoutReconsile(status) {
      if (!status) return false;
      return status
    },
    checkedFunctionRecommended(status) {
      if (!status) return false;
      return status
    },
    checkedFunctionArchived(status) {
      if (!status) return false;
      return status
    },
    validateState(state){
      switch (state) {
        case 'pending':
        return true;
        case 'archived':
        return false;
        case 'reconciled':
        return false;
        case 'balance':
        return true;
        default:
        return true;
      }
    },
    validateArchived(state){
      switch (state) {
        case 'pending':
        return true;
        case 'archived':
        return false;
        case 'reconciled':
        return false;
        case 'balance':
        return true;
        default:
        return true;
      }
    },
    setNameState(state){
      switch (state) {
        case 'pending':
        return 'Pendiente';
        case 'archived':
        return 'Archivado';
        case 'reconciled':
        return 'Conciliado';
        case 'balance':
        return 'Saldo';
        default:
        return '';
      }
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "No recuperarás este egreso",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "Tu egreso ha sido eliminado",
              "success"
            );
            this.axios.delete("/transactions/" + id).then(() => this.getTransactions());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido eliminado",
              "error"
            );
          }
        });
    },
    showModalMovementsReconsile(transaction_id){
      this.showMovementsReconsile = true;
      this.id = transaction_id
    },
    showModalMovement(movement_id){
      this.showMovement = true;
      this.buy_id = movement_id;
    },
    closeFunction(){
      this.showMovement = false;
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    reload(){
      this.getTransactions();
      this.getBankBills();
    },
    setArchived(id, state){
      let app = this;

      this.$swal
        .fire({
          title: "¿Seguro de archivar la transacción?",
          text: "Está transacción sera modificada",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, estoy seguro",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Modificado",
              "Tu egreso ha sido modificado",
              "success"
            );
            // requests
            this.axios
              .post("/transaction-archived/" + id, {
                state: state
              })
              .then(() => {
                app.reload();
              }
            );
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido modificado",
              "error"
            );
          }
        });
    },
    setTransfer(id){
      let app = this;
      this.$swal
        .fire({
          title: "¿Seguro de hacer el traspaso de la transacción?",
          text: "Está transacción será modificada a traspaso entre cuentas",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
          confirmButtonText: "Si, estoy seguro",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Modificado",
              "Tu egreso ha sido modificado",
              "success"
            );
            // requests
            app.loading = true;
            this.axios
              .post("/transaction-transfer/" + id, {})
              .then(() => {
                app.reload();
              }
            );
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu egreso no ha sido modificado",
              "error"
            );
          }
        });
    },
    getTransactions(opts) {

      let app = this;
      app.loading = true;

      if(opts == 'sales') {
        app.query_sales = !app.movement_sales;
      } else {
        app.query_sales = app.movement_sales;
      }

      if(opts == 'buys') {
        app.query_buys = !app.movement_buys;
      } else {
        app.query_buys = app.movement_buys;
      }

      if(opts == 'reconsile') {
        app.query_reconsile = !app.reconsile;
      } else {
        app.query_reconsile = app.reconsile;
      }

      if(opts == 'without_reconciling') {
        app.query_without_reconciling = !app.without_reconciling;
      } else {
        app.query_without_reconciling = app.without_reconciling;
      }

      if(opts == 'only_recommended') {
        app.query_only_recommended = !app.only_recommended;
      } else {
        app.query_only_recommended = app.only_recommended;
      }

      if(opts == 'only_archived') {
        app.query_only_archived = !app.only_archived;
      } else {
        app.query_only_archived = app.only_archived;
      }

      clearTimeout(this.debounceQuery);
      this.debounceQuery = setTimeout(() => {
        this.axios.get("/transactions", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            query: app.query,
            bank_bill_id: app.bank_bill,
            movement_sales: app.query_sales,
            movement_buys: app.query_buys,
            reconsile: app.query_reconsile,
            without_reconciling: app.query_without_reconciling,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            only_recommended: app.query_only_recommended,
            only_archived: app.query_only_archived,
          }
        }).then((response) => {
          app.vendors = response.data.data;
          app.vendors.map( e => {
            e.selected_recommended = null
            if(e.recommended_movement.length > 0){
              e.selected_recommended = e.recommended_movement[0]
            }
          })
          app.current_page = response.data.current_page;
          app.page = response.data.current_page; // current page offset from server
          // Ingresos
          app.balance_total = response.data.balance_total;
          app.total = response.data.per_page;
          app.filterTotal = response.data.total_items;
          app.pageSize = response.data.per_page;
          app.pageCount = response.data.total_pages;
          app.per_page = response.data.per_page;
          // count
          app.count_income = response.data.count_income;
          app.count_expense = response.data.count_expense;
          app.count_reconciled_expense = response.data.count_reconciled_expense;
          app.count_reconciled = response.data.count_reconciled;
          // income
          app.percentage_of_reconciled = response.data.percentage_of_reconciled
          app.percentage_of_balance = response.data.percentage_of_balance
          app.percentage_of_archived = response.data.percentage_of_archived
          app.percentage_of_transfer = response.data.percentage_of_transfer
          app.percentage_of_pending = response.data.percentage_of_pending
          app.total_reconciled = response.data.total_reconciled
          app.total_balance = response.data.total_balance
          app.total_archived = response.data.total_archived
          app.total_pending = response.data.total_pending
          // expense
          app.balance_total_expense = response.data.balance_total_expense;
          app.percentage_of_reconciled_expense = response.data.percentage_of_reconciled_expense
          app.percentage_of_balance_expense = response.data.percentage_of_balance_expense
          app.percentage_of_archived_expense = response.data.percentage_of_archived_expense
          app.percentage_of_transfer_expense = response.data.percentage_of_transfer_expense
          app.percentage_of_pending_expense = response.data.percentage_of_pending_expense
          app.total_reconciled_expense = response.data.total_reconciled_expense
          app.total_balance_expense = response.data.total_balance_expense
          app.total_archived_expense = response.data.total_archived_expense
          app.total_pending_expense = response.data.total_pending_expense

          app.loading = false;

          if(opts == 'sales') {
            app.movement_sales = !app.movement_sales;
          }
          if(opts == 'buys') {
            app.movement_buys = !app.movement_buys;
          }
          if(opts == 'reconsile') {
            app.reconsile = !app.reconsile;
          }
          if(opts == 'without_reconciling') {
            app.without_reconciling
            = !app.without_reconciling;
          }
          if(opts == 'only_recommended') {
            app.only_recommended = !app.only_recommended;
          }
          if(opts == 'only_archived') {
            app.only_archived = !app.only_archived;
          }
        });
      }, 500);
    },
    getBankBills() {
      let app = this;
      app.loading = true;
      this.axios
        .get("/bank_bills", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page
          }
        }).then((response) => {
          app.banks = response.data.banks;
          app.balance_available = response.data.balance_available;
          app.balance_current = response.data.balance_current;
          app.balance_limit = response.data.balance_limit;
          app.loading = false;
        });
    },
  },
};
</script>
