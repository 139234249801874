var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('h2',{staticClass:"my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200"},[_vm._v(" Formularios ")]),_c('div',{staticClass:"grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"date"}},[_vm._v(" Fecha Formulario 29")]),_c('date-picker',{staticClass:"border dark:bg-black-dark dark:border-background-dark",style:({
          width: '100%',
          'border-radius': '2px',
          'padding-left': '0.35rem',
          'padding-right': '0.35rem',
          'padding-top': '0.35rem',
          'padding-bottom': '0.35rem',
          'border-radius': '5px',
        }),attrs:{"format":"DD-MM-YYYY","type":"date","input-class":"xmx-input","placeholder":"Seleccionar periodo"},model:{value:(_vm.company.f29_date),callback:function ($$v) {_vm.$set(_vm.company, "f29_date", $$v)},expression:"company.f29_date"}})],1)]),_c('div',{staticClass:"grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3"},[_c('div',{staticClass:"flex flex-col h-full"},[_c('label',{staticClass:"block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white",attrs:{"for":"date"}},[_vm._v(" Fecha Formulario 50")]),_c('date-picker',{staticClass:"border dark:bg-black-dark dark:border-background-dark",style:({
          width: '100%',
          'border-radius': '2px',
          'padding-left': '0.35rem',
          'padding-right': '0.35rem',
          'padding-top': '0.35rem',
          'padding-bottom': '0.35rem',
          'border-radius': '5px',
        }),attrs:{"format":"DD-MM-YYYY","type":"date","input-class":"xmx-input","placeholder":"Seleccionar periodo"},model:{value:(_vm.company.f50_date),callback:function ($$v) {_vm.$set(_vm.company, "f50_date", $$v)},expression:"company.f50_date"}})],1)]),_c('div',{staticClass:"flex items-center justify-center my-12"},[(_vm.loading)?_c('Spinner'):_c('button',{staticClass:"flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[_vm._v(" Guardar Configuración ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }