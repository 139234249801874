<template>
  <div
    class="mx-4 md:mx-6 lg:mx-2"
    v-if="true"
  >
    <div class="flex-1 mt-6 main-content lg:mt-2 lg:pb-4 md:pb-5">
      <div class="flex flex-col justify-start lg:flex-row">
        <div class="w-full lg:w-12/12">
          <div v-if="!loading" class="flex items-center justify-center mt-6 h-96">
            <Spinner color="#d1d5db"/>
          </div>
          <div v-else-if="loading" class="px-0 py-3 mt-6 rounded-lg xl:px-4 dark:border-black-dark">
            <div v-if="activeGraph==''" id="grapBar" class="pr-0 lg:pr-2">
              <bar-chart :chart-data="datacollection"></bar-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BarChart from "../BarChart";
import Spinner from '@/components/Spinner.vue';

export default {
  name: "dashboard",
  components: {
    BarChart,
    Spinner,
  },
  data() {
    return {
      banks: [],
      activeTab: 0,
      tabs: [
        "Movimientos",
        "Activos",
        "Gastos"
      ],
      countAsset: [],
      countLostResult: [],
      widgetAssets: [],
      widgetLostResult: [],
      user: null,
      movementId: null,
      showModal: false,
      showCategoriesSection: false,
      optionsAccount: [],
      company: null,
      buy: null,
      sales: null,
      movements: [],
      total_items: 0,
      labels: [],
      data_graph_sales: [],
      data_graph_buys: [],
      datacollection: null,
      dataCollectionBuys: null,
      dataCollectionSales: null,
      sale_total_bill: null,
      sale_total_not_bill: null,
      buy_total_paid: null,
      accumulated_buy: null,
      accumulated_sale: null,
      buy_total_not_paid: null,
      percentage: 0,
      average: 0,
      utility: 0,
      losses: 0,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      chartOptions: {
        hoverBorderWidth: 2,
        legend: false,
        responsive: true,
        maintainAspectRatio: false
      },
      charData: {},
      charDataAsset: {},
      loading: false,
      loadingSummary: false,
      loadingGraph: false,
      loadingAcumulated: false,
      loadingMovementsUtility: false,
      activeGraph: '',
      showModalRequest: false,
    };
  },
  created() {
    this.user = this.$auth.user();
    this.getAccounts();
    this.getMovements();
    this.fillData();
  },
  computed: {
    company_id() {
      return this.$route.query.company_id;
    },
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
  },
  mounted() {
    this.getMovements();
    this.fillData();
  },
  methods: {
    changeGraph(graph) {
      this.activeGraph = graph;
    },
    closeFunction() {
      this.showModal = false;
    },
    closeCategoriesSection() {
      this.showCategoriesSection = false;
    },
    selectAccount(id) {
      this.showModal = true;
      this.movementId = id;
    },
    updateValue(e, id, i) {
      if (e == null) {
        this.$refs.select[i].$refs.search.style.display = "block";
      } else {
        this.$refs.select[i].$refs.search.style.display = "none";
        this.axios
          .patch(`/movements/${id}`, {
            account_neto_id: e,
          })
          .then(() => {
            console.log("success");
            this.movements = [];
            this.getMovements();
          })
          .catch(() => {
            console.log("wrong!");
          });
      }
    },
    fillData() {
      const app = this;
      this.datacollection = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Ventas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#2554FF",
            data: app.data_graph_sales,
          },
          {
            label: "Compras",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buys,
          }
        ],
      };
      this.dataCollectionSales = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Pagadas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#aafdd2",
            borderColor: "#aafdd2",
            pointBorderColor: "#2554FF",
            data: app.data_graph_sale_total_bill,
          },
          {
            label: "No Pagadas",
            borderWidth: 1,
            stack: "ventas",
            backgroundColor: "#e5e7e9",
            borderColor: "#e5e7e9",
            pointBorderColor: "#e5e7e9",
            data: app.data_graph_sale_total_not_bill,
          }
        ],
      };

      this.dataCollectionBuys = {
        labels: this.labels,
        responsive: true,
        datasets: [
          {
            label: "Pagadas",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#f9b3b5",
            borderColor: "#f9b3b5",
            pointBorderColor: "#2554FF",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buy_total_paid,
          },
          {
            label: "No Pagadas",
            borderWidth: 1,
            stack: "compras",
            backgroundColor: "#e5e7e9",
            borderColor: "#e5e7e9",
            pointBorderColor: "#e5e7e9",
            borderRadius: 50,
            borderSkipped: false,
            data: app.data_graph_buy_total_not_paid,
          }
        ],
      };
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getMovements() {
      this.getMovementsData();
    },
    getMovementsData() {
      this.axios
        .get("/only-graph", {
          params: {
            company_id: this.company_id,
            from: this.dateRange[0],
            to: this.dateRange[1],
          },
        })
        .then((response) => {
          this.movements = response.data.without_account;
          this.total_items = response.data.total_items;
          this.labels = response.data.labels;
          this.data_graph_sales = response.data.data_graph_sales;
          this.data_graph_buys = response.data.data_graph_buys;
          this.data_graph_sale_total_bill = response.data.data_graph_sale_total_bill;
          this.data_graph_sale_total_not_bill = response.data.data_graph_sale_total_not_bill;
          this.data_graph_buy_total_paid = response.data.data_graph_buy_total_paid;
          this.data_graph_buy_total_not_paid = response.data.data_graph_buy_total_not_paid;
          this.percentage = response.data.percentage;
          this.average = response.data.average;
          this.charData = response.data.char_data;
          this.fillData();
          this.loading = true;
        });
    },
  },
};
</script>

<style type="text/css">
.green {
  color: green;
}
.red {
  color: red;
}
.box .selectDashboard {
  width: 250%;
}
.box .vs__dropdown-toggle {
  border: none;
  border-bottom: 1px solid #f7f9ff;
}
.movements-box {
  max-height: 38vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.accounts-box {
  max-height: 35vh;
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-columns{
  grid-template-columns: repeat(auto-fit, minmax(18rem, 1fr));
}
#grapPie canvas{
  height: 200px !important;
}
#grapPieTwo canvas{
  height: 200px !important;
}
.movements-box::-webkit-scrollbar, .accounts-box::-webkit-scrollbar {
  width: 5px;
}

    /* Track */
.movements-box::-webkit-scrollbar-track, .accounts-box::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 2px grey; */
  border-radius: 10px;
}

    /* Handle */
.movements-box::-webkit-scrollbar-thumb, .accounts-box::-webkit-scrollbar-thumb {
  background: #4c1760;/*rgb(7, 7, 7);*/
  border-radius: 10px;
}

    /* Handle on hover */
.movements-box::-webkit-scrollbar-thumb:hover, .accounts-box::-webkit-scrollbar-thumb:hover {
  background: #4c1760;
}
</style>
