
<script src="https://js.fintoc.com/v1/"></script>
<template>
  <div class="modal-mask flex md:justify-center md:items-center">
    <div class="h-screen w-screen"></div>
    <div class="absolute w-full max-w-sm left-0">
      <aside
        class="dark:bg-background-dark bg-white pb-10 overflow-auto left-0 h-screen md:max-h-screen  w-full overscroll-contain">
        <div class="flex justify-start pl-4 sm:px-6 pt-4 lg:pt-6">
          <button class="inline-block right-0 hover:scale-150 dark:hover:text-cian-dark mt-2.5 lg:mt-1 focus:outline-none" @click.prevent="$emit('close')">
            <Icon icon="heroicons:x-mark" class="h-6 w-6" />
          </button>
        </div>

        <div class="px-6">
          <nav class="mt-16">
            <a
              class="flex  items-center py-3 cursor-pointer"
              @click.prevent="goTo('dashboard')"
            >
              <span class="font-medium text-lg text-black dark:text-white">Dashboard</span>
            </a>
            <a
              class="flex  items-center py-3 cursor-pointer"
              @click.prevent="goTo('Sales')"
            >
              <span class="font-medium text-lg text-black dark:text-white">Ingresos</span>
            </a>
            <a
              class="flex items-center py-3"
              @click.prevent="goTo('Buys')"
            >
              <span class="font-medium text-lg text-black dark:text-white">Egresos</span>
            </a>
            <details name="category">
              <summary
                class="items-center py-3 text-lg text-black dark:text-white cursor-pointer"
              >
                Banco
              </summary>
              <a
                class="flex items-center py-3"
                @click.prevent="openFintoc()"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Agregar cuenta bancaria</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('transaction')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Movimientos</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('banks')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Cuentas bancarias</span>
              </a>
            </details>
            <details name="category">
              <summary
                class="items-center py-3 text-lg text-black dark:text-white cursor-pointer"
              >
                Contactos
              </summary>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('AllClients')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Clientes</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('AllVendors')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Proveedores</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('serviceProvider')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Proveedores de servicios</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('AllEmployees')"
              >
                <span class="font-medium text-lg text-black dark:text-white pl-4">Trabajadores</span>
              </a>
            </details>
            <a
              class="flex items-center py-3 cursor-pointer"
              @click.prevent="goTo('accounting')"
            >
              <span class="font-medium text-lg text-black dark:text-white">Comprobantes</span>
            </a>
            <details name="category">
              <summary
                class="items-center py-3 text-lg text-black dark:text-white cursor-pointer"
              >
                Reportes
              </summary>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('diaryBook')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Libro Diario</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('ledger')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Libro Mayor</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('remunerations')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Libro de Remuneraciones</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('reports')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Balance</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('statementOfIncome')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Estado Resultado</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('statementOfIncomeComparative')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Estado Resultado Comparativo</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('cashflow')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Flujo de Caja</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('historial')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Historial</span>
              </a>
            </details>
            <details name="category">
              <summary
                class="items-center font-medium text-lg text-black dark:text-white py-3 cursor-pointer focus:outline-none "
              >
                Configuración
              </summary>
              <a
                class="flex items-center py-3 "
                @click.prevent="goTo('Company')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Empresas</span>
              </a>
              <a
                class="flex items-center py-3  "
                @click.prevent="goTo('account')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Plan de cuentas</span>
              </a>
              <a
                class="flex items-center py-3  "
                @click.prevent="goTo('tax')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Impuesto</span>
              </a>
              <a
                class="flex items-center py-3  "
                @click.prevent="goTo('costCenter')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Centro de Costos</span>
              </a>
              <a
                class="flex items-center py-3 "
                @click.prevent="goTo('branchOffice')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Sucursales</span>
              </a>
              <a
                class="flex items-center py-3"
                @click.prevent="goTo('Currency')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Indicadores Financieros</span>
              </a>
              <a
                class="flex items-center py-3 "
                @click.prevent="goTo('Requests')"
              >
                <span class="mx-4 font-medium text-lg text-black dark:text-white pl-4">Soporte</span>
              </a>
            </details>
            <a
              class="flex items-center py-3 "
              @click.prevent="goTo('integration')"
            >
              <span class="font-medium text-lg text-black dark:text-white">Integraciones</span>
            </a>
          </nav>
        </div>

      </aside>
    </div>
  </div>
</template>

<script>
import { Icon } from '@iconify/vue2';

export default {
  components: {
    Icon,
  },
  data() {
    return {
      company_id: null,
    };
  },
  methods: {
    goTo(url) {
      this.$router.push({ name: url, params: { company_id: this.company_id } })
      .then(() => this.$emit('close') )
      .catch(()=>{});
    },
    openFintoc() {
      let cur = this;
      let user_id = this.$auth.user().id;
      this.widget = Fintoc.create({
        holderType: "business",
        product: "movements",
        publicKey: "pk_live_9DAneop21aQambS4MmugKhEXXDqdyiqF",
        webhookUrl: `https://api.cymasuite.com/fintoc?credentials=${cur.company_id}_${user_id}`,
      });
      this.widget.open();
    },
  },
};

</script>

<style scoped>
::-webkit-scrollbar {
  width: 0;
}
.h-slider{
  height: calc(100vh - 2rem);
}
details {
  transition: all .3s ease;
}
details[open] summary {
  background-color: #f3f4f6;
  color: #000;
  font-weight: 600;
}
summary > * {
  display: inline-block
}
.prisma {
  width: 30px;
  height: 30px;
}
</style>
