<template>
  <div>
    <h4 class="text-gray-800 dark:text-gray-100 font-semibold text-lg mb-1.5">
      {{ author.name }} {{ author.lastname }}
      <span class="text-gray-400">{{ author.email }}</span>
    </h4>
  </div>
</template>
<script>
export default {
  name: "UserName",
  props: {
    id: Number,
  },
  data() {
    return {
      author: {}
    };
  },
  created() {
    this.getAuthorChange()
  },
  methods: {
    getAuthorChange(){
      let app = this;
      console.log('app', isNaN(app.id));
      if (isNaN(app.id)) {
        app.author.name = 'Servidor'
        app.author.lastname = 'Interno'
        app.author.email = 'contacto@cymasuite.com'
      } else {
        this.axios.get('users/' + app.id).then((response) => {
          app.author = response.data
        });
      }
    },
  },
};
</script>