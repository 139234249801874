<template>
	<div
    v-if="!isAccountant && showAdvice"
    class="relative isolate p-6 sm:px-3.5 py-2.5 bg-gray-100 text-gray-800 dark:bg-black-dark dark:text-white mb-4 rounded-lg flex gap-x-6 items-center banner-accountant"
  >
		<div class="flex flex-wrap items-center gap-x-4 gap-y-2">
			<p class="text-sm text-center hidden md:block">
				<span>Aún no has configurado un contador para tus solicitudes.</span>
			</p>
      <RouterLink
        :to="{ name: 'editUsersAllowed' }"
        class="px-3.5 py-1 bg-gray-900 hover:bg-gray-700 shadow-sm text-white font-semibold rounded-full hover:bg-cian-light dark:bg-cian-500 dark:hover:bg-cian-600 dark:text-white hover:text-white transition"
      >
        Asignar contador <span aria-hidden="true">&rarr;</span>
      </RouterLink>
		</div>
    <div class="flex flex-1 justify-end">
      <button
        type="button"
        @click="hideAdvice"
        class="-m-3 p-3 focus-visible:outline-offset-[-4px] text-gray-900 dark:text-gray-200"
      >
        <Icon icon="heroicons:x-mark" class="w-5 h-5" />
      </button>
    </div>
	</div>
</template>
<script>
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon
  },
  data() {
    return {
      isAccountant: true,
      showAdvice: true
    }
  },
  created() {
    this.getPermissions()
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    company_id(){
      this.getPermissions()
    }
  },
  methods: {
    getPermissions() {
      this.axios.get("/permissions",{
        params:{
          company_id: this.company_id,
          state: [0,2],
        }
      }).then((response) => {
        let roles = response.data.map(element => element.role)
        this.isAccountant = roles.includes('accountant')
        this.checkAdviceState()
      });
    },
    checkAdviceState() {
      const adviceState = this.$cookie.get(`adviceState_${this.company_id}`)
      this.showAdvice = adviceState !== 'hidden'
    },
    hideAdvice() {
      this.showAdvice = false
      // Set the cookie to expire in one day
      this.$cookie.set(`adviceState_${this.company_id}`, 'hidden', { expires: '1D' })
    }
  },
}
</script>


<style scoped>
@media screen and (min-width: 768px) {
  .banner-accountant::before {
    content: '';
    flex: 1 1 0%;
  }
}
</style>
