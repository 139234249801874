<template>
        <input v-model="rut" class="form-control" name="rut" maxlength="12" type="text" 
          placeholder="12.345.678-0" required autofocus>
        <!-- <span class="help-block" v-if="error != ''">
            <strong>{{error}}</strong>
        </span> -->
</template>
<script>
    export default {
        props: {
          rt: String,
          dv: String,
        },
        data() {
            return {
                rut: '',
                error: '',
            }
        },
        watch: {
            rut: function () {
                this.rut = this.cleanRut(this.rut);
                let len = this.rut.length - 1;
                if (len > 3) {
                    let dv = this.rut[len];
                    let beforeDv = this.rut.substring(0, len).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                    this.rut = beforeDv + '-' + dv;
                }
                let rt = this.rut.split('-')[0].split('.').join('')
                let dv = this.rut.split('-')[1]
                this.$emit('input_rut', rt)
                this.$emit('input_dv', dv)
            }
        },
        created(){
          this.rut = this.rt + this.dv
        },
        methods: {
            cleanRut(value) {
                if(value != undefined) {
                  return value.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();
                }
            },
            errorInRut() {
                console.log('')
            }
        }
    }
</script>