<template  >
  <div class="py-2 mx-6">
    <div class="w-full flex gap-4">
      <div class="lg:text-4xl text-3xl font-semibold items-center dark:text-white flex-auto flex gap-4">
        $ {{ formatNumber(total || 0) }}
        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="#fab6b8">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
    <div class="flex mb-2 lg:mb-6">
      <h2 class="flex-auto lg:text-lg sm:text-sm text-gray-500 sm:text-left">
        El Formulario 29 corresponde a las declaraciones de carácter mensual de impuestos.
      </h2>
    </div>
    <div class="flex flex-wrap flex-col sm:flex-row gap-2 mb-6">
      <div class="filter">
        <date-picker
          input-class="xmx-input"
          :style="{
            border: '1px solid #e2e8f0',
            width: '100%',
            'background-color': 'white',
            'border-radius': '0px',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.80rem',
            'padding-bottom': '0.80rem'
          }"
          v-model="dateRange"
          type="date"
          format='DD-MM-YYYY'
          range
          placeholder="Seleccionar un rango de Fecha"
          v-on:input="getReportTax"
        ></date-picker>
      </div>
    </div>

    <modalF29
        v-if="showModal"
        @close="showModal = false"
        :tax_id="tax_id"
        :action="action"
        :domHTML="domHTML"
    ></modalF29>

    <modalCodeF29
        v-if="showModalCode"
        @close="showModalCode = false"
        :tax_id="tax_id"
        :action="action"
        :domJSON="domJSON"
    ></modalCodeF29>

    <BeeGridTable
      border
      height="560"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :data="reportsF29"
      :loading="loading"
    >
      <BeeColumn field="date" title="Descripción" type="text">
        <template slot-scope="{ row }">
          Formulario 29 - {{ row.date }}
        </template>
      </BeeColumn>

      <BeeColumn field="Total" title="Total" type="text">
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.total_to_pay_within_the_legal_term) }}
        </template>
      </BeeColumn>

      <BeeColumn field="determined_VAT" title="Impuesto determinado" type="text">
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.determined_VAT) }}
        </template>
      </BeeColumn>
       <!-- retention_code_151: integer, net_amount_PPM: integer, sub_total_determined_tax: integer, remaining_credit_addiction: integer, tax_art42_determined: integer, remnant_advances: integer, advance_to_impute: integer, total_determined: integer, total_to_pay_within_the_legal_term: integer, more_IPC: integer, more_interest_and_fine: integer, montal_forcononation: integer, total_to_pay_with_surcharge: integer, created_at: datetime, updated_at: datetime) -->

      <BeeColumn field="editar" title="Opciones" type="text">
        <template slot-scope="{ row }">
            <div class="flex gap-4">
              <a v-on:click="editFunctionCode(row.id, row.data_json)" class="items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
                </svg>
              </a>

              <a v-on:click="editFunction(row.id, row.data_html)" class="items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M19 20H5a2 2 0 01-2-2V6a2 2 0 012-2h10a2 2 0 012 2v1m2 13a2 2 0 01-2-2V7m2 13a2 2 0 002-2V9a2 2 0 00-2-2h-2m-4-3H9M7 16h6M7 8h6v4H7V8z" />
                </svg>
              </a>
            </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatNumber } from "@/utils/formatters";
import modalF29 from "./modalF29";
import modalCodeF29 from "./modalCodeF29";
import DatePicker from "vue2-datepicker";

export default {
  name: "tax",
  props: {},
  data() {
    return {
      tax_id: "",
      action: "create",
      showModalCode: false,
      showModal: false,
      reportsF29: [],
      f29: [],
      columns: [],
      domHTML: null,
      loading: true,
      total: 0,
    };
  },
  components: {
    modalF29,
    modalCodeF29,
    DatePicker
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  mounted() {
    this.user = this.$auth.user();
    this.getReportTax();
  },
  watch: {
    company_id() {
      this.getReportTax();
    },
  },
  methods: {
    formatNumber,
    editFunction(id, dom) {
      this.showModal = true;
      this.tax_id = id;
      this.action = "update";
      this.domHTML = dom;
    },
    editFunctionCode(id, json) {
      this.showModalCode = true;
      this.tax_id = id;
      this.action = "update";
      this.domJSON = json;
    },
    getReportTax() {
      let app = this;

      this.axios.get("/f29", {
        params: {
          company_id: app.company_id,
          from: app.dateRange[0],
          to: app.dateRange[1],
        },
      }).then((response) => {
        this.reportsF29 = response.data.data;
        this.total = response.data.total;
        this.loading = false;
      });
    },
  },
};
</script>

<style>
.padded-bel-panel {
    width: 100%;
    text-align: left !important;
    font-size: 11px;
}
.tabla td {
    padding: 2px !important;
    border: 1px solid #d15600;
    background-color: #fed9af;
}
.tabla_f29 td.fw-campoOculto {
    background-color: #fff !important;
}
.celda-centrada,
.celda-centrada-bb {
    padding: 0;
    text-align: center;
}
.f29_celda_cabecera_propuesta,
.f29_celda_cabecera {
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #d9edf7;
    color: black;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}
.f29_celda_cabecera_b {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #d9edf7;
    color: black;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}
.f29_celda_cabecera_izq {
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #d9edf7;
    color: black;
    font-size: 13px;
    text-indent: 25px;
    font-weight: bold;
    text-align: justify;
}
.f29_celda_cabecera_der {
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 11px;
    font-weight: bold;
    text-align: right;
}
.f29_celda_columna_cabecera,
.f29_celda_columna_cabecera_b {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #d9edf7;
    font-size: 11px !important;
    font-weight: bold;
    height: 25px !important;
    text-align: center;
}
.f29_celda_columna_cabecera_izq {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #d9edf7;
    font-size: 11px !important;
    text-indent: 25px;
    font-weight: bold;
    height: 25px !important;
    text-align: justify;
}
.f29_celda_columna_cabecera_der {
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #9fc989;
    font-size: 11px !important;
    height: 25px;
    font-weight: bold;
    text-align: right;
}
.celda-linea {
    border-left: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #e8e8e8;
    color: #000;
    text-align: center;
}
.texto-informativo {
    font-weight: normal;
    text-align: justify;
}
.celda-codigo,
.celda-codigo-propuesta {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    text-align: center;
    vertical-align: middle;
    background-color: #d9edf7 !important;
    color: #000 !important;
}
.celda-signo {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
    text-align: center;
    vertical-align: middle;
    width: 15px;
    background: #e8e8e8;
}
.celda-glosa-centro {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
    text-align: center;
    vertical-align: middle;
    background: #d9edf7;
}
.celda-glosa-derecha {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
    text-align: right;
    vertical-align: middle;
    background: #d9edf7;
    width: 100%;
    padding-right: 5px;
}
.celda-glosa {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
    text-align: left;
    vertical-align: middle;
    background: #fff;
    padding-left: 4px;
}
.celda-linea-b {
    border-top: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    border-left: 1px solid #5a5353;
    border-bottom: 1px solid #73b464;
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #73b464;
    color: #000;
    font-weight: bold;
    text-align: center;
}
.celda-codigo-b {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #dcdcdc !important;
    text-align: center;
    vertical-align: middle;
    background-color: #eee !important;
    color: #000 !important;
}
.celda-signo-b {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #dcdcdc;
    color: #000;
    text-align: center;
    vertical-align: middle;
    width: 15px;
    background: #dcdcdc;
}
.celda-glosa-b {
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #dcdcdc;
    color: #000;
    text-align: left;
    padding-left: 4px;
    vertical-align: middle;
    background: #d9edf7;
}
.celda-glosa-c {
    border-top: 1px solid #dcdcdc;
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    color: #000;
    text-align: left;
    vertical-align: middle;
    background: #dcdcdc;
}
.borde_tabla_f29 {
    border-collapse: collapse;
    margin: 0;
}
.borde_tabla_f29 .gwt-TextBox,
.fw-campoOculto,
.tabla_disabled_text {
    border: none !important;
    font-size: 10px;
    text-align: right;
}
.borde_tabla_f29 .tabla_td_codigo,
.borde_tabla_f29_xslt .tabla_td_codigo,
.borde_tabla_f29_sb .tabla_td_codigo,
.borde_tabla_f29_sbs .tabla_td_codigo {
    background-color: white !important;
}
.borde_tabla_f29 td {
    border-collapse: collapse;
    margin: 0;
    border: 1px solid #000 !important;
}
.borde_tabla_f29_xslt {
    border-collapse: collapse;
    margin: 0;
    width: inherit;
}
.borde_tabla_f29_xslt .gwt-TextBox,
.fw-campoOculto,
.tabla_disabled_text {
    border: none !important;
    font-size: 10px;
    text-align: right;
}
.borde_tabla_f29_xslt td {
    border-collapse: collapse;
    margin: 0;
    border: 1px solid #000 !important;
}
.borde_tabla_f29_sb {
    border-collapse: collapse;
    margin: 0;
}
.noleft {
    text-align: inherit;
}
.borde_tabla_f29_sb .gwt-TextBox,
.fw-campoOculto,
.tabla_disabled_text {
    border: none !important;
}
.borde_tabla_f29_sbs {
    border-collapse: collapse;
    margin: 0;
    width: 100%;
}
.borde_tabla_f29_sbs .gwt-TextBox,
.fw-campoOculto,
.tabla_disabled_text {
    border: none !important;
    font-size: 10px;
    text-align: right;
}
.borde_tabla_f29_sbs td {
    border-collapse: collapse;
    margin: 0;
    border-right: 1px solid #000 !important;
    border-left: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
}
.f29_cabecera_subtabla_bs {
    margin-left: 0;
    margin-right: 0;
    border-top: 1px solid #000;
    border-left: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
}
.contenedor_formulario_b {
    margin-left: 0;
    margin-right: 0;
    border: 1px solid #ccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    vertical-align: middle;
    color: black;
    font-size: 9px;
    font-weight: normal;
}
.f29_celda_cabecera_bi {
    margin-left: 0;
    margin-right: 0;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    border-bottom: 1px solid #5a5353;
}
.f29_cabecera_tabla {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    border-top: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 13px;
    font-weight: bold;
    width: 100%;
}
.f29_cabecera_subtabla {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
}
.f29_cabecera_subtabla_bi {
    margin-left: 0;
    margin-right: 0;
    border-left: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #73b464;
    color: black;
    font-size: 11px;
    font-weight: bold;
    width: 100%;
}
.f29_cabecera_subtabla_c {
    margin-left: 0;
    margin-right: 0;
    height: 20px;
    border: 1px solid #5a5353;
    vertical-align: middle;
    background-color: #d9edf7;
    color: black;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
}
.tabla_f29 {
    border: 1px solid #5a5353;
    border-collapse: collapse;
    margin: 0;
    color: #333;
    width: 100%;
}
.tabla_f29 td {
    padding: 2px !important;
    border: 1px solid #ccc;
    background-color: #d6fed6;
}
.tabla_f29_fixed {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    color: #333;
}
.fw-campoOculto {
    background-color: #eee;
    border-left: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    height: 16px;
    text-align: right;
}
.fw-campoOculto-c {
    background-color: #eee;
    border-left: 1px solid #5a5353;
    border-bottom: 1px solid #5a5353;
    border-right: 1px solid #5a5353;
    height: 16px;
    text-align: center;
    pointer-events: none;
}
.fw-campoMinimo-c {
    border-style: solid;
    border-color: silver;
    border-width: 1px;
    min-height: 16px;
    text-align: center;
}
.tabla_td_fixed_b_right {
    padding: 2px !important;
    background-color: #fff !important;
    width: 90px !important;
    white-space: nowrap;
    text-align: right;
    border-style: solid;
    border-color: silver;
    border-width: 1px;
}
.invisible {
    visibility: hidden;
}
.fila_invisible {
    visibility: hidden;
    border-style: none;
    height: 1px;
}
.fila_invisible td {
    border-collapse: collapse;
    margin: 0;
    visibility: hidden !important;
    border-style: none !important;
    height: 1px;
}
</style>
