<template>
  <div
    class="py-2 mx-6"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider,
    }"
  >
    <div
      class="flex flex-col transition-all duration-300 transform"
    >
      <!-- Profile Background -->
      <div class="relative h-56">
        <img
          src="@/assets/images/wallpaper_cymasuite.png"
          class="object-cover w-full h-full"
          alt="Profile Background"
        />
      </div>

      <!-- Content -->
      <div class="relative pb-8 px-4 sm:px-6">
        <!-- Pre header -->
        <div class="-mt-16 mb-6 sm:mb-3">
          <div
            class="flex flex-col items-center sm:flex-row sm:items-end sm:justify-between"
          >
            <!-- Avatar -->
            <picture class="inline-flex -ml-1 -mt-1 mb-4 sm:mb-0">
              <img
                class="rounded-full border-4 border-white"
                :src="defaultImage"
                alt="Avatar"
                width="128"
                height="128"
              />
            </picture>
            <!-- Actions -->
            <div class="flex mb-2 space-x-2">
              <!-- <button class="p-2 flex-shrink-0 rounded border border-cian-dark shadow-sm">
                <svg class="w-4 h-1 fill-current text-gray-400" viewBox="0 0 16 4">
                  <circle cx="8" cy="2" r="2"></circle>
                  <circle cx="2" cy="2" r="2"></circle>
                  <circle cx="14" cy="2" r="2"></circle>
                </svg>
              </button>
              <button class="p-2 flex-shrink-0 rounded border border-cian-dark hover:border-green-400 shadow-sm"></button>
              <button
                class="px-2 py-1 inline-flex items-center justify-center flex-shrink-0 text-white font-medium bg-cian-dark hover:bg-white hover:text-cian-dark shadow-sm rounded border border-transparent transition-all duration-150"
              >
                <span>Editar</span>
              </button> -->
            </div>
          </div>
        </div>
        <!-- Header -->
        <header class="text-center sm:text-left mb-6">
          <!-- Name -->
          <div class="inline-flex items-start mb-2">
            <h2
              class="text-2xl text-gray-900 dark:text-white font-bold"
            >
              {{ employee.name }} {{ employee.last_name }}
              {{ employee.mothers_last_name }}
            </h2>
            <svg class="w-4 h-4 fill-current flex-shrink-0 text-red-400 ml-2" viewBox="0 0 16 16">
              <path
                d="M13 6a.75.75 0 0 1-.75-.75 1.5 1.5 0 0 0-1.5-1.5.75.75 0 1 1 0-1.5 1.5 1.5 0 0 0 1.5-1.5.75.75 0 1 1 1.5 0 1.5 1.5 0 0 0 1.5 1.5.75.75 0 1 1 0 1.5 1.5 1.5 0 0 0-1.5 1.5A.75.75 0 0 1 13 6ZM6 16a1 1 0 0 1-1-1 4 4 0 0 0-4-4 1 1 0 0 1 0-2 4 4 0 0 0 4-4 1 1 0 1 1 2 0 4 4 0 0 0 4 4 1 1 0 0 1 0 2 4 4 0 0 0-4 4 1 1 0 0 1-1 1Z"
              ></path>
            </svg>
          </div>
          <!-- Bio -->
          <!-- <div class="text-sm mb-3 dark:text-gray-400 flex items-center">
          </div> -->
          <!-- Meta -->
          <div class="flex flex-wrap justify-center sm:justify-start space-x-4">
            <div class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="flex-shrink-0 h-4 w-4 text-gray-400">
                <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
              </svg>
              <span class="dark:text-gray-400 ml-2">{{ employee.position }}</span>
            </div>
            <div class="flex items-center">
              <svg class="flex-shrink-0 h-3 w-3 fill-current text-gray-400" viewBox="0 0 16 16">
                  <path d="M11 0c1.3 0 2.6.5 3.5 1.5 1 .9 1.5 2.2 1.5 3.5 0 1.3-.5 2.6-1.4 3.5l-1.2 1.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l1.1-1.2c.6-.5.9-1.3.9-2.1s-.3-1.6-.9-2.2C12 1.7 10 1.7 8.9 2.8L7.7 4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l1.2-1.1C8.4.5 9.7 0 11 0ZM8.3 12c.4-.4 1-.5 1.4-.1.4.4.4 1 0 1.4l-1.2 1.2C7.6 15.5 6.3 16 5 16c-1.3 0-2.6-.5-3.5-1.5C.5 13.6 0 12.3 0 11c0-1.3.5-2.6 1.5-3.5l1.1-1.2c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L2.9 8.9c-.6.5-.9 1.3-.9 2.1s.3 1.6.9 2.2c1.1 1.1 3.1 1.1 4.2 0L8.3 12Zm1.1-6.8c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4.2 4.2c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l4.2-4.2Z"></path>
              </svg>
              <span class="dark:text-gray-400 ml-2">
                <a @click.prevent="copyClipboard">
                  {{ employee.contact }}
                </a>
              </span>
            </div>
            <div v-if="employee.company" class="flex items-center">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 text-gray-400">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z" />
              </svg>
              <span class="dark:text-gray-400 ml-2 capitalize">{{ employee.company.business_name }}</span>
            </div>
          </div>
        </header>
        <!-- Tabs -->
        <div class="relative mb-6">
          <div class="absolute bottom-0 w-full h-px bg-gray-300" aria-hidden="true"></div>
          <ul
            class="relative text-sm font-medium flex flex-nowrap -mx-4 sm:-mx-6 lg:-mx-8 overflow-x-auto"
          >
            <li
              class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="block pb-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 whitespace-nowrap"
                :class="{'text-indigo-500 dark:text-cian-dark border-b-2 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark':activeItem === 'General'}"
                @click.prevent="activeItem = 'General'"
              >
                Información General
              </a>
            </li>
            <li
              class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="block pb-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 whitespace-nowrap"
                :class="{'text-indigo-500 dark:text-cian-dark border-b-2 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Movements'}"
                @click.prevent="activeItem = 'Movements'"
              >
                Movimientos
              </a>
            </li>
            <!-- <li
              class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="block pb-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 whitespace-nowrap"
                :class="{'text-indigo-500 dark:text-cian-dark border-b-2 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Conditions'}"
                @click.prevent="activeItem = 'Conditions'"
              >
                Condiciones
              </a>
            </li>
            <li
              class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="block pb-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 whitespace-nowrap"
                :class="{'text-indigo-500 dark:text-cian-dark border-b-2 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Forecast'}"
                @click.prevent="activeItem = 'Forecast'"
              >
                Previsión
              </a>
            </li>
            <li
              class="mr-6 first:pl-4 sm:first:pl-6 lg:first:pl-8 last:pr-4 sm:last:pr-6 lg:last:pr-8"
            >
              <a
                class="block pb-3 text-gray-500 dark:text-gray-200 hover:text-gray-600 whitespace-nowrap"
                :class="{'text-indigo-500 dark:text-cian-dark border-b-2 hover:text-indigo-600 border-indigo-500 dark:border-cian-dark': activeItem === 'Absenteeism'}"
                @click.prevent="activeItem = 'Absenteeism'"
              >
                Ausentismo
              </a>
            </li> -->
          </ul>
        </div>
        <!-- Profile content -->
        <div class="flex flex-col xl:flex-row xl:space-x-16">
          <!-- Main Content -->
          <div class="mb-8 xl:mb-0 space-y-5 w-full dark:text-white">
            <!-- About me -->
            <div v-if="activeItem=='General'">
              <General  :employee="employee" />
            </div>
            <div v-if="activeItem=='Movements'">
              <Movements  :employee_id="employee_id" />
            </div>
            <!-- <div v-if="activeItem == 'Conditions'">
              <Conditions />
            </div>
            <div v-if="activeItem == 'Forecast'">
              <Forecast />
            </div>
            <div v-if="activeItem == 'Absenteeism'">
              <Absenteeism />
            </div> -->
          </div>
          <!-- Sidebar -->
          <aside v-if="activeItem !=='Movements'" class="xl:min-w-sm xl:w-56 space-y-4">
            <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">RUT</h3>
              <div class="text-gray-600d dark:text-gray-400">
                {{ formatRut(employee.rut) }}
              </div>
            </div>
            <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">Cargo</h3>
              <div class="text-gray-600 dark:text-gray-400">
                {{ employee.position }}
              </div>
            </div>
            <!-- <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">Location</h3>
              <div class="text-gray-600 dark:text-gray-400">
                Milan, IT - Remote
              </div>
            </div> -->
            <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">Email</h3>
              <div class="text-gray-600 dark:text-gray-400">{{employee.contact}}</div>
            </div>
            <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">Cumpleaños</h3>
              <div class="text-gray-600 dark:text-gray-400">
                {{ formatDate(employee.date_of_birth || " ") }}
              </div>
            </div>
            <div class="text-sm">
              <h3 class="text-gray-800 dark:text-white">Se unió</h3>
              <div class="text-gray-600 dark:text-gray-400">{{formatDate(employee.created_at)}}</div>
            </div>
          </aside>
        </div>
      </div>
      <!-- end main content -->
    </div>
  </div>
</template>

<script>
import { formatRut, formatPercentage } from '@/utils/formatters'
import "@/assets/stylesheets/switch.scss";
import General from "./tabs/General.vue";
import Movements from "./tabs/Movements.vue";
// import Absenteeism from "./tabs/Absenteeism.vue";
// import Conditions from "./tabs/Conditions.vue";
// import Forecast from "./tabs/Forecast.vue";
import avatar from "@/assets/images/user.svg";

export default {
  components: { General, Movements },
  name: "Employees",
  props: {},
  data() {
    return {
      company: {},
      defaultImage : avatar,
      user: { avatar_url: "" },
      activeItem: "General",
      employee_id: parseInt(this.$route.params.employee_id),
      employee: [],
      loading: true,
    };
  },
  created() {
    this.reload();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  methods: {
    formatRut,
    formatPercentage,
    reload() {
      this.getEmployee();
      this.getCompany();
    },
    copyClipboard() {
      try {
        navigator.clipboard.writeText(
          this.employee.contact
        )
        this.$toast.success('copiado al portapapeles!', 'OK', {
          position: "bottomLeft",
          timeout: 2000,
        });
      } catch (err) {
        throw console.error(err)
      }
    },
    formatDateSort(date) {
      return new Date(date).toLocaleDateString("es-CL", { timeZone: "UTC" });
    },
    formatDate(date) {
      if(date == " ") return "Sin fecha";
      return new Date(date).toLocaleDateString("es-CL", { timeZone: "UTC" });
    },
    getEmployee() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/employees/" + app.employee_id, {
          params: {
            company_id: app.company_id,
          },
        })
        .then((response) => {
          app.employee = response.data;
        });
    },
    getCompany() {
      const app = this;
      this.axios
        .get("/companies/" + app.company_id)
        .then((response) => {
          app.company = response.data;
        });
    },
  },
};
</script>
<style scoped></style>
