<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">Socios</h2>
    <div
      class="grid w-full grid-rows-3 gap-4 my-3 sm:grid-cols-2 lg:grid-cols-3"
      v-for="(partner, i) in company.partners_attributes"
      :key="i"
    >
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Vigencia
        </label>
        <input
          disabled
          class="input-text"
          type="text"
          placeholder="Vigencia"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Nombre</label
        >
        <input
          type="text"
          disabled
          class="input-text"
          v-model="partner.names"
          placeholder="Nombre"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Rut</label
        >
        <VueRut
          class="input-text"
          :rt="partner.rut"
          :dv="partner.dv"
          disabled
          @input_rut="partner.rut = $event"
          @input_dv="partner.dv = $event"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >capital</label
        >
        <input
          type="text"
          disabled
          class="input-text"
          placeholder="Capital"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Utilidades</label
        >
        <input
          type="text"
          disabled
          class="input-text"
          v-model="partner.profit_sharing"
          placeholder="Utilidades"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Fecha de incorporación</label
        >
        <input
          type="text"
          disabled
          class="input-text"
          :value="formatSimpleDate(partner.date_of_incorporation)"
          placeholder="Fecha de incorporación"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Correo</label
        >
        <input
          type="text"
          class="input-text"
          placeholder="Correo"
          v-model="partner.email"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Contacto</label
        >
        <input
          type="text"
          class="input-text"
          placeholder="Contacto"
          v-model="partner.phone"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker"
          >Fecha de Nacimiento</label
        >
        <date-picker
          input-class="xmx-input"
          :style="{
            'width': '100%',
            'border-radius': '1px',
            'padding-left': '0.55rem',
            'padding-right': '0.55rem',
            'padding-top': '0.55rem',
            'padding-bottom': '0.55rem'
          }"
          format='DD-MM-YYYY'
          v-model="partner.birthdate"
          type="date"
          placeholder="Fecha de Nacimiento"
        ></date-picker>
      </div>
      <br><br>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import { formatSimpleDate } from "@/utils/formatters";
import Spinner from '@/components/Spinner2.vue';
import VueRut from "@/components/etc/VueRut.vue"
import DatePicker from "vue2-datepicker";

export default {
  name: "PartnersData",
  data() {
    return {
      loading: false,
    }
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$emit('updateTab', "Partners")
    this.company.partners_attributes.map((data) =>{
      return data.birthdate = new Date(data.birthdate)
    })
  },
  components: { VueRut, Spinner, DatePicker },
  methods: {
    formatSimpleDate,
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company.id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>
