<template>
  <div class="w-full">
    <h2 class="text-xl dark:text-white font-semibold my-4">
      Información del Trabajador
    </h2>
    <table class="w-full">
      <!-- <thead class="">
      </thead> -->
      <tbody class="border-gray-300 border-b">
        <tr class="border-b">
          <td class="text-gray-900 px-4 py-2 md:w-1/2">Nombre</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.name }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Apellido Paterno</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.last_name }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Apellido Materno</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.mothers_last_name }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Sexo</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.gender }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Fecha de Nacimiento</td>
          <td class="px-4 py-2 md:w-1/2">
            {{ formatSimpleDate(employee.date_of_birth) }}
          </td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Nacionalidad</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.nationality }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Dirección</td>
          <td class="px-4 py-2 md:w-1/2"></td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Estado Civil</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.civil_status }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Nivel Educacional</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.educational_level }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">idiomas</td>
          <td class="px-4 py-2 md:w-1/2">Español</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Email personal</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.contact }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Teléfono</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.phone }}</td>
        </tr>
        <tr class="border-b">
          <td class="px-4 py-2 md:w-1/2">Profesión</td>
          <td class="px-4 py-2 md:w-1/2">{{ employee.profession }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatSimpleDate } from '@/utils/formatters';

export default {
  name: "General",
  props: ["employee"],

  methods: {
    formatSimpleDate,
  },
}
</script>
