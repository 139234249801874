<template>
  <div
    class="overflow-hidden rounded-xl divide-y divide-gray-200 dark:divide-gray-800 ring-1 ring-gray-200 dark:ring-gray-800 shadow dark:bg-gray-900 relative group flex flex-col hover:ring-2 hover:ring-primary-500 dark:hover:ring-primary-400 hover:bg-gray-100/50 dark:hover:bg-gray-900/50 h-full"
    :class="setColorBackground(object)"
  >
    <div class="flex-1 px-4 py-5 sm:p-6">
      <div class="mb-6 flex">
        <span
          class="relative inline-flex items-center justify-center flex-shrink-0 rounded-lg h-12 w-12 text-lg"
        >
          <slot name="icon"></slot>
        </span>
      </div>
      <p
        class="text-gray-900 dark:text-white text-base font-semibold truncate flex items-center gap-1.5"
      >
        {{ title }}
        <span
          class="i-ph-medal-duotone h-4 w-4 text-primary pointer-events-none"
        ></span>
      </p>
      <p class="text-[15px] text-gray-500 dark:text-gray-400 mt-1">
        <span class="line-clamp-2"
          >{{ description }}</span
        >
      </p>
      <br />
      <p v-if="object.state == 'success'">
        <span v-if="object.end" class="line-clamp-2 flex gap-2 dark:text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 flex-shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
            />
          </svg>
          <span
            >Última Sincronización
            {{ formatDateTimeToLongFormat(object.end) }}</span
          >
        </span>
        <span v-else>{{ object.state }}</span>
      </p>

      <p v-if="object.state == 'success'">
        <span class="flex gap-2 dark:text-gray-300">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-5 h-5 flex-shrink-0"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
            />
          </svg>
          Tiempo ejecución
          {{ calcularTiempoTranscurrido(object.start, object.end) }}
        </span>
      </p>

      <p v-if="object.state == 'init'">
        Empezando a sincronizar la información de tu empresa. 🚀
      </p>

      <p v-if="object.state == 'running'">
        Verificando cada <b>{{ contador }}</b> segundo(s) para asegurarnos de
        que tu tarea se complete con éxito. ⏳🔧
      </p>

      <div
        class="lg:inline-flex absolute top-6 right-6 transition gap-2 flex items-center"
        :class="statusColor(object.state)"
      >
        <span class="text-xs">{{ setState(object.state) }}</span>
        <svg
          v-if="object.state == 'success'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9 12.75 11.25 15 15 9.75m-3-7.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285Z"
          />
        </svg>
        <svg
          v-if="object.state == 'running'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6 rotating-icon"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
          />
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
          />
        </svg>
        <svg
          v-if="object.state == 'error'"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 9v3.75m0-10.036A11.959 11.959 0 0 1 3.598 6 11.99 11.99 0 0 0 3 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.25-8.25-3.286Zm0 13.036h.008v.008H12v-.008Z"
          />
        </svg>
      </div>
    </div>

    <div
      class="bg-gray-100/50 dark:bg-gray-800/50 px-4 py-4 sm:px-6"
      v-if="object.state == 'success' || object.state == 'error'"
    >
      <div
        class="flex items-center justify-between gap-3 -my-1 text-gray-600 dark:text-gray-300"
      >
        <div class="relative inline-flex">
          <a
            @click.prevent="
              updateCron(
                object.company_id,
                object.account,
                '/company/update_cron_'+ cronType
              )
            "
            class="dark:hover:text-white text-sm font-medium flex gap-2"
          >
            Sincronizar ahora
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  formatDateTimeToLongFormat,
} from "@/utils/formatters.js";

export default {
  props: {
    object: {
      type: Object,
      required: true,
    },
    cronType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      contador: 5,
      intervalo: null,
    };
  },
  watch: {
    'object.state': function(newVal, oldVal) {
      if (newVal == 'running'){
        this.initCount();
      } else if (newVal != 'success' && newVal != 'error' && newVal != undefined) {
        console.log('Iniciar contador watch', oldVal, newVal);
        this.initCount();
      }
    }
  },
  created() {
    if (this.object.state != 'success' && this.object.state != 'error' && this.object.state != undefined) {
      console.log('Iniciar contador created', this.object.state);
      this.initCount();
    }
  },
  methods: {
    formatDateTimeToLongFormat,
    statusColor(status) {
      if (status == "success") {
        return "text-green-500 dark:text-green-400";
      } else if (status == "running") {
        return "text-blue-500 dark:text-blue-400";
      } else if (status == "error") {
        return "text-red-500 dark:text-red-400";
      } else {
        return "text-gray-500 dark:text-gray-400";
      }
    },
    setColorBackground(object){
      if(!object.id) {
        return "bg-gray-100/50 dark:bg-gray-800/50";
      } else {
        return "bg-white dark:bg-gray-900";
      }
    },
    setState(status) {
      if (status == "success") {
        return "sincronizado";
      } else if (status == "running") {
        return "en progreso";
      } else if (status == "error") {
        return "error";
      } else {
        return "no configurado";
      }
    },
    updateCron(company_id, account, endpoint) {
      let app = this;
      this.$swal
        .fire({
          title: "¿Estas seguro?",
          text: "Vas a sincronizar toda la información del servicio de impuestos internos.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sincronizar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Actualizado",
              "Tu compañía ha sido sincronizado.",
              "success"
            );
            app.initCount();
            app.axios.post(endpoint, {
              company_id: company_id,
              bank_bill_id: account,
            }).then(() => {
              app.$emit("reload");
            });
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "Tu compañía no ha sido sincronizado.",
              "error"
            );
          }
        });
    },
    initCount() {
      this.$emit("reload");
      this.timeout = setTimeout(() => {
        if (this.contador > 0) {
          this.contador--;
          this.initCount();
        } else {
          this.contador = 5;
          this.$emit("reload");
          if (this.object.state == 'running') {
            this.initCount();
          }
        }
      }, 1500); // Cambia 2000 según la velocidad deseada
    },
    calcularTiempoTranscurrido(start, end) {
      // Parsear las fechas
      const fechaInicio = new Date(start);
      const fechaFin = new Date(end);

      // Calcular la diferencia en milisegundos
      const diferenciaEnMilisegundos = fechaFin - fechaInicio;

      // Obtener segundos, minutos y horas
      const segundos = Math.floor((diferenciaEnMilisegundos / 1000) % 60);
      const minutos = Math.floor((diferenciaEnMilisegundos / (1000 * 60)) % 60);
      const horas = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60));

      // Crear una cadena legible
      let resultado = "";
      if (horas > 0) {
        resultado += `${horas} hora(s) `;
      }
      if (minutos > 0) {
        resultado += `${minutos} minuto(s) `;
      }
      if (segundos > 0) {
        resultado += `${segundos} segundo(s)`;
      }

      return resultado.trim();
    }
  }
}

</script>


<style scoped>
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rotating-icon {
  animation: rotate 2s linear infinite;
}
</style>
