<template>
  <div class="py-2 mx-6" :class="{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }">
    <div class="flex flex-wrap items-center">
      <div class="flex flex-row justify-between w-full"></div>
        <h1 class="flex-auto text-xl font-semibold">Todos los Socios</h1>
        <a @click.prevent="createButton" class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2">
          <span class="hidden sm:block">
            Agregar Socio
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </a>
      </div>
      <div class="filter w-2/12 pb-4">
        <input
          type="text"
          class="
            appearance-none
            block
            w-full
            bg-grey-lighter
            text-grey-darker
            border border-grey-lighter
            py-3
            px-4
          "
          placeholder="Buscar Socios "
          @input="getAllPartners"
          v-model="query"
        />
      </div>
    <Add
      v-if="showModal"
      @close="showModal = false"
      @getAllPartners="getAllPartners"
      :id="id"
      :action="action"
    ></Add>
    <BeeGridTable
      :serverSide="true"
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showSummary="false"
      :showFilter="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="partners"
      :loading="loading"
    >
      <BeeColumn field="name" title="Socio" type="text">
        <template slot-scope="{ row }">
          <a
            href=""
            @click.prevent="
              goTo('Partners', {
                partner_id: row.id,
              })
            "
          >
            {{ row.names }} {{row.last_name}} {{row.mothers_last_name}}
          </a>
        </template>
      </BeeColumn>
      <BeeColumn field="amount_receivable" title="Participacion en la Empresa" type="text">
        <template slot-scope="{ row }">
          {{ formatNumber(row.equity_participation) }} %

        </template>
      </BeeColumn>



      <BeeColumn field="editar" title="Opciones" type="text" :width="120">
        <template slot-scope="{ row }">
          <div class="flex gap-5">
            <a v-on:click="editFunction(row.id)">
              <Edit />
            </a>
            <a v-on:click="deleteFunction(row.id)">
              <Times />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatNumber } from "@/utils/formatters";
import routerMixin from "@/mixins/routerMixin";
import Edit from '@/components/Icons/Edit.vue';
import Times from '@/components/Icons/Times.vue';

import Add from "./Add";
export default {
  name: "AllPartners",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      id: null,
      action: null,
      showModal: false,
      sale_total: 0,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      columns: [],
      partners: [],
      attribute_sorting: null,
      sorting: null,
      loading: true,
      query: null
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.getAllPartners();
    },
  },
  components: {
    Add,
    Times,
    Edit
  },
  mounted() {
    this.getAllPartners();
  },
  methods: {
    formatNumber,
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      console.log(sorting);
      // this.page = offset;
      if(sortInfos.length > 0) {
        let _nameSorting = []
        let _sorting = []

        _nameSorting = sortInfos.map(function(value) {
          return value.field;
        });

        _sorting = sortInfos.map(function(value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      this.current_page = offset;
      this.per_page = size;
      this.getAllPartners();
      // request api example
      // axios
      //   .post("yourDomain/yourApiRoute", {
      //     sorting,
      //     dynamicFilterInfo: getFilterCondition(filterInfos),
      //     count: size,
      //     page: offset,
      //   })
      //   .then((res) => {
      //     this.data = res.data.items; // table data
      //     this.total = res.data.total; // total data count
      //     this.page = res.data.page; // current page offset from server
      //     this.filterTotal = res.data.filteredTotal; //total filtered data count
      //   });
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    deleteFunction(id) {
      this.$swal
        .fire({
          title: "Estas seguro?",
          text: "Se eliminara el socio",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminalo",
          cancelButtonText: "No, conservalo",
        })
        .then((result) => {
          if (result.value) {
            this.$swal.fire(
              "Eliminado",
              "El socio ha sido eliminado",
              "success"
            );
            this.axios
              .delete(`partners/${id}`)
              .then(() => this.getAllPartners());
          } else if (result.dismiss === this.$swal.DismissReason.cancel) {
            this.$swal.fire(
              "Cancelado",
              "El socio no ha sido eliminado",
              "error"
            );
          }
        });
    },
    getAllPartners() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/partners", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query
          },
        })
        .then((response) => {
          console.log(response)
          app.partners      = response.data.data;
          app.filterTotal  = response.data.total_items; //total filtered data count
          app.total        = response.data.per_page; // total data count
          app.page         = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize     = response.data.per_page;
          app.pageCount    = response.data.total_pages;
          this.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
