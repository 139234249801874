<template>
  <div
    class="py-2 mx-6"
    :class="{
      widthVal: this.booleanSlider,
      widthValidation: !this.booleanSlider,
    }"
  >
    <div class="flex flex-wrap items-center">
      <h1 class="flex-auto text-lg sm:text-xl font-semibold dark:text-white">
        Todos los Clientes
      </h1>

      <div class="flex items-center gap-2">
        <div class="flex flex-row items-center">
          <input
            class="hidden"
            ref="file_excel"
            id="file_excel"
            @change="selectedFile"
            type="file"
            name="file"
          />
          <!-- <a
            @click="$refs.file_excel.click()"
            class="px-1 dark:text-white dark:hover:text-cian-dark"
          >
            Importar
          </a> -->
          <div class="flex flex-col">
            <p>{{ excelName }}</p>
            <div class="flex gap-2">
              <a v-if="excelName" v-on:click.prevent="upload()">Enviar</a>
              <a v-if="excelName" v-on:click.prevent="eliminateFile()"
                >Cancelar</a
              >
            </div>
          </div>
        </div>
        <button @click.prevent="createButton" class="button-cymasuite">
          <span class="hidden sm:block">
            Agregar Cliente
          </span>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
          </svg>
        </button>
      </div>
    </div>
    <div>
      <div class="filter sm:w-2/12 w-full pb-4 mt-2">
        <input
          type="text"
          class="input-text"
          placeholder="Buscar Clientes"
          @input="getAllClients"
          v-model="query"
        />
      </div>
    </div>
    <Add
      v-if="showModal"
      @close="showModal = false"
      @getAllClients="getAllClients"
      :id="id"
      :action="action"
    ></Add>
    <div class="flex gap-2 pb-4">
      <button class="flex items-center gap-2 text-gray-500 hover:text-light-blue dark:text-white dark:hover:text-cian-500 focus:outline-none" v-on:click.prevent="exportIn('excel')">
        <svg stroke="currentColor" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="15px" height="15px">
          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z"/>
        </svg>
        Exportar
      </button>
      <!-- o <a v-on:click.prevent="exportIn('pdf')">Pdf</a> -->
    </div>
    <BeeGridTable
      :serverSide="true"
      stripe
      border
      :filterTotal="filterTotal"
      :total="total"
      :page="page"
      :pageSize="pageSize"
      :pageCount="pageCount"
      :showSummary="false"
      :showFilter="false"
      :columns="columns"
      :fetchMethod="fetchMethod"
      :data="clients"
      :loading="loading"
    >
      <BeeColumn field="clients.name" title="Cliente" type="text" :sortable="true" :width="400" :resizable="true"
      >
        <template slot-scope="{ row }">
          <a
            href=""
            class="dark:text-cian-dark dark:hover:text-white uppercase"
            @click.prevent="
              goTo('Clients', {
                client_id: row.id,
              })
            "
          >
            {{ row.name }}
          </a>
        </template>
      </BeeColumn>

      <BeeColumn field="rut" title="Rut" type="text"  :width="200" :resizable="true"
      >
        <template slot-scope="{ row }">
          <p>
            {{ formatRut(row.rut) }}
          </p>
        </template>
      </BeeColumn>

      <BeeColumn field="balance_receivable" title="Por Cobrar" type="text" :sortable="true" :width="180" :resizable="true">
        <template slot-scope="{ row }">
          $ {{ formatNumber(row.balance_receivable) }}
        </template>
      </BeeColumn>

      <BeeColumn field="number_of_movements" title="Nº Documentos" type="text" :sortable="true" :width="180" :resizable="true">
        <template slot-scope="{ row }">
          Nº {{ formatNumber(row.number_of_movements) }}
        </template>
      </BeeColumn>

      <BeeColumn field="percentage_receivable" title="Deuda" type="text" :sortable="true" :width="180" :resizable="true">
        <template slot-scope="{ row }">
          {{ formatPercentage(row.percentage_receivable) }} %
        </template>
      </BeeColumn>

      <BeeColumn field="accounts.name" title="Cuenta Predeterminada" type="text" :sortable="true" :width="250" :resizable="true">
        <template slot-scope="{ row }">
          <div v-if="row && row.account" class="text-xs uppercase">{{ row.account.name }}</div>
        </template>
      </BeeColumn>

      <BeeColumn field="cost_centers.name" title="Centro De Costo Predeterminada" type="text" :sortable="true" :width="250" :resizable="true">
        <template slot-scope="{ row }">
          <div v-if="row && row.cost_center" class="text-xs uppercase">{{ row.cost_center.name }}</div>
        </template>
      </BeeColumn>

      <BeeColumn field="branch_offices.name" title="Sucursal Predeterminada" type="text" :sortable="true" :width="220" :resizable="true">
        <template slot-scope="{ row }">
          <div v-if="row && row.branch_office" class="text-xs uppercase">{{ row.branch_office.name }}</div>
        </template>
      </BeeColumn>

      <BeeColumn  field="editar" title="Opciones" type="text" :width="120">
        <template slot-scope="{ row }">
          <div class="flex items-center">
            <a v-on:click="editFunction(row.id)" class="pl-1 dark:text-cian-dark dark:hover:text-white">
              <Edit />
            </a>
          </div>
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import { formatRut, formatNumber, formatPercentage } from '@/utils/formatters'
import routerMixin from '@/mixins/routerMixin';
import Edit from '@/components/Icons/Edit.vue';
import Add from "./Add";
export default {
  name: "AllClients",
  props: {},
  mixins: [routerMixin],
  data() {
    return {
      excelName: '',
      showFileInput: false,
      uploadFile: null,
      id: null,
      action: null,
      showModal: false,
      sale_total: 0,
      filterTotal: 0,
      page: 1,
      total: 0,
      pageCount: 0,
      pageSize: 0,
      per_page: 10,
      current_page: 1,
      columns: [],
      clients: [],
      attribute_sorting: null,
      sorting: null,
      loading: true,
      query: null,
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
    booleanSlider() {
      return this.$store.state.booleanSlider;
    },
  },
  watch: {
    company_id() {
      this.getAllClients();
    },
  },
  components: {
    Add,
    Edit,
  },
  mounted() {
    this.getAllClients();
  },
  methods: {
    formatRut,
    formatNumber,
    formatPercentage,
    exportIn(file) {
      const params = {
        company_id: this.company_id,
      };
      const baseUrl = "https://api.cymasuite.com/clients-" + file;
      // const baseUrl = "http://localhost:3000/clients-" + file;
      const url = baseUrl + "?company_id=" + params.company_id;
      window.open(url, "_blank");
    },
    eliminateFile() {
      this.uploadFile = null;
      this.excelName = "";
    },
    selectedFile(e) {
      //Save the information of the selected File
      e.preventDefault();
      let files = e.target.files;
      this.uploadFile = files[0];
      this.excelName = files[0].name;
    },
    upload() {
      //POST File using FormData
      let formData = new FormData();
      formData.append("company_id", this.company_id);
      formData.append("file", this.uploadFile);
      let config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.axios
        .post("import-clients-excel", formData, config)
        .then((response) => {
          console.log(response);
          this.getAllClients();
          this.uploadFile = null;
          this.excelName = "";
          this.showFileInput = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchMethod(sortInfos, filterInfos, offset, size) {
      // print sort column infomations、filter column infomations
      console.log(sortInfos, filterInfos, offset, size);
      let sorting = "";
      for (let i = 0; i < sortInfos.length; i++) {
        sorting += sortInfos[i].field + " " + sortInfos[i].sortType;
        if (i !== sortInfos.length - 1) {
          sorting += ",";
        }
      }
      // this.page = offset;
      if (sortInfos.length > 0) {
        let _nameSorting = [];
        let _sorting = [];

        _nameSorting = sortInfos.map(function (value) {
          return value.field;
        });

        _sorting = sortInfos.map(function (value) {
          return value.sortType;
        });
        this.sorting = _sorting.toString();
        this.nameSorting = _nameSorting.toString();
      }
      console.log(sorting);
      this.current_page = offset;
      this.per_page = size;
      this.getAllClients();
      // request api example
    },
    createButton() {
      this.showModal = true;
      this.id = null;
      this.action = "create";
    },
    editFunction(id) {
      this.showModal = true;
      this.id = id;
      this.action = "update";
    },
    getAllClients() {
      this.loading = true;
      let app = this;
      this.axios
        .get("/clients", {
          params: {
            company_id: app.company_id,
            page: app.current_page, // will_paginate
            per_page: app.per_page,
            attribute_sorting: app.nameSorting,
            sorting: app.sorting,
            query: app.query
          },
        })
        .then((response) => {
          app.clients      = response.data.data;
          app.filterTotal  = response.data.total_items; //total filtered data count
          app.total        = response.data.per_page; // total data count
          app.page         = response.data.current_page; // current page offset from server
          app.current_page = response.data.current_page;
          app.pageSize     = response.data.per_page;
          app.pageCount    = response.data.total_pages;
          app.loading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
