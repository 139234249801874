<template  >
  <div class="py-2 mx-6">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold dark:text-white">Indicadores financieros</h1>
      <div class="flex items-center gap-4">
        <div class="filter">
          <v-select
            class="style-chooser custom-border"
            v-model="exchange_rate"
            :options="options_exchange_rate"
            :reduce="(type) => type.value"
            label="name"
          ></v-select>
        </div>
        <div class="filter">
          <date-picker
            input-class="xmx-input"
            :style="{
              width: '100%',
              'border-radius': '1px',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.50rem',
              'padding-bottom': '0.50rem',
            }"
            v-model="dateRange"
            type="date"
            format='DD-MM-YYYY'
            range
            placeholder="Seleccionar un rango de Fecha"
            v-on:input="getCurrencies"
          ></date-picker>
        </div>
      </div>
    </div>
    <br>
    <BeeGridTable
      border
      height="560"
      :showFilter="false"
      :showSummary="false"
      :columns="columns"
      :data="currencies"
      :loading="loading"
    >
      <BeeColumn field="name" title="Nombre" type="text">
        <template slot-scope="{ row }">
          {{ row.name }}
        </template>
      </BeeColumn>

      <BeeColumn field="date" title="Fecha" type="date">
        <template slot-scope="{ row }">
          {{ row.date }}
        </template>
      </BeeColumn>

      <BeeColumn field="price" title="Precio" type="date">
        <template slot-scope="{ row }">
          {{ row.price }}
        </template>
      </BeeColumn>
    </BeeGridTable>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
export default {
  name: "Currencie",
  props: {},
  data() {
    return {
      currencies: [],
      columns: [],
      options_exchange_rate: [
        { value: null, name: 'Todos' },
        { value: "usd", name: 'Dolares' },
        { value: "euro", name: 'Euros' },
        { value: "uf", name: 'UF' },
        { value: "utm", name: 'UTM' },
      ],
      loading: true,
    };
  },
  components: {
    DatePicker
  },
  computed: {
    dateRange: {
      get(){
        return this.$store.getters.getDateRange;
      },
      set(newValue){
        this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
      }
    },
    exchange_rate: {
      get(){
        return this.$store.state.exchange_rate;
      },
      set(newValue){
        this.$store.commit('addExchangeRate', newValue);
      }
    },
  },
  mounted() {
    this.getCurrencies();
  },
  watch: {
    exchange_rate() {
      this.getCurrencies()
    },
  },
  methods: {
    getCurrencies() {
      let app = this;
      app.loading = true;
      this.axios.get("/currencies", {
        params: {
          name: app.exchange_rate,
          from: app.dateRange[0],
          to: app.dateRange[1],
        },
      }).then((response) => {
        app.currencies = response.data.data;
        app.loading = false;
      });
    },
  },
};
</script>