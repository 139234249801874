<template>

  <!--Code Block for gray tooltip starts-->
  <div class="relative">
    <div :class="{'text-light-blue': isOpen}" @mouseenter="open" @mouseleave="close">
      <slot name="icon"></slot>
    </div>
    <div
      v-if="isOpen"
      role="tooltip"
      class="eo opacity-0 max-w-xs -mt-6 ml-8 absolute left-0 transition-opacity duration-500 ease-in-out overflow-y-auto bg-black text-white sm:text-sm text-xs leading-4 text-center font-bold shadow-lg p-2 rounded-md z-30"
      :class="[{ 'opacity-100 pointer-events-none fade-in': isOpen }, additionalClass]"
      @mouseenter="open"
      @mouseleave="close"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: "WithStepsAlternate",
    props: {
      additionalClass: {
        type: String,
        default: 'whitespace-nowrap',
      },
    },
    data() {
      return {
        isOpen: false,
      }
    },
    methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },

    },
};
</script>
<style scoped>

.fade-in {
  animation: fade 0.7s;
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* width */
.eo::-webkit-scrollbar {
  width: .1875rem;
}

/* Track */
.dark .eo::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #e4e4f0;
}

/* Handle */
.dark .eo::-webkit-scrollbar-thumb {
  background: rgb(105, 103, 107);
  opacity: 0.5;
  border-radius: 100vh;
}

.eo::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: rgb(105, 103, 107);
}

/* Handle */
.eo::-webkit-scrollbar-thumb {
  background: #e4e4f0;
  opacity: 0.5;
  border-radius: 100vh;
}

/* Handle on hover */
.eo::-webkit-scrollbar-thumb:hover {
  opacity: 0.5;
  background: black;
}

</style>
