`<template>
  <div class="">
    <transition name="modal">
      <div class="modal-mask flex md:justify-center md:items-center">
        <div class="w-screen h-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="w-screen h-screen px-4 py-5 overflow-auto bg-white dark:bg-background-dark md:h-auto md:max-h-screen md:w-full md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto mx-4">
                <h1
                  class="font-semibold text-grey-darker dark:text-white py-8 text-4xl"
                  v-if="action === 'create'"
                >
                  Agregar Trabajador
                </h1>
                <h1 class="font-semibold text-grey-darker dark:text-white py-8 text-4xl" v-else>
                  Actualizar Trabajador
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a
                    class="text-6xl dark:hover:text-cian-dark"
                    @click.prevent="$emit('close')"
                  >
                    &times;
                </a>
              </div>
            </div>
            <div class="">
              <slot name="body">
                <form class="h-full">
                  <div class="px-5 dark:text-white font-semibold">
                    <h2 class="text-xl font-semibold">{{ obj.name }}</h2>
                    <h2 class="text-xl font-semibold text-gray-500">{{ obj.rut }}</h2>
                  </div>
                  <div v-if="action === 'create'">
                    <div class="grid grid-cols-3 grid-rows-2 h-40">
                      <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="label-cymasuite"
                          >RUT</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="obj.rut"
                        />
                      </div>

                      <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="label-cymasuite"
                          >Nombre</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="obj.name"
                        />
                      </div>
                       <div class="flex flex-col h-full w-full px-4 mx-2">
                        <label
                          class="label-cymasuite"
                          >Apellido</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="obj.last_name"
                        />
                      </div>
                      
                      <div class="flex flex-col h-full w-full px-4 mx-2 mb-2">
                        <label
                          class="label-cymasuite"
                          >Apellido Materno</label
                        >
                        <input
                          type="text"
                          class="input-text"
                          v-model="obj.mothers_last_name"
                        />
                      </div>
                    </div>
                  </div>
                  <h3 class="mx-6 uppercase py-4 pt-2 label-cymasuite">
                    Acuerdo comercial
                  </h3>
                  <div class="grid grid-cols-3 grid-rows-2 h-24">
                    <div class="flex flex-col w-full h-full px-4 mx-2 mb-2">
                      <label
                        class="label-cymasuite"
                        >Días de caducidad</label
                      >
                      <input
                        type="text"
                        class="input-text"
                        v-model="obj.expiration_date_days"
                      />
                    </div>
                  </div>
                  <h3 class="mx-6 uppercase py-4 pt-2 label-cymasuite">
                    Contacto
                  </h3>
                  <div class="grid grid-cols-3 grid-rows-2 h-40">
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                      >
                        Nombre
                      </label>
                      <input
                        class="input-text"
                        type="text"
                      />
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                      >
                        Cargo
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.position"
                      />
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                      >
                        Teléfono
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.phone"
                      />
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                      >
                        Giro
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.money_order"
                      />
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                      >
                        Correo Electrónico
                      </label>
                      <input
                        class="input-text"
                        type="text"
                        v-model="obj.contact"
                      />
                    </div>
                  </div>
                  <h3 class="mx-6 py-4 pt-4 label-cymasuite">
                    Clasificación Predeterminada
                  </h3>
                  <div class="grid grid-cols-3">
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                        >Plan de cuenta</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="accounts[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.account_id"
                        placeholder="Plan de Cuentas"
                      ></v-select>
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                        >Sucursal</label
                      >
                      <v-select
                        class="style-chooser custom-border w-full"
                        :options="branch_offices[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.branch_office_id"
                        placeholder="Sucursal"
                      ></v-select>
                    </div>
                    <div class="flex flex-col h-full w-full px-4 mx-2">
                      <label
                        class="label-cymasuite"
                        >Centro de Costos</label
                      >
                      <v-select
                        class="style-chooser custom-border"
                        :options="cost_centers[0]"
                        :reduce="(element) => element.id"
                        label="name"
                        v-model="obj.cost_center_id"
                        placeholder="Centro de Costos"
                      ></v-select>
                    </div>
                  </div>
                </form>
              </slot>
            </div>
            <div class="modal-footer flex justify-center pr-5 pb-3">
              <slot name="footer">
                <div class="mt-8 w-5/5">
                  <button
                    class="
                      bg-black
                      w-full
                      hover:bg-white
                      hover:text-black
                      border
                      hover:border-black
                      text-white
                      font-bold
                      p-2
                      px-4
                      rounded-full
                      dark:bg-cian-dark
                      dark:border-cian-dark
                    "
                    @click.prevent="submit"
                  >
                    Guardar Información
                  </button>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
export default {
  name: "Add",
  props: {
    id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      branch_offices: [],
      cost_centers: [],
      accounts: [],
      obj: {
        rut: null,
        name: null,
        business_name: null,
        company_id: null,
        contact: null,
        branch_office_id: null,
        cost_center_id: null,
        date_of_birth: null,
        expiration_date_days: null,
        total_charged: null,
        total_pending_collection: null,
        account_id: null,
        position: null,
        phone: null,
        money_order: null,
        last_name: null,
        mothers_last_name: null
      },
    };
  },
  components: {},
  created() {
    this.getOption("/branch_offices", this.branch_offices);
    this.getOption("/cost_centers", this.cost_centers);
    this.getOption("/select_movement_sale", this.accounts);
  },
  mounted() {
    this.getService();
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  methods: {
    getService() {
      this.axios
        .get("employees/" + this.id)
        .then((response) => {
          this.obj = response.data;
        })
        .catch((e) => console.log(e));
    },
    getOption(route, element) {
      this.axios
        .get(route, {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          element.push(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    update() {
      this.axios
        .patch(`employees/${this.id}`, this.obj)
        .then(() => {
          this.$swal.fire(
            "Actualizado",
            "Ha sido actualizado con exito",
            "success"
          );
          this.$emit("getAllEmployees");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    create() {
      this.axios
        .post("employees", this.obj)
        .then(() => {
          this.$swal.fire("Creado", "Ha sido creado con exito", "success");
          this.$emit("getAllEmployees");
          this.$emit("close");
        })
        .catch(() => {
          this.$swal.fire("Oops...", "Algo ha salido mal", "error");
        });
    },
    submit: function () {
      this.obj.company_id = this.company_id;
      this.action === "create" ? this.create() : this.update();
    },
  },
};
</script>
<style scoped>
</style>`