<template>
  <div class="relative" @mouseenter="open" @mouseleave="close">
    <div class="pr-10 cursor-pointer">
      <svg
        aria-haspopup="true"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        :stroke="!isOpen ? '#bdc5cf' : '#2d8cf0'"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" />
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12.01" y2="8" />
        <polyline points="11 12 12 12 12 16 13 16" />
      </svg>
    </div>
    <div
      role="tooltip"
      class="absolute left-0 z-30 w-64 p-4 ml-8 -mt-6 overflow-y-auto text-gray-700 transition duration-150 ease-in-out bg-white rounded shadow-lg opacity-100 dark:shadow-lg-dark dark:bg-background-dark dark:text-white max-h-52 scroll-mod"
      v-if="isOpen"
    >
      <p class="pb-1 text-sm font-bold">{{ activities.name }}</p>
      <p class="pb-1 text-xs font-bold" v-if="activities.rut">
        {{ formatRut(activities.rut) }}
      </p>
      <div
        class="pb-2 text-xs leading-4"
        v-for="(activity, i) in activities.activities"
        :key="i"
      >
        <b>{{ activity.code }}</b> {{ activity.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatRut } from "@/utils/formatters"

export default {
  name: "WithStepsAlternate",
  props: {
    id: null,
    buy_id: null,
  },
  data() {
    return {
      activities: [],
      isOpen: false,
    }
  },
  methods: {
    formatRut,
    open() {
      this.isOpen = true;
      if (this.activities.length == 0) {
        this.getVendor();
      }
    },
    close() {
      this.isOpen = false;
    },
    getVendor() {
      let cur = this;
      this.axios
        .get("vendors/" + cur.id)
          .then((response) => {
            cur.activities = response.data;
          })
          .catch((e) => console.log(e));
    },
  },
};
</script>
