<template>
  <index title="Sucursales" route="/branch_offices"></index>
</template>
<script>
import index from '../../../../components/etc/crud/index'
export default {
  name:"BranchOffice",
  components: {
    index
  }
}
</script>