<template>
  <div>
    <transition name="modal">
      <div class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="bg-white w-screen h-screen py-5 px-4 md:w-full md:h-full md:py-4 md:px-10">
            <div class="flex">
              <div class="flex-auto">
                <h1 class="font-semibold text-grey-darker py-4 text-4xl">
                  {{ movementObj.business_name }}
                </h1>
              </div>
              <div class="flex justify-end">
                <a class="text-4xl" @click.prevent="$emit('close')">
                  &times;
                </a>
              </div>
            </div>
            <div class="" id="modalWidth">
              <slot name="body">
                <div class="bg-gray-100 modal-document-box rounded-lg" v-for="(document, index) in documents" v-bind:key="index">
                  <pre v-if="document['header']" class="p-4 text-xs">{{ document['header'] }}</pre>
                  <pre v-if="document['detail'].length > 0">{{ document['detail'] }}</pre>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>

export default {
  name: "modalDocument",
  props: {
    buy_id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      movementObj: {},
      documents: []
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {},
  mounted() {
    this.getBuy();
  },
  methods: {
    getBuy() {
      let cur = this;
        if(cur.buy_id) {
          this.axios.get('movements/' + cur.buy_id).then((res) => {
            cur.movementObj = res.data;
            cur.documents = res.data.documents;
          });
        }
      },
  },
};
</script>
<style>
.modal-document-box {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto; 
}
textarea:focus,
input:focus {
  outline: none;
}
</style>
