<template>
  <div class="py-2 mx-6" :class="{ widthVal: this.booleanSlider, widthValidation: !this.booleanSlider }">
    <div class="flex flex-wrap">
      <h1 class="flex-auto text-xl font-semibold">
        {{partner.names}} {{partner.last_name}} {{partner.mothers_last_name}} <span class="pl-4 text-gray-500">{{formatRut(partner.rut)}}</span>
      </h1>
      <p class="text-xl"></p>
      <div class="text-md font-semibold text-gray-500">
        <!-- <div>
          <date-picker
            input-class="xmx-input"
            v-model="dateRange"
            type="date"
            range
            placeholder="Selecciona un rango de Fecha"
            v-on:input="reload"
            :style="{
              border: '1px solid #e2e8f0',
              width: '100%',
              'border-radius': '2px',
              'padding-left': '0.55rem',
              'padding-right': '0.55rem',
              'padding-top': '0.70rem',
              'padding-bottom': '0.70rem',
              'border-radius': '0px'
            }"
          ></date-picker>
        </div> -->
      </div>
      <div class="w-full flex-none text-sm font-medium mt-2">
        <p class="text-sm tracking-normal" v-if="partner.contact">
          Contacto: {{partner.contact}}
        </p>
        <p class="text-sm tracking-normal">
          Dia de Incorporacion: {{formatSimpleDate(partner.date_of_incorporation)}}
        </p>
        <p class="text-sm tracking-normal">
          Participacion en la empresa: {{partner.equity_participation}} %
        </p>
      </div>
    </div>

    <div class="">
      <newSales
        v-if="showModal"
        @close="showModal = false"
        @getSales="getSales"
        :sale_id="sale_id"
        :action="action"
      ></newSales>
      <newMovement
        v-if="showMovement"
        @getSales="getSales"
        @close="closeFunction"
        :sale_id="sale_id"
      ></newMovement>
    </div>
  </div>
</template>

<script>
  import { formatRut, formatSimpleDate } from '@/utils/formatters'
  import newSales from "../sales/newSales";
  import newMovement from "../sales/newMovement"
  import "@/assets/stylesheets/switch.scss";
  export default {
    name: "Partners",
    props: {},
    data() {
      return {
        sales: [],
        current_page: 0,
        per_page: 0,
        total_pages: 0,
        total_items: 0,
        without_account: 0,
        partner_id: parseInt(this.$route.params.partner_id),
        columns: [],
        sale_total: 0,
        sale_total_bill: 0,
        sale_total_not_bill: 0,
        partner: [],
        showModal: false,
        showModalClient: false,
        showMovement: false,
        dataCollection: null,
        dataCollectionStacked: null,
        data_graph_sales: [],
        data_graph_buys: [],
        data_graph_sale_total_bill: [],
        data_graph_sale_total_not_bill: [],
        movement_state: null,
        status: [
          { value: null, name: 'Seleccionar estado' },
          { value: 3, name: 'No cobrado' },
          { value: 2, name: 'Cobrado' },
        ],
        category_movement: null,
        types: [
          { value: null, name: 'Seleccionar tipo de ingreso' },
          { value: 0, name: "Ventas con facturas" },
          { value: 1, name: "Otros ingresos" },
          { value: 2, name: "Ventas con boletas" }
        ],
        loading: true,
      };
    },
    computed: {
      dateRange: {
        get(){
          return this.$store.getters.getDateRange;
        },
        set(newValue){
          this.$store.commit('addDateRange', [newValue[0], newValue[1]]);
        }
      },
      company_id() {
        return this.$store.state.company_id;
      },
      booleanSlider() {
        return this.$store.state.booleanSlider;
      }
    },
    watch: {
      company_id(){
        this.getSales();
        this.getPartners();
      },
    },
    components: {
      // DatePicker,
      // BarChart,
      // StackedChart,
      // Add,
      newSales,
      newMovement
    },
    mounted() {
      this.getSales();
      this.getPartners();
      this.getStas();
      this.getMovementsSymmary();
    },
    methods: {
      formatRut,
      formatSimpleDate,
      reload() {
        this.getSales();
        this.getPartners();
        this.getStas();
        this.getMovementsSymmary();
      },
      editFunctionClient(id) {
        this.showModalClient = true;
        this.id = id;
        this.action = "update";
      },
      editFunction(id) {
        console.log("update",id)
        this.showModal = true;
        this.sale_id = id;
        this.action = "update";
      },
      closeFunction(){
        this.showMovement = false
      },
      updatePay(event,id) {
        console.log(event)
        this.showMovement = true
        this.sale_id = id
      },
      deleteFunction(id) {
        this.$swal
          .fire({
            title: "Estas seguro?",
            text: "No recuperarás este ingreso",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminalo",
            cancelButtonText: "No, conservalo",
          })
          .then((result) => {
            if (result.value) {
              this.$swal.fire(
                "Eliminado",
                "Tu ingreso ha sido eliminado",
                "success"
              );
              this.axios.delete(`movements/${id}`).then(() => this.getBuys());
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelado",
                "Tu ingreso no ha sido eliminado",
                "error"
              );
            }
          });
      },
      checkedFunction(status){
        if(status == "not_paid") return false
          return status === "bill_subscriber" || status === "bill"
      },
      fillData() {
        const app = this;
        this.dataCollection = {
          labels: this.labels,
          responsive: true,
          datasets: [
            {
              label: "Ventas",
              borderWidth: 1,
              stack: "ventas",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sales,
            },
            {
              label: "Compras",
              borderWidth: 1,
              stack: "compras",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_buys,
            },
          ],
        };
      },
      fillDataStacked(){
        const app = this;
        this.dataCollectionStacked = {
          labels: this.labels,
          datasets: [
            {
              label: "Pagado",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sale_total_bill
            },
            {
              label: "No Pagado",
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgba(255,99,132,1)",
              pointBorderColor: "#2554FF",
              data: app.data_graph_sale_total_not_bill
            }
          ]
        }
      },
      getPartners() {
        this.loading = true;
        let app = this;
        this.axios.get("/partners/" + app.partner_id, {
          params: {
            company_id: app.company_id,
          }
        },
        ).then((response) => {
          app.partner = response.data;
        });
      },
      getSales() {
        this.loading = true;
        let app = this;
        this.axios.get("/movements",{
          params: {
            type_movement: 'type_sale',
            company_id: app.company_id,
            client_id: app.client_id,
            category_movement: app.category_movement || [0, 1, 2],
            movement_status: app.movement_state,
            from: app.dateRange[0],
            to: app.dateRange[1],
          }
        },
        ).then((response) => {
          app.sales = response.data.data;
          app.current_page = response.data.current_page;
          app.per_page = response.data.per_page;
          app.total_pages = response.data.total_pages;
          app.total_items = response.data.total_items;
          app.without_account = response.data.without_account;
          app.sale_total = response.data.sale_total;
          app.sale_total_bill = response.data.sale_total_bill;
          app.sale_total_not_bill = response.data.sale_total_not_bill;
        });
      },
      getStas() {
        this.loading = true;
        let app = this;
        this.axios
        .get("/stats", {
          params: {
            company_id: app.company_id,
            from: app.dateRange[0],
            to: app.dateRange[1],
            client_id: app.client_id,
            per_page: 5,
          },
        })
        .then((response) => {
          this.total_items = response.data.total_items;
          this.labels = response.data.labels;
          this.data_graph_sales = response.data.data_graph_sales;
          this.data_graph_buys = response.data.data_graph_buys;
          this.data_graph_sale_total_bill = response.data.data_graph_sale_total_bill;
          this.data_graph_sale_total_not_bill = response.data.data_graph_sale_total_not_bill;
          this.fillData();
          this.fillDataStacked();
          this.loading = false;
        });
      },
      getMovementsSymmary() {
        this.axios
          .get("/stats-summary", {
            params: {
              company_id: this.company_id,
              from: this.dateRange[0],
              to: this.dateRange[1],
            },
          })
          .then((response) => {
            this.sale_total_bill = response.data.sale_total_bill;
            this.sale_total_not_bill = response.data.sale_total_not_bill;
            this.buy_total_paid = response.data.buy_total_paid;
            this.buy_total_not_paid = response.data.buy_total_not_paid;
          });
      },
    },
  };
</script>
<style scoped>
  .filter .style-chooser{
    min-width: 255px;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

