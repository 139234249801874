<template>
  <div
    class="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  >
    <div class="max-w-md w-full space-y-8 bg-white border border-gray p-4 pb-4">
      <div>
        <h2 class="text-3xl font-semibold text-gray-900">Cambiar contraseña</h2>
      </div>
      <form class="mt-8 space-y-6">
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <div class="mt-4">
              <label
                class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
              >
                Nueva Contraseña
              </label>
            </div>
            <div class="relative">
              <input
                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                :type="showNewPassword ? 'text' : 'password'"
                v-model="password"
              />
              <div class="eyeButton">
                <i
                  class="fas fa-eye"
                  @mousedown="showNewPassword = !showNewPassword"
                  @mouseup="showNewPassword = !showNewPassword"
                  @touchstart="showNewPassword = !showNewPassword"
                  @touchend="showNewPassword = !showNewPassword"
                  style="cursor: pointer"
                ></i>
              </div>
            </div>
          </div>
          <div>
            <div class="mt-4">
              <label
                class="block md:text-left uppercase tracking-wide text-grey-darker text-xs font-bold mb-1"
              >
                Confirmar Nueva Contraseña
              </label>
            </div>
            <div class="relative">
              <input
                class="appearance-none block w-full bg-grey-lighter text-grey-darker border border-grey-lighter rounded py-3 px-4"
                :type="showNewPasswordConfirm ? 'text' : 'password'"
                v-model="confirmPassword"
              />
              <div class="eyeButton">
                <i
                  class="fas fa-eye"
                  @mousedown="showNewPasswordConfirm = !showNewPasswordConfirm"
                  @mouseup="showNewPasswordConfirm = !showNewPasswordConfirm"
                  @touchstart="showNewPasswordConfirm = !showNewPasswordConfirm"
                  @touchend="showNewPasswordConfirm = !showNewPasswordConfirm"
                  style="cursor: pointer"
                ></i>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            type="button"
            class="group relative w-full flex justify-center py-2 px-4 border border-gray text-sm font-medium rounded-md text-white bg-black hover:bg-white hover:text-black"
            v-on:click="onSubmit()"
          >
            Cambiar Contraseña
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import routerMixin from "@/mixins/routerMixin";

export default {
  name: "forgetPassword",
  mixins: [routerMixin],
  data() {
    return {
      showNewPassword: false,
      showNewPasswordConfirm: false,
      password: "",
      confirmPassword: "",
      error: "",
      resetPasswordToken: null,
      handleError: false
    };
  },
  mounted() {
    this.resetPasswordToken = this.$route.query.reset_password_token
  },
  methods: {
    onSubmit() {
      const cur = this;
      console.log(cur.resetPasswordToken)
      cur.axios
        .put("auth/password", {
          password: cur.password,
          password_confirmation: cur.confirmPassword,
          reset_password_token: cur.resetPasswordToken,
        })
        .then(() => {
          cur.goTo("login");
        })
        .catch((e) => {
          cur.handleError = true;
          cur.error = e.response.data.errors.full_messages[0];
        });
    },
  },
};
</script>
