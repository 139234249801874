<template>
  <div>
    <transition name="modal">
      <div class="
          h-screen
          w-screen
          fixed
          top-0
          left-0
          bg-black bg-opacity-50
          flex
          justify-center
          items-center
          z-50
        " v-if="loading">
        <Loader />
      </div>
      <div v-else class="modal-mask flex md:justify-center md:items-center">
        <div class="h-screen w-screen"></div>
        <div class="absolute w-10/12">
          <div
            class="dark:bg-background-dark bg-white w-screen h-screen py-5 px-4 md:w-full md:max-h-screen md:h-auto overflow-auto md:py-4 md:px-10"
          >
            <div class="flex">
              <div class="flex-auto">
                <h1 class="font-semibold text-grey-darker dark:text-white py-8 lg:text-4xl text-3xl">
                  Versiones
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a class="text-6xl dark:hover:text-cian-dark" @click.prevent="$emit('close')">
                  &times;
                </a>
              </div>
            </div>

            <ol class="border-l border-gray-300">
              <li v-for="(version, i) in versions" :key="i">
                <div class="flex flex-start items-center pt-3">
                  <div class="bg-gray-300 w-2 h-2 rounded-full -ml-1 mr-3"></div>
                  <p class="text-gray-500 text-sm">
                    {{ formatterAttr(version.event) }} de <span class="font-semibold text-black dark:text-white"> {{ formatterObj(version.item_type) }} </span> - {{ formatSimpleDate(version.created_at) }} {{ formatHour(version.created_at) }}
                  </p>
                </div>
                <div class="mt-0.5 ml-4 mb-6">
                  <Author :id="parseInt(version.whodunnit)" />
                  <div v-for="(change, attribute) in version['object_changes']" :key="attribute">
                    <p
                      class="mb-2 text-gray-500"
                      v-if="attribute != 'updated_at'"
                    >Se modificó el atributo <span class="font-semibold text-black dark:text-white"> {{ attribute }} </span> de <del>{{ change[0] || 'Nulo' }}</del> a <span class="font-semibold text-black dark:text-white">{{ change[1] }}</span></p>
                  </div>
                </div>
              </li>
            </ol>
            <div class="flex justify-center gap-4 flex-wrap">
              <button @click="paginate(i)" class="rounded-full bg-gray-300 w-8 h-8" :class="{'text-white bg-blue-500': currentPage == i}" v-for="(i) in totalPages" :key="i + 'Page'">
                {{ i }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { formatSimpleDate } from "@/utils/formatters";
import Author from "./author.vue"
import Loader from "@/components/loader.vue";

export default {
  name: "modalVersions",
  components: {
   Author,
   Loader
  },
  props: {
    buy_id: { default: null },
    action: { default: null },
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 0,
      page: 1,
      perPage: 3,
      loading: true,
      whodunnit: {},
      versions: [],
    };
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  watch: {
    page(){
      this.getVersions()
    }
  },
  created() {
    this.getVersions();
  },
  methods: {
    formatSimpleDate,
    formatterObj(attr){
      let formatter = {
        'Movement':  "Movimiento",
        'AccountMovement': 'Asiento Contable'
      }
      return formatter[attr]
    },
    formatterAttr(attr){
      let formatter = {
        'description':  "Descripción",
        'update': 'Actualización',
        'create': 'Creación'
      }
      return formatter[attr]
    },
    formatHour(date) {
      return new Date(date).toLocaleTimeString("es-CL", { timeZone: "UTC" });
    },
    filterObject(object){
      let newObj = Object.keys(object)
      .filter(key => key != 'updated_at')
      .reduce((obj, key) => {
        obj[key] = object[key];
        return obj;
        }, {})
      console.log(newObj)
      return newObj
    },
    paginate(number){
      this.page = number;
    },
    getVersions() {
      this.loading = true
      let cur = this;
      if (cur.buy_id) {
        this.axios.get("movements/" + cur.buy_id + "/versions", { params: {
          page: this.page,
          per_page: this.perPage,
        }}).then((res) => {
          this.currentPage = res.data.current_page;
          this.totalPages = res.data.total_pages;
          this.versions = res.data.data;
          this.loading = false
        });
      }
    },
  },
};
</script>
<style>
.modal-document-box {
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
}
textarea:focus,
input:focus {
  outline: none;
}
.diff {
  overflow: auto;
}
.diff ul {
  background: #fff;
  overflow: auto;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;
}
.diff del,
.diff ins {
  display: block;
  text-decoration: none;
}
.diff li {
  padding: 0;
  display: table-row;
  margin: 0;
  height: 1em;
}
.diff li.ins {
  background: #dfd;
  color: #080;
}
.diff li.del {
  background: #fee;
  color: #b00;
}
.diff li:hover {
  background: #ffc;
}

.diff del,
.diff ins,
.diff span {
  white-space: pre-wrap;
  font-family: courier;
}
.diff del strong {
  font-weight: normal;
  background: #fcc;
}
.diff ins strong {
  font-weight: normal;
  background: #9f9;
}
.diff li.diff-comment {
  display: none;
}
.diff li.diff-block-info {
  background: none repeat scroll 0 0 gray;
}
</style>
