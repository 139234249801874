<template>
  <div>
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Representates legales
    </h2>
    <div
      class="grid w-full grid-rows-2 gap-4 sm:grid-cols-2 lg:grid-cols-3"
      v-for="(representative, i) in company.representatives_attributes"
      :key="i"
    >
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Vigencia
        </label>
        <input
          class="input-text"
          type="text"
          placeholder="Vigencia"
          disabled
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Nombre</label
        >
        <input
          type="text"
          disabled
          class="input-text"
          placeholder="Nombre"
          v-model="representative.first_names"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Rut</label
        >
        <VueRut
          class="input-text"
          :rt="representative.rut"
          :dv="representative.dv"
          disabled
          @input_rut="representative.rut = $event"
          @input_dv="representative.dv = $event"
        />
        <!-- <input
          type="text"
          class="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter dark:bg-black-dark dark:border-black-dark"
          placeholder="rut"
          v-model="representative.rut"
        /> -->
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Correo electrónico</label
        >
        <input
          type="text"
          class="input-text"
          placeholder="Correo electrónico"
          v-model="representative.email"
        />
      </div>
      <div class="flex flex-col my-2">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Contacto</label
        >
        <input
          type="text"
          class="input-text"
          placeholder="Contacto"
          v-model="representative.phone"
        />
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue';
import VueRut from "@/components/etc/VueRut.vue"

export default {
  name: "CompanyLegalRepresentative",
  data() {
    return {
      loading: false,
    }
  },
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.$emit('updateTab', "LegalRepresentatives")
  },
  components: { VueRut, Spinner },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>
