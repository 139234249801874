<template>
  <div>
    <transition name="modal">
      <div class="flex modal-mask md:justify-center md:items-center">
        <div class="w-screen h-screen"></div>
        <div class="absolute w-11/12 xl:w-10/12">
          <div
            class="w-screen h-screen px-4 py-5 overflow-auto bg-white dark:bg-background-dark md:w-full md:h-full md:py-4 md:px-10"
          >
            <div class="flex items-center">
              <div class="flex-auto">
                <h1
                  class="py-8 text-3xl font-semibold text-grey-darker dark:text-white lg:text-4xl"
                >
                  Clasificación Masiva
                </h1>
              </div>
              <div class="flex justify-end dark:text-white">
                <a
                  class="text-6xl dark:hover:text-cian-dark"
                  @click.prevent="$emit('close')"
                >
                  &times;
                </a>
              </div>
            </div>
            <div class="flex flex-row items-center gap-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <p
                class="text-lg text-center text-gray-500 sm:text-left"
                v-if="countClassified >= 0"
              >
                {{ countClassified }} movimientos clasficados serán
                sobreescritos.
              </p>
            </div>
            <div
              class="hidden lg:flex mt-12 gap-2"
            >
              <div class="filter">
                <h3
                  class="block text-xs font-bold tracking-wide uppercase md:text-left dark:text-white text-grey-darker"
                >
                  Sucursal
                </h3>
              </div>
              <div class="filter">
                <h3
                  class="block text-xs font-bold tracking-wide uppercase md:text-left dark:text-white text-grey-darker"
                >
                  Centro de costo
                </h3>
              </div>
              <div class="filter">
                <h3
                  class="block text-xs font-bold tracking-wide uppercase md:text-left dark:text-white text-grey-darker"
                >
                  Clasificación
                </h3>
              </div>
              <div class="mx-2 filter">
                <h3
                  class="block text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
                >
                  Porcentaje
                </h3>
              </div>
            </div>
            <div class="" id="modalWidth">
              <slot name="body">
                <form
                  class="flex gap-2 mt-4 lg:grid-cols-5 items-top"
                  v-for="(input, k) in $v.dataAcc.$each.$iter"
                  :key="k"
                  enctype="multipart/form-data"
                >
                  <div class="filter">
                    <v-select
                      class="style-chooser custom-border"
                      :options="branchOffices"
                      :reduce="(option) => option.id"
                      v-model="input.branch_office_id.$model"
                      label="name"
                      placeholder="Sucursal"
                    ></v-select>
                  </div>
                  <div class="filter">
                    <v-select
                      class="style-chooser custom-border"
                      :options="costCenter"
                      :reduce="(option) => option.id"
                      v-model="input.cost_center_id.$model"
                      label="name"
                      placeholder="Centro de Costos"
                    ></v-select>
                  </div>
                  <div class="filter">
                    <v-select
                      append-to-body
                      class="style-chooser custom-border"
                      :options="accounts"
                      :reduce="(account) => account.id"
                      label="fantasy_name"
                      v-model="input.account_id.$model"
                      placeholder="Plan de cuenta"
                    ></v-select>
                    <div class="text_error" v-if="input.account_id.$error">
                      Este campo es requerido
                    </div>
                  </div>
                  <div class="flex items-center gap-3 filter">
                    <range-slider
                      class="slider dark:bg-background-dark flex-grow"
                      :min="0"
                      :max="max"
                      @change="calculatePorcentaje($event, k)"
                      v-model="input.percentage.$model"
                      :disabled="k == 0 || input.disable.$model"
                    >
                    </range-slider>
                    <p
                      class="block appearance-none bg-grey-lighter dark:text-white"
                    >
                      {{ input.percentage.$model }} %
                    </p>
                  </div>
                  <div class="flex items-center">
                    <a
                      v-if="k == dataAcc.length - 1 && k != 0"
                      class="mx-2 dark:text-cian-dark dark:hover:text-white"
                      title="Eliminar"
                      @click.prevent="
                        input.destroy_manual.$model = true
                        remove(k)
                      "
                    >
                      <Trash />
                    </a>
                  </div>
                </form>
                <button
                  @click.prevent="add()"
                  class="mt-4 my-2 focus:outline-none hover:text-cian-400 dark:text-cian-dark dark:hover:text-white"
                >
                  Dividir en más
                </button>
              </slot>
            </div>

            <div class="flex justify-end py-3 mx-4 modal-footer">
              <slot name="footer">
                <div class="">
                  <button
                    v-if="!submitValidation"
                    :disabled="submitValidation"
                    @click.prevent="massClasification"
                    class="w-full p-3 mx-4 font-bold text-white bg-black border rounded-full hover:bg-white hover:text-black hover:border-black dark:bg-cian-dark dark:border-cian-dark"
                  >
                    Clasificar
                  </button>
                  <div
                    class="flex items-center justify-center px-4 py-2 mt-4"
                    v-else
                  >
                    <Spinner2 customClass="w-6 h-6" />
                  </div>
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import RangeSlider from "vue-range-slider"
import "vue-range-slider/dist/vue-range-slider.css"
import { required } from "vuelidate/lib/validators"
import Spinner2 from "@/components/Spinner2.vue"
export default {
  name: "massClasification",
  props: {
    selected_checks: { default: null },
    action: { default: null },
  },
  data() {
    return {
      submitValidation: false,
      inputActive: false,
      disableTypePurchase: true,
      total: 100,
      max: 100,
      lines_sum: 0,
      first_line: 100,
      countClassified: 0,
      dataAcc: [
        {
          company_id: this.$store.state.company_id,
          account_id: null,
          branch_office_id: null,
          cost_center_id: null,
          percentage: 100,
          destroy_manual: null,
          disable: false,
        },
      ],
      accounts: [],
      branchOffices: [],
      costCenter: [],
      ids: [],
      account_id: null,
    }
  },
  validations: {
    dataAcc: {
      required,
      $each: {
        min: {},
        max: {},
        account_id: {},
        branch_office_id: {},
        cost_center_id: {},
        total_amount: {},
        destroy_manual: {},
        percentage: {},
        disable: {},
      },
    },
  },
  components: {
    // addMassClasification,
    RangeSlider,
    Spinner2,
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  watch: {},
  mounted() {
    this.getAccounts()
    this.getCostCenter()
    this.getBranchOffice()
    this.getCountAccounts()
    this.loading = false
  },
  methods: {
    getAccounts() {
      this.axios
        .get("select_movement_sale", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.accounts = response.data
        })
    },
    calculatePorcentaje() {
      let sum_array = []
      this.dataAcc
        .slice(1)
        .filter((e) => {
          return e.destroy_manual == false
        })
        .forEach((e) => {
          sum_array.push(e.percentage)
        })
      this.lines_sum = sum_array.reduce((sum, a) => sum + a, 0)
      this.dataAcc[0].percentage = 100 - this.lines_sum
    },
    getCostCenter() {
      this.axios
        .get("cost_centers", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.costCenter = response.data
        })
    },
    getBranchOffice() {
      this.axios
        .get("branch_offices", {
          params: {
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.branchOffices = response.data
        })
    },
    add() {
      let cur = this
      cur.dataAcc.push({
        company_id: this.$store.state.company_id,
        account_id: null,
        branch_office_id: null,
        cost_center_id: null,
        percentage: 0,
        destroy_manual: false,
        disable: false,
      })
      cur.dataAcc[cur.dataAcc.length - 2].disable = true
      this.max = this.dataAcc[0].percentage
    },
    remove(index) {
      console.log(this.dataAcc)
      let acc = this.dataAcc[index]
      if (acc.id == null) {
        this.dataAcc.splice(index, 1)
      } else {
        acc._destroy = "1"
      }
      this.dataAcc[index - 1].disable = false
      this.max = this.dataAcc[0].percentage + this.dataAcc[index - 1].percentage
      this.calculatePorcentaje(index)
    },
    // delete_mass
    massClasification() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitValidation = true
        this.axios
          .post("update_mass_clasification", {
            configMovement: this.dataAcc,
            arrMovements: this.selected_checks,
          })
          .then(() => {
            this.$emit("close")
            this.$emit("getSales")
            setTimeout(() => {
              this.submitValidation = false
            }, 500)
          })
      }
    },
    getCountAccounts() {
      this.axios
        .get("classified-movements", {
          params: {
            movement_ids: this.selected_checks,
            company_id: this.company_id,
          },
        })
        .then((response) => {
          this.countClassified = response.data
        })
    },
  },
}
</script>
<style>
textarea:focus,
input:focus {
  outline: none;
}
</style>
