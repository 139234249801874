<template>
  <transition name="modal">
    <div class="
      h-screen
      w-screen
      fixed
      top-0
      left-0
      bg-black bg-opacity-50
      flex
      justify-center
      items-center
      z-50" v-if="loading">
      <Loader/>
    </div>
    <div v-else class="modal-mask flex md:justify-center md:items-center">
      <div class="h-screen w-screen"></div>
        <div class="absolute md:w-full lg:w-8/12">
        <div
            class="
              dark:bg-background-dark
              bg-white
              w-screen
              overflow-auto
              h-screen
              md:h-auto
              md:max-h-screen
              py-5
              px-4
              md:w-full
              md:py-4 md:px-10"
            id="modalWidthModal"
          >
            <div class="flex items-center dark:text-white py-4 justify-center">

              <h1 class="flex-auto text-grey-darker font-semibold lg:text-3xl text-xl">
                {{ movement.business_name }}
              </h1>

              <div class="flex justify-end ">
                <a
                  class="text-6xl dark:hover:text-cian-dark dark:text-white"
                  @click.prevent="$emit('close')"
                >
                  &times;
                </a>
              </div>
            </div>
            <div class="">
              <div class="flex flex-col md:flex-row justify-between mx-2">
                <div class="text-gray-500 text-sm w-2/3 pt-1">
                  <div class="text-sm tracking-normal text-gray-500">
                    {{ movement.folio }} / {{ formatDocType(movement.doc_type) }}
                  </div>
                  <div class="text-sm tracking-normal text-gray-500">
                    {{ formatSimpleDate(movement.period) }}
                  </div>
                </div>
                <div class="mr-32">
                  <div class="leading-5 text-gray-500">Neto</div>
                  <div class="text-lg font-semibold dark:text-white"> $ {{ formatNumber(neto) }} </div>
                </div>
              </div>
              <addAccountMovementModal
                class="w-full mt-8"
                :dataAcc="dataAcc"
                :movementId="movementId"
                :movement="movement"
                :accounts="accounts"
                :neto="neto"
                :ids="ids"
                :type_movement="type_movement"
                :loadingButton="loadingButton"
                @addAttribute="addAttribute"
                @submit="submit"
                @addVendorAccountId="addVendorAccountId"
              ></addAccountMovementModal>
              <div
                class="grid grid-cols-2 -mt-8 mb-3 mx-2 right-0"
                v-if="movement.vendor && !movement.vendor.account_id && dataAcc.length === 1"
              >
                <label class="flex justify-start items-center">
                  <div
                    class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500 dark:bg-background-dark"
                  >
                    <input
                      type="checkbox"
                      class="opacity-0 absolute"
                      v-model="movement.movement_checked"
                    />
                    <svg
                      class="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
                      viewBox="0 0 20 20"
                    >
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  </div>
                  <div
                    class="select-none ml-2 text-sm tracking-normal text-gray-500 dark:text-white"
                  >
                    Categorizar a
                    <span class="font-black">{{ movement.vendor.name }}</span>
                    siempre en está cuenta.
                  </div>
                </label>
              </div>

              <div
                class="grid grid-cols-2 -mt-8 mb-3 mx-2 right-0"
                v-if="movement.service_provider && !movement.service_provider.account_id && dataAcc.length === 1"
              >
                <label class="flex justify-start items-center">
                  <div
                    class="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500"
                  >
                    <input
                      type="checkbox"
                      class="opacity-0 absolute"
                      v-model="movement.movement_checked"
                    />
                    <svg
                      class="fill-current hidden w-4 h-4 text-green-500 pointer-events-none"
                      viewBox="0 0 20 20"
                    >
                      <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                    </svg>
                  </div>
                  <div
                    class="select-none ml-2 lg:text-sm text-xs tracking-normal text-gray-500 dark:text-white"
                  >
                    Categorizar a
                    <span class="font-black">{{ movement.service_provider.name }}</span>
                    siempre en está cuenta.
                  </div>
                </label>
              </div>
            </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { formatDocType, formatNumber, formatSimpleDate } from "@/utils/formatters.js";
import addAccountMovementModal from "./addAccountMovementModal";
import Loader from "@/components/loader.vue"

export default {
  name: "modalAccount",
  props: {
    movementId: Number,
    accounts: Array
  },
  data() {
    return {
      ids: [],
      user: null,
      dataAcc: [],
      type_movement: '',
      loading: false,
      loadingButton: false,
      movement: {
        type_movement: "type_sale",
        coin: null,
        total: null,
        period: '',
        responsable: null,
        folio: null,
        account_neto_id: null,
        company_id: null,
        client_id: null,
        user_id: null,
        neto: null,
        vendor: { account_id: null },
        vendor_account_id: null,
        description: null,
        movement_status: null,
        account_movements_attributes: [],
        recommended: null,
      },
      neto: null,
    };
  },
  components: {
    addAccountMovementModal,
    Loader
  },
  computed: {
    company_id() {
      return this.$store.state.company_id;
    },
  },
  mounted() {
    this.loading = true;
    this.user = this.$auth.user();
    this.getMovement();
  },
  methods: {
    formatDocType,
    formatNumber,
    formatSimpleDate,
    getMovement() {
      this.$emit("getMovements");
      this.axios.get("movements/" + this.movementId).then((res) => {
        this.neto = Number(res.data.neto);
        this.movement.total = Number(res.data.total);
        this.movement.business_name = res.data.business_name;
        this.movement.doc_type = res.data.doc_type;
        this.movement.movement_checked = res.data.movement_checked;
        this.movement.neto = Number(res.data.neto);
        this.movement.period = res.data.period;
        this.movement.folio = res.data.folio;
        this.movement.vendor = res.data.vendor;
        this.movement.service_provider = res.data.service_provider;
        this.movement.company_id = res.data.company_id;
        this.movement.client_id = res.data.client_id;
        this.movement.user_id = res.data.user_id;
        this.movement.account_neto_id = res.data.account_neto_id;
        this.movement.service_provider_id = res.data.service_provider_id;
        this.movement.type_movement = this.setTypeMovement(res.data.type_movement);
        this.movement.account_movements_attributes = this.setAttributes(res.data.account_movements_only_neto, res.data.recommended);
        this.movement.recommended = res.data.recommended;
        this.movement.recommended_name = res.data.recommended_name;
        this.loading = false;
      });
    },
    setTypeMovement(value){
      this.type_movement = value.split('_')[1]
      return value
    },
    setAttributes(account_movements, recommended){
      let a = account_movements.map((element, index) =>{
        if(index == 0){
          element.account_id = recommended
        }
        let porcentaje = (element.total_amount / this.neto) * 100;
        element.percentage = Math.round(porcentaje, 2);
        element.min = 0;
        element.max = Math.floor(this.neto * element.percentage)/100;
        this.ids.push(index)
        element.disable = false;
        return element
      })
      console.log(a)
      this.dataAcc = a
      return a
    },
    update(){
      this.loadingButton = true
      this.axios
      .patch("movements/" + this.movementId, this.movement)
      .then(() => {
        this.loadingButton = false
        this.$swal.fire(
          "Actualizado",
          "Tu egreso ha sido actualizado con exito",
          "success"
          );
        this.$emit("getMovements");
        this.$emit("close");
      })
      .catch(() => {
        this.loadingButton = false
        this.$swal.fire("Oops...", "Algo ha salido mal", "error");
      });
    },
    submit() {
      if ( this.$children[0].balance > 0) {
        throw this.$swal.fire("Oops...", "Algo ha salido mal", "error");
      }
      this.update()
    },
    addVendorAccountId(id) {
      this.movement.vendor_account_id = id;
    },
    addAttribute(e) {
      this.movement.account_movements_attributes = e;
      this.dataAcc = e
      this.sleep(2000);
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>
<style>
input:checked + svg {
  display: block;
}
.w-100{
 width: 50vw;
}
</style>
