<template>
  <div class="w-full">
    <h2 class="my-2 text-base font-bold uppercase lg:text-xl dark:text-gray-200">
      Datos del Servicio de impuestos interno
    </h2>
    <div class="grid w-full gap-4 my-4 sm:grid-cols-2 lg:grid-cols-3">
      <div class="flex flex-col h-full">
        <label
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          for="inline-full-name"
        >
          RUT SII
        </label>
        <input
          disabled
          class="input-text"
          type="text"
          required
          maxlength="12"
          v-model="company.sii_config_attributes.rutcntr"
          @input.prevent="formatter($event.target)"
          placeholder="Ingrese RUT"
        />
      </div>
      <div class="flex flex-col h-full">
        <label
          for="password"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
          >Contraseña</label
        >
        <div class="relative">
          <input
            :type="show ? 'text' : 'password'"
            v-model="company.sii_config_attributes.password"
            class="input-text"
            placeholder="Contraseña"
          />
          <div class="pr-4 eyeButton dark:text-gray-500">
            <i
              class="fas fa-eye"
              @mousedown="show = !show"
              @mouseup="show = !show"
              @touchstart="show = !show"
              @touchend="show = !show"
              style="cursor: pointer"
            ></i>
          </div>
        </div>
      </div>
      <div class="flex flex-col h-full">
        <label
          for="date"
          class="block mb-1 text-xs font-bold tracking-wide uppercase md:text-left text-grey-darker dark:text-white"
        >
          Fecha</label
        >
        <date-picker
          :style="{
            width: '100%',
            'border-radius': '2px',
            'padding-left': '0.35rem',
            'padding-right': '0.35rem',
            'padding-top': '0.35rem',
            'padding-bottom': '0.35rem',
            'border-radius': '5px',
          }"
          format="DD-MM-YYYY"
          v-model="company.sii_config_attributes.from"
          class="border dark:bg-black-dark dark:border-background-dark"
          type="date"
          input-class="xmx-input"
          placeholder="Seleccionar periodo"
        ></date-picker>
      </div>
    </div>
    <div class="flex items-center justify-center my-12">
      <Spinner v-if="loading"/>
      <button
        v-else
        class="flex justify-center gap-2 dark:bg-cian-dark dark:text-white items-center bg-gray-200 rounded-full p-2 px-4"
        type="button"
        @click.prevent="update"
      >
        Guardar Configuración
      </button>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner2.vue';
import DatePicker from "vue2-datepicker";

export default {
  name: "siiData",
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  components: {Spinner, DatePicker},
  data () {
    return {
      show: false,
      loading: false,
    }
  },
  created() {
    this.$emit('updateTab', "SII")
  },
  computed: {
    company_id() {
      return this.$store.state.company_id
    },
  },
  methods: {
    formatter(target) {
      let value = target.value.replace(/\./g, "").replace("-", "")

      if (value.match(/^(\d{2})(\d{3}){2}(\w{1})$/)) {
        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4")
      } else if (value.match(/^(\d)(\d{3}){2}(\w{0,1})$/)) {
        value = value.replace(/^(\d)(\d{3})(\d{3})(\w{0,1})$/, "$1.$2.$3-$4")
      } else if (value.match(/^(\d)(\d{3})(\d{0,2})$/)) {
        value = value.replace(/^(\d)(\d{3})(\d{0,2})$/, "$1.$2.$3")
      } else if (value.match(/^(\d)(\d{0,2})$/)) {
        value = value.replace(/^(\d)(\d{0,2})$/, "$1.$2")
      }
      target.value = value
      this.rutctnr = value
      let arrayRut = this.rutctnr.split("-")
      this.rut = arrayRut[0].split(".").join("")
      this.dv = arrayRut[1]
    },
    update() {
      this.loading = true,
      this.axios
        .patch("companies/" + this.company_id, this.company)
        .then(() => {
          this.$swal.fire(
              "Actualizado",
              "Tu Compañia ha sido guardada exitosamente.",
              "success"
          );
          this.loading = false;
        })
        .catch((e) => console.log(e))
    },
  },
}
</script>
